import React from "react";
import "./mspCalculatorDropdownAndModals.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import integrationUtils from "../../../../../Utils/integration.utils";
import IntegrationIcon from "../IntegrationIcon";

const MultiplyIcons = ({
  icons = [],
  nameKey = '',
  idKey = '',
  iconKey = ''
}) => {

  return (
    <OverlayTrigger
      placement="bottom"
      trigger={["hover", "focus"]}
      delay={{ show: 200, hide: 250 }}
      overlay={
        <Tooltip id={`tooltip-assets`}>
          {
            icons?.map((iconItem) => {
              return (
                <div className="d-flex my-1">
                  <IntegrationIcon
                    integrationIconId={iconItem[idKey]}
                    toolIcon={iconItem[iconKey]}
                    passedClassName={'mr-1'}
                  />
                  {/* <img
                    alt=""
                    id=""
                    width={16}
                    height={16}
                    className="mr-1 rounded-full"
                    src={integrationUtils.getImagePath(iconItem[idKey], iconItem[iconKey])}
                  /> */}
                  <div className="f-400 f-12 f-white">
                    {iconItem[nameKey]}
                  </div>
                </div>
              )
            })
          }
        </Tooltip>
      }
    >
      {
        icons?.length > 3 ? <div className="d-flex align-items-center">
          {
            icons?.slice(0, 3)?.map((iconItem, iconIndex) => {
              return (
                <IntegrationIcon
                  integrationIconId={iconItem[idKey]}
                  toolIcon={iconItem[iconKey]}
                  styles={{ marginLeft: iconIndex != 0 && "-6px" }}
                />
                // <img
                //   alt=""
                //   id=""
                //   width={16}
                //   className="rounded-full"
                //   style={{
                //     marginLeft: iconIndex != 0 && "-6px"
                //   }}
                //   src={integrationUtils.getImagePath(iconItem[idKey], iconItem[iconKey])}
                // />
              )
            })
          }
          <div
            className="d-flex align-items-center justify-content-center f-500 f-9 f-black line-height-12 rounded-full bg-dark-greyish"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '-6px'
            }}
          >
            + {icons?.length - 3}
          </div>
        </div> : <div className="d-flex align-items-center">
          {
            icons?.map((iconItem, iconIndex) => {
              return (
                <IntegrationIcon
                  integrationIconId={iconItem[idKey]}
                  toolIcon={iconItem[iconKey]}
                  styles={{ marginLeft: iconIndex != 0 && "-6px" }}
                />
                // <img
                //   alt=""
                //   id=""
                //   width={16}
                //   className="rounded-full"
                //   style={{
                //     marginLeft: iconIndex != 0 && "-6px"
                //   }}
                //   src={integrationUtils.getImagePath(iconItem[idKey], iconItem[iconKey])}
                // />
              )
            })
          }
        </div>

      }
    </OverlayTrigger>
  );
};

export default MultiplyIcons;
