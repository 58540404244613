import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Button, Spinner, Dropdown } from "react-bootstrap";
import ImageCropperModal from "./personal-profile-modals/ImageCropperModal";
import UserDetailModal from "./personal-profile-modals/UserDetailModal";

import "./personal-profile.scss";
import { useDispatch, useSelector } from "react-redux";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { setuser } from "../../../redux/userState/actions";
import { useDb } from "../../context/db";
import { WhiteLoader } from "../../Theme/APILoaders";
import { useHistory } from "react-router-dom";
import PreviewAccessModal from "../membersAccess/PreviewAccessModal";
import TestNotificationModal from "../activeSessions/TestNotificationModal";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import LogoutAllDevicesModal from "../membersAccess/LogoutAllDevicesModal";
import MemberAccessRoleModal from "../membersAccess/MemberAccessRoleModal";
import AddEditCertificateModal from "../../MSPv2/secOps/trainingCertificates/AddEditCertificateModal";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
import { USER_ROLES } from "../../../constants/user.constants";
import userUtils from "../../../Utils/user.utils";

const PersonalProfileV2 = () => {
  const { setdbObject } = useDb();
  const history = useHistory();
  const { authTokens, setAuthTokens, setUserType } = useAuth();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetail);

  const [notifSetting, setNotifSetting] = useState(false);
  const [disableResetPassword, setDisableResetPassword] = useState(true);
  const [loading, setloading] = useState(false);
  const [mfaReset, setMfaReset] = useState(false);
  const [DisableMFAReset, setDisableMFAReset] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [userObjId, setUserObjId] = useState("");
  const [removingPhoto, setRemovingPhoto] = useState(false);
  const [deleteCode, setDeleteCode] = useState("");
  const [showPreviewAccessModal, setShowPreviewAccessModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [roles, setRoles] = useState([]);
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false)
  const [deviceList, setDeviceList] = useState(5);
  const [showLogoutAllDevicesModal, setShowLogoutAllDevicesModal] = useState(false);
  const [certificatesInfo, setCertificatesInfo] = useState([]);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [categoryIdType, setCategoryIdType] = useState(null);
  const [selectedCertificateData, setSelectedCertificateData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => { },
  });
  const isSaaS = userUtils.isUserSaaS(userDetails?.Role);

  const accessData = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  const userRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;


  useEffect(() => {
    getNotificationSettings();
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.userId) {
      getCertificatesInfo();
    }
  }, [userInfo])

  async function getUserInfo() {
    await getAPIData("851", authTokens, {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    }).then((response) => {
      if (response?.mr?.Success) {
        setUserInfo(response);
        setRoles(response?.roles);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  async function getCertificatesInfo() {
    await getAPIData("806", authTokens, {
      optionEnum99: userRole === "MSP" ? 5 : 42,
      optionStrEnum1: userInfo?.userId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    }).then((response) => {
      if (response?.mr?.Success) {
        setCertificatesInfo(response);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function changeRole(roleId, userId, roleAction) {
    await postRequest(authTokens, "UpdateUserAccessRole", {
      objId_AccessRole: roleId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: userId,
      action: roleAction,
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Access role changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function changeDashboard(dashboardId, roleAction) {
    await postRequest(authTokens, "AssignDashboard", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: userInfo.userId,
      objId_UserSettings: userInfo?.objId_UserSettings,
      objId_Dashboard: dashboardId,
      action: roleAction,
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Dashboard changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function changeDefaultDashboard(dashboardId) {
    await postRequest(authTokens, "AssignDefaultDashboard", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: userInfo.userId,
      objId_UserSettings: userInfo?.objId_UserSettings,
      objId_Dashboard: dashboardId,
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Default dashboard changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function RemoveDevice(enum1) {
    await getAPIData(105, authTokens, {
      optionEnum1: enum1,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    })
      .then((result) => {
        if (result.Success) {
          TrackUser("Device deleted");

          CallToast("Device removed successfully", false, false, "v2style");
        } else {
          CallToast("Unable to update", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function getNotificationSettings() {
    const response = await getAPIData(103, authTokens);
    setNotifSetting(response.NotificationSettings);
    setUserObjId(response.objId_UserSetting);
  }

  async function resetData(id) {
    const response = await getAPIData(id, authTokens, {
      optionEnum1: userObjId,
    });

    if (response.Success) {


      if (Number(id) === 107) {
        CallToast("Password reset email sent", false, false, "v2style");
      }
      // CallToast(response?.Message, false, false);
    } else {
      CallToast("Something went wrong", false);
    }
  }

  async function handleSave(notifName, notifValue) {
    notifValue = notifValue.replace(/<\/?[^>]+>/gi, "");
    if (notifName == "Fullname") {
    }
    const inputs = {
      SettingOwner: notifSetting.SettingOwner,
      OwnerType: notifSetting.OwnerType,
      NotificationName: notifName,
      NotificationValue: notifValue,
    };
    if (notifName == "Password_Reset") {
      setloading(true);
    }
    if (notifName == "MFA_Reset") {
      setMfaReset(true);
      setDisableMFAReset(false);
    }

    if (notifName == "Password_Reset") {
      setDisableResetPassword(false);
    }
    if (notifValue !== 1) {
    }
    setShowToast(true);
    await postData(authTokens, "SubmitNotificationSettings", inputs)
      .then((response) => {
        if (response.Success == true || response.FullName == notifValue) {
          dispatch(setuser(response));
          setloading(false);
          setMfaReset(false);
        } else {
          setloading(false);
        }
      })
      .catch(() => {
        setloading(false);
      });
  }

  async function RemovePhoto() {
    setRemovingPhoto(true);
    await getAPIData("258", authTokens).then((response) => {
      setRemovingPhoto(false);
      if (response.Success) {
        updateAccessKeyInLocalStorage(response?.Message)
    
        dispatch(setuser({ Avatar: "No URL" }));
        CallToast("Photo removed", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function deleteCertification(certificationId) {
    await postData(authTokens, "Certification/ArchiveOrDelete", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      objId_Certification: certificationId,
      userId: userInfo?.userId,
      optionEnum99: userRole === "MSP" ? 5 : 42
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Certification deleted successfully",
            true,
            false,
            "v2style"
          );
          getCertificatesInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const fileUpload = useRef();
  const [detailModal, setdetailModal] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [type, setType] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [userImage, setUserImage] = useState(
    "https://st4.depositphotos.com/1012074/20946/v/450/depositphotos_209469984-stock-illustration-flat-isolated-vector-illustration-icon.jpg"
  );

  const setImage = (image) => {
    setUserImage(image);
  };

  const hideNotificationModal = () => {
    setNotificationModal(false);
  };

  const showCropperModal = () => {
    setCropperModal(true);
  };

  const hideCropperModal = () => {
    setCropperModal(false);
  };

  const hideDetailModal = () => setdetailModal(false);
  const showAsNameModal = () => {
    setType("name");
    setdetailModal(true);
  };
  const showAsPositionModal = () => {
    setType("position");
    setdetailModal(true);
  };

  const [deleteAccountError, setDeleteAccountError] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  // Function to delte account
  async function DeleteAccount() {
    if (deleteCode === "") {
      setDeleteAccountError("dfdsf");
      return;
    }
    setDeleting(true);
    await getAPIData("1000", authTokens, {
      optionEnum1: userDetails?.objId_UserSettings,
      optionEnum2: deleteCode,
    })
      .then((response) => {
        setDeleting(false);
        if (response?.Success) {
          setDeleteCode("");
          CallToast("Account deleted successfully", false, false, "v2style");
          setTimeout(() => {
            history.push("/logout");
          }, 2000);
        } else {
          setDeleteAccountError("dfdsf");
          CallToast(response?.Message, false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setDeleting(false);
      });
  }

  return (
    <div className="personal-profile-settings-v2 ">
      <h3>Profile settings</h3>

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img
            className="rounded-circle border-grey camera-icon cursor-pointer"
            src={
              userDetails.Avatar == "No URL"
                ? "/images/settings-v2/profile-thumbnail.svg"
                : userDetails.Avatar
            }
            alt=""
          // onClick={() => setShowUploadModal(true)}
          // width="79"
          />
          <input
            type="file"
            accept="image/*"
            ref={fileUpload}
            className="d-none"
            onChange={(e) => {
              if (e.target.files[0]) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);

                reader.onload = function () {
                  setSelectedImage(reader.result);
                  showCropperModal();
                };
              }
            }}
          />

          <div className="ml-3">
            <p className="userName m-0">{userInfo?.FullName}</p>
            <p className="userEmail m-0">{userInfo?.Email}</p>
          </div>
        </div>

        {userDetails.Avatar == "No URL" ? (
          <Button
            className="profile-upload-btn"
            onClick={() => {
              fileUpload.current.click();
            }}
          >
            Upload photo
          </Button>
        ) : (
          <div>
            <Button
              className="change-photo-btn"
              onClick={() => {
                fileUpload.current.click();

                // CallToast("Email has been Updated", false, false);
              }}
            >
              Change photo
            </Button>
            <Button
              className="remove-photo-btn"
              onClick={() => {
                RemovePhoto();
              }}
            >
              {removingPhoto ? (
                <>
                  <Spinner
                    size="sm"
                    className=" mr-2"
                    animation="border"
                    variant="secondary"
                  />
                  Removing...
                </>
              ) : (
                "Remove photo"
              )}
            </Button>
          </div>
        )}
      </div>
      <hr />

      <div className="personal-info">
        <h4>Relationships</h4>
        <div className="user-details">
          <Row>
            <Col xs={12}>
              <div className="name-detail">
                <div className="name-label">Company</div>
                <div>
                  <span className="name mr-2">{userDetails?.OrgName} </span>
                </div>
              </div>
              {!isSaaS && (
                <hr className="m-0" />
              )}
            </Col>
            {!isSaaS && (
              <Col xs={12}>
                <div className="name-detail">
                  <div className="name-label">MSP Name</div>
                  <div>
                    <span className="name mr-2">{userInfo?.MSPName} </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>

      <div className="personal-info">
        <h4 className="mt-3">Personal Info</h4>
        <div className="user-details">
          <Row>
            <Col xs={12} onClick={showAsNameModal} className="pointer">
              <div className="name-detail">
                <div className="name-label">Name</div>
                <div>
                  <span className="name">{userInfo?.FullName} </span>
                  <img
                    alt=""
                    src="/images/chevron-right.svg"
                    className="details-arrow"
                    style={{
                      marginRight: '7px'
                    }}
                  />
                </div>
              </div>
              <hr className="m-0" />
            </Col>

            <Col
              xs={12}
              className="pointer"
            >
              <div className="name-detail">
                <div className="name-label">Email</div>
                <div>
                  <span className="name mr-2">{userDetails?.UserName} </span>
                  {/* <img
                    alt=""
                    src="/images/chevron-right.svg"
                    className="details-arrow hidden d-none"
                  /> */}
                </div>
              </div>
              <hr className="m-0" />
            </Col>
            <Col>
              <div
                className="d-flex align-items-center justify-content-between border-item p-3"
                onClick={() => {
                  showAsPositionModal();
                  getUserInfo();
                }}
              >
                <div className="f-14 f-500">Position</div>
                <div className="f-grey d-flex align-items-center pointer my-2">
                  <div className="mr-4">{userInfo?.Position?.length === 0 ? 'No position' : userInfo?.Position}</div>
                  <div>
                    <img src="/images/chevron-right.svg" className="mr-2" alt="" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="security-wrapper">
        <h4 className="mb-2 mt-3">Access roles</h4>
        <div className="bg-grey radius-8 border-parent">
          <div className="border-item d-flex align-items-center justify-content-between p-3">
            <div className="f-500">Access roles</div>
            <div className="d-flex align-items-center">
              <div className="f-darkgrey mr-3">
                {
                  userInfo?.AccessRoles?.length < 30 ?
                    userInfo?.AccessRoles : `${userInfo?.AccessRoles?.slice(0, 30)}...`
                }
              </div>

              {/* <Dropdown
                className="hg-white-dropdown user-detail-dropdown transparennt-wrapper-dropdown"
                onToggle={(e) => {
                  setIsDropdownOpen(e)
                }}
              >
                <Dropdown.Toggle
                  disabled={accessData?.AccessState_UserManagement < 3 ? true : false}
                  className={`  f-blue ${IsDropdownOpen ? "opened" : ""}`}
                >
                  {
                    accessData?.AccessState_UserManagement >= 3 &&
                    <div className="f-blue">
                      {
                        userInfo?.AccessRoles?.length < 1 ? 'Select' : 'Change'
                      }
                    </div>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div
                    className="p-2 pl-3 my-1 pointer f-darkgrey"
                    style={{ 'display': 'grid', 'grid-template-columns': '10% 90%' }}
                    onClick={(e) => {
                      setShowAccessLevelModal(true);
                      e.target.closest(".hg-white-dropdown").click();
                      getUserInfo();
                    }}
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/lightgrey-plus-icon.svg"
                      className="mr-2"
                    />
                    <div>Create Access Role</div>
                  </div>
                  {roles.map(el => {
                    return (<div
                      key={el.RoleName}
                      className="p-2 pl-3 my-1 pointer f-grey"
                      style={{ 'display': 'grid', 'grid-template-columns': '10% 90%' }}
                      onClick={(e) => {
                        const memberRolesArray = userInfo?.objId_AccessRoles;
                        let action;

                        if (memberRolesArray) {
                          const isRoleHere = memberRolesArray?.find(
                            (item) => item === el.objId_AccessRole
                          );
                          action = isRoleHere === el.objId_AccessRole ? 0 : 1;
                        } else {
                          action = 1;
                        }

                        if (memberRolesArray.length === 1 && action === 0) {
                          CallToast("Member role can not be empty", false, false, "v2style");
                        } else {
                          changeRole(el.objId_AccessRole, userInfo?.userId, action);
                        }

                        getUserInfo();
                        e.target.closest(".hg-white-dropdown").click();
                      }}
                    >
                      <div>
                        {userInfo?.objId_AccessRoles?.map((membRoleId) => (
                          membRoleId === el.objId_AccessRole ?
                            <img
                              alt=""
                              src="/images/settings-v2/check-icon.svg"
                              className="mr-2"
                            />
                            : <></>
                        ))
                        }
                      </div>

                      <div>
                        {el.RoleName}
                        <div className="smallGreyText text-ellipsis f-darkgrey">{el.RoleDescription}</div>
                      </div>

                    </div>)
                  })}
                </Dropdown.Menu>
              </Dropdown> */}

            </div>

          </div>
          <div className="border-item d-flex align-items-center justify-content-between px-3 py-2">
            <div className="f-500 f-grey f-14">Preview access</div>
            <div
              onClick={() => {
                setShowPreviewAccessModal(true);
              }}
            >
              <img src="/images/chevron-right.svg" className="mr-2 pointer" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="security-wrapper">
          <div className="d-flex align-items-center justify-content-between mb-2 mt-3">
            <h4 className="mb-2 mt-3">Training Certificates</h4>
            <div>
              <Button className="btn-blue"
                onClick={() => {
                  setShowCertificateModal(true);
                }}
              >
                <div>
                  <img
                    alt=""
                    src="/images/settings-v2/plus-icon.svg"
                    className="mr-2"
                  />
                  Add Certificate
                </div>
              </Button>
            </div>
          </div>

          <div className="border-parent radius-8">
            {
              certificatesInfo?.items && certificatesInfo?.items[0] ? (
                <>
                  {certificatesInfo?.items?.map((item, index) => {
                    return (
                      <div
                        className="border-item bg-hover-row d-flex align-items-center justify-content-between p-3 pointer"
                      >
                        <div 
                          className="d-flex align-items-center overlap_parent w-100"
                          onClick={() => {
                            setSelectedCertificateData(item);
                            setShowCertificateModal(true);
                          }}
                        >
                          {item?.certificateIcon?.showInitials ? (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <ImageWithInitials
                                initials={item?.certificateIcon?.initials}
                                width={33}
                                background={"purple"}
                              />
                              {item?.certificateIcon?.statusIcon && (
                                <img
                                  width={13}
                                  src={
                                    "/images/msp/" +
                                    item?.certificateIcon?.statusIcon
                                  }
                                  className="rounded-full overlapping_icon"
                                  alt=""
                                />
                              )}
                            </div>
                          ) : (
                            <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                              <img
                                width={33}
                                src={`/images/msp/framework-certificates/${item?.certificateIcon?.icon}`}
                                className="rounded-full"
                                alt=""
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src =
                                    "/images/msp/default-cert-icon.svg";
                                }}
                              />
                              {item?.certificateIcon?.statusIcon && (
                                <img
                                  width={13}
                                  src={
                                    "/images/msp/" +
                                    item?.certificateIcon?.statusIcon
                                  }
                                  className="rounded-full overlapping_icon"
                                  alt=""
                                />
                              )}
                            </div>
                          )}
                          <div className="ml-3">
                            <div className="f-500">
                              {item?.name}
                              &nbsp; &nbsp;
                              {item?.status && (
                                <span
                                  style={{
                                    color: item?.status?.foreColor,
                                  }}
                                >
                                  {item?.status?.text}
                                </span>
                              )}
                            </div>
                            <div className="f-darkgrey">
                              {item?.points?.map((point, idx) => (
                                <span>
                                  {idx > 0 ? " • " : ""} {point}
                                </span>
                              ))}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {item?.linkUrl && (
                            <div 
                              className="d-flex align-items-center f-12 f-darkgrey mr-2"
                              onClick={() => {
                                const win = window.open(`${item?.linkUrl}`, "_blank");
                                win.focus();
                              }}
                            >
                              <img
                                src={"/images/msp/msp-cert-link-icon.svg"}
                                alt=""
                                className="mr-2  "
                              />
                              <div className="h-17px">
                                Link
                              </div>
                            </div>
                          )}
                          {item?.fileUrl && (
                            <div 
                              className="d-flex align-items-center f-12 f-darkgrey mr-2"
                              onClick={() => {
                                  downloadFile(
                                    item?.fileUrl,
                                    item?.fileName
                                  );  
                              }}
                            >
                              <img
                                src={"/images/msp/msp-cert-file-icon.svg"}
                                alt=""
                                className="mr-2  "
                              />
                              <div className="h-17px">
                                File
                              </div>
                            </div>
                          )}
                          {item?.showOnlyAddButton && (
                            <div 
                              className="d-flex align-items-center f-12 f-darkgrey border1 px-2 radius-2 mr-2"
                              onClick={() => {
                                  setSelectedCertificateData(item);
                                  setShowCertificateModal(true);
                              }}
                            >
                              <img
                                src={"/images/msp/msp-cert-add-icon.svg"}
                                alt=""
                                className="mr-2  "
                              />
                              Add
                            </div>
                          )}
                          <div
                            className="d-flex align-items-center f-12 f-darkgrey ml-1"
                            onClick={() => {
                                setShowDeleteModal({
                                  showModal: true,
                                  deleteMessage:
                                    "You are about to delete a training certificate.",
                                  deleteSentQuestionnaire:
                                    () =>
                                      deleteCertification(item?.objId_Certification)
                                });
                            }}
                          >
                            <img
                              src={"/images/complianceManager/deleteIcon.svg"}
                              alt=""
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="p-3 f-grey">No certificates for this user yet</div>
              )
            }
          </div>
        </div>

      {/* <div className="security-wrapper">
        <h4 className="mb-2 mt-3">Dashboard</h4>
        <div className="bg-grey radius-8 border-parent">
          <div className="border-item d-flex align-items-center justify-content-between p-3">
            <div className="f-500">Available dashboards</div>
            <div className="d-flex align-items-center">

              <Dropdown
                className="hg-white-dropdown user-detail-dropdown transpare nnt-wrapper-dropdown"
                onToggle={(e) => {
                  setIsDropdownOpen(e)
                }}
              >
                <Dropdown.Toggle
                  disabled={accessData?.AccessState_UserManagement < 3 ? true : false}
                  className={`  f-blue custom-dropdown-toggle f-500 ${IsDropdownOpen ? "opened" : ""
                    }`}
                >
                  {userInfo?.dashBoardTypes?.map((dashboardItem, dashboardItemIndex) => (
                    dashboardItem?.isSelected ?
                      `${dashboardItem?.name}, ` : ''
                  ))}

                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2"
                  style={{
                    width: "320px"
                  }}

                >
                  <div className="f-darkgrey">
                    Available dashboards
                  </div>
                  <div>
                    {userInfo?.dashBoardTypes?.map((dashboardItem, dashboardItemIndex) => {
                      return (
                        <div className="d-flex align-items-ceter bg-hover-grey pointer justify-content-between p-2 parent-visible">
                          <div
                            className="d-flex align-items-center f-grey"
                            onClick={() => {
                              let action = dashboardItem.isSelected ? 0 : 1;
                              changeDashboard(dashboardItem?.objId_Dashboard, action);
                            }}
                          >
                            <img
                              src="/images/msp/dropdown-grey-tick.svg"
                              className="mr-2"
                              alt=""
                              style={{
                                visibility: dashboardItem.isSelected ? "visible" : "hidden"
                              }}
                            />

                            {dashboardItem.name}
                          </div>
                          {
                            dashboardItem.isDefault ? (
                              <div className="f-blue">
                                Default
                              </div>
                            ) : (
                              <div
                                className="f-darkgrey child-visible"
                                onClick={() => {
                                  changeDefaultDashboard(dashboardItem?.objId_Dashboard)
                                }}
                              >
                                Make default
                              </div>
                            )
                          }

                        </div>

                      )
                    })

                    }
                  </div>


                </Dropdown.Menu>
              </Dropdown>

            </div>

          </div>

        </div>
      </div> */}


      {/* <div className="security-wrapper">
        <h4 className="topic-heading mb-2 mt-3">Devices</h4>
        <div className="devices security-details">
          <Row>
            <div className="devices-wrapper w-100">
              {
                userInfo?.UserDevices?.map((device) => (
                  <Col xs={12}>
                    <div className="d-flex justify-content-between device-wrap p-3">
                      <div className="d-flex align-items-center">
                        <img
                          alt=""
                          src="/images/mobile.svg"
                          className="mr-3"
                        />
                        <div className="device-name">
                          {device.Detail}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="device-date">
                          {device.DateAdded}
                        </div>
                        {
                          device.DeviceId !== 0 && (
                            <Dropdown className="device-dropdown black-dropdown">
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  src="/images/horizontal-dots.svg"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  <Row>
                                    <Col
                                      xs={12}
                                      onClick={(e) => {
                                        setNotificationModal(true);
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      <div className="notification-btn bg-hover-grey-light">
                                        Send Notifications
                                      </div>
                                    </Col>
                                    <hr
                                      style={{
                                        width: "70%",
                                        margin: "auto",
                                      }}
                                    />
                                    <Col
                                      xs={12}
                                      onClick={(e) => {
                                        RemoveDevice(device?.DeviceId);
                                        getUserInfo();
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      <div className="remove-btn delete-btn bg-hover-grey-light">
                                        Remove
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          )
                        }
                      </div>
                    </div>
                  </Col>
                ))
              }
            </div>
          </Row>
        </div>

        {
          <div className="d-flex justify-content-between">
            {userInfo?.Device_Count > 0 ? (
              <div
                className={`show-more-btn  mt-3  ${deviceList > 5 ? "arrow-down" : "arrow-up"
                  } `}
                onClick={() => {
                  if (deviceList > 5) {
                    setDeviceList(5);
                  } else {
                    setDeviceList(1000);
                  }
                }}
              >
                {deviceList > 5 ? "Show less" : "Show more"}
              </div>
            ) : (
              <div></div>
            )}
            {
              userInfo?.Device_Count > 0 &&
              <Button
                className="reset-btn mt8px"
                onClick={() => {
                  setShowLogoutAllDevicesModal(true);
                  getUserInfo();
                }}
              >
                Logout all
              </Button>
            }
          </div>
        }
      </div> */}

      <div className="security-wrapper">
        <h4>Security</h4>
        <div className="security-details">
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Reset MFA</div>
                  <span className="greyText">
                    Reset MFA for this user
                  </span>
                </div>
                <Button
                  disabled={!userInfo?.EnableMFAReset}
                  className={`align-self-center ${"personalDetail.EnableMFAReset && DisableMFAReset"
                    ? "light-red-btn"
                    : "btn-disable"
                    }     ${!userInfo?.EnableMFAReset ? "disabled-btn" : ""
                    }           `}
                  style={{ minWidth: "70px", maxWidth: "70px" }}
                  onClick={() => {
                    resetData("108");
                  }}
                >
                  {/* Reset */}
                  {false ? <Spinner size="sm" animation="border" /> : "Reset"}
                </Button>
              </div>
              <hr className="m-0" />
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Reset Password</div>
                  <span className="greyText">
                    Reset the password for this user
                  </span>
                </div>
                <Button
                  className={`align-self-center ${"disableResetPassword" ? "light-red-btn" : "btn-disable"
                    }`}
                  style={{ minWidth: "70px", maxWidth: "70px" }}
                  onClick={() => {
                    resetData("107");
                  }}
                >
                  {/* {loading ? <Spinner size="sm" animation="border" /> : "Reset"} */}
                  Reset
                  {/* Reset */}
                </Button>
              </div>
              <hr className="m-0" />
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">Delete my account</div>
                  <span className="greyText">You will need your 2FA code</span>
                </div>
                <Button
                  className={`align-self-center ${"disableResetPassword" ? "light-red-btn" : "btn-disable"
                    }`}
                  style={{ minWidth: "70px", maxWidth: "70px" }}
                  onClick={() => {
                    setShowDeleteRow(true);
                  }}
                >
                  Delete
                </Button>
              </div>
              {showDeleteRow && <hr className="m-0" />}
            </Col>
            {/* To enter 2FA code to delete account */}
            <Col
              xs={12}
              className={`code-input ${showDeleteRow ? "open" : ""}`}
            >
              <div className="d-flex justify-content-between align-items-center reset-password-wrapper">
                <div>
                  <div className="textBlackBold">
                    Enter your 2FA code and click confirm
                  </div>
                  <span className="greyText">This can not be undone !</span>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={deleteCode}
                    onChange={(e) => {
                      setDeleteAccountError("");
                      setDeleteCode(e.target.value);
                    }}
                    className={`pl-1 border-none bg-white mr-3 radius-4 ${deleteAccountError ? "input-error" : ""
                      }`}
                    style={{
                      height: "26px",
                    }}
                    autoFocus
                  />
                  <Button
                    className={`d-flex align-items-center ${"disableResetPassword" ? "light-red-btn" : "btn-disable"
                      }`}
                    style={{ minWidth: "50px" }}
                    onClick={() => {
                      DeleteAccount();
                    }}
                  >
                    {deleting ? (
                      <div className="d-flex align-items-center">
                        <WhiteLoader width={12} variant={"danger"} />
                        Confirming deletion..
                      </div>
                    ) : (
                      "Confirm account deletion"
                    )}
                  </Button>
                </div>
              </div>
              {/* <hr className="m-0" /> */}
            </Col>
          </Row>
        </div>
        <div className="p-5"></div>
      </div>
      <TestNotificationModal
        show={notificationModal}
        hideModal={hideNotificationModal}
      />
      <UserDetailModal
        type={type}
        show={detailModal}
        hideModal={hideDetailModal}
        handleSave={handleSave}
        refreshData={() => {
          getUserInfo();
        }}
        userDetails={userInfo}
        userId={userInfo?.userId}
      />
      <ImageCropperModal
        show={cropperModal}
        hideModal={hideCropperModal}
        profileImage={selectedImage}
        setProfileImage={setImage}
      />
      <MemberAccessRoleModal
        accessLevelId={0}
        show={showAccessLevelModal}
        hideModal={() => {
          setShowAccessLevelModal(false);
        }}
        parentID="mainBody"
        refreshData={() => {
          getUserInfo();
        }}
        isFromMyProfile={true}
      />
      <LogoutAllDevicesModal
        show={showLogoutAllDevicesModal}
        hideModal={() => {
          setShowLogoutAllDevicesModal(false);
        }}
        isFromMyProfile={true}
        userId={userInfo.userId}
        userSettingsId={userInfo?.objId_UserSettings}
      />
      <PreviewAccessModal
        isFromMyProfile={true}
        show={showPreviewAccessModal}
        hideModal={() => {
          setShowPreviewAccessModal(false);
        }}
        userId={userInfo.userId}
      />
       <AddEditCertificateModal
        show={showCertificateModal}
        categoryId={categoryIdType}
        hideModal={() => {
          setCategoryIdType(null);
          setShowCertificateModal(false);
          setSelectedCertificateData({});
        }}
        selectedUserId={userInfo?.userId}
        selectedCertificateId={
          selectedCertificateData?.objId_Certification ?? 0
        }
        passedUserId={userInfo?.userId}
        passedOptionEnum99={userRole === "MSP" ? 5 : 42}
        refreshData={() => {
          getCertificatesInfo();
          getUserInfo();
        }}
      />
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage={showDeleteModal.deleteMessage}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
    </div>
  );
};

export default PersonalProfileV2;

function downloadFile(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}