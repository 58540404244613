import React from "react";
import './searchFilterComponent.scss';
import { GreyLoader } from "../Theme/APILoaders";
import HeaderDropdown from "./HeaderDropdown";

export const SortingHeaderComponent = ({
    headerName = '',
    headerKey = '',
    sortData = {},
    showHideButton = true,
    sorting = false,
    sortOrder = 1,
    filterList = [],
    childFilterItem,
    onSortClick = () => { },
    updatedHeaderList = () => { }
}) => {
console.log('sortOrder', sortOrder)
    return (
        <div>
            {
                headerKey !== '' ?
                    <div className={`d-flex align-items-center py-1 px-2 w-fit-content pointer ${sortData.sortItem ===
                        headerKey ? 'bg-greyish radius-2' : ''}`}
                        onClick={() => {
                            if (sortData.sortItem !== headerKey) {
                                onSortClick();
                            }
                        }}
                    >
                        {
                            sortData.sortItem === headerKey ?
                                <HeaderDropdown
                                    headerName={headerName}
                                    headerKey={headerKey}
                                    sortData={sortData}
                                    onSortClick={onSortClick}
                                    showHideButton={showHideButton}
                                    updatedHeaderList={updatedHeaderList}
                                /> : <div className={`f-500 f-12  ${sortData.sortItem ===
                                    headerKey ? 'f-black pointer' : 'f-grey'}`}>
                                    {headerName}
                                </div>
                        }
                        {sortData.sortItem ===
                            headerKey && (
                                <>
                                    {sortData?.sorting ? (
                                        <GreyLoader width={10} />
                                    ) : (
                                        <img
                                            alt=""
                                            src="/images/actioncenter/arrowdown.svg"
                                            className={`ml-2 pointer ${sortData.sortOrder === 0
                                                ? "rotate180"
                                                : ""
                                                }`}
                                            onClick={() => {
                                                onSortClick();
                                            }}
                                        />
                                    )}
                                </>
                            )}
                    </div> : <div className={`d-flex align-items-center py-1 px-2 w-fit-content pointer bg-greyish radius-2`}>
                        <HeaderDropdown
                            headerName={headerName}
                            headerKey={headerKey}
                            sortOrder={sortOrder}
                            onSortClick={onSortClick}
                            showHideButton={showHideButton}
                            filterList={filterList}
                            childFilterItem={childFilterItem}
                            updatedHeaderList={updatedHeaderList}
                        />
                        {sorting ? (
                            <GreyLoader width={10} />
                        ) : (
                            <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className={`ml-2 pointer ${sortOrder === 0
                                    ? "rotate180"
                                    : ""
                                    }`}
                                onClick={() => {
                                    onSortClick();
                                }}
                            />
                        )}
                    </div>
            }
        </div>
    );
};

export default SortingHeaderComponent;
