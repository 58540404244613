import { useMemo, useState } from "react";
import integrationUtils from "../../../../Utils/integration.utils";
import { Button, Spinner } from "react-bootstrap";
import { HGHighlightText } from "../../../App/App";

const TableHeaders = ["Tool", "Products", "Last sold", "Action"];

const SuggestionsContent = ({ data, onApply, clientId, searchValue }) => {
  const groupedData = useMemo(() => (
    Object.entries(data?.reduce((acc, item) => {
      if (!acc[item?.IntegrationTechnologyName]) {
        acc[item?.IntegrationTechnologyName] = [];
      }
      acc[item?.IntegrationTechnologyName].push(item);
      return acc;
    }, {}))
  ), [data]);

  const filteredData = useMemo(() => {
    if (searchValue) {
      return groupedData?.map(([sectionName, items]) => {
        const filteredItems = items.filter((item) => (
          item?.ToolName.toLowerCase().includes(searchValue.toLowerCase())
        ));
        return [sectionName, filteredItems];
      }
      ).filter(([sectionName, items]) => items.length > 0);
    }
    return groupedData;
  }, [groupedData, searchValue]);

  return (
    <div className="suggestion-content">
      {filteredData?.map(([sectionName, items]) => (
        <div className="section" key={`section-${sectionName}`}>
          <h2 className="header-wrapper f-14 f-400 line-height-22 f-black m-0">
            {sectionName}
          </h2>
          <div className="table-header">
            {TableHeaders?.map((header) => (
              <div key={`header-${header}`} className="f-12 line-height-18 f-500 f-darkgrey">
                {header}
              </div>
            ))}
          </div>
          <div>
            {items?.map((suggestedItem) => (
              <SuggestedItem 
                key={`suggestedItem-${suggestedItem?.ToolId}`} 
                suggestedItem={suggestedItem} 
                onApply={onApply}
                clientId={clientId}
                searchValue={searchValue}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

const SuggestedItem = ({ suggestedItem, onApply, clientId, searchValue }) => {
  const [isApplying, setIsApplying] = useState(false);

  const handleApply = async () => {
    setIsApplying(true);
    await onApply({
      IntegrationTechList: [suggestedItem?.IntegrationTechnologyEnum], 
      ObjectId: suggestedItem?.ToolId, 
      Selected: true, 
      ToolName: suggestedItem?.ToolName, 
      customerId: clientId
    });
    setIsApplying(false);
  }

  return (
    <div className="suggested-item">
      <div className="d-flex align-items-center" style={{ gap: '8px', alignSelf: 'flex-start' }}>
        <img 
          src={integrationUtils.getImagePath(suggestedItem?.ToolId, suggestedItem?.ToolIcon)}
          width="16px"
          height="16px"
          alt="tool-icon"
          style={{ alignSelf: 'flex-start', marginTop: '2px' }}
        />
        <span 
          className="f-14 line-height-22 f-black"
          style={{ 
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
           }}
        >
          <span 
            dangerouslySetInnerHTML={{ __html: HGHighlightText(suggestedItem?.ToolName, searchValue) }}
          />
        </span>
      </div>
      <div className="d-flex flex-column" style={{ gap: '4px' }}>
        {suggestedItem?.IntegrationTechnologyProductSuggession_List?.map((product) => (
          <div key={`product-${product?.ProductId}`} className="f-black">
            {product?.ProductName}
          </div> 
        ))}
      </div>
      <div className="d-flex flex-column" style={{ gap: '4px' }}>
        {suggestedItem?.IntegrationTechnologyProductSuggession_List?.map((product) => (
          <div key={`product-last-sold--${product?.ProductId}`} className="f-black">
            {product?.LastSold}
          </div> 
        ))}
      </div>
      <div>
        {suggestedItem?.ShowApplyButton && (
          <Button 
            variant="text" 
            className={`apply-btn ${isApplying ? "disabled" : ""}`}
            onClick={handleApply}
            disabled={isApplying}
            style={{ width: '45px' }}
          >
            {isApplying ? (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            ) : "Apply"}
          </Button>
        )}
      </div>
    </div>
  )
}

export default SuggestionsContent;
