import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VisibilityType } from "../../../../../constants/filter.constants";
import SearchFilterComponent from "../../../../SearchFilterComponent/SearchFilterComponent";
import { SubscriptionsNotFoundUI } from "../techspend.not-found";
import TechspendAddDropdown from "../techspend-add.dropdown";
import { TechspendAddMenuAction } from "../../../../../constants/techspend.constants";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import { GreyLoader } from "../../../../Theme/APILoaders";
import SubscriptionCard from "../../subscription.card";
import SubscriptionIcon from "../../../../Common/subscription-icon.component";
import HomeScreenSubscriptionFilters from "../../HomeScreenSubscriptionFilters";
import { useState } from "react";

const filters = [
  {
    filterName: "Amount",
    filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
    filterData: {
      filterType: "amount",
      fromAmount: "",
      toAmount: "",
    },
  },
  {
    filterName: "Supplier",
    filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    filterData: {
      filterType: "supplier",
      filterList: [],
    },
  },
  
  {
    filterName: "Expires",
    filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
    filterData: {
      filterType: "expires",
      selectedDateRange: {
        startDate: null, // new Date(),
        endDate: null, //new Date(),
        key: "selection",
      },
    },
  },
  {
    filterName: "Status",
    filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
    filterData: {
      filterType: "status",
      filterList: [],
    },
  },
]

const SubscriptionsTab = ({
  viewOnlyAccess,
  subscriptionSearch,
  setSubscriptionSearch,
  subscriptionSearchRef,
  goTo,
  Pages,
  setAddSpendSelectedTab,
  setSubscriptionViewInvoiceData,
  getInvoiceDetails,
  setLoading,
  setShowCreateSubcategoryModal,
  parentCategortyData,
  childCategoryData,
  categoryPageData,
  clientId,
  getSubPaymentLastInvoice,
  subscriptionActiveFilters,
  subscriptionViewData,
  setSubscriptionActiveFilters = () => {},
  getSubscriptionViewData = () => {},
}) => {
  const [order, setOrder] = useState(0);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-2 sub-filter-box">
        <div className="f-500 f-grey pl-2">Subscriptions</div>
        <div className="d-flex align-items-center">
          <SearchFilterComponent
            visibilityType={VisibilityType.LIST}
            showLastButton={true}
            searchValue={subscriptionSearch}
            searchOnChange={e => {
              setSubscriptionSearch(e.target.value);
            }}
            passedRef={subscriptionSearchRef}
            setRef={() => {
              subscriptionSearchRef.current.focus()
            }}
            searchOnCross={() => {
              setSubscriptionSearch("");
            }}
            onSortClick={() => {
              setOrder(order === 0 ? 1 : 0);
            }}
            lastButtonChild={(
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <TechspendAddDropdown 
                  goTo={goTo} 
                  Pages={Pages} 
                  setAddSpendSelectedTab={setAddSpendSelectedTab}
                  setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                  getInvoiceDetails={getInvoiceDetails}
                  setLoading={setLoading}
                  setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                  defaultAction={TechspendAddMenuAction.AddSubscription}
                  childCategoryData={childCategoryData}
                  parentCategortyData={parentCategortyData}
                />
              </OverlayTrigger>
            )}
            childFilterItem={(
              filters.map((filterItem, filterItemIndex) => {
                return (
                  <div
                    className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey"
                    onClick={(e) => {
                      let updated_active_filters = [
                        ...subscriptionActiveFilters,
                      ];
                      let indexOfFilter =
                        updated_active_filters.findIndex(
                          (item) =>
                            item?.filterType ===
                            filterItem?.filterData?.filterType
                        );
                      if (indexOfFilter === -1) {
                        setSubscriptionActiveFilters([
                          ...updated_active_filters,
                          { ...filterItem?.filterData },
                        ]);
                      }
                      try {
                        setTimeout(() => {
                          let filter_Component =
                            document.querySelector(".sub-box-techspend");
                          if (filter_Component) {
                            let dropdown =
                              filter_Component.querySelector(
                                `#${filterItem?.filterData?.filterType}_filterX`
                              );
                            if (dropdown) {
                              dropdown
                                .querySelector(".dropdown-toggle")
                                .click();
                            }
                          }
                        }, 100);
                      } catch (err) {}

                      e.target
                        .closest(".transparennt-wrapper-dropdown")
                        .click();
                    }}
                  >
                    <div>{filterItem.filterName}</div>
                    <img
                      src="/images/attack-surface/chevron-right.svg"
                      alt=""
                      className=""
                    />
                  </div>
                );
              })
            )}
          />
        </div>
      </div>
      <HomeScreenSubscriptionFilters
        activeFilters={subscriptionActiveFilters}
        setActiveFilters={setSubscriptionActiveFilters}
        subscriptionViewData={subscriptionViewData}
        onApply={(filters) => {
          getSubscriptionViewData({ filters });
        }}
        className={"sub-box-techspend"}
      />
      {categoryPageData?.isSubscriptionLoading ? (
        <div className="d-flex align-items-center justify-content-center w-100 mt-5 mb-5">
         <GreyLoader width={18} />
       </div>
      ) : !categoryPageData?.SubscriptionList || categoryPageData?.SubscriptionList?.length === 0 ? (
        <SubscriptionsNotFoundUI 
          onAddClick={() => {
            setLoading(true);
            setSubscriptionViewInvoiceData({ newInvoice: true });
            getInvoiceDetails(0, 0, {
              Category_Selected: parentCategortyData,
              isSubscriptionView: true,
              ...(childCategoryData?.objId_Category && {
                SubCategory_Selected: childCategoryData,
              })
            });
            setAddSpendSelectedTab("addBySubscription");
            goTo(
              "Add Subscription",
              Pages.ADD_SPEND,
              {
                currentTab: "addBySubscription",
              }
            );
          }}
        />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '8px' }}>
          {categoryPageData?.SubscriptionList
          ?.sort((a, b) => {
            const aDate = new Date(a?.EndDate?.Year, a?.EndDate?.Month, a?.EndDate?.Day);
            const bDate = new Date(b?.EndDate?.Year, b?.EndDate?.Month, b?.EndDate?.Day);
            return order === 1 ? aDate - bDate : bDate - aDate;
          })
          ?.filter(subscriptionItem => (
            subscriptionItem?.SubscriptionTitle?.toLowerCase().includes(subscriptionSearch.toLowerCase())
          ))
          ?.map((subscriptionItem, index) => {
            const onItemClick = () => {
              setLoading(true);
              setAddSpendSelectedTab("addBySubscription");
              goTo(
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative w-fit-content h-fit-content">
                    <SubscriptionIcon subscriptionItem={subscriptionItem} />
                  </div>
                  {TrimString(
                    subscriptionItem?.SubscriptionTitle,
                    30
                  )}
                </div>,
                Pages.ADD_SPEND,
                {
                  invoiceId: subscriptionItem?.ObjId_Invoice,
                  categoryId: subscriptionItem?.ObjId_Category,
                  subCategoryId: subscriptionItem?.ObjId_SubCategory,
                  invoiceType: "addBySubscription",
                  currentTab: "addBySubscription",
                }
              );
              getSubPaymentLastInvoice({
                clientId,
                invoiceId: subscriptionItem?.ObjId_Invoice
              })
              getInvoiceDetails(
                subscriptionItem?.ObjId_Invoice,
                subscriptionItem?.ObjId_SubCategory > 0
                  ? subscriptionItem?.ObjId_SubCategory
                  : subscriptionItem?.ObjId_Category,
                { isSubscriptionView: true }
              );
            }
            return (
              <SubscriptionCard 
                key={`subscription-${index}`}
                subscriptionItem={subscriptionItem}
                searchValue={subscriptionSearch}
                onClick={onItemClick}
                onEditClick={onItemClick}
                relatedToCategory={(ObjId_SubCategory) => {
                  return categoryPageData?.SubCategory_List?.find(
                    (item) => item?.objId_Category === ObjId_SubCategory
                  ) || categoryPageData?.ID === ObjId_SubCategory;
                }}
              />
            );
          })}
        </div>
      )}
    </>
  )
}

export default SubscriptionsTab;
