import React, { useEffect, useMemo, useState } from "react";
import "./templatesModal.scss";
import { Button } from "react-bootstrap";
import { hexToRGBA, numberWithCommas, renderPriceText } from "../../../mspClientPriceCalculator/MspClientPriceCalculator";
import { useAuth } from "../../../../context/Auth";
import { Radar } from "react-chartjs-2";
import ImportTemplatesModal from "./ImportTemplatesModal";
import { AddButtonForPackage } from "..";
import CyberComplianceGauge from "../mspPriceCalculatorComponents/CyberComplianceGauge";
import CyberScoreGauge from "../mspPriceCalculatorComponents/CyberScoreGauge";
import SwitchButtonMulti from "../../../../Theme/switchButtonMulti";
import MspFeatures from "../mspCalculatorDropdownAndModals/MspFeatures";
import MspComponents from "../mspCalculatorDropdownAndModals/MspComponents";
import MyRadarChart from "../../../../chartjs/my-radar.chart";
import { PackageToolTip, QuestionChip } from "../mspCalculatorDropdownAndModals/MspScoreModal";

const PackageView = ({
    data,
    featuresData
}) => {

    const [componentsData, setComponentsData] = useState([]);

    useEffect(() => {
        setPackageData(data);
        setExchangeRateData({
            Date: data?.CreatedDate,
            FromCurrency: data?.CurrencyCode_Selected?.CurrencyName,
            Rate: data?.ForeignCurrencyRate,
            ToCurrency: data?.CurrencyCode_List?.find((f) => f.IsDefault),
        });
        setComponentsData(
            data?.Nist_All?.flatMap((obj) =>
                Array.isArray(obj?.ScoreContributionSelected_List)
                    ? obj.ScoreContributionSelected_List.filter(item => item?.ContributionName != null)
                    : []
            ).reduce((acc, current) => {
                console.log('acc', acc)
                const existingItem = acc.find(item => item.ContributionName === current.ContributionName);

                if (existingItem) {
                    existingItem.List_ScoreProducts = [
                        ...existingItem.List_ScoreProducts,
                        ...current.List_ScoreProducts.filter(
                            product => !existingItem.List_ScoreProducts.some(
                                existingProduct => existingProduct.objId_Product === product.objId_Product
                            )
                        )
                    ];
                } else {
                    acc.push(current);
                }

                return acc;
            }, [])
        );
    }, [data]);

    const [exchangeRateData, setExchangeRateData] = useState({});
    const [packageData, setPackageData] = useState({});
    const { authTokens } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);
    // const [switchVal, setSwitchVal] = useState(true);

    const [showImportTemplatesModal, setShowImportTemplatesModal] = useState(false);


    return (
        <div className="mt-5">
            <div className="mx-4">
                <div>
                    <div>
                        <div className="f-600 f-14 f-black">{data?.ScenarioHeader?.Name}</div>
                        <div className="f-400 f-14 f-darkgrey">{data?.ScenarioHeader?.Description}</div>

                        <div className="d-flex justify-content-between">


                            <div
                                className="leftBlockDetails"
                            >
                                <div className="my-2">
                                    <div className="f-400 f-14 f-black my-2">Services</div>
                                    <div className="bg-grey border-parent radius-4">
                                        {
                                            packageData?.Nist_All?.map((serviceItem) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="border-item d-flex align-items-center justify-content-between row-padding"
                                                            onClick={() => { }}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    alt=""
                                                                    width={16}
                                                                    height={16}
                                                                    className="mr-2"
                                                                    src='/images/other-apps-icon.svg'
                                                                />
                                                                <div className="d-flex flex-column">
                                                                    <div className="f-14 f-500 f-black">
                                                                        {serviceItem?.Headline}

                                                                    </div>
                                                                    <div className="f-12 f-darkgrey">
                                                                        {serviceItem?.IsAddOn
                                                                            ? "Add-on"
                                                                            : "Service"}{" "}


                                                                        {
                                                                            serviceItem?.AnnualPrice_Text
                                                                        }{" "}
                                                                        {
                                                                            serviceItem
                                                                                ?.SnapToUnit_Selected
                                                                                ?.DDLColour
                                                                        }{" "}
                                                                        /
                                                                        {
                                                                            serviceItem
                                                                                ?.BillingPeriod_Selected
                                                                                ?.DDLColour
                                                                        }{" "}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                    <div>
                                        <div className="f-600 f-14 f-black my-3">Financial Breakdown</div>

                                        <div className="f-400 f-14 f-black my-3">Billing model</div>
                                        <div
                                            className="mr-2"
                                        >
                                            <div className="f-500 f-14 f-black">
                                                {packageData?.ScenarioHeader?.PackageType_Text}
                                            </div>
                                            <div className="f-400 f-12 f-darkgrey">
                                                {packageData?.ScenarioHeader?.PackageType_Enum == 1 ? 'Billing based on clients assets quantities' : 'Billed based on the number of included assets'}
                                            </div>
                                        </div>

                                        <div className="f-500 f-12 f-darkgrey mt-3 mb-1">
                                            Pricing
                                        </div>

                                        <div className="d-flex align-items-center w-100 my-2">
                                            <div className="w-50 f-400 f-14 f-black">
                                                Billing period
                                            </div>
                                            <div className="w-50 f-500 f-14 f-grey">
                                                {packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLText}
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center w-100 my-2">
                                            <div className="w-50 f-400 f-14 f-black">
                                                Currency
                                            </div>
                                            <div className="w-50 f-500 f-14 f-grey">
                                                {packageData?.CurrencyCode_Selected?.CurrencyName}
                                            </div>
                                        </div>

                                        {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                                            <div className="d-flex align-items-center w-100 my-2">
                                                <div className="w-50 f-400 f-14 f-black">
                                                    Seat Cost
                                                </div>
                                                <div className="w-50 f-500 f-14 f-grey">
                                                    {`${packageData?.CurrencyCode_Selected?.CurrencySymbol} ${numberWithCommas(
                                                        (
                                                            packageData?.MSPPackagePrice?.Cost *
                                                            (exchangeRateData?.Rate ?? 1)
                                                        )?.toFixed(2)
                                                    )} /${packageData?.ScenarioHeader
                                                        ?.BillingPeriod_Selected?.DDLColour
                                                        }`}
                                                </div>
                                            </div>
                                        }

                                        {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                                            <div className="d-flex align-items-center w-100 my-2">
                                                <div className="w-50 f-400 f-14 f-black">
                                                    Seat RRP
                                                </div>
                                                <div className="w-50 f-500 f-14 f-grey">
                                                    {`${packageData?.CurrencyCode_Selected?.CurrencySymbol} ${numberWithCommas(
                                                        (
                                                            packageData?.MSPPackagePrice?.RRP *
                                                            (exchangeRateData?.Rate ?? 1)
                                                        )?.toFixed(2)
                                                    )} /${packageData?.ScenarioHeader
                                                        ?.BillingPeriod_Selected?.DDLColour
                                                        }`}
                                                </div>
                                            </div>
                                        }

                                        {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                                            <div className="d-flex align-items-center w-100 my-2">
                                                <div className="w-50 f-400 f-14 f-black">
                                                    Seat Price
                                                </div>
                                                <div className="w-50 f-500 f-14 f-grey">
                                                    {`${packageData?.CurrencyCode_Selected?.CurrencySymbol} ${numberWithCommas(
                                                        (
                                                            packageData?.MSPPackagePrice?.SellOverridden *
                                                            (exchangeRateData?.Rate ?? 1)
                                                        )?.toFixed(2)
                                                    )} /${packageData?.ScenarioHeader
                                                        ?.BillingPeriod_Selected?.DDLColour
                                                        }`}
                                                </div>
                                            </div>
                                        }

                                        {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                                            <div className="d-flex align-items-center w-100 my-2">
                                                <div className="w-50 f-400 f-14 f-black">
                                                    Seat Margin
                                                </div>
                                                <div className="w-50 f-500 f-14 f-grey">
                                                    {`${packageData?.CurrencyCode_Selected?.CurrencySymbol} ${numberWithCommas(
                                                        (
                                                            packageData?.MSPPackagePrice?.SellOverridden -
                                                            packageData?.MSPPackagePrice?.Cost
                                                        ).toFixed(2)
                                                    )} /${packageData?.ScenarioHeader
                                                        ?.BillingPeriod_Selected?.DDLColour
                                                        }`}
                                                </div>
                                            </div>
                                        }

                                        <div className="d-flex align-items-center w-100 my-2">
                                            <div className="w-50 f-400 f-14 f-black">
                                                Tax rate
                                            </div>
                                            <div className="w-50 f-500 f-14 f-grey">
                                                {`${packageData?.TaxRates_Selected?.TaxName ?? 'Not Taxable'} (${packageData?.TaxRates_Selected?.TaxRate ?? 0}%)`}
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        packageData?.ScenarioHeader?.PackageType_Enum == 1 ?
                                            <div className="">
                                                <div
                                                    className="w-100 d-flex align-items-center justify-content-between p-2 bg-hov er-grey radius-4 mt-2"
                                                >
                                                    <div className="d-flex align-items-center f-500 f-12 f-darkgrey">
                                                        Package Pricing
                                                    </div>
                                                </div>
                                                <div
                                                    className="border-parent  radius-8 "
                                                >
                                                    <div
                                                        className={`border-item f-12 f-500 f-darkgrey p-2 per_asset_period-breakdown_table`}
                                                    >
                                                        <div className="f-500 f-12 f-darkgrey">Asset Type</div>
                                                        <div className="f-500 f-12 f-darkgrey">Cost </div>
                                                        <div className="f-500 f-12 f-darkgrey">Sell</div>
                                                        <div className="f-500 f-12 f-darkgrey">Margin</div>
                                                    </div>
                                                    {
                                                        packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                                                            (item) => item.UnitCost !== 0
                                                        )?.length > 0 ? <div>

                                                            {packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                                                                (item) => item.UnitCost !== 0
                                                            )?.map((data, dataIndex) => {
                                                                return (
                                                                    <div
                                                                        className={`border-item f-grey p-2 per_asset_period-breakdown_table`}
                                                                    >
                                                                        <div className="f-black f-500">
                                                                            {data.Name?.replace("Per ", "")}
                                                                        </div>
                                                                        <div>
                                                                            <div>
                                                                                {packageData?.CurrencyCode_Selected?.CurrencySymbol}
                                                                                {(
                                                                                    data?.UnitCost *
                                                                                    (exchangeRateData?.Rate ?? 1)
                                                                                )?.toFixed(2)}
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            {
                                                                                packageData?.CurrencyCode_Selected?.CurrencySymbol
                                                                            }
                                                                            {(
                                                                                data.UnitSell *
                                                                                exchangeRateData?.Rate
                                                                            )?.toFixed(2)}{" "}
                                                                        </div>

                                                                        <div className="f-500 f-grey">
                                                                            {renderPriceText(
                                                                                99,
                                                                                (
                                                                                    (data.UnitSell - data.UnitCost) *
                                                                                    (exchangeRateData?.Rate ?? 1)
                                                                                )?.toFixed(2),
                                                                                packageData?.CurrencyCode_Selected?.CurrencySymbol
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div> : <div
                                                            className="f-400 f-12 f-darkgrey p-2"
                                                        >
                                                            No data yet
                                                        </div>
                                                    }
                                                </div>
                                            </div> : <div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between"
                                                >
                                                    <div
                                                        className={`my-2 f-12 f-darkgrey d-flex align-items-center`}
                                                    >
                                                        Seat includes
                                                    </div>
                                                </div>

                                                <div
                                                    className="border-parent  radius-8"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <div className="per_seat_includes_table border-item f-grey">
                                                        <div className="f-500 f-12 f-darkgrey pl-2">Asset Type</div>
                                                        <div className="f-500 f-12 f-darkgrey">Qnt.</div>
                                                    </div>
                                                    {packageData?.DefaultPackagePerSeat_List?.map(
                                                        (item, index) => {
                                                            return (
                                                                <div className="per_seat_includes_table border-item f-grey px-2">
                                                                    <div className="f-400 f-14 f-black flex-shrink-0">
                                                                        {item?.Name?.replace("Per ", "")}
                                                                    </div>
                                                                    <div className="f-500 f-grey">
                                                                        {item?.PerSeat}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                    }


                                    <div>
                                        <div className="f-500 f-12 f-grey mt-2">
                                            Margin
                                        </div>
                                        <div
                                            className="border-block  radius-8 mt-2"
                                        >
                                            <div className={` border-item f-grey per_asset_period-breakdown_table`}
                                            >
                                                <div className="  pl-2">Period</div>
                                                <div>Cost</div>
                                                <div>Sell</div>
                                                <div>Margin</div>
                                            </div>

                                            {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                                                (data, index) => {
                                                    return (
                                                        <div className={`  border-item per_asset_period-breakdown_table`}
                                                        >
                                                            <div className="f-500  pl-2">
                                                                {data?.Name}{" "}
                                                            </div>
                                                            <div className="f-500 f-grey">
                                                                {renderPriceText(
                                                                    99,
                                                                    (
                                                                        data?.Cost *
                                                                        (exchangeRateData?.Rate ?? 1)
                                                                    )?.toFixed(2),
                                                                    data?.CurrencyCode_Selected?.CurrencySymbol
                                                                )}
                                                            </div>
                                                            <div className="f-500 f-grey">
                                                                {renderPriceText(
                                                                    99,
                                                                    (
                                                                        data?.Sell *
                                                                        (exchangeRateData?.Rate ?? 1)
                                                                    )?.toFixed(2),
                                                                    data?.CurrencyCode_Selected?.CurrencySymbol
                                                                )}
                                                            </div>
                                                            <div className="f-500 f-grey">
                                                                {renderPriceText(
                                                                    99,
                                                                    (
                                                                        data?.Margin *
                                                                        (exchangeRateData?.Rate ?? 1)
                                                                    )?.toFixed(2),
                                                                    data?.CurrencyCode_Selected?.CurrencySymbol
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="f-600 f-14 f-black my-2">
                                            Included Features
                                        </div>

                                        {
                                            packageData?.Nist_All?.length > 0 ?
                                                <div className="border-parent  radius-8">
                                                    <div className="features_table_templates border-item px-2 py-1">
                                                        <div className="f-500 f-12 f-darkgrey">Service</div>
                                                        <div className="f-500 f-12 f-darkgrey">Features</div>
                                                    </div>
                                                    {
                                                        packageData?.Nist_All?.map((item) => {
                                                            return (
                                                                <div className="features_table_templates border-item py-1">
                                                                    <div>
                                                                        <div className="d-flex px-2">
                                                                            <img
                                                                                alt=""
                                                                                width={14}
                                                                                height={14}
                                                                                className="mx-2 mt-1"
                                                                                src='/images/other-apps-icon.svg'
                                                                            />
                                                                            <div>
                                                                                <div className="f-400 f-14 f-black">
                                                                                    {item?.Headline}
                                                                                </div>
                                                                                <div className="f-12 f-darkgrey"
                                                                                    style={{
                                                                                        marginLeft: '26px'
                                                                                    }}
                                                                                >
                                                                                    {item?.SecurityPillar_Selected?.DDLText && `${item?.SecurityPillar_Selected?.DDLText} • `}
                                                                                    {
                                                                                        item?.MonthlyPrice_Text
                                                                                    }{" "}
                                                                                    {
                                                                                        item
                                                                                            ?.SnapToUnit_Selected
                                                                                            ?.DDLColour
                                                                                    }{" "}
                                                                                    /
                                                                                    {
                                                                                        item
                                                                                            ?.BillingPeriod_Selected
                                                                                            ?.DDLColour
                                                                                    }{" "}
                                                                                    <span className="f-12 txt-blue">
                                                                                        {item?.IsOnDemand ? ' • POD' : ''}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {item?.SecurityPack_Bullet_List?.length > 0 ? (
                                                                            <>
                                                                                {item?.SecurityPack_Bullet_List?.map((item) => {
                                                                                    return (
                                                                                        <div className="d-flex align-items-center my-1 f-12 f-400 f-black">
                                                                                            <img
                                                                                                alt=""
                                                                                                className="ml-1 mr-2"
                                                                                                src="/images/msp/green-circular-tick.svg"
                                                                                            />
                                                                                            {item?.Text}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </>
                                                                        ) : (
                                                                            <div className="d-flex align-items-center my-2 f-grey f-12">
                                                                                This service has no features
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> : <div
                                                    className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                                                >
                                                    No data yet
                                                </div>
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <div className="f-600 f-14 f-black my-2">
                                            Service Components
                                        </div>
                                        <MspComponents
                                            componentsData={componentsData}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <div className="f-600 f-14 f-black my-2">
                                            Alignment to NIST CSF 2.0 Framework
                                        </div>

                                        {
                                            featuresData?.length > 0 ?
                                                <div className="border-block w-100">
                                                    {
                                                        featuresData?.map((featureItem, featureIndex) => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        featureItem?.Question_List?.filter(question =>
                                                                            question?.AnswerId !== 0 && question?.AnswerBool === true
                                                                        )?.length > 0 && <div className="w-100">
                                                                            <div className={`f-500 f-14 f-black bg-grey px-2 py-1 ${featureIndex < featuresData?.length - 1 ? 'border-item' : ''
                                                                                } ${featureIndex > 0 ? 'border-top-item' : ''}`}>
                                                                                {featureItem?.Title}
                                                                            </div>
                                                                            <div className="">
                                                                                {
                                                                                    featureItem?.Question_List?.map((question, questionIndex) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {question?.AnswerId !== 0 &&
                                                                                                    question?.AnswerBool === true && (
                                                                                                        <div className={`d-flex ${questionIndex < featureItem?.Question_List?.filter(question =>
                                                                                                            question?.AnswerId !== 0 && question?.AnswerBool === true
                                                                                                        )?.length - 1 ? 'border-item' : ''
                                                                                                            }`}>
                                                                                                            <img
                                                                                                                alt=""
                                                                                                                height={14}
                                                                                                                className="ml-2 my-3"
                                                                                                                src="/images/msp/green-circular-tick.svg"
                                                                                                            />
                                                                                                            <>
                                                                                                                {question?.InheritedText && (
                                                                                                                    <div className="table-title radius-4 f-500">
                                                                                                                        <div className="img">
                                                                                                                            <img
                                                                                                                                alt=""
                                                                                                                                className=" "
                                                                                                                                src="/images/msp/arrow-enter.svg"
                                                                                                                            />
                                                                                                                        </div>

                                                                                                                        <div className="title f-darkgrey">
                                                                                                                            Inherited from `
                                                                                                                            {question?.InheritedText}`{" "}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <div
                                                                                                                    className=""
                                                                                                                    style={{ padding: "12px" }}
                                                                                                                >
                                                                                                                    <div className="d-flex align-items-start justify-content-between">
                                                                                                                        <div>
                                                                                                                            {question.Explanation}

                                                                                                                            <div className="d-flex flex-wrap mt-2">
                                                                                                                                {
                                                                                                                                    question?.nistType &&
                                                                                                                                    <div
                                                                                                                                        className='px-2 rounded-full d-flex align-items-center border1 f-grey bg-greyish f-12 f-500 mr-2'
                                                                                                                                    >
                                                                                                                                        {question?.nistType}
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                                {question?.NISTAssetClassification_List?.map(
                                                                                                                                    (chip, chipIndex) => {
                                                                                                                                        return (
                                                                                                                                            <div className="position-relative tooltip-parent">
                                                                                                                                                <QuestionChip
                                                                                                                                                    iconUrl={
                                                                                                                                                        chip.IsFramework
                                                                                                                                                            ? "/images/msp/msp-framework-icon.svg"
                                                                                                                                                            : null
                                                                                                                                                    }
                                                                                                                                                    title={chip?.Name}
                                                                                                                                                    className={"mr-2"}
                                                                                                                                                    IsBlue={
                                                                                                                                                        chip?.Name?.toLowerCase() ===
                                                                                                                                                        "best practice"
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                                {!chip.IsFramework && (
                                                                                                                                                    <PackageToolTip
                                                                                                                                                        content={chip?.Detail}
                                                                                                                                                    />
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                        );
                                                                                                                                    }
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> : <div
                                                    className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                                                >
                                                    No data yet
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>


                            <div className="rightBlockDetails h-fit-content">
                                <div className="py-2 px-3">
                                    <div className="f-500 f-14 f-grey my-1">
                                        Security Posture
                                    </div>
                                    <div className=" d-flex justify-content-center">
                                        <MspClientPriceCalculatorEmptyRadarChart
                                            dataList={[
                                                packageData?.ScenarioHeader?.InspectScore,
                                                packageData?.ScenarioHeader?.ProtectScore,
                                                packageData?.ScenarioHeader?.DetectScore,
                                                packageData?.ScenarioHeader?.RespondScore,
                                                packageData?.ScenarioHeader?.RecoverScore,
                                            ]}
                                            passedWidth={355}
                                            passedHeight={336}
                                            colorPassed={"#5244B3"}
                                        />
                                    </div>
                                </div>

                                <div className="border-top p-3 d-flex justify-content-between">
                                    <div>
                                        <div className="f-500 f-14 f-grey">
                                            Predicted CyberResilience
                                        </div>
                                        <div className="f-500 f-18 f-black py-1">
                                            {packageData?.ScenarioHeader?.CyberCompliance}%
                                        </div>
                                    </div>
                                    <CyberComplianceGauge
                                        value={packageData?.ScenarioHeader?.CyberCompliance}
                                        showTitle={false}
                                    />
                                </div>

                                <div className="border-top p-3 d-flex justify-content-between">
                                    <div>
                                        <div className="f-500 f-14 f-grey">
                                            Predicted CyberScore
                                        </div>
                                        <div className="f-500 f-18 f-black py-1">
                                            {packageData?.ScenarioHeader?.CyberScore}
                                        </div>
                                    </div>
                                    <CyberScoreGauge
                                        value={packageData?.ScenarioHeader?.CyberScore}
                                        showTitle={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <ImportTemplatesModal
                show={showImportTemplatesModal}
            />
        </div>
    );
};

export default PackageView;


export const MspClientPriceCalculatorEmptyRadarChart = ({
    dataList,
    colorPassed,
    passedWidth,
    passedHeight,
    passedMarginBottom
}) => {
    const updatedData = useMemo(() => {
        return {
            labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

            datasets: [
                {
                    data: dataList ?? [0, 0, 0, 0, 0],
                    pointRadius: [0, 0, 0, 0, 0],
                    datalabels: {
                        display: false,
                    },
                    backgroundColor: colorPassed ? hexToRGBA(colorPassed, 0.24) : "rgba(0, 143, 212, 0.24)",
                    // backgroundColor: `${"#5244B3"}30`,
                    borderColor: colorPassed ?? `${"#008FD4"}`, // "rgba(0, 143, 212, 1)",
                    pointBackgroundColor: colorPassed ?? "rgba(0, 143, 212, 1)",
                    borderWidth: 1,
                    pointLabelFontSize: 0,
                    scaleShowLabels: false,
                    label: "Current Posture",
                },
            ],
        };
    }, [dataList]);
    const updatedOptions = useMemo(() => {
        return {
            animation: {
                duration: 1, // 4000, // Set the duration of the transition in milliseconds
            },
            responsive: false,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },

            tooltips: {
                enabled: true,
            },
            scales: {
                r: {
                    beginAtZero: false,
                    max: /*40*/50,
                    min: 0,
                    ticks: {
                        display: false,
                        stepSize: 10,
                        backdropColor: "rgba(0, 0, 0, 0)",
                    },
                    pointLabels: {
                        display: false,
                        fontSize: 16,
                        fontFamily: "Archivo, sans-serif",
                        fontColor: "#101010",
                    },
                },
            },
            elements: {
                point: {
                    radius: 2,
                    hoverRadius: 4,
                },
            },
        };
        // return opts;
    }, []);

    return (
        <div
            className="zoom-independent position-relative"
            style={{
                width: "350px",
                height: "350px",
                marginTop: "60px",
                marginBottom: passedMarginBottom ?? '40px',
                // backgroundColor: "#f0f0f0", // Light gray background
            }}
        >
            <MyRadarChart
                id="actial-radar"
                redraw={true}
                height={345}
                width={345}
                style={{ margin: "auto" }}
                data={updatedData}
                options={updatedOptions}
            />
            {/* ... existing code */}
            <div
                className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
                style={{
                    top: "-24px",
                    left: "173px",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
                    Identify
                </div>

                <div
                    className="w-fit-content h-fit-content "
                    style={{
                        color: (dataList[0] == 0 || dataList[0] == undefined) ? `rgba(187, 190, 201, 1)` : colorPassed ?? `${"#008FD4"}`,
                    }}
                >
                    {dataList[0] ?? 0}
                </div>
            </div>

            <div
                className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
                style={{
                    right: "-40px",
                    top: "calc(150px - 4rem)",
                }}
            >
                <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
                    Protect
                </div>

                <div
                    className="w-fit-content h-fit-content "
                    style={{
                        color: (dataList[1] == 0 || dataList[1] == undefined) ? `rgba(187, 190, 201, 1)` : colorPassed ?? `${"#008FD4"}`,
                    }}
                >
                    {dataList[1] ?? 0}
                </div>
            </div>

            <div
                className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
                style={{
                    right: "calc(58px - 3rem)",
                    bottom: "calc(53px - 4rem)",
                }}
            >
                <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
                    Detect
                </div>

                <div
                    className="w-fit-content h-fit-content "
                    style={{
                        color: (dataList[2] == 0 || dataList[2] == undefined) ? `rgba(187, 190, 201, 1)` : colorPassed ?? `${"#008FD4"}`,
                    }}
                >
                    {dataList[2] ?? 0}
                </div>
            </div>

            <div
                className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
                style={{
                    right: "calc(356px - 4.5rem)",
                    bottom: "calc(53px - 4rem)",
                }}
            >
                <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
                    Respond
                </div>

                <div
                    className="w-fit-content h-fit-content "
                    style={{
                        color: (dataList[3] == 0 || dataList[3] == undefined) ? `rgba(187, 190, 201, 1)` : colorPassed ?? `${"#008FD4"}`,
                    }}
                >
                    {dataList[3] ?? 0}
                </div>
            </div>

            <div
                className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
                style={{
                    left: "calc(-13px - 2.5rem)",
                    top: "calc(150px - 4rem)",
                }}
            >
                <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
                    Recover
                </div>

                <div
                    className="w-fit-content h-fit-content "
                    style={{
                        color: (dataList[4] == 0 || dataList[4] == undefined) ? `rgba(187, 190, 201, 1)` : colorPassed ?? `${"#008FD4"}`,
                    }}
                >
                    {dataList[4] ?? 0}
                </div>
            </div>
        </div>
    );
};