import { BASE_API_ROUTES } from "../../constants/apis.constants";
import httpUtils from "../../Utils/http.utils";
import userUtils from "../../Utils/user.utils";
import useBaseActions from "./base.actions";

const useTechSpendActions = () => {
  const { onError, onSuccess, sendRequest } = useBaseActions();

  const getSubscriptionLastInvoiceDate = ({
    clientId,
    invoiceId
  }) => {
    const query = httpUtils.generateQueryString({
      accessKey: userUtils.getUserMessage(),
      objId_Customer: clientId,
      objId_Invoice: invoiceId
    });
    const path = `${BASE_API_ROUTES.getSubscriptionLastInvoiceDate}${query}`;
    return sendRequest(path);
  }

  return {
    getSubscriptionLastInvoiceDate
  }
}

export default useTechSpendActions;
