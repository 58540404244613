import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../Header";
import ConnectedTools from "./ConnectedTools";
import AppMarketPlace from "./AppMarketPlace";
import "./integrationsv2.scss";
import IntegrationDetailsPage from "./IntegrationDetailsPage";
import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import SuggestIntegrationModal from "./SuggestIntegrationModal";
import { useHistory } from "react-router-dom";
import { TrackUser } from "../../Utils/SegmentFunctions";

import { NO_ACCESS_TEXT, NoAccessUI, getAccessStateValue, viewOnlyAccess } from "../App/App";
import { getMenuItemId } from "../SideNav/SideNav";
import SearchFilterComponent from "../SearchFilterComponent/SearchFilterComponent";
import AppliedFiltersComponent from "../SearchFilterComponent/AppliedFiltersComponent";
import DisabledButtonWithToolTip from "../Theme/DisabledButtonWithToolTip";
import CrossIcon from "../icons/cross.icon";

const IntegrationsV2 = () => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  // const [mainPageLoading, setMainPageLoading] = useState(false);
  const userDetails = useSelector((state) => state.user?.userDetail);
  let selectedTab = new URLSearchParams(window.location.search).get(
    "selectedTab"
  );
  const history = useHistory();
  const [sortAsc, setSortAsc] = useState(true);
  const [showRecommendedList, setShowRecommendedList] = useState(true);
  const [showComingSoonlist, setShowComingSoonlist] = useState(true);

  const appMarketPlace = useRef(null);
  const [connectIntegrationModal, setConnectIntegrationModal] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [suggestIntegrationModal, setSuggestIntegrationModal] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] =
    useState("appMarketPlace");
  const [page, setPage] = useState({
    type: "home",
    name: "",
    data: {},
  });
  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip_access" id="tooltip">
      This feature is managed by your service provider
    </Tooltip>
  );

  const menuItemId = getMenuItemId(window.location.pathname)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;


  const SearchInput = useRef();
  const [searchInput, setSearchInput] = useState(false);
  const [selectedFilterList, setSelectedFilterList] = useState([]);
  const filterDataOptions = [
    {
      id: 0,
      title: 'Type',
      data: [
        {
          id: 1,
          type: "Recommended",
        },
        {
          id: 2,
          type: "Coming soon",
        }]
    },
  ]

  return (
    <>
      {
        menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_Integrations === 0) ? <>
          {
            userPortalRole === 'MSP' ?
              <NoAccessUI /> : <NoAccessUI type={userPortalRole} />
          }
        </> : <>
          <React.Fragment>
            <Row className="fullHeight integrationsV2 m-0" id="integration-v2-screen">
              <Col xs={12} className="px-0">
                <div>
                  <header
                    className="d-flex justify-content-between navbar sticky-top pb-0"
                    style={{
                      paddingTop: ` ${"0.8em"
                        } `,
                      background: "#FFFFFF",
                      position: "fixed",
                      width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                      zIndex: 6,

                      paddingLeft: "2rem",
                      // borderBottom: `1px solid #EBEDF1`,
                      paddingBottom: "8px",
                    }}
                  >
                    <h3 className="f-16 f-600">Integrations</h3>

                    <Header />
                  </header>
                </div>
                <div style={{ paddingTop: "5%" }}></div>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={selectedTab ?? defaultSelectedTab}
                >
                  <Row>
                    <Col
                      sm={12}
                      className={`tab-header d-flex  justify-content-between align-items-center  ${false}  `}
                    >
                      {/* Home screen left part */}
                      {page.type === "home" && (
                        <div className={`${true}`} style={{ marginLeft: "15px" }}>
                          <Nav
                            variant="pills"
                            className="flex-row"
                            onSelect={(e) => {
                              setDefaultSelectedTab(e);

                              history.push("/integrations");
                            }}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="connectedTools" onClick={() => { }}>
                                Connected Tools
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item id="app-market-place">
                              <Nav.Link eventKey="appMarketPlace" onClick={() => { }}>
                                <img
                                  alt=""
                                  src="/images/icons/appmarketplace-icon.svg"
                                  className="mr-1"
                                />{" "}
                                App Marketplace
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      )}
                      {page.type === "integrationDetails" && (
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center pointer"
                            onClick={() => {
                              setPage({ type: "home", name: "" });
                            }}
                          >
                            <img
                              alt=""
                              src="/images/icons/arrowLeft.svg"
                              className="pointer ml-3"
                            />
                            <div className="f-grey f-500 ml-3 pointer">Back</div>
                          </div>

                          <div className="ml-4 f-16 f-500 f-black">{page.name}</div>
                        </div>
                      )}

                      {/* Right side items at top bar */}
                      {(defaultSelectedTab === "appMarketPlace" ||
                        selectedTab === "appMarketPlace") &&
                        page.type === "home" && (
                          <SearchFilterComponent
                            leftSide={<div className="f-16 f-600">
                            </div>}
                            passedMainStyles={''}
                            searchValue={searchString}
                            searchOnChange={(e) => {
                              setSearchInput(true);
                              setSearchString(e.target.value);
                            }}
                            searchOnCross={() => {
                              setSearchString("");
                            }}
                            passedRef={SearchInput}
                            setRef={() => {
                              SearchInput.current.focus()
                            }}
                            onSortClick={() => {
                              setSortAsc(!sortAsc);
                            }}
                            lastButtonChild={<div>
                              {getAccessStateValue("AccessState_Integrations") > 2 ? <Button
                                className="hg-cancel-btn"
                                onClick={() => {
                                  setSuggestIntegrationModal(true);
                                  TrackUser("Suggest Integration clicked");
                                }}
                              >
                                <img
                                  alt=""
                                  src="/images/attack-surface/request-tool-icon.svg"
                                  className="mr-2"
                                />
                                Request a tool
                              </Button> :


                                <DisabledButtonWithToolTip
                                  buttonText={"Request a tool"}
                                  noAccessText={NO_ACCESS_TEXT}
                                />

                              }
                            </div>}
                            passedFilterList={filterDataOptions}
                            childFilterItem={<div>
                              {
                                filterDataOptions?.map((filterItem) => {
                                  return (
                                    <div className="d-flex align-items-center bg-hover-grey pointer"
                                      onClick={(e) => {
                                        setSelectedFilterList(prevList => {
                                          const newList = [...prevList];
                                          const index = newList.findIndex(item => item?.id === filterItem?.id);
                                          if (index !== -1) {
                                            newList.splice(index, 1);
                                          } else {
                                            newList.push(filterItem);
                                          }
                                          console.log(newList);
                                          return newList;
                                        });

                                        e.target
                                          .closest(".transparennt-wrapper-dropdown")
                                          .click();
                                      }}
                                    >
                                      {
                                        selectedFilterList?.some(option => option?.id === filterItem?.id) ?
                                          <img
                                            alt=""
                                            src="/images/msp/dropdown-grey-tick.svg"
                                          /> : <div style={{
                                            width: '12px'
                                          }}></div>
                                      }
                                      <div className="f-400 f-14 f-grey p-1 ml-2">
                                        {filterItem?.title}
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>}
                          />
                        )}
                    </Col>

                    <div className="w-100"
                      style={{
                        marginLeft: '60px',
                        marginRight: '60px',
                        marginTop: '10px',
                        marginBottom: '-20px'
                      }}
                    >
                      {
                        selectedFilterList?.length > 0 &&
                        <AppliedFiltersComponent
                          childAppliedFilterItem={
                            <div className="d-flex align-items-center">
                              {
                                selectedFilterList?.map((filterItem) => {
                                  const hasSelectedItems = showRecommendedList || showComingSoonlist;
                                  return (
                                    <div className={`d-flex align-items-center py-1 px-2 mr-2 mb-3 rounded-full applied-filter-item ${hasSelectedItems ? 'has-value ' : ''}`}>
                                      <Dropdown className="transparennt-wrapper-dropdown">
                                        <Dropdown.Toggle className="d-flex align-items-center">
                                          <div
                                            className="f-500 f-12 text pointer"
                                          >
                                            {filterItem?.title}
                                          </div>
                                          {
                                            filterItem?.data?.map((filterDataOptionItem) => {
                                              return (
                                                <div>
                                                  {
                                                    (filterItem?.id == 0 ?
                                                      ((showRecommendedList && filterDataOptionItem?.id == 1) || (showComingSoonlist && filterDataOptionItem?.id == 2)) : false) &&
                                                    <div className={`f-500 f-12 text rounded-full pointer`}>
                                                      {(showRecommendedList && showComingSoonlist) ? (filterDataOptionItem?.id == 1 ? ': ' : ', ') : ': '}{filterDataOptionItem?.type}
                                                    </div>
                                                  }
                                                </div>
                                              )
                                            })
                                          }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            filterItem?.data?.map((filterDataOptionItem) => {
                                              return (
                                                <div
                                                  className="p-1 d-flex align-items-center bg-hover-grey pointer"
                                                  style={{
                                                    width: '200px'
                                                  }}
                                                  onClick={(e) => {
                                                    if (filterItem?.id == 0) {
                                                      if (filterDataOptionItem?.id == 1) {
                                                        setShowRecommendedList(!showRecommendedList);
                                                      } else {
                                                        setShowComingSoonlist(!showComingSoonlist);
                                                      }
                                                    }

                                                    e.target
                                                      .closest(".transparennt-wrapper-dropdown")
                                                      .click();
                                                  }}
                                                >
                                                  <img
                                                    alt=""
                                                    src="/images/msp/dropdown-grey-tick.svg"
                                                    className="mx-1"
                                                    style={{
                                                      visibility: `${(filterItem?.id == 0 ?
                                                        ((showRecommendedList && filterDataOptionItem?.id == 1) || (showComingSoonlist && filterDataOptionItem?.id == 2)) : false)
                                                        ? "visible"
                                                        : "hidden"
                                                        }`,
                                                    }}
                                                  />
                                                  <div className="f-400 f-14 f-grey">

                                                    {filterDataOptionItem?.type}
                                                  </div>
                                                </div>
                                              )
                                            })
                                          }

                                        </Dropdown.Menu>
                                      </Dropdown>
                                      <CrossIcon 
                                        className="pointer ml-2 cross-icon"
                                        width={9}
                                        height={9}
                                        onClick={(e) => {
                                          if (filterItem?.id == 0) {
                                            setShowRecommendedList(false);
                                            setShowComingSoonlist(false);
                                          }

                                          let filterUpd = selectedFilterList?.filter(item => item?.id !== filterItem?.id)
                                          setSelectedFilterList(filterUpd);
                                        }}
                                      />
                                    </div>
                                  )
                                })
                              }
                            </div>
                          }
                          onResetClick={() => {
                            setShowRecommendedList(false);
                            setShowComingSoonlist(false);

                            setSelectedFilterList([]);
                          }}
                        />
                      }
                    </div>

                    {page.type === "home" && (
                      <Col sm={12}>
                        <Tab.Content>
                          {/* Suppliers */}
                          <Tab.Pane eventKey="connectedTools">
                            {(defaultSelectedTab === "connectedTools" ||
                              selectedTab === "connectedTools") && <ConnectedTools />}
                          </Tab.Pane>
                          <Tab.Pane eventKey="appMarketPlace">
                            {(defaultSelectedTab === "appMarketPlace" ||
                              selectedTab === "appMarketPlace") && (
                                <AppMarketPlace
                                  searchString={searchString}
                                  ref={appMarketPlace}
                                  sortAsc={sortAsc}
                                  showRecommendedList={showRecommendedList}
                                  showComingSoonlist={showComingSoonlist}

                                />
                              )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    )}

                    {page.type === "integrationDetails" && (
                      <Col sm={12}>
                        <IntegrationDetailsPage />
                      </Col>
                    )}
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
            <ConnectIntegrationModal
              show={connectIntegrationModal}
              hideModal={() => setConnectIntegrationModal(false)}

            />
            <SuggestIntegrationModal
              show={suggestIntegrationModal}
              hideModal={() => setSuggestIntegrationModal(false)}

            />
          </React.Fragment>
        </>
      }
    </>
  );
};

export default IntegrationsV2;
