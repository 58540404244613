import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import MspMappingDropdownSearchInput from "./mspMappingScreens/mspMappingComponents/mspMappingDropdownSearchInput";
import ContentLoader from "react-content-loader";

import { GreyLoader } from "../../Theme/APILoaders";
import DotsLoader from "../../../Utils/dotsLoader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { HGHighlightText, viewOnlyAccess } from "../../App/App";
import ScrollableDivWithShadow from "../MspAttackSurface.js/ScrollableDivWithShadow";
import SlidingModal from "../../CustomModal/SlidingModal";
import { getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import MappedUsingAI from "./mapped-using-ai/mapped-using-ai.component";
import MagicIcon from "../../icons/magic.icon";
import ThumbUpIcon from "../../icons/thumb-up.icon";
import ThumbDownIcon from "../../icons/thumb-down.icon";
import useConfigurationMappingList from "./configuration-mapping-list.data";
import CrossIcon from "../../icons/cross.icon";
import ThreeDotsIcon from "../../icons/three-dots.icon";
import useIntegrationActions from "../../../hooks/actions/integrations.actions";

const MapAssets = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  updateMemberData,
  updateImportStatus,
  clientPageData,
  IntegrationId
}) => {
  const [loading, setLoading] = useState(false);
  const [fetchingNewMembers, setFetchingNewMembers] = useState(false);
  const [memberSearchString, setMemberSearchString] = useState("");
  const [searchTimer, setSearchTimer] = useState();
  const [searchLoading, setSearchLoading] = useState(false);

  const [automaticallyImportStatus, setAutomaticallyImportStatus] =
    useState(false);
  const [importStatus, setImportStatus] = useState(false);
  const [assetsSearchString, setAssetsSearchString] = useState("");
  const [assetsData, setAssetsData] = useState();
  const { authTokens } = useAuth();

  useEffect(() => {
    setLoading(true);
    setAssetsData([])
    Promise.all([
      getAssetsMappingListData({
        NextPageNo: 1,
        searchString: assetsSearchString ?? "",
      })
    ])
      .then((response) => {
        setLoading(false);
        setAutomaticallyImportStatus(response?.[0]?.AutoImport);
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  }, []);

  async function getMoreAssetsData(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (assetsData?.NextPage === false) {
          return
        } else {
          let element = document.getElementById("mainBody");
          element.removeEventListener("scroll", getMoreAssetsData);

          setFetchingNewMembers(true);
          getAssetsMappingListData({
            NextPageNo: assetsData?.NextPageNo,
            searchString: assetsSearchString,
          }).then(() => {
            setFetchingNewMembers(false);
          });
        }

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  async function getAssetsMappingListData(kwargs) {
    setSearchLoading(true);
    const NextPageNo = kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
    ? kwargs?.NextPageNo
    : clientPageData?.NextPageNo !== null &&
      clientPageData?.NextPageNo !== undefined
      ? clientPageData?.NextPageNo
      : 1;
    if (kwargs?.updateIndex !== null && kwargs?.updateIndex !== undefined) {
      let newAssetsData = [...assetsData?.ObjectList];
      newAssetsData[kwargs?.updateIndex] = {
        ...newAssetsData[kwargs?.updateIndex],
        ...(kwargs?.sellOrAse === 1 ? {
          loadingSell: true
        } : {
          loadingASE: true
        })
      };
      setAssetsData(prev => ({
        ...prev,
        ObjectList: newAssetsData
      }))
    }
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPSAConfigurationMapping?optionEnum1=${6}&optionEnum2=${NextPageNo}&optionEnum3=${30}&optionEnum4=${IntegrationId}&optionEnum5=${kwargs?.hideIgnored ?? 0}&optionStrEnum1=${kwargs?.searchString ?? ""}&optionEnum99=${1}&accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
      getRequestOptions(authTokens)
    ).then((response) => response.json());
    setSearchLoading(false);

    if (data?.mr?.Success) {
      let updated_data = null;
      setImportStatus(data?.detail?.ShowAssets);
      const startIndex = assetsData?.ObjectList?.findIndex(item => item.NextPageNo === NextPageNo);
      if ((startIndex !== -1 && assetsData?.ObjectList?.length > 0) || (kwargs?.NextPageNo > 1 && assetsData?.ObjectList && assetsData?.ObjectList?.length > 0)) {
        let newAssetsData = [...assetsData?.ObjectList];
        let updateState = false;
        if (startIndex !== -1) {
          const endIndex = assetsData?.ObjectList?.findLastIndex(item => item.NextPageNo === NextPageNo);
          newAssetsData.splice(startIndex, endIndex - startIndex + 1, ...data?.detail?.ObjectList?.map((item) => {
            return {
              ...item,
              NextPageNo
            }
          }));
          updateState = true;
        } else {
          newAssetsData = [
            ...newAssetsData,
            ...data?.detail?.ObjectList?.map((item) => {
              return {
                ...item,
                NextPageNo
              }
            })
          ];
        }

        updated_data = {
          ...data?.detail,
          ObjectList: newAssetsData,
          ...(updateState && {
            NextPageNo: assetsData?.NextPageNo,
            NextPage: assetsData?.NextPage
          })
        }

        setAssetsData(updated_data);
      } else {
        updated_data = {
          ...data?.detail,
          ObjectList: data?.detail?.ObjectList?.map((item) => {
            return {
              ...item,
              NextPageNo
            }
          })
        }
        setAssetsData(updated_data);
      }
      return updated_data;
    }
  }

  async function updateConfigurationMapping(recordId, ASEObject, SellObject, NextPageNo, updateIndex, sellOrAse) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateConfigurationMapping?RecordId=${recordId}&ASEObjectVm=${ASEObject}&SellObjectVm=${SellObject}&accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
      getRequestOptions(authTokens, "POST")
    ).then((response) => response.json());
    if (data?.mr?.Success) {
      getAssetsMappingListData({
        NextPageNo,
        searchString: assetsSearchString ?? "",
        updateIndex,
        sellOrAse
      })
    }
  }

  async function updateConfigurationFieldMapping(configurationId, fieldData, NextPageNo) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdatePSAConfigFieldMapping?objId_Configuration=${configurationId}&FieldData=${JSON.stringify(fieldData ?? {})}&objId_Integration=${IntegrationId}&accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
      getRequestOptions(authTokens, "POST")
    ).then((response) => response.json());
    if (data?.mr?.Success) {
    }
  }

  const [isAfterModal, setIsAfterModal] = useState(false);

  useEffect(() => {
    if (isAfterModal) {
      setIsAfterModal(false);
    } else {
      var element = document.getElementById("mainBody");
      element.addEventListener("scroll", getMoreAssetsData);

      return () => {
        element.removeEventListener("scroll", getMoreAssetsData);
      };
    }
  }, [assetsData]);

  return (
    <>
      {loading ? (
        <MapMembersLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen  "
        >
          <div className="d-flex align-items-center pt-4 justify-content-between">
            <div className="d-flex align-items-center">
              <img
                alt=""
                width={42}
                height={42}
                src={`/images/attack-surface/integrations/${parseIntegrationName("importedIntegrtionId")}.webp`}
                className="mr-3 radius-4"
                style={{
                  border: "1px solid #ebecf180"
                }}
              />
              <div>
                <p className="f-black f-600 f-15 m-0">Import & Map Assets</p>
                <p className="f-grey f-14">Categorise, Import and Map Assets from your {parseIntegrationName("importedIntegrtionName")}</p>
              </div>
            </div>
            <div className="">
              <Form.Check
                style={{
                  scale: "1.5",
                }}
                type="switch"
                id={`switchnitification1`}
                checked={importStatus}
                className={`m-0`}
                onClick={() => { }}
                onChange={(e) => {
                  updateImportStatus(21, Number(e.target.checked))
                    .then(() => {
                      if (e.target.checked) {
                        getAssetsMappingListData({
                          NextPageNo: 1,
                          searchString: assetsSearchString ?? "",
                        });
                      }
                    })
                  setImportStatus(e.target.checked);
                }}
              />
            </div>
          </div>

          {importStatus && (
            <>
              <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
                <div className="f-black f-16 f-600 d-flex align-items-center" style={{ gap: '6px', paddingLeft: '8px' }}>
                  <div>
                    <span>Assets</span>
                    {assetsData?.ShowAIBanner && (
                      <MappedUsingAI />
                    )}
                  </div>
                  {searchLoading && (
                    <Spinner
                      animation="border"
                      variant="grey"
                      size="sm"
                      className="mx-1"
                      style={{
                        width: "12px",
                        height: "12px",
                        minWidth: "12px",
                      }}
                    />
                  )}
                </div>
                <div className="mx-2 d-flex align-items-center" style={{ paddingRight: '8px' }}>
                  <input
                    type="text"
                    placeholder="Search.."
                    value={assetsSearchString}
                    onChange={(e) => {
                      setAssetsSearchString(e.target.value);
                      if (searchTimer) {
                        clearTimeout(searchTimer);
                      }
                      setSearchTimer(
                        setTimeout(() => {
                          getAssetsMappingListData({
                            NextPageNo: 1,
                            searchString: e.target.value,
                          });
                        }, 500)
                      )
                    }}
                    onBlur={() => { }}
                    className={`border-none bg-tranparent pl-2`}
                  />
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => { }}
                  />
                </div>
              </div>
              <div className="border-parent bg-grey radius-8" style={{ position: 'relative' }}>
                {(fetchingNewMembers || searchLoading) && (
                  <div 
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      background: 'rgba(255, 255, 255, 0.5)',
                      zIndex: 2
                    }}
                  />
                )}
                <MapAssetsBlock
                  setIsAfterModal={setIsAfterModal}
                  dataToMap={assetsData?.ObjectList}
                  memberPageData={memberPageData}
                  setMemberPageData={setMemberPageData}
                  getMembersListData={getMembersListData}
                  membersMappingData={membersMappingData}
                  getMembersMappingListData={getMembersMappingListData}
                  memberSearchString={memberSearchString ?? ""}
                  updateMemberData={updateMemberData}
                  updateConfigurationMapping={updateConfigurationMapping}
                  assetsData={assetsData}
                  updateConfigurationFieldMapping={updateConfigurationFieldMapping}
                  getAssetsMappingListData={getAssetsMappingListData}
                  setAssetsData={setAssetsData}
                  assetsSearchString={assetsSearchString}
                />
              </div>
              {fetchingNewMembers && (
                <div className="d-flex align-items-center f-darkgrey">
                  <GreyLoader width={12} />
                  Loading...
                </div>
              )}
              <div className="p-3"></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MapAssets;

export const MapAssetsBlock = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  memberSearchString,
  updateMemberData,
  dataToMap,
  assetsData,
  updateConfigurationMapping = () => { },
  updateConfigurationFieldMapping = () => { },
  getAssetsMappingListData = () => { },
  setAssetsData = () => { },
  assetsSearchString,
  setIsAfterModal = () => { }
}) => {
  const [searchTimer, setSearchTimer] = useState();
  const [showMappedMemberDetailModal, setShowMappedMemberDetailModal] =
    useState(false);
  const [sideModal, setSideModal] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const { 
    data: unitsModelData, 
    isLoading: isUnitsModelLoading, 
    fetchData: fetchUnitsModel,
    ignoreItem: ignoreUnitsModel,
    searchValue: unitsSearchValue,
    hasNextPage: unitsHasNextPage,
    page: unitsPage,
    setSearchValue: setUnitsSearchValue
  } = useConfigurationMappingList({ sellOrAse: "1" });
  const { 
    data: assetsModelData, 
    isLoading: isAssetsModelLoading, 
    fetchData: fetchAssetsModel,
    ignoreItem: ignoreAssetsModel,
    searchValue: assetsSearchValue,
    hasNextPage: assetsHasNextPage,
    page: assetsPage,
    setSearchValue: setAssetsSearchValue
  } = useConfigurationMappingList({ sellOrAse: "0" });
  const { reviewAssetMapping } = useIntegrationActions();

  const [configurationFieldsData, setConfigurationFieldsData] = useState([])
  const [selectedClientData, setSelectedClientData] = useState({});

  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");

  const onSearching = (searchString, sellOrAse) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    const fecthFunc = sellOrAse === "1" ? fetchUnitsModel : fetchAssetsModel;
    setSearchTimer(
      setTimeout(() => {
        fecthFunc({
          page: 1,
          limit: 20,
          search: searchString,
        });
        setSearchTimer(null);
      }, 500)
    );
  }

  return (
    <>
      <ImportedClientDetailModal
        show={showMappedMemberDetailModal}
        hideModal={() => setShowMappedMemberDetailModal(false)}
        data={memberDetails}
        parentID={"mapped-client-inegtration-modal-parent"}
      />
      {/* Header */}
      <div className="border-item map-assets-header">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-3 w-100 d-flex align-items-center justify-content-between">
          <div>Connectwise Manage</div>
          <div>{assetsData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">Managed security</div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">Attack Surface</div>
      </div>

      {/* Firts row */}
      {
        dataToMap?.map((dataItem, dataIndex) => {
          return (
            <div className="px-3 py-1 map-assets align-items-center border-bottom-gray">
              <div>
                <div className="f-500 f-14 f-black line-height-22 letter-space-2">
                  <span 
                    dangerouslySetInnerHTML={{__html: HGHighlightText(dataItem?.name, assetsSearchString)}}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <img
                    alt=""
                    className="mr-2"
                    src={dataItem?.MappingStatusIcon}
                  />
                  <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">{dataItem?.MappingStatus}</div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {dataItem?.loadingSell ? (
                  <Spinner
                    animation="border"
                    variant="grey"
                    size="sm"
                    className="mx-1"
                    style={{
                      width: "14px",
                      height: "14px",
                      minWidth: "14px",
                    }}
                  />
                ) : (
                  <img
                    src={dataItem?.SellMappingStatusIcon}
                    alt=""
                  />
                )}
                <Dropdown 
                  className="map-clients-dropdown w-100 my-dropdown"
                  onToggle={(isOpen) => {
                    if (isOpen) {
                      fetchUnitsModel({
                        page: 1,
                        limit: 20,
                        sellOrAse: "0"
                      })
                    } else {
                      setUnitsSearchValue("");
                    }
                  }}
                >
                  <Dropdown.Toggle disabled={viewOnly} className="w-100">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="w-100 bg-grey f-black d-flex pr-4">{dataItem?.ManageSecurityObjects?.name}</div>
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/integrations/chevron-down-msp.svg"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu assets-columns" style={{ padding: '4px 0' }}>
                    <div>
                      <div className="modal-search">
                        <input 
                          placeholder="Find units..."
                          value={unitsSearchValue}
                          onChange={(e) => {
                            setUnitsSearchValue(e.target.value);
                            onSearching(e.target.value, "1");
                          }}
                        />
                      </div>
                      <div 
                        className="hide-scrollbar"
                        style={{ 
                          padding: '0 4px',
                          height: '200px',
                          overflowY: 'auto'
                        }}
                      >
                        <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2 px-2 py-1 d-flex align-items-center" style={{ gap: '6px' }}>
                          <span>Assets</span>
                          {isUnitsModelLoading && (
                            <Spinner
                              animation="border"
                              variant="grey"
                              size="sm"
                              className="mx-1"
                              style={{
                                width: "12px",
                                height: "12px",
                                minWidth: "12px",
                              }}
                            />
                          )}
                        </div>
                        {
                          unitsModelData?.map((assetItem) => {
                            return (
                              <div
                                className="f-400 f-14 f-grey line-height-22 letter-space-2 px-2 py-1 pointer modal-item"
                                onClick={(e) => {
                                  updateConfigurationMapping(dataItem?.id, JSON.stringify({
                                    id: dataItem?.ASEObjects?.id,
                                    name: dataItem?.ASEObjects?.name,
                                    Fields_details: null,
                                  } ?? {}), JSON.stringify(assetItem), dataItem?.NextPageNo, dataIndex, 1)
                                  e.target
                                    .closest(".map-clients-dropdown")
                                    .click();
                                }}
                              >
                                <span 
                                  dangerouslySetInnerHTML={{__html: HGHighlightText(assetItem?.name, unitsSearchValue)}}
                                />
                              </div>
                            )
                          })
                        }
                        {(!isUnitsModelLoading && unitsHasNextPage) && (
                          <div 
                            className="px-2 py-1 pointer modal-item d-flex align-items-center" 
                            style={{ gap: '6px' }}
                            onClick={() => {
                              fetchUnitsModel({
                                page: unitsPage + 1,
                                limit: 20,
                                sellOrAse: "0"
                              })
                            }}
                          >
                            <ThreeDotsIcon 
                              width="12px" 
                              height="4px" 
                              fill="#9499AA"
                            />
                            <span className="f-darkgrey">Show more</span>
                          </div>
                        )}
                        {isUnitsModelLoading && (
                          <div className="d-flex align-items-center f-darkgrey py-2">
                            <GreyLoader width={12} />
                            Loading...
                          </div>
                        )}
                      </div>
                      <div style={{ padding: '0 4px' }}>
                        <div className="separator" />
                        <div 
                          className="d-flex align-items-center pointer modal-item" 
                          style={{ gap: '8px', padding: '4px 12px' }}
                          onClick={(e) => {
                            if (!ignoreUnitsModel) return;
                            updateConfigurationMapping(dataItem?.id, JSON.stringify({
                              id: dataItem?.ASEObjects?.id,
                              name: dataItem?.ASEObjects?.name,
                              Fields_details: null,
                            } ?? {}), JSON.stringify(ignoreUnitsModel), dataItem?.NextPageNo, dataIndex, 1)
                            e.target
                              .closest(".map-clients-dropdown")
                              .click();
                          }}
                        >
                          <CrossIcon width="8px" height="8px" fill="#616778" />
                          <span className="f-grey">
                            Ignore
                          </span>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {dataItem?.loadingASE ? (
                  <Spinner
                    animation="border"
                    variant="grey"
                    size="sm"
                    className="mx-1"
                    style={{
                      width: "14px",
                      height: "14px",
                      minWidth: "14px",
                    }}
                  />
                ) : (
                  <img
                    src={dataItem?.ASEMappingStatusIcon}
                    alt=""
                  />
                )}
                <Dropdown 
                  className="map-clients-dropdown w-100 my-dropdown"
                  onToggle={(isOpen) => {
                    if (isOpen) {
                      fetchAssetsModel({
                        page: 1,
                        limit: 20,
                        sellOrAse: "1"
                      })
                    } else {
                      setAssetsSearchValue("");
                    }
                  }}
                >
                  <Dropdown.Toggle disabled={viewOnly} className="w-100">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="w-100 bg-grey f-black d-flex pr-4">{dataItem?.ASEObjects?.name}</div>
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/integrations/chevron-down-msp.svg"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="menu assets-columns" style={{ padding: '4px 0' }}>
                    <div>
                      <div className="modal-search">
                        <input 
                          placeholder="Find assets..."
                          value={assetsSearchValue}
                          onChange={(e) => {
                            setAssetsSearchValue(e.target.value);
                            onSearching(e.target.value, "0");
                          }}
                        />
                      </div>
                      <div 
                        className="hide-scrollbar"
                        style={{ 
                          padding: '0 4px',
                          height: '200px',
                          overflowY: 'auto'
                        }}
                      >
                        <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2 px-2 py-1 d-flex align-items-center">
                          <span>Unit type</span>
                          {isAssetsModelLoading && (
                            <Spinner
                              animation="border"
                              variant="grey"
                              size="sm"
                              className="mx-1"
                              style={{
                                width: "12px",
                                height: "12px",
                                minWidth: "12px",
                              }}
                            />
                          )}
                        </div>
                        {
                          assetsModelData?.map((assetItem) => {
                            return (
                              <div
                                className="f-400 f-14 f-grey line-height-22 letter-space-2 px-2 py-1 pointer modal-item"
                                onClick={(e) => {
                                  updateConfigurationMapping(dataItem?.id, JSON.stringify(assetItem), JSON.stringify({
                                    id: dataItem?.ManageSecurityObjects?.id,
                                    name: dataItem?.ManageSecurityObjects?.name,
                                    Fields_details: null
                                  } ?? {}), dataItem?.NextPageNo, dataIndex, 0)
                                  e.target
                                    .closest(".map-clients-dropdown")
                                    .click();
                                }}
                              >
                                <span 
                                  dangerouslySetInnerHTML={{__html: HGHighlightText(assetItem?.name, assetsSearchValue)}}
                                />
                              </div>
                            )
                          })
                        }
                        {(!isAssetsModelLoading && assetsHasNextPage) && (
                          <div
                            className="px-2 py-1 pointer modal-item d-flex align-items-center"
                            style={{ gap: '6px' }}
                            onClick={() => {
                              fetchAssetsModel({
                                page: assetsPage + 1,
                                limit: 20,
                                sellOrAse: "1"
                              })
                            }}
                          >
                            <ThreeDotsIcon
                              width="12px"
                              height="4px"
                              fill="#9499AA"
                            />
                            <span className="f-darkgrey">Show more</span>
                          </div>
                        )}
                        {isAssetsModelLoading && (
                          <div className="d-flex align-items-center f-darkgrey py-2">
                            <GreyLoader width={12} />
                            Loading...
                          </div>
                        )}
                      </div>
                      <div style={{ padding: '0 4px' }}>
                        <div className="separator" />
                        <div 
                          className="d-flex align-items-center pointer modal-item " 
                          style={{ gap: '8px', padding: '4px 12px' }}
                          onClick={(e) => {
                            if (!ignoreAssetsModel) return;
                            updateConfigurationMapping(dataItem?.id, JSON.stringify(ignoreAssetsModel), JSON.stringify({
                              id: dataItem?.ManageSecurityObjects?.id,
                              name: dataItem?.ManageSecurityObjects?.name,
                              Fields_details: null
                            } ?? {}), dataItem?.NextPageNo, dataIndex, 0)
                            e.target
                              .closest(".map-clients-dropdown")
                              .click();
                          }}
                        >
                          <CrossIcon width="8px" height="8px" fill="#616778" />
                          <span className="f-grey">
                            Ignore
                          </span>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {
                (dataItem?.MappingStatus && dataItem?.MappingStatusIcon && dataItem?.MappingStatus != "" && dataItem?.MappingStatusIcon != "") ? <div
                  className={`f-500 f-12 line-height-18 letter-space-2 pointer ${dataItem?.mappingReviewed ? 'greyText2' : 'txt-blue'}`}
                  onClick={() => {
                    setConfigurationFieldsData(dataItem?.FieldMap);
                    setSelectedClientData(dataItem);
                    setSideModal(true);
                    if (dataItem?.mappingReviewed === false) {
                      reviewAssetMapping({ objectId: dataItem?.id })
                        .then(() => {
                          getAssetsMappingListData({
                            NextPageNo: dataItem?.NextPageNo,
                            searchString: assetsSearchString ?? "",
                          }).then((updated_data) => {
                            const newSelectedClientData = updated_data?.ObjectList?.find(obj => obj.id === dataItem?.id);
                            setSelectedClientData(newSelectedClientData)
                            setConfigurationFieldsData(newSelectedClientData?.FieldMap);
                          })
                        })
                    }
                  }}
                >
                  Map Details
                </div> : <div></div>
              }
            </div>
          )
        })
      }

      <SideModal
        show={sideModal}
        hideModal={() => {
          setIsAfterModal(true);
          setSideModal(false);
        }}
        refreshData={() => {
          return new Promise((resolve, reject) => {
            getAssetsMappingListData({
              NextPageNo: selectedClientData?.NextPageNo,
              searchString: assetsSearchString ?? "",
            }).then((updated_data) => {
              const newSelectedClientData = updated_data?.ObjectList?.find(obj => obj.id === selectedClientData?.id);
              setSelectedClientData(newSelectedClientData)
              setConfigurationFieldsData(newSelectedClientData?.FieldMap);
            })
            .finally(() => {
              resolve();
            })
          })
        }}
        configurationFieldsData={configurationFieldsData}
        selectedClientData={selectedClientData}
        setConfigurationFieldsData={setConfigurationFieldsData}
        updateConfigurationFieldMapping={updateConfigurationFieldMapping}
        assetsData={assetsData}
      />
    </>
  );
};


const SideModal = ({
  show,
  hideModal,
  configurationFieldsData,
  selectedClientData,
  setConfigurationFieldsData = () => { },
  refreshData = () => { },
  updateConfigurationFieldMapping,
  assetsData
}) => {
  const viewOnly = viewOnlyAccess("AccessState_PSA_Integration");
  const { rateAIMapping } = useIntegrationActions();
  const [isRateLoading, setIsRateLoading] = useState(false);

  const isSomethingLoading = configurationFieldsData.some(item => item?.isLoading) || isRateLoading;

  const onMappingRate = (rating) => {
    if (isRateLoading) return;
    setIsRateLoading(true);
    rateAIMapping({ objectId: selectedClientData?.id, rating })
      ?.then(() => {
        refreshData()
          ?.finally(() => {
            setIsRateLoading(false);
          })
      })
  }

  return (
    <SlidingModal
      show={show}
      className="common-big-modal memberAccessModal"
      id="ounsqxw"
      width={"28%"}
      onHide={() => {
        hideModal();
      }}
    >
      <SlidingModal.Header className="text-center d-flex justify-content-center p-3 border-bottom w-100 position-relative">
        <div
          className="f-500 f-14 f-black line-height-22 letter-space-2 d-flex"
        >
          <span>
            {selectedClientData?.name}
          </span>
          <CrossIcon 
            width="16px"
            height="16px"
            className="pointer"
            style={{ position: 'absolute', right: '16px', top: '17px' }}
            onClick={() => {
              hideModal();
            }}
          />
        </div>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-service-add-edit-modal p-3 ${true}`}>
        <div style={{ position: 'relative' }}>
          {isSomethingLoading && (
            <div 
              style={{
                backgroundColor: '#ffffff90',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 2
              }}
            />
          )}
          <div className="d-flex align-items-center">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Name:</div>
            <div className="f-400 f-14 f-black line-height-22 letter-space-2 w-50">{selectedClientData?.name}</div>
          </div>
          <div className="d-flex align-items-center mt-1">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Qty. assets:</div>
            <div className="f-400 f-14 f-black line-height-22 letter-space-2 w-50">
              {selectedClientData?.AssetCount}
            </div>
          </div>
          <div className="d-flex align-items-center mt-1">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Integration:</div>
            <div className="d-flex align-items-center w-50">
              <img
                alt=""
                width={20}
                height={20}
                src={`/images/attack-surface/integrations/${selectedClientData?.IntegrationLogo}.webp`}
                className="mr-2"
              />
              <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                {selectedClientData?.IntegrationName}
              </div>
            </div>
          </div>
          <div className="my-3 hg-border-bottom"></div>

          <div className="f-500 f-14 f-black line-height-22 letter-space-2 ">Fields</div>
          <div className="f-400 f-14 greyText2 line-height-22 letter-space-2">Map fields from {selectedClientData?.IntegrationName} to HighGround Attack Surface</div>

          {selectedClientData?.ShowAIBanner && (
            <div className="d-flex align-items-center justify-content-between" style={{ padding: '12px 0' }}>
              <div>
                <MappedUsingAI />
                <p className="f-12 f-darkgrey d-flex align-items-center" style={{ paddingLeft: '24px', gap: '4px' }}>
                  <span>Rate response for better suggestions</span>
                  {isRateLoading && (
                    <Spinner
                      animation="border"
                      variant="grey"
                      size="sm"
                      className="mx-1"
                      style={{
                        width: "12px",
                        height: "12px",
                        minWidth: "12px",
                      }}
                    />
                  )}
                </p>
              </div>
              <div className="d-flex align-items-center" style={{ gap: '8px' }}>
                <ThumbUpIcon 
                  className={`${!isRateLoading ? 'pointer' : ''}`}
                  onClick={() => {
                    onMappingRate(1);
                  }}
                  checked={selectedClientData?.mappingRating === true}
                />
                <ThumbDownIcon 
                  className={`${!isRateLoading ? 'pointer' : ''}`}
                  onClick={() => {
                    onMappingRate(0);
                  }}
                  checked={selectedClientData?.mappingRating === false}
                />
              </div>
            </div>
          )}

          <div className="d-flex align-items-center my-1">
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">{selectedClientData?.IntegrationName}</div>
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Attack Surface</div>
          </div>

          {
            !configurationFieldsData || configurationFieldsData?.length <= 0 ? <div
              className="d-flex align-items-center justify-content-center my-5 f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-100"
            >
              No data yet
            </div> : <div className="d-flex flex-column" style={{ gap: '4px' }}>
              {
                configurationFieldsData
                ?.sort((a, b) => (+a?.ConfigFieldSortOrder) - (+b?.ConfigFieldSortOrder))
                ?.map((item) => {
                  const isMappedByAI = item?.MappedByAI;
                  const groupFieldsMappingVM = selectedClientData?.FieldsMappingVM?.reduce((acc, curr) => {
                    if (!curr?.ASEGroupName) return acc;
                    if (!acc[curr?.ASEGroupName]) {
                      acc[curr?.ASEGroupName] = [];
                    }
                    acc[curr?.ASEGroupName].push(curr);
                    return acc;
                  } , {});

                  return (
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center justify-content-between w-50">
                        <div className="f-400 f-14 f-black line-height-22 letter-space-2">{item?.ConfigFieldName}{item?.isConfigFieldMandatory && '*'}</div>
                        {item?.isLoading ? (
                          <Spinner
                            animation="border"
                            variant="grey"
                            size="sm"
                            className="mr-2"
                            style={{
                              width: "12px",
                              height: "12px",
                              minWidth: "12px",
                            }}
                          />
                        ) : (
                          <img
                            alt=""
                            width={11}
                            height={11}
                            className="mr-2"
                            src='/images/attack-surface/right-arrow.svg'
                          />
                        )}
                      </div>
                      <Dropdown className="map-clients-dropdown-assets  w-50 my-dropdown">
                        <Dropdown.Toggle disabled={viewOnly} className="p-0">
                          {
                            item?.isIgnored ? <div className="d-flex align-items-center p-1">
                              <div className="f-500 f-12 f-grey line-height-18 letter-space-2">Ignore</div>
                              <img
                                alt=""
                                width={9}
                                height={9}
                                className="ml-2"
                                src='/images/grey-cross.svg'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const updated_data = configurationFieldsData.map(mapItem => {
                                    if (mapItem?.label === item?.label) {
                                      return {
                                        ...mapItem,
                                        ignore: false,
                                      };
                                    }
                                    return item;
                                  }).map(mapItem => {
                                    if (mapItem?.ConfigFieldName === item?.ConfigFieldName) {
                                      return {
                                        ...mapItem,
                                        isLoading: true
                                      };
                                    }
                                    return mapItem;
                                  });
                                  setConfigurationFieldsData(updated_data);
                                  updateConfigurationFieldMapping(selectedClientData?.id, {
                                    ...item,
                                    ASEFieldName: null,
                                    isLoading: true
                                  }, selectedClientData?.NextPageNo).then(() => {
                                    refreshData();
                                  });
                                }}
                              />
                            </div> : item?.ASEFieldName == null ? <div className="d-flex align-items-center">
                              <img
                                alt=""
                                className="mr-2"
                                src='/images/mdi_warning.svg'
                              />
                              <div className="f-500 f-12 f-grey line-height-18 letter-space-2">Select field</div>
                            </div> : (
                              <OverlayTrigger
                                placement="bottom-start"
                                trigger={isMappedByAI ? ["hover", "focus"] : []}
                                delay={{ show: 500, hide: 0 }}
                                overlay={
                                  <Tooltip
                                    id="ai-assets-item"
                                    style={{ position: 'relative', zIndex: 10000,  }}
                                  >
                                    <div className="d-flex" style={{ gap: '4px' }}>
                                      <MagicIcon width="16px" style={{ minWidth: '16px' }} />
                                      <span className="text" style={{ textAlign: 'start' }}>
                                        Field automatically mapped by AI
                                      </span>
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div className={`d-flex align-items-center bg-greyish p-1 ${isMappedByAI ? 'mapped-by-ai-item' : ''}`}>
                                  <div 
                                    className="f-500 f-12 f-grey line-height-18 letter-space-2 text"
                                    style={{
                                      whiteSpace: 'pre-wrap',
                                      textAlign: 'start'
                                    }}
                                  >
                                    {item?.ASEFieldName}
                                  </div>
                                  <img
                                    alt=""
                                    width={9}
                                    height={9}
                                    className="ml-2 pointer"
                                    src={isMappedByAI ? '/images/pink-cross.svg' : '/images/grey-cross.svg'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      const updated_data = configurationFieldsData.map(mapItem => {
                                        if (mapItem?.label === item?.label) {
                                          return {
                                            ...mapItem,
                                            ignore: false,
                                          };
                                        }
                                        return item;
                                      }).map(mapItem => {
                                        if (mapItem?.ConfigFieldName === item?.ConfigFieldName) {
                                          return {
                                            ...mapItem,
                                            isLoading: true
                                          };
                                        }
                                        return mapItem;
                                      });
                                      setConfigurationFieldsData(updated_data);
                                      updateConfigurationFieldMapping(selectedClientData?.id, {
                                        ...item,
                                        ASEFieldName: null,
                                        isLoading: true
                                      }, selectedClientData?.NextPageNo).then(() => {
                                        refreshData();
                                      });
                                    }}
                                  />
                                </div>
                              </OverlayTrigger>
                            )
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="menu assets-columns" style={{ zIndex: 1 }}>
                          <div
                            className="hide-scrollbar"
                            style={{
                              height: '300px',
                              overflowY: 'auto',
                            }}
                          >
                            {groupFieldsMappingVM && Object.keys(groupFieldsMappingVM).map((groupKey) => {
                              return (
                                <div className="px-2 f-400 f-14 f-grey line-height-22 letter-space-2 py-1">
                                  <div className="px-2 f-12 f-darkgrey">
                                    {groupKey}
                                  </div>
                                  {
                                    groupFieldsMappingVM[groupKey]?.map((groupItem) => {
                                      if (groupItem?.AlreadyUsed) return null;
                                      const isIncorrectType = groupItem?.ToolTip;
                                      return (
                                        <OverlayTrigger
                                          placement="bottom"
                                          trigger={isIncorrectType ? ["hover", "focus"] : []}
                                          delay={{ show: 500, hide: 0 }}
                                          overlay={
                                            <Tooltip
                                              id="wrong-type-item"
                                              style={{ position: 'relative', zIndex: 10000,  }}
                                            >
                                              <div className="d-flex flex-column text" style={{ whiteSpace: 'pre-wrap' }}>
                                                <p className="f-white m-0">
                                                  Incompatible field type Required field type: {groupItem?.ToolTip}
                                                </p>
                                                <p className="f-grey m-0">
                                                  Change it in your PSA if you want to be able to map it in HighGround.
                                                </p>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div
                                            className={`px-2 f-400 f-14 f-grey line-height-22 letter-space-2 py-1 bg-hover-grey pointer ${isIncorrectType ? 'wrong-type-item' : ''}`}
                                            onClick={(e) => {
                                              if (isIncorrectType) return;
                                              let upd_item = {
                                                ...item,
                                                ASEFieldName: groupItem?.ASEFieldName,
                                                isLoading: true
                                              };
                                              let upd_data = [...configurationFieldsData];

                                              upd_data = upd_data?.map((configItem) => {
                                                if (configItem?.ConfigFieldName == item?.ConfigFieldName) {
                                                  return {
                                                    ...configItem,
                                                    isLoading: true
                                                  }
                                                }
                                                return configItem;
                                              })
                                              setConfigurationFieldsData(upd_data);

                                              updateConfigurationFieldMapping(selectedClientData?.id, upd_item, selectedClientData?.NextPageNo)
                                                .then(() => {
                                                  refreshData();
                                                });
                                              e.target
                                                .closest(".map-clients-dropdown-assets")
                                                .click();
                                            }}
                                          >
                                            <div className="d-flex align-items-center justify-content-between" style={{ gap: '4px' }}>
                                              {groupItem?.ASEFieldName}
                                              {isIncorrectType && (
                                                <img 
                                                  src={groupItem?.Icon}
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </OverlayTrigger>
                                      )
                                    })
                                  }
                                </div>
                              ) 
                            })}
                          </div>
                          <div style={{ padding: '0 4px' }}>
                            <div className="separator" />
                            <div 
                              className="d-flex align-items-center pointer modal-item" 
                              style={{ gap: '8px', padding: '4px 12px' }}
                              onClick={(e) => {
                                let upd_data = [...configurationFieldsData];
                                upd_data = upd_data?.map((configItem) => {
                                  if (configItem?.ConfigFieldName == item?.ConfigFieldName) {
                                    return {
                                      ...configItem,
                                      isLoading: true
                                    }
                                  }
                                  return configItem;
                                })
                                setConfigurationFieldsData(upd_data);
                                updateConfigurationFieldMapping(selectedClientData?.id, {
                                  ...item,
                                  ...assetsData?.ignoreOption,
                                  ASEFieldName: assetsData?.ignoreOption?.name,
                                }, selectedClientData?.NextPageNo).then(() => {
                                  refreshData();
                                });
                                e.target
                                  .closest(".map-clients-dropdown-assets")
                                  .click();
                              }}
                            >
                              <CrossIcon width="8px" height="8px" fill="#616778" />
                              <span className="f-grey">
                                Ignore
                              </span>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )
                })
              }
            </div>
          }


          <div className="f-400 f-12 f-grey line-height-18 letter-space-2">* - Mandatory fields</div>

          {/* <div className="d-flex align-items-center mt-1">
            <div className="d-flex align-items-center justify-content-between w-50">
              <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2">Name*</div>
              <img
                alt=""
                width={11}
                height={11}
                className="mr-2"
                src='/images/attack-surface/right-arrow.svg'
              />
            </div>
            <Dropdown className="map-clients-dropdown  w-50">
              <Dropdown.Toggle>
                <div className="d-flex align-items-center bg-greyish p-1">
                  <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2">Name</div>
                  <img
                    alt=""
                    width={11}
                    height={11}
                    className="ml-2"
                    src='/images/grey-cross.svg'
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <>test</>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">

      </SlidingModal.Footer>

    </SlidingModal>
  );
};


//
// const MapMembersDropdown = ({
//   memberDetail,
//   membersMappingData,
//   getMembersMappingListData,
//   onStateChange,
// }) => {
//   function closeDropdown(e) {
//     e.target.closest(".map-clients-dropdown").click();
//   }
//   const [fetchingMapping, setFetchingMapping] = useState(false);
//   const [search, setSearch] = useState("");
//   const [selected, setSelected] = useState();
//   const [page, setPage] = useState("home");
//   const [loadingMappingData, setLoadingMappingData] = useState(false)

//   useEffect(() => {
//     if (memberDetail?.state === "Ignored") {
//       setSelected();
//     } else if (memberDetail?.state === "Imported") {
//       setSelected();
//     } else if (memberDetail?.state === "Not_Selected") {
//       setSelected();
//     } else if (memberDetail?.state === "Mapped") {
//       let mapped_list = [...membersMappingData?.ObjectList];
//       if (memberDetail?.ParentObjId > 0) {
//         let selected = mapped_list.filter(
//           (item) => item?.id === memberDetail?.ParentObjId
//         )[0];
//         setSelected(selected);
//       }
//     }
//   }, [memberDetail]);

//   const [timeoutId, setTimeoutId] = useState();

//   const handleInputChange = (event) => {
//     const { value } = event.target;
//     setSearch(value);


//     // Clear the previous timeout
//     clearTimeout(timeoutId);

//     // Set a new timeout to call the API after 1 second
//     let _timeoutId = setTimeout(() => {
//       setLoadingMappingData(true);
//       getMembersMappingListData({
//         NextPageNo: 1,
//         searchString: value,
//       }).then(() => {
//         setLoadingMappingData(false);
//       });
//     }, 700);
//     setTimeoutId(_timeoutId);
//   };

//   return (
//     <Dropdown
//       className="map-clients-dropdown"
//       onToggle={(e) => {
//         if (e) {
//         } else {
//           setPage("home");
//           setSearch("");
//           getMembersMappingListData({
//             NextPageNo: 1,
//             searchString: "",
//           });
//         }
//       }}
//     >
//       <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
//         {memberDetail?.state === "Ignored" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Ignore</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Not_Selected" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Imported" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">
//               {memberDetail?.ImportName ? (
//                 <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
//               ) : (
//                 <>Import "{TrimString(memberDetail?.name, 20)}"</>
//               )}
//             </div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Provisional_Import" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">
//               {memberDetail?.ImportName ? (
//                 <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
//               ) : (
//                 <>Import "{TrimString(memberDetail?.name, 20)}"</>
//               )}
//             </div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Auto_Imported" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Deleted" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Deleted</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Mapped" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="d-flex  align-items-center f-black">
//               <img
//                 alt=""
//                 width={16}
//                 height={16}
//                 className=" mr-2"
//                 src={"/images/user-logo.svg"}
//               />

//               {TrimString(
//                 selected?.name ?? memberDetail?.relationalObjectDetails?.name,
//                 25
//               )}
//             </div>
//             <div>
//               <img
//                 alt=""
//                 className="mr-2"
//                 src="/images/msp/integrations/chevron-down-msp.svg"
//               />
//             </div>
//           </div>
//         )}
//       </Dropdown.Toggle>
//       <Dropdown.Menu className="no-border-shadow-dropdown">
//         {page === "home" && (
//           <>
//             <div className="d-flex align-items-center mb-2">
//               <MspMappingDropdownSearchInput
//                 loading={loadingMappingData}
//                 placeholder="Find or import as..."
//                 value={search}
//                 onChange={(e) => {
//                   handleInputChange(e)
//                 }}
//               />
//             </div>
//             <div className="f-12 f-400 f-darkgrey my-1 pl-2">Map with</div>

//             <ScrollableDivWithShadow
//               className="transparent-scroller  member-right-list-scroller pl-2"
//               style={{
//                 maxHeight: "300px",
//                 overflowY: "scroll",
//               }}
//             >
//               {membersMappingData?.ObjectList

//                 // ?.filter((item) =>
//                 //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
//                 // )

//                 ?.map((member, memberIndex) => {
//                   return (
//                     <div
//                       className="d-flex align-items-center f-grey f-14 f-500 w-100 contextmenu-grey-item pointer "
//                       title={member?.name}
//                       onClick={(e) => {
//                         closeDropdown(e);
//                         setSearch("");
//                         onStateChange("Mapped", member);
//                         setSelected(member);
//                       }}
//                     >
//                       <img
//                         alt=""
//                         width={16}
//                         height={16}
//                         className="mx-2"
//                         src={"/images/user-logo.svg"}
//                       />

//                       <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(member?.name, 25), search) }} />

//                     </div>
//                   );
//                 })}
//             </ScrollableDivWithShadow>

//             {membersMappingData?.ObjectList?.length === 0 && search === "" && (
//               <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
//                 No{" "}{Number(parseIntegrationName("importedIntegrtionId")) === 3906771 ? "agents" : "members"}{" "}have been imported yet
//               </div>
//             )}
//             {membersMappingData?.ObjectList?.length === 0 && search !== "" && (
//               <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
//                 No results...
//               </div>
//             )}

//             {search !== "" && (
//               <div
//                 className="f-blue pointer d-flex align-items-center pl-3"
//                 onClick={(e) => {
//                   onStateChange("Provisional_Import", { ImportName: search });
//                   closeDropdown(e);
//                   setSearch("");
//                 }}
//               >
//                 <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
//                 Import as “{TrimString(search, 20)}”
//               </div>
//             )}
//             {membersMappingData?.NextPage === true && (
//               <div
//                 className="d-flex pointer align-items-center pl-3 mt-2"
//                 onClick={() => {
//                   if (fetchingMapping) return;
//                   setFetchingMapping(true);
//                   getMembersMappingListData({
//                     NextPageNo: null,
//                     searchString: search,
//                     skip: membersMappingData?.ObjectList?.length,
//                     count: 1000,
//                   })
//                     .then(() => {
//                       setFetchingMapping(false);
//                       const elementToScroll = document.querySelector(
//                         ".member-right-list-scroller"
//                       );
//                       elementToScroll.scrollTo({
//                         top: 10000000,
//                         behavior: "smooth", // You can use 'auto' for instant scrolling
//                       });
//                     })
//                     .catch(() => {
//                       setFetchingMapping(false);
//                     });
//                 }}
//               >
//                 <DotsLoader animate={fetchingMapping} />
//                 <div className="f-darkgrey ml-2">Show more</div>
//               </div>
//             )}
//             <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} />
//             <div className="mx-2  ">
//               <div
//                 className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setSelected();
//                   closeDropdown(e);
//                   setSearch("");
//                   onStateChange("Provisional_Import");
//                 }}
//               >
//                 <img
//                   src="/images/attack-surface/right-arrow.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Import "{TrimString(memberDetail?.name, 20)}"
//               </div>
//               <div
//                 className="f-grey  pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setPage("importAs");
//                 }}
//               >
//                 <img
//                   src="/images/attack-surface/right-arrow.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Import as ...
//               </div>
//               <div
//                 className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setSelected();
//                   closeDropdown(e);
//                   setSearch("");
//                   onStateChange("Ignored");
//                 }}
//               >
//                 <img
//                   src="/images/msp/integrations/cross-icon-msp.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Ignore
//               </div>
//             </div>
//           </>
//         )}
//         {page === "importAs" && (
//           <div className="w-100 p-2">
//             <div className="f-12 f-darkgrey mb-2 pl-1"> Import as </div>

//             <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border">
//               <input
//                 type="text"
//                 className="border-none bg-transparent w-100"
//                 value={search}
//                 onChange={(e) => {
//                   setSearch(e.target.value);
//                 }}
//               />
//             </div>
//             <div className="mt-2 d-flex align-items-center justify-content-end">
//               <Button
//                 className="hg-cancel-btn mr-2"
//                 onClick={() => {
//                   setPage("home");
//                   setSearch("");
//                 }}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 className="hg-blue-btn"
//                 disabled={search === ""}
//                 onClick={(e) => {
//                   onStateChange("Provisional_Import", { ImportName: search });
//                   closeDropdown(e);
//                   setSearch("");
//                 }}
//               >
//                 Save
//               </Button>
//             </div>
//           </div>
//         )}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };


const MapMembersLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};

