import { Button, Spinner } from "react-bootstrap";
import AuthTextInput from "../auth-text-input.component";
import { useState } from "react";

const MFACodeSection = ({
  setTotpError,
  totpError,
  submit2FACode,
  loading,
  setError,
  error,
  setFormState,
  onMfaBack = () => {}
}) => {
  const [totp, setTotp] = useState("");
  return (
    <div className="user-email-section d-flex flex-column" style={{ gap: '16px' }}>
      <div>
        <form
          onSubmit={e => {
            e.preventDefault();
            submit2FACode(totp);
          }}
        >
          <div className="label f-black" style={{ marginBottom: '8px' }}>
            Enter MFA code
          </div>
          <AuthTextInput
            error={error || totpError}
            type="number"
            placeholder="Enter 6-digit code"
            onChange={(event) => {
              if (String(event.target.value).length > 6) {
                return;
              }
              setTotp(event.target.value);
              setTotpError("");
              setError("");
            }}
            value={totp}
            autoFocus
          />

          <div style={{ marginTop: '8px' }}>
            <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type={loading ? "button" : "submit"}
              style={{ padding: "5px" }}
            >
              {loading ? (
                <span>
                  <Spinner
                    size="sm"
                    className="mr8px"
                    animation="border"
                  />{" "}
                  Authenticating
                </span>
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="f-500 pointer f-blue clean-button"
          variant="blue"
          onClick={() => {
            onMfaBack();
          }}
        >
          Go back
        </Button>
      </div>
    </div>
  );
}

export default MFACodeSection;
