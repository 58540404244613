import React from "react";
import './searchFilterComponent.scss';
import SearchComponent from "./SearchComponent";
import FilterComponent from "./FilterComponent";
import VisibilityComponent from "./VisibilityComponent";
import { VisibilityType } from "../../constants/filter.constants";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NO_ACCESS_TEXT } from "../App/App";
import DisabledButtonWithToolTip from "../Theme/DisabledButtonWithToolTip";
import AppliedFiltersComponent from "./AppliedFiltersComponent";

/**
 * @param {Object} props
 * @param {string} props.searchValue
 * @param {function} props.searchOnChange
 * @param {function} props.searchOnCross
 * @param {function} props.onSortClick
 * @param {Array} props.headersList
 * @param {function} props.updatedHeaderList
 * @param {string} props.visibilityType
 * @param {Array<{ label: string, key: string, value: boolean }>} props.visibilityFilters
 * @param {function} props.onVisibilityFilterChange
 * @param {Array<{ FieldKey: string, FieldName: string, SortOrder: number, Visibility: boolean }>} props.orderData
 * @param {function} props.setOrderData
 */
export const SearchFilterComponent = ({
    isWhite = false,
    leftSide,
    rightSide,
    passedMainStyles = '',
    searchValue,
    passedRef,
    passedFilterList = [],
    setRef = () => { },
    searchOnChange = (e) => { },
    searchOnCross = () => { },
    onSortClick = () => { },
    visibilityType = VisibilityType.TABLE,
    visibilityFilters = [],
    onVisibilityFilterChange = () => { },
    orderData = [],
    setOrderData = () => { },
    isLastButtonDisabled = false,
    showLastButton = true,
    lastButtonLabel,
    lastButtonOnClick = () => { },
    childFilterItem,
    selectedFilterList = [],
    setSelectedFilterList = () => { },
    childAppliedFilterItem,
    onResetClick = () => { },
    sortOptionTooltip,
    lastButtonChild,
    noToSearch = false,
    checkId = ""
}) => {
    return (
        <div className={`${passedMainStyles}`}>
            <div className={`d-flex align-items-center justify-content-between`}>
                {
                    leftSide ? leftSide : <></>
                }
                <div className="d-flex align-items-center search-filter-component"
                    style={{
                        // marginRight: '200px',
                        gap: '4px'
                    }}
                >
                    {
                        rightSide && rightSide
                    }
                    <SearchComponent
                        value={searchValue}
                        onChange={searchOnChange}
                        onCross={searchOnCross}
                        passedRef={passedRef}
                        setRef={setRef}
                        isWhite={isWhite}
                        noToSearch={noToSearch}
                        checkId={checkId}
                    />
                    <FilterComponent
                        filterList={passedFilterList}
                        childFilterItem={childFilterItem}
                        isWhite={isWhite}
                    />
                    {
                        sortOptionTooltip ? <OverlayTrigger
                            placement="bottom"
                            trigger={["hover", "focus"]}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                                <Tooltip id={`tooltip-1365`}>
                                    {sortOptionTooltip}
                                </Tooltip>
                            }
                        >
                            <div
                                style={{ width: '32px', height: '32px' }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <img
                                    alt=""
                                    width={17}
                                    height={15}
                                    src={isWhite ? "/images/sort-icon-white.svg" : "/images/sort-icon.svg"}
                                />
                            </div>
                        </OverlayTrigger> :
                            <div
                                style={{ width: '32px', height: '32px' }}
                                className="d-flex align-items-center justify-content-center pointer"
                                onClick={() => {
                                    onSortClick();
                                }}
                            >
                                <img
                                    alt=""
                                    width={17}
                                    height={15}
                                    src={isWhite ? "/images/sort-icon-white.svg" : "/images/sort-icon.svg"}
                                />
                            </div>
                    }
                    <VisibilityComponent
                        visibilityType={visibilityType}
                        filterList={visibilityFilters}
                        onFilterChange={onVisibilityFilterChange}
                        orderData={orderData}
                        setOrderData={setOrderData}
                        isWhite={isWhite}
                    />
                    {
                        showLastButton && <>
                            {
                                lastButtonChild ? lastButtonChild : <>
                                    {
                                        isLastButtonDisabled ? <DisabledButtonWithToolTip
                                            buttonText={lastButtonLabel ?? 'Add'}
                                            noAccessText={NO_ACCESS_TEXT}
                                        /> : <Button
                                            className="hg-blue-btn py-1 px-2"
                                            onClick={() => {
                                                lastButtonOnClick();
                                            }}
                                        >
                                            {lastButtonLabel ?? 'Add'}
                                        </Button>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {
                selectedFilterList?.length > 0 &&
                <AppliedFiltersComponent
                    childAppliedFilterItem={childAppliedFilterItem}
                    onResetClick={onResetClick}
                />
            }
        </div>
    );
};

export default SearchFilterComponent;
