import { useAuth } from "../../Components/context/Auth";
import { CallToast } from "../../Components/PriceCalculator/modals/CustomToast";

const useBaseActions = () => {
  const { authTokens } = useAuth();

  const onSuccess = () => {
    CallToast("Updated successfully", false, false, "v2style");
  }

  const onError = () => {
    CallToast("Something went wrong", false, false, "v2style");
  }

  const getBody = (method, body) => {
    if (method === 'GET') return null;
    if (body instanceof FormData) return body;
    return JSON.stringify(body);
  }
  
  const sendRequest = async (url, method = 'GET', body = null, headers = {}) => {
    try {
      const resHeaders = body instanceof FormData ? headers : {
        ...headers,
        'Content-Type': 'application/json'
      };
      const response = await fetch(url, { method, body: getBody(method, body), headers: {
        ...resHeaders,
        ...(authTokens && {
          Authorization: `Bearer ${authTokens}`,
        })
      } });
      const data = await response.json();
      if (!response.ok) {
        onError();
        throw new Error(data?.message || 'Request error');
      }
      return data;
    } catch (error) {
      return error;
    }
  }

  return {
    sendRequest,
    onSuccess,
    onError,
    authTokens
  }
}

export default useBaseActions;
