import { Dropdown } from "react-bootstrap";
import IntegrationItem from "./integration-item.component";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/app.constants";

const IntegrationsList = ({ 
  integrationList, 
  onOptionAdd, 
  showIcon = true,
  searchValue = "",
  title = "No tools available",
  refreshData = () => {},
}) => (
  <div className="scrollable-div pr-1">
    {integrationList?.length === 0 ? (
      <div className="px-3">
        <h3 
          className="f-grey m-0 no-available-title"
          style={{ fontSize: '11px', fontWeight: '500' }}
        >
          {title}
        </h3>
      </div>
    ) : (
      integrationList?.map((option, optionIndex) => (
        <Dropdown.Item
          className="px-0 pl-3 py-2 integration-item"
          key={`stio-${option?.ToolName}-${optionIndex}`} 
        >
          <IntegrationItem 
            integrationName={option?.ToolName}
            integrationId={option?.obj_IntegrationId}
            toolIcon={option?.ToolIcon} 
            isLoading={option?.loading}
            showIcon={showIcon} 
            onAdd={() => {
              onOptionAdd(option);
            }}
            searchValue={searchValue} 
            isCustom={option?.IsUserDefined}
            refreshData={refreshData}
          />
        </Dropdown.Item>
      ))
    )}
  </div>
);

const SelectIntegrations = ({ integrationList, fullIntegrationList, onOptionAdd, searchValue = "", refreshData, isPractice = false }) => {
  const history = useHistory();
  const mspTools = useMemo(() => (
    integrationList?.filter((option) => option?.IsMSPTool)
  ), [integrationList]);
  const practicesTools = useMemo(() => (
    integrationList?.filter((option) => option?.IsPractice)
  ), [integrationList]);
  const otherTools = useMemo(() => (
    integrationList?.filter((option) => !option?.IsMSPTool && !option?.IsPractice)
  ), [integrationList]);

  const amountOfPractices = useMemo(() => (
    fullIntegrationList.filter((option) => option?.IsPractice).length
  ), [fullIntegrationList]);
  const noPractices = amountOfPractices === 0;

  const onBuildStack = () => {
    history.push(ROUTE_PATHS.mspv2.securityStack);
  }

  return (
    <div>
      <div className="mb-3">
        <h3 className="px-3 f-12 mb-1 no-available-title">{`Select a ${isPractice ? 'practices' : 'tools'} from your MSPs stack`}</h3>
        {mspTools.length === 0 ? (
          <div className="px-3 mt-2">
            <button 
              className="f-12 build-stack-btn"
              onClick={onBuildStack}
            >
              Build your stack
            </button>
          </div>
        ) : (
          <IntegrationsList 
            integrationList={mspTools} 
            onOptionAdd={onOptionAdd} 
            searchValue={searchValue}
            refreshData={refreshData}
          />
        )}
      </div>
      <div className="d-flex flex-column">
        {/* {!noPractices && (
          <div className="mb-3">
            <h3 className="px-3 f-12 all-tools-title mb-1">Practices</h3>
            <IntegrationsList 
              integrationList={practicesTools} 
              onOptionAdd={onOptionAdd}
              showIcon={false}
              searchValue={searchValue}
              title={"All practices have been added"}
              refreshData={refreshData} 
            />
          </div>
        )} */}
        <div>
          <h3 className="px-3 f-12 all-tools-title mb-1">{`All ${isPractice ? 'practices' : 'tools'}`}</h3>
          <IntegrationsList 
            integrationList={isPractice ? integrationList : otherTools} 
            onOptionAdd={onOptionAdd} 
            searchValue={searchValue}
            refreshData={refreshData}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectIntegrations;
