import React, { useEffect, useMemo, useRef, useState } from "react";
import "./addItemInSpendModal.scss";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";
import moment from "moment";
import { GreyLoader, WhiteLoader } from "../../../Theme/APILoaders";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

import { TrimString } from "../../../../Utils/StringTrimmer";
import { HGHighlightText } from "../../../App/App";
import { validateNumber } from "../../../../Utils/CommonFunctions";
import ScrollableDivWithShadow from "../ScrollableDivWithShadow";
import integrationUtils from "../../../../Utils/integration.utils";
import useUpdateTaxRate from "./update-tax-rate.data";
import CategorySelectorDropdown from "../../../Common/category-selector/category-selector.dropdown";

const clientId = window.location.pathname.split("/")[4];
const AddItemInSpendModal = ({
  show,
  hideModal,
  onCreate,
  categoryList,
  selectedInvoiceItemData,
  onUpdate,
  onDelete,
  InvoiceItemIndex,
  getTaxRateList,
  currencyList,
  setUserSelectedCurrency,
  userSelectedCurrency,
  exchangeRateData,
  invoicePageData,
  categoryData,
  categoryPageData,
  showSelectedSubcategory,
  currentSubcategory,
  defaultCurrencyData,
  updateExchangeRateData,
  isDraggableParent,

}) => {
  const addItemInSpendModalRef = useRef();
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [invoiceItemData, setInvoiceItemData] = useState({
    ...dummyInvoiceData,
  });
  const [loading, setLoading] = useState(false);
  const [taxList, setTaxList] = useState([]);
  // const showTax = localStorage.getItem("msPshowTax") ? JSON.parse(localStorage.getItem("msPshowTax")) : true;

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});
  const [inputValueSubcategory, setInputValueSubcategory] = useState("");
  const [inputValueCategory, setInputValueCategory] = useState("");
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);
  const [showCreateSubcategoryModal, setShowCreateSubcategoryModal] =
    useState(false);
    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const { isLoading: isTaxRateUpdating, updateTaxRate } = useUpdateTaxRate();

  function resetModal() {
    setSubCategoryList([]);
  }

  function updateTaxList(stoploading = false) {
    if (!stoploading) {
      setLoading(true);
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function onClickUpdateTaxRate(item) {
    return new Promise((resolve, reject) => {
      updateTaxRate(item)
        .then(() => {
          getTaxRateList();
          resolve();
        })
    });
  }

  async function createInvoiceItem() {
    setSaving(true);
    await postData(authTokens, "CreateMSPTechnologyInvoiceItem", {
      ObjId_InvoiceItem: invoiceItemData?.Id,
      objId_Customer: clientId,
      Title: invoiceItemData?.Title,
      Amount: invoiceItemData?.ItemValue,
      InvoiceDate: moment().format("DD/MM/YYYY"),
      CategoryId: invoiceItemData?.Category_Selected?.objId_Category,
      SubCategoryId: invoiceItemData?.SubCategory_Selected?.objId_Category,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
      ForeignCurrencyRate:exchangeRateData?.Rate??1,
      TaxRates_Selected: JSON.stringify(invoiceItemData?.TaxRates_Selected),
      ForeignCurrencyAmount:Number(
        invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
          (invoiceItemData?.ItemValue *
            invoiceItemData?.Quantity *
            invoiceItemData?.TaxRates_Selected?.TaxRate) /
            100
      ).toFixed(2)
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          CallToast("updated successfully", false, false, "v2style");
          hideModal();
          onUpdate(InvoiceItemIndex, invoiceItemData);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setSaving(false);
      });
  }

  function getSubCategoryList(_categoryId) {
    setLoadingSubcategories(true);
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetCategoryDetail", {
        CategoryId: _categoryId,
        ObjId_Customer: clientId,
        LoadInvoice: false,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          setSubCategoryList(response?.SubCategory_List ?? []);
          setLoadingSubcategories(false);
          resolve(response?.SubCategory_List);
        })
        .catch((err) => {
          setLoadingSubcategories(false);
          reject(err);
        });
    });
  }

  function handleClickOutside(event) {
    if (
      addItemInSpendModalRef.current &&
      !addItemInSpendModalRef.current.contains(event.target)
    ) {
      hideModal();
    }
  }
  useEffect(() => {
    if (show) {
      setLoading(true);
      resetModal();
      document.addEventListener("mousedown", handleClickOutside);
      getTaxRateList()
        .then((response) => {
          setTaxList(response?.TaxRates_List);
          setLoading(false);
          let updated_data = {};
          if (Object.keys(selectedInvoiceItemData).length > 0) {
            updated_data = { ...selectedInvoiceItemData };
            if (!updated_data?.SubCategory_Selected) {
            }
            getSubCategoryList(updated_data?.Category_Selected?.objId_Category);

            setInvoiceItemData(updated_data);
          } else {
            // New entry
            updated_data = { ...dummyInvoiceData };
            updated_data.Category_Selected = categoryList?.find(
              (item) =>
                item?.objId_Category === categoryPageData?.objId_Category
            );

            if (showSelectedSubcategory) {
              updated_data.SubCategory_Selected = { ...currentSubcategory };
            }
            let selectedTaxRate = response?.TaxRates_List?.find(
              (item) =>
                item?.ObjectId === updated_data?.TaxRates_Selected?.ObjectId
            );

            if (selectedTaxRate) {
              updated_data.TaxRates_Selected = selectedTaxRate;
            } else {
            }
            setInvoiceItemData(updated_data);
            getSubCategoryList(updated_data?.Category_Selected?.objId_Category);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setInvoiceItemData({ ...dummyInvoiceData });
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [show, selectedInvoiceItemData]);

  const [errors, setErrors] = useState({});
  async function checkInvoiceItemValidations() {
    let errors = 0;
    if (!invoiceItemData?.Title) {
      errors += 1;
      setErrors((prev) => ({ ...prev, titleError: true }));
    }
    // if (!invoiceItemData?.Category_Selected) {
    //   errors += 1;
    //   setErrors((prev) => ({ ...prev, selectedCategoryError: true }));
    // }
    // if (!invoiceItemData?.SubCategory_Selected) {
    //   errors += 1;
    //   setErrors((prev) => ({ ...prev, selectedSubCategoryEror: true }));
    // }
    if (!invoiceItemData?.ItemValue) {
      errors += 1;
      setErrors((prev) => ({ ...prev, itemValueError: true }));
    }
    if (
      !invoiceItemData?.Quantity ||
      Number(invoiceItemData?.Quantity) <= 0 ||
      invoiceItemData?.Quantity?.toString()?.trim()?.length === 0
    ) {
      errors += 1;
      setErrors((prev) => ({ ...prev, quantityError: true }));
    }

    return errors;
  }

  async function deleteInvoiceItem(_invoiceItemId) {
    setDeleting(true);
    await getAPIData(`${629}`, authTokens, {
      optionEnum1: _invoiceItemId,
      optionEnum2: 0,
    })
      .then((response) => {
        setDeleting(false);
        if (response?.Success) {
          onDelete(InvoiceItemIndex, _invoiceItemId);
          CallToast("Updated Successfully", false, false, "v2style");
          hideModal();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setDeleting(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function getCategoryDetail(_categoryId, kwargs, editData = true) {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: _categoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: kwargs?.Amount ?? "",
      // FromDate:
      // kwargs?.fromDate ??
      // moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      // ToDate:
      //   kwargs?.toDate ??
      //   moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify(kwargs?.subCategoryIds ?? []),
    })
      .then((response) => {
        // setApplyingFilters(false);
        if (response?.mr?.Success) {
          // if (kwargs && editData) {
          //   setCategoryPageData({
          //     ID: _categoryId,
          //     ...categoryPageData,
          //     InvoiceItemVM_List: response?.InvoiceItemVM_List,
          //   });
          // } else {
          //   setCategoryPageData({ ID: _categoryId, ...response });
          // }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // setApplyingFilters(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  
  if (!show) return null;

  return (
    <div
      className={`add-item-in-spend-modal ${
        isDraggableParent ? "add-spend-draggable-parent" : ""
      }`}
      ref={addItemInSpendModalRef}
    >
      {/* Header */}
      <div className="p-2 modal-header">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="f-500 ml-2 f-12 f-grey f-500">
            {invoiceItemData?.Id === 0 ? "Add" : ""} Item
          </div>
          <img
            alt=""
            src="/images/user-detail-close.svg"
            className="mr-1 pointer"
            onClick={hideModal}
          />
        </div>
      </div>

      {/* Body */}
      {loading ? (
        <>
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "310px",
            }}
          >
            <Loader />
          </div>
        </>
      ) : (
        <div className="hide-scrollbar p-3">
          <div className="mb-2">
            <Dropdown className="add-item-category-dropdown w-100">
              <Dropdown.Toggle className="w-100">
                <div
                  className={`  ${
                    errors?.titleError ? "input-error" : ""
                  } highlight-input bg-grey p-2 radius-4 d-flex align-items-center`}
                >
                  <input
                    className="border-none bg-transparent flex-grow-1"
                    type="text"
                    placeholder="Title"
                    value={invoiceItemData?.Title ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      setInvoiceItemData((prev) => ({ ...prev, Title: value }));
                      setErrors((prev) => ({ ...prev, titleError: false }));
                    }}
                  />
                  {/* <img alt="" src="/images/chevron-down.svg" className="mx-2" /> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100 d-none">
                {["Identify", "Protect", "Recover", "Detect", "Respond"]?.map(
                  (item, itemIndex) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between my-2 pointer pl-2">
                        <div
                          className={`${item === "Recover" ? "f-blue" : ""}`}
                        >
                          <img
                            alt=""
                            className={`${
                              item.selected ? "blue-icon" : ""
                            } mr-2`}
                            src={item.image}
                          />
                          {item}
                        </div>
                        <div>
                          {item === "Recover" && (
                            <img
                              alt=""
                              className=" mr-3"
                              src="/images/msp/blue-tick.svg"
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="f-12 f-grey f-500 mt-2 mb-1">Category</div>
          <div className="mb-2">
            <CategorySelectorDropdown 
              data={categoryList}
              fieldId={"objId_Category"}
              fieldValue={"CategoryName"}
              fieldColor={"CategoryColourHexCode"}
              placeholder={"Category"}
              selected={invoiceItemData?.Category_Selected}
              onSelect={(category) => {
                let updated_data = { ...invoiceItemData };
                updated_data.Category_Selected = category;
                updated_data.SubCategory_Selected = null;
                setInvoiceItemData(updated_data);
                setErrors((prev) => ({
                  ...prev,
                  selectedCategoryError: false,
                }));
              }}
              toggleClassName="bg-grey radius-4"
            />
          </div>
          <div className="mb-2">
            <CategorySelectorDropdown 
              data={subCategoryList}
              fieldId={"objId_Category"}
              fieldValue={"CategoryName"}
              fieldColor={"CategoryColourHexCode"}
              placeholder={"Subcategory"}
              menuTitle="Choose subcategory"
              selected={invoiceItemData?.SubCategory_Selected}
              onSelect={(category) => {
                let updated_data = { ...invoiceItemData };
                updated_data.SubCategory_Selected = category;
                setInvoiceItemData(updated_data);
                setErrors((prev) => ({
                  ...prev,
                  selectedSubCategoryEror: false,
                }));
              }}
              toggleClassName="bg-grey radius-4"
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="flex-1">
              <div className="f-12 f-grey f-500 mt-2 mb-1">Item Price</div>
              <div
                id="item-price-input-wrapper"
                className={` ${
                  errors?.itemValueError ? "input-error" : ""
                }  d-flex align-items-center justify-content-between radius-4 highlight-input bg-grey p-2 flex-grow-1 mr-1`}
                style={{
                  height: "2.3rem",
                }}
              >
                <div className="w-100 d-flex">
                  <span>{userSelectedCurrency?.CurrencySymbol}&nbsp;</span>
                  <input
                    type="number"
                    className="bg-transparent w-100 border-none"
                    placeholder="Type amount"
                    value={invoiceItemData?.ItemValue}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (value.toString().length > 7) {
                        return;
                      }
                      setInvoiceItemData((prev) => ({
                        ...prev,
                        ItemValue: validateNumber(value),
                      }));
                      setErrors((prev) => ({ ...prev, itemValueError: false }));
                    }}
                    onFocus={(e) => {
                      if (!invoiceItemData?.ItemValue) {
                        setInvoiceItemData((prev) => ({
                          ...prev,
                          ItemValue: "",
                        }));
                      }
                    }}
                    onBlur={() => {
                      if (!invoiceItemData?.ItemValue) {
                        setInvoiceItemData((prev) => ({
                          ...prev,
                          ItemValue: 0,
                        }));
                      }
                    }}
                  />
                </div>
                {
                  // invoicePageData?.InvoiceItem_List &&
                  //   invoicePageData?.InvoiceItem_List?.length === 0 &&

                  <CurrencyDropdown
                    disabled
                    dropdownId={"add-currencymodal-dropdown"}
                    // width={"14.7rem"}
                    // inputWidth={"10.6rem"}
                    menuClassName="currency-dropdown-in-add-item-spend-modal"
                    onChange={(data) => {
                      setUserSelectedCurrency(data);
                      updateExchangeRateData({ _currency: data?.CurrencyName });
                    }}
                    currencyList={currencyList}
                    selected={userSelectedCurrency}
                  />
                }
              </div>
            </div>

            <div className="flex-1">
              <div className="f-12 f-grey f-500 mt-2 mb-1">Quantity</div>
              <div
                className={` ${
                  errors?.quantityError ? "input-error" : ""
                }  d-flex align-items-center radius-4 highlight-input bg-grey p-2 flex-grow-1 mr-1`}
              >
                <input
                  type="number"
                  className="bg-transparent w-100 border-none"
                  placeholder="Type quantity"
                  value={invoiceItemData?.Quantity}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value.toString().length > 5) {
                      return;
                    }

                    setInvoiceItemData((prev) => ({
                      ...prev,
                      Quantity: parseInt(value),
                    }));
                    setErrors((prev) => ({ ...prev, quantityError: false }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between f-darkgrey mt-2">
            <div className="f-500">Subtotal:</div>
            {Number(
              isNaN(invoiceItemData?.ItemValue * invoiceItemData?.Quantity)
                ? 0
                : invoiceItemData?.ItemValue * invoiceItemData?.Quantity
            ) === 0 ||
            userSelectedCurrency?.CurrencySymbol ===
              defaultCurrencyData?.CurrencySymbol ? (
              <div>
                {userSelectedCurrency?.CurrencySymbol}&nbsp;
                {Number(
                  isNaN(invoiceItemData?.ItemValue * invoiceItemData?.Quantity)
                    ? 0
                    : invoiceItemData?.ItemValue * invoiceItemData?.Quantity
                ).toFixed(2)}
              </div>
            ) : (
              <div className="d-flex">
                {userSelectedCurrency?.CurrencySymbol}&nbsp;
                {Number(
                  isNaN(invoiceItemData?.ItemValue * invoiceItemData?.Quantity)
                    ? 0
                    : invoiceItemData?.ItemValue * invoiceItemData?.Quantity
                ).toFixed(2)}
                <img
                  width={15}
                  height={15}
                  src="/images/msp/exchange-rate-icon.svg"
                  alt=""
                  className="mx-2"
                />
                {defaultCurrencyData?.CurrencySymbol}&nbsp;
                {(
                  exchangeRateData?.Rate *
                  Number(
                    isNaN(
                      invoiceItemData?.ItemValue * invoiceItemData?.Quantity
                    )
                      ? 0
                      : invoiceItemData?.ItemValue * invoiceItemData?.Quantity
                  )
                )?.toFixed(2)}
              </div>
            )}
          </div>

          <div>
            <div className="f-12 f-grey f-500 mt-1 mb-1">Tax Rate </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div
                className="flex-1 radius-4 p-2"
                style={{
                  backgroundColor: "#f6f8fb",
                }}
              >
                <TaxRateDropdown
                  deleteTaxRate={deleteTaxRate}
                  updateTaxList={updateTaxList}
                  onClickMakeDefault={onClickUpdateTaxRate}
                  isUpdating={isTaxRateUpdating}
                  onChange={(data) => {
                    let updated_data = { ...invoiceItemData };
                    updated_data.TaxRates_Selected = data;
                    setInvoiceItemData(updated_data);
                  }}
                  taxList={taxList}
                  selected={invoiceItemData?.TaxRates_Selected}
                  onClickEditTaxRate={(data) => {
                    setTaxRateData(data);
                    setShowAddEditTaxRateModal(true);
                  }}
                  onClickAddTaxRate={() => {
                    setTaxRateData({
                      ObjectId: 0,
                      TaxCode: "",
                      TaxName: "",
                      TaxRate: "",
                    });
                    setShowAddEditTaxRateModal(true);
                  }}
                />
              </div>

              <div
                className="flex-1 d-flex"
                style={{
                  flexDirection: "row-reverse",
                }}
              >
                <div className="f-darkgrey">
                  {Number(invoiceItemData?.TaxRates_Selected?.TaxRate).toFixed(
                    2
                  )}
                  % = {userSelectedCurrency?.CurrencySymbol}{" "}
                  {TrimString(
                    (
                      Number(
                        invoiceItemData?.ItemValue *
                          invoiceItemData?.Quantity *
                          invoiceItemData?.TaxRates_Selected?.TaxRate
                      ) / 100
                    )
                      .toFixed(2)
                      .toString(),
                    10
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between f-grey mt-2">
            <div className="f-500">Total:</div>
            {Number(
              invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
                (invoiceItemData?.ItemValue *
                  invoiceItemData?.Quantity *
                  invoiceItemData?.TaxRates_Selected?.TaxRate) /
                  100
            ) === 0 ||
            userSelectedCurrency?.CurrencySymbol ===
              defaultCurrencyData?.CurrencySymbol ? (
              <div className="f-black">
                {userSelectedCurrency?.CurrencySymbol}&nbsp;
                {Number(
                  invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
                    (invoiceItemData?.ItemValue *
                      invoiceItemData?.Quantity *
                      invoiceItemData?.TaxRates_Selected?.TaxRate) /
                      100
                ).toFixed(2)}
              </div>
            ) : (
              <div className="f-black d-flex">
                {userSelectedCurrency?.CurrencySymbol}&nbsp;
                {Number(
                  invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
                    (invoiceItemData?.ItemValue *
                      invoiceItemData?.Quantity *
                      invoiceItemData?.TaxRates_Selected?.TaxRate) /
                      100
                ).toFixed(2)}
                <img
                  width={15}
                  height={15}
                  src="/images/msp/exchange-rate-icon.svg"
                  alt=""
                  className="mx-2"
                />
                {defaultCurrencyData?.CurrencySymbol}&nbsp;
                {(
                  exchangeRateData?.Rate *
                  Number(
                    invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
                      (invoiceItemData?.ItemValue *
                        invoiceItemData?.Quantity *
                        invoiceItemData?.TaxRates_Selected?.TaxRate) /
                        100
                  )
                ).toFixed(2)}
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={`add-edit-tax-rate-modal-wrapper ${
          showAddEditTaxRateModal ? "show" : ""
        }`}
      >
        <AddEditTaxRateModal
          show={showAddEditTaxRateModal}
          hideModal={() => {
            setShowAddEditTaxRateModal(false);
          }}
          taxRateData={taxRateData}
          updateTaxList={updateTaxList}
          deleteTaxRate={deleteTaxRate}
        />
      </div>
      {exchangeRateData?.FromCurrency !== defaultCurrencyData?.CurrencyName && (
        <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
          <img
            alt=""
            width={13}
            height={13}
            src="/images/msp/exchange-rate-icon.svg"
            className="mr-2"
          />{" "}
          The exchange rate on{" "}
          {moment(exchangeRateData?.Date ?? "")?.format("DD MMM YYYY")} was 1{" "}
          {exchangeRateData?.FromCurrency} = {exchangeRateData?.Rate}{" "}
          {defaultCurrencyData?.CurrencyName}
        </div>
      )}
      <div className="p-1 d-flex align-items-center justify-content-between border-top">
        <div
          className="d-flex align-items-center f-500 f-grey pointer"
          onClick={() => {
            onDelete(InvoiceItemIndex);
            hideModal();
            return;
            if (invoiceItemData?.objId_Invoice === 0) {
            } else {
              deleteInvoiceItem(invoiceItemData?.Id);
            }
          }}
        >
          {InvoiceItemIndex > -1 && (
            <>
              <img
                alt=""
                className="mx-2"
                src="/images/actioncenter/delete.svg"
              />
              {deleting ? "Deleting.." : "Delete"}
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            disabled={saving}
            onClick={async () => {
              let errors = await checkInvoiceItemValidations();

              if (errors > 0) {
                let _modal = document.querySelector(
                  // ".technology-spend-modal"
                  ".add-item-in-spend-modal"
                );
                let top_error = _modal.getElementsByClassName("input-error")[0];
                if (top_error) {
                  top_error.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  });
                }
              } else {
                if (invoiceItemData?.objId_Invoice > 0) {
                  createInvoiceItem();
                  // onUpdate(InvoiceItemIndex,invoiceItemData);
                } else {
                  let _invoiceItemData = { ...invoiceItemData };
                  _invoiceItemData.CurrencyCode_Selected = userSelectedCurrency;
                  _invoiceItemData.ForeignCurrencyRate =
                    exchangeRateData?.Rate ?? 1;
                  _invoiceItemData.ForeignCurrencyAmount = Number(
                    invoiceItemData?.ItemValue * invoiceItemData?.Quantity +
                      (invoiceItemData?.ItemValue *
                        invoiceItemData?.Quantity *
                        invoiceItemData?.TaxRates_Selected?.TaxRate) /
                        100
                  ).toFixed(2);

                  onCreate(InvoiceItemIndex, _invoiceItemData);
                  hideModal();
                }
              }
            }}
          >
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddItemInSpendModal;

const dummyInvoiceData = {
  objId_Customer: clientId,
  Id: 0,
  Title: "",
  Editable: true,
  Source: 0,
  objId_Integration: 0,
  objId_Invoice: 0,
  InvoiceRef: "",
  InvoiceDate: "0001-01-01T00:00:00",
  objId_SpendCategory: 0,
  SpendCategory_Name: null,
  SpendCategory_Colour: null,
  objId_SpendSubCategory: 0,
  SpendSubCategory_Name: null,
  SpendSubCategory_Colour: null,
  Category_Selected: null,
  SubCategory_Selected: null,
  ItemValue: 0,
  TotalItemValue: 0,
  ItemValue_Text: "£130.00",
  TotalItemValue_Text: "£130.00",
  Quantity: 1,
  Quantity_Text: "x1",
  IsImported: false,
  IsDirectAmount: false,
  IsLinkedToCategory: true,
  TaxName: "Not Taxable",
  TaxValue: 0,
  TaxRates_Selected: {
    ObjectId: 0,
    TaxCode: "Not Taxable",
    TaxName: "Not Taxable",
    TaxRate: 0,
    IsDefault: false,
  },
};

export const CurrencyDropdown = ({
  disabled,
  showFullCurrencyName,
  menuClassName,
  selected,
  inputWidth,
  dropdownId,
  onChange = () => {},
  width,
  currencyList = [
    {
      ObjectId: 4097542,
      CurrencyName: "AFN",
      CurrencySymbol: "؋",
      IsDefault: false,
    },
  ],
  rightAlign,
  forPricing = false
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(selected ?? {});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    if (selected) {
      setSelectedCurrency(selected);
    } else {
      setSelectedCurrency(currencyList[0]);
    }
  }, [selected]);
  return (
    <Dropdown
      // alignRight
      className="transparennt-wrapper-dropdown"
      show={dropdownOpen}
      onToggle={(e) => {
        setDropdownOpen(e);
        // Styling for Add Item modal Currency Dropdown
        if (dropdownId === "add-currencymodal-dropdown" && e) {
          let wrapper = document.getElementById("item-price-input-wrapper");
          console.log(wrapper.offsetWidth);
          const css = `#add-currencymodal-dropdown {
            width: ${wrapper.offsetWidth}px !important; /* Set the width */
            /* You can add more CSS properties here */
          }`;
          const existingStyle = document.getElementById(
            "add-item-currency-dropdown"
          );
          if (existingStyle) {
            existingStyle.remove();
          }
          const style = document.createElement("style");
          style.id = "add-item-currency-dropdown";
          style.innerHTML = css;
          document.body.appendChild(style);
        }
      }}
    >
      <Dropdown.Toggle
        className="w-100 f-12 f-500 f-grey"
        disabled={disabled}
        title={selectedCurrency?.FullCurrencyName}        
      >
        <div className="d-flex align-items-center">
          <div className="f-grey text-ellipsis">
            {selectedCurrency?.CurrencyName}

            {showFullCurrencyName
              ? ` - ${selectedCurrency?.FullCurrencyName}`
              : ""}
          </div>
          {!disabled && (
            <img
              alt=""
              src="/images/big-chevron-down.svg"
              className={`ml-2 ${dropdownOpen ? "rotate180" : ""}`}
            />
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        id={dropdownId}
        className={`${menuClassName} py-2 pl-2 ${forPricing ? 'pricing-dropdown-dir' : 'right-dropdown-dir'}`}
        style={{
          width: "230px",
          minWidth: "120px",
          maxWidth: "500px",
          overflowX: "hidden",
          height: "auto",
        }}
      >
        <div className="d-flex align-items-center bg-grey radius-4 mr-2 p-2 search-box-with-border mb-2"
        
        >
          <img
            src="/images/attack-surface/search-icon.svg"
            className="mr-1"
            alt=""
          />
          <input
            type="text"
            className="flex-1 bg-transparent border-none pl-1"
            placeholder="Search"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            style={{
              width: inputWidth,
            }}
            autoFocus
          />
          <img
            src="/images/settings-v2/header-search-cross.svg"
            alt=""
            className="mx-1 pointer"
            style={{
              visibility: searchString ? "visible" : "hidden",
            }}
            onClick={() => {
              setSearchString("");
            }}
          />
        </div>
        {currencyList?.filter(
          (currency) =>
            currency?.FullCurrencyName?.toLowerCase()?.includes(
              searchString?.toLowerCase()
            ) ||
            currency?.CurrencyName?.toLowerCase()?.includes(
              searchString?.toLowerCase()
            )
        )?.length === 0 && (
          <div className="my-2 f-darkgrey w-100 pl-4">No currencies found</div>
        )}
        <ScrollableDivWithShadow
          className="transparent-scroller"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {currencyList
            ?.filter(
              (currency) =>
                currency?.FullCurrencyName?.toLowerCase()?.includes(
                  searchString?.toLowerCase()
                ) ||
                currency?.CurrencyName?.toLowerCase()?.includes(
                  searchString?.toLowerCase()
                )
            )
            ?.map((item, itemIndex) => {
              return (
                <div
                  key={itemIndex}
                  className="d-flex align-items-center pointer f-grey contextmenu-grey-item"
                  onClick={(e) => {
                    setSelectedCurrency(item);
                    onChange(item);
                    setSearchString("");
                    setDropdownOpen(false)
                    // e.target.closest(".transparennt-wrapper-dropdown").click();
                  }}
                >
                  <img
                    src="/images/msp/dropdown-grey-tick.svg"
                    className="mr-2"
                    alt=""
                    style={{
                      visibility: `${
                        selectedCurrency?.CurrencyName === item?.CurrencyName
                          ? "visible"
                          : "hidden"
                      }`,
                    }}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: HGHighlightText(
                        TrimString(
                          `${item?.CurrencyName} - ${item?.FullCurrencyName}`,
                          100
                        ),
                        searchString
                      ),
                    }}
                  />
                </div>
              );
            })}
        </ScrollableDivWithShadow>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const UpdateAreas = {
  None: 0,
  Default: 1,
  Active: 2,
};

export const TaxRateDropdown = ({
  deleteTaxRate,
  updateTaxList,
  selected,
  onChange = () => {},

  taxList = [
    {
      ObjectId: 0,
      TaxCode: "Not Taxable",
      TaxName: "Not Taxable",
      TaxRate: 0,
      IsDefault: false,
    },
  ],
  imgClassName,
  error,
  disabled
}) => {
  const [selectedTax, setSelectedTax] = useState({
    // ObjectId: 0,
    // TaxCode: "Not Taxable",
    // TaxName: "Not Taxable",
    // TaxRate: 0,
    // IsDefault: false,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showEditTaxRate, setShowEditTaxRate] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});
  const { isLoading, updateTaxRate } = useUpdateTaxRate();
  const [updatingArea, setUpdatingArea] = useState(UpdateAreas.None);
  const filteredTaxList = useMemo(() => (
    taxList.filter((item) => !item.IsDisabled)
  ), [taxList]);

  useEffect(() => {
    if (selected) {
      setSelectedTax(selected);
    }
  }, [selected]);

  useEffect(() => {
    let defaultTaxRate = filteredTaxList?.find((item) => item?.IsDefault);
    if (defaultTaxRate) {
      setSelectedTax(defaultTaxRate);
      onChange(defaultTaxRate);
    }
  }, [filteredTaxList]);

  return (
    <Dropdown
      // alignRight
      className="transparennt-wrapper-dropdown tax-rate-parent-dropdown"
      onToggle={(e) => {
        setDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle
        className="w-100 f-14 f-black"
        style={{
          backgroundColor: "f6f8fb",
          borderRadius: "4px",
          ...(error && { border: "1px solid #f36060" }),
        }}
        disabled={disabled}
      >
        <div className="d-flex align-items-center">
          <div
            className={`${selectedTax?.TaxName ? "f-black" : "f-grey"}  mr-2`}
          >
            {selectedTax?.TaxName ?? "Choose..."}
          </div>
          <img
            alt=""
            src="/images/big-chevron-down.svg"
            className={`${imgClassName ? imgClassName : 'mr-2 ml-auto '}  ${dropdownOpen ? "rotate180" : ""}`}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="transparent-scroller px-1"
        style={{ width: "240px" }}
        id="tax-rate-dropdown"
      >
        <div className="hide-scrollbar" style={{ maxHeight: "250px", overflowY: "auto" }}>
          {filteredTaxList?.map((item, itemIndex) => {
            return (
              <>
                <div
                  id={`tax-rate-${item?.ObjectId}`}
                  className="d-flex align-items-center justify-content-between radius-4 bg-hover-grey p-2 pointer"
                >
                  <div
                    className="d-flex align-items-center pointer f-grey  px-2"
                    onClick={(e) => {
                      setSelectedTax(item);
                      onChange(item);
                      e.target.closest(".transparennt-wrapper-dropdown").click();
                    }}
                  >
                    <img
                      src="/images/msp/dropdown-grey-tick.svg"
                      className="mr-2"
                      alt=""
                      style={{
                        visibility: `${
                          selectedTax?.ObjectId === item?.ObjectId
                            ? "visible"
                            : "hidden"
                        }`,
                      }}
                    />
                    {item?.TaxName}
                  </div>

                  {item?.ObjectId !== 0 && !item.isImported ? (
                    <div className="d-flex align-items-center" style={{ gap: '6px' }}>
                      {item?.IsDefault && (
                        <span className="f-14 f-darkgrey">
                          Default
                        </span>
                      )}
                      <Dropdown
                        alignRight
                        className="transparennt-wrapper-dropdown"
                        onToggle={(e) => {}}
                      >
                        <Dropdown.Toggle className="w-100 f-12 f-500 f-grey">
                          <div className="d-flex align-items-center p-2">
                            <img
                              alt=""
                              src="/images/settings-v2/horizontal-dots.svg"
                              className=""
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                          popperConfig={{ strategy: 'fixed' }}
                          alignRight
                          renderOnMount
                        >
                          <div className="m-2 pointer">
                            <div
                              className="mb-2 f-grey"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.target
                                  .closest(".transparennt-wrapper-dropdown")
                                  .click();
                                setTaxRateData(item);
                                setShowEditTaxRate(true);
                              }}
                            >
                              Edit
                            </div>
                            {(!item?.IsDefault && item.ObjectId > 0) && (
                              <div
                                className="mb-2 f-grey d-flex align-items-center justify-content-between"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUpdatingArea(UpdateAreas.Default);
                                  updateTaxRate({
                                    ...item,
                                    IsDefault: true,
                                  }).then(() => {
                                    setUpdatingArea(UpdateAreas.None);
                                    updateTaxList();
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown")
                                      .click();
                                    e.target
                                      .closest(".tax-rate-parent-dropdown")
                                      .click();
                                  });
                                }}
                              >
                                <span>
                                  Make default
                                </span>
                                {(isLoading && updatingArea === UpdateAreas.Default) && (
                                  <GreyLoader width={13} />
                                )}
                              </div>
                            )}
                            {item.ObjectId > 0 && (
                              <div
                                className="f-grey"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setUpdatingArea(UpdateAreas.Active);
                                  updateTaxRate({
                                    ...item,
                                    IsDisabled: true,
                                  }).then(() => {
                                    setUpdatingArea(UpdateAreas.None);
                                    updateTaxList();
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown")
                                      .click();
                                  });
                                }}
                              >
                                <span>
                                  Disable
                                </span>
                                {(isLoading && updatingArea === UpdateAreas.Active) && (
                                  <GreyLoader width={13} />
                                )}
                              </div>
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : item.isImported ? (
                    <div>
                      <OverlayTrigger
                          placement="bottom"
                          trigger={["hover", "focus"]}
                          delay={{ show: 200, hide: 250 }}
                          container={() => document.getElementById(`tax-rate-${item?.ObjectId}`)}
                          overlay={
                              <Tooltip
                                  id={`tax-rate-tooltip`} 
                                  style={{ position: 'relative', zIndex: 10000,  }}
                              >
                                  <div className="d-flex" style={{ gap: '4px' }}>
                                   <img
                                      alt=""
                                      src={integrationUtils.getImagePath(item?.IntegrationId)}
                                      width="16px"
                                      height="16px"
                                      style={{ 
                                        borderRadius: '2px', 
                                        backgroundColor: '#fff',
                                        textAlign: 'left',
                                        marginTop: '1px' 
                                      }}
                                    />
                                    <span>{`Imported tax rate from ${item?.IntegrationName} can not be edited or deleted`}</span>
                                  </div>
                              </Tooltip>
                          }
                      >
                          <div
                              className=""
                              style={{
                              flex: "1 1 100%",
                              minWidth: 0,
                              }}
                          >
                            <img
                              alt=""
                              src={integrationUtils.getImagePath(item?.IntegrationId)}
                              width="16px"
                              height="16px"
                              style={{ borderRadius: '2px', marginRight: '6px' }}
                            />
                          </div>
                      </OverlayTrigger>
                    </div>
                  ) : (null)}
                </div>
              </>
            );
          })}
        </div>
        <hr className="my-2" />
        {showEditTaxRate ? (
          <AddEditTaxRateModal
            show={showEditTaxRate}
            hideModal={() => {
              setShowEditTaxRate(false);
            }}
            taxRateData={taxRateData}
            updateTaxList={updateTaxList}
            deleteTaxRate={deleteTaxRate}
        />
        ) : (
          <div
            className="f-blue  f-500 mb-2 pointer pl-2"
            onClick={(e) => {
              setShowEditTaxRate(true);
              setTaxRateData({
                ObjectId: 0,
                TaxCode: "",
                TaxName: "",
                TaxRate: "",
                IsDefault: false,
                IsDisabled: false,
              });
            }}
          >
            + Add a tax code
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const AddEditTaxRateModal = ({
  show,
  hideModal,
  taxRateData = {
    ObjectId: 0,
    TaxCode: "",
    TaxName: "",
    TaxRate: "",
  },
  updateTaxList = () => {},
  deleteTaxRate = () => {},
  showDelete = false
}) => {
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState({});
  const addEditTaxRateModalRef = useRef();
  const { isLoading: isTaxRateUpdating, updateTaxRate } = useUpdateTaxRate();
  useEffect(() => {
    console.log(taxRateData);
    setData(taxRateData);
  }, [taxRateData]);

  function handleClickOutside(event) {
    if (
      addEditTaxRateModalRef.current &&
      !addEditTaxRateModalRef.current.contains(event.target)
    ) {
      hideModal();
    }
  }
  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [show]);

  return (
    <div className="add-edit-tax-rate-modal" ref={addEditTaxRateModalRef}>
      {/* header */}
      <div className="p-0 modal-header">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="f-500 ml-2 f-12 f-grey f-500">
            {data.ObjectId === 0 ? "Add" : "Edit"} a tax code
          </div>
          <img
            alt=""
            src="/images/user-detail-close.svg"
            className="mr-1 pointer"
            onClick={hideModal}
          />
        </div>
      </div>

      {/* Body */}
      <div className="p-1 d-flex align-items-center" style={{ gap: '8px' }}>
        <div className="d-flex align-items-center search-supplier-input highlight-input bg-grey radius-4 mb-1 p-1">
          <input
            type="text"
            className=" w-100 border-none bg-transparent"
            value={data?.TaxName}
            placeholder="Name"
            onChange={(e) => {
              let value = e.target.value;
              setData((prev) => ({ ...prev, TaxName: value }));
            }}
            autoFocus
          />
        </div>

        <div className="d-flex align-items-center justify-content-between" style={{ gap: '8px' }}>
          <div className="f-black" style={{ whiteSpace: 'nowrap' }}>Tax Rate</div>
          <div className="d-flex align-items-center search-supplier-input highlight-input radius-4 p-1">
            <input
              type="text"
              className=" w-100 border-none bg-transparent"
              placeholder="0.00"
              value={data?.TaxRate}
              onChange={(e) => {
                let value = e.target.value
                  .toString()
                  .replaceAll(/[^0-9%]/g, "");
                setData((prev) => ({ ...prev, TaxRate: value }));
              }}
              autoFocus
            />
            <span className="ml-1">%</span>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="d-flex align-items-center justify-content-between border-top" style={{ padding: '4px 4px 0 4px' }}>
        {showDelete && (
          <div
            className="d-flex align-items-center f-500 f-grey pointer"
            onClick={() => {
              setDeleting(true);
              deleteTaxRate(data?.ObjectId).then((response) => {
                setDeleting(false);
                if (response?.Success) {
                  updateTaxList({ deletedId: data?.ObjectId });
                  CallToast("Deleted Successfully", false, false, "v2style");
                  hideModal();
                } else {
                  CallToast("Something went wrong", false, false, "v2style");
                }
              });
            }}
          >
            {data.ObjectId !== 0 && (
              <>
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                {deleting ? "Deleting.." : "Delete"}
              </>
            )}
          </div>
        )}
        <div className="d-flex w-100 align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2 f-12" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn f-12"
            disabled={saving || !data?.TaxName || !data?.TaxRate}
            onClick={async () => {
              setSaving(true);
              await updateTaxRate({
                ...data,
                TaxRate: data?.TaxRate?.toString().replaceAll("%", ""),
              }).then((response) => {
                setSaving(false);
                if (response?.Success) {
                  updateTaxList({ created: response?.EntityId });
                  CallToast("Updated Successfully", false, false, "v2style");
                  hideModal();
                } else {
                  CallToast("Something went wrong", false, false, "v2style");
                }
              });
            }}
          >
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

function searchCategoryOrSubcategory(obj, key = "", searchString = "") {
  if (key in obj) {
    if (obj[key]?.toLowerCase()?.includes(searchString?.toLowerCase())) {
      return true;
    }
  }
  return false;
}

function highlightedCategoryOrSubcategory(title, searchValue) {
  if (searchValue !== "") {
    const highlightedText = title.replace(
      new RegExp(searchValue, "gi"),
      (match) => `<span class="list-highlight-class">${match}</span>`
    );

    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  } else {
    return <span>{title}</span>;
  }
}

export const TaxListDropdown = ({
  selected,
  onChange = () => {},
  width,
  taxList = [{}],
  passedClassName,
}) => {
  const [selectedTax, setSelectedTax] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Dropdown
      alignRight
      className="transparennt-wrapper-dropdown w-100"
      onToggle={(e) => {
        setDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle className="w-100 f-14 f-500 ">
        <div className="d-flex align-items-center">
          <div className="f-black">{selected?.TaxName !== '' ? selected?.TaxName : "Not Taxable"}</div>
          <img
            alt=""
            src="/images/big-chevron-down.svg"
            className={`ml-2 ${dropdownOpen ? "rotate180" : ""}`}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={`transparent-scroller ${passedClassName}`}
        style={{
          maxHeight: "200px",
          overflowY: "auto",
          width: "120px",
          minWidth: "120px",
        }}
      >
        {taxList?.map((item, itemIndex) => {
          return (
            <>
              <div
                className="d-flex align-items-center pointer f-grey mb-2 px-2"
                onClick={(e) => {
                  setSelectedTax(item);
                  onChange(item);
                  e.target.closest(".transparennt-wrapper-dropdown").click();
                }}
              >
                <img
                  src="/images/msp/dropdown-grey-tick.svg"
                  className="mr-2"
                  alt=""
                  style={{
                    visibility: `${
                      selectedTax?.ObjectId === item?.ObjectId
                        ? "visible"
                        : "hidden"
                    }`,
                  }}
                />
                {item.TaxName}
              </div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
 

export async function  deleteTaxRate(_taxRateId){
   const authTokens = JSON.parse(localStorage.getItem("tokens"));
  return new Promise((resolve, reject) => {
    getAPIData(`${772}`, authTokens, {
      optionEnum1: _taxRateId, 
    })
      .then((response) => {
        CallToast("Deleted Successfully", false, false, "v2style");
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
