const MagicIcon = (props) => (
  <svg width="16" height="16"  {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_64027_81138)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1459 1.98062L12.4357 0.950751C12.1745 0.571958 11.6048 0.571958 11.3436 0.950752L10.6334 1.98062C10.5862 2.04909 10.526 2.10804 10.456 2.15426L9.40413 2.84961C9.01723 3.10537 9.01723 3.66307 9.40413 3.91882L10.456 4.61417C10.526 4.6604 10.5862 4.71935 10.6334 4.78782L11.3436 5.81769C11.6048 6.19648 12.1745 6.19648 12.4357 5.81769L13.1459 4.78782C13.1931 4.71935 13.2534 4.6604 13.3233 4.61417L14.3752 3.91882C14.7621 3.66307 14.7621 3.10537 14.3752 2.84961L13.3233 2.15426C13.2534 2.10804 13.1931 2.04909 13.1459 1.98062ZM7.97209 4.86882C7.48229 4.15859 6.41423 4.15859 5.92443 4.86882L4.42679 7.04049C4.33826 7.16887 4.22536 7.2794 4.09423 7.36608L1.87611 8.83235C1.15067 9.31189 1.15067 10.3576 1.87611 10.8371L4.09423 12.3034C4.22536 12.3901 4.33826 12.5006 4.42679 12.629L5.92443 14.8006C6.41423 15.5109 7.48229 15.5109 7.97209 14.8006L9.46974 12.629C9.55827 12.5006 9.67117 12.3901 9.80229 12.3034L12.0204 10.8371C12.7459 10.3576 12.7459 9.31189 12.0204 8.83235L9.80229 7.36608C9.67117 7.2794 9.55827 7.16887 9.46974 7.04049L7.97209 4.86882Z" fill="#5244B3"/>
    </g>
    <defs>
    <clipPath id="clip0_64027_81138">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default MagicIcon;
