import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { getAPIData, postData } from "../../../../../Utils/commonApi";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import { useAuth } from "../../../../context/Auth";
import { CallToast } from "../../../../PriceCalculator/modals/CustomToast";
import "./mspCalculatorDropdownAndModals.scss";

const AddSnapToUnitDropdown = (props) => {
  const { refreshData } = props;
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [addUnit, setAddUnit] = useState({});
  const { authTokens } = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const handleWindowClick = (event) => {
      const targetElement = event.target;
      if (
        // !targetElement.closest(".drag-item") &&
        !targetElement.closest(".addfeature")
      ) {
        let newAdd = {
          ...addUnit,
        };
        newAdd.title = "";
        setAddUnit(newAdd);
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  async function createUnit(id, Name) {
    await postData(authTokens, "CreateSnapToUnit", {
      ObjectId: id ? id : 0,
      Name: Name ?? "",
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Saved successfully", false, false, "v2style");
          setAddUnit({});

          if (response?.ObjectTemplateList[0]) {
            console.log("fgfgfgfgfg");
            const updatedData = data?.map((item) => {
              if (item.DDLId === response?.ObjectTemplateList[0]?.DDLId) {
                // Заменяем объект новым
                return {
                  ...response?.ObjectTemplateList[0] /* новые свойства */,
                };
              }
              return item;
            });
            if (
              !updatedData.find(
                (item) => item.DDLId === response?.ObjectTemplateList[0]?.DDLId
              )
            ) {
              updatedData.push(response.ObjectTemplateList[0]);
            }
            console.log(updatedData);
            setData(updatedData);
          }

          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function deleteUnit(objId) {
    await getAPIData(690, authTokens, {
      optionEnum1: objId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Deleted Successfully", false, false, "v2style");
          const updatedData = data?.filter((item) => item.DDLId !== objId);
          setData(updatedData);
          //   hideModal();
          //   cleanModal();
          if (refreshData) {
            refreshData();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Dropdown
      className={`custom_drop-down-snap ${
        props.transparentBackgroundToggle ? "bg-transparent" : ""
      }    ${props.error ? "input-error" : ""}`}
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle
        className={`${props.selected ? "selected" : ""}   ${
          IsDropdownOpen ? "opened" : ""
        } ${props.disabled && props.disabledIcon ? "locked" : ""}
        
        
        
        `}
      >
        {props.children ? (
          props.children
        ) : (
          <div
            className={`selected-option d-flex ${
              props.disabled ? "f-darkgrey" : ""
            }`}
          >
            {props?.selected ? (
              props?.showid ? (
                props?.selected[props.fieldId]
              ) : (
                TrimString(
                  props?.selected[props.fieldValue]?.replaceAll("_", " "),
                  props.trimStringSelected ?? 30
                )
              )
            ) : (
              <span className="drop-placeholder">{props?.placeholder}</span>
            )}
            {props.disabled && props.disabledIcon ? (
              <>
                {" "}
                <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="ml-1"
                  style={{ marginRight: "-2rem" }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </Dropdown.Toggle>
      {!props.disabled && (
        <Dropdown.Menu
          className={`${
            props.rightAlign ? "right-align" : "left-align"
          } hide-scrollbar  ${props.DropDownMenuClassName ?? ""}`}
          style={{
            zIndex: 5,
            maxHeight: "348px",
            width: "220px",
            overflowY: "auto",
          }}
        >
          <div className=" px-3 pt-3 f-darkgrey f-12">Default units</div>
          {data
            ?.filter((el) => el?.ObjId == 0)
            ?.map(
              (
                val //DDLAllowUserEdit
              ) => (
                <>
                  {addUnit?.title == val?.DDLId ? (
                    <div
                      className={`mx-1 edit-task  edit-title addfeature d-flex align-items-center`}
                    >
                      <div className="mx-1 f-darkgrey ">Per</div>
                      <textarea
                        type="text"
                        className={`edit-task  w-100 addfeature`}
                        autoFocus
                        value={
                          addUnit?.Text?.split("Per ")[1]
                            ? addUnit?.Text?.split("Per ")[1]
                            : addUnit?.Text
                        }
                        rows={
                          addUnit?.Text
                            ? Math.ceil(addUnit?.Text?.length / 17)
                            : 1
                        }
                        onChange={(e) => {
                          e.preventDefault();
                          console.log(e.target.value);
                          let newAdd = { ...addUnit };
                          newAdd.Text = e.target.value;
                          setAddUnit(newAdd);
                        }}
                      />
                      <Button
                        style={{
                          padding: "4px",
                          borderRadius: "2px",
                          background: " var(--primary-primary-50, #008FD4)",
                          //   alignSelf: "flex-start",
                        }}
                        onClick={(e) => {
                          if (addUnit?.Text?.trim() !== "") {
                            createUnit(
                              addUnit?.title,
                              `Per ${addUnit?.Text?.trim()}`
                            );
                            //   setAddUnit({});
                          }
                        }}
                      >
                        <img
                          alt=""
                          src="/images/settings-v2/white-tick-save.svg"
                          style={{ padding: "2px" }}
                        />
                      </Button>
                    </div>
                  ) : (
                    <div
                      className={`dropdown_item d-flex hover-on-show-parent
              justify-content-between
              ${!props.selectionArrowAtLeft ? "justify-content-between" : ""}
              
              ${
                props?.selected &&
                props?.selected[props.fieldId] == val[props.fieldId]
                  ? "selected"
                  : ""
              }`}
                      onClick={(e) => {
                        props.onClick(
                          val[props.fieldId],
                          val[props.fieldValue],
                          e,
                          val
                        );
                        {
                          !props.controlClosing &&
                            e.target.closest(".custom_drop-down-snap").click();
                        }
                      }}
                      key={val[props.fieldId]}
                      data-id={val[props.fieldId]}
                      data-value={val[props.fieldValue]}
                    >
                      <div>
                        {props.selectionArrowAtLeft && (
                          <img
                            src={"/images/msp/dropdown-grey-tick.svg"}
                            className="float-left tick"
                            alt=""
                            style={{
                              visibility: `${
                                props.selected &&
                                props.selected[props.fieldId] ==
                                  val[props.fieldId]
                                  ? "visible"
                                  : "hidden"
                              }`,

                              filter: `${
                                props.greyTextAndArrowTheme
                                  ? "invert(46%) sepia(2%) saturate(3030%) hue-rotate(187deg) brightness(84%) contrast(85%)"
                                  : ""
                              }`,
                            }}
                          />
                        )}
                        <span
                          className={`${
                            props.selectionArrowAtLeft ? "pl-1" : ""
                          }
              d-flex 
              ${props.greyTextAndArrowTheme ? "f-grey" : ""}
              
              `}
                        >
                          {" "}
                          {TrimString(
                            val[props.fieldValue]?.replaceAll("_", " "),
                            props.trimStringItem ?? 54
                          )}
                        </span>
                      </div>
                      {/* <div
                    className="menu-dropdown-light"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Dropdown className="device-dropdown">
                      <Dropdown.Toggle>
                        <img
                          alt=""
                          src="/images/horizontal-dots.svg"
                          className="hover-on-show-child"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "96px" }}>
                        <div>
                          <div className="  pointer">
                            <div
                              className="d-flex align-items-center ml-2  drop-item"
                              onClick={(e) => {
                                console.log(val);
                                setAddUnit({
                                  title: val?.DDLId,
                                  Text: val?.DDLText,
                                });
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Edit
                            </div>
                          </div>

                          <div className={`pointer`}>
                            <div
                              className="d-flex align-items-center ml-2  drop-item f-danger"
                              onClick={(e) => {
                                // setShowDeleteModal({
                                //   showModal: true,
                                //   deleteMessage:
                                //     "Are you sure you would like to delete this member from Training certification list? All uploaded certifications will be deleted",
                                //   deleteSentQuestionnaire:
                                //     () => deleteUnit(val?.DDLId),
                                // });
                                deleteUnit(val?.DDLId);
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                    </div>
                  )}
                </>
              )
            )}

          <div className=" px-3 pt-2 f-darkgrey f-12">Custom units</div>
          {data
            ?.filter((el) => el?.ObjId == 1)
            ?.map((val) => (
              <>
                {addUnit?.title == val?.DDLId ? (
                  <div
                    className={`mx-1 edit-task  edit-title addfeature d-flex align-items-center`}
                  >
                    <div className="mx-1 f-darkgrey ">Per</div>
                    <textarea
                      type="text"
                      className={`edit-task  w-100 addfeature`}
                      autoFocus
                      value={
                        addUnit?.Text?.split("Per ")[1]
                          ? addUnit?.Text?.split("Per ")[1]
                          : addUnit?.Text
                      }
                      rows={
                        addUnit?.Text
                          ? Math.ceil(addUnit?.Text?.length / 17)
                          : 1
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        console.log(e.target.value);
                        let newAdd = { ...addUnit };
                        newAdd.Text = e.target.value;
                        setAddUnit(newAdd);
                      }}
                    />
                    <Button
                      style={{
                        padding: "4px",
                        borderRadius: "2px",
                        background: " var(--primary-primary-50, #008FD4)",
                        //   alignSelf: "flex-start",
                      }}
                      onClick={(e) => {
                        if (addUnit?.Text?.trim() !== "") {
                          createUnit(
                            addUnit?.title,
                            `Per ${addUnit?.Text?.trim()}`
                          );
                          //   setAddUnit({});
                        }
                      }}
                    >
                      <img
                        alt=""
                        src="/images/settings-v2/white-tick-save.svg"
                        style={{ padding: "2px" }}
                      />
                    </Button>
                  </div>
                ) : (
                  <div
                    className={`dropdown_item d-flex hover-on-show-parent
              justify-content-between
              ${!props.selectionArrowAtLeft ? "justify-content-between" : ""}
              
              ${
                props?.selected &&
                props?.selected[props.fieldId] == val[props.fieldId]
                  ? "selected"
                  : ""
              }`}
                    onClick={(e) => {
                      props.onClick(
                        val[props.fieldId],
                        val[props.fieldValue],
                        e,
                        val
                      );
                      {
                        !props.controlClosing &&
                          e.target.closest(".custom_drop-down-snap").click();
                      }
                    }}
                    key={val[props.fieldId]}
                    data-id={val[props.fieldId]}
                    data-value={val[props.fieldValue]}
                  >
                    <div>
                      {props.selectionArrowAtLeft && (
                        <img
                          src={"/images/msp/dropdown-grey-tick.svg"}
                          className="float-left tick"
                          alt=""
                          style={{
                            visibility: `${
                              props.selected &&
                              props.selected[props.fieldId] ==
                                val[props.fieldId]
                                ? "visible"
                                : "hidden"
                            }`,

                            filter: `${
                              props.greyTextAndArrowTheme
                                ? "invert(46%) sepia(2%) saturate(3030%) hue-rotate(187deg) brightness(84%) contrast(85%)"
                                : ""
                            }`,
                          }}
                        />
                      )}
                      <span
                        className={`${props.selectionArrowAtLeft ? "pl-1" : ""}
              d-flex 
              ${props.greyTextAndArrowTheme ? "f-grey" : ""}
              
              `}
                      >
                        {" "}
                        {TrimString(
                          val[props.fieldValue]?.replaceAll("_", " "),
                          props.trimStringItem ?? 54
                        )}
                      </span>
                    </div>
                    {val?.DDLAllowUserEdit && (
                      <div
                        className="menu-dropdown-light"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Dropdown className="device-dropdown">
                          <Dropdown.Toggle>
                            <img
                              alt=""
                              src="/images/horizontal-dots.svg"
                              className="hover-on-show-child"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ width: "96px" }}>
                            <div>
                              <div className="  pointer">
                                <div
                                  className="d-flex align-items-center ml-2  drop-item"
                                  onClick={(e) => {
                                    console.log(val);
                                    setAddUnit({
                                      title: val?.DDLId,
                                      Text: val?.DDLText,
                                    });
                                    e.target
                                      .closest(".device-dropdown")
                                      .click();
                                  }}
                                >
                                  Edit
                                </div>
                              </div>

                              <div className={`pointer`}>
                                <div
                                  className="d-flex align-items-center ml-2  drop-item f-danger"
                                  onClick={(e) => {
                                    // setShowDeleteModal({
                                    //   showModal: true,
                                    //   deleteMessage:
                                    //     "Are you sure you would like to delete this member from Training certification list? All uploaded certifications will be deleted",
                                    //   deleteSentQuestionnaire:
                                    //     () => deleteUnit(val?.DDLId),
                                    // });
                                    deleteUnit(val?.DDLId);
                                    e.target
                                      .closest(".device-dropdown")
                                      .click();
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                )}
              </>
            ))}

          {addUnit?.title == "unit" ? (
            <div
              className={`mx-1 edit-task  edit-title addfeature d-flex align-items-center`}
            >
              <div className="mx-1 f-darkgrey ">Per</div>
              <textarea
                type="text"
                // className="border-none bg-transparent"
                className={`edit-task  w-100 addfeature`}
                autoFocus
                //   placeholder="Feature"
                value={addUnit?.Text}
                rows={addUnit?.Text ? Math.ceil(addUnit?.Text?.length / 17) : 1}
                onChange={(e) => {
                  let newAdd = { ...addUnit };
                  newAdd.Text = e.target.value;
                  setAddUnit(newAdd);
                }}
                onBlur={async () => {}}
                onKeyDown={async (e) => {}}
              />
              <Button
                style={{
                  padding: "4px",
                  borderRadius: "2px",
                  background: " var(--primary-primary-50, #008FD4)",
                  alignSelf: "flex-start",
                }}
                onClick={(e) => {
                  if (addUnit?.Text?.trim() !== "") {
                    createUnit(0, `Per ${addUnit?.Text?.trim()}`);
                    setAddUnit({});
                  }
                }}
              >
                <img
                  alt=""
                  src="/images/settings-v2/white-tick-save.svg"
                  style={{ padding: "2px" }}
                />
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div
            className="dropdown_item bg-transparent radius-2 bg-hover-blue f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-3  addfeature"
            onClick={() => {
              setAddUnit({ title: "unit", Text: "" });
            }}
          >
            <img
              src="/images/actioncenter/plusgrey.svg"
              alt=""
              className="mr-2"
            />
            Add unit
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default AddSnapToUnitDropdown;
