import integrationUtils from "../../Utils/integration.utils";
import SubPaymentInvoiceIcon from "../icons/sub-payment-invoice.icon";
import ImageLoader from "./ImageLoader";

const PaymentIconComponent = ({ payment }) => (
  payment?.IsImported ? (
    <div className="position-relative ">
      <img
        alt=""
        className="mr-2"
        src="/images/msp/msp-invoice-icon.svg"
      />
      <ImageLoader
        src={integrationUtils.getImagePath(payment?.objId_Integration, payment?.ToolIcon)}
        alt=""
        className="position-absolute rounded-full"
        width={13}
        height={13}
        style={{
          top: "44%",
          right: "4px",
        }}
        noImageComponent={(
          <img
            src="/images/attack-surface/generic2.svg"
            alt=""
            className="position-absolute rounded-full"
            width={13}
            height={13}
            style={{
              top: "44%",
              right: "4px",
            }}
          />
        )}
      />
    </div>
  ) : payment?.IsSubscription ? (
    <SubPaymentInvoiceIcon
      width="20px"
      height="20px"
      className="d-inline-block"
      style={{ marginLeft: "-2px", marginRight: "5px" }}
    />
  ) : (
    <img
      alt=""
      className="mr-2"
      src="/images/msp/msp-invoice-icon.svg"
    />
  )
)

export default PaymentIconComponent;
