import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./exportManagedSecurityPackageModal.scss";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../../Common/loader";
import { postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import ImageLoader from "../../../Common/ImageLoader";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import ExportClientPDFHeader from "../../exportClientReport/export-client-pdf-header.component";
import ExportClientPDFFooter from "../../exportClientReport/export-client-pdf-footer.component";
import useAttackSurfaceData from "../../exportClientReport/export-client-left-section/attack-surface.section/attack-surface.data";
import { SearchCriteriaEnumASEIssues } from "../../../../constants/ase.constants";
import ExportPackageLeftSection from "./export-package-left-section/export-package-left.section";
import ExportPackagePDFHeader from "./export-package-pdf-header.component";
import { MspSingleTagUI } from "../../integrations/mspIntegrationdetailScreen/MspIntegrationCard";
import { numberWithCommas, renderPriceText } from "../MspClientPriceCalculator";
import { PackageToolTip, QuestionChip } from "../../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import { MspClientPriceCalculatorEmptyRadarChart } from "../../mspSell/mspPriceCalculator/TemplatesModal/PackageView";
import { CyberComplianceGaugeSmaller } from "../../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import { CyberScoreGaugeSmaller } from "../../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import { services_enums_selected } from "../../mspSell/mspPriceCalculator";
import MspComponents from "../../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspComponents";

const ExportManagedSecurityPackageModal = ({ show, hideModal, clientId, pageData, packageId, featuresData, packageData, securityCapabilitiesData }) => {
  const { authTokens } = useAuth()
  const clientReportref = useRef(null);
  const [modalLoading, setModalLoading] = useState(false)
  const [showContentOnPrint, setShowContentOnPrint] = useState(false)
  const [showCoverNote, setShowCoverNote] = useState(true);
  const [coverNoteMessage, setCoverNoteMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const {
    data: aseData,
    filterList: aseFilterList,
    isLoading: isASELoading,
    setFilterList: setASEFilterList,
    fetchAttackSurfaceData
  } = useAttackSurfaceData({
    defaultSearchCriterias: [
      {
        criteriaEnum: SearchCriteriaEnumASEIssues.Installed_Date,
        SearchParams: ["60"]
      }
    ]
  });

  useEffect(() => {
    if (!show) return;
    fetchAttackSurfaceData(clientId)
  }, [show, aseFilterList]);

  useEffect(() => {
    if (show) {
      setModalLoading(true)
      Promise.all([
        getCoverNoteData(),
      ]).then(() => {
        setModalLoading(false)
      }).catch((err) => {

      });
    }
  }, [show]);


  const [packageDetails, setPackageDetails] = useState(pageData?.ScenarioHheaderList?.find(item => item?.objId_Scenario == packageId));

  useEffect(() => {
    if (packageData && packageId != 0) {
      setPackageDetails(pageData?.ScenarioHheaderList?.find(item => item?.objId_Scenario == packageId));
    }
  }, [pageData, packageId])

  const [coverNoteData, setCoverNoteData] = useState({})
  function getCoverNoteData() {
    return new Promise((resolve, reject) => {
      postData(authTokens, "ExportManagedSecurityPackageCoverNote", {
        ObjId_Customer: clientId,
        CoverNote: "",
        ShowCoverNote: true
      }).then((response) => {
        setCoverNoteData(response);
        resolve(response?.ClientSecurityHeader);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  const [showPriceIncludingTax, setShowPriceIncludingTax] = useState(false);
  const [showFinancialBreakdown, setShowFinancialBreakdown] = useState(true);
  const [showIncludedFeatures, setShowIncludedFeatures] = useState(true);
  const [showServiceComponents, setShowServiceComponents] = useState(true);
  const [showNistCsfAlignment, setShowNistCsfAlignment] = useState(true);
  const [showSecurityCapabilities, setShowSecurityCapabilities] = useState(true);
  const [showSecurityCapabilitiesBySelected, setShowSecurityCapabilitiesBySelected] = useState({
    id: 0,
    label: 'NIST CSF Function'
  });

  const showSecurityCapabilitiesBy = [{
    id: 0,
    label: 'NIST CSF Function'
  },
  {
    id: 1,
    label: 'Security Pillar'
  }];
  const [showSecurityPosture, setShowSecurityPosture] = useState(true);
  const [securityPostureData, setSecurityPostureData] = useState([{
    id: 0,
    title: 'Current Posture',
    selected: true
  },
  {
    id: 1,
    title: 'Projected CyberScore',
    selected: true
  },
  {
    id: 2,
    title: 'Projected CyberResilience',
    selected: true
  }])

  const [isCoverNoteMoved, setIsCoverNoteMoved] = useState(false);
  const [isSecurityPostureMoved, setIsSecurityPostureMoved] = useState(false);

  const [sorting, setSorting] = useState({
    'cover_note': 0,
    'security_posture': 1,
    'security_capabilities': 2,
    'financial_breakdown': 3,
    'included_features': 4,
    'service_components': 5,
    'nist_csf_alignment': 6
  })

  const keys = Object.entries(sorting).sort(([, a], [, b]) => a - b).map(([key]) => key);

  return (
    <Modal
      show={show}
      onHide={hideModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="export-client-report-modal"
    >
      <Modal.Body className="p-0 h-100">
        {modalLoading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="w-100 h-100 d-flex">
            <ExportPackageLeftSection
              clientReportref={clientReportref}
              coverNoteMessage={coverNoteMessage}
              hideModal={hideModal}
              setCoverNoteMessage={setCoverNoteMessage}
              setShowNistCsfAlignment={setShowNistCsfAlignment}
              setShowContentOnPrint={setShowContentOnPrint}
              setShowCoverNote={setShowCoverNote}
              setShowPriceIncludingTax={setShowPriceIncludingTax}
              setShowIncludedFeatures={setShowIncludedFeatures}
              setShowServiceComponents={setShowServiceComponents}
              showServiceComponents={showServiceComponents}
              setShowSecurityCapabilities={setShowSecurityCapabilities}
              setShowSecurityCapabilitiesBySelected={setShowSecurityCapabilitiesBySelected}
              setShowSecurityPosture={setShowSecurityPosture}
              setShowFinancialBreakdown={setShowFinancialBreakdown}
              showNistCsfAlignment={showNistCsfAlignment}
              showCoverNote={showCoverNote}
              showPriceIncludingTax={showPriceIncludingTax}
              showIncludedFeatures={showIncludedFeatures}
              showSecurityCapabilities={showSecurityCapabilities}
              showSecurityCapabilitiesBySelected={showSecurityCapabilitiesBySelected}
              showSecurityCapabilitiesBy={showSecurityCapabilitiesBy}
              showSecurityPosture={showSecurityPosture}
              securityPostureData={securityPostureData}
              setSecurityPostureData={setSecurityPostureData}
              showFinancialBreakdown={showFinancialBreakdown}
              aseFilterList={aseFilterList}
              setASEFilterList={setASEFilterList}
              setSorting={setSorting}
              setIsCoverNoteMoved={setIsCoverNoteMoved}
              setIsSecurityPostureMoved={setIsSecurityPostureMoved}
            />

            {/* Close Button */}
            <div
              className="p-2 rounded-full pointer export-modal-close-icon h-fit-content w-fit-content position-absolute"
              style={{
                right: "15px",
                top: "15px"
              }}
              onClick={() => {
                hideModal();
              }}
            >
              <img
                src="/images/attack-surface/cross-icon.svg"
                alt=""
                width={18}
                height={18}
                className=""
              />
            </div>

            {/* RIGHT SECTION */}
            <div
              className="w-70 transparent-scroller h-100"
              style={{
                overflowY: "auto",
                background: "#F6F8FB",
              }}
            >
              {loading || isASELoading ? (
                <div
                  className="d-flex align-items-center justify-content-center w-100 "
                  style={{
                    height: "85vh",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <div className="p-4">
                  <div
                    className="radius-16 border1  bg-white"
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <table
                      className="bg-white main-page-wrapper w-100 padding-00"
                      ref={clientReportref}
                    >
                      <thead>
                        <div className="page-header">
                          <div>
                            <ExportClientPDFHeader
                              coverNoteData={coverNoteData}
                            />
                          </div>
                          <div className="hr" />
                        </div>
                      </thead>
                      <tfoot>
                        <ExportClientPDFFooter style={{ opacity: 0 }} />
                        <ExportClientPDFFooter style={{ position: 'fixed', bottom: 0 }} />
                      </tfoot>
                      <div
                        className="export-cust-modal-content"
                        style={{
                          margin: "2rem 4rem",
                        }}
                      >
                        <div className="hide-on-print">
                          <ExportPackagePDFHeader
                            coverNoteData={coverNoteData}
                          />
                        </div>

                        <div
                          className="w-100 d-flex justify-content-between main-page-wrapper"
                          style={{
                            paddingTop: "3rem",
                          }}
                        >
                          <div className="w-50 mx-3">
                            <div className="f-600 f-20 line-height-36 f-black pb-4">
                              {packageData?.ScenarioHeader?.Name}
                            </div>

                            <div>

                              <div className="w-100 d-flex align-items-center mb-2">
                                <div className="w-30 f-darkgrey">
                                  Security provider
                                </div>
                                <div className="w-70 d-flex align-items-center">
                                  {coverNoteData?.MspName ? (
                                    <>
                                      {coverNoteData?.MSPLogo && (
                                        <ImageLoader
                                          src={coverNoteData?.MSPLogo}
                                          alt=""
                                          className="mr-2 radius-4"
                                          width={20}
                                          height={20}
                                          noImageComponent={(
                                            <div className="mr-2 radius-4">
                                              <ImageWithInitials
                                                initials={coverNoteData?.MSPNameInitials || coverNoteData?.MspName?.[0]}
                                                width={20}
                                                height={20}
                                                background={"purple"}
                                              />
                                            </div>
                                          )}
                                        />
                                      )}
                                      {coverNoteData?.MspName}
                                    </>
                                  ) : (
                                    "NA"
                                  )}
                                </div>
                              </div>
                              <div className="w-100 d-flex align-items-center mb-2">
                                <div className="w-30 f-darkgrey">
                                  Billing type
                                </div>
                                <div className="w-70 d-flex align-items-center">
                                  <MspSingleTagUI
                                    itemName={packageData?.ScenarioHeader?.PackageType_Text ?? 'NA'}
                                  />
                                </div>
                              </div>
                              {packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLText && (
                                <div className="w-100 d-flex align-items-center mb-2">
                                  <div className="w-30 f-darkgrey">
                                    Billing period
                                  </div>
                                  <div className="w-70 d-flex align-items-center f-400 f-14 f-black">
                                    {packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLText}
                                  </div>
                                </div>
                              )}
                              {packageData?.CurrencyCode_Selected?.CurrencyName && (
                                <div className="w-100 d-flex align-items-center mb-2">
                                  <div className="w-30 f-darkgrey">
                                    Currency
                                  </div>
                                  <div className="w-70 d-flex align-items-center f-400 f-14 f-black">
                                    {packageData?.CurrencyCode_Selected?.CurrencyName}
                                  </div>
                                </div>
                              )}
                              {packageData?.ScenarioHeader?.PackageType_Enum == 0 && (
                                <div className="w-100 d-flex align-items-center mb-2">
                                  <div className="w-30 f-darkgrey">
                                    Qty Seats
                                  </div>
                                  <div className="w-70 d-flex align-items-center f-400 f-14 f-black">
                                    {packageData?.MSPPackagePrice?.RequiredSeatsOverriden ?? 0}
                                  </div>
                                </div>
                              )}
                              <div className="w-100 d-flex align-items-center mb-2">
                                <div className="w-30 f-darkgrey">
                                  Price
                                </div>
                                <div className="w-70 d-flex align-items-center f-400 f-14 f-black">
                                  {/* {renderPriceText(
                                    packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLSort,
                                    (packageData?.MSPPackagePrice?.TotalCost - packageData?.MSPPackagePrice?.Contribution - packageData?.MSPPackagePrice?.Discount)?.toFixed(2),
                                    packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                                  )} */}
                                  {
                                    `${packageDetails?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${showPriceIncludingTax ?
                                      (packageDetails?.MonthlyPrice - packageDetails?.Contribution)?.toFixed(2) :
                                      (parseFloat(packageDetails?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - packageDetails?.Contribution)?.toFixed(2)} /${packageDetails?.BillingPeriod_Text}`
                                  }
                                  <span className="f-400 f-14 f-darkgrey ml-2">
                                    {showPriceIncludingTax ? '(inc tax)' : '(ex tax)'}
                                  </span>
                                </div>
                              </div>
                              {coverNoteData?.ClientSecurityHeader?.HeaderDate && (
                                <div className="w-100 d-flex align-items-center mb-2">
                                  <div className="w-30 f-darkgrey">
                                    Proposal Date
                                  </div>
                                  <div className="w-70 d-flex align-items-center f-400 f-14 f-black">
                                    {coverNoteData?.ClientSecurityHeader?.HeaderDate}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="d-flex my-2 f-14 f-400 f-black">
                              <img
                                alt=""
                                width={13}
                                height={13}
                                className="mr-1 mt-1"
                                src="/images/msp/green-circular-tick.svg"
                              />
                              This package is aligned to the NIST Cybersecurity Framework (CSF) 2.0.
                            </div>

                            {/* Cover Note */}
                            {!isCoverNoteMoved && showCoverNote && (
                              <div className="mt-4">
                                <div
                                  className="covernote-text pl-3 mb-3"
                                  dangerouslySetInnerHTML={{
                                    __html: coverNoteMessage,
                                  }}
                                />
                                <div className="d-flex align-items-center">
                                  <ImageLoader
                                    src={coverNoteData?.MSPUserLogo}
                                    alt=""
                                    className="mr-2 rounded-full"
                                    width={20}
                                    height={20}
                                    noImageComponent={(
                                      <div className="mr-2 rounded-full">
                                        <ImageWithInitials
                                          initials={
                                            coverNoteData?.MSPUserNameInitials
                                            || coverNoteData?.MspUserName?.[0]
                                          }
                                          width={20}
                                          height={20}
                                          background={"purple"}
                                        />
                                      </div>
                                    )}
                                  />
                                  <div>
                                    <div className="f-400 f-14 f-black">
                                      {coverNoteData?.MspUserName}
                                    </div>
                                    <div className="f-12 f-400 f-darkgrey">
                                      {coverNoteData?.MspUserDesignation}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Security Posture */}
                          {!isSecurityPostureMoved && showSecurityPosture && (
                            <div className="mx-3 bg-grey-imp radius-8"
                              style={{
                                minWidth: '500px',
                                maxWidth: '500px'
                              }}>
                              <div className="px-3 pt-3">
                                <div className="f-500 f-14 greyText2">
                                  Security Posture
                                </div>

                                <div className="d-flex justify-content-center">
                                  <MspClientPriceCalculatorEmptyRadarChart
                                    dataList={[
                                      packageData?.ScenarioHeader?.InspectScore,
                                      packageData?.ScenarioHeader?.ProtectScore,
                                      packageData?.ScenarioHeader?.DetectScore,
                                      packageData?.ScenarioHeader?.RespondScore,
                                      packageData?.ScenarioHeader?.RecoverScore,
                                    ]}
                                    passedWidth={355}
                                    passedHeight={336}
                                    passedMarginBottom={'10px'}
                                    colorPassed={"#5244B3"}
                                  />
                                </div>
                              </div>

                              {securityPostureData[0]?.selected && (
                                <div className="p-3">
                                  <div className="nist-component-table">
                                    <div className="f-500 f-12 f-darkgrey">
                                      NIST Component
                                    </div>
                                    <div className="f-500 f-12 f-darkgrey">
                                      Current
                                    </div>
                                    <div className="f-500 f-12 f-darkgrey">
                                      Predicted
                                    </div>
                                  </div>

                                  {
                                    Object.values(services_enums_selected)?.map((serviceNistItem) => {
                                      return (
                                        <div className="nist-component-table">
                                          <div className="f-400 f-14 f-black">
                                            {serviceNistItem?.DDLText}
                                          </div>
                                          <div className="f-400 f-14 f-grey">
                                            {pageData?.CurrentPosture[serviceNistItem?.DDLText]}
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="f-500 f-14 f-black mr-2">
                                              {packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText]}
                                            </div>
                                            {
                                              packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] != pageData?.CurrentPosture[serviceNistItem?.DDLText] && <div className="d-flex align-items-center">
                                                <img
                                                  alt=""
                                                  src={`${packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                  className="mr-1"
                                                />
                                                <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? 'f-green-light' : 'redText'}`}>
                                                  {Math.abs(packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] - pageData?.CurrentPosture[serviceNistItem?.DDLText])}
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )}

                              {securityPostureData[1]?.selected && (
                                <div>
                                  <div className="d-flex justify-content-between p-3 border-top">
                                    <div>
                                      <div className="f-500 f-14 f-grey">
                                        Projected CyberScore
                                      </div>
                                      <div className="d-flex align-items-center mt-2">
                                        <div className="f-500 f-18 f-black mr-2">
                                          {packageData?.ProjectedScenarioPosture?.CyberScore}
                                        </div>
                                        {
                                          packageData?.ProjectedScenarioPosture?.CyberScore != pageData?.CurrentPosture?.CyberScore && <div className="d-flex align-items-center">
                                            <img
                                              alt=""
                                              src={`${packageData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                              className="mr-1"
                                            />
                                            <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? 'f-green-light' : 'redText'}`}>
                                              {Math.abs(packageData?.ProjectedScenarioPosture?.CyberScore - pageData?.CurrentPosture?.CyberScore)}
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>

                                    {/* <CyberScoreGauge */}
                                    <CyberScoreGaugeSmaller
                                      value={packageData?.ScenarioHeader?.CyberScore}
                                      showTitle={false}
                                    />
                                  </div>
                                </div>
                              )}

                              {securityPostureData[2]?.selected && (
                                <div>
                                  <div className="d-flex justify-content-between p-3 border-top">
                                    <div>
                                      <div className="f-500 f-14 f-grey">
                                        Projected CyberResilience
                                      </div>
                                      <div className="d-flex align-items-center mt-2">
                                        <div className="f-500 f-18 f-black mr-2">
                                          {packageData?.ProjectedScenarioPosture?.CyberCompliance}%
                                        </div>
                                        {
                                          packageData?.ProjectedScenarioPosture?.CyberCompliance != pageData?.CurrentPosture?.CyberCompliance && <div className="d-flex align-items-center">
                                            <img
                                              alt=""
                                              src={`${packageData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                              className="mr-1"
                                            />
                                            <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? 'f-green-light' : 'redText'}`}>
                                              {Math.abs(packageData?.ProjectedScenarioPosture?.CyberCompliance - pageData?.CurrentPosture?.CyberCompliance)}%
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </div>

                                    {/* <CyberComplianceGauge */}
                                    <CyberComplianceGaugeSmaller
                                      value={packageData?.ScenarioHeader?.CyberCompliance}
                                      showTitle={false}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        {
                          keys?.map((item) => {
                            return (
                              <div>
                                {/* Cover Note */}
                                {isCoverNoteMoved && item == 'cover_note' && showCoverNote && (
                                  <div className="mt-4">
                                    <div
                                      className="covernote-text pl-3 mb-3"
                                      dangerouslySetInnerHTML={{
                                        __html: coverNoteMessage,
                                      }}
                                    />
                                    <div className="d-flex align-items-center">
                                      <ImageLoader
                                        src={coverNoteData?.MSPUserLogo}
                                        alt=""
                                        className="mr-2 rounded-full"
                                        width={20}
                                        height={20}
                                        noImageComponent={(
                                          <div className="mr-2 rounded-full">
                                            <ImageWithInitials
                                              initials={
                                                coverNoteData?.MSPUserNameInitials
                                                || coverNoteData?.MspUserName?.[0]
                                              }
                                              width={20}
                                              height={20}
                                              background={"purple"}
                                            />
                                          </div>
                                        )}
                                      />
                                      <div>
                                        <div className="f-400 f-14 f-black">
                                          {coverNoteData?.MspUserName}
                                        </div>
                                        <div className="f-12 f-400 f-darkgrey">
                                          {coverNoteData?.MspUserDesignation}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {/* Security Posture */}
                                {isSecurityPostureMoved && item == 'security_posture' && showSecurityPosture && (
                                  <div className="mx-auto mt-3 bg-grey-imp radius-8"
                                    style={{
                                      minWidth: '500px',
                                      maxWidth: '500px'
                                    }}>
                                    <div className="px-3 pt-3">
                                      <div className="f-500 f-14 greyText2">
                                        Security Posture
                                      </div>

                                      <div className="d-flex justify-content-center">
                                        <MspClientPriceCalculatorEmptyRadarChart
                                          dataList={[
                                            packageData?.ScenarioHeader?.InspectScore,
                                            packageData?.ScenarioHeader?.ProtectScore,
                                            packageData?.ScenarioHeader?.DetectScore,
                                            packageData?.ScenarioHeader?.RespondScore,
                                            packageData?.ScenarioHeader?.RecoverScore,
                                          ]}
                                          passedWidth={355}
                                          passedHeight={336}
                                          passedMarginBottom={'10px'}
                                          colorPassed={"#5244B3"}
                                        />
                                      </div>
                                    </div>

                                    {securityPostureData[0]?.selected && (
                                      <div className="p-3">
                                        <div className="nist-component-table">
                                          <div className="f-500 f-12 f-darkgrey">
                                            NIST Component
                                          </div>
                                          <div className="f-500 f-12 f-darkgrey">
                                            Current
                                          </div>
                                          <div className="f-500 f-12 f-darkgrey">
                                            Predicted
                                          </div>
                                        </div>

                                        {
                                          Object.values(services_enums_selected)?.map((serviceNistItem) => {
                                            return (
                                              <div className="nist-component-table">
                                                <div className="f-400 f-14 f-black">
                                                  {serviceNistItem?.DDLText}
                                                </div>
                                                <div className="f-400 f-14 f-grey">
                                                  {pageData?.CurrentPosture[serviceNistItem?.DDLText]}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                  <div className="f-500 f-14 f-black mr-2">
                                                    {packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText]}
                                                  </div>
                                                  {
                                                    packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] != pageData?.CurrentPosture[serviceNistItem?.DDLText] && <div className="d-flex align-items-center">
                                                      <img
                                                        alt=""
                                                        src={`${packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                        className="mr-1"
                                                      />
                                                      <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? 'f-green-light' : 'redText'}`}>
                                                        {Math.abs(packageData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] - pageData?.CurrentPosture[serviceNistItem?.DDLText])}
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    )}

                                    {securityPostureData[1]?.selected && (
                                      <div>
                                        <div className="d-flex justify-content-between p-3 border-top">
                                          <div>
                                            <div className="f-500 f-14 f-grey">
                                              Projected CyberScore
                                            </div>
                                            <div className="d-flex align-items-center mt-2">
                                              <div className="f-500 f-18 f-black mr-2">
                                                {packageData?.ProjectedScenarioPosture?.CyberScore}
                                              </div>
                                              {
                                                packageData?.ProjectedScenarioPosture?.CyberScore != pageData?.CurrentPosture?.CyberScore && <div className="d-flex align-items-center">
                                                  <img
                                                    alt=""
                                                    src={`${packageData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                    className="mr-1"
                                                  />
                                                  <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? 'f-green-light' : 'redText'}`}>
                                                    {Math.abs(packageData?.ProjectedScenarioPosture?.CyberScore - pageData?.CurrentPosture?.CyberScore)}
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          </div>

                                          {/* <CyberScoreGauge */}
                                          <CyberScoreGaugeSmaller
                                            value={packageData?.ScenarioHeader?.CyberScore}
                                            showTitle={false}
                                          />
                                        </div>
                                      </div>
                                    )}

                                    {securityPostureData[2]?.selected && (
                                      <div>
                                        <div className="d-flex justify-content-between p-3 border-top">
                                          <div>
                                            <div className="f-500 f-14 f-grey">
                                              Projected CyberResilience
                                            </div>
                                            <div className="d-flex align-items-center mt-2">
                                              <div className="f-500 f-18 f-black mr-2">
                                                {packageData?.ProjectedScenarioPosture?.CyberCompliance}%
                                              </div>
                                              {
                                                packageData?.ProjectedScenarioPosture?.CyberCompliance != pageData?.CurrentPosture?.CyberCompliance && <div className="d-flex align-items-center">
                                                  <img
                                                    alt=""
                                                    src={`${packageData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                    className="mr-1"
                                                  />
                                                  <div className={`f-500 f-14 ${packageData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? 'f-green-light' : 'redText'}`}>
                                                    {Math.abs(packageData?.ProjectedScenarioPosture?.CyberCompliance - pageData?.CurrentPosture?.CyberCompliance)}%
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          </div>

                                          {/* <CyberComplianceGauge */}
                                          <CyberComplianceGaugeSmaller
                                            value={packageData?.ScenarioHeader?.CyberCompliance}
                                            showTitle={false}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {/* Security Capabilities */}
                                {item == 'security_capabilities' && showSecurityCapabilities && (
                                  <div>
                                    <hr className="mt-5" />
                                    <div className="export-report-print-only mt-5" style={{ display: "none" }} />
                                    <div className="f-600 f-16 mb-2 f-black">
                                      Security Capabilities
                                    </div>

                                    <div className="border-gray w-100">
                                      <div className="security_capabilities_table">
                                        {
                                          (() => {
                                            const originalArray = showSecurityCapabilitiesBySelected?.id == 0 ? securityCapabilitiesData?.SecurityCapabiltiesByNist_List : securityCapabilitiesData?.SecurityCapabiltiesBySecurityPillar_List;
                                            const newArray = [...originalArray];

                                            const length = newArray.length;
                                            if (length % 3 !== 0) {
                                              const elementsToAdd = 3 - (length % 3);
                                              for (let i = 0; i < elementsToAdd; i++) {
                                                newArray.push({ Name: '', SecurityCapabiltiesData_List: [] });
                                              }
                                            }

                                            return newArray.map((securityCapabilityItem, itemIndex, array) => {
                                              const isLastRow = itemIndex >= array.length - (array.length % 3 || 3);
                                              return (
                                                <div key={itemIndex} className={isLastRow ? 'no-border-bottom h-100' : ''}>
                                                  <div className="f-500 f-14 f-black mb-1">
                                                    {securityCapabilityItem?.Name}
                                                  </div>
                                                  {securityCapabilityItem?.SecurityCapabiltiesData_List?.length > 0 ? (
                                                    securityCapabilityItem?.SecurityCapabiltiesData_List.map((dataItem, dataIndex) => (
                                                      <div key={dataIndex} className="d-flex align-items-center mb-2">
                                                        <img
                                                          width={14}
                                                          alt=""
                                                          className="mr-2"
                                                          src={
                                                            dataItem?.IsGreyColour
                                                              ? '/images/icons/check-gray-circle.svg'
                                                              : '/images/icons/check-blue-circle.svg'
                                                          }
                                                        />
                                                        <div className="f-400 f-12 f-black">
                                                          {dataItem?.Name}
                                                        </div>
                                                        {dataItem?.IsManagedBySOC && (
                                                          <img
                                                            width={14}
                                                            alt=""
                                                            className="ml-2"
                                                            src="/images/icons/monitor-icon.svg"
                                                          />
                                                        )}
                                                      </div>
                                                    ))
                                                  ) : (
                                                    <div className="f-400 f-12 f-black">{securityCapabilityItem?.Name && 'No security functionality in this area'}</div>
                                                  )}
                                                </div>
                                              );
                                            });
                                          })()
                                        }
                                      </div>

                                      <div className="d-flex align-items-center border-top p-2">
                                        <img
                                          width={14}
                                          alt=""
                                          className="mr-1 ml-2"
                                          src="/images/icons/check-gray-circle.svg"
                                        />
                                        <div className="f-400 f-12 f-darkgrey">Pre-existing</div>
                                        <img
                                          width={14}
                                          alt=""
                                          className="mr-1 ml-3"
                                          src="/images/icons/check-blue-circle.svg"
                                        />
                                        <div className="f-400 f-12 f-darkgrey">New!</div>
                                        <img
                                          width={14}
                                          alt=""
                                          className="mr-1 ml-3"
                                          src="/images/icons/monitor-icon.svg"
                                        />
                                        <div className="f-400 f-12 f-darkgrey">Monitored by SOC</div>
                                      </div>
                                    </div>

                                  </div>
                                )}

                                {/* Financial Breakdown */}
                                {item == 'financial_breakdown' && showFinancialBreakdown && (
                                  <div
                                    style={{
                                      pageBreakBefore: "always",
                                    }}
                                  >
                                    <div className="export-report-print-only mt-4" style={{ display: "none" }} />
                                    <hr className="hide-on-print mt-5" />
                                    <div className="f-600 f-16 mb-2 f-black">
                                      Financial Breakdown
                                    </div>

                                    <div className="my-2">
                                      <div className="f-500 f-12 f-darkgrey my-1">
                                        Package Pricing
                                      </div>

                                      {packageData?.ScenarioHeader?.PackageType_Enum == 0 ?
                                        <div className="border-block">
                                          <div
                                            className='border-item f-12 f-500 f-darkgrey p-2 per_asset_financial_breakdown_table'
                                          >
                                            <div className="f-500 f-12 f-darkgrey">Asset Type</div>
                                            <div className="f-500 f-12 f-darkgrey">Inc. per Seat</div>
                                            <div className="f-500 f-12 f-darkgrey">Client Qty</div>
                                            <div className="f-500 f-12 f-darkgrey">Seats Req</div>
                                          </div>
                                          {packageData?.MSPPackagePrice?.PackagePerSeat_List
                                            ?.map((data, dataIndex) => {
                                              return (
                                                <div
                                                  className='border-item f-grey px-2 per_asset_financial_breakdown_table'
                                                >
                                                  <div className="f-400 f-14 f-black">
                                                    {data.Name}
                                                  </div>
                                                  <div className="f-400 f-14 f-grey">
                                                    {data?.PerSeat}
                                                  </div>
                                                  <div className="f-400 f-14 f-grey">
                                                    {data?.ClientQty}
                                                  </div>
                                                  <div className={`f-600 f-14 ${data?.SeatRequired == (packageData?.MSPPackagePrice?.PackagePerSeat_List?.reduce((max, obj) => obj?.SeatRequired > max?.SeatRequired ? obj : max, packageData?.MSPPackagePrice?.PackagePerSeat_List[0]))?.SeatRequired ? 'txt-blue' : 'f-grey'}`}>
                                                    {data?.SeatRequired}
                                                    <span className="f-600 f-14 txt-blue">
                                                      {data?.SeatRequired == (packageData?.MSPPackagePrice?.PackagePerSeat_List?.reduce((max, obj) => obj?.SeatRequired > max?.SeatRequired ? obj : max, packageData?.MSPPackagePrice?.PackagePerSeat_List[0]))?.SeatRequired && '*'}
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          <div className="per_asset_financial_breakdown_table border-item">
                                            <div className="f-500  pl-2">Req. Seats</div>
                                            <div></div>
                                            <div></div>
                                            <div className="f-600 f-14 f-black">
                                              {packageData?.MSPPackagePrice?.RequiredSeatsOverriden}
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-end f-400 f-12 f-darkgrey mr-3v">
                                            <span className="txt-blue mr-2">
                                              *
                                            </span>
                                            {" "}highest ‘seats req’ value used to determine qty of seats to be billed
                                          </div>
                                        </div> : <div className="border-block">
                                          <div
                                            className='border-item f-12 f-500 f-darkgrey p-2 per_asset_financial_breakdown_table'
                                          >
                                            <div className="f-500 f-12 f-darkgrey">Asset Type</div>
                                            <div className="f-500 f-12 f-darkgrey">Qty</div>
                                            <div className="f-500 f-12 f-darkgrey">Unit Price</div>
                                            <div className="f-500 f-12 f-darkgrey">Line Total</div>
                                          </div>
                                          {packageData?.MSPPackagePrice?.PackagePerAsset_List
                                            ?.map((data, dataIndex) => {
                                              return (
                                                <>
                                                  {data.UnitCost !== 0 && (
                                                    <div
                                                      className='border-item f-grey px-2 per_asset_financial_breakdown_table'
                                                    >
                                                      <div className="f-400 f-14 f-black">
                                                        {data.Name}
                                                      </div>
                                                      <div className="f-400 f-14 f-grey">
                                                        {data?.Qty}
                                                      </div>
                                                      <div className="f-400 f-14 f-grey">
                                                        {" "}
                                                        <span>
                                                          {packageData?.CurrencyCode_Selected
                                                            ?.CurrencySymbol ?? "£"}
                                                        </span>
                                                        {Number(data.UnitSell).toFixed(2)}
                                                      </div>
                                                      <div className="f-400 f-14 f-grey">
                                                        <span>
                                                          {" "}
                                                          {packageData?.CurrencyCode_Selected
                                                            ?.CurrencySymbol ?? "£"}
                                                        </span>
                                                        {Number(data?.SellTotal)?.toFixed(2)}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })}
                                        </div>
                                      }
                                    </div>

                                    {
                                      packageData?.ScenarioHeader?.PackageType_Enum == 1 &&
                                      <div className="my-2">
                                        <div className="f-500 f-12 f-darkgrey my-1">
                                          Contribution
                                        </div>

                                        <div className="border-block">
                                          <div className='border-item f-12 f-500 f-darkgrey p-2 contribution_table'>
                                            <div className='f-500 f-12 f-darkgrey'>Contribution</div>
                                            <div className='f-500 f-12 f-darkgrey'>Qty.</div>
                                            <div className='f-500 f-12 f-darkgrey'>Price</div>
                                            <div className='f-500 f-12 f-darkgrey'>Total</div>
                                            <div className='f-500 f-12 f-darkgrey'>Period</div>
                                          </div>
                                          {
                                            packageData?.MSPPackagePrice?.PackageMapCurrentSpend_List?.length > 0 ? <div>
                                              {packageData?.MSPPackagePrice?.PackageMapCurrentSpend_List
                                                ?.map((data, dataIndex) => {
                                                  return (
                                                    <>
                                                      {data.UnitCost !== 0 && (
                                                        <div className='border-item p-2 contribution_table'>
                                                          <div className="f-400 f-14 f-black">{data.ItemName}</div>
                                                          <div className="f-400 f-14 f-grey">{data.Quantity}</div>
                                                          <div className="f-400 f-14 f-grey">{data.Price}</div>
                                                          <div className="f-400 f-14 f-grey">{data.Total}</div>
                                                          <div className="f-400 f-14 f-grey">{data.Period_Text}</div>
                                                        </div>
                                                      )}
                                                    </>
                                                  );
                                                })}
                                            </div> : <div className="border-item p-2 f-400 f-14 f-grey">
                                              No contributions have been added
                                            </div>
                                          }

                                          <div className="contribution_table p-2">
                                            <div className="f-400 f-14 greyText2">
                                              Total
                                            </div>
                                            <div></div>
                                            <div></div>
                                            <div>
                                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                                            </div>
                                            <div className="f-400 f-14 greyText2">
                                              /{packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }

                                    <div className="my-2 w-100 d-flex">
                                      <div className="w-50"></div>
                                      <div className="w-50">
                                        <div className="f-500 f-12 f-darkgrey my-1">
                                          Summary
                                        </div>

                                        <div className="border-parent radius-8">
                                          {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
                                            <div className="per_asset_total_pricing_table-updated border-item">
                                              <div className="f-400 f-14 f-black pl-2">Seat Price</div>
                                              <div></div>
                                              <div className="f-400 f-14 f-grey">
                                                {renderPriceText(
                                                  packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLSort,
                                                  Number(packageData?.MSPPackagePrice?.SellOverridden)?.toFixed(2),
                                                  packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                                                )}
                                              </div>
                                            </div>
                                          }

                                          <div className="per_asset_total_pricing_table-updated border-item">
                                            <div className="f-400 f-14 f-black pl-2">Subtotal</div>
                                            <div></div>
                                            <div className="f-400 f-14 f-grey">
                                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                              {Number(packageData?.MSPPackagePrice?.Subtotal)?.toFixed(2)}
                                            </div>
                                          </div>

                                          {
                                            packageData?.ScenarioHeader?.PackageType_Enum == 1 &&
                                            <div className="per_asset_total_pricing_table-updated  border-item">
                                              <div className="f-400 f-14 f-black pl-2">
                                                Contribution
                                              </div>
                                              <div className="f-400 f-14 txt-blue underline">
                                                -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                                {numberWithCommas(Number(packageData?.MSPPackagePrice?.Contribution)?.toFixed(2))}
                                              </div>

                                              <div className="f-400 f-14 f-grey">
                                                {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                                {numberWithCommas(
                                                  Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution).toFixed(2)
                                                )}
                                              </div>
                                            </div>
                                          }

                                          <div className="per_asset_total_pricing_table-updated  border-item">
                                            <div className="f-400 f-14 f-black pl-2">
                                              Discount
                                            </div>
                                            <div className="f-400 f-14 f-black" >
                                              -{packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                              {numberWithCommas(Number(packageData?.MSPPackagePrice?.Discount)?.toFixed(2))}
                                            </div>

                                            <div className="f-400 f-14 f-grey">
                                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                              {numberWithCommas(
                                                Number(packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - packageData?.MSPPackagePrice?.Discount).toFixed(2)
                                              )}
                                            </div>
                                          </div>

                                          <div className="per_asset_total_pricing_table-updated  border-item">
                                            <div className="f-400 f-14 f-black pl-2">
                                              Tax Rate
                                              <span className="f-400 f-14 f-darkgrey ml-2">
                                                {packageData?.TaxRates_Selected?.TaxName}
                                              </span>
                                              <span className="f-400 f-14 f-darkgrey ml-1">
                                                ({packageData?.TaxRates_Selected?.TaxRate}%)
                                              </span>
                                            </div>
                                            <div className="f-400 f-14 f-black">
                                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                              {Number(packageData?.MSPPackagePrice?.Tax)?.toFixed(2)}
                                            </div>
                                            <div className="f-400 f-14 f-grey">
                                              {packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                                              {Number(
                                                packageData?.MSPPackagePrice?.Subtotal - packageData?.MSPPackagePrice?.Contribution - packageData?.MSPPackagePrice?.Discount +
                                                packageData?.MSPPackagePrice?.Tax
                                              ).toFixed(2)}{" "}
                                            </div>
                                          </div>

                                          <div className="per_asset_total_pricing_table-updated  border-item">
                                            <div className="f-600 f-14 f-black pl-2">Total</div>
                                            <div></div>
                                            <div className="f-600 f-14 f-grey">
                                              {renderPriceText(
                                                packageData?.ScenarioHeader?.BillingPeriod_Selected
                                                  ?.DDLSort,
                                                (packageData?.MSPPackagePrice?.TotalCost - packageData?.MSPPackagePrice?.Contribution - packageData?.MSPPackagePrice?.Discount)?.toFixed(2),
                                                packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                )}

                                {/* Included Features */}
                                {item == 'included_features' && showIncludedFeatures && (
                                  <div>
                                    <hr className="mt-5" />
                                    <div className="f-600 f-16 mb-2 f-black">
                                      Included Features
                                    </div>

                                    {
                                      packageData?.Nist_All?.length > 0 ?
                                        <div className="border-block  radius-8">
                                          <div className="included_features_table border-item px-2 py-1">
                                            <div className="f-500 f-12 f-darkgrey">Service</div>
                                            <div className="f-500 f-12 f-darkgrey">Features</div>
                                          </div>
                                          {
                                            packageData?.Nist_All?.map((item) => {
                                              return (
                                                <div className="included_features_table border-item p-2">
                                                  <div>
                                                    <div className="d-flex">
                                                      <img
                                                        alt=""
                                                        width={14}
                                                        height={14}
                                                        className="mx-2 mt-1"
                                                        src='/images/other-apps-icon.svg'
                                                      />
                                                      <div>
                                                        <div className="f-400 f-14 f-black line-height-22">
                                                          {item?.Headline}
                                                        </div>
                                                        <div className="f-12 f-darkgrey">
                                                          {item?.SecurityPillar_Selected?.DDLText && `${item?.SecurityPillar_Selected?.DDLText} • `}
                                                          {
                                                            item?.MonthlyPrice_Text
                                                          }{" "}
                                                          {
                                                            item
                                                              ?.SnapToUnit_Selected
                                                              ?.DDLColour
                                                          }{" "}
                                                          /
                                                          {
                                                            item
                                                              ?.BillingPeriod_Selected
                                                              ?.DDLColour
                                                          }{" "}
                                                          <span className="f-12 txt-blue">
                                                            {item?.IsOnDemand ? ' • POD' : ''}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    {item?.SecurityPack_Bullet_List?.length > 0 ? (
                                                      <>
                                                        {item?.SecurityPack_Bullet_List?.map((item) => {
                                                          return (
                                                            <div className="d-flex align-items-center my-1 f-12 f-400 f-black">
                                                              <img
                                                                alt=""
                                                                className="ml-1 mr-2"
                                                                src="/images/msp/green-circular-tick.svg"
                                                              />
                                                              {item?.Text}
                                                            </div>
                                                          );
                                                        })}
                                                      </>
                                                    ) : (
                                                      <div className="d-flex align-items-center my-2 f-grey f-12">
                                                        This service has no features
                                                      </div>
                                                    )}

                                                  </div>
                                                </div>
                                              )
                                            })
                                          }
                                        </div> : <div
                                          className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                                        >
                                          No data for included features
                                        </div>
                                    }
                                  </div>
                                )}

                                {item == 'service_components' && showServiceComponents && (
                                  <div
                                    style={{
                                      pageBreakBefore: "always",
                                    }}
                                  >
                                    <div className="export-report-print-only mt-4" style={{ display: "none" }} />
                                    <hr className="hide-on-print mt-5" />
                                    <div className="f-600 f-16 mb-2 f-black">
                                      Service Components
                                    </div>
                                    <MspComponents
                                      containerStyle={'border-container'}
                                      componentsData={packageData?.Nist_All?.flatMap((obj) =>
                                        Array.isArray(obj?.ScoreContributionSelected_List)
                                          ? obj.ScoreContributionSelected_List.filter(item => item?.ContributionName != null)
                                          : []
                                      ).reduce((acc, current) => {
                                        console.log('acc', acc)
                                        const existingItem = acc.find(item => item.ContributionName === current.ContributionName);

                                        if (existingItem) {
                                          existingItem.List_ScoreProducts = [
                                            ...existingItem.List_ScoreProducts,
                                            ...current.List_ScoreProducts.filter(
                                              product => !existingItem.List_ScoreProducts.some(
                                                existingProduct => existingProduct.objId_Product === product.objId_Product
                                              )
                                            )
                                          ];
                                        } else {
                                          acc.push(current);
                                        }

                                        return acc;
                                      }, [])}
                                    />
                                  </div>
                                )}

                                {/* Alignment to NIST CSF 2.0 Framework */}
                                {item == 'nist_csf_alignment' && showNistCsfAlignment && (
                                  <div
                                    style={{
                                      pageBreakBefore: "always",
                                    }}
                                  >
                                    <div className="export-report-print-only mt-4" style={{ display: "none" }} />
                                    <hr className="hide-on-print mt-5" />
                                    <div className="f-600 f-16 mb-2 f-black">
                                      Alignment to NIST CSF 2.0 Framework
                                    </div>

                                    {
                                      featuresData?.length > 0 ?
                                        <div className="border-block w-100">
                                          {
                                            featuresData?.map((featureItem, featureIndex) => {
                                              return (
                                                <div>
                                                  {
                                                    featureItem?.Question_List?.filter(question =>
                                                      question?.AnswerId !== 0 && question?.AnswerBool === true
                                                    )?.length > 0 && <div className="w-100">
                                                      <div className={`f-500 f-14 f-black bg-grey px-2 py-1 ${featureIndex < featuresData?.length - 1 ? 'border-item' : ''
                                                        } ${featureIndex > 0 ? 'border-top-item' : ''}`}>
                                                        {featureItem?.Title}
                                                      </div>
                                                      <div className="">
                                                        {
                                                          featureItem?.Question_List?.map((question, questionIndex) => {
                                                            return (
                                                              <div>
                                                                {question?.AnswerId !== 0 &&
                                                                  question?.AnswerBool === true && (
                                                                    <div className={`d-flex ${questionIndex < featureItem?.Question_List?.filter(question =>
                                                                      question?.AnswerId !== 0 && question?.AnswerBool === true
                                                                    )?.length - 1 ? 'border-item' : ''
                                                                      }`}>
                                                                      <img
                                                                        alt=""
                                                                        height={14}
                                                                        className="ml-2 my-3"
                                                                        src="/images/msp/green-circular-tick.svg"
                                                                      />
                                                                      <>
                                                                        {question?.InheritedText && (
                                                                          <div className="table-title radius-4 f-500">
                                                                            <div className="img">
                                                                              <img
                                                                                alt=""
                                                                                className=" "
                                                                                src="/images/msp/arrow-enter.svg"
                                                                              />
                                                                            </div>

                                                                            <div className="title f-darkgrey">
                                                                              Inherited from `
                                                                              {question?.InheritedText}`{" "}
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                        <div
                                                                          className=""
                                                                          style={{ padding: "12px" }}
                                                                        >
                                                                          <div className="d-flex align-items-start justify-content-between">
                                                                            <div>
                                                                              {question.Explanation}

                                                                              <div className="d-flex flex-wrap mt-2">
                                                                                {
                                                                                  question?.nistType &&
                                                                                  <div
                                                                                    className='px-2 rounded-full d-flex align-items-center border1 f-grey bg-greyish f-12 f-500 mr-2'
                                                                                  >
                                                                                    {question?.nistType}
                                                                                  </div>
                                                                                }
                                                                                {question?.NISTAssetClassification_List?.map(
                                                                                  (chip, chipIndex) => {
                                                                                    return (
                                                                                      <div className="position-relative tooltip-parent">
                                                                                        <QuestionChip
                                                                                          iconUrl={
                                                                                            chip.IsFramework
                                                                                              ? "/images/msp/msp-framework-icon.svg"
                                                                                              : null
                                                                                          }
                                                                                          title={chip?.Name}
                                                                                          className={"mr-2"}
                                                                                          IsBlue={
                                                                                            chip?.Name?.toLowerCase() ===
                                                                                            "best practice"
                                                                                          }
                                                                                        />
                                                                                        {!chip.IsFramework && (
                                                                                          <PackageToolTip
                                                                                            content={chip?.Detail}
                                                                                          />
                                                                                        )}
                                                                                      </div>
                                                                                    );
                                                                                  }
                                                                                )}
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              )
                                            })
                                          }
                                        </div> : <div
                                          className="f-400 f-12 f-darkgrey p-5 d-flex align-items-center justify-content-center w-100 hg-border radius-4 my-2"
                                        >
                                          No data for alignment to NIST CSF 2.0 framework
                                        </div>
                                    }

                                  </div>
                                )}

                              </div>
                            )
                          })
                        }

                      </div>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExportManagedSecurityPackageModal;