import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './row-ordering.styles.scss';

const RowOrdering = ({ rows, setRows, draggableParentOffset }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function handleDragEnd(result, list) {
    if (!result.destination) {
      return list;
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    const resultList = items.map((item, index) => {
      return {
        ...item,
        SortOrder: index + 1,
      };
    })
    return resultList;
  }

  function handleDragEndRows(result) {
    const resultList = handleDragEnd(result, rows);
    setRows(resultList);
  }

  return (
    <div>
      {
        rows?.length > 0 ?
          <div className="row-ordering">
            <span className="section-title f-darkgrey">Rows</span>
            <DragDropContext onDragEnd={handleDragEndRows}>
              <Droppable
                direction="vertical"
                droppableId="droppableSection"
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {rows &&
                      rows
                        .map((section, i) => (
                          <DraggableItem
                            item={section}
                            index={i}
                            draggableParentOffset={draggableParentOffset}
                          />
                        ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div> : <div className="f-400 f-13 f-darkgrey py-2 px-3">
            No options yet
          </div>
      }
    </div>
  );
}

export default RowOrdering;

const DraggableItem = ({
  item, index, draggableParentOffset
}) => {
  return (
    <Draggable
      key={item?.FieldKey}
      draggableId={item?.FieldKey}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`section-item d-flex justify-content-between`}
            key={index}
            index={index}
            style={{
              ...provided.draggableProps.style,
              ...(provided.draggableProps.style.top && draggableParentOffset && {
                top: draggableParentOffset.y + provided.draggableProps.style.top,
                left: draggableParentOffset.x + provided.draggableProps.style.left
              })
            }}
          >
            <>
              <div
                className="d-inline-flex w-75"
                onClick={() => { }}
                style={{
                  alignItems: "center",
                  paddingTop: "3px",
                }}
              >
                <img
                  alt=""
                  src="/images/actioncenter/contextmenu.svg"
                  className="pr-2"
                  style={{ cursor: "grab" }}
                  onClick={(e) => { }}
                />
                <span className="f-grey">
                  {item.FieldName}
                </span>
              </div>
            </>
          </div>
        );
      }}
    </Draggable>
  );
}
