import React, { useMemo, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CyberCompliance from "../../CyberCompliance/CyberCompliance";
import CyberScore2 from "../../CyberScore/CyberScore2";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import ThreatLevel from "../../ThreatLevel/ThreatLevel";
import "./mspAttackSurface.scss";
import { Line } from "react-chartjs-2";
import CreateCategorySpendModal from "./CreateCategorySpendModal";
import TechnologySpendModal from "./technologySpendModal/TechnologySpendModal";
import { useAuth } from "../../context/Auth";
import { IndependentGetAPIData, getAPIData, getPageDataApi, postData } from "../../../Utils/commonApi";

import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { GreyLoader } from "../../Theme/APILoaders";
import MspDonutChart, { MspDonutChartForDashboard } from "./MspDonutChart";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { BlueComingSoonTag } from "../../supplyChainMonitoring/Index";
import { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MSPTechnicalDrillDownCyberThreatLevelModal from "../../AttackSurface/MSPTechnicalDrillDownCyberThreatLevelModal";
import "./technologySpendFilter/technologyFilters.scss";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createStaticRanges } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { mspClientDashboardDatePickerState } from "../../../redux/mspClientStates/actions";
import MSPCatSubcatSortFilters from "../clients/MSPCatSubcatSortFilters";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import CyberGroupModal from "../../AttackSurface/CyberGroupModal";
import { AttestationPopUp } from "../../AttackSurface/KPIpopup/KPIpopup";
import ExploreMappingModal from "../../SecurityAssessments/ExploreMappingModal";
import { MspTechSpendScreenRadarChart } from "../mspClientPriceCalculator/MspClientPriceCalculatorRadarChart";
import { Attestation_Icon_Mappings } from "../../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import { CustomHGDropdown } from "../clients/createClientScreens/CyberComplianceForGroupModal";
import TechSpendSubscriptionView from "./TechSpendSubscriptionView";
import TechSpendListView from "./TechSpendListView";
import TechSpendHomeScreenFilters from "./TechSpendHomeScreenFilters";
import HomeScreenSubscriptionFilters from "./HomeScreenSubscriptionFilters";
import { TechnologySpendColorList } from "../../../Utils/CommonFunctions";
import { getMenuItemId } from "../../SideNav/SideNav";
import {
  NO_ACCESS_TEXT,
  NoAccessUI,
  getAccessStateValue,
  viewOnlyAccess,
} from "../../App/App";
import DisabledButtonWithToolTip from "../../Theme/DisabledButtonWithToolTip";
import TechnologySpendModalV2 from "./technologySpendModal/TechnologySpendModalV2";
import TooltipWrapper from "../../TooltipWrapper/TooltipWrapper";
import useQueryParams from "../../../hooks/query-params.hook";
import { ROUTE_ACTIONS } from "../../../constants/app.constants";
import MyLineChart from "../../chartjs/my-line.chart";
import MSPAddDropdown from "./msp-add.dropdown";
import useUserActions from "../../../hooks/actions/user.actions";
import { CLIENT_GETTING_STARTED_TYPE } from "../../../constants/user.constants";

const CategoriesShowState = {
  ALL: 0,
  ONLY_VISIBLE: 1,
}

const MspAttackSurface = ({ setClientData, isSaas, setClientDetail, setActivePackageName, setActivePackageId, activePackageName, activePackageId }) => {
  const queryParams = useParams();
  var clientId = null;
  if (queryParams?.clientId) {
    clientId = queryParams?.clientId;
  } else {
    clientId = /*isSaas*/ 0;
  }
  const graphRef = useRef();
  const SearchInput = useRef();
  const [searchInput, setSearchInput] = useState(false);
  const [searchString, setSearchString] = useState("");
  const { getParam, clearParams } = useQueryParams();
  const action = getParam("action");
  const integrationName = getParam('integrationName')
  const integrationCategory = getParam('integrationCategory')
 
  const TechnologySpendHeaderRef  = useRef(null);;
  const { authTokens } = useAuth();
  const history = useHistory();
  const [pageData, setPageData] = useState({});
  const [techSpendDashboardData, setTechSpendDashboardData] = useState({});
  const [showCyberGroupModal, setShowCyberGroupModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [chartBoxList, setChartBoxList] = useState(_boxData);
  const [changeHappened, setChangeHappened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [updatingTechnologySpend, setUpdatingTechnologySpend] = useState(false);;
  const [updatingListView, setUpdatingListView] = useState(false);
  const [technologyCategoriesToShow, setTechnologyCategoriesToShow] =
    useState(CategoriesShowState.ONLY_VISIBLE);
  const [viewType, setViewType] = useState({
    id: 1,
    category: "Category View",
  });
  const [defaultSpendSelectedTab, setDefaultSpendSelectedTab] = useState(undefined);
  const [activeFilters, setActiveFilters] = useState([]);
  const [subscriptionActiveFilters, setSubscriptionActiveFilters] = useState(
    []
  );
  const menuItemId = getMenuItemId(
    `/${window.location.pathname.split("/")[5]}`
  );
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility
        ?.MenuItem_List
    : null;
  const menuItemData = MenuItem_List.find(
    (obj) => obj?.MenuItemId === parseInt(menuItemId)
  );
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role
    : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData
    : null;
  const ownClientID = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.objId_Client
    : null;
  const { updateGettingStarted } = useUserActions();

    const isViewOnly = /*Number(ownClientID) == Number(clientId) ? true :*/ false;

  const [showGraph, setShowGraph] = useState(true);
  const [showTax, setShowTax] = useState(false);
  const [updatingTax, setUpdatingTax] = useState(false);

  const [technologySpendData, setTechnologySpendData] = useState({});

  const [loadingKPIGraphData, setLoadingKPIGraphData] = useState(false);

  const [graphPeriod, setGraphPeriod] = useState({
    id: 0,
    category: "Last 3 months",
  });
  const [showTechnologySpendModal, setShowTechnologySpendModal] =
    useState(false);
  const [showCategorySpendModal, setShowCategorySpendModal] = useState(false);
  const [donutColorIds, setDonutColorIds] = useState([]);

  // Date filter Setup

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
    endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
    startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
    startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
    startOfCurrentYear: startOfYear(new Date()),
    endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
  };

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];

    return customDateObjects;
  };

  const sideBar = sideBarOptions();

  const staticRanges = [...createStaticRanges(sideBar)];

  const selectedDateFilter = useSelector((state) => {
    let dashboard_date = {
      ...state?.mspclient?.mspClientDashboardDatePickerState,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  });
  const dispatch = useDispatch();

  function renderGraphByPoints(_points) {
    let labelList = [..._points].map((item) => item?.Label);
    let valueList = [..._points].map((item) => item?.Value);

    return {
      labels: labelList,
      datasets: [
        {
          data: valueList,
          label: "",
          borderColor: "#008fd4",
          backgroundColor: "#008fd409",
          fill: true,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  }

  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  const _annotation = {
    type: "line",
    borderColor: "black",
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      enabled: true,
      content: (ctx) => "Average: " + average(ctx).toFixed(2),
      position: "end",
    },
    scaleID: "y",
    value: (ctx) => {
      console.log(ctx);
      return average(ctx);
    },
  };
  const data = {
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        label: "Africa",
        borderColor: "#008fd4",
        backgroundColor: "#008fd409",
        fill: true,
        pointRadius: 0,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    devicePixelRatio: 2,
    tension: 0.4,
    annotation: {
      annotations: [
        {
          type: "line",
          borderColor: "red",
          borderDash: [1, 1],
          borderDashOffset: 0,
          borderWidth: 1,
          label: {
            enabled: true,
            content: (ctx) => "Average: " + average(ctx).toFixed(2),
            // position: "end",
          },
          scaleID: "x",
          value: (ctx) => {
            console.log(ctx);
            return average(ctx);
          },
        },
      ],
    },
    datalabels: {
      display: false,
    },
    style: {
      strokewidth: 1,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    plugins: {
      autocolors: true,
      annotation: {
        annotations: {
          _annotation,
        },
      },
      datalabels: {
        backgroundColor: "transparent",
        color: "transparent",
      },
    },
  };

  async function getActivePackage() {
    await IndependentGetAPIData(984, authTokens, {
      optionEnum1: clientId
    })
      .then((response) => {
        if (response/*?.mr?.Success*/) {
          setActivePackageName(response?.PackageName);
          setActivePackageId(response?.objId_Scenario);
        } 
      })
      .catch((err) => {});
  }

  async function getGraphData(_period = 0) {
    await getAPIData(`${603}`, authTokens, {
      optionEnum1: clientId,
      optionEnum2: _period,
      isSaas,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setChartBoxList(response?.KpiBox_List);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoadingKPIGraphData(false);
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function getPageData() {
    await getAPIData(`${
      602
    // 676
    }`, authTokens, {
      optionEnum1: clientId,
      optionEnum2: 0,
      isSaas,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setPageData(response);
          if (setClientData) {
            setClientData(response);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function UpdateCategorySorting(e) {
    setCategoriesLoading(true);
    postData(authTokens, "UpdatePSACategorySettings", {
      CategorySettings: JSON.stringify(e),
      CustomerId: clientId,
      isSubcat: false,
    }).then(() => {
      getTechnologySpendData();
    });
  }

  async function getClientDetail() {
    await getAPIData(`${579}`, authTokens, {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      optionEnum98: clientId,
    })
      .then((response) => {
        if (response.mr?.Success) {
          if (setClientDetail) {
            setClientDetail(response?.detail);
          }
        }
      })
      .catch((err) => {});
    }
    function UpdateListViewSorting(e) {
      setUpdatingListView(true);
      // getPageDataApi("UpdateSortingVisibility", authTokens, {
      postData(authTokens, "UpdateSortingVisibility", {
        SortingJson: JSON.stringify(e),
        CustomerId: clientId,
      }).then(() => {
        getListViewData();
      });
    }
    function UpdateSubscriptionViewSorting(e) {
      setUpdatingSubscriptionView(true);
      // getPageDataApi("UpdateSortingVisibility", authTokens, {
      postData(authTokens, "UpdateSortingVisibility", {
        SortingJson: JSON.stringify(e),
        CustomerId: clientId,
        SortSettingsArea: 1,
      }).then(() => {
        getSubscriptionViewData();
      });
  }

  function getTechnologySpendData(kwargs) {
    setUpdatingTechnologySpend(true);

    return new Promise((resolve, reject) => {
      postData(authTokens, "GetTechnologySpend", {
        FromDate: convertToDDMMYYYY(
          kwargs?.startDate ?? selectedDateFilter?.startDate
        ),
        ToDate: convertToDDMMYYYY(
          kwargs?.endDate ?? selectedDateFilter?.endDate
        ),
        ObjId_Customer: clientId,
        isSaas,
      })
        .then((response) => {
          try {
            setUpdatingTechnologySpend(false);
            setCategoriesLoading(false);
            if (response?.mr?.Success) {
              setShowTax(response?.ShowTax);
              localStorage.setItem("msPshowTax", response?.ShowTax);
              let categorySettings = [...response?.CategorySettings];
              let visibleCategories = response?.TechnologySpend_SummaryList?.filter(
                (item) => item?.CategoryVisibility
              ).sort((a, b) => a?.CategorySortOrder - b?.CategorySortOrder);
              let notVisibleCategories = response?.TechnologySpend_SummaryList?.filter(
                (item) => !item?.CategoryVisibility
              ).sort((a, b) => a?.CategorySortOrder - b?.CategorySortOrder);
              let categoryList = [...visibleCategories, ...notVisibleCategories]
                .map((item) => ({
                  ...item,
                  SubCatSettings: categorySettings?.find(
                    (settingItem) => settingItem?.CategoryID === item?.CategoryId
                  )?.SubCatSettings,
                  Visibility: item?.CategoryVisibility,
                  FieldKey: "C" + item?.CategoryId,
                  FieldName: item?.CategoryName,
                  SortOrder: item?.CategorySortOrder,
                }));
              setTechnologySpendData({
                ...response,
                TechnologySpend_SummaryList: categoryList
              });
              setTechSpendDashboardData({
                ...response,
                TechnologySpend_SummaryList: categoryList,
              });

              resolve({
                ...response,
                TechnologySpend_SummaryList: categoryList,
              });
            } else {
              CallToast("Something went wrong", false, false, "v2style");
              reject("Something went wrong");
              setCategoriesLoading(false);
            }
          } catch (e) {
            console.log(e, "errrro");
          }
        })
        .catch((err) => {
          setCategoriesLoading(false);
          reject("Something went wrong");
        });
    });
  }

  const amountOfHiddenCategories = useMemo(() => {
    return technologySpendData?.TechnologySpend_SummaryList?.filter(
      (item) => item?.Visibility === false
    ).length;
  }, [technologySpendData]);

  // List View States
  const [listViewPageNumber, setListViewPageNumber] = useState(1);
  const [listViewFilters, setListViewFilters] = useState([]);
  const [listViewData, setListViewData] = useState({});
  const [listViewNextPageLoading, setListViewNextPageLoading] = useState(false);
  const [listViewInvoiceData, setListViewInvoiceData] = useState();
  // {"SearchCriterias":[{"criteriaEnum":1,"SearchParams":["12/30/2023 8:33:07 PM","3/30/2024 8:33:07 PM"]},{"criteriaEnum":2,"SearchParams":["14262","16253"]},{"criteriaEnum":0,"SearchParams":["32655858","65965863"]},{"criteriaEnum":4,"SearchParams":["0","100"]}],}
  // public enum SearchCriteriaEnum
  // {
  //     Supplier,         //........0
  //     InvoiceDate,      //........1
  //     Categories,       //........2
  //     SubCategories,    //........3
  //     Amount            //........4
  // }
  function getListViewData(kwargs) {
    if(!kwargs?.stopLoader){
// alert('jhvjhvhhvh')
      setUpdatingListView(true);
    }
    setListViewPageNumber(kwargs?.listViewPageNumber ?? 1);
    let nextPageLoading = kwargs?.nextPage;
    let filters = getListViewFilters(kwargs);
    setListViewFilters(filters);

    return new Promise((resolve, reject) => {
      getPageDataApi("TechSpendListView", authTokens, {
        spendFilters: kwargs?.filters
          ? JSON.stringify({ SearchCriterias: filters })
          : JSON.stringify({ SearchCriterias: listViewFilters }),
        pageNo: kwargs?.listViewPageNumber ?? 1,
        objId_Customer: clientId,
        pageSize: 20,
        isSaas: isSaas,
        searchString: kwargs?.searchString ?? searchString,
        sortColumn:kwargs?.sortColumn ?? "",
        sortOrder: kwargs?.sortOrder ?? 0
      })
        .then((response) => {
          setUpdatingListView(false);
          setListViewNextPageLoading(false);
          try {
            if (response?.mr?.Success) {
              if (nextPageLoading) {
                let new_invoice_list = [...response?.detail?.InvoiceList];

                let new_data = { ...listViewData };
                new_data.InvoiceList = [
                  ...listViewData.InvoiceList,
                  ...new_invoice_list,
                ];
                new_data.nextPage = response?.detail?.nextPage;
                setListViewData(new_data);
              } else {
                setListViewData({
                  ...response?.detail,
                  menuSorting: response?.detail?.SortVisibility?.map(
                    (item) => ({
                      ...item,
                      Visibility: item?.ColumnVisibility,
                      SortOrder: item?.ColumnSortOrder,
                      FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                      FieldName: item?.ColumnName,
                    })
                  ),
                });
              }
              resolve("Success");
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          } catch (e) {
            console.log(e, "errrro");
          }
        })
        .catch((err) => {
          setCategoriesLoading(false);
          reject("Something went wrong");
        });
    });
  }
  // Subscription View States
  const [subscriptionViewPageNumber, setSubscriptionViewPageNumber] =
    useState(1);
  const [subscriptionViewFilters, setSubscriptionViewFilters] = useState([]);
  const [subscriptionViewData, setSubscriptionViewData] = useState({});
  const [subscriptionViewNextPageLoading, setSubscriptionViewNextPageLoading] =
    useState(false);
  const [subscriptionViewInvoiceData, setSubscriptionViewInvoiceData] =
    useState();
  const [updatingSubscriptionView, setUpdatingSubscriptionView] =
    useState(false);
  const [subscriptionSearchString, setSubscriptionSearchString] = useState("");
  const [subscriptionTaxStatus, setSubscriptionTaxStatus] = useState(true);
  // {"SearchCriterias":[{"criteriaEnum":1,"SearchParams":["12/30/2023 8:33:07 PM","3/30/2024 8:33:07 PM"]},{"criteriaEnum":2,"SearchParams":["14262","16253"]},{"criteriaEnum":0,"SearchParams":["32655858","65965863"]},{"criteriaEnum":4,"SearchParams":["0","100"]}],}
  // public enum SearchCriteriaEnum
  // {
  //     Supplier,         //........0
  //     InvoiceDate,      //........1
  //     Categories,       //........2
  //     SubCategories,    //........3
  //     Amount            //........4
  // }
  function getSubscriptionViewData(kwargs) {
    setUpdatingSubscriptionView(true);
    setSubscriptionViewPageNumber(kwargs?.listViewPageNumber ?? 1);
    let nextPageLoading = kwargs?.NextPage;
    let filters = getListViewFilters(kwargs);
    setSubscriptionViewFilters(filters);

    return new Promise((resolve, reject) => {
      getPageDataApi("GetMSPSubscriptionList", authTokens, {
        spendFilters: kwargs?.filters
          ? JSON.stringify({ SearchCriterias: filters })
          : JSON.stringify({ SearchCriterias: subscriptionViewFilters }),
        pageNo: kwargs?.subscriptionViewPageNumber ?? 1,
        objId_Customer: clientId,
        pageSize: 20,
        isSaas: isSaas,
        searchString: kwargs?.searchString ?? subscriptionSearchString,
        showTax: kwargs?.showTax ?? subscriptionTaxStatus,
      })
        .then((response) => {
          setUpdatingSubscriptionView(false);
          setSubscriptionViewNextPageLoading(false);
          try {
            if (response?.mr?.Success) {
              if (nextPageLoading) {
                let new_subscription_list = [
                  ...response?.detail?.SubscriptionList,
                ];

                let new_data = { ...subscriptionViewData };
                new_data.SubscriptionList = [
                  ...subscriptionViewData.SubscriptionList,
                  ...new_subscription_list,
                ];
                new_data.NextPage = response?.detail?.NextPage;
                setSubscriptionViewData(new_data);
              } else {
                setSubscriptionViewData({
                  ...response?.detail,
                  menuSorting: response?.detail?.SortVisibility?.map(
                    (item) => ({
                      ...item,
                      Visibility: item?.ColumnVisibility,
                      SortOrder: item?.ColumnSortOrder,
                      FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                      FieldName: item?.ColumnName,
                    })
                  ),
                });
              }
              resolve("Success");
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
          } catch (e) {
            console.log(e, "errrro");
          }
        })
        .catch((err) => {
          setCategoriesLoading(false);
          reject("Something went wrong");
        });
    });
  }

  useEffect(() => {
    if (!clientId && !isSaas) return;
    switch (viewType.id) {
      case 1: {
        if (Object.keys(technologySpendData).length !== 0) return;
        getActivePackage();
        setLoading(true);
        if (clientId !== null) {
          Promise.all([
            getGraphData(),
            getPageData(),
            getClientDetail(),
            getTechnologySpendData(),
          ]).then((response) => {
            setLoading(false);
            localStorage.setItem("dasboardLoader", null);
          });
        }
        break;
      }
      case 2: {
        if (Object.keys(listViewData).length !== 0) return;
        getListViewData();
        break;
      }
      case 3: {
        if (Object.keys(subscriptionViewData).length !== 0) return;
        getSubscriptionViewData();
        break;
      }
      default:
        break;
    }
  }, [viewType, clientId]);

  useEffect(() => {
    if (showTechnologySpendModal) {
      getTechnologySpendData();
    }
  }, [
    selectedDateFilter.startDate.getTime(),
    selectedDateFilter.endDate.getTime(),
  ]);

  useEffect(() => {
    if (!action) return;
    if (action === ROUTE_ACTIONS.addSubscription) {
      setSubscriptionViewInvoiceData({ newInvoice: true });
      setShowCyberGroupModal(false);
      setTimeout(() => {
        setShowTechnologySpendModal(true);
      }, 100);
    }
  }, [action]);

  useEffect(() => {
    updateGettingStarted({
      configValue: true,
      configType: CLIENT_GETTING_STARTED_TYPE.ManageTechnologySpend,
      clientId
    })
  }, []);

  function getSpendDataForGraph() {
    if (technologySpendData?.TechnologySpend_SummaryList?.length > 0) {
      const dummyColors = ["#FFCA78", "#DF6844", "#EB9E87", "#F36060"];
      let data = [...technologySpendData?.TechnologySpend_SummaryList]

        .sort((a, b) => a.ColourSortOrder - b.ColourSortOrder)
        .filter((item) => item?.CategoryValue_Total > 0)
        .map((item, itemIndex) => ({
          label: item?.CategoryName,
          value: item?.[`CategoryValue_Total`],
          color:
            item?.CategoryColourHexCode ??
            dummyColors[itemIndex % dummyColors.length],
          colorId: item?.CategoryId,
        }));

      return data;
    } else {
      return [];
    }
  }

  const graphData = useMemo(() => getSpendDataForGraph(), [technologySpendData]);

  const [change, setChange] = useState(false);
  function featureBoxExpanded() {
    const _featureListWrapper =
      document.getElementsByClassName("feature-list")[0];

    return _featureListWrapper?.style?.maxHeight === "0px";
  }

   function getDateDifferenceCategory(date1, date2) {
    // Convert the input strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 0);

    // Define the time frames
    const currentYearStart = new Date(currentDate.getFullYear(), 0, 1);

    const last12MonthsStart = startOfMonth(subMonths(new Date(), 12));
    const previousYearStart = new Date(currentDate.getFullYear() - 1, 0, 1);
    const previousYearEnd = new Date(currentDate.getFullYear() - 1, 11, 31);
    previousYearEnd.setHours(23, 59, 59);
    const last6MonthsStart = startOfMonth(subMonths(new Date(), 6));
    const last90DaysStart = startOfDay(addDays(new Date(), -90));
    const last30DaysStart = startOfDay(addDays(new Date(), -30));

    // Compare the input dates with the defined time frames
    if (
      startDate.getTime() === currentYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Current Year";
    } else if (
      startDate.getTime() === previousYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), previousYearEnd.getTime(), 5000)
    ) {
      return "Previous Year";
    } else if (
      startDate.getTime() === last12MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 12 Months";
    } else if (
      startDate.getTime() === last6MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 6 Months";
    } else if (
      startDate.getTime() === last90DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 90 Days";
    } else if (
      startDate.getTime() === last30DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 30 Days";
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const _startDate = startDate.toLocaleDateString("en-US", options);
      const _endDate = endDate.toLocaleDateString("en-US", options);
      return _startDate + " - " + _endDate;
    }
  }

  const [toggleDateFilterDropdown, setToggleDateFilterDropdown] =
    useState(false);
  const dateFilterValue = getDateDifferenceCategory(
    selectedDateFilter?.startDate,
    selectedDateFilter?.endDate
  );

  function isDifferenceInRange(number1, number2, difference) {
    const absoluteDifference = Math.abs(number1 - number2);
    return absoluteDifference <= difference;
  }

  function ToggleTax(value) {
    let updatedState = Number(!showTax);
    if (value !== null && value !== undefined) {
      updatedState = Number(value);
    }
    setUpdatingTax(true);
    getAPIData(`${669}`, authTokens, {
      optionEnum1: clientId,
      optionEnum2: updatedState,
    })
      .then((response) => {
        setUpdatingTax(false);
        if (response?.Success) {
          setShowTax(!showTax);
          if (viewType.id === 1) {
            try {
              document.getElementsByClassName("dashboardDateFilter")[0].click();
            } catch (err) {}
            getTechnologySpendData();
          } else if (viewType.id === 2) {
            getListViewData();
          } else {
            getSubscriptionViewData();
          }
          document.getElementsByClassName("msp_attackSurface")?.[0]?.click();
          CallToast("Updated successfully", false, false, "v2style");
          getTechnologySpendData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      getListViewData({
        searchString: value,
        // stopLoader:true
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };
  const handleSubscriptionInputChange = (event) => {
    const { value } = event.target;
    setSubscriptionSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      getSubscriptionViewData({
        searchString: value,
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  // List View pagination

  async function getMoreInvoices(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (listViewData.nextPage === false) return;

        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreInvoices);

        setListViewNextPageLoading(true);

        getListViewData({
          nextPage: true,
          listViewPageNumber: listViewPageNumber + 1,
          stopLoader:true
        }).then(() => {
          var element = document.getElementById("mainBody");
          element.removeEventListener("scroll", getMoreInvoices);
          setListViewNextPageLoading(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }
  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.removeEventListener("scroll", getMoreInvoices);
    if (viewType.id === 2) {
      element.addEventListener("scroll", getMoreInvoices);
    }

    return () => {
      element.removeEventListener("scroll", getMoreInvoices);
    };
  }, [listViewData, listViewNextPageLoading, viewType]);

  useEffect(() => {
    setDonutColorIds([...graphData].map((item) => item.colorId));
  }, [graphData]);

  // FILTERS SETUP
  const [filterOptions] = useState([
    {
      filterName: "Supplier",
      filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
      filterData: {
        filterType: "supplier",
        filterList: [],
      },
    },
    {
      filterName: "Invoice Date",
      filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
      filterData: {
        filterType: "date",
        selectedDateRange: {
          startDate: null, // new Date(),
          endDate: null, //new Date(),
          key: "selection",
        },
      },
    },

    {
      filterName: "Categories",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "category",
        filterList: [],
      },
    },
    {
      filterName: "Subcategories",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "subcategory",
        filterList: [],
      },
    },
    {
      filterName: "Amount",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "amount",
        fromAmount: "",
        toAmount: "",
      },
    },
  ]);
  const [subscriptionFilterOptions] = useState([
    {
      filterName: "Amount",
      filterItemIcon: "/images/msp/msp-subcategory-filter-icon.svg",
      filterData: {
        filterType: "amount",
        fromAmount: "",
        toAmount: "",
      },
    },
    {
      filterName: "Supplier",
      filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
      filterData: {
        filterType: "supplier",
        filterList: [],
      },
    },
    
    {
      filterName: "Expires",
      filterItemIcon: "/images/msp/msp-date-picker-icon.svg",
      filterData: {
        filterType: "expires",
        selectedDateRange: {
          startDate: null, // new Date(),
          endDate: null, //new Date(),
          key: "selection",
        },
      },
    },
    {
      filterName: "Status",
      filterItemIcon: "/images/msp/msp-amount-filter-icon.svg",
      filterData: {
        filterType: "status",
        filterList: [],
      },
    },
  ]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      console.log('event.target.tagName - ', event.target.tagName)
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
        setSearchInput(true);
        SearchInput?.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      {menuItemData?.disableState ||
      (accessState?.Override === true &&
        accessState?.AccessState_TechnologySpend === 0) ? (
        <>
          {userPortalRole === "MSP" ? (
            <NoAccessUI />
          ) : (
            <NoAccessUI type={userPortalRole} />
          )}
        </>
      ) : (
        <>
          <div className="msp_attackSurface px-5 pb-5 pt-0">
            {loading ? (
              <MspDashboardLoader />
            ) : (
              <>
                {/* Header banner */}
                <div className=" bg-grey radius-8 d-none">
                  <div className="d-flex align-items-center justify-content-between p-2">
                    <div className="f-600 f-black">Client Setup</div>

                    <div
                      className="f-500 f-grey pointer"
                      onClick={() => {
                        let featureListWrapper =
                          document.getElementsByClassName("feature-list")[0];

                        if (featureListWrapper?.style?.maxHeight !== "0px") {
                          featureListWrapper.style.maxHeight = "0px";
                        } else {
                          featureListWrapper.style.maxHeight =
                            featureListWrapper.scrollHeight + "px";
                        }

                        setChange(!change);
                      }}
                    >
                      {featureBoxExpanded() ? "Show all" : "Hide all"}
                    </div>
                  </div>
                  <Row className="m-0 p-0 overflow-hidden feature-list">
                    <Col xs={4} className="m-0 p-0 border-right p-3 mt-2">
                      <div className="f-16 f-500 mb-2">
                        <img
                          src="/images/msp/connect-integration-icon.svg"
                          alt=""
                          className="mr-2"
                        />
                        Define Security Tools
                      </div>
                      <div className="f-grey mb-3">
                        Start by defining your clients security tools and <br />
                        capabilities.
                      </div>
                      <div>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={
                            isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{ show: 200, hide: 250 }}
                          overlay={
                            <Tooltip id={`tooltip-1365`}>
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <Button
                            className={` ${
                              isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                                ? "btn-disable"
                                : "hg-blue-bt"
                            } `}
                            onClick={() => {
                              setShowCyberGroupModal(true);
                            }}
                          >
                            {isViewOnly || viewOnlyAccess("AccessState_TechnologySpend") && (
                              <img
                                alt=""
                                src="/images/settings-v2/locker-grey.svg"
                                className="mr-2"
                              />
                            )}
                            Define tools
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </Col>
                    <Col xs={4} className="m-0 p-0 border-right p-3 mt-2">
                      <div className="f-16 f-500 mb-2">
                        <img
                          src="/images/msp/build-stack-icon.svg"
                          alt=""
                          className="mr-2"
                        />
                        Define IT & Cyber Spend
                      </div>
                      <div className="f-grey mb-3">
                        Security is only part of your clients IT investment.
                        Define all technology spend to create perspective.
                      </div>
                      <div>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={
                            isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{ show: 200, hide: 250 }}
                          overlay={
                            <Tooltip id={`tooltip-1365`}>
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <Button
                            className={` ${
                              isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                                ? "btn-disable"
                                : "hg-blue-btn"
                            } `}
                            onClick={() => {
                              let error_element =
                                document.getElementById(`scroll-section`);
                              if (error_element) {
                                error_element.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "nearest",
                                });
                              }
                            }}
                          >
                            {isViewOnly || viewOnlyAccess("AccessState_TechnologySpend") && (
                              <img
                                alt=""
                                src="/images/settings-v2/locker-grey.svg"
                                className="mr-2"
                              />
                            )}
                            Define Spend
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </Col>
                    <Col xs={4} className="m-0 p-0 p-3 mt-2">
                      <div className="f-16 f-500 mb-2">
                        <img
                          src="/images/msp/build-stack-icon.svg"
                          alt=""
                          className="mr-2"
                        />
                        Create Security Scenerios
                      </div>
                      <div className="f-grey mb-3">
                        Create meaningful, costed visions of your clients future
                        security capabilities that you can deliver.
                      </div>
                      <div>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={
                            isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{ show: 200, hide: 250 }}
                          overlay={
                            <Tooltip id={`tooltip-1365`}>
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <Button
                            className={` ${
                              isViewOnly || viewOnlyAccess("AccessState_TechnologySpend")
                                ? "btn-disable"
                                : "hg-blue-btn"
                            } `}
                            onClick={() => {
                              history.push(
                                `/mspv2/clients/clientsView/${clientId}/managed-security`
                              );
                            }}
                          >
                            {isViewOnly || viewOnlyAccess("AccessState_TechnologySpend") && (
                              <img
                                alt=""
                                src="/images/settings-v2/locker-grey.svg"
                                className="mr-2"
                              />
                            )}
                            Complete
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* SCORE DATA FOR MSP */}

          <Row className="m-0 pt-5">
            <Col xs={4} className="m-0 p-0 pr-3  ">
              <div className="m-0 p-0 p-3 mr-2 bg-grey radius-8 pointer h-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="f-500 f-grey">Cyber Score</div>
                  <div>
                    <AttestationPopUp>
                      <img
                        alt=""
                        src={
                          Attestation_Icon_Mappings[
                            pageData?.CommonComponents?.ScoreAttestation
                          ]
                        }
                        className=""
                      />
                    </AttestationPopUp>
                  </div>
                </div>
                <div className="f-18 f-500 f-black">
                  {pageData?.CommonComponents?.CyberScoreNumber}
                </div>
                <div
                  onClick={() => {
                    if (pageData?.IsPreDataState) {
                      return;
                    }
                    setDefaultTab("cyberScore");
                    setShowCyberGroupModal(true);
                  }}
                  className={`head d-flex align-items-center justify-content-center position-relative ${"cyber_pre_data_popover_parent"}`}
                >
                  {pageData?.IsPreDataState && (
                    <div className="position-absolute cyber_pre_data_popover">
                      <div className="mx-2 f-black mt-4">
                        Answer some quick questions to map your clients cyber
                        security posture
                      </div>
                      <div
                        className="f-blue mt-3 ml-2"
                        onClick={() => {
                          setDefaultTab("cyberScore");
                          setShowCyberGroupModal(true);
                        }}
                      >
                        Get my CyberScore
                        <img
                          src="/images/msp/blue-right-icon.svg"
                          alt=""
                          className="ml-2"
                        />
                      </div>
                    </div>
                  )}
                  <div className="graph z-Index-0">
                    <CyberScore2
                      key={0}
                      size="small"
                      scoreNumber={
                        pageData?.CommonComponents?.CyberScoreNumber ?? 0
                      }
                      dataStroke={18}
                      bottomText={false}
                      Width="180"
                      Height="180"
                      hideLabels={false}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4} className="m-0 p-0 pr-3  ">
              <div className="m-0 p-0 p-3 mr-2 bg-grey radius-8 pointer h-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="f-500 f-grey">CyberCompliance</div>
                  <div>
                    <AttestationPopUp>
                      <img
                        alt=""
                        src={
                          Attestation_Icon_Mappings[
                            pageData?.CommonComponents
                              ?.CyberComplianceAttestation
                          ]
                        }
                        className=""
                      />
                    </AttestationPopUp>
                  </div>
                </div>
                <div className="f-18 f-500 f-black">
                  {pageData?.CEOComponents?.CyberComplianceNumber}
                </div>
                <div
                  onClick={() => {
                    if (pageData?.IsPreDataState) {
                      return;
                    }
                    setDefaultTab("cyberCompliance");
                    setShowCyberGroupModal(true);
                  }}
                  className="head d-flex align-items-center justify-content-center position-relative cyber_pre_data_popover_parent"
                >
                  {pageData?.IsPreDataState && (
                    <div className="position-absolute cyber_pre_data_popover">
                      <div className="mx-2 f-black mt-4">
                        Answer some quick questions to map your clients cyber
                        security posture
                      </div>
                      <div
                        className="f-blue mt-3 ml-2"
                        onClick={() => {
                          setDefaultTab("cyberCompliance");
                          setShowCyberGroupModal(true);
                        }}
                      >
                        Get my CyberCompliance Score
                        <img
                          src="/images/msp/blue-right-icon.svg"
                          alt=""
                          className="ml-2"
                        />
                      </div>
                    </div>
                  )}
                  <div className="graph complianceGraph z-Index-0">
                    <CyberCompliance
                      ccNumber={
                        pageData?.CEOComponents?.CyberComplianceNumber ?? 0
                      }
                      dataStroke={20}
                      fontSize={22}
                      id="cyber-comliance"
                      wh={180}
                      whpx="180px"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4} className="m-0 p-0  ">
              <div
                className="m-0 p-0 p-3 mr-2 bg-grey radius-8 pointer h-100 w-100"
                onClick={() => {
                  // setShowCyberGroupModal(true);
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="f-500 f-grey mb-2">CyberSecurity posture</div>
                  <div></div>
                </div>
                <div className="f-18 f-500 f-black">
                  {/* {pageData?.CommonComponents?.CyberThreatNumber} */}
                </div>
                <div className="head d-flex mt-4 pt-4 flex-column align-items-center justify-content-between position-relative">
                  {/* <img
                    src="/images/msp/score-calculator-icon.svg"
                    alt=""
                    className="mb-2"
                  /> */}

                        <MspTechSpendScreenRadarChart
                          _datasets={[
                            {
                              data: [
                                pageData?.CurrentPosture?.Identity ?? 0,
                                pageData?.CurrentPosture?.Protect ?? 0,
                                pageData?.CurrentPosture?.Detect ?? 0,
                                pageData?.CurrentPosture?.Response ?? 0,
                                pageData?.CurrentPosture?.Recover ?? 0,
                              ],
                              pointRadius: [0, 0, 0, 0, 0],
                              datalabels: {
                                display: false,
                              },
                              backgroundColor: `${"#008fd4"}`,
                              borderColor: `${"#008fd4"}`,
                              pointBackgroundColor: "#008fd4",
                              borderWidth: 1,
                              pointLabelFontSize: 0,
                              scaleShowLabels: false,
                              label: "Current Posture",
                            },
                          ]}
                          activeChart={0}
                          height={150}
                          width={150}
                        />
                        {isViewOnly ? <DisabledButtonWithToolTip
                            buttonText={"Improve posture"}
                            noAccessText={NO_ACCESS_TEXT}
                          /> :
                        getAccessStateValue("AccessState_TechnologySpend") >
                        2 ? (
                          <Button
                            className="hg-blue-btn mt-2"
                            onClick={() => { 
                              setDefaultTab("cyberScore");
                              setShowCyberGroupModal(true);
                            }}
                          >
                            Improve posture{" "}
                            <img
                              src="/images/msp/white-arrow-right.svg"
                              alt=""
                              className="ml-2"
                            />
                          </Button>
                        ) : (
                          <DisabledButtonWithToolTip
                            buttonText={"Improve posture"}
                            noAccessText={NO_ACCESS_TEXT}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* Technology Spend Data */}
                {/* header + filter Dropdown */}
                <div className="d-flex align-items-center justify-content-between pt-3">
                  <div
                    className="d-flex align-items-center"
                    ref={TechnologySpendHeaderRef}
                  >
                    <div
                      className="f-16 f-600"
                      onClick={() => {
                        // setShowExploreMappingModal(true);
                      }}
                    >
                      Technology Spend
                    </div>
                    &nbsp;
                    {technologySpendData?.SyncStatus?.IsSyncing === true && (
                      <div className="d-flex align-items-center ml-2">
                        {/* <img
                    src="/images/msp/integration-sync-icon.svg"
                    alt=""
                    className="mr-2"
                  /> */}
                        <GreyLoader width={12} />
                        Syncing...
                      </div>
                    )}
                    {technologySpendData?.SyncStatus?.IsSyncing === false &&
                      technologySpendData?.SyncStatus?.MessageIconURL && (
                        <div className="d-flex align-items-center integration-parent-hover pointer ml-2">
                          <div className="w-auto child-hoverable">
                            <img
                              src={
                                technologySpendData?.SyncStatus?.MessageIconURL
                              }
                              alt=""
                              width={17}
                              height={17}
                              className="rounded-full"
                            />
                          </div>

                          <div
                            className="controller-child pl-2 f-500"
                            style={{
                              color:
                                technologySpendData?.SyncStatus?.MessageColour,
                              height: "20px",
                            }}
                          >
                            {technologySpendData?.SyncStatus?.Message?.replace(
                              "updated",
                              "synced"
                            )}
                          </div>
                        </div>
                      )}
                  </div>

            <div className="mr-2 d-flex align-items-center">
              {viewType.id === 1 && (
                <div className="d-flex align-items-center f-500 f-darkgrey ml-3">
                  {updatingTechnologySpend && (
                    <>
                      <GreyLoader width={14} />
                    </>
                  )}
                </div>
              )}
              {viewType.id === 2 && (
                <div className="d-flex align-items-center f-500 f-darkgrey ml-3">
                  {updatingTax && (
                    <>
                      <GreyLoader width={14} />
                    </>
                  )}
                </div>
              )}
              {viewType.id === 3 && (
                <div className="d-flex align-items-center f-500 f-darkgrey ml-3">
                  {updatingTax && (
                    <>
                      <GreyLoader width={14} />
                    </>
                  )}
                </div>
              )}
              {/* Search */}
              {(viewType.id === 2 ||viewType.id === 1)  && (
                <div className="mx-2 d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {
                      setSearchInput(true);
                      SearchInput.current.focus();
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search.."
                    ref={SearchInput}
                    value={searchString}
                    onChange={(e) => {
                      setSearchInput(true);
                      handleInputChange(e);
                      setViewType(

                        { id: 2, category: "List View" }
                      );
                    }}
                    onBlur={() => {}}
                    className={`${
                      searchInput
                        ? "animated-search-input-open"
                        : "animated-search-input-close"
                    } pl-2`}
                    autoFocus
                  />
                  <img
                    alt=""
                    style={{
                      visibility: searchString !== "" ? "visible" : "hidden",
                    }}
                    src="/images/settings-v2/header-search-cross.svg"
                    className="pointer"
                    onClick={() => {
                      handleInputChange({ target: { value: "" } });
                    }}
                  />
                </div>
              )}
              {viewType.id === 3 && (
                <div className="mx-2 d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {
                      setSearchInput(true);
                      SearchInput.current.focus();
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search.."
                    ref={SearchInput}
                    value={subscriptionSearchString}
                    onChange={(e) => {
                      setSearchInput(true);
                      handleSubscriptionInputChange(e);
                    }}
                    onBlur={() => {}}
                    className={`${
                      searchInput
                        ? "animated-search-input-open"
                        : "animated-search-input-close"
                    } pl-2`}
                    autoFocus
                  />
                  <img
                    alt=""
                    style={{
                      visibility:
                        subscriptionSearchString !== "" ? "visible" : "hidden",
                    }}
                    src="/images/settings-v2/header-search-cross.svg"
                    className="pointer"
                    onClick={() => {
                      handleSubscriptionInputChange({ target: { value: "" } });
                    }}
                  />
                </div>
              )}

              {/* Filter Dropdown */}
              {viewType.id === 2 && (
                <div className="mr-2">
                  <Dropdown className="transparennt-wrapper-dropdown ">
                    <Dropdown.Toggle className="p-0">
                      <div className="f-grey f-500">Filter</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-1"
                      style={{
                        width: "200px",
                      }}
                    >
                      {filterOptions
                        // ?.filter((item) => hideFilters.indexOf(item?.filterName) === -1)
                        .map((filterItem, filterItemIndex) => {
                          return (
                            <div
                              className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey"
                              onClick={(e) => {
                                let updated_active_filters = [...activeFilters];
                                let indexOfFilter =
                                  updated_active_filters.findIndex(
                                    (item) =>
                                      item?.filterType ===
                                      filterItem?.filterData?.filterType
                                  );
                                if (indexOfFilter === -1) {
                                  setActiveFilters([
                                    ...updated_active_filters,
                                    { ...filterItem?.filterData },
                                  ]);
                                }
                                try {
                                  setTimeout(() => {
                                    let filter_Component =
                                      document.querySelector(".filter-box-X");
                                    if (filter_Component) {
                                      let dropdown =
                                        filter_Component.querySelector(
                                          `#${filterItem?.filterData?.filterType}_filterX`
                                        );

                                      if (dropdown) {
                                        dropdown
                                          .querySelector(".dropdown-toggle")
                                          .click();
                                      }
                                    }
                                  }, 100);
                                } catch (err) {}

                                e.target
                                  .closest(".transparennt-wrapper-dropdown")
                                  .click();
                              }}
                            >
                              <div>{filterItem.filterName}</div>
                              <img
                                src="/images/attack-surface/chevron-right.svg"
                                alt=""
                                className=""
                              />
                            </div>
                          );
                        })}
                      {/* {filterOptions.map((filterItem, filterItemIndx) => {
                      return (
                        <div className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey">
                          <div>{filterItem.filterName}</div>
                          <img
                            src="/images/attack-surface/chevron-right.svg"
                            alt=""
                            className=""
                          />
                        </div>
                      );
                    })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {viewType.id === 3 && (
                <div className="mr-2">
                  <Dropdown className="transparennt-wrapper-dropdown ">
                    <Dropdown.Toggle className="p-0">
                      <div className="f-grey f-500">Filter</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-1"
                      style={{
                        width: "200px",
                      }}
                    >
                      {subscriptionFilterOptions
                        // ?.filter((item) => hideFilters.indexOf(item?.filterName) === -1)
                        .map((filterItem, filterItemIndex) => {
                          return (
                            <div
                              className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey"
                              onClick={(e) => {
                                let updated_active_filters = [
                                  ...subscriptionActiveFilters,
                                ];
                                let indexOfFilter =
                                  updated_active_filters.findIndex(
                                    (item) =>
                                      item?.filterType ===
                                      filterItem?.filterData?.filterType
                                  );
                                if (indexOfFilter === -1) {
                                  setSubscriptionActiveFilters([
                                    ...updated_active_filters,
                                    { ...filterItem?.filterData },
                                  ]);
                                }
                                try {
                                  setTimeout(() => {
                                    let filter_Component =
                                      document.querySelector(".filter-box-X");
                                    if (filter_Component) {
                                      let dropdown =
                                        filter_Component.querySelector(
                                          `#${filterItem?.filterData?.filterType}_filterX`
                                        );

                                      if (dropdown) {
                                        dropdown
                                          .querySelector(".dropdown-toggle")
                                          .click();
                                      }
                                    }
                                  }, 100);
                                } catch (err) {}

                                e.target
                                  .closest(".transparennt-wrapper-dropdown")
                                  .click();
                              }}
                            >
                              <div>{filterItem.filterName}</div>
                              <img
                                src="/images/attack-surface/chevron-right.svg"
                                alt=""
                                className=""
                              />
                            </div>
                          );
                        })}
                      {/* {filterOptions.map((filterItem, filterItemIndx) => {
                      return (
                        <div className="d-flex align-items-center  justify-content-between f-grey f-500 p-2 pointer radius-2 bg-hover-grey">
                          <div>{filterItem.filterName}</div>
                          <img
                            src="/images/attack-surface/chevron-right.svg"
                            alt=""
                            className=""
                          />
                        </div>
                      );
                    })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {viewType.id === 1 && (
                <div>
                  <Dropdown
                    className="date-filter-dropdown m-1 dashboardDateFilter"
                    alignRight
                    onToggle={(e) => {
                      setToggleDateFilterDropdown(e);
                    }}
                    // id={`${activeFilter?.filterType}_filterX`}
                  >
                    <Dropdown.Toggle className="p-0">
                      <div className="d-flex align-items-center p-2 bg-grey f-grey f-500 radius-4">
                        {dateFilterValue}
                        <img
                          src="/images/big-chevron-down.svg"
                          alt=""
                          className={`ml-2 ${
                            toggleDateFilterDropdown ? "rotate180" : ""
                          }`}
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-0"
                      style={{
                        zIndex: 4,
                      }}
                    >
                      <DateRangePicker
                        direction="vertical"
                        ranges={[selectedDateFilter ?? {}]}
                        staticRanges={staticRanges}
                        onChange={(ranges) => {
                          dispatch(
                            mspClientDashboardDatePickerState(ranges.selection)
                          );
                          if (
                            ranges.selection.startDate ===
                            ranges.selection.endDate
                          )
                            return;

                          getTechnologySpendData(ranges.selection);
                          document
                            .querySelector(".dashboardDateFilter")
                            .click();
                        }}
                      />
                      <div
                        className="pl-4 f-12 pointer pt-0"
                        onClick={() => {
                          dispatch(
                            mspClientDashboardDatePickerState({
                              startDate: defineds.startOfLastTwelveMonth,
                              endDate: defineds.endOfToday,
                              key: "selection",
                            })
                          );
                          getTechnologySpendData({
                            startDate: defineds.startOfLastTwelveMonth,
                            endDate: defineds.endOfToday,
                          });
                        }}
                        style={{
                          padding: "0.7rem",
                        }}
                      >
                        Clear
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              <div className=" mr-2">
                <CustomHGDropdown
                  dropDownMenuClass={"w-max-content"}
                  greyBackground={true}
                  fieldId={"id"}
                  fieldValue={"category"}
                  selected={viewType}
                  placeholder="Select"
                  onClick={(id, val, item) => {
                    setViewType(item);
                  }}
                  data={[
                    { id: 1, category: "Category View" },
                    { id: 2, category: "List View" },
                    { id: 3, category: "Subscription View" },
                  ]}
                />
              </div>

              {isViewOnly ?  <DisabledButtonWithToolTip
                  buttonText={"Add"}
                  noAccessText={NO_ACCESS_TEXT}
                /> :
              getAccessStateValue("AccessState_TechnologySpend") > 2 ? 
                <MSPAddDropdown 
                  setShowCategorySpendModal={setShowCategorySpendModal}
                  setShowTechnologySpendModal={setShowTechnologySpendModal}
                  setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                  setDefaultSpendSelectedTab={setDefaultSpendSelectedTab}
                />
                 :
                <DisabledButtonWithToolTip
                  buttonText={"Add"}
                  noAccessText={NO_ACCESS_TEXT}
                />
              }
              <div>
                {viewType.id === 1 && (
                  <MSPCatSubcatSortFilters
                    headersList={
                      techSpendDashboardData?.TechnologySpend_SummaryList ?? []
                    }
                    id="oreo"
                    updatedHeaderList={(e) => {
                      let updated_data = [...e].map((item) => ({
                        CategoryName: item?.CategoryName,
                        CategoryID: item?.CategoryId,
                        CategoryColor: item?.CategoryColour,
                        CategorySortOrder: item?.SortOrder,
                        CategoryVisibility: item?.Visibility,
                        IsEditable: item?.IsEditable,
                        SubCatSettings: item?.SubCatSettings,
                      }));
                      UpdateCategorySorting(updated_data);
                    }}
                    ToggleTax={ToggleTax}
                    updatingTax={updatingTax}
                    showTax={showTax}
                  >
                    <div className="bg-grey py-2 px-3 radius-4">
                      <img
                        src="/images/settings-v2/horizontal-dots.svg"
                        className=""
                        alt=""
                      />
                    </div>
                  </MSPCatSubcatSortFilters>
                )}
                {viewType.id === 2 && (
                  <MSPCatSubcatSortFilters
                    NoDataStateText={"No Item !"}
                    headersList={listViewData?.menuSorting}
                    id="britannia"
                    updatedHeaderList={(e) => {
                      let updated_data = [...e].map((item) => ({
                        ColumnName: item?.ColumnName,
                        ColumnSortOrder: item?.SortOrder,
                        ColumnVisibility: item?.Visibility,
                      }));
                      UpdateListViewSorting(updated_data);
                    }}
                    ToggleTax={ToggleTax}
                    updatingTax={updatingTax}
                    showTax={showTax}
                  >
                    <div className="bg-grey py-2 px-3 radius-4">
                      <img
                        src="/images/settings-v2/horizontal-dots.svg"
                        className=""
                        alt=""
                      />
                    </div>
                  </MSPCatSubcatSortFilters>
                )}
                {viewType.id === 3 && (
                  <MSPCatSubcatSortFilters
                    NoDataStateText={"No Item !"}
                    headersList={listViewData?.menuSorting}
                    id="britannia"
                    updatedHeaderList={(e) => {
                      let updated_data = [...e].map((item) => ({
                        ColumnName: item?.ColumnName,
                        ColumnSortOrder: item?.SortOrder,
                        ColumnVisibility: item?.Visibility,
                      }));
                      UpdateListViewSorting(updated_data);
                    }}
                    ToggleTax={ToggleTax}
                    updatingTax={updatingTax}
                    showTax={showTax}
                    hideCustomiseView
                  >
                    <div className="bg-grey py-2 px-3 radius-4">
                      <img
                        src="/images/settings-v2/horizontal-dots.svg"
                        className=""
                        alt=""
                      />
                    </div>
                  </MSPCatSubcatSortFilters>
                )}
              </div>
            </div>
          </div>
          {/* Technology Spend Data Filters */}
          {viewType.id === 2 && (
            <TechSpendHomeScreenFilters
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              listViewData={listViewData}
              onApply={(filters) => {
                getListViewData({
                  filters: filters,
                });
              }}
            />
          )}
          {viewType.id === 3 && (
            <HomeScreenSubscriptionFilters
              activeFilters={subscriptionActiveFilters}
              setActiveFilters={setSubscriptionActiveFilters}
              subscriptionViewData={subscriptionViewData}
              onApply={(filters) => {
                getSubscriptionViewData({
                  filters: filters,
                });
              }}
            />
          )}

          {/* Category View */}
          {(viewType.id === 1 || true) && (
            <Row
              className={
                viewType.id === 1
                  ? "m-0 bg-grey radius-8 mt-2 position-relative"
                  : "d-none"
              }
              id={"scroll-section"}
            >
              {categoriesLoading && (
                <div
                  className="position-absolute"
                  style={{
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    opacity: "0.5",
                    background: "white",
                    zIndex: 1,
                  }}
                ></div>
              )}
              <Col xs={8} className="m-0 p-0">
                <Row className="m-0 border-right">
                  <Row
                    className="m-0"
                    onMouseLeave={() => {
                      graphRef.current.expandSpecificSegement(null);
                    }}
                  >
                    {technologySpendData?.TechnologySpend_SummaryList
                    ?.filter((item) => {
                      if (technologyCategoriesToShow === CategoriesShowState.ONLY_VISIBLE) {
                        return item?.Visibility;
                      }
                      return true;
                    })
                    ?.map((spendItem, spendItemIndex) => {
                      const isItemHidden = !spendItem?.Visibility;
                      return (
                        <Col
                          className={`m-0 parent-visible p-3 bg-hover-dark-grey ${isViewOnly ? 'cursor-default' : accessState?.AccessState_TechnologySpend > 1 ? 'pointer' : 'cursor-default'}
                        category-item-on-dashboard ${spendItemIndex % 3 === 2 ? "" : "border-right"} border-bottom`}
                          xs={4}
                          onMouseEnter={() => {
                            const colorId = donutColorIds.findIndex((colorId) => colorId === spendItem?.CategoryId);
                            if (colorId === -1) {
                              graphRef.current.expandSpecificSegement(null);
                              return;
                            }
                            graphRef.current.expandSpecificSegement(
                              colorId
                            );
                          }}
                          onClick={() => {
                            if (!isViewOnly) {
                              if (accessState?.AccessState_TechnologySpend > 1) {
                                setSelectedCategory(spendItem);
                                setShowTechnologySpendModal(true);
                              }
                            }
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className={`d-flex align-items-center f-500 ${isItemHidden ? 'f-darkgrey' : 'f-grey'}`}>
                              <div
                                style={{
                                  width: "8px",
                                  height: "8px",
                                  background: spendItem?.CategoryColourHexCode,
                                }}
                                alt=""
                                className="mr-2 rounded-full"
                              />
                              {spendItem?.CategoryName}
                            </div>
                            {isItemHidden ? (
                              <div 
                                className="pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  // find item and make its visibility true
                                  let updated_data = [...technologySpendData?.TechnologySpend_SummaryList].map((item) => {
                                    if (item?.CategoryId === spendItem?.CategoryId) {
                                      return {
                                        ...item,
                                        Visibility: true,
                                      };
                                    }
                                    return item;
                                  }).map((item) => ({
                                    CategoryName: item?.CategoryName,
                                    CategoryID: item?.CategoryId,
                                    CategoryColor: item?.CategoryColour,
                                    CategorySortOrder: item?.SortOrder,
                                    CategoryVisibility: item?.Visibility,
                                    IsEditable: item?.IsEditable,
                                    SubCatSettings: item?.SubCatSettings,
                                  }));
                                  UpdateCategorySorting(updated_data);
                                }}
                              >
                                <img
                                  src="/images/attack-surface/hidden-icon.svg"
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div>
                                {
                                  isViewOnly ? <img
                                  src="/images/settings-v2/locker-grey.svg"
                                  alt=""
                                  className="child-visible"
                                /> : accessState?.AccessState_TechnologySpend > 1 ?
                                  <img
                                    src="/images/actioncenter/plusgrey.svg"
                                    alt=""
                                    className="child-visible"
                                  /> : <img
                                  src="/images/settings-v2/locker-grey.svg"
                                  alt=""
                                  className="child-visible"
                                />
                                }
                              </div>
                            )}
                          </div>
                          <div className={`f-18 f-500 mt-3 ${isItemHidden ? 'f-darkgrey' : 'f-black'}`}>
                            {spendItem[`CategoryValue_Total_Text`]}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>

                  {amountOfHiddenCategories > 0 && (
                    <div
                      className="w-100 border-to p pointer f-grey f-500 p-2 pl-2"
                      id="show-more-less-btn"
                      onClick={() => {
                        if (technologyCategoriesToShow === CategoriesShowState.ONLY_VISIBLE) {
                          setTechnologyCategoriesToShow(CategoriesShowState.ALL);
                        } else {
                          setTechnologyCategoriesToShow(CategoriesShowState.ONLY_VISIBLE);
                        }
                      }}
                    >
                      {technologyCategoriesToShow === CategoriesShowState.ONLY_VISIBLE ? (
                        <>
                          <img
                            className="mx-2"
                            alt=""
                            src="/images/msp/arrow-downn.svg"
                          />
                          Show {amountOfHiddenCategories} hidden
                        </>
                      ) : (
                        <>
                          <img
                            className="mx-2"
                            alt=""
                            src="/images/msp/arrow-upp.svg"
                          />
                          Hide {amountOfHiddenCategories} hidden
                        </>
                      )}
                    </div>
                  )}
                </Row>
              </Col>
              <Col xs={4} className="p-3">
                <div className="f-500 f-grey">Spend by categories</div>

                <div className="f-18 f-500 f-black mt-3">
                  {technologySpendData[`CategoryValue_Total_Text`]}
                </div>
                <div className="d-flex align-items-center justify-content-center progress_bar_x">
                  <div className="w-fit-content position-relative">
                    <div className="chart w-100">
                      {showGraph && (
                        <MspDonutChartForDashboard
                          height={200}
                          graphData={graphData}
                          forwardedRef={graphRef}
                          thickness={40}
                        />
                      )}
                    </div>

                        {/* <svg class="progress-bar-x">
                    <circle
                      fill="none"
                      class="background-x"
                      strokeWidth="23"
                      stroke="#ebecf1"
                      r="85"
                      cy="125"
                      cx="125"
                      pathLength="100"
                    ></circle>
                    <circle
                      fill="none"
                      class="progress-x"
                      pathLength="100"
                      stroke-dasharray="100"
                      strokeDashoffset={25}
                      className=""
                      r="85"
                      cy="125"
                      cx="125"
                      strokeWidth="23"
                      stroke="#008fd4"
                    ></circle>
                  </svg> */}
                        {/* <div className="position-absolute graph_text">
                    <div className="f-18 f-500 ">0%</div>
                    <div className="f-12 f-grey">Cyber Security</div>
                  </div> */}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      id="currency-tag"
                    >
                      <div className="f-darkgrey">Currency:</div>

                  <div className="d-flex f-12 f-500 f-grey border1 ml-3 radius-24 px-2 py-1">
                    {technologySpendData?.CurrencyCode}
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {viewType.id === 2 && (
            <div
              className="hide-scroller w-100 mt-3 position-relative"
              style={{
                overflowX: "auto",
              }}
            >
              {updatingListView && (
                <div
                  className="position-absolute"
                  style={{
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    opacity: "0.5",
                    background: "white",
                    zIndex: 1,
                  }}
                ></div>
              )}

              {updatingListView ? (
                <ContentLoader 
                  speed={2}
                  width={"100%"}
                  height={"100%"}
                  viewBox="0 0 1500 400"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
              ) : (
                <TechSpendListView
                  listViewData={listViewData}
                  searchString={searchString}
                  onOpenInvoice={(invoiceData) => {
                    setListViewInvoiceData(invoiceData);
                    setShowTechnologySpendModal(true);
                  }}
                  setListViewData={setListViewData}
                  getListViewData={getListViewData}
                />
              )}

              {listViewNextPageLoading && (
                <div className="d-flex align-items-center f-darkgrey ml-3 ">
                  <GreyLoader width={12} />
                  Loading...
                </div>
              )}

              {/* {listViewData?.nextPage === true && (
                <Button
                  className="hg-cancel-bt n d-none mt-2"
                  onClick={() => {
                    setListViewNextPageLoading(true);
                    getListViewData({
                      nextPage: true,
                      listViewPageNumber: listViewPageNumber + 1,
                    });
                  }}
                >
                  {listViewNextPageLoading ? (
                    <div className="d-flex align-items-center">
                      <GreyLoader width={13} />
                      <div className="">Loading..</div>
                    </div>
                  ) : (
                    "Load more"
                  )}
                </Button>
              )} */}
            </div>
          )}

          {/* Subscription View */}
          {viewType.id === 3 && (
            <div
              className="hide-scroller w-100 mt-3 position-relative"
              style={{}}
            >
              {updatingSubscriptionView && (
                <div
                  className="position-absolute"
                  style={{
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    opacity: "0.5",
                    background: "white",
                    zIndex: 1,
                  }}
                ></div>
              )}

              {updatingSubscriptionView ? (
                <ContentLoader 
                  speed={2}
                  width={"100%"}
                  height={"100%"}
                  viewBox="0 0 1500 400"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
              ) : (
                <TechSpendSubscriptionView
                  subscriptionViewData={subscriptionViewData}
                  searchString={subscriptionSearchString}
                  onOpenInvoice={(invoiceData) => {
                    setSubscriptionViewInvoiceData(invoiceData);
                    setShowTechnologySpendModal(true);
                  }}
                />
              )}
              
              {subscriptionViewNextPageLoading && (
                <div className="d-flex align-items-center f-darkgrey ml-3  mt-2">
                  <GreyLoader width={12} />
                  Loading...
                </div>
              )}
            </div>
          )}

          {/* KPI's Graphs */}
          {(viewType.id === 1 || true) && (
            <div
              className={
                viewType.id === 1 ? "d-flex flex-column pr-3 " : "d-none"
              }
            >
              {/* kpi overview header */}
              <div className="d-flex align-items-center justify-content-between my-3 mt-4">
                <div className="f-16 f-600">KPIs</div>
                <div className="compliance-filter-dropdown mr-2 d-flex align-items-center">
                  {loadingKPIGraphData && (
                    <div className="mr-2">
                      <GreyLoader width={14} />
                    </div>
                  )}
                  <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"category"}
                    selected={graphPeriod}
                    placeholder="Filters"
                    //  showid={true}
                    onClick={(id, val) => {
                      getGraphData(id);
                      setGraphPeriod({
                        id,
                        category: val,
                      });
                      setLoadingKPIGraphData(true);
                    }}
                    data={[
                      { id: 0, category: "Last 3 months" },
                      { id: 1, category: "Last 6 months" },
                      { id: 2, category: "Last 12 months" },
                    ]}
                  />
                </div>
              </div>

                  {/* graphs */}

              <div className="bg-grey radius-8">
                <Row className="m-0 border-bottom border-parent-right">
                  {[...chartBoxList]
                    .slice(0, 4)
                    .sort((a, b) => {
                      if (a?.BoxTitle === "Supplier Risk") return 1;
                      if (b?.BoxTitle === "Supplier Risk") return -1;
                      return 0;
                    })
                    .map((chartBox, chartBoxIndex) => {
                      if (!chartBox?.BoxEnabled) return null;
                      return (
                        <Col
                          className={`${"border-item"} p-2
                  
                  
                  ${
                    chartBox?.BoxTitle === "CyberCompliance" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }

                  ${
                    chartBox?.BoxTitle === "CyberScore" && chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }

                  ${
                    chartBox?.BoxTitle === "CyberThreat Level" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }
                  `}
                          style={{
                            minHeight: "200px",
                          }}
                          onClick={() => {
                            if (
                              chartBox?.BoxTitle === "CyberCompliance" &&
                              chartBox?.BoxEnabled
                            ) {
                              setShowCyberGroupModal(true);
                            }

                            if (
                              chartBox?.BoxTitle === "CyberScore" &&
                              chartBox?.BoxEnabled
                            ) {
                              setShowCyberGroupModal(true);
                            }

                            if (
                              chartBox?.BoxTitle === "CyberThreat Level" &&
                              chartBox?.BoxEnabled
                            ) {
                              setShowCyberGroupModal(true);
                            }
                          }}
                        >
                          <div
                            className={`d-flex align-items-center justify-content-between
                  
                  
                  ${
                    chartBox?.BoxTitle === "CyberCompliance" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }
                  
                  
                  `}
                          >
                            <div className="f-grey">{chartBox?.BoxTitle}</div>
                            <div>
                              {chartBox?.BoxEnabled ? (
                                <img
                                  width={14}
                                  height={14}
                                  src={chartBox?.BoxIcon}
                                  alt=""
                                />
                              ) : (
                                <img
                                  width={14}
                                  height={14}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  // className="ml-2"
                                />
                              )}
                            </div>
                          </div>
                          {chartBox?.BoxEnabled ? (
                            <>
                              <div className="f-18 f-500 my-2">
                                {chartBox?.BoxTitle === "Supplier Risk" ? (
                                  <BlueComingSoonTag />
                                ) : (
                                  chartBox?.BoxScore
                                )}
                                {/* {chartBox?.BoxScore} */}
                              </div>
                              <div style={{ maxWidth: '100%' }}>
                                {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
                                <MyLineChart
                                  redraw={false}
                                  data={
                                    chartBox?.ChartData_Points?.Points?.length >
                                    0
                                      ? renderGraphByPoints(
                                          chartBox?.ChartData_Points?.Points
                                        )
                                      : data
                                  }
                                  options={options}
                                  // plugins={annotation}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="f-12 f-darkgrey pt-2">
                                You dont have acces to this data. Update your
                                access level for this supplier
                              </div>
                            </>
                          )}
                        </Col>
                      );
                    })}
                </Row>
                <Row className="m-0  border-parent-right">
                  {[...chartBoxList]
                    .slice(4, 8)
                    .map((chartBox, chartBoxIndex) => {
                      if (!chartBox?.BoxEnabled) return null;
                      return (
                        <Col
                          className={`${"border-item"} p-2`}
                          style={{
                            minHeight: "200px",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="f-grey">{chartBox?.BoxTitle}</div>
                            <div>
                              {chartBox?.BoxEnabled ? (
                                <img
                                  width={14}
                                  height={14}
                                  src={chartBox?.BoxIcon}
                                  alt=""
                                />
                              ) : (
                                <img
                                  width={14}
                                  height={14}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  // className="ml-2"
                                />
                              )}
                            </div>
                          </div>
                          {chartBox?.BoxEnabled ? (
                            <>
                              <div className="f-18 f-500 my-2">
                                {chartBoxIndex === 0
                                  ? chartBox?.BoxScoreText
                                  : chartBox?.BoxScore}
                              </div>
                              <div>
                                {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
                                <MyLineChart
                                  redraw={false}
                                  data={
                                    chartBox?.ChartData_Points?.Points?.length >
                                    0
                                      ? renderGraphByPoints(
                                          chartBox?.ChartData_Points?.Points
                                        )
                                      : data
                                  }
                                  options={options}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="f-12 f-darkgrey pt-2">
                                You dont have access to this data. Update your
                                access level for this supplier
                              </div>
                            </>
                          )}
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          )}
        </>
      )}
      <CreateCategorySpendModal
        isSaas={isSaas}
        show={showCategorySpendModal}
        hideModal={() => setShowCategorySpendModal(false)}
        refreshData={() => {
          getTechnologySpendData().then(() => {
            let shomMoreBtn = document.getElementById("show-more-less-btn");
            if (shomMoreBtn?.innerText === "Show more") {
              shomMoreBtn.click();
            }
            // let categories = document.getElementsByClassName("category-item-on-dashboard")
            // let lastCategory = categories[categories.length - 1]

                  // lastCategory.classList.add("blinking-grey-text")
                  // setTimeout(() => {
                  //   lastCategory.classList.remove("blinking-grey-text")

                  // }, 3000);
                });

                TechnologySpendHeaderRef.current.scrollIntoView({
                  // behavior: 'smooth',
                  block: "start", // 'start', 'center', 'end', or 'nearest'
                  // inline: 'center' // 'start', 'center', 'end', or 'nearest'
                });
                let scroller = document.getElementById("mainBody");

          scroller.scrollBy(0, -110);
        }}
      />

      <TechnologySpendModalV2
        isSaas={isSaas}
        isDraggableParent
        defaultCurrencyData={technologySpendData?.CurrencyCode_Selected}
        // show={showTechnologySpendModal}
        hideModal={() => {
          setShowTechnologySpendModal(false);
          setListViewInvoiceData();
          setSubscriptionViewInvoiceData();
        }}
        refreshData={() => {
          getTechnologySpendData();
          if (viewType.id === 2) {
            getListViewData();
          }
          if (viewType.id === 3) {
            getSubscriptionViewData();
          }
        }}
        listViewInvoiceData={listViewInvoiceData}
        subscriptionViewInvoiceData={subscriptionViewInvoiceData}
        parentID="mainRow"
        categoryData={selectedCategory}
        // parentID="msp-main-screen-for-integration-modal"
      />
      <TechnologySpendModal
        isSaas={isSaas}
        isDraggableParent
        defaultCurrencyData={technologySpendData?.CurrencyCode_Selected}
        show={showTechnologySpendModal}
        hideModal={() => {
          setShowTechnologySpendModal(false);
          setListViewInvoiceData();
          setSubscriptionViewInvoiceData();
          clearParams();
          setDefaultSpendSelectedTab(undefined);
        }}
        refreshData={() => {
          getTechnologySpendData();
          if (viewType.id === 2) {
            getListViewData();
          }
          if (viewType.id === 3) {
            getSubscriptionViewData();
          }
        }}
        listViewInvoiceData={listViewInvoiceData}
        subscriptionViewInvoiceData={subscriptionViewInvoiceData}
        parentID="mainRow"
        setDefaultSpendSelectedTab={setDefaultSpendSelectedTab}
        setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
        categoryData={selectedCategory}
        {...(integrationName && { defaultTitle: integrationName })}
        {...(integrationCategory && { defaultCategoryName: integrationCategory })}
        defaultSpendSelectedTab={defaultSpendSelectedTab}
        {...(action === ROUTE_ACTIONS.addSubscription) && {
          defaultSpendSelectedTab: "addBySubscription"
        }}
        // parentID="msp-main-screen-for-integration-modal"
      />

            {/* <MSPTechnicalDrillDownCyberScoreModal
      isSaas={isSaas}
        clientId={clientId}
        show={showTechnicalDrillDowncyberScoreModa}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getPageData();
        }}
                      
              
      />

      <MSPTechnicalDrillDownCyberComplianceModal
      isSaas={isSaas}
        clientId={clientId}
        show={showTechnicalCyberComplianceModal}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getPageData();
        }}
      /> */}
            {/* <MSPTechnicalDrillDownCyberThreatLevelModal
      isSaas={isSaas}
        clientId={clientId}
        show={showTechnicalDrillDownCyberThreatLevel}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getPageData();
        }}

              
              
      /> */}
      <CyberGroupModal
        isSaas={isSaas}
        // showActivePackage={true}
        activePackageId={activePackageId}
        activePackageName={activePackageName}
        show={showCyberGroupModal}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getPageData();
        }}
        clientId={clientId}
        parentID={"mainBody"}
        defaultTab={defaultTab}
        AccessStateLabel={"AccessState_TechnologySpend"}  
        isViewOnly={isViewOnly} 
      />
       
           </div>
        </>
      )}
    </>
  );
};

export default MspAttackSurface;

const MspDashboardLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="180" rx="8" ry="8" width="100%" height="230" />

      <rect x="0%" y="460" rx="8" ry="8" width="32%" height="300" />
      <rect x="34%" y="460" rx="8" ry="8" width="32%" height="300" />
      <rect x="68%" y="460" rx="8" ry="8" width="32%" height="300" />

      <rect x="0%" y="800" rx="8" ry="8" width="100%" height="300" />
    </ContentLoader>
  );
};

const _boxData = [
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
  {
    BoxSortOrder: 3,
    BoxTitle: "Cyber Score",
    BoxEnabled: true,
    BoxScore: 0,
    CurrencySymbol: null,
    BoxIcon:
      "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreyCircle.svg",
    ChartData_Points: {
      Label: "CyberCompliance",
      WebAppLabel: "CyberCompliance 22",
      CurrentValue: 22,
      Assessment: "Dangerous",
      BaseColour: "#F36060",
      Period: 0,
      MaxValue: 31,
      Points: [
        {
          Order: 0,
          Label: "5/1",
          Value: 29,
        },
        {
          Order: 1,
          Label: "12/1",
          Value: 31,
        },
        {
          Order: 2,
          Label: "19/1",
          Value: 31,
        },
        {
          Order: 3,
          Label: "26/1",
          Value: 31,
        },
        {
          Order: 4,
          Label: "2/2",
          Value: 31,
        },
        {
          Order: 5,
          Label: "9/2",
          Value: 0,
        },
        {
          Order: 6,
          Label: "16/2",
          Value: 0,
        },
      ],
    },
  },
];

export function convertToDDMMYYYY(formattedDate) {
  const dateObject = new Date(formattedDate);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Month is 0-based, so add 1
  const year = dateObject.getFullYear();

  const formattedDateString = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
  return formattedDateString;
}

function getListViewFilters(kwargs) {
  let criterion_filters = [];
  if (kwargs?.filters) {
    for (let index = 0; index < kwargs?.filters?.length; index++) {
      if (
        kwargs?.filters[index]?.filterType === "supplier" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 0,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item?.PotentialSupplierId.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "date" &&
        kwargs?.filters[index]?.selectedDateRange?.startDate &&
        kwargs?.filters[index]?.selectedDateRange?.endDate
      ) {
        criterion_filters.push({
          criteriaEnum: 1,
          SearchParams: [
            kwargs?.filters[index]?.selectedDateRange?.startDate,
            kwargs?.filters[index]?.selectedDateRange?.endDate,
          ],
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "category" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 2,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.CategoryID.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "subcategory" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 3,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.CategoryID.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "amount" &&
        kwargs?.filters[index]?.toAmount &&
        kwargs?.filters[index]?.fromAmount
      ) {
        criterion_filters.push({
          criteriaEnum: 4,
          SearchParams:kwargs?.filters[index]?.toAmount? [
            kwargs?.filters[index]?.fromAmount.toString(),
            kwargs?.filters[index]?.toAmount.toString(),
          ]:[
            kwargs?.filters[index]?.fromAmount.toString(), 
          ],
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "status" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 5,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.Id.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "expires" &&
        kwargs?.filters[index]?.selectedDateRange?.startDate &&
        kwargs?.filters[index]?.selectedDateRange?.endDate
      ) {
        criterion_filters.push({
          criteriaEnum: 6,
          SearchParams: [
            kwargs?.filters[index]?.selectedDateRange?.startDate,
            kwargs?.filters[index]?.selectedDateRange?.endDate,
          ],
        });
      }
    }
  }

  return criterion_filters;
}
