import React from "react";
import './searchFilterComponent.scss';
import { Dropdown } from "react-bootstrap";

export const FilterComponent = ({
    filterList=[],
    childFilterItem,
    isWhite
}) => {

    // const filterList = [{
    //     img: "/images/filter-icon.svg",
    //     text: 'test'
    // },{
    //     img: "/images/filter-icon.svg",
    //     text: 'test'
    // },{
    //     img: "/images/filter-icon.svg",
    //     text: 'test'
    // }]

    return (
        <Dropdown className="transparennt-wrapper-dropdown my-dropdown">
            <Dropdown.Toggle
                style={{ width: '32px', height: '32px'}}
                className="d-flex align-items-center justify-content-center"
            >
                <img
                    alt=""
                    width={15}
                    height={10}
                    src={isWhite ? "/images/filter-icon-white.svg" : "/images/filter-icon.svg"}
                    className="pointer"
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu">
                <div className="px-2">
                    <div className="f-400 f-12 f-darkgrey">
                        Filters
                    </div>
                    {
                        childFilterItem ?? <div>
                            {
                                filterList?.length > 0 ? <div>
                                    {
                                        filterList?.map((filterItem) => {
                                            return (
                                                <div className="d-flex align-items-center bg-hover-grey py-1 pointer">
                                                    <img
                                                        alt=""
                                                        // height={14}
                                                        src={filterItem?.img}
                                                        className="mr-2"
                                                    />
                                                    <div className="f-400 f-14 f-grey">
                                                        {filterItem?.text}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> : <div className="f-400 f-13 f-darkgrey py-1">
                                    No filters yet
                                </div>
                            }
                        </div>
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default FilterComponent;
