const ThumbDownIcon = (props) => (
  props?.checked ? (
    <svg width="14" height="13" {...props} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0593 7.92483H11.2411V0.666748H13.0593C13.22 0.666748 13.3742 0.730472 13.4879 0.843902C13.6015 0.957331 13.6654 1.11117 13.6654 1.27159V7.31999C13.6654 7.4804 13.6015 7.63425 13.4879 7.74767C13.3742 7.8611 13.22 7.92483 13.0593 7.92483ZM9.85143 8.70689L5.97264 12.5779C5.92109 12.6295 5.8526 12.6607 5.77978 12.666C5.70695 12.6712 5.63469 12.65 5.57627 12.6063L5.0593 12.2192C4.91573 12.1116 4.80728 11.964 4.74763 11.795C4.68799 11.6261 4.67982 11.4432 4.72415 11.2696L5.42294 8.52967H1.54415C1.22268 8.52967 0.914371 8.40222 0.687054 8.17536C0.459737 7.9485 0.332032 7.64082 0.332032 7.31999V6.0474C0.331868 5.88932 0.362754 5.73274 0.422941 5.58652L2.2993 1.04175C2.34493 0.930914 2.42251 0.8361 2.52221 0.769336C2.62191 0.702572 2.73924 0.666866 2.8593 0.666748H9.42294C9.58368 0.666748 9.73783 0.730472 9.85149 0.843902C9.96515 0.957331 10.029 1.11117 10.029 1.27159V8.27926C10.029 8.43967 9.96509 8.59348 9.85143 8.70689Z" fill="#F36060"/>
    </svg>
  ) : (
    <svg width="14" height="13" {...props} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.42294 8.32844H1.54415C1.22268 8.32844 0.914371 8.20425 0.687054 7.98319C0.459737 7.76214 0.332032 7.46232 0.332032 7.1497V5.90966C0.331868 5.75562 0.362754 5.60305 0.422941 5.46056L2.2993 1.03204C2.34493 0.924035 2.42251 0.831647 2.52221 0.76659C2.62191 0.701534 2.73924 0.666741 2.8593 0.666626H13.0593C13.22 0.666626 13.3742 0.72872 13.4879 0.839248C13.6015 0.949777 13.6654 1.09969 13.6654 1.256V7.1497C13.6654 7.30601 13.6015 7.45592 13.4879 7.56644C13.3742 7.67697 13.22 7.73907 13.0593 7.73907H10.949C10.852 7.73904 10.7564 7.76167 10.6702 7.80506C10.584 7.84844 10.5098 7.91131 10.4538 7.98837L7.149 12.5418C7.10722 12.5994 7.04561 12.6405 6.97534 12.6577C6.90507 12.6749 6.83079 12.667 6.76597 12.6356L5.66658 12.1004C5.35718 11.95 5.11001 11.7012 4.96613 11.3953C4.82226 11.0894 4.79033 10.7449 4.87567 10.4189L5.42294 8.32844ZM10.029 6.80315V1.84537H3.26537L1.54415 5.90966V7.1497H5.42294C5.60755 7.14972 5.78971 7.19075 5.95553 7.26966C6.12135 7.34856 6.26644 7.46326 6.37974 7.60499C6.49304 7.74673 6.57156 7.91176 6.60929 8.08749C6.64703 8.26322 6.64299 8.44501 6.59749 8.619L6.05021 10.7101C6.03309 10.7753 6.03944 10.8443 6.06822 10.9055C6.097 10.9667 6.14647 11.0165 6.2084 11.0466L6.609 11.2411L9.46355 7.30883C9.61506 7.10019 9.809 6.92927 10.029 6.80315ZM11.2411 6.56033H12.4532V1.84537H11.2411V6.56033Z" fill="#616778"/>
    </svg>
  )
);

export default ThumbDownIcon;
