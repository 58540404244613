export const SubActiveIcon = (props) => (
  <svg width="12" height="12" {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.700195 6.0002C0.700195 3.07301 3.07301 0.700195 6.0002 0.700195C8.92738 0.700195 11.3002 3.07301 11.3002 6.0002C11.3002 8.92738 8.92738 11.3002 6.0002 11.3002C3.07301 11.3002 0.700195 8.92738 0.700195 6.0002ZM6.8002 3.2002V5.7002H8.8002V7.3002H5.2002V3.2002H6.8002Z" fill="#40D88E"/>
  </svg>
);

export const SubSuspendedIcon = (props) => (
  <svg width="12" height="12" {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.0002 11.3002C3.07301 11.3002 0.700195 8.92739 0.700195 6.0002C0.700195 3.07301 3.07301 0.700195 6.0002 0.700195C8.92739 0.700195 11.3002 3.07301 11.3002 6.0002C11.3002 8.92739 8.92739 11.3002 6.0002 11.3002ZM4.4102 4.4102V7.5902H5.4702V4.4102H4.4102ZM6.5302 4.4102V7.5902H7.5902V4.4102H6.5302Z" fill="#FFAC2C"/>
  </svg>
);

export const SubCancelledIcon = (props) => (
  <svg width="12" height="12" {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.0002 11.3002C3.07301 11.3002 0.700195 8.92739 0.700195 6.0002C0.700195 3.07301 3.07301 0.700195 6.0002 0.700195C8.92739 0.700195 11.3002 3.07301 11.3002 6.0002C11.3002 8.92739 8.92739 11.3002 6.0002 11.3002ZM6.0002 5.25078L4.50136 3.75141L3.75141 4.50136L5.25078 6.0002L3.75141 7.49904L4.50136 8.24899L6.0002 6.74962L7.49904 8.24899L8.24899 7.49904L6.74962 6.0002L8.24899 4.50136L7.49904 3.75141L6.0002 5.25078Z" fill="#F36060"/>
  </svg>
);

export const SubCompletedIcon = (props) => (
  <svg width="12" height="12" {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.0002 11.3002C3.07301 11.3002 0.700195 8.92739 0.700195 6.0002C0.700195 3.07301 3.07301 0.700195 6.0002 0.700195C8.92739 0.700195 11.3002 3.07301 11.3002 6.0002C11.3002 8.92739 8.92739 11.3002 6.0002 11.3002ZM5.47179 8.1202L9.21889 4.37257L8.46947 3.62315L5.47179 6.62136L3.97242 5.12199L3.223 5.87141L5.47179 8.1202Z" fill="#616778"/>
  </svg>
);
