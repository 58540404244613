import React, { useEffect, useState } from "react";
import './searchFilterComponent.scss';

export const SearchComponent = ({
    value = "",
    passedRef,
    setRef = () => { },
    onChange = (e) => { },
    onCross = () => { },
    isWhite,
    noToSearch = false
}) => {
    const [searchInput, setSearchInput] = useState(noToSearch ? false : value == '' ? false : true);
    useEffect(() => {
        if (!noToSearch) {
            setSearchInput(value == '' ? false : true);
        }
    }, [value])


    useEffect(() => {
        if (noToSearch == false) {
            const handleKeyPress = (event) => {
                if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
                    setSearchInput(true);
                    setRef();
                }
            };

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }
    }, []);

    return (
        <div className="mx-2 d-flex align-items-center">
            <img
                alt=""
                width={17}
                height={17}
                src={isWhite ? "/images/attack-surface/search-icon-white.svg" : "/images/attack-surface/search-icon.svg"}
                className="pointer"
                onClick={() => {
                    if (!noToSearch) {
                        setSearchInput(true);
                        setRef();
                    }
                }}
            />
            <div
                className={`d-flex align-items-center ${searchInput ? "animated-search-input-open" : "animated-search-input-close"}`}
            >
                <input
                    type="text"
                    placeholder="Search.."
                    ref={passedRef}
                    value={value}
                    onChange={(e) => {
                        onChange(e);
                    }}
                    onBlur={() => {
                        if (!noToSearch) {
                            if (value == "") {
                                console.log('----------------------6')
                                setSearchInput(false);
                            }
                        }
                    }}
                    className={`pl-2  ${isWhite ? 'bg-transparent f-white-light' : ''}`}
                    autoFocus
                />
                <div
                    style={{
                        visibility: value !== "" ? "visible" : "hidden",
                        padding: '0 5px'
                    }}
                    className="pointer"
                    onClick={() => {
                        onCross();
                    }}
                >
                    <img
                        alt=""
                        src={isWhite ? "/images/settings-v2/header-search-cross-white.svg" : "/images/settings-v2/header-search-cross.svg"}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchComponent;
