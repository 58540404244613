import { postData } from "../../Utils/commonApi";
import useBaseActions from "./base.actions";

const useUserActions = () => {
  const { authTokens } = useBaseActions();

  const updateGettingStarted = ({
    configValue, configType, clientId
  }) => {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateGettingStarted", {
        ConfigValue: configValue,
        ConfigType: configType,
        ...(clientId && { objId_Customer: clientId }),
      })
        .then((response) => {
          if (response?.Success) {
            resolve(response);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          console.log(err, "error in PSAGlobalConfig");
          reject(false);
        });
    });
  }

  return {
    updateGettingStarted
  }
}

export default useUserActions;
