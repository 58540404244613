import { Dropdown, Nav, Tab } from "react-bootstrap";
import HighgroundDatePicker from "../../../../test/TestComponent";
import AddItemInSpendModal, { CurrencyDropdown, TaxRateDropdown } from "../../addItemInSpendModal/AddItemInSpendModal";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import AttackSurfaceTooltip from "../../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { CustomHGDropdown } from "../../../clients/createClientScreens/CyberComplianceForGroupModal";
import moment from "moment";
import { removeWhiteSpace, validateNumber } from "../../../../../Utils/CommonFunctions";
import SubscriptionTermsPeriod from "../subscription-terms-period/subscription-terms-period.component";
import dateUtils from "../../../../../Utils/date.utils";
import { NoAutomaticGeneratedInvoiceUI } from "../techspend.not-found";
import SubPaymentCardItem from "../sub-payment-card-item/sub-payment-card-item.component";
import CategorySelectorDropdown from "../../../../Common/category-selector/category-selector.dropdown";
import { useEffect } from "react";
import LinkIcon from "../../../../icons/link.icon";
import SubscriptionCard from "../../subscription.card";
import SubscriptionIcon from "../../../../Common/subscription-icon.component";

const TechspendAddspendContent = ({
  addSpendSelectedTab,
  setAddSpendSelectedTab,
  invoicePageData,
  invoiceEditMode,
  setInvoiceEditMode,
  setInvoicePageData,
  changesInInvoice,
  setChangesInInvoice,
  setSwitchInvoiceTabModalData,
  executeInvoiceSwitchingModal,
  setUnlinkRelinkModalData,
  all_integration_data,
  showAddByInvoiceSupplierDropdown,
  setShowAddByInvoiceSupplierDropdown,
  potentialSupplierSearch,
  setPotentialSupplierSearch,
  currencyList,
  userSelectedCurrency,
  setUserSelectedCurrency,
  updateExchangeRateData,
  exchangeRateData,
  defaultCurrencyData,
  getSubTotalAmountForInvoice,
  getTaxForInvoice,
  getTotalAmountForInvoice,
  setInvoiceItemIndex,
  setSelectedInvoiceItemData,
  setShowAddItemSpendModal,
  InvoiceItemIndex,
  selectedInvoiceItemData,
  categoryData,
  subCategoryPageData,
  categoryPageData,
  pages,
  getTaxRateList,
  showAddItemSpendModal,
  errors,
  setErrors,
  setShowAddByAmountSupplierDropdown,
  handleDateForInvoice,
  highlightInvoiceItem,
  convertToCurrencyFormat,
  showAddByAmountSupplierDropdown,
  deleteTaxRate,
  updateTaxList,
  onClickUpdateTaxRate,
  isTaxRateUpdating,
  taxList,
  setTaxRateData,
  setShowAddEditTaxRateModal,
  getSubCategoryList,
  subCategoryList,
  disableCalculationFields,
  getSubscriptionEndDate,
  subPaymentDetails,
  Pages,
  getInvoiceDetails,
  goTo,
  setLoading,
  clientId,
  getSubPaymentLastInvoice
}) => {
  useEffect(() => {
    setTimeout(() => {
      setChangesInInvoice(0);
    }, 200);
  }, []);
  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={addSpendSelectedTab}
        activeKey={addSpendSelectedTab}
        onSelect={(e) => {
          let tabName = e;
          if (changesInInvoice > 0) {
            setSwitchInvoiceTabModalData((prev) => ({
              ...prev,
              deleteParams: tabName,
              showModal: true,
            }));
          } else {
            executeInvoiceSwitchingModal(e);
          }
        }}
      >
        <div>
          {invoicePageData?.objId_Invoice === 0 && (
            <div
              sm={12}
              className="tab-header d-flex justify-content-between align-items-center ml- p-0"
            >
              <div className={`${true}`}>
                <Nav
                  variant="pills"
                  className="flex-row"
                  onSelect={(e) => {
                    let tabName = e;
                    if (changesInInvoice > 0) {
                      setSwitchInvoiceTabModalData((prev) => ({
                        ...prev,
                        deleteParams: tabName,
                        showModal: true,
                      }));
                    } else {
                      executeInvoiceSwitchingModal(e);
                    }
                  }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="addByInvoice">
                      Add Invoice
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item id="">
                    <Nav.Link eventKey="addByAmount">
                      Add Amount
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="addBySubscription">
                      Add subscription
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          )}
          <div sm={12} className="p-0">
            <Tab.Content>
              <Tab.Pane eventKey="addByInvoice">
                <div style={{ padding: '8px 0', position: 'relative' }}>
                  {invoicePageData?.ID && !invoicePageData?.editable && (
                    <div 
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        top: 0,
                        background: 'rgba(255, 255, 255, 0.3)',
                        zIndex: 1,
                      }}
                    />
                  )}
                  <div
                    className="w-100"
                    style={{
                      pointerEvents: invoiceEditMode ? "all" : "none",
                    }}
                  >
                    {invoicePageData?.IntegrationId > 0 ? (
                      <div className="w-100 py-2 d-flex align-items-center f-black">
                        <div className="w-50 f-500">Payment # </div>
                        <div className="w-50 f-500">
                          {invoicePageData?.InvoiceRef ?? "N/A"}
                        </div>
                      </div>
                    ) : (
                      <div
                        tabIndex={-1}
                        className={`  ${
                          errors?.invoiceRefError ? "input-error" : ""
                        }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                      >
                        <div className="f-darkgrey f-500">
                        Payment #{" "}
                        </div>
                        <input
                          tabIndex={0}
                          type="text"
                          className="bg-transparent flex-grow-1 border-none pl-2 "
                          placeholder="Type"
                          value={invoicePageData?.InvoiceRef ?? ""}
                          onChange={(e) => {
                            setChangesInInvoice(changesInInvoice + 1);
                            let value = e.target.value;
                            setInvoicePageData((prev) => ({
                              ...prev,
                              InvoiceRef: removeWhiteSpace(value),
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              invoiceRefError: false,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {invoicePageData?.IsImported === false &&
                    invoicePageData?.IntegrationId > 0 && (
                      <>
                        <div className="d-flex align-items-center my-1">
                          <div className="f-12 f-darkgrey">
                            <img
                              src="/images/msp/unlink-icon.svg"
                              alt=""
                              className="mr-1"
                              width={10}
                              height={10}
                            />{" "}
                            &nbsp;
                            {invoicePageData?.UnlinkTime?.replace(
                              "cancel",
                              ""
                            )}
                            &nbsp;
                          </div>
                          {invoicePageData?.UnlinkTime?.indexOf(
                            "cancel"
                          ) !== -1 ? (
                            <div
                              className="pointer f-blue f-500"
                              onClick={() => {
                                setUnlinkRelinkModalData((prev) => ({
                                  ...prev,
                                  show: true,
                                  linkButtonText: "Unlink",
                                  headerText: `${`Unlink ${
                                    invoicePageData.InvoiceRef
                                  } from ${
                                    all_integration_data[
                                      invoicePageData?.IntegrationId
                                    ]
                                  }`}`,
                                  bodyText: `${`Are you sure you would like to cancel relinking of invoice ${
                                    invoicePageData.InvoiceRef
                                  } to ${
                                    all_integration_data[
                                      invoicePageData?.IntegrationId
                                    ]
                                  }?`}`,
                                  deleteParams: {
                                    linkStatus: false,
                                    objId_invoice:
                                      invoicePageData?.objId_Invoice,
                                  },
                                }));
                              }}
                            >
                              Cancel
                            </div>
                          ) : (
                            <div
                              className="pointer f-blue f-500"
                              onClick={() => {
                                setUnlinkRelinkModalData((prev) => ({
                                  ...prev,
                                  show: true,
                                  linkButtonText: "Relink",
                                  headerText: `${`Relink ${
                                    invoicePageData.InvoiceRef
                                  } to ${
                                    all_integration_data[
                                      invoicePageData?.IntegrationId
                                    ]
                                  }`}`,
                                  bodyText: `${`All edits you have made to this invoice in HighGround will be lost.\n\nAre you sure you would like to relink ${
                                    invoicePageData.InvoiceRef
                                  } to ${
                                    all_integration_data[
                                      invoicePageData?.IntegrationId
                                    ]
                                  }?`}`,
                                  deleteParams: {
                                    linkStatus: true,
                                    objId_invoice:
                                      invoicePageData?.objId_Invoice,
                                  },
                                }));
                              }}
                            >
                              Relink
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  <div
                    className="w-100"
                    style={{
                      pointerEvents: invoiceEditMode ? "all" : "none",
                    }}
                  >
                    {/* Description */}
                    {invoicePageData?.IntegrationId > 0 ? (
                      <div className="w-100 py-2 d-flex align-items-center f-black">
                        <div className="w-50 f-500">Description</div>
                        <div className="w-50">
                          {invoicePageData?.Description
                            ? invoicePageData?.Description
                            : "N/A"}
                        </div>
                      </div>
                    ) : (
                      <div
                        tabIndex={-1}
                        className={`  ${
                          errors?.descriptionError
                            ? "input-error"
                            : ""
                        }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                      >
                        <textarea
                          tabIndex={0}
                          rows={4}
                          style={{ resize: "none" }}
                          className="bg-transparent flex-grow-1 border-none  hide-scrollbar"
                          placeholder="Description"
                          value={invoicePageData?.Description ?? ""}
                          onChange={(e) => {
                            setChangesInInvoice(changesInInvoice + 1);
                            let value = e.target.value;
                            setInvoicePageData((prev) => ({
                              ...prev,
                              Description: removeWhiteSpace(value),
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              descriptionError: false,
                            }));
                          }}
                        />
                      </div>
                    )}
                    <hr tabIndex={-1} />
                    <div
                      className="f-12 f-500 f-darkgrey mb-2"
                      tabIndex={-1}
                    >
                      Details
                    </div>
                    <div
                      className="w-100  d-flex align-items-center f-black"
                      tabIndex={-1}
                    >
                      <div className="w-50 f-500">Supplier</div>
                      <div className="w-50">
                        {invoicePageData?.IntegrationId > 0 ? (
                          <>
                            {invoicePageData?.PotentialSupplierDetail_List?.filter(
                              (pt) => pt?.IsMSP === true
                            )?.map((item, index) => {
                              return (
                                <div className="d-flex align-items-center justify-content-between px-2 py-1 pointer">
                                  <div className="d-flex align-items-center f-black">
                                    <img
                                      src={
                                        item?.PotentialSupplierIcon
                                      }
                                      onError={({
                                        currentTarget,
                                      }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          "/images/user-logo.svg";
                                      }}
                                      width={18}
                                      height={18}
                                      className="mr-2 rounded-full"
                                      alt=""
                                    />
                                    {item?.PotentialSupplierName}
                                  </div>
                                  <div className="f-12 f-darkgrey">
                                    You
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <Dropdown
                            show={showAddByInvoiceSupplierDropdown}
                            onToggle={(isOpen) =>
                              setShowAddByInvoiceSupplierDropdown(
                                isOpen
                              )
                            }
                            className="add-item-category-dropdown w-100"
                          >
                            <Dropdown.Toggle className="w-100">
                              <div 
                                className="highlight-input p-2 radius-4 d-flex align-items-center"
                              >
                                {invoicePageData
                                  ?.PotentialSupplierSelected
                                  ?.IsMSP === true ? (
                                  <div
                                    className="d-flex align-items-center justify-content-between  w-100 pointer"
                                    style={{
                                      height: "15px",
                                    }}
                                  >
                                    <div className="d-flex align-items-center f-black">
                                      <img
                                        src={
                                          invoicePageData
                                            ?.PotentialSupplierSelected
                                            ?.PotentialSupplierIcon
                                        }
                                        onError={({
                                          currentTarget,
                                        }) => {
                                          currentTarget.onerror =
                                            null; // prevents looping
                                          currentTarget.src =
                                            "/images/user-logo.svg";
                                        }}
                                        width={14}
                                        height={14}
                                        className="mr-2 rounded-full"
                                        alt=""
                                      />
                                      {
                                        invoicePageData
                                          ?.PotentialSupplierSelected
                                          ?.PotentialSupplierName
                                      }
                                    </div>
                                    <div className="f-12 f-darkgrey">
                                      You
                                    </div>
                                  </div>
                                ) : (
                                  <input
                                    tabIndex={0}
                                    className="border-none bg-transparent flex-grow-1"
                                    type="text"
                                    placeholder="Optional"
                                    value={
                                      invoicePageData
                                        ?.PotentialSupplierSelected
                                        ?.PotentialSupplierName
                                    }
                                    onChange={(e) => {
                                      setChangesInInvoice(
                                        changesInInvoice + 1
                                      );
                                      setShowAddByAmountSupplierDropdown(
                                        true
                                      );
                                      let value = e.target.value;
                                      setPotentialSupplierSearch(
                                        e.target.value
                                      );
                                      let SelectedSupplier = {
                                        PotentialSupplierName: value,
                                        PotentialSupplierId: 0,
                                      };
                                      let updated_data = {
                                        ...invoicePageData,
                                      };
                                      updated_data.PotentialSupplierSelected =
                                        SelectedSupplier;
                                      setInvoicePageData(
                                        updated_data
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="w-100 transparent-scroller mx-1"
                              style={{
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                (pt) => pt?.IsMSP === true
                              )?.map((item, index) => {
                                return (
                                  <div
                                    className="d-flex align-items-center justify-content-between px-2 py-1 pointer"
                                    onClick={(e) => {
                                      setChangesInInvoice(
                                        changesInInvoice + 1
                                      );
                                      let updated_data = {
                                        ...invoicePageData,
                                      };
                                      updated_data.PotentialSupplierSelected =
                                        item;
                                      setInvoicePageData(
                                        updated_data
                                      );
                                      setPotentialSupplierSearch("");
                                      e.target
                                        .closest(
                                          ".add-item-category-dropdown"
                                        )
                                        .click();
                                    }}
                                  >
                                    <div className="d-flex align-items-center f-grey">
                                      <img
                                        src={
                                          item?.PotentialSupplierIcon
                                        }
                                        onError={({
                                          currentTarget,
                                        }) => {
                                          currentTarget.onerror =
                                            null; // prevents looping
                                          currentTarget.src =
                                            "/images/user-logo.svg";
                                        }}
                                        width={18}
                                        height={18}
                                        className="mr-2 rounded-full"
                                        alt=""
                                      />
                                      {item?.PotentialSupplierName}
                                    </div>
                                    <div className="f-12 f-darkgrey">
                                      You
                                    </div>
                                  </div>
                                );
                              })}
                              <div className={` f-grey f-12 pl-2`}>
                                Last used
                              </div>
                              {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                (pk) =>
                                  pk?.PotentialSupplierName?.length >
                                  0
                              )
                                ?.filter(
                                  (pt) =>
                                    pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                      potentialSupplierSearch?.toLowerCase()
                                    ) && pt?.IsMSP === false
                                )
                                ?.map((item, itemIndex) => {
                                  return (
                                    <div
                                      className="d-flex align-items-center justify-content-between my-2 pointer pl-2"
                                      onClick={(e) => {
                                        let updated_data = {
                                          ...invoicePageData,
                                        };
                                        updated_data.PotentialSupplierSelected =
                                          item;
                                        setInvoicePageData(
                                          updated_data
                                        );
                                        setPotentialSupplierSearch(
                                          ""
                                        );
                                        e.target
                                          .closest(
                                            ".add-item-category-dropdown"
                                          )
                                          .click();
                                      }}
                                    >
                                      <div
                                        className={`${
                                          item?.PotentialSupplierId ===
                                          invoicePageData
                                            ?.PotentialSupplierSelected
                                            ?.PotentialSupplierId
                                            ? "f-blue "
                                            : "f-black"
                                        }`}
                                      >
                                        <img
                                          alt=""
                                          className={`${
                                            item.selected
                                              ? "blue-icon"
                                              : ""
                                          } mr-2 d-none`}
                                          src={item.image}
                                        />
                                        {item?.PotentialSupplierName}
                                      </div>
                                      <div>
                                        {item?.PotentialSupplierId ===
                                          invoicePageData
                                            ?.PotentialSupplierSelected
                                            ?.PotentialSupplierId && (
                                          <img
                                            alt=""
                                            className=" mr-3"
                                            src="/images/msp/blue-tick.svg"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              {potentialSupplierSearch !== "" && (
                                <div
                                  className={
                                    "f-500  f-blue pointer pl-2"
                                  }
                                  onClick={(e) => {
                                    let SelectedSupplier = {
                                      PotentialSupplierName:
                                        potentialSupplierSearch,
                                      PotentialSupplierId: 0,
                                    };
                                    let updated_data = {
                                      ...invoicePageData,
                                    };
                                    updated_data.PotentialSupplierSelected =
                                      SelectedSupplier;
                                    setInvoicePageData(updated_data);
                                    setPotentialSupplierSearch("");
                                    e.target
                                      .closest(
                                        ".add-item-category-dropdown"
                                      )
                                      .click();
                                  }}
                                >
                                  Add "{potentialSupplierSearch}"
                                  supplier
                                </div>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                    <div className="w-100 py-2 d-flex align-items-center f-black">
                      <div className="w-50 f-500">Contact Email</div>
                      <div className="w-50">
                        <div
                          className={` ${
                            errors?.invoicePageEmailError
                              ? "input-error"
                              : ""
                          }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                        >
                          <input
                            type="text"
                            className={` ${""} bg-transparent flex-grow-1 border-none f-500`}
                            placeholder={
                              invoicePageData?.IntegrationId > 0
                                ? "N/A"
                                : "Optional"
                            }
                            value={invoicePageData?.ContactEmail}
                            onChange={(e) => {
                              setChangesInInvoice(
                                changesInInvoice + 1
                              );
                              let value = e.target.value;
                              setInvoicePageData((prev) => ({
                                ...prev,
                                ContactEmail: value,
                              }));
                              setErrors((prev) => ({
                                ...prev,
                                invoicePageEmailError: false,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100  d-flex align-items-center f-black">
                      <div className="w-50 f-500">Invoice Date</div>
                      <div className="w-50 msp-date-picker f-500">
                        <HighgroundDatePicker
                          disableFutureDates
                          isError={errors?.invoiceDateError}
                          selected={
                            // moment(invoicePageData?.InvoiceDate).format("MM/DD/YYYY")
                            // invoicePageData?.InvoiceDate === "0001-01-01T00:00:00"
                            //   ? ""
                            //   : invoicePageData?.InvoiceDate
                            handleDateForInvoice(
                              invoicePageData?.InvoiceDate
                            )
                            // invoicePageData?.InvoiceDate === "0001-01-01T00:00:00"
                            //   ? ""
                            //   : moment(invoicePageData?.InvoiceDate).format("MM/DD/YYYY")
                          }
                          icon={
                            "/images/attack-surface/date-icon.svg"
                          }
                          onChange={(val) => {
                            if (val !== invoicePageData?.InvoiceDate) {
                              setChangesInInvoice(changesInInvoice + 1);
                            }
                            setInvoicePageData((prev) => ({
                              ...prev,
                              InvoiceDate: val,
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              invoiceDateError: false,
                            }));
                            if (
                              exchangeRateData?.FromCurrency !==
                              defaultCurrencyData?.CurrencyName
                            ) {
                              updateExchangeRateData({
                                _date: val,
                              });
                            }
                          }}
                          id={"dvdsgvzdgdj"}
                        />
                        {errors?.invoiceDateError &&
                          errors?.invoiceDateError?.length > 5 && (
                            <div className="f-danger">
                              {errors?.invoiceDateError}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="w-100  d-flex align-items-center f-black">
                      <div className="w-50 f-500">Currency</div>
                      <div className="w-50   f-500">
                        <div className="w-fit-content bg-grey radius-4 p-2 mt-3">
                          <CurrencyDropdown
                          disabled={invoicePageData?.objId_Invoice > 0}
                            showFullCurrencyName
                            menuClassName={
                              "add-amount-currency-dropdown"
                            }
                            width={"200px"}
                            inputWidth={"144px"}
                            currencyList={currencyList}
                            onChange={(data) => {
                              setUserSelectedCurrency(data);
                              updateExchangeRateData({
                                _currency: data?.CurrencyName,
                                _date:
                                  invoicePageData?.InvoiceDate ===
                                  "0001-01-01T00:00:00"
                                    ? moment().format("DD/MM/YYYY")
                                    : handleDateForInvoice(
                                        invoicePageData?.InvoiceDate
                                      ),
                              });
                            }}
                            selected={userSelectedCurrency}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="invoice-summary-table mb-2">
                      <div className="f-12 f-500 f-darkgrey">
                        Item
                      </div>
                      <div className="f-12 f-500 f-darkgrey">
                        Unit Price
                      </div>
                      <div className="f-12 f-500 f-darkgrey">
                        Qty.
                      </div>
                      <div
                        className="f-12 f-500 f-darkgrey text-right"
                        style={{
                          paddingRight: "3rem",
                        }}
                      >
                        Line Total
                      </div>
                    </div>
                    {invoicePageData?.InvoiceItem_List?.map(
                      (invoiceItem, invoiceItemIndex) => {
                       
                        return (
                          <div
                            className={`p-2 mb-2 invoice-summary-table radius-4 bg-hover-grey pointer align-items-center${
                              invoiceItem?.objId_Invoice === 0
                                ? ""
                                : "pointer"
                            }`}
                            style={{
                              background:
                                // invoiceItem?.IsLinkedToCategory ===
                                // true
                                highlightInvoiceItem(invoiceItem,categoryPageData,subCategoryPageData)    ? "#EBF6FC"
                                  : "",
                            }}
                            onClick={() => {
                              // if (invoiceItem?.objId_Invoice === 0) return;
                              setInvoiceItemIndex(invoiceItemIndex);
                              setSelectedInvoiceItemData(invoiceItem);
                              setShowAddItemSpendModal(true);
                            }}
                          >
                            <div
                              className="w-90"
                              title={invoiceItem?.Title}
                            >
                              <div className="f-500">
                                {TrimString(invoiceItem?.Title, 500)}
                              </div>
                              <div className="f-12 f-darkgrey">
                                {invoiceItem?.Category_Selected
                                  ?.CategoryName && (
                                  <>
                                    {TrimString(
                                      invoiceItem?.Category_Selected
                                        ?.CategoryName,
                                      200
                                    )}{" "}
                                    {invoiceItem?.SubCategory_Selected
                                      ?.CategoryName && "> "}
                                    {TrimString(
                                      invoiceItem
                                        ?.SubCategory_Selected
                                        ?.CategoryName,
                                      250
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="f-500">
                              {}
                              {/* {userSelectedCurrency?.CurrencySymbol}{" "} */}
                              {TrimString(
                                convertToCurrencyFormat(
                                  invoiceItem?.ItemValue,
                                  userSelectedCurrency?.CurrencySymbol
                                ),
                                100
                              )}
                            </div>
                            <div className="f-500">
                              x{" "}
                              {TrimString(invoiceItem?.Quantity, 10)}
                            </div>
                            <div className="f-500 f-black d-flex">
                              <div className="w-100 text-right mr-3">
                                <div className="f-500">
                                  {TrimString(
                                    convertToCurrencyFormat(
                                      invoiceItem?.ItemValue *
                                        invoiceItem?.Quantity +
                                        (0 *
                                          invoiceItem?.ItemValue *
                                          invoiceItem?.Quantity *
                                          invoiceItem
                                            ?.TaxRates_Selected
                                            ?.TaxRate) /
                                          100,
                                      userSelectedCurrency?.CurrencySymbol
                                    ),
                                    15
                                  )}
                                </div>
                                <div
                                  className={`f-12 f-darkgrey ${
                                    userSelectedCurrency?.CurrencySymbol ===
                                    defaultCurrencyData?.CurrencySymbol
                                      ? "d-none"
                                      : ""
                                  }`}
                                >
                                  <AttackSurfaceTooltip
                                    bottomLeft={true}
                                    topLeft={false}
                                    content={
                                      <span>
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/msp/white-exchange-rate-icon.svg"
                                          className="mr-1"
                                        />{" "}
                                        The exchange rate on{" "}
                                        {moment(
                                          exchangeRateData?.Date ?? ""
                                        )?.format("DD MMM YYYY")}{" "}
                                        was 1{" "}
                                        {
                                          exchangeRateData?.FromCurrency
                                        }{" "}
                                        = {exchangeRateData?.Rate}{" "}
                                        {
                                          defaultCurrencyData?.CurrencyName
                                        }
                                      </span>
                                    }
                                  >
                                    <img
                                      alt=""
                                      width={13}
                                      height={13}
                                      src="/images/msp/exchange-rate-icon.svg"
                                      className="mr-1"
                                    />
                                  </AttackSurfaceTooltip>
                                  {
                                    defaultCurrencyData?.CurrencySymbol
                                  }
                                  {TrimString(
                                    Number(
                                      exchangeRateData?.Rate *
                                        (invoiceItem?.ItemValue *
                                          invoiceItem?.Quantity) +
                                        (0 *
                                          invoiceItem?.ItemValue *
                                          invoiceItem?.Quantity *
                                          invoiceItem
                                            ?.TaxRates_Selected
                                            ?.TaxRate) /
                                          100
                                    ).toFixed(2),
                                    15
                                  )}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-center mr-1">
                                <img
                                  alt=""
                                  className="mx-1 pointer"
                                  style={{
                                    visibility:
                                      invoiceEditMode === true
                                        ? "visible"
                                        : "hidden",
                                  }}
                                  src="/images/attack-surface/cross-icon.svg"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let updated_data = {
                                      ...invoicePageData,
                                    };
                                    updated_data.InvoiceItem_List.splice(
                                      invoiceItemIndex,
                                      1
                                    );
                                    setInvoicePageData(updated_data);
                                    // if (
                                    //   invoiceItem?.objId_Invoice > 0
                                    // ) {
                                    //   deleteInvoiceItem(
                                    //     invoiceItem?.Id
                                    //   );
                                    // }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                    {invoiceEditMode === true && (
                      <div
                        className="p-2 d-flex align-items-center w-fit-content f-500 f-grey pointer"
                        onClick={() => {
                          if (
                            !invoicePageData?.InvoiceDate ||
                            invoicePageData.InvoiceDate ===
                              "0001-01-01T00:00:00"
                          ) {
                            setErrors((prev) => ({
                              ...prev,
                              invoiceDateError:
                                "Invoice date must be selected before adding invoice line item",
                            }));
                            return;
                          }
                          setChangesInInvoice(changesInInvoice + 1);
                          setInvoiceItemIndex(-1);
                          setSelectedInvoiceItemData({});
                          setShowAddItemSpendModal(true);
                          setErrors((prev) => ({
                            ...prev,
                            invoiceItemsError: false,
                          }));
                        }}
                      >
                        <img
                          alt=""
                          className="mr-2"
                          src="/images/actioncenter/plusgrey.svg"
                        />
                        Add
                      </div>
                    )}
                    {errors?.invoiceItemsError && (
                      <div className="f-danger">
                        {errors.invoiceItemsError}
                      </div>
                    )}
                    <div className="f-12 f-500 f-darkgrey mt-2 mb-1">
                      Total
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-between f-black h-32">
                      <div className="w-30 f-500">Subtotal</div>
                      <div className="w-70">
                        <div className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1">
                          {
                            // invoicePageData?.Tax ?? <div className="f-darkgrey">£ 0.00</div>
                            getSubTotalAmountForInvoice() === 0 ? (
                              <div className="f-black">
                                {userSelectedCurrency?.CurrencySymbol}{" "}
                                0.00
                              </div>
                            ) : (
                              <div className="f-black">
                                {convertToCurrencyFormat(
                                  getSubTotalAmountForInvoice() ?? 0,
                                  userSelectedCurrency?.CurrencySymbol
                                )}
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-between f-black h-32" style={{ gap: '8px' }}>
                      <div className="f-500">
                        <span>
                          Tax
                        </span>
                        {invoicePageData?.TaxRates_Selected && (
                          <span>
                            {` @ ${invoicePageData?.TaxRates_Selected?.TaxRate}% (${invoicePageData?.TaxRates_Selected?.TaxName})`}
                          </span>
                        )}
                      </div>
                      <div className="">
                        <div className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1">
                          {
                            // invoicePageData?.Tax ?? <div className="f-darkgrey">£ 0.00</div>
                            getTaxForInvoice() === 0 ? (
                              <div
                                className={` ${
                                  invoicePageData?.ID !== 0
                                    ? "textBlackBold"
                                    : "f-black"
                                } `}
                              >
                                {userSelectedCurrency?.CurrencySymbol}{" "}
                                0.00
                              </div>
                            ) : (
                              <div className="f-black">
                                {convertToCurrencyFormat(
                                  getTaxForInvoice() ?? 0,
                                  userSelectedCurrency?.CurrencySymbol
                                )}
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="w-100  d-flex align-items-center justify-content-between f-black h-32">
                      <div className="w-30 f-600">Total</div>
                      <div className="w-50">
                        <div
                          className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1 f-600"
                          style={{
                            pointerEvents: "none",
                          }}
                        >
                          {getTotalAmountForInvoice() === 0 ||
                          userSelectedCurrency?.CurrencySymbol ===
                            defaultCurrencyData?.CurrencySymbol ? (
                            <>
                              {getTotalAmountForInvoice() === 0 ? (
                                <div className="f-600 f-black">
                                  {
                                    userSelectedCurrency?.CurrencySymbol
                                  }{" "}
                                  0.00
                                </div>
                              ) : (
                                <div className="f-600 f-black">
                                  {convertToCurrencyFormat(
                                    getTotalAmountForInvoice() ?? 0,
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <div 
                              className="d-flex align-items-center w-100"
                              style={{ 
                                gap: '10px',
                                ...(!defaultCurrencyData && {
                                  justifyContent: 'end'
                                })
                              }}
                            >
                              <div>
                                {convertToCurrencyFormat(
                                  getTotalAmountForInvoice() ?? 0,
                                  userSelectedCurrency?.CurrencySymbol
                                )}
                              </div>
                              {defaultCurrencyData && (
                                <>
                                  <img
                                    width={15}
                                    height={15}
                                    src="/images/msp/exchange-rate-icon.svg"
                                    alt=""
                                  />
                                  <div className="d-flex">
                                    {convertToCurrencyFormat(
                                      exchangeRateData?.Rate *
                                        Number(
                                          getTotalAmountForInvoice() ?? 0
                                        ),
                                      defaultCurrencyData?.CurrencySymbol
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`add-item-spend-modal-wrapper    ${
                        showAddItemSpendModal ? "show" : ""
                      }  `}
                    >
                      <AddItemInSpendModal
                        isDraggableParent
                        showSelectedSubcategory={
                          pages.some(
                            (item) => item.type === "subcategory"
                          )
                            ? true
                            : false
                        }
                        updateExchangeRateData={
                          updateExchangeRateData
                        }
                        invoicePageData={invoicePageData}
                        exchangeRateData={exchangeRateData}
                        defaultCurrencyData={defaultCurrencyData}
                        setUserSelectedCurrency={
                          setUserSelectedCurrency
                        }
                        userSelectedCurrency={userSelectedCurrency}
                        currencyList={currencyList}
                        getTaxRateList={getTaxRateList}
                        InvoiceItemIndex={InvoiceItemIndex}
                        selectedInvoiceItemData={
                          selectedInvoiceItemData
                        }
                        show={showAddItemSpendModal}
                        categoryList={
                          invoicePageData?.SpendCategoryVM_List
                        }
                        currentCategory={categoryData}
                        currentSubcategory={subCategoryPageData}
                        categoryData={categoryData}
                        categoryPageData={categoryPageData}
                        hideModal={() =>
                          setShowAddItemSpendModal(false)
                        }
                        class="add-item-in-spend-modal" // only to remeember it
                        onCreate={(_index, createdInvoiceItem) => {
                          let updated_data = { ...invoicePageData };
                          if (_index > -1) {
                            updated_data.InvoiceItem_List[_index] =
                              createdInvoiceItem;
                          } else {
                            updated_data.InvoiceItem_List = [
                              ...updated_data.InvoiceItem_List,
                              createdInvoiceItem,
                            ];
                          }
                          setInvoicePageData(updated_data);
                        }}
                        onUpdate={(_index, updated_Item) => {
                          let updated_data = { ...invoicePageData };
                          updated_data.InvoiceItem_List[_index] =
                            updated_Item;
                          setInvoicePageData(updated_data);
                        }}
                        onDelete={(_index, _ItemId) => {
                          let updated_data = { ...invoicePageData };
                          if (_index > -1) {
                            updated_data.InvoiceItem_List.splice(
                              _index,
                              1
                            );
                          } else {
                          }
                          setInvoicePageData(updated_data);
                          // refreshData()
                        }}
                      />
                    </div>
                  </div>
                  {invoicePageData?.SubscriptionList?.length > 0 && (
                    <LinkedSubscriptionSection 
                      subscriptionItem={invoicePageData?.SubscriptionList[0]}
                      Pages={Pages}
                      clientId={clientId}
                      getInvoiceDetails={getInvoiceDetails}
                      getSubPaymentLastInvoice={getSubPaymentLastInvoice}
                      goTo={goTo}
                      setAddSpendSelectedTab={setAddSpendSelectedTab}
                      setLoading={setLoading}
                    />
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="addByAmount">
                <div
                  style={{
                    pointerEvents: invoiceEditMode ? "all" : "none",
                    padding: '8px 0',
                  }}
                >
                  <div
                    className={`  ${
                      errors?.invoiceRefError ? "input-error" : ""
                    }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                  >
                    <div className="f-darkgrey">Title:</div>
                    <input
                      type="text"
                      className="bg-transparent flex-grow-1 border-none pl-2"
                      placeholder=" Type"
                      value={invoicePageData?.InvoiceRef ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          InvoiceRef: removeWhiteSpace(value),
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          invoiceRefError: false,
                        }));
                      }}
                    />
                  </div>
                  {/* Description */}
                  <div
                    className={`  ${
                      errors?.descriptionError ? "input-error" : ""
                    }   d-flex align-items-center radius-4 grey-border flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                  >
                    <textarea
                      rows={4}
                      className="bg-transparent flex-grow-1 border-none  hide-scrollbar p-2"
                      placeholder="Description"
                      value={invoicePageData?.Description ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          Description: removeWhiteSpace(value),
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          descriptionError: false,
                        }));
                      }}
                    />
                  </div>
                  <hr />
                  <div className="f-12 f-500 f-darkgrey mb-2">
                    Details
                  </div>
                  <div className="w-100  d-flex align-items-center f-black">
                    <div className="w-50">Supplier</div>
                    <div className="w-50">
                      <Dropdown
                        show={showAddByAmountSupplierDropdown}
                        onToggle={(isOpen) =>
                          setShowAddByAmountSupplierDropdown(isOpen)
                        }
                        className="add-item-category-dropdown w-100"
                      >
                        <Dropdown.Toggle className="w-100">
                          <div className="highlight-input p-2 radius-4 d-flex align-items-center">
                            {invoicePageData
                              ?.PotentialSupplierSelected?.IsMSP ===
                            true ? (
                              <div
                                className="d-flex align-items-center justify-content-between  w-100 pointer"
                                style={{
                                  height: "15px",
                                }}
                              >
                                <div className="d-flex align-items-center f-black">
                                  <img
                                    src={
                                      invoicePageData
                                        ?.PotentialSupplierSelected
                                        ?.PotentialSupplierIcon
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/user-logo.svg";
                                    }}
                                    width={14}
                                    height={14}
                                    className="mr-2 rounded-full"
                                    alt=""
                                  />
                                  {
                                    invoicePageData
                                      ?.PotentialSupplierSelected
                                      ?.PotentialSupplierName
                                  }
                                </div>
                                <div className="f-12 f-darkgrey">
                                  You
                                </div>
                              </div>
                            ) : (
                              <input
                                className="border-none bg-transparent flex-grow-1"
                                type="text"
                                placeholder="Optional"
                                value={
                                  invoicePageData
                                    ?.PotentialSupplierSelected
                                    ?.PotentialSupplierName
                                }
                                onChange={(e) => {
                                  setChangesInInvoice(
                                    changesInInvoice + 1
                                  );
                                  setShowAddByAmountSupplierDropdown(
                                    true
                                  );
                                  let value = e.target.value;
                                  setPotentialSupplierSearch(
                                    e.target.value
                                  );
                                  let SelectedSupplier = {
                                    PotentialSupplierName: value,
                                    PotentialSupplierId: 0,
                                  };
                                  let updated_data = {
                                    ...invoicePageData,
                                  };
                                  updated_data.PotentialSupplierSelected =
                                    SelectedSupplier;
                                  setInvoicePageData(updated_data);
                                }}
                              />
                            )}
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="w-100 transparent-scroller mx-1"
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          {invoicePageData?.PotentialSupplierDetail_List?.filter(
                            (pt) => pt?.IsMSP === true
                          )?.map((item, index) => {
                            return (
                              <div
                                className="d-flex align-items-center justify-content-between px-2 py-1 pointer"
                                onClick={(e) => {
                                  setChangesInInvoice(
                                    changesInInvoice + 1
                                  );
                                  let updated_data = {
                                    ...invoicePageData,
                                  };
                                  updated_data.PotentialSupplierSelected =
                                    item;
                                  setInvoicePageData(updated_data);
                                  setPotentialSupplierSearch("");
                                  e.target
                                    .closest(
                                      ".add-item-category-dropdown"
                                    )
                                    .click();
                                }}
                              >
                                <div className="d-flex align-items-center f-grey">
                                  <img
                                    src={item?.PotentialSupplierIcon}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/user-logo.svg";
                                    }}
                                    width={18}
                                    height={18}
                                    className="mr-2 rounded-full"
                                    alt=""
                                  />
                                  {item?.PotentialSupplierName}
                                </div>
                                <div className="f-12 f-darkgrey">
                                  You
                                </div>
                              </div>
                            );
                          })}
                          <div className={`f-grey f-12 pl-2`}>
                            Last used
                          </div>
                          {invoicePageData?.PotentialSupplierDetail_List?.filter(
                            (pk) =>
                              pk?.PotentialSupplierName?.length > 0
                          )
                            ?.filter(
                              (pt) =>
                                pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                  potentialSupplierSearch?.toLowerCase()
                                ) && pt?.IsMSP === false
                            )
                            ?.map((item, itemIndex) => {
                              return (
                                <div
                                  className="d-flex align-items-center justify-content-between pointer pl-2 my-2"
                                  onClick={(e) => {
                                    setChangesInInvoice(
                                      changesInInvoice + 1
                                    );
                                    let updated_data = {
                                      ...invoicePageData,
                                    };
                                    updated_data.PotentialSupplierSelected =
                                      item;
                                    setInvoicePageData(updated_data);
                                    setPotentialSupplierSearch("");
                                    e.target
                                      .closest(
                                        ".add-item-category-dropdown"
                                      )
                                      .click();
                                  }}
                                >
                                  <div
                                    className={`${
                                      item?.PotentialSupplierId ===
                                      invoicePageData
                                        ?.PotentialSupplierSelected
                                        ?.PotentialSupplierId
                                        ? "f-blue  "
                                        : " f-black"
                                    }`}
                                  >
                                    {item?.PotentialSupplierName}
                                  </div>
                                  <div>
                                    {item?.PotentialSupplierId ===
                                      invoicePageData
                                        ?.PotentialSupplierSelected
                                        ?.PotentialSupplierId && (
                                      <img
                                        alt=""
                                        className=" mr-2"
                                        src="/images/msp/blue-tick.svg"
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          {potentialSupplierSearch !== "" && (
                            <div
                              className={"f-500  f-blue pointer pl-2"}
                              onClick={(e) => {
                                let SelectedSupplier = {
                                  PotentialSupplierName:
                                    potentialSupplierSearch,
                                  PotentialSupplierId: 0,
                                };
                                let updated_data = {
                                  ...invoicePageData,
                                };
                                updated_data.PotentialSupplierSelected =
                                  SelectedSupplier;
                                setInvoicePageData(updated_data);
                                setPotentialSupplierSearch("");
                                e.target
                                  .closest(
                                    ".add-item-category-dropdown"
                                  )
                                  .click();
                              }}
                            >
                              Add "{potentialSupplierSearch}" supplier
                            </div>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="w-100 py-2 d-flex align-items-center f-black">
                    <div className="w-50">Contact Email</div>
                    <div className="w-50">
                      <div
                        className={` ${
                          errors?.invoicePageEmailError
                            ? "input-error"
                            : ""
                        }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                      >
                        <input
                          type="text"
                          className="bg-transparent flex-grow-1 border-none"
                          placeholder="Optional"
                          value={invoicePageData?.ContactEmail ?? ""}
                          onChange={(e) => {
                            let value = e.target.value;
                            setChangesInInvoice(changesInInvoice + 1);
                            setInvoicePageData((prev) => ({
                              ...prev,
                              ContactEmail: value,
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              invoicePageEmailError: false,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100  d-flex align-items-center f-black">
                    <div className="w-50">Spend Date</div>
                    <div className="w-50 msp-date-picker">
                      <HighgroundDatePicker
                        disableFutureDates
                        isError={errors?.invoiceDateError}
                        selected={handleDateForInvoice(
                          invoicePageData?.InvoiceDate
                        )}
                        icon={"/images/attack-surface/date-icon.svg"}
                        onChange={(val) => {
                          if (val !== invoicePageData?.InvoiceDate) {
                            setChangesInInvoice(changesInInvoice + 1);
                          }
                          setInvoicePageData((prev) => ({
                            ...prev,
                            InvoiceDate: val,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            invoiceDateError: false,
                          }));
                          updateExchangeRateData({
                            _date: val,
                          });
                        }}
                        id={"dvdsgwvzdgdj"}
                      />
                    </div>
                  </div>
                  <div className="w-100  d-flex align-items-center f-black">
                    <div className="w-50 f-500">Currency</div>
                    <div className="w-50   f-500">
                      <div className="w-fit-content bg-grey radius-4 p-2 mt-3">
                        <CurrencyDropdown
                          disabled={invoicePageData?.objId_Invoice > 0}
                        
                          showFullCurrencyName
                          menuClassName={
                            "add-amount-currency-dropdown"
                          }
                          width={"200px"}
                          inputWidth={"144px"}
                          currencyList={currencyList}
                          onChange={(data) => {
                            setUserSelectedCurrency(data);
                            updateExchangeRateData({
                              _currency: data?.CurrencyName,
                              _date:
                                invoicePageData?.InvoiceDate ===
                                "0001-01-01T00:00:00"
                                  ? moment().format("DD/MM/YYYY")
                                  : handleDateForInvoice(
                                      invoicePageData?.InvoiceDate
                                    ),
                            });
                          }}
                          selected={userSelectedCurrency}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="f-12 f-500 f-darkgrey mt-2">
                    Total
                  </div>
                  {/* Amount */}
                  <div className="w-100   d-flex align-items-center f-black mb-1">
                    <div className="w-50">Amount</div>
                    <div className=" d-flex align-items-center justify-content-between w-50">
                      <div
                        className={`${
                          errors?.totalAmountError
                            ? "input-error"
                            : ""
                        } d-flex align-items-center radius-4 highlight-input  p-2  w-60`}
                      >
                        <div className="mr-1">
                          {" "}
                          {userSelectedCurrency?.CurrencySymbol}{" "}
                        </div>
                        <input
                          type="number"
                          className="bg-transparent  border-none w-100"
                          placeholder="Type"
                          value={invoicePageData?.TotalAmount ?? ""}
                          onChange={(e) => {
                            let value = e.target.value;
                            setChangesInInvoice(changesInInvoice + 1);
                            if (value?.toString().length > 10) {
                              return;
                            }
                            setInvoicePageData((prev) => ({
                              ...prev,
                              TotalAmount: validateNumber(value),
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              totalAmountError: false,
                            }));
                          }}
                          onFocus={() => {
                            if (!invoicePageData?.TotalAmount) {
                              setInvoicePageData((prev) => ({
                                ...prev,
                                TotalAmount: "",
                              }));
                            }
                          }}
                          onBlur={() => {
                            if (!invoicePageData?.TotalAmount) {
                              setInvoicePageData((prev) => ({
                                ...prev,
                                TotalAmount: 0,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className=" ">
                        {/* <CurrencyDropdown
                          showFullCurrencyName
                          menuClassName={
                            "add-amount-currency-dropdown"
                          }
                          width={"200px"}
                          inputWidth={"144px"}
                          currencyList={currencyList}
                          onChange={(data) => {
                            setUserSelectedCurrency(data);
                            updateExchangeRateData({
                              _currency: data?.CurrencyName,
                              _date:
                                invoicePageData?.InvoiceDate ===
                                "0001-01-01T00:00:00"
                                  ? moment().format("DD/MM/YYYY")
                                  : handleDateForInvoice(
                                      invoicePageData?.InvoiceDate
                                    ),
                            });
                          }}
                          selected={userSelectedCurrency}
                        /> */}
                      </div>
                    </div>
                  </div>
                  {/* Tax Rate */}
                  <div className="w-100   d-flex align-items-center f-black mb-1">
                    <div className="w-50">Tax Rate</div>
                    <div className="w-50 d-flex align-items-center justify-content-between">
                      <div>
                        <div
                          className="flex-1 radius-4 p-2"
                          style={{
                            backgroundColor: "#f6f8fb",
                          }}
                        >
                          <TaxRateDropdown
                            deleteTaxRate={deleteTaxRate}
                            updateTaxList={updateTaxList}
                            onClickMakeDefault={onClickUpdateTaxRate}
                            isUpdating={isTaxRateUpdating}
                            onChange={(data) => {
                              setInvoicePageData((prev) => ({
                                ...prev,
                                TaxRates_Selected: data,
                              }));
                            }}
                            taxList={taxList}
                            selected={
                              invoicePageData?.TaxRates_Selected
                            }
                            onClickEditTaxRate={(data) => {
                              setTaxRateData(data);
                              setShowAddEditTaxRateModal(true);
                            }}
                            onClickAddTaxRate={() => {
                              setTaxRateData({
                                ObjectId: 0,
                                TaxCode: "",
                                TaxName: "",
                                TaxRate: "",
                              });
                              setShowAddEditTaxRateModal(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                        {invoicePageData?.TaxRates_Selected
                          ?.TaxRate ?? 0}
                        % ={" "}
                        {convertToCurrencyFormat(
                          Number(
                            (((invoicePageData.TotalAmount ?? 0) -
                              invoicePageData?.Discount ?? 0) *
                              (invoicePageData?.TaxRates_Selected
                                ?.TaxRate ?? 0)) /
                              100 ?? 0
                          ),
                          userSelectedCurrency?.CurrencySymbol
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100   d-flex align-items-center f-black">
                    <div className="w-50 f-600">Total</div>
                    <div className="w-50 pl-2">
                      <div
                        className="d-flex align-items-center radius-4 py-2 pr-2 flex-grow-1 mr-1 f-600"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        {invoicePageData?.TotalAmount === 0 ||
                        userSelectedCurrency?.CurrencySymbol ===
                          defaultCurrencyData?.CurrencySymbol ? (
                          <>
                            {invoicePageData?.TotalAmount === 0 ? (
                              <div className="f-600 f-black">
                                {userSelectedCurrency?.CurrencySymbol}{" "}
                                0.00
                              </div>
                            ) : (
                              <div className="f-600 f-black">
                                {convertToCurrencyFormat(
                                  getTotalAmountForInvoice(),
                                  defaultCurrencyData?.CurrencySymbol
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <div 
                            className="d-flex align-items-center w-100"
                            style={{ gap: '10px' }}
                          >
                            <div>
                              {convertToCurrencyFormat(
                                getTotalAmountForInvoice(),
                                userSelectedCurrency?.CurrencySymbol
                              )}
                            </div>
                            <img
                              width={15}
                              height={15}
                              src="/images/msp/exchange-rate-icon.svg"
                              alt=""
                            />
                            <div>
                              {convertToCurrencyFormat(
                                exchangeRateData?.Rate *
                                  getTotalAmountForInvoice(),
                                defaultCurrencyData?.CurrencySymbol
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "0rem",
                  }}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="addBySubscription">
                <div
                  style={{
                    pointerEvents: invoiceEditMode ? "all" : "none",
                    padding: '8px 0',
                  }}
                >
                  <div
                    className={`  ${
                      errors?.invoiceRefError ? "input-error" : ""
                    }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                  >
                    <div className="f-darkgrey">Title:</div>
                    <input
                      type="text"
                      className="bg-transparent flex-grow-1 border-none pl-2"
                      placeholder=" Type"
                      value={invoicePageData?.InvoiceRef ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          InvoiceRef: removeWhiteSpace(value),
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          invoiceRefError: false,
                        }));
                      }}
                    />
                  </div>
                  {/* Description */}
                  <div
                    className={`  ${
                      errors?.descriptionError ? "input-error" : ""
                    }   d-flex align-items-center radius-4 grey-border flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                  >
                    <textarea
                      rows={4}
                      className="bg-transparent flex-grow-1 border-none  hide-scrollbar p-2"
                      placeholder="Description"
                      value={invoicePageData?.Description ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          Description: removeWhiteSpace(value),
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          descriptionError: false,
                        }));
                      }}
                    />
                  </div>
                  <div className="f-12 f-darkgrey f-500 mt-2 mb-1">
                    Category
                  </div>
                  <div
                    className={`mb-2 w-100 border1 radius-4 ${
                      errors?.selectedCategoryError
                        ? "input-error"
                        : ""
                    }`}
                  >
                    <CategorySelectorDropdown 
                      placeholder={"Categories:"}
                      selected={invoicePageData?.Category_Selected}
                      data={invoicePageData?.SpendCategoryVM_List}
                      fieldId={"objId_Category"}
                      fieldValue={"CategoryName"}
                      fieldColor={"CategoryColourHexCode"}
                      onSelect={(item) => {
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          Category_Selected: item,
                          SubCategory_Selected: null,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          selectedCategoryError: false,
                        }));
                        getSubCategoryList(item?.objId_Category);
                      }}
                    />
                  </div>
                  <div
                    className={` mb-2 w-100 border1 radius-4 ${
                      errors?.selectedSubCategoryError
                        ? "input-error"
                        : ""
                    }`}
                  >
                    <CategorySelectorDropdown 
                      placeholder={"Subcategories:"}
                      selected={invoicePageData?.SubCategory_Selected}
                      data={subCategoryList}
                      fieldId={"objId_Category"}
                      fieldValue={"CategoryName"}
                      fieldColor={"CategoryColourHexCode"}
                      menuTitle="Choose subcategory"
                      onSelect={(item) => {
                        setChangesInInvoice(changesInInvoice + 1);
                        setInvoicePageData((prev) => ({
                          ...prev,
                          SubCategory_Selected: item,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          selectedSubCategoryError: false,
                        }));
                      }}
                    />
                  </div>
                  <div className="f-12 f-darkgrey f-500 mt-2 mb-1">
                    Supplier
                  </div>
                  <div>
                    <Dropdown
                      show={showAddByAmountSupplierDropdown}
                      onToggle={(isOpen) =>
                        setShowAddByAmountSupplierDropdown(isOpen)
                      }
                      className="add-item-category-dropdown w-100"
                    >
                      <Dropdown.Toggle className="w-100">
                        <div className={`highlight-input bordered p-2 radius-4 d-flex align-items-center`}>
                          {invoicePageData
                            ?.PotentialSupplierSelected?.IsMSP ===
                          true ? (
                            <div
                              className="d-flex align-items-center justify-content-between  w-100 pointer"
                              style={{
                                height: "15px",
                              }}
                            >
                              <div className="d-flex align-items-center f-black">
                                <img
                                  src={
                                    invoicePageData
                                      ?.PotentialSupplierSelected
                                      ?.PotentialSupplierIcon
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                      "/images/user-logo.svg";
                                  }}
                                  width={14}
                                  height={14}
                                  className="mr-2 rounded-full"
                                  alt=""
                                />
                                {
                                  invoicePageData
                                    ?.PotentialSupplierSelected
                                    ?.PotentialSupplierName
                                }
                              </div>
                              <div className="f-12 f-darkgrey">
                                You
                              </div>
                            </div>
                          ) : (
                            <div className="position-relative w-100 d-flex">
                              <input
                                className="bg-transparent w-100"
                                type="text"
                                placeholder="Type or select supplier (optional)"
                                value={
                                  invoicePageData
                                    ?.PotentialSupplierSelected
                                    ?.PotentialSupplierName
                                }
                                onChange={(e) => {
                                  setChangesInInvoice(
                                    changesInInvoice + 1
                                  );
                                  
                                  setShowAddByAmountSupplierDropdown(
                                    true
                                  );
                                  let value = e.target.value;
                                  setPotentialSupplierSearch(
                                    e.target.value
                                  );
                                  let SelectedSupplier = {
                                    PotentialSupplierName: value,
                                    PotentialSupplierId: 0,
                                  };
                                  let updated_data = {
                                    ...invoicePageData,
                                  };
                                  updated_data.PotentialSupplierSelected =
                                    SelectedSupplier;
                                  setInvoicePageData(updated_data);
                                  setErrors((prev) => ({
                                    ...prev,
                                    potentialSupplierError: false,
                                  }));
                                }}
                              />
                              <img
                                src="/images/big-chevron-down.svg"
                                alt=""
                                className="ml-2 position-absolute"
                                style={{
                                  right: 2,
                                  top: 4,
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="w-100 transparent-scroller"
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                        }}
                      >
                        {invoicePageData?.PotentialSupplierDetail_List?.filter(
                          (pk) =>
                            pk?.PotentialSupplierName?.length > 0
                        )
                          ?.filter(
                            (pt) =>
                              pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                potentialSupplierSearch?.toLowerCase()
                              ) && pt?.IsMSP === false
                          ).length === 0 && (
                            <div className="f-500 pl-2" style={{ fontWeight: 400 }}>
                              No supplier found
                            </div>
                          )}
                        {invoicePageData?.PotentialSupplierDetail_List?.filter(
                          (pk) =>
                            pk?.PotentialSupplierName?.length > 0
                        )
                          ?.filter(
                            (pt) =>
                              pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                potentialSupplierSearch?.toLowerCase()
                              ) && pt?.IsMSP === false
                          )
                          ?.map((item, itemIndex) => {
                            return (
                              <div
                                className="d-flex align-items-center justify-content-between pointer pl-2 my-2"
                                onClick={(e) => {
                                  setChangesInInvoice(
                                    changesInInvoice + 1
                                  );
                                  setErrors((prev) => ({
                                    ...prev,
                                    potentialSupplierError: false,
                                  }));
                                  let updated_data = {
                                    ...invoicePageData,
                                  };
                                  updated_data.PotentialSupplierSelected =
                                    item;
                                  setInvoicePageData(updated_data);
                                  setPotentialSupplierSearch("");
                                  e.target
                                    .closest(
                                      ".add-item-category-dropdown"
                                    )
                                    .click();
                                }}
                              >
                                <div
                                  className={`${
                                    item?.PotentialSupplierId ===
                                    invoicePageData
                                      ?.PotentialSupplierSelected
                                      ?.PotentialSupplierId
                                      ? "f-blue  "
                                      : " f-black"
                                  }`}
                                >
                                  {item?.PotentialSupplierName}
                                </div>
                                <div>
                                  {item?.PotentialSupplierId ===
                                    invoicePageData
                                      ?.PotentialSupplierSelected
                                      ?.PotentialSupplierId && (
                                    <img
                                      alt=""
                                      className=" mr-2"
                                      src="/images/msp/blue-tick.svg"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        {potentialSupplierSearch !== "" && (
                          <div
                            className={"f-500  f-blue pointer pl-2"}
                            onClick={(e) => {
                              let SelectedSupplier = {
                                PotentialSupplierName:
                                  potentialSupplierSearch,
                                PotentialSupplierId: 0,
                              };
                              let updated_data = {
                                ...invoicePageData,
                              };
                              updated_data.PotentialSupplierSelected =
                                SelectedSupplier;
                              setInvoicePageData(updated_data);
                              setPotentialSupplierSearch("");
                              e.target
                                .closest(
                                  ".add-item-category-dropdown"
                                )
                                .click();
                            }}
                          >
                            Add "{potentialSupplierSearch}" supplier
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {invoicePageData?.objId_Invoice > 0 && (
                    <>
                      <div className="f-12 f-darkgrey f-500 mt-2 mb-1">
                        Status
                      </div>
                      <Dropdown className="transparennt-wrapper-dropdown ">
                        <Dropdown.Toggle className="p-0 w-100">
                          <div
                            className="w-100 f-white radius-4 d-flex align-items-center justify-content-center"
                            style={{
                              background:
                                invoicePageData?.SubscriptionStatus
                                  ?.OptionColor,
                              padding: '5px 8px'
                            }}
                          >
                            <div>
                              {
                                invoicePageData?.SubscriptionStatus
                                  ?.OptionText
                              }
                            </div>
                            <img
                              src="/images/msp/white-chevron-down.svg"
                              alt=""
                              className="ml-2"
                            />
                          </div>
                        </Dropdown.Toggle>
                        {invoicePageData?.SubscriptionMeta
                          ?.SubscriptionStatusOptions?.length > 0 && (
                          <Dropdown.Menu
                            className="p-2 menu-inset-right-corner"
                            style={{
                              width: "100%",
                            }}
                          >
                            {invoicePageData?.SubscriptionMeta?.SubscriptionStatusOptions?.map(
                              (elem) => {
                                return (
                                  <div
                                    className="d-flex align-items-center mb-2 pointer radius-4 bg-hover-little-grey p-1"
                                    onClick={(e) => {
                                      e.target
                                        .closest(
                                          ".transparennt-wrapper-dropdown"
                                        )
                                        .click();
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        SubscriptionStatus: elem,
                                      }));
                                    }}
                                  >
                                    <img
                                      src="/images/msp/dropdown-grey-tick.svg"
                                      alt=""
                                      className={"mr-2"}
                                      style={{
                                        visibility:
                                          elem?.Id ===
                                          invoicePageData
                                            ?.SubscriptionStatus?.Id
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    />
                                    <div className="">
                                      <div className="f-grey f-500">
                                        {elem?.OptionText}{" "}
                                      </div>
                                      <div className="f-12 f-darkgrey">
                                        {elem?.OptionDescription}{" "}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </>
                  )}
                  <div className="d-flex flex-column" style={{ gap: '2px', marginTop: '16px' }}>
                    <div
                      className="f-12 f-500 f-darkgrey"
                      onClick={() => {
                        // setShowGenerateInvoiceModal(true);
                      }}
                    >
                      Amount
                    </div>
                    <div>
                      <div className="w-100  d-flex align-items-center f-black">
                        <div className="w-30">Amount</div>
                        <div className="d-flex align-items-center w-70">
                          <div className="d-flex align-items-center justify-content-between" style={{ gap: '16px' }}>
                            <div className="d-flex align-items-center px-2" style={{ gap: '16px' }}>
                              <CurrencyDropdown
                                disabled={invoicePageData?.objId_Invoice > 0 || disableCalculationFields}
                                menuClassName={
                                  "add-amount-currency-dropdown menu disable-transform"
                                }
                                width={"200px"}
                                inputWidth={"144px"}
                                currencyList={currencyList}
                                onChange={(data) => {
                                  setUserSelectedCurrency(data);
                                  let today = new Date();
                                  updateExchangeRateData({
                                    _currency: data?.CurrencyName,
                                    _date:
                                      // formatDateDDMMYYY(today)
                                      invoicePageData?.InvoiceDate ===
                                      "0001-01-01T00:00:00"
                                        ? moment().format("DD/MM/YYYY")
                                        : handleDateForInvoice(
                                            invoicePageData?.InvoiceDate
                                          ),
                                  });
                                }}
                                selected={userSelectedCurrency}
                              />
                              <div
                                className={`${
                                  errors?.totalAmountError
                                    ? "input-error"
                                    : ""
                                } d-flex align-items-center radius-4 highlight-input  p-1  w-60`}
                              >
                                <div className="mr-1">
                                  {" "}
                                  {userSelectedCurrency?.CurrencySymbol}{" "}
                                </div>
                                <input
                                  type="number"
                                  className="bg-transparent  border-none w-100"
                                  placeholder="Type"
                                  value={invoicePageData?.TotalAmount ?? ""}
                                  disabled={disableCalculationFields}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setChangesInInvoice(changesInInvoice + 1);
                                    if (value?.toString().length > 10) {
                                      return;
                                    }
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      TotalAmount: validateNumber(value),
                                    }));
                                    setErrors((prev) => ({
                                      ...prev,
                                      totalAmountError: false,
                                    }));
                                  }}
                                  onFocus={() => {
                                    if (!invoicePageData?.TotalAmount) {
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        TotalAmount: "",
                                      }));
                                    }
                                  }}
                                  onBlur={() => {
                                    if (!invoicePageData?.TotalAmount) {
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        TotalAmount: 0,
                                      }));
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <CustomHGDropdown
                              redBorder={errors?.recurringPeriodError}
                              fieldId={"Id"}
                              contentwidth
                              fieldValue={"OptionText"}
                              selected={invoicePageData?.BillingTenure}
                              placeholder="Select"
                              dropDownMenuClass="menu disable-transform align-right"
                              disabled={disableCalculationFields}
                              onClick={(id, val, item) => {
                                setInvoicePageData((prev) => ({
                                  ...prev,
                                  BillingTenure: item,
                                }));
                                setChangesInInvoice(changesInInvoice + 1);
                                setErrors((prev) => ({
                                  ...prev,
                                  recurringPeriodError: false,
                                }));
                              }}
                              data={
                                invoicePageData?.SubscriptionMeta
                                  ?.RecurringPeriodOptions
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* Tax Rate */}
                      <div className="w-100   d-flex align-items-center f-black mb-1">
                        <div className="w-30">Tax Rate</div>
                        <div className="w-70 d-flex align-items-center justify-content-between">
                          <div>
                            <div
                              className="flex-1 radius-4 p-2"
                              style={{
                                // backgroundColor: "#f6f8fb",
                                ...(errors?.taxRateError && {
                                  border: "1px solid #f36060",
                                }),
                                marginTop: '2px'
                              }}
                            >
                              <TaxRateDropdown
                                deleteTaxRate={deleteTaxRate}
                                updateTaxList={updateTaxList}
                                onClickMakeDefault={onClickUpdateTaxRate}
                                isUpdating={isTaxRateUpdating}
                                onChange={(data) => {
                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    TaxRates_Selected: data,
                                  }));
                                  setChangesInInvoice(changesInInvoice + 1);
                                  setErrors((prev) => ({
                                    ...prev,
                                    taxRateError: false,
                                  }));
                                }}
                                taxList={taxList}
                                selected={
                                  invoicePageData?.TaxRates_Selected
                                }
                                onClickEditTaxRate={(data) => {
                                  setTaxRateData(data);
                                  setShowAddEditTaxRateModal(true);
                                }}
                                onClickAddTaxRate={() => {
                                  setTaxRateData({
                                    ObjectId: 0,
                                    TaxCode: "",
                                    TaxName: "",
                                    TaxRate: "",
                                  });
                                  setShowAddEditTaxRateModal(true);
                                }}
                                disabled={disableCalculationFields}
                              />
                            </div>
                          </div>
                          {invoicePageData?.TaxRates_Selected && invoicePageData?.TaxRates_Selected?.TaxRate ? (
                            <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                              {invoicePageData?.TaxRates_Selected
                                ?.TaxRate ?? 0}
                              % ={" "}
                              {convertToCurrencyFormat(
                                Number(
                                  (((invoicePageData.TotalAmount ?? 0) -
                                    invoicePageData?.Discount ?? 0) *
                                    (invoicePageData?.TaxRates_Selected
                                      ?.TaxRate ?? 0)) /
                                    100 ?? 0
                                ),
                                userSelectedCurrency?.CurrencySymbol
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-100   d-flex align-items-center f-black">
                        <div className="w-30 f-600">Total</div>
                        <div className="w-70 pl-2">
                          <div
                            className="d-flex align-items-center radius-4 py-2 pr-2 flex-grow-1 mr-1 f-600"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            {invoicePageData?.TotalAmount === 0 ||
                            userSelectedCurrency?.CurrencySymbol ===
                              defaultCurrencyData?.CurrencySymbol ? (
                              <>
                                {invoicePageData?.TotalAmount === 0 ? (
                                  <div className="f-600 f-black">
                                    <span>
                                      {userSelectedCurrency?.CurrencySymbol}{" "}
                                      0.00
                                    </span>
                                    <span className="ml-1">
                                      {invoicePageData?.BillingTenure?.OptionText}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="f-600 f-black">
                                    {convertToCurrencyFormat(
                                      getTotalAmountForInvoice(),
                                      defaultCurrencyData?.CurrencySymbol
                                    )}
                                    <span className="ml-1">
                                      {invoicePageData?.BillingTenure?.OptionText}
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div 
                                className="d-flex align-items-center w-100"
                                style={{ gap: '10px' }}
                              >
                                <div>
                                  {convertToCurrencyFormat(
                                    getTotalAmountForInvoice(),
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                                  <span className="ml-1">
                                    {invoicePageData?.BillingTenure?.OptionText}
                                  </span>
                                </div>
                                {defaultCurrencyData && (
                                  <>
                                    <img
                                      width={15}
                                      height={15}
                                      src="/images/msp/exchange-rate-icon.svg"
                                      alt=""
                                    />
                                    <div>
                                      {convertToCurrencyFormat(
                                        exchangeRateData?.Rate *
                                          getTotalAmountForInvoice(),
                                        defaultCurrencyData?.CurrencySymbol
                                      )}
                                      <span className="ml-1">
                                        {invoicePageData?.BillingTenure?.OptionText}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="f-12 f-darkgrey f-500 mt-3 mb-2">
                    Contract Details
                  </div>
                  <div className="d-flex flex-column" style={{ gap: '2px' }}>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Start Date</div>
                      <div className="w-70 msp-date-picker">
                        <HighgroundDatePicker
                          // disableFutureDates
                          isError={errors?.subscriptionStartDateError}
                          selected={handleDateForInvoice(
                            invoicePageData?.SubscriptionStartDate
                          )}
                          icon={"/images/attack-surface/date-icon.svg"}
                          onChange={(val) => {
                            if (val !== invoicePageData?.SubscriptionStartDate) {
                              setChangesInInvoice(changesInInvoice + 1);
                            }
                            setInvoicePageData((prev) => ({
                              ...prev,
                              SubscriptionStartDate: val,
                              InvoiceDate: val,
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              subscriptionStartDateError: false,
                            }));
                            updateExchangeRateData({
                              _date: val,
                            });
                            if (invoicePageData?.AutoRenewalTerms) {
                              getSubscriptionEndDate({
                                StartDate:val,
                                AutoRenewalObject:invoicePageData?.AutoRenewalTerms
                              })
                            }
                          }}
                          id={"dvdsgffwvzdgdj"}
                          menuClassName="menu disable-transform small"
                          disableDatePicker={disableCalculationFields}
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Term</div>
                      <SubscriptionTermsPeriod
                        custom 
                        selected={invoicePageData?.RenewalTermsDuration} 
                        errors={errors?.termError}
                        maxMonths={60}
                        onChange={(item) => {
                          setChangesInInvoice(changesInInvoice + 1);
                          setInvoicePageData((prev) => ({
                            ...prev,
                            RenewalTermsDuration: item,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            termError: false,
                            recurringPeriodError: false,
                          }));
                        }}
                        disabled={disableCalculationFields}
                      />
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Cancellation Terms</div>
                      <div className="w-70 d-flex align-items-center" style={{ gap: '16px' }}>
                        <SubscriptionTermsPeriod
                          custom 
                          selected={invoicePageData?.CancellationTermsDuration} 
                          list={invoicePageData?.SubscriptionMeta?.CancellationTermsOptions} 
                          errors={errors?.cancellationTermsError}
                          onChange={(item) => {
                            setInvoicePageData((prev) => ({
                              ...prev,
                              CancellationTermsDuration: item,
                            }));
                            setChangesInInvoice(changesInInvoice + 1);
                            setErrors((prev) => ({
                              ...prev,
                              cancellationTermsError: false,
                            }));
                          }}
                          maxValue={invoicePageData?.RenewalTermsDuration}
                          disabled={disableCalculationFields}
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Payment Method</div>
                      <div className="w-70 d-flex align-items-center" style={{ gap: '16px' }}>
                        <CustomHGDropdown
                          redBorder={errors?.paymentMethodError}
                          fieldId={"Id"}
                          contentwidth
                          fieldValue={"OptionText"}
                          selected={invoicePageData?.PaymentMethod}
                          placeholder="Select"
                          onClick={(id, val, item) => {
                            setInvoicePageData((prev) => ({
                              ...prev,
                              PaymentMethod: item,
                            }));
                            setChangesInInvoice(changesInInvoice + 1);
                            setErrors((prev) => ({
                              ...prev,
                              paymentMethodError: false,
                            }));
                          }}
                          data={
                            invoicePageData?.SubscriptionMeta
                              ?.PaymentMethod
                          }
                          disabled={disableCalculationFields}
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">1st Payment Date</div>
                      <div className="w-70 msp-date-picker">
                        <HighgroundDatePicker
                          isError={errors?.invoiceDateError}
                          selected={
                            handleDateForInvoice(
                              invoicePageData?.InvoiceDate
                            )
                          }
                          icon={"/images/attack-surface/date-icon.svg"}
                          onChange={(val) => {
                            if (!invoicePageData?.SubscriptionStartDate) return;
                            let startDate = invoicePageData?.SubscriptionStartDate;
                            if (dateUtils.isDatePickerFormat(startDate)) {
                              startDate = dateUtils.converToDateFormat(startDate);
                            }
                            if (dateUtils.isDatePickerFormat(val)) {
                              val = dateUtils.converToDateFormat(val);
                            }
                            if (new Date(val) < new Date(startDate)) {
                              return;
                            }
                            if (val !== invoicePageData?.InvoiceDate) {
                              setChangesInInvoice(changesInInvoice + 1);
                            }
                            setInvoicePageData((prev) => ({
                              ...prev,
                              InvoiceDate: val,
                            }));
                            setErrors((prev) => ({
                              ...prev,
                              invoiceDateError: false,
                            }));
                          }}
                          id={"dvdsgffwvzdgdja112aa"}
                          menuClassName="menu disable-transform small"
                          minDate={invoicePageData?.SubscriptionStartDate}
                          rerenderDependencies={[invoicePageData?.SubscriptionStartDate]}
                          disableDatePicker={disableCalculationFields}
                        />
                      </div>
                    </div>
                  </div>                           
                  <div className="f-12 f-darkgrey f-500 mt-2 mb-2">
                    Info
                  </div>
                  <div className="d-flex flex-column mt-1" style={{ gap: '12px' }}>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Renewal date</div>
                      <div className="w-70 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        { subPaymentDetails?.Renewal_Date }
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Last Payment</div>
                      <div className="w-70 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        { invoicePageData?.LastPaymentDate }
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Next Payment</div>
                      <div className="w-70 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        { subPaymentDetails?.Next_Payment }
                      </div>
                    </div>
                    <div className="w-100 d-flex align-items-center f-black">
                      <div className="w-30">Total Payments</div>
                      <div className="w-70 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        { invoicePageData?.TotalSubscrionPayments }
                      </div>
                    </div>
                  </div>
                  {invoicePageData?.objId_Invoice > 0 && (
                    <>
                      <div className="border-bottom w-100 my-3" />
                      <div className="f-12 f-500 f-darkgrey my-2">
                        {" "}
                        Payments
                      </div>
                      {invoicePageData?.InvoiceItem_List?.length ===
                        0 && <NoAutomaticGeneratedInvoiceUI />}
                      {invoicePageData?.InvoiceItem_List?.map(
                        (_invoice) => {
                          return (
                            <SubPaymentCardItem
                              key={_invoice?.Id}
                              Pages={Pages}
                              _invoice={_invoice}
                              categoryPageData={categoryPageData}
                              getInvoiceDetails={getInvoiceDetails}
                              goTo={goTo}
                              setAddSpendSelectedTab={setAddSpendSelectedTab}
                              setInvoiceEditMode={setInvoiceEditMode}
                              setLoading={setLoading}
                              subCategoryPageData={subCategoryPageData}
                            />
                          );
                        }
                      )}
                      <div className="p-3" />
                    </>
                  )}
                </div>
                <div
                  style={{
                    padding: "0rem",
                  }}
                />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
        {defaultCurrencyData && exchangeRateData?.FromCurrency !==
          defaultCurrencyData?.CurrencyName ? (
          <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
            <img
              alt=""
              width={13}
              height={13}
              src="/images/msp/exchange-rate-icon.svg"
              className="mr-2"
            />{" "}
            The exchange rate on{" "}
            {moment(exchangeRateData?.Date ?? "")?.format(
              "DD MMM YYYY"
            )}{" "}
            was 1 {exchangeRateData?.FromCurrency} ={" "}
            {exchangeRateData?.Rate}{" "}
            {defaultCurrencyData?.CurrencyName}
          </div>
        ) : null}
      </Tab.Container>
    </>
  );
}

export default TechspendAddspendContent;

const LinkedSubscriptionSection = ({
  subscriptionItem,
  setLoading,
  setAddSpendSelectedTab,
  goTo,
  getSubPaymentLastInvoice,
  getInvoiceDetails,
  clientId,
  Pages
}) => {
  const onItemClick = () => {
    setLoading(true);
    setAddSpendSelectedTab("addBySubscription");
    goTo(
      <div className="d-flex align-items-center justify-content-center">
        <div className="position-relative w-fit-content h-fit-content">
          <SubscriptionIcon subscriptionItem={subscriptionItem} />
        </div>
        {TrimString(
          subscriptionItem?.SubscriptionTitle,
          30
        )}
      </div>,
      Pages.ADD_SPEND,
      {
        invoiceId: subscriptionItem?.ObjId_Invoice,
        categoryId: subscriptionItem?.ObjId_Category,
        subCategoryId: subscriptionItem?.ObjId_SubCategory,
        invoiceType: "addBySubscription",
        currentTab: "addBySubscription",
      }
    );
    getSubPaymentLastInvoice({
      clientId,
      invoiceId: subscriptionItem?.ObjId_Invoice
    })
    getInvoiceDetails(
      subscriptionItem?.ObjId_Invoice,
      subscriptionItem?.ObjId_SubCategory > 0
        ? subscriptionItem?.ObjId_SubCategory
        : subscriptionItem?.ObjId_Category,
      { isSubscriptionView: true }
    );
  }
  return (
    <div 
      className="mt-3 d-flex flex-column" 
      style={{ gap: '8px', position: 'relative', zIndex: 2 }}
    >
      <div className="d-flex align-items-center px-1" style={{ gap: '6px' }}>
        <LinkIcon />
        <span className="f-12 line-height-18 f-darkgrey">
          Linked subscription
        </span>
      </div>
      <SubscriptionCard 
        subscriptionItem={subscriptionItem}
        showSubcategory={false}
        onClick={onItemClick}
        onEditClick={onItemClick}
      />
    </div>
  );
}
