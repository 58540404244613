import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import SlidingModal from "../../../CustomModal/SlidingModal";
import "./technologySpendModal.scss";
import AddSubcategoryModal from "../AddSubcategoryModal";
import {
  AddEditTaxRateModal,
  deleteTaxRate,
} from "../addItemInSpendModal/AddItemInSpendModal";
import Loader from "../../../Common/loader";
import { getAPIData, getPageDataApi, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../../../Utils/StringTrimmer";
import { WhiteLoader } from "../../../Theme/APILoaders";
import {
  all_integration_data,
  removeWhiteSpace,
} from "../../../../Utils/CommonFunctions";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  CustomDeleteModal,
  DeleteModal,
} from "../../../DeleteModal/DeleteModal";
import AddSupplierModal from "../../../AttackSurface/Suppliers/AddSupplierModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { viewOnlyAccess } from "../../../App/App";
import GenerateInvoiceModal, {
  DeleteTechSpendSubscription,
} from "./GenerateInvoiceModal";
import subscriptionUtils from "../../../../Utils/subscription.utills";
import dateUtils from "../../../../Utils/date.utils";
import useUpdateTaxRate from "../addItemInSpendModal/update-tax-rate.data";
import { BillingPeriodInMonths, CustomSubDateSelection } from "../../../../constants/subscription.constants";
import integrationUtils from "../../../../Utils/integration.utils";
import useGetSubPaymentDetails from "./sub-payment-details/get-sub-payment-details.data";
import TechspendHomeContent from "./contents/techspend-home.content";
import TechspendSubcategoryContent from "./contents/techspend-subcategory.content";
import CreateCategorySpendModal from "../CreateCategorySpendModal";
import TechspendAddspendContent from "./contents/techspend-addspend.content";
import { Checkbox } from "semantic-ui-react";
import SubPaymentInvoiceIcon from "../../../icons/sub-payment-invoice.icon";

const ModalTabs = {
  AllSpend: "allSpend",
  Subscriptions: "subscriptions",
  Subcategories: "subcategories",
}

const TechnologySpendModal = ({
  show,
  hideModal,
  parentID,
  categoryData,
  refreshData = () => {},
  defaultCurrencyData,
  isDraggableParent,
  isSaas,
  listViewInvoiceData,
  subscriptionViewInvoiceData,
  defaultTitle,
  defaultCategoryName,
  setDefaultSpendSelectedTab = () => {},
  setSubscriptionViewInvoiceData = () => {},
  defaultSpendSelectedTab = "addBySubscription"
}) => {
  const dispatch = useDispatch();
  const categoryGraphRef = useRef(null);
  const history = useHistory();
  const clientId = window.location.pathname.split("/")[4];
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState(ModalTabs.AllSpend);
  const [selectedTab, setSelectedTab] = useState(ModalTabs.AllSpend);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] =
    useState(false);

  const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);
  const [sortingSubcategoryList, setSortingSubcategoryList] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState({});
  const [deleteMessage, setDeleteMessage] = useState("");
  const [
    showAddByInvoiceSupplierDropdown,
    setShowAddByInvoiceSupplierDropdown,
  ] = useState(false);
  const [showAddByAmountSupplierDropdown, setShowAddByAmountSupplierDropdown] =
    useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [potentialSupplierSearch, setPotentialSupplierSearch] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [categoryDeleteData, setCategoryDeleteData] = useState({});
  const [linkedCatDeleteModal, setLinkedCatDeleteModal] = useState(false);
  const [changesInInvoice, setChangesInInvoice] = useState(0);
  const [switchInvoiceTabModalData, setSwitchInvoiceTabModalData] = useState({
    showModal: false,
    deleteType: "switchInvoicesTabInMspTechSpend",
    deleteParams: "tab name",
  });
  const { isLoading: isTaxRateUpdating, updateTaxRate } = useUpdateTaxRate();
  // Pages Data
  const [categoryPageData, setCategoryPageData] = useState({});
  const [subCategoryPageData, setSubCategoryPageData] = useState({});
  const [invoicePageData, setInvoicePageData] = useState({});
  const [selectedInvoiceItemData, setSelectedInvoiceItemData] = useState({});
  const [InvoiceItemIndex, setInvoiceItemIndex] = useState(-1);
  const [invoiceEditMode, setInvoiceEditMode] = useState(true);
  const [showCreateSubcategoryModal, setShowCreateSubcategoryModal] =
    useState(null);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showDeleteSubscriptionModal, setShowDeleteSubscriptionModal] =
    useState(false);
  const [showAddItemSpendModal, setShowAddItemSpendModal] = useState(false);
  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});
  const [addSpendSelectedTab, setAddSpendSelectedTab] =
    useState(defaultSpendSelectedTab);
  const [
    generatedInoiceListBySubscription,
    setGeneratedInoiceListBySubscription,
  ] = useState([]);
  const [subscriptionJson, setSubscriptionJson] = useState({});
  const [subscriptionActiveFilters, setSubscriptionActiveFilters] = useState(
    []
  );
  const [categorySubscriptionViewData, setCategorySubscriptionViewData] = useState({});
  const [subcategorySubscriptionViewData, setSubcategorySubscriptionViewData] = useState({});
  const [unlinkRelinkModalData, setUnlinkRelinkModalData] = useState({
    show: false,
    linkButtonText: "Unlink",
    headerText: "Unlink INV-0001 from HaloPSA",
    bodyText: "Are you sure you would like to unlink this",
    deleteParams: {},
  });
  const {
    data: subPaymentLastInvoice,
    fetchData: getSubPaymentLastInvoice,
    setData: setLastInvoiceDate
  } = useGetSubPaymentDetails();
  const disableCalculationFields = !subscriptionViewInvoiceData?.newInvoice;
  const [subcategoriesSearch, setSubcategoriesSearch] = useState("");
  const [subscriptionSearch, setSubscriptionSearch] = useState("");
  const [spendSearch, setSpendSearch] = useState("");
  const subcategorySearchRef = useRef(null);
  const subscriptionSearchRef = useRef(null);
  const spendSearchRef = useRef(null);

  //Unlink INV-0001 from HaloPSA
  //Are you sure you would like to unlink this invoice #INV-0001 from HaloPSA?

  //Relink INV-0001 to HaloPSA
  // All edits you have made to this invoice in HighGround will be lost.Are you sure you would like to relink #INV-0001 to HaloPSA?
  const [pages, setPages] = useState([
    {
      type: "home",
      name: "Technology Spend Modal",
      data: {},
    },
  ]);

  const [myIntegrations, setMyIntegrations] = useState(0);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [donutColorIds, setDonutColorIds] = useState([]);

  const subPaymentDetails = useMemo(() => {
    if (!invoicePageData?.BillingTenure || !invoicePageData?.SubscriptionStartDate || !invoicePageData?.RenewalTermsDuration) {
      return null;
    }
    let startDate = invoicePageData?.SubscriptionStartDate;
    if (dateUtils.isDatePickerFormat(startDate)) {
      startDate = dateUtils.converToDateFormat(startDate);
    }
    startDate = new Date(startDate);
    let invoiceDate = invoicePageData?.InvoiceDate;
    if (dateUtils.isDatePickerFormat(invoiceDate)) {
      invoiceDate = dateUtils.converToDateFormat(invoiceDate);
    }
    invoiceDate = new Date(invoiceDate);
    const termsSelection = CustomSubDateSelection.find(selection => selection.OptionText === invoicePageData?.RenewalTermsDuration?.DurationType);
    if (!termsSelection) return null;
    const months = invoicePageData?.RenewalTermsDuration?.Duration * termsSelection.value;
    return subscriptionUtils.getSubscriptionAddSpendCalcs(startDate, months, invoicePageData?.BillingTenure, invoiceDate);
  }, [
    invoicePageData?.BillingTenure, 
    invoicePageData?.SubscriptionStartDate, 
    invoicePageData?.InvoiceDate, 
    invoicePageData?.RenewalTermsDuration
  ]);

  useEffect(
    () =>
      setSelectedInvoiceItemData({
        Category_Selected: categoryPageData,
        SubCategory_Selected: subCategoryPageData,
      }),
    [categoryPageData, subCategoryPageData]
  );

  useEffect(() => {
    getMyIntegrationsData();
    getProductCategories();
  }, []);

  async function getProductCategories() {
    await getAPIData(636, authTokens)
      .then((response) => {
        setProductCategoryList(response?.TechnologySpendDetails_List);
      })
      .catch((err) => {});
  }

  function getSubscriptionEndDate(kwargs = {}) {
    return new Promise((resolve, reject) => {
      getPageDataApi("GetSubscriptionEndDateByRolling", authTokens, {
        StartDate: kwargs?.StartDate,
        AutoRenewalObject:JSON.stringify( kwargs?.AutoRenewalObject)
      })
        .then(response => {
          if(response?.mr?.Success){
            setInvoicePageData((prev) => ({
              ...prev,
              SubscriptionEndDate: response?.detail,
            }));
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async function getMyIntegrationsData() {
    const response = await getAPIData("61", authTokens, {
      OptionEnum1: "1",
    });
    setMyIntegrations(response);
  }

  // Filters Setup
  const [activeFilters, setActiveFilters] = useState([]);
  const [invoiceFilterSearch, setInvoiceFilterSearch] = useState("");
  const [applyingFilters, setApplyingFilters] = useState(false);

  const selectedDateFilter = useSelector((state) => {
    let dashboard_date = {
      ...state?.mspclient?.mspClientDashboardDatePickerState,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  });
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (
      !firstLoad ||
      activeFilters.filter((item) => item?.filterType === "date").length > 0
    ) {
      setFirstLoad(true);
      setActiveFilters([
        {
          filterType: "date",
          selectedDateRange: {
            startDate: selectedDateFilter.startDate,
            endDate: selectedDateFilter.endDate,
            key: "selection",
          },
        },
      ]);
    } else {
      setActiveFilters([]);
    }
    setInvoiceFilterSearch("");
    setApplyingFilters(false);
  }, [pages]);

  function updateSortSubCategoryList(_subcatList) {
    let updated_data = [..._subcatList]
      ?.sort((a, b) => a.CategorySortOrder - b.CategorySortOrder)
      .map((item) => ({
        ...item,
        Visibility: item?.CategoryVisibility,
        FieldKey: "C" + item?.objId_Category,
        FieldName: item?.CategoryName,
        SortOrder: item?.CategorySortOrder,
      }));
    setSortingSubcategoryList(updated_data);
  }

  function UpdatelinkingRelinking(kwargs = {}) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "LinkingRelinkingSpend", {
        CustomerId: clientId,
        linkStatus: kwargs.linkStatus,
        objId_invoice: kwargs.objId_invoice,
      })
        .then((response) => {
          resolve(response);
          if (activePage?.type === Pages.HOME) {
            getCategoryDetail(categoryPageData.ID);
          } else {
            getSubCategoryDetail(subCategoryPageData.ID);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function getFilters(_filters) {
    let filterData = {
      Amount: [],
      fromDate: "",
      toDate: "",
      subCategoryIds: [],
    };
    let updatedActiveFilters = [];
    if (_filters) {
      updatedActiveFilters = [..._filters];
    } else {
      updatedActiveFilters = [...activeFilters];
    }
    if (updatedActiveFilters?.length > 0) {
      for (let index = 0; index < updatedActiveFilters.length; index++) {
        if (
          updatedActiveFilters[index].filterType === "date" &&
          updatedActiveFilters[index].selectedDateRange.startDate
        ) {
          filterData["fromDate"] = moment(
            updatedActiveFilters[index].selectedDateRange.startDate
          ).format("DD/MM/YYYY");
          filterData["toDate"] = moment(
            updatedActiveFilters[index].selectedDateRange.endDate
          ).format("DD/MM/YYYY");
        }
        if (
          updatedActiveFilters[index].filterType === "amount" &&
          updatedActiveFilters[index].fromAmount && updatedActiveFilters[index].toAmount
        ) {
          filterData["Amount"] = [
            +updatedActiveFilters[index].fromAmount,
            +updatedActiveFilters[index].toAmount,
          ];
        }
        if (
          updatedActiveFilters[index].filterType === "subcategory" &&
          updatedActiveFilters[index].filterList?.length > 0
        ) {
          filterData["subCategoryIds"] =
            updatedActiveFilters[index].filterList?.map(
              (item) => item?.objId_Category
            ) ?? [];
        }
      }
      return filterData;
    } else {
      return filterData;
    }
  }

  const activePage = pages[pages.length - 1];

  /**
   *
   * @param {"Modal Header Name"} pageName
   * @param {"Type of Page"} type
   * @param {"Data for Page"} data
   *
   * @returns void
   */
  function goTo(pageName, type = "", data = {}) {
    if (pageName == null || pageName === "") {
      console.log("go to page is Null");
      return;
    }
    setPages([...pages, { name: pageName, type: type, data: data }]);
  }

  function goBack() {
    let updated_pages = [...pages];
    const currentPageType = activePage?.type;
    setSubscriptionViewInvoiceData(undefined);
    setChangesInInvoice(0);
    if (currentPageType === Pages.SUBCATEGORY) {
      setSubCategoryPageData({});
    }
    if (updated_pages.length > 1) {
      let future_page = updated_pages[updated_pages.length - 2];
      if (future_page.type === Pages.HOME) {
        setLoading(true);
        getCategoryDetail(categoryPageData.ID);
      }
      if (future_page.type === Pages.SUBCATEGORY) {
        setLoading(true);
        getSubCategoryDetail(subCategoryPageData.ID);
      }
      if (future_page.type === Pages.ADD_SPEND) {
        setLoading(true);
        if (future_page.data.currentTab) {
          setAddSpendSelectedTab(future_page.data.currentTab);
        } else {
          setAddSpendSelectedTab('addByInvoice');
        }
        getInvoiceDetails(
          future_page.data.invoiceId,
          future_page.data.subCategoryId > 0
            ? future_page.data.subCategoryId
            : future_page.data.categoryId,
          {
            isSubscriptionView: future_page.data.currentTab === "addBySubscription",
          }
        );
      }

      updated_pages.pop();
      setPages(updated_pages);
    } else {
      hideModal();
    }
  }

  const Pages = {
    HOME: "home",
    INVOICE_DATA: "invoice_data",
    ADD_SPEND: "add_spend",
    SUBCATEGORY: "subcategory",
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      getTaxRateList();
      setLastInvoiceDate(null);
      setChangesInInvoice(0);
      setSubscriptionActiveFilters([]);
      setSelectedTab(defaultSelectedTab);
      // For Adding and opening List View Data
      if (listViewInvoiceData) {
        if (listViewInvoiceData.newInvoice) {
          setLoading(true);
          setAddSpendSelectedTab("addByInvoice");
          setInvoiceEditMode(true);
          // goTo("Add Spend", Pages.ADD_SPEND, {});
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: "Add Spend",
              data: {
                currentTab: "addByInvoice",
              },
            },
          ]);

          getInvoiceDetails(0, 0, { isSubscriptionView: false });
        } else {
          setLoading(true);
          // setInvoiceEditMode(
          //   invoiceItem?.Editable
          // );
          if (listViewInvoiceData?.isDirectAmout) {
            setAddSpendSelectedTab("addByAmount");
          } else {
            setAddSpendSelectedTab("addByInvoice");
          }
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative w-fit-content h-fit-content">
                    {listViewInvoiceData?.isSubscription ? (
                      <SubPaymentInvoiceIcon
                        width="20px"
                        height="20px"
                        className="d-inline-block"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      <>
                        <img
                          alt=""
                          className="mr-2"
                          src="/images/msp/msp-invoice-icon.svg"
                        />
                        {listViewInvoiceData?.IntegrationID > 0 && (
                          <img
                            src={integrationUtils.getImagePath(listViewInvoiceData?.IntegrationID, listViewInvoiceData?.ToolIcon)}
                            alt=""
                            className="position-absolute rounded-full"
                            width={13}
                            height={13}
                            style={{
                              top: "44%",
                              right: "4px",
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {TrimString(listViewInvoiceData?.SpendTitle, 30)}
                </div>
              ),
              data: {
                invoiceId: listViewInvoiceData?.InvoiceId,
                currentTab: listViewInvoiceData?.isDirectAmout ? "addByAmount" : "addByInvoice",
              },
            },
          ]);
          getInvoiceDetails(
            listViewInvoiceData?.InvoiceId,
            listViewInvoiceData?.objId_SubCategory > 0
              ? listViewInvoiceData?.objId_SubCategory
              : listViewInvoiceData?.objId_Category,
            { isSubscriptionView: false }
          );
        }

        return;
      }

      // For Opening Susbcription Data
      if (subscriptionViewInvoiceData) {
        if (subscriptionViewInvoiceData.newInvoice) {
          setLoading(true);
          setAddSpendSelectedTab(defaultSpendSelectedTab);
          setInvoiceEditMode(true);
          // goTo("Add Spend", Pages.ADD_SPEND, {});
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: "Add Subscription",
              data: {
                currentTab: defaultSpendSelectedTab,
              },
            },
          ]);

          getInvoiceDetails(0, 0, { isSubscriptionView: true });
        } else {
          setLoading(true);
          // setInvoiceEditMode(
          //   invoiceItem?.Editable
          // );
          // if (listViewInvoiceData?.isDirectAmout) {
          //   setAddSpendSelectedTab(
          //     "addByAmount"
          //   );
          // } else {
          //   setAddSpendSelectedTab(
          //     "addByInvoice"
          //   );
          // }
          setAddSpendSelectedTab(defaultSpendSelectedTab);
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative w-fit-content h-fit-content">
                    <SubPaymentInvoiceIcon
                      width="20px"
                      height="20px"
                      className="d-inline-block"
                      style={{ marginRight: "5px" }}
                    />
                    {/* {listViewInvoiceData?.IntegrationID >
                  0 && (
                  <img
                    src={`/images/attack-surface/integrations/${listViewInvoiceData?.IntegrationID}.webp`}
                    alt=""
                    className="position-absolute rounded-full"
                    width={13}
                    height={13}
                    style={{
                      top: "44%",
                      right: "4px",
                    }}
                  />
                )} */}
                  </div>
                  {TrimString(
                    subscriptionViewInvoiceData?.SubscriptionTitle,
                    30
                  )}
                </div>
              ),
              data: {
                invoiceId: subscriptionViewInvoiceData?.ObjId_Invoice,
                categoryId: subscriptionViewInvoiceData?.ObjId_Category,
                subCategoryId: subscriptionViewInvoiceData?.ObjId_SubCategory,
                invoiceType: "addBySubscription",
                currentTab: defaultSpendSelectedTab,
              },
            },
          ]);
          getSubPaymentLastInvoice({
            clientId,
            invoiceId: subscriptionViewInvoiceData?.ObjId_Invoice
          })
          getInvoiceDetails(
            subscriptionViewInvoiceData?.ObjId_Invoice,
            subscriptionViewInvoiceData?.ObjId_SubCategory > 0
              ? subscriptionViewInvoiceData?.ObjId_SubCategory
              : subscriptionViewInvoiceData?.ObjId_Category,
            { isSubscriptionView: defaultSpendSelectedTab === "addBySubscription" }
          );
        }

        return;
      }

      if (categoryData?.CategoryId > 0) {
        setPages([
          {
            type: "home",
            name: (
              <>
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    background: categoryData?.CategoryColourHexCode,
                  }}
                  alt=""
                  className="mr-2 rounded-full d-inline-block"
                />
                {TrimString(categoryData?.CategoryName, 30)}
              </>
            ),
            data: {},
          },
        ]);

        getCategoryDetail(
          categoryData?.CategoryId,
          {
            Amount: "",
            fromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
            toDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
            subCategoryIds: [],
          },
          false
        );
      }
    } else {
      setCategoryPageData({});
      setSelectedInvoiceItemData({});
      setSubCategoryPageData({});
      setInvoicePageData({});
      setFirstLoad(false);
    }
  }, [show]);

  function getSubscriptionViewData(kwargs) {
    if (!kwargs?.filters) {
      if (activePage?.type === Pages.HOME) {
        if (!kwargs?.edit && categoryPageData?.SubscriptionList) return;
        setCategoryPageData(prev => ({
          ...prev,
          isSubscriptionLoading: true,
        }));
      } else {
        if (!kwargs?.edit && subCategoryPageData?.SubscriptionList) return;
        setSubCategoryPageData(prev => ({
          ...prev,
          isSubscriptionLoading: true,
        }));
      }
    }
   
    let filters = getListViewFilters(kwargs);
    return new Promise((resolve, reject) => {
      getPageDataApi("GetMSPSubscriptionList", authTokens, {
        pageNo: kwargs?.subscriptionViewPageNumber ?? 1,
        objId_Customer: clientId,
        pageSize: 200,
        isSaas: isSaas,
        ...(kwargs?.ObjId_Categogy ? { ObjId_Categogy: kwargs?.ObjId_Categogy } : {
          ObjId_Categogy: activePage?.type === Pages.HOME ? categoryPageData?.ID : subCategoryPageData?.ID,
        }),
        ...(kwargs?.filters && { spendFilters: JSON.stringify({ SearchCriterias: filters })}),
      }).then((response) => {
        if (response?.mr?.Success) {
          if (kwargs?.homePage || activePage?.type === Pages.HOME) {
            setCategoryPageData(prev => ({
              ...prev,
              SubscriptionList: response?.detail?.SubscriptionList,
              isSubscriptionLoading: false,
            }));
            setCategorySubscriptionViewData({
              ...response?.detail,
              menuSorting: response?.detail?.SortVisibility?.map(
                (item) => ({
                  ...item,
                  Visibility: item?.ColumnVisibility,
                  SortOrder: item?.ColumnSortOrder,
                  FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                  FieldName: item?.ColumnName,
                })
              ),
            });
          } else {
            setSubCategoryPageData(prev => ({
              ...prev,
              SubscriptionList: response?.detail?.SubscriptionList,
              isSubscriptionLoading: false,
            }));
            setSubcategorySubscriptionViewData({
              ...response?.detail,
              menuSorting: response?.detail?.SortVisibility?.map(
                (item) => ({
                  ...item,
                  Visibility: item?.ColumnVisibility,
                  SortOrder: item?.ColumnSortOrder,
                  FieldKey: item?.ColumnName?.replaceAll(" ", ""),
                  FieldName: item?.ColumnName,
                })
              ),
            });
          }
          resolve(response);
        } else {
          reject(response);
        }
      });
    });
  }

  useEffect(() => {
    if (!show) return;
    if (selectedTab === ModalTabs.Subscriptions) {
      getSubscriptionViewData();
    }
  }, [selectedTab]);

  /**
   *
   * @param {*} _categoryId
   *
   * @param kwargs {
   *    amount:"",
   *    fromDate:"",
   *    toDate:"",
   *   subCategoryIds: []
   * }
   */

  async function getCategoryDetail(_categoryId, kwargs, editData = true) {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: _categoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: kwargs?.Amount ? kwargs?.Amount.join(",") : [],
      FromDate:
        kwargs?.fromDate ??
        moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate:
        kwargs?.toDate ??
        moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify(kwargs?.subCategoryIds ?? []),
      isSaas,
    })
      .then((response) => {
        setApplyingFilters(false);
        updateSortSubCategoryList(response?.SubCategory_List);
        if (response?.mr?.Success) {
          if (kwargs && editData) {
            setCategoryPageData(prev => ({
              ...prev,
              ID: _categoryId,
              ...categoryPageData,
              InvoiceItemVM_List: response?.InvoiceItemVM_List,
              SubCategory_List: response?.SubCategory_List?.sort(
                (a, b) => a.CategorySortOrder - b.CategorySortOrder
              )?.filter((item) => item?.CategoryVisibility === true),
              TotalAmount_Text: response?.TotalAmount_Text,
            }));
            setSelectedInvoiceItemData({
              Category_Selected: categoryPageData,
              SubCategory_Selected: subCategoryPageData,
            });
          } else {
            setCategoryPageData(prev => ({
              ...prev,
              ID: _categoryId,
              ...response,
              SubCategory_List: response?.SubCategory_List?.sort(
                (a, b) => a.CategorySortOrder - b.CategorySortOrder
              )?.filter((item) => item?.CategoryVisibility === true),
            }));
            setSelectedInvoiceItemData({
              Category_Selected: categoryPageData,
              SubCategory_Selected: subCategoryPageData,
            });
          }
          getSubscriptionViewData({ edit: true, ObjId_Categogy: _categoryId, homePage: true });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setApplyingFilters(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function updateSubCategoryList() {
    postData(authTokens, "GetCategoryDetail", {
      CategoryId: categoryPageData.ID,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: "",
      FromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify([]),
      isSaas,
    })
      .then((response) => {
        setSubCategoriesLoading(false);
        updateSortSubCategoryList(response?.SubCategory_List);
        if (response?.mr?.Success) {
          let updated_data = { ...categoryPageData };

          setCategoryPageData({
            ...updated_data,
            SubCategory_List: response?.SubCategory_List?.sort(
              (a, b) => a.CategorySortOrder - b.CategorySortOrder
            )?.filter((item) => item?.CategoryVisibility === true),
          });
        } else {
        }
      })
      .catch((error) => {
        setSubCategoriesLoading(false);
      });
  }

  function updateSubCategorySorting(e) {
    setSubCategoriesLoading(true);
    let data = { ...categoryPageData?.CategorySettings[0] };
    postData(authTokens, "UpdatePSACategorySettings", {
      CategorySettings: JSON.stringify([
        {
          ...data,
          SubCatSettings: e,
        },
      ]),
      CustomerId: clientId,
      isSubcat: true,
      isSaas,
    }).then(() => {
      updateSubCategoryList();
    });
  }

  /**
   *
   * @param {*} _subCategoryId
   *
   * @param kwargs {
   *    amount:"",
   *    fromDate:"",
   *    toDate:""
   * }
   */

  async function getSubCategoryDetail(_subCategoryId, kwargs) {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: _subCategoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: kwargs?.Amount ?? "",
      FromDate:
        kwargs?.fromDate ??
        moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate:
        kwargs?.toDate ??
        moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      isSaas,
    })
      .then((response) => {
        setApplyingFilters(false);
        if (response?.mr?.Success) {
          if (kwargs) {
            setSubCategoryPageData({
              ID: _subCategoryId,
              ...subCategoryPageData,
              InvoiceItemVM_List: response?.InvoiceItemVM_List,
              TotalAmount_Text: response?.TotalAmount_Text,
              SubscriptionList: subCategoryPageData?.SubscriptionList,
            });
          } else {
            setSubCategoryPageData({ ID: _subCategoryId, ...response });
          }
          getSubscriptionViewData({ edit: true, ObjId_Categogy: _subCategoryId });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setApplyingFilters(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [taxList, setTaxList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});

  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("632", authTokens, {
        ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        isSaas,
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
          setCurrencyList(response?.CurrencyCode_List ?? []);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  //  kwargs ={
  //   stoploading:true,
  //   created:true
  //  }
  function updateTaxList(kwargs = {}) {
    if (!kwargs?.stoploading) {
      // setLoading(true)
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        setLoading(false);
        if (kwargs?.created > 0) {
          let newly_created = response?.TaxRates_List.find(
            (item) => item?.ObjectId === kwargs?.created
          );
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: newly_created ?? response?.TaxRates_List?.[0],
          }));
          return;
        }
        // If Selected Tax rate is deleted
        if (
          kwargs?.deletedId === invoicePageData?.TaxRates_Selected?.ObjectId
        ) {
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: response?.TaxRates_List?.[0],
          }));
          return;
        }

        if (!invoicePageData?.TaxRates_Selected) {
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: response?.TaxRates_List?.[0],
          }));
        } else {
          let selected_tax = response?.TaxRates_List.find(
            (item) =>
              item?.ObjectId === invoicePageData?.TaxRates_Selected?.ObjectId
          );
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: selected_tax,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function onClickUpdateTaxRate(item) {
    return new Promise((resolve, reject) => {
      updateTaxRate(item)
        .then(() => {
          getTaxRateList();
          resolve();
        })
    });
  }

  const [updatingExchangeRate, setUpdatingExchangeRate] = useState(false);
  function toFixedTruncated(rate, precision = 5) {
    const multiplier = Math.pow(10, precision);
    const truncatedRate = Math.trunc(rate * multiplier) / multiplier;
    return truncatedRate.toFixed(precision);
  }
  function updateExchangeRateData(kwargs) {
    setUpdatingExchangeRate(true);
    // if (updatingExchangeRate) {
    //   return;
    // }
    let ForeignCurrencyRate = kwargs?.ForeignCurrencyRate;
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetCurrencyConverionRate", {
        FromCurrency: kwargs?._currency ?? exchangeRateData?.FromCurrency,
        ToCurrency: defaultCurrencyData?.CurrencyName,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Date:
          kwargs?._date ??
          moment(exchangeRateData?.Date ?? "").format("DD/MM/YYYY"),
        isSaas,
      })
        .then((response) => {
          resolve(response);
          let updated_data = { ...response };
          updated_data.Rate =
            ForeignCurrencyRate ?? toFixedTruncated(updated_data.Rate);
          setExchangeRateData(updated_data);

          setUpdatingExchangeRate(false);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setUpdatingExchangeRate(false);
        });
    });
  }

  function getInvoiceDetails(_invoiceId, _catSubcatID, kwargs = {}) {
    let filter_invoiceItem_id = kwargs?.invoiceItemId;
    getAPIData(`${632}`, authTokens, {
      optionEnum1: _invoiceId,
      optionEnum2: clientId,
      optionEnum3: _catSubcatID ?? 0,
      isSaas,
      ...(kwargs?.isSubscriptionView !== undefined && {
        isSubscriptionView: kwargs?.isSubscriptionView,
      }),
    })
      .then((response) => {
        let categoryItem = null;
        if (defaultCategoryName) {
          categoryItem = response?.SpendCategoryVM_List?.find(item => item.CategoryName === defaultCategoryName);
        }
        if (categoryItem) {
          getSubCategoryList(categoryItem.objId_Category);
        }
        setErrors({});
        setChangesInInvoice(0);
        setInvoicePageData({
          ID: _invoiceId,
          ...response,
          ...(defaultTitle && {
            InvoiceRef: removeWhiteSpace(defaultTitle),
          }),
          ...(categoryItem && {
            Category_Selected: categoryItem,
          }),
          InvoiceItem_List: response?.InvoiceItem_List,
            SubscriptionMeta: {
              ...response?.SubscriptionMeta,
              BillingDay: response?.SubscriptionMeta?.BillingDay?.map(item => ({
                ...item,
                OptionText: item.OptionText.split(' ')[0]
              }))
            },
          ...(!response?.SubscriptionStartDate && {
            SubscriptionStartDate: moment().format("YYYY-MM-DD"),
          }),
          ...((!response?.InvoiceDate || response?.InvoiceDate === "0001-01-01T00:00:00") && {
            InvoiceDate: moment().format("YYYY-MM-DD"),
          }),
          ...(!response?.SubscriptionStatus && {
            SubscriptionStatus: response?.SubscriptionMeta?.SubscriptionStatusOptions?.[0]
          }),
          ...(!response?.RenewalTermsDuration && {
            RenewalTermsDuration: { Duration: 1, DurationType: "Months" }
          }),
          ...(!response?.BillingTenure && {
            BillingTenure: response?.SubscriptionMeta?.RecurringPeriodOptions?.[0]
          }),
          ...(kwargs?.Category_Selected && {
            Category_Selected: kwargs?.Category_Selected,
          }),
          ...(kwargs?.SubCategory_Selected && {
            SubCategory_Selected: kwargs?.SubCategory_Selected,
          })
        });
        // setLoading(false);
        const categorySelected = response?.Category_Selected?.objId_Category || kwargs?.Category_Selected?.objId_Category;
        if (
          addSpendSelectedTab === "addBySubscription" &&
          categorySelected
        ) {
          getSubCategoryList(categorySelected);
        }

        updateExchangeRateData({
          _currency: response?.CurrencyCode_Selected
            ? response?.CurrencyCode_Selected?.CurrencyName
            : defaultCurrencyData?.CurrencyName,
          _date:
            response?.InvoiceDate === "0001-01-01T00:00:00"
              ? moment().format("DD/MM/YYYY")
              : moment(response?.InvoiceDate).format("DD/MM/YYYY"),
          ForeignCurrencyRate: response?.ForeignCurrencyRate,
        }).then(() => {
          setLoading(false);
          setUserSelectedCurrency(
            response?.CurrencyCode_Selected 
              ?? defaultCurrencyData
              ?? response?.CurrencyCode_List?.[0]
          );
        });
      })
      .catch((err) => {
        setLoading(false);
        setChangesInInvoice(0);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  function getSpendDataForGraph() {
    if (
      categoryPageData?.SubCategory_List?.length > 0 ||
      categoryPageData?.TotalUnassignedAmount > 0
    ) {
      const dummyColors = ["#FFCA78", "#DF6844", "#EB9E87", "#F36060"];
      let data =
        // [...dummy]
        [...categoryPageData?.SubCategory_List]
          .filter((item) => item?.TotalAmount > 0)
          .map((item, itemIndex) => ({
            label: item?.CategoryName,
            value: item?.TotalAmount,
            color:
              item?.CategoryColourHexCode ??
              dummyColors[itemIndex % dummyColors.length],
            colorId: item?.objId_Category,
          }));
      if (categoryPageData?.TotalUnassignedAmount > 0) {
        data.push({
          label: categoryPageData?.CategoryName,
          value: categoryPageData?.TotalUnassignedAmount,
          color: categoryPageData?.CategoryColourHexCode ?? "#DF6844",
          colorId: categoryPageData?.ID,
        });
      }
  

      return data;
    } else {
      return [];
    }
  }

  const graphData = useMemo(() => getSpendDataForGraph(), [categoryPageData]);

  useEffect(() => {
    setDonutColorIds([...graphData].map((item) => item.colorId));
  }, [graphData]);

  async function AddEditInvoice() {
    setSaving(true);
    setSubscriptionJson({});
    const correctDate = (data) => {
      let res = data;
      if (data && dateUtils.isDatePickerFormat(data)) {
        res = dateUtils.converToDateFormat(data);
      }
      if (res) {
        res = new Date(res);
        const day = res.getDate();
        const month = res.getMonth() + 1;
        const year = res.getFullYear();
        return `${day}/${month}/${year}`;
      }
      return res;
    }
    await postData(authTokens, "CreateMSPTechnologyInvoice", {
      objId_Invoice: invoicePageData?.ID,
      objId_Customer: clientId,
      Discount: invoicePageData?.Discount ? invoicePageData?.Discount : 0,
      Description: invoicePageData?.Description ?? "",
      InvoiceRef: invoicePageData?.InvoiceRef,
      ContactEmail: invoicePageData?.ContactEmail ?? "",
      SupplierId:
        invoicePageData?.PotentialSupplierSelected?.PotentialSupplierId,
      InvoiceDate: correctDate(invoicePageData?.InvoiceDate),
      InvoiceItem_List: JSON.stringify(invoicePageData?.InvoiceItem_List),
      SupplierName:
        invoicePageData?.PotentialSupplierSelected?.PotentialSupplierName,
      TaxRates_Selected: JSON.stringify(
        invoicePageData?.TaxRates_Selected ?? {}
      ),
      CurrencyCode_Selected: JSON.stringify(userSelectedCurrency ?? {}),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      IsDirectAmount: addSpendSelectedTab === "addByAmount" ? true : false,
      CategoryId:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.Category_Selected?.objId_Category
          : categoryPageData.ID,
      SubCategoryId:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.SubCategory_Selected?.objId_Category
          : subCategoryPageData.ID,
      TotalAmount:
        addSpendSelectedTab === "addByAmount"
          ? Number(invoicePageData?.TotalAmount)
          : Number(getTotalAmountForInvoice() ?? 0),
      ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
      ForeignCurrencyAmount:
        addSpendSelectedTab === "addByAmount"
          ? Number(invoicePageData?.TotalAmount) -
            Number(invoicePageData?.Discount)
          : Number(getTotalAmountForInvoice() ?? 0),
      // SUBSCRIPTION VARIABLES
      // AutoRenewalTerms: JSON.stringify(invoicePageData?.AutoRenewalTerms),
      SubscriptionStatus: JSON.stringify(
        invoicePageData?.SubscriptionStatus ??
          invoicePageData?.SubscriptionMeta?.SubscriptionStatusOptions?.[0]
      ),
      BillingTenure: JSON.stringify(invoicePageData?.BillingTenure),
      CancellationTermsDuration: JSON.stringify({
        ...invoicePageData?.CancellationTermsDuration,
        Duration: Number(invoicePageData?.CancellationTermsDuration?.Duration),
      }),
      RenewalTermsDuration: JSON.stringify({
        ...invoicePageData?.RenewalTermsDuration,
        Duration: Number(invoicePageData?.RenewalTermsDuration?.Duration),
      }),
      PaymentMethod: JSON.stringify(invoicePageData?.PaymentMethod),
      SubscriptionStartDate: correctDate(invoicePageData?.SubscriptionStartDate),
      SubscriptionEndDate: correctDate(subPaymentDetails?.Renewal_Date),
      isSubscription:
       ( addSpendSelectedTab === "addBySubscription" || invoicePageData?.IsSubscription) ? true : false,
      isSaas: isSaas,
      BaseAmount:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.TotalAmount
          : null,
      ...(subPaymentLastInvoice && {
        LastPaymentDate: subPaymentLastInvoice
      })
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          if (
            addSpendSelectedTab === "addBySubscription" &&
            response?.ObjectTemplateList?.length > 0
          ) {
            setInvoicePageData((prev) => ({
              ...prev,
              ID: response?.EntityId,
            }));
            setGeneratedInoiceListBySubscription(response?.ObjectTemplateList);
            setSubscriptionJson(response?.Message);
            setShowGenerateInvoiceModal(true);
          } else {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSaving(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function deleteInvoice(_invoiceId, isSubscription = false) {
    return new Promise((resolve, reject) => {
      getAPIData(`${628}`, authTokens, {
        optionEnum1: _invoiceId,
        optionEnum2: 0,
        IsSubscriptionInvoice: false,
        isSaas,
        ArchiveAutoGenInvoice: !isSubscription,
      })
        .then((response) => {
          if (response?.Success) {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          reject("Something went wrong");
        });
    });
  }

  async function deleteInvoiceItem(_invoiceItemId) {
    // setDeleting(true);
    await getAPIData(`${629}`, authTokens, {
      optionEnum1: _invoiceItemId,
      optionEnum2: 0,
      isSaas,
    })
      .then((response) => {
        refreshData();
        // setDeleting(false);
        if (response?.Success) {
          // CallToast("Updated Successfully", false, false, "v2style");
        } else {
          // CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        // setDeleting(false);
        console.log(err);
        // CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function deleteCategorySubCategory(_catSubcatID, deleteAllInvoices = false) {
    // setDeleting(true);
    return new Promise((resolve, reject) => {
      getAPIData(`${630}`, authTokens, {
        optionEnum1: _catSubcatID,
        optionEnum2: 0,
        optionEnum3: deleteAllInvoices ? "1" : "0",
        isSaas,
      })
        .then((response) => {
          setDeleting(false);
          if (response?.Success) {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject(new Error("Update failed"));
          }
        })
        .catch((err) => {
          setDeleting(false);
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          reject(err);
        });
    });
  }

  // Updating Data Setup
  const [errors, setErrors] = useState({});

  // Category Page
  async function checkCategroyPageErrors() {
    let errors = 0;
    if (!categoryPageData?.CategoryName) {
      errors += 1;
      setErrors((prev) => ({ ...prev, categoryNameError: true }));
    }

    return errors;
  }

  async function updateCategory() {
    setSaving(true);
    postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Category: categoryPageData?.objId_Category,
      objId_Customer: clientId,
      CategoryName: categoryPageData?.CategoryName,
      CategoryColour: categoryPageData?.CategoryColour,
      ParentCategoryId: 0,
      isSaas,
      // SubCategory_List: JSON.stringify(sub_category_list),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          hideModal();
          refreshData();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");

        setSaving(false);
      });
  }

  // Sub Category
  async function checkSubCategoryPageErrors() {
    let errors = 0;
    if (!subCategoryPageData?.CategoryName) {
      errors += 1;
      setErrors((prev) => ({ ...prev, subCategoryNameError: true }));
    }

    return errors;
  }

  async function updateSubCategory() {
    setSaving(true);
    postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Category: subCategoryPageData?.objId_Category,
      objId_Customer: clientId,
      CategoryName: subCategoryPageData?.CategoryName,
      CategoryColour: subCategoryPageData?.CategoryColour,
      ParentCategoryId: categoryPageData?.objId_Category,
      isSaas,
      // SubCategory_List: JSON.stringify(sub_category_list),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          hideModal();
          refreshData();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");

        setSaving(false);
      });
  }

  // Invoice Page errors
  async function checkAddSpendPageErrors(tabType) {
    let errors = 0;
    // For handle Add by Invoice Error
    if (tabType === "addByInvoice") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }

      if (
        !invoicePageData?.InvoiceDate ||
        invoicePageData.InvoiceDate === "0001-01-01T00:00:00"
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      // if (!validateEmail(invoicePageData?.ContactEmail)) {
      //   errors += 1;
      //   setErrors((prev) => ({ ...prev, invoicePageEmailError: true }));
      // }

      if (!(invoicePageData?.InvoiceItem_List?.length > 0)) {
        errors += 1;
        setErrors((prev) => ({
          ...prev,
          invoiceItemsError:
            "Invoice must contain a miniumum of 1 line item. Please add 1 or more line items to this invoice to proceed",
        }));
      }
    }

    if (tabType === "addByAmount") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }
      // if (!validateEmail(invoicePageData?.ContactEmail)) {
      //   errors += 1;
      //   setErrors((prev) => ({ ...prev, invoicePageEmailError: true }));
      // }
      if (
        !invoicePageData?.InvoiceDate ||
        invoicePageData.InvoiceDate === "0001-01-01T00:00:00"
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      if (!invoicePageData?.TotalAmount) {
        errors += 1;
        setErrors((prev) => ({ ...prev, totalAmountError: true }));
      }
    }
    if (tabType === "addBySubscription") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }
      if (!invoicePageData?.Description) {
        errors += 1;
        setErrors((prev) => ({ ...prev, descriptionError: true }));
      }
      if (!invoicePageData?.BillingTenure) {
        errors += 1;
        setErrors((prev) => ({ ...prev, recurringPeriodError: true }));
      }
      const billingTenure = invoicePageData?.BillingTenure?.OptionText;
      if (billingTenure && invoicePageData?.RenewalTermsDuration?.Duration) {
        const billingTenureDuration = BillingPeriodInMonths[billingTenure];
        const renewalType = CustomSubDateSelection.find(
          (item) => item?.OptionText === invoicePageData?.RenewalTermsDuration?.DurationType
        );
        const renewalMonths = invoicePageData?.RenewalTermsDuration?.Duration * renewalType.value;
        if (billingTenureDuration > renewalMonths) {
          errors += 1;
          setErrors((prev) => ({ ...prev, recurringPeriodError: true }));
        }
      }
      if (!invoicePageData?.TaxRates_Selected) {
        errors += 1;
        setErrors((prev) => ({ ...prev, taxRateError: true }));
      }
      if (!invoicePageData?.RenewalTermsDuration || !invoicePageData?.RenewalTermsDuration?.Duration) {
        errors += 1;
        setErrors((prev) => ({ ...prev, termError: true }));
      }
      if (!invoicePageData?.PaymentMethod) {
        errors += 1;
        setErrors((prev) => ({ ...prev, paymentMethodError: true }));
      }
      if (!invoicePageData?.Category_Selected) {
        errors += 1;
        setErrors((prev) => ({ ...prev, selectedCategoryError: true }));
      }
      if (!invoicePageData?.SubscriptionStartDate) {
        errors += 1;
        setErrors((prev) => ({ ...prev, subscriptionStartDateError: true }));
      }
      let startDateData = invoicePageData?.SubscriptionStartDate;
      if (startDateData && dateUtils.isDatePickerFormat(startDateData)) {
        startDateData = dateUtils.converToDateFormat(startDateData);
      }
      if (startDateData) {
        startDateData = new Date(startDateData);
        startDateData = new Date(startDateData.getFullYear(), startDateData.getMonth(), startDateData.getDate());
      }
      let invoiceDateData = invoicePageData?.InvoiceDate;
      if (invoiceDateData && dateUtils.isDatePickerFormat(invoiceDateData)) {
        invoiceDateData = dateUtils.converToDateFormat(invoiceDateData);
      }
      if (invoiceDateData) {
        invoiceDateData = new Date(invoiceDateData);
        invoiceDateData = new Date(invoiceDateData.getFullYear(), invoiceDateData.getMonth(), invoiceDateData.getDate());
      }

      if (!invoicePageData?.InvoiceDate || (
        startDateData &&
        invoiceDateData &&
        new Date(startDateData) > new Date(invoiceDateData)
      )) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      if (!invoicePageData?.CancellationTermsDuration || !invoicePageData?.CancellationTermsDuration?.Duration) {
        errors += 1;
        setErrors((prev) => ({ ...prev, cancellationTermsError: true }));
      }
      if (!invoicePageData?.TotalAmount) {
        errors += 1;
        setErrors((prev) => ({ ...prev, totalAmountError: true }));
      }
    }

    return errors;
  }

  function getTotalAmountForInvoice() {
    if (addSpendSelectedTab === "addByInvoice") {
      const totalAmount = invoicePageData?.InvoiceItem_List?.reduce(
        (total, item) =>
          total +
          Number(
            item.ItemValue * item.Quantity +
              item.ItemValue *
                item.Quantity *
                ((item?.TaxRates_Selected?.TaxRate ?? 0) / 100)
          ),
        0
      );

      return isNaN(totalAmount)
        ? 0
        : totalAmount - Number(invoicePageData?.Discount);
    } else if (addSpendSelectedTab === "addByAmount") {
      const totalAmount =
        Number(
          (((invoicePageData.TotalAmount ?? 0) - invoicePageData?.Discount ??
            0) *
            (invoicePageData?.TaxRates_Selected?.TaxRate ?? 0)) /
            100 ?? 0
        ) +
        Number(invoicePageData?.TotalAmount ?? 0) -
        Number(invoicePageData?.Discount ?? 0);

      return isNaN(totalAmount) ? 0 : totalAmount;
    } else if (addSpendSelectedTab === "addBySubscription") {
      const totalAmount =
        Number(
          (((invoicePageData.TotalAmount ?? 0) - invoicePageData?.Discount ??
            0) *
            (invoicePageData?.TaxRates_Selected?.TaxRate ?? 0)) /
            100 ?? 0
        ) +
        Number(invoicePageData?.TotalAmount ?? 0) -
        Number(invoicePageData?.Discount ?? 0);

      return isNaN(totalAmount) ? 0 : totalAmount;
    } else {
      return 0;
    }
  }

  function getSubTotalAmountForInvoice() {
    const subTotalAmount = invoicePageData?.InvoiceItem_List?.reduce(
      (total, item) =>
        total +
        Number(
          item.ItemValue * item.Quantity +
            item.ItemValue * item.Quantity * (0 / 100)
        ),
      0
    );

    return subTotalAmount - Number(invoicePageData?.Discount ?? 0);
  }

  function getTaxForInvoice() {
    const totalAmount = invoicePageData?.InvoiceItem_List?.reduce(
      (total, item) =>
        total +
        Number(
          item.ItemValue *
            item.Quantity *
            ((item?.TaxRates_Selected?.TaxRate ?? 0) / 100)
        ),
      0
    );

    return isNaN(totalAmount) ? 0 : totalAmount;
  }

  function highlightedInvoice(title, searchValue) {
    if (searchValue !== "") {
      const highlightedText = title.replace(
        new RegExp(searchValue, "gi"),
        (match) => `<span class="list-highlight-class">${match}</span>`
      );

      return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    } else {
      return <span>{title}</span>;
    }
  }

  function executeInvoiceSwitchingModal(e) {
    setInvoicePageData((prev) => ({
      ...prev,
      InvoiceRef: "",
      Description: "",
      ContactEmail: "",
      PotentialSupplierSelected: {
        PotentialSupplierName: "",
        PotentialSupplierId: 0,
      },
      InvoiceDate: "",
      InvoiceItem_List: [],
      Discount: 0,
      TotalAmount: 0,
      SpendCategory_Name: "",
      Category_Selected: null,
      SubCategory_Selected: null,
      // BillingTenure: null,
      SubscriptionStartDate: "",
      SubscriptionEndDate: "",
      AutoRenewalTerms: null,
      CancellationTermsDuration: null,
    }));
    setErrors({});
    setPotentialSupplierSearch("");
    setAddSpendSelectedTab(e);
    setChangesInInvoice(0);
    setUserSelectedCurrency(defaultCurrencyData);
  }
  const [subCategoryLoading, setsubCategoryLoading] = useState(false);
  const [subCategoryList, setsubCategoryList] = useState([]);
  function getSubCategoryList(_categoryId) {
    setsubCategoryLoading(true);

    postData(authTokens, "GetCategoryDetail", {
      CategoryId: _categoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: "",
      FromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify([]),
      isSaas,
    }).then((response) => {
      setsubCategoryLoading(false);
      setsubCategoryList(response?.SubCategory_List);
    });
  }

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="0016sqxw"
      width={"480px"}
      onHide={hideModal}
      isDraggableParent={isDraggableParent}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <span className="cursor-pointer">
          {pages.length > 1 && (
            <img
              src="/images/supply-chain/chevron-left.svg"
              onClick={() => {
                goBack();
              }}
              alt=""
            />
          )}
        </span>

        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
          title={activePage?.name}
        >
          {activePage?.name}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className="technology-spend-modal hide-scrollbar h-100">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {activePage?.type === Pages.HOME && (
              <TechspendHomeContent
                ModalTabs={ModalTabs}
                Pages={Pages}
                applyingFilters={applyingFilters}
                categoryGraphRef={categoryGraphRef}
                categoryPageData={categoryPageData}
                defaultSelectedTab={defaultSelectedTab}
                donutColorIds={donutColorIds}
                errors={errors}
                getInvoiceDetails={getInvoiceDetails}
                getSubCategoryDetail={getSubCategoryDetail}
                goTo={goTo}
                graphData={graphData}
                hideModal={hideModal}
                highlightedInvoice={highlightedInvoice}
                invoiceFilterSearch={invoiceFilterSearch}
                isSaas={isSaas}
                searchSpendInvoices={searchSpendInvoices}
                selectedTab={selectedTab}
                setAddSpendSelectedTab={setAddSpendSelectedTab}
                setCategoryPageData={setCategoryPageData}
                setDefaultSelectedTab={setDefaultSelectedTab}
                setErrors={setErrors}
                setInvoiceEditMode={setInvoiceEditMode}
                setLoading={setLoading}
                setSelectedInvoiceItemData={setSelectedInvoiceItemData}
                setSelectedTab={setSelectedTab}
                setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                setSubcategoriesSearch={setSubcategoriesSearch}
                sortingSubcategoryList={sortingSubcategoryList}
                spendSearch={spendSearch}
                setSpendSearch={setSpendSearch}
                spendSearchRef={spendSearchRef}
                subCategoriesLoading={subCategoriesLoading}
                subCategoryPageData={subCategoryPageData}
                subcategoriesSearch={subcategoriesSearch}
                subcategorySearchRef={subcategorySearchRef}
                updateSubCategorySorting={updateSubCategorySorting}
                viewOnlyAccess={viewOnlyAccess}
                setDeleteID={setDeleteID}
                setDeleteMessage={setDeleteMessage}
                setDeleteType={setDeleteType}
                setShowDeleteModal={setShowDeleteModal}
                setSubscriptionSearch={setSubscriptionSearch}
                activeFilters={activeFilters}
                getCategoryDetail={getCategoryDetail}
                getFilters={getFilters}
                setActiveFilters={setActiveFilters}
                setApplyingFilters={setApplyingFilters}
                subscriptionSearch={subscriptionSearch}
                subscriptionSearchRef={subscriptionSearchRef}
                setDefaultSpendSelectedTab={setDefaultSpendSelectedTab}
                setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                clientId={clientId}
                getSubPaymentLastInvoice={getSubPaymentLastInvoice}
                setPages={setPages}
                subscriptionActiveFilters={subscriptionActiveFilters}
                setSubscriptionActiveFilters={setSubscriptionActiveFilters}
                getSubscriptionViewData={getSubscriptionViewData}
                subscriptionViewData={categorySubscriptionViewData}
                setUnlinkRelinkModalData={setUnlinkRelinkModalData}
                setCategoryDeleteData={setCategoryDeleteData}
              />
            )}

            {activePage?.type === Pages.ADD_SPEND && (
              <TechspendAddspendContent 
                InvoiceItemIndex={InvoiceItemIndex}
                Pages={Pages}
                addSpendSelectedTab={addSpendSelectedTab}
                all_integration_data={all_integration_data}
                categoryData={categoryData}
                categoryPageData={categoryPageData}
                changesInInvoice={changesInInvoice}
                convertToCurrencyFormat={convertToCurrencyFormat}
                currencyList={currencyList}
                defaultCurrencyData={defaultCurrencyData}
                deleteTaxRate={deleteTaxRate}
                disableCalculationFields={disableCalculationFields}
                errors={errors}
                exchangeRateData={exchangeRateData}
                executeInvoiceSwitchingModal={executeInvoiceSwitchingModal}
                getInvoiceDetails={getInvoiceDetails}
                getSubCategoryList={getSubCategoryList}
                getSubTotalAmountForInvoice={getSubTotalAmountForInvoice}
                getSubscriptionEndDate={getSubscriptionEndDate}
                getTaxForInvoice={getTaxForInvoice}
                getTaxRateList={getTaxRateList}
                getTotalAmountForInvoice={getTotalAmountForInvoice}
                goTo={goTo}
                handleDateForInvoice={handleDateForInvoice}
                highlightInvoiceItem={highlightInvoiceItem}
                invoiceEditMode={invoiceEditMode}
                invoicePageData={invoicePageData}
                isTaxRateUpdating={isTaxRateUpdating}
                onClickUpdateTaxRate={onClickUpdateTaxRate}
                pages={pages}
                potentialSupplierSearch={potentialSupplierSearch}
                selectedInvoiceItemData={selectedInvoiceItemData}
                setAddSpendSelectedTab={setAddSpendSelectedTab}
                setChangesInInvoice={setChangesInInvoice}
                setErrors={setErrors}
                setInvoiceEditMode={setInvoiceEditMode}
                setInvoiceItemIndex={setInvoiceItemIndex}
                setInvoicePageData={setInvoicePageData}
                setLoading={setLoading}
                setPotentialSupplierSearch={setPotentialSupplierSearch}
                setSelectedInvoiceItemData={setSelectedInvoiceItemData}
                setShowAddByAmountSupplierDropdown={setShowAddByAmountSupplierDropdown}
                setShowAddByInvoiceSupplierDropdown={setShowAddByInvoiceSupplierDropdown}
                setShowAddEditTaxRateModal={setShowAddEditTaxRateModal}
                setShowAddItemSpendModal={setShowAddItemSpendModal}
                setSwitchInvoiceTabModalData={setSwitchInvoiceTabModalData}
                setTaxRateData={setTaxRateData}
                setUnlinkRelinkModalData={setUnlinkRelinkModalData}
                setUserSelectedCurrency={setUserSelectedCurrency}
                showAddByAmountSupplierDropdown={showAddByAmountSupplierDropdown}
                showAddByInvoiceSupplierDropdown={showAddByInvoiceSupplierDropdown}
                showAddItemSpendModal={showAddItemSpendModal}
                subCategoryList={subCategoryList}
                subCategoryLoading={subCategoryLoading}
                subCategoryPageData={subCategoryPageData}
                subPaymentDetails={subPaymentDetails}
                taxList={taxList}
                updateExchangeRateData={updateExchangeRateData}
                updateTaxList={updateTaxList}
                userSelectedCurrency={userSelectedCurrency}
                setDeleteID={setDeleteID}
                setDeleteMessage={setDeleteMessage}
                setDeleteType={setDeleteType}
                setShowDeleteModal={setShowDeleteModal}
                loading={loading}
                clientId={clientId}
                getSubPaymentLastInvoice={getSubPaymentLastInvoice}
              />
            )}
            {activePage?.type === Pages.SUBCATEGORY && (
              <TechspendSubcategoryContent 
                Pages={Pages}
                activeFilters={activeFilters}
                applyingFilters={applyingFilters}
                categoryPageData={categoryPageData}
                defaultSelectedTab={defaultSelectedTab}
                errors={errors}
                getFilters={getFilters}
                getInvoiceDetails={getInvoiceDetails}
                getSubCategoryDetail={getSubCategoryDetail}
                goTo={goTo}
                hideModal={hideModal}
                highlightedInvoice={highlightedInvoice}
                history={history}
                invoiceFilterSearch={invoiceFilterSearch}
                isSaas={isSaas}
                searchSpendInvoices={searchSpendInvoices}
                setActiveFilters={setActiveFilters}
                setAddSpendSelectedTab={setAddSpendSelectedTab}
                setApplyingFilters={setApplyingFilters}
                setDefaultSelectedTab={setDefaultSelectedTab}
                setErrors={setErrors}
                setInvoiceEditMode={setInvoiceEditMode}
                setInvoiceFilterSearch={setInvoiceFilterSearch}
                setLoading={setLoading}
                setSubCategoryPageData={setSubCategoryPageData}
                subCategoryPageData={subCategoryPageData}
                viewOnlyAccess={viewOnlyAccess}
                ModalTabs={ModalTabs}
                getCategoryDetail={getSubCategoryDetail}
                setSpendSearch={setSpendSearch}
                setSubscriptionSearch={setSubscriptionSearch}
                spendSearch={spendSearch}
                spendSearchRef={spendSearchRef}
                subscriptionSearch={subscriptionSearch}
                subscriptionSearchRef={subscriptionSearchRef}
                setSelectedTab={setSelectedTab}
                setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                clientId={clientId}
                setDefaultSpendSelectedTab={setDefaultSpendSelectedTab}
                getSubPaymentLastInvoice={getSubPaymentLastInvoice}
                setDeleteID={setDeleteID}
                setDeleteMessage={setDeleteMessage}
                setDeleteType={setDeleteType}
                setPages={setPages}
                setShowDeleteModal={setShowDeleteModal}
                subscriptionViewData={subcategorySubscriptionViewData}
                getSubscriptionViewData={getSubscriptionViewData}
                setUnlinkRelinkModalData={setUnlinkRelinkModalData}
                setCategoryDeleteData={setCategoryDeleteData}
                selectedTab={selectedTab}
                subscriptionActiveFilters={subscriptionActiveFilters}
                setSubscriptionActiveFilters={setSubscriptionActiveFilters}
              />
            )}
          </>
        )}

        <div
          className={`add-edit-tax-rate-modal-wrapper ${
            showAddEditTaxRateModal ? "show" : ""
          }`}
        >
          <AddEditTaxRateModal
            show={showAddEditTaxRateModal}
            hideModal={() => {
              setShowAddEditTaxRateModal(false);
            }}
            taxRateData={taxRateData}
            updateTaxList={updateTaxList}
            deleteTaxRate={deleteTaxRate}
          />
        </div>
      </SlidingModal.Body>

      <SlidingModal.Footer className="position-sticky w-100 border-top">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            visibility: loading ? "hidden" : "visible",
            padding: "16px"
          }}
        >
          {activePage?.type === Pages.HOME && (
            <>
              <OverlayTrigger
                placement="top"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                  || categoryPageData?.isPSASpendDetected || subCategoryPageData?.isPSASpendDetected
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    {categoryPageData?.isPSASpendDetected || subCategoryPageData?.isPSASpendDetected ? (
                      "This feature is managed by automatic spend detection"
                    ) : (
                      "This feature is managed by your service provider"
                    )}
                  </Tooltip>
                }
              >
                <div
                  className={`f-500  ${
                    viewOnlyAccess("AccessState_TechnologySpend")
                      ? "greyText cursor-default"
                      : "f-grey pointer"
                  } `}
                  style={{
                    visibility: `${
                      categoryPageData?.IsDefault === true ||
                      productCategoryList?.find(
                        (item) => item?.ObjectId === categoryPageData?.ID
                      )?.IsPSACategory === true
                        ? "hidden"
                        : "visible"
                    }`,
                  }}
                  onClick={() => {
                    if (categoryPageData?.isPSASpendDetected || subCategoryPageData?.isPSASpendDetected) return;
                    if (!viewOnlyAccess("AccessState_TechnologySpend")) {
                      setDeleteID(categoryPageData?.ID);
                      setDeleteMessage(
                        "Are you sure you want to delete this category?"
                      );
                      setDeleteType("categoryOrSubcategory");
                      if (categoryPageData?.SubCategory_List?.length > 0) {
                        setLinkedCatDeleteModal(true);
                      } else {
                        setShowDeleteModal(true);
                        setCategoryDeleteData({
                          id: categoryPageData?.ID,
                          invoiceCount: categoryPageData?.InvoiceCount,
                          deleteAllSelected: false,
                          categoryName: categoryPageData?.CategoryName,
                        });
                      }
                    }
                  }}
                >
                  {viewOnlyAccess("AccessState_TechnologySpend") || (
                    categoryPageData?.isPSASpendDetected || subCategoryPageData?.isPSASpendDetected
                  ) ? (
                    <img
                      alt=""
                      src="/images/settings-v2/locker-grey.svg"
                      className="mr-2"
                    />
                  ) : (
                    <img
                      alt=""
                      className="mx-2"
                      src="/images/actioncenter/delete.svg"
                    />
                  )}
                  {deleting ? "Deleting..." : "Delete"}
                </div>
              </OverlayTrigger>
            </>
          )}
          {activePage?.type === Pages.INVOICE_DATA && (
            <div className="f-500 f-grey">
              {/* <img
                alt=""
                className="mx-2"
                src="/images/actioncenter/delete.svg"
              />
              {deleting ? "Deleting" : "Delete"} */}
            </div>
          )}

          {activePage?.type === Pages.ADD_SPEND &&
            invoicePageData?.IntegrationId === 0 && (
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div
                  className={`f-500  ${
                    viewOnlyAccess("AccessState_TechnologySpend")
                      ? "greyText cursor-default"
                      : "f-grey pointer"
                  }  ${
                    invoicePageData?.ID > 0 && invoiceEditMode === true
                      ? "visibility-visible"
                      : "visibility-hidden"
                  }  `}
                  style={{
                    visibility:
                      invoicePageData?.ID > 0 && !loading
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={() => {
                    if (!viewOnlyAccess("AccessState_TechnologySpend")) {
                      if (addSpendSelectedTab === "addBySubscription") {
                        setShowDeleteSubscriptionModal(true);
                      } else {
                        setDeleteID(invoicePageData?.ID);
                        setDeleteType("deleteInvoice");
                        setDeleteMessage(
                          "Are you sure you want to delete this Invoice?"
                        );
                        setCategoryDeleteData({
                          id: invoicePageData?.ID,
                          invoiceName: invoicePageData?.InvoiceRef,
                        });
                        setShowDeleteModal(true);
                      }
                    }
                  }}
                >
                  {viewOnlyAccess("AccessState_TechnologySpend") ? (
                    <img
                      alt=""
                      src="/images/settings-v2/locker-grey.svg"
                      className="mr-2"
                    />
                  ) : (
                    <img
                      alt=""
                      className="mx-2"
                      src="/images/actioncenter/delete.svg"
                    />
                  )}
                  {deleting ? "Deleting..." : "Delete"}
                </div>
              </OverlayTrigger>
            )}

          {activePage?.type === Pages.ADD_SPEND &&
            invoicePageData?.IntegrationId > 0 && (
              <AttackSurfaceTooltip
                top={true}
                content={`This spend has been synced from ${
                  all_integration_data[invoicePageData?.IntegrationId]
                }`}
              >
                <div className="d-flex align-items-center f-grey">
                  <img
                    src={integrationUtils.getImagePath(invoicePageData?.IntegrationId, invoicePageData?.ToolIcon)}
                    alt=""
                    className="mr-2 rounded-full"
                    width={20}
                    height={20}
                  />
                  {all_integration_data[invoicePageData?.IntegrationId]}
                </div>
              </AttackSurfaceTooltip>
            )}

          {activePage?.type === Pages.SUBCATEGORY && (
            <OverlayTrigger
              placement="bottom"
              trigger={
                viewOnlyAccess("AccessState_TechnologySpend")
                  ? ["hover", "focus"]
                  : []
              }
              delay={{ show: 200, hide: 250 }}
              overlay={
                <Tooltip id={`tooltip-1365`}>
                  This feature is managed by your service provider
                </Tooltip>
              }
            >
              <div
                className={`f-500 ${
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? "greyText cursor-default"
                    : "f-grey pointer"
                } ${
                  subCategoryPageData?.IsDefault === false
                    ? "visibility-visible"
                    : "visibility-hidden"
                }`}
                onClick={() => {
                  if (!viewOnlyAccess("AccessState_TechnologySpend")) {
                    setDeleteID(subCategoryPageData?.ID);
                    setDeleteType("categoryOrSubcategory");
                    setDeleteMessage(
                      `Deleting a subcategory will not delete any invoices or subscriptions related to this subcategory. They will be assigned to parent category instead\n\nAre you sure you want to delete this subcategory?`
                    );
                    setShowDeleteModal(true);
                    setCategoryDeleteData({
                      id: subCategoryPageData?.ID,
                      invoiceCount: subCategoryPageData?.InvoiceCount,
                      deleteAllSelected: false,
                      categoryName: subCategoryPageData?.CategoryName,
                    })
                  }
                }}
              >
                {viewOnlyAccess("AccessState_TechnologySpend") ? (
                  <img
                    alt=""
                    src="/images/settings-v2/locker-grey.svg"
                    className="mr-2"
                  />
                ) : (
                  <img
                    alt=""
                    className="mx-2"
                    src="/images/actioncenter/delete.svg"
                  />
                )}
                {deleting ? "Deleting..." : "Delete"}
              </div>
            </OverlayTrigger>
          )}

          {activePage?.type === Pages.ADD_SPEND &&
          invoicePageData?.IntegrationId > 0 &&
          invoiceEditMode === false ? (
            <div>
              {invoicePageData?.IsImported === true &&
                invoiceEditMode === false && (
                  <Button
                    className="hg-cancel-btn"
                    onClick={() => {
                      setUnlinkRelinkModalData((prev) => ({
                        ...prev,
                        show: true,
                        linkButtonText: "Unlink",
                        headerText: `${`Unlink ${
                          invoicePageData.InvoiceRef
                        } from ${
                          all_integration_data[invoicePageData?.IntegrationId]
                        }`}`,
                        bodyText: `${`Are you sure you would like to unlink this invoice ${
                          invoicePageData.InvoiceRef
                        } from ${
                          all_integration_data[invoicePageData?.IntegrationId]
                        }?`}`,
                        deleteParams: {
                          linkStatus: false,
                          objId_invoice: invoicePageData?.objId_Invoice,
                        },
                      }));
                    }}
                  >
                    <img
                      src="/images/msp/unlink-icon.svg"
                      alt=""
                      className="mr-1"
                    />{" "}
                    Unlink
                  </Button>
                )}
            </div>
          ) : activePage?.type === Pages.ADD_SPEND 
          && addSpendSelectedTab === "addByInvoice"
          && !subscriptionViewInvoiceData?.newInvoice
          && !invoicePageData?.editable ? (
            <div className="d-flex align-items-center justify-content-end">
              <Button 
                className="hg-blue-btn" 
                onClick={() => {
                  setInvoicePageData((prev) => ({
                    ...prev,
                    editable: true,
                  }));
                }}
              >
                Edit
              </Button>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-end">
              <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
                Cancel
              </Button>
              <OverlayTrigger
                placement="top"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <Button
                  className={` ${
                    viewOnlyAccess("AccessState_TechnologySpend")
                      ? "btn-disable"
                      : "hg-blue-btn"
                  } `}
                  disabled={updatingExchangeRate}
                  onClick={async () => {
                    if (activePage?.type === Pages.HOME) {
                      let errors = await checkCategroyPageErrors();

                      if (errors > 0) {
                        let _modal = document.querySelector(
                          ".technology-spend-modal"
                        );
                        let top_error =
                          _modal.getElementsByClassName("input-error")[0];
                        if (top_error) {
                          top_error.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                          });
                        }
                      } else {
                        updateCategory();
                      }
                    }
                    if (activePage?.type === Pages.INVOICE_DATA) {
                    }
                    if (activePage?.type === Pages.ADD_SPEND) {
                      let errors = await checkAddSpendPageErrors(
                        addSpendSelectedTab
                      );
  // const bodyContainerRef = document.getElementsByClassName("technology-spend-modal")?.[0];

                      if (errors > 0) {
                        let _modal = document.querySelector(
                          ".technology-spend-modal"
                        );
                        let top_error =
                          _modal.getElementsByClassName("input-error")[0];
                        if (top_error) {
                          _modal.scrollTop = top_error.offsetTop - 100;
                          top_error.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                          });
                        }
                      } else {
                        AddEditInvoice();
                      }
                    }
                    if (activePage?.type === Pages.SUBCATEGORY) {
                      let errors = await checkSubCategoryPageErrors();

                      if (errors > 0) {
                        let _modal = document.querySelector(
                          ".technology-spend-modal"
                        );
                        let top_error =
                          _modal.getElementsByClassName("input-error")[0];
                        if (top_error) {
                          top_error.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "nearest",
                          });
                        }
                      } else {
                        updateSubCategory();
                      }
                    }
                  }}
                >
                  {viewOnlyAccess("AccessState_TechnologySpend") && (
                    <img
                      alt=""
                      src="/images/settings-v2/locker-grey.svg"
                      className="mr-2"
                    />
                  )}
                  {saving ? (
                    <>
                      <WhiteLoader width={13} />
                      Saving..
                    </>
                  ) : (
                    "Save"
                  )}
                </Button>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </SlidingModal.Footer>
    
      <CustomDeleteModal
        processingText={"Please wait.."}
        show={unlinkRelinkModalData.show}
        hideModal={(data) => {
          if (data) {
            setInvoicePageData((prev) => ({
              ...prev,
              IsImported:
                data.Message.indexOf("cancel") !== -1 ? false : data.linkStatus,
              UnlinkTime: data.Message,
            }));
            setInvoiceEditMode(data.Message.indexOf("cancel") !== -1 ? false : true)
          }
          setUnlinkRelinkModalData((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        deleteButtonClass={"hg-blue-btn"}
        deleteButtonText={unlinkRelinkModalData.linkButtonText}
        deleteHeaderText={unlinkRelinkModalData.headerText}
        deleteBodyText={unlinkRelinkModalData.bodyText}
        deleteFunction={UpdatelinkingRelinking}
        // deleteFunction={(data) => {
        //   UpdatelinkingRelinking({
        //     linkStatus: data.linkStatus,
        //     objId_invoice: data.objId_invoice,
        //   });

        //   setInvoicePageData((prev) => ({
        //     ...prev,
        //     IsImported: data.linkStatus,
        //     UnlinkTime:moment().format("lll")
        //   }));
        // }}
        deleteParams={unlinkRelinkModalData?.deleteParams}
        deleteType={"linkRelinkInMspTechSpend"}
      />
      <AddSubcategoryModal
        show={!!showCreateSubcategoryModal}
        categoryData={showCreateSubcategoryModal}
        hideModal={() => setShowCreateSubcategoryModal(null)}
        parentCategoryId={categoryData?.CategoryId}
        colorList={categoryPageData?.SubCategorySpendColor_List}
        refreshData={() => {
          getCategoryDetail(categoryData?.CategoryId);
        }}
      />
      <CreateCategorySpendModal
        show={showCreateCategoryModal}
        hideModal={() => setShowCreateCategoryModal(false)}
      />
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => {
          setShowDeleteModal(false);
          setCategoryDeleteData({});
        }}
        deleteMessage={deleteMessage}
        deleteType={deleteType}
        deleteCategorySubCategory={() => {
          if (deleteType === "categoryOrSubcategory") {
            deleteCategorySubCategory(deleteID, categoryDeleteData?.deleteAllSelected);
          } else {
            deleteCategorySubCategory(deleteID);
          }
        }}
        deletePromise={deleteInvoice}
        ID={deleteID}
        {...(deleteType === "categoryOrSubcategory" && {
          confirmQuestion: `Delete ${categoryDeleteData?.categoryName}`,
          deleteBtnTitle: "Delete",
          cancelBtnTitle: "Cancel",
        })}
        {...(deleteType === "deleteInvoice" && {
          confirmQuestion: `Delete ${categoryDeleteData?.invoiceName}`,
          deleteBtnTitle: "Delete",
          cancelBtnTitle: "Cancel",
        })}
      >
        {deleteType 
        && deleteType === "categoryOrSubcategory"
        && categoryDeleteData
        && categoryDeleteData?.invoiceCount > 0 
        ? (
          <div>
            <div 
              className="d-flex align-items-center" style={{ gap: '8px' }}
            >
              <Checkbox 
                onChange={e => {
                  setCategoryDeleteData(prev => ({
                    ...prev,
                    deleteAllSelected: e.target.checked
                  }));
                }} 
                id={`delete-all-invoices-cb`} 
                className="checkbox"
                checked={categoryDeleteData?.deleteAllSelected} 
              />
              <label className="m-0 f-14 line-height-22 f-black f-500" htmlFor={`delete-all-invoices-cb`}>
                Delete all related invoices ({categoryDeleteData?.invoiceCount})
              </label>
            </div>
          </div>
        ) : null}
      </DeleteModal>
      <CustomDeleteModal
        show={linkedCatDeleteModal}
        deleteHeaderText={"Delete Confirmation"}
        hideModal={() => {
          setLinkedCatDeleteModal(false);
        }}
        deleteType={"deleteLinkedCategory"}
        deleteFunction={deleteCategorySubCategory}
        deleteParams={{ deleteId: deleteID }}
        customBody={
          <>
            <div className="f-500 my-2">
              This category is linked to other Subcategories. If you delete this
              category then these subcategories and the spend in all categories
              and subcategories will be deleted.
            </div>
            <div className="f-darkgrey mb-2">Linked Subcategories:</div>
            {categoryPageData?.SubCategory_List?.map((subCategory) => {
              return (
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <div className="f-500">
                    {" "}
                    {TrimString(subCategory?.CategoryName, 30)}
                  </div>
                  <div className="f-grey f-500">
                    {" "}
                    {TrimString(subCategory?.TotalAmount_Text, 20)}
                  </div>
                </div>
              );
            })}
          </>
        }
      />
      <CustomDeleteModal
        show={switchInvoiceTabModalData.showModal}
        // deleteHeaderText={"Are you Sure"}
        hideModal={() => {
          setSwitchInvoiceTabModalData((prev) => ({
            ...prev,
            showModal: false,
          }));
        }}
        deleteType={switchInvoiceTabModalData.deleteType}
        deleteFunction={(e) => {
          executeInvoiceSwitchingModal(e);
        }}
        deleteBodyText={"Any unsaved changes will be lost."}
        deleteButtonText={"Proceed"}
        deleteParams={switchInvoiceTabModalData.deleteParams}
        // customBody={<>
        //   <div className="f-500 my-2">
        //     This category is linked to other Subcategories. If you delete this category then these subcategories and the spend in all categories and subcategories will be deleted.
        //   </div>
        //   <div className="f-darkgrey mb-2">
        //     Linked Subcategories:
        //   </div>
        //   {
        //     categoryPageData?.SubCategory_List?.map((subCategory) => {
        //       return (
        //         <div className="d-flex align-items-center justify-content-between mb-1">
        //           <div className="f-500"> {TrimString(subCategory?.CategoryName, 30)}</div>
        //           <div className="f-grey f-500"> {TrimString(subCategory?.TotalAmount_Text, 20)}</div>
        //         </div>
        //       )
        //     })
        //   }

        // </>}
      />
      <AddSupplierModal
        _supplierName={potentialSupplierSearch}
        show={showAddSupplierModal}
        hideModal={() => setShowAddSupplierModal(false)}
        refreshData={(_supplierId) => {
          let SelectedSupplier = {
            PotentialSupplierName: potentialSupplierSearch,
            PotentialSupplierId: _supplierId,
          };
          let updated_data = {
            ...invoicePageData,
          };
          updated_data.PotentialSupplierSelected = SelectedSupplier;
          updated_data.PotentialSupplierDetail_List.unshift(SelectedSupplier);
          setInvoicePageData(updated_data);
          setPotentialSupplierSearch("");
        }}
      />

      <GenerateInvoiceModal
        show={showGenerateInvoiceModal}
        invoicesList={generatedInoiceListBySubscription}
        subscriptionJson={subscriptionJson}
        clientId={clientId}
        invoiceId={invoicePageData?.ID}
        hideModal={() => {
          setShowGenerateInvoiceModal(false);
          goBack();
          refreshData();
        }}
      />

      <DeleteTechSpendSubscription
        show={showDeleteSubscriptionModal}
        hideModal={() => {
          setShowDeleteSubscriptionModal(false);
        }}
        isSaas={isSaas}
        invoiceId={invoicePageData?.ID}
        invoicePageData={invoicePageData}
        deleteSuccess={() => {
          goBack();
          refreshData();
        }}
      />
    </SlidingModal>
  );
};

export default TechnologySpendModal;

//msp-syncing-icon.svg

function searchSpendInvoices(
  arr,
  key = "",
  searchString = "",
  IsAutomatic,
  subscriptionInvoice = false
) {
  let length_of_filtered_array = arr;
  length_of_filtered_array = arr
    ?.filter((_item) => _item["IsImported"] === IsAutomatic)
    ?.filter((item) => item[key].includes(searchString))
    ?.filter((__item) =>
      subscriptionInvoice ? __item.IsSubscription : !__item.IsSubscription
    );
  if (length_of_filtered_array?.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function handleDateForInvoice(_date) {
  if (_date == null) {
    return moment().format("DD/MM/YYYY");
  } else if (_date === "0001-01-01T00:00:00" || _date == "1970-01-01T00:00:00") {
    // return "";
    return moment().format("DD/MM/YYYY")
  } else if (_date?.split("/")?.length === 3) {
    return _date;
  } else if (moment(_date).format("MM/DD/YYYY")?.split("/")?.length > 2) {
    // let value = moment(_date).format("MM/DD/YYYY")?.split("/");
    // let month = value[0];
    // let date = value[1];
    // let year = value[2];
    // let newDate = `${date}/${month}/${year}`;
    // if (date > 12) {
    //   return newDate;
    // }
    return moment(_date).format("DD/MM/YYYY");
  } else {
    return _date;
  }
}

const NoSpendFoundUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
    </div>
  );
};
const ManualNoSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
      <div
        className={`w-fit-content f-500 mt-2 ${
          viewOnlyAccess("AccessState_TechnologySpend")
            ? "f-darkgrey cursor-default"
            : "f-blue pointer"
        }`}
        onClick={() => {
          if (!viewOnlyAccess("AccessState_TechnologySpend")) {
            onClickAdd();
          }
        }}
      >
        {viewOnlyAccess("AccessState_TechnologySpend") ? (
          <img
            alt=""
            className="lockerDarkGreyImage mr-2 mb-1"
            src="/images/settings-v2/locker-grey.svg"
          />
        ) : (
          <></>
        )}
        Add Spend
      </div>
    </div>
  );
};
const NoSubscriptionSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">
        {/* No spend found */}
        No payments yet
      </div>
      <div
        // className="w-fit-content f-500 mt-2 f-blue pointer"
        className="w-fit-content mt-2 f-darkgrey pointer"
        // onClick={onClickAdd}
      >
        {/* Add Spend */}
        There will be autogenerated payments
      </div>
    </div>
  );
};

const AutomaticNoSpendFoundUI = ({ type = "category" }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-black">No spend found</div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        No spend has been found in this <br /> {type} in your PSA
      </div>
    </div>
  );
};
const NoAutomaticGeneratedInvoiceUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-black">No payments yet</div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        There will be autogenerated payments
      </div>
    </div>
  );
};
const AutomaticSpendSyncingUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/syncing-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-grey f-500">
        Data is syncing
      </div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        Please come back later
      </div>
    </div>
  );
};

function convertToCurrencyFormat(value, currencySymbol) {
  // Check if the value is negative
  const isNegative = value < 0;

  // Convert value to absolute value
  const absoluteValue = Math.abs(value);

  // Create an Intl.NumberFormat instance for currency formatting
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format the absolute value with currency symbol and group separators
  const formattedValue = `${currencySymbol} ${formatter.format(absoluteValue)}`;

  // Prepend '-' if the value was negative
  return isNegative ? `-${formattedValue}` : formattedValue;
}

export function _DeleteInvoiceItem(_invoiceItemId) {
  return new Promise((resolve, reject) => {
    const authTokens = JSON.parse(localStorage.getItem("tokens"));

    getAPIData(`${629}`, authTokens, {
      optionEnum1: _invoiceItemId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          resolve(response);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        reject("Something went wrong");
      });
  });
}

function formatDateDDMMYYY(date) {
  // Get day, month, and year
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are zero indexed
  let year = date.getFullYear();

  // Add leading zeros if necessary
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // Return formatted date
  return `${day}/${month}/${year}`;
}

function highlightInvoiceItem(_invoiceItem, _categoryData, _subCategoryData) {
  if (_subCategoryData?.objId_Category > 0) {
    if (
      _subCategoryData?.objId_Category ===
      _invoiceItem?.SubCategory_Selected?.objId_Category
    ) {
      return true;
    } else {
      return false;
    }
  }
  if (_categoryData?.objId_Category > 0) {
    if (
      _categoryData?.objId_Category ===
      _invoiceItem?.Category_Selected?.objId_Category
    ) {
      return true;
    } else {
      return false;
    }
  }

  return false;

  
}

function getListViewFilters(kwargs) {
  let criterion_filters = [];
  if (kwargs?.filters) {
    for (let index = 0; index < kwargs?.filters?.length; index++) {
      if (
        kwargs?.filters[index]?.filterType === "supplier" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 0,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item?.PotentialSupplierId.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "date" &&
        kwargs?.filters[index]?.selectedDateRange?.startDate &&
        kwargs?.filters[index]?.selectedDateRange?.endDate
      ) {
        criterion_filters.push({
          criteriaEnum: 1,
          SearchParams: [
            kwargs?.filters[index]?.selectedDateRange?.startDate,
            kwargs?.filters[index]?.selectedDateRange?.endDate,
          ],
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "category" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 2,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.CategoryID.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "subcategory" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 3,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.CategoryID.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "amount" &&
        kwargs?.filters[index]?.toAmount &&
        kwargs?.filters[index]?.fromAmount
      ) {
        criterion_filters.push({
          criteriaEnum: 4,
          SearchParams:kwargs?.filters[index]?.toAmount? [
            kwargs?.filters[index]?.fromAmount.toString(),
            kwargs?.filters[index]?.toAmount.toString(),
          ]:[
            kwargs?.filters[index]?.fromAmount.toString(), 
          ],
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "status" &&
        kwargs?.filters[index]?.filterList?.length > 0
      ) {
        criterion_filters.push({
          criteriaEnum: 5,
          SearchParams: kwargs?.filters[index]?.filterList?.map(
            (item) => item.Id.toString()
          ),
        });
      }
      if (
        kwargs?.filters[index]?.filterType === "expires" &&
        kwargs?.filters[index]?.selectedDateRange?.startDate &&
        kwargs?.filters[index]?.selectedDateRange?.endDate
      ) {
        criterion_filters.push({
          criteriaEnum: 6,
          SearchParams: [
            kwargs?.filters[index]?.selectedDateRange?.startDate,
            kwargs?.filters[index]?.selectedDateRange?.endDate,
          ],
        });
      }
    }
  }

  return criterion_filters;
}