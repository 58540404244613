import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import "./technologySpendModal.scss";
import { useAuth } from "../../../context/Auth";
import { getAPIData, getPageDataApi } from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import moment from "moment";
import useApiActions from "../../../../hooks/api-actions.hook";
const GenerateInvoiceModal = ({
  show,
  hideModal,
  invoicesList,
  subscriptionJson,
  clientId,
  invoiceId,
}) => {
  const { authTokens } = useAuth();
  const { generateSubscription } = useApiActions();

  const [loading, setLoading] = useState(false);
  function skipGeneratingInvoices() {
    hideModal();
  }

  const onGenerateClick = () => {
    setLoading(true);
    generateSubscription({ subscriptionJson })
      .then((response) => {
        hideModal();
        CallToast("Updated successfully", false, false, "v2style");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="generate-invoice-modal"
      className=" "
      aria-labelledby="generate-invoice-modal"
      centered
      onHide={() => {}}
    >
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="f-16 f-500 mb-3 mt-1">Generate payments?</div>
          </Col>
          <Col xs={12}>
            <div className="f-black">
              There are {invoicesList?.length ?? 0} payments that could be
              generated since the start date of this subscription. Would you
              like Highground to create these for you?
            </div>
            <div className="f-12 f-500 f-darkgrey my-2">Payments</div>
            <div className="transparent-scroller"
            
            style={{
              maxHeight:"220px",
              overflowY:"auto"
            }}
            >

           
            {invoicesList?.map((item) => {
              return (
                <div className="invoice-row-summary-table bg-hover-row p-2 radius-2">
                  <div className="d-flex align-item-center">
                    <img
                      src="/images/msp/msp-spend-invoice-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    {item.Title}
                  </div>
                  <div className="d-flex justify-content-end">
                    {moment(item.InvoiceDate).format("ll")}
                  </div>
                  <div className="d-flex justify-content-end">
                    {item.Currency} {item.Amount}{" "}
                  </div>
                </div>
              );
            })}
             </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            className="cancel-btn"
            onClick={() => {
              skipGeneratingInvoices();
            }}
          >
            {loading ? "Please wait.." : "No, Skip"}
          </Button>
          <Button
            className={"hg-blue-btn"}
            disabled={loading}
            onClick={() => {
              onGenerateClick();
            }}
          >
            {/* For deleting things */}
            {loading && false ? (
              <>
                <Spinner
                  size="sm"
                  className="mr8px ml-2 mr-2"
                  animation="border"
                  variant="light"
                />
                {"Please wait.."}
              </>
            ) : (
              "Yes, Generate"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateInvoiceModal;

export const DeleteTechSpendSubscription = ({
  show,
  hideModal,
  isSaas,
  invoiceId,
  deleteSuccess,
  invoicePageData
}) => {
  const { authTokens } = useAuth();

  const [loading, setLoading] = useState(false);
  const [autoDeleteGenInvoices, setAutoDeleteGenInvoices] = useState(true)
  function deleteSubscription() {
    setLoading(true);
    return new Promise((resolve, reject) => {
      getAPIData(`${628}`, authTokens, {
        optionEnum1: invoiceId,
        optionEnum2: 0,
        IsSubscriptionInvoice: true,
        isSaas:isSaas,
        ArchiveAutoGenInvoice: autoDeleteGenInvoices
      }).then((response) => {
        setLoading(false);
        hideModal();
        deleteSuccess()
        CallToast("Updated successfully", false, false, "v2style");
      });
    });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="generate-invoice-modal"
      className=" "
      aria-labelledby="generate-invoice-modal"
      centered
      onHide={() => {}}
    >
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="f-16 f-500 mb-3 mt-1">Delete subscription</div>
          </Col>
          <Col xs={12}>
            <div className="f-black">
              Deleting a subscription will not automatically delete any payments
              that have ben generated.
              <br />
              <br />
              Are you sure you want to delete this subscription?
            </div>
           
           <div className="d-flex align-items-center mt-2">
            {invoicePageData?.total_SubscriptionInvoices > 0 ? (
              <div className="d-flex align-items-center">
                <div>
                  <Form.Group
                    controlId={`card-supplier-sxsax`}
                    className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${"radio-shadow"}`}
                  >
                    <Form.Check
                      type={"checkbox"}
                      checked={
                        autoDeleteGenInvoices
                      }
                      name={
                        `check-suppliersc ` 
                      }
                      label=""
                      disabled={false}
                      onChange={() => {}}
                      onClick={(e) => {
                        setAutoDeleteGenInvoices(!autoDeleteGenInvoices)
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="f-500 pl-3 pt-2 f-500">
                Delete all autogenerated payments ({invoicePageData?.total_SubscriptionInvoices})
                </div>
              </div>
            ) : null}
           </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            className="cancel-btn"
            onClick={() => {
             hideModal()
            }}
          >
           Cancel
          </Button>
          <Button
            className={"hg-red-btn"}
            disabled={loading}
            onClick={() => {
              deleteSubscription()
            }}
          >
            {/* For deleting things */}
            {loading  ? (
              <>
                <Spinner
                  size="sm"
                  className="mr8px ml-2 mr-2"
                  animation="border"
                  variant="light"
                />
                {"Please wait.."}
              </>
            ) : (
              "Delete"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
