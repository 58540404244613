import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./kpiModal.scss";
import { Dropdown } from "react-bootstrap";



const KPIpopup = (  ) => {
  const data = [
    {
      image: "/images/attack-surface/verified.svg",
      title: "Verified",
      subtitle:
        "KPIs are verified when they are powered from your own data by Integrating your security tools.",
    },
    {
      image: "/images/attack-surface/part-verified.svg",
      title: "Part-Verified or Attested",
      subtitle:
        "KPIs are Part-Verified when they are powered by a combination of your own data (by integrating your tools) and self-defined data in the attack surface module. KPIs are Attested where you have claimed to have measures in place but have provided no evidence.",
    },
    {
      image: "/images/attack-surface/unverified.svg",
      title: "Unverified or Attested Missing ",
      subtitle:
        "KPIs are Unverified when they are powered exclusively from self-defined data in the attack surface module. KPIs are Attested Missing where you have confirmed that no measure is in place.",
    },
    {
      image: "/images/attack-surface/initial-verified.svg",
      title: "No Data",
      subtitle:
        "There is no data in Highground to power this KPI.",
    },
  ];
  const history = useHistory();
  return (
    <div className="kpi-modal " id="kpi-modal">
      <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
        <div className="f-grey">
          <img
            alt=""
            src="/images/attack-surface/info-icon.svg"
            className="mr-2 pointer"
          />
          KPI Attestation
        </div>
        <div className="d-flex align-items-center pointer">
          <img
            alt=""
            src="/images/attack-surface/expand-icon.svg"
            className="mx-2"
          />
          <img
            alt=""
            src="/images/attack-surface/cross-icon.svg"
            className="mx-2 mr-2"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              let modal = document.getElementsByClassName("kpi-modal");
              for (let i = 0; i < modal.length; i++) {
                modal[i].style.display = "none";
              }

              // modal.style.display = "none";
            }}
          />
        </div>
      </header>
      <div className="bg-white">
        <section className="px-3 bg-white">
          {data?.map((item, index) => {
            return (
              <div key={index} className="d-flex align-items-start my-3">
                <img alt="" src={item?.image} className="mr-2" />
                <div>
                  <p className="font-weight-bold m-0">{item?.title}</p>
                  <p
                    className="f-black"
                    style={{
                      whiteSpace: "initial",
                    }}
                  >
                    {item?.subtitle}
                  </p>
                </div>
              </div>
            );
          })}
        </section>
        <hr className="m-0 dividerx" />
        <footer className="p-2 px-4 bg-white">
          <div
            className="d-flex align-items-center pointer mt-2"
            onClick={(e) => {
              e.stopPropagation();
              history.push("/attack_surface");
            }}
          >
            <img
              alt=""
              src="/images/attack-surface/attackSurfaceIcon.svg"
              className="mr-2"
            />
            <div className="f-blue">Open Attack Surface</div>
          </div>
          <div
            className="d-flex align-items-center mt-2 pointer"
            onClick={(e) => {
              e.stopPropagation();
              window.open("https://kb.highground.io");
            }}
          >
            <img
              alt=""
              src="/images/settings-v2/knowledgebase.svg"
              className="mr-2"
            />
            <div className="f-grey">Learn more in our Knowlege base</div>
            <img
              alt=""
              src="/images/attack-surface/goto.svg"
              className="ml-2"
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default KPIpopup;


















export const AttestationPopUp = ({
  children, 
  title,
  openBy = "click",
}) => {
  const data = [
    {
      image: "/images/attack-surface/verified.svg",
      title: "Verified",
      subtitle:
        "KPIs are verified when they are powered from your own data by Integrating your security tools.",
    },
    {
      image: "/images/attack-surface/part-verified.svg",
      title: "Part-Verified or Attested",
      subtitle:
        "KPIs are Part-Verified when they are powered by a combination of your own data (by integrating your tools) and self-defined data in the attack surface module. KPIs are Attested where you have claimed to have measures in place but have provided no evidence.",
    },
    {
      image: "/images/attack-surface/unverified.svg",
      title: "Unverified or Attested Missing ",
      subtitle:
        "KPIs are Unverified when they are powered exclusively from self-defined data in the attack surface module. KPIs are Attested Missing where you have confirmed that no measure is in place.",
    },
    {
      image: "/images/attack-surface/initial-verified.svg",
      title: "No Data",
      subtitle:
        "There is no data in Highground to power this KPI.",
    },
  ];
  const [showDropDown, setShowDropDown] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
   

  return (
    <Dropdown
      className="transparennt-wrapper-dropdown"
      show={showDropDown}
      // onToggle={(e) => {
      //   setShowDropDown(e);
      // }}
      onMouseLeave={() => {
        setShowDropDown(false);
        clearTimeout(timeoutId)
      }}
    >
      <Dropdown.Toggle
        onMouseEnter={(e) => {
          // e.target.click();
          // e.stopPropagation();
          
          
          let _timeoutId = setTimeout(() => {
            
            setShowDropDown(true);
             
          }, 400);
          setTimeoutId(_timeoutId);
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="attestation-modal p-0 w-max-content">
        <div className="  ">
          <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
            <div className="f-grey">
              <img
                alt=""
                src="/images/attack-surface/info-icon.svg"
                className="mr-2 pointer"
              />
              {title ?? "Data Attestation States"}
            </div>
            <div className="d-flex align-items-center pointer">
              <img
                alt=""
                src="/images/attack-surface/expand-icon.svg"
                className="mx-2"
              />
              <img
                alt=""
                src="/images/attack-surface/cross-icon.svg"
                className="mx-2 mr-2"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowDropDown(false);
                }}
              />
            </div>
          </header>
          <div className="bg-white">
            <section className="px-3 bg-white">
              {data?.map((item, index) => {
                return (
                  <div key={index} className="d-flex align-items-start my-3">
                    <img alt="" src={item?.image} className="mr-2" />
                    <div>
                      <p className="font-weight-bold m-0">{item?.title}</p>
                      <p
                        className="f-black"
                        style={{
                          whiteSpace: "initial",
                        }}
                      >
                        {item?.subtitle}
                      </p>
                    </div>
                  </div>
                );
              })}
            </section>
            <hr className="m-0 dividerx" />
            <footer className="pb-2 px-4 bg-white">
              <div
                className="d-flex align-items-center mt-2 pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://kb.highground.io");
                }}
              >
                <img
                  alt=""
                  src="/images/settings-v2/knowledgebase.svg"
                  className="mr-2"
                />
                <div className="f-grey">Learn more in our Knowlege base</div>
                <img
                  alt=""
                  src="/images/attack-surface/goto.svg"
                  className="ml-2"
                />
              </div>
            </footer>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export const HGPopUp = ({
  children,
  content,
  maxWidth,
  title,
  openBy = "click",
  darkMode
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
  return (
    <Dropdown
      className="transparennt-wrapper-dropdown"
      onMouseLeave={() => {
        setShowDropDown(false);
        clearTimeout(timeoutId)
      }}
      show={showDropDown}
      // onToggle={(e) => {
      //   setShowDropDown(e);
      // }}
    >
      <Dropdown.Toggle
        style={{ maxWidth: "100%" }}
        onMouseEnter={(e) => {
          // e.target.click();
          // e.stopPropagation();
          // setShowDropDown(true);
          let _timeoutId = setTimeout(() => {
            
            setShowDropDown(true);
             
          }, 400);
          setTimeoutId(_timeoutId);
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="attestation-modal p-0 w-max-content"
        style={{ maxWidth: maxWidth ? maxWidth : "250px" ,
      
      background:darkMode? "#0C0D11" :undefined
      
      }}
      >
        <div className="  ">{content}</div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
