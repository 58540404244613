import React, { useState } from "react";
import './searchFilterComponent.scss';
import { Dropdown } from "react-bootstrap";

export const HeaderDropdown = ({
    headerName = '',
    headerKey = '',
    sortData = {},
    filterList = [],
    sortOrder = 1,
    childFilterItem,
    showHideButton = true,
    onSortClick = () => { },
    updatedHeaderList = () => { }
}) => {
    const [isFilterView, setIsFilterView] = useState(false);

    return (
        <div>
            {
                headerKey !== '' ?
                    <Dropdown className="transparennt-wrapper-dropdown">
                        <Dropdown.Toggle>
                            <div className="f-500 f-12 f-black pointer">
                                {headerName}
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-side">
                            <div className="px-2">
                                <div className="f-400 f-12 f-darkgrey">{headerName}</div>
                                {
                                    isFilterView ? <div>
                                        <div className="d-flex align-items-center pointer"
                                            onClick={() => {
                                                setIsFilterView(false);
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/images/supply-chain/chevron-left.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Filters</div>
                                        </div>
                                        {
                                            filterList?.length > 0 ? <div>
                                                {
                                                    filterList?.map((filterItem) => {
                                                        return (
                                                            childFilterItem ??
                                                            <div className="d-flex align-items-center bg-hover-grey py-1 pointer">
                                                                <img
                                                                    alt=""
                                                                    // height={14}
                                                                    src={filterItem?.img}
                                                                    className="mr-2"
                                                                />
                                                                <div className="f-400 f-14 f-grey">
                                                                    {filterItem?.text}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : <div className="f-400 f-13 f-darkgrey py-1">
                                                No filters yet
                                            </div>
                                        }
                                    </div> : <div>
                                        <div className="d-flex align-items-center bg-hover-grey pointer"
                                            style={{
                                                paddingTop: '6px',
                                                paddingBottom: '6px'
                                            }}
                                            onClick={(e) => {
                                                if (sortData.sortOrder === 1) {
                                                    onSortClick();
                                                }
                                                e.target
                                                    .closest(".transparennt-wrapper-dropdown")
                                                    .click();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                width={12}
                                                src="/images/actioncenter/arrowup.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Sort ascending</div>
                                        </div>
                                        <div className="d-flex align-items-center bg-hover-grey pointer"
                                            style={{
                                                paddingTop: '6px',
                                                paddingBottom: '6px'
                                            }}
                                            onClick={(e) => {
                                                if (sortData.sortOrder === 0) {
                                                    onSortClick();
                                                }
                                                e.target
                                                    .closest(".transparennt-wrapper-dropdown")
                                                    .click();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                width={12}
                                                src="/images/actioncenter/arrowdown.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Sort descending</div>
                                        </div>
                                        {
                                            filterList?.length > 0 &&
                                            <div className="d-flex align-items-center bg-hover-grey pointer"
                                                style={{
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px'
                                                }}
                                                onClick={() => {
                                                    setIsFilterView(true);
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    width={12}
                                                    src="/images/filter-icon.svg"
                                                    className="mr-2"
                                                />
                                                <div className="f-400 f-14 f-grey">Filter</div>
                                            </div>
                                        }
                                        {
                                            showHideButton &&
                                            <div className="d-flex align-items-center bg-hover-grey pointer border-top"
                                                style={{
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px'
                                                }}
                                                onClick={(e) => {
                                                    updatedHeaderList();
                                                    e.target
                                                        .closest(".transparennt-wrapper-dropdown")
                                                        .click();
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    width={12}
                                                    src="/images/attack-surface/hidden-icon.svg"
                                                    className="mr-2"
                                                />
                                                <div className="f-400 f-14 f-grey">Hide column</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown> : <Dropdown className="transparennt-wrapper-dropdown">
                        <Dropdown.Toggle>
                            <div className="f-500 f-12 f-black pointer">
                                {headerName}
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-side">
                            <div className="px-2">
                                <div className="f-400 f-12 f-darkgrey">{headerName}</div>
                                {
                                    isFilterView ? <div>
                                        <div className="d-flex align-items-center pointer"
                                            onClick={() => {
                                                setIsFilterView(false);
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src="/images/supply-chain/chevron-left.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Filters</div>
                                        </div>
                                        {
                                            childFilterItem ?? <div>
                                                {
                                                    filterList?.length > 0 ? <div>
                                                        {
                                                            filterList?.map((filterItem) => {
                                                                return (
                                                                    <div className="d-flex align-items-center bg-hover-grey py-1 pointer">
                                                                        <img
                                                                            alt=""
                                                                            // height={14}
                                                                            src={filterItem?.img}
                                                                            className="mr-2"
                                                                        />
                                                                        <div className="f-400 f-14 f-grey">
                                                                            {filterItem?.text}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> : <div className="f-400 f-13 f-darkgrey py-1">
                                                        No filters yet
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div> : <div>
                                        <div className="d-flex align-items-center bg-hover-grey pointer"
                                            style={{
                                                paddingTop: '6px',
                                                paddingBottom: '6px'
                                            }}
                                            onClick={(e) => {
                                                if (sortOrder === 1) {
                                                    onSortClick();
                                                }
                                                e.target
                                                    .closest(".transparennt-wrapper-dropdown")
                                                    .click();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                width={12}
                                                src="/images/actioncenter/arrowup.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Sort ascending</div>
                                        </div>
                                        <div className="d-flex align-items-center bg-hover-grey pointer"
                                            style={{
                                                paddingTop: '6px',
                                                paddingBottom: '6px'
                                            }}
                                            onClick={(e) => {
                                                if (sortOrder === 0) {
                                                    onSortClick();
                                                }
                                                e.target
                                                    .closest(".transparennt-wrapper-dropdown")
                                                    .click();
                                            }}
                                        >
                                            <img
                                                alt=""
                                                width={12}
                                                src="/images/actioncenter/arrowdown.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-14 f-grey">Sort descending</div>
                                        </div>
                                        {
                                            filterList?.length > 0 &&
                                            <div className="d-flex align-items-center bg-hover-grey pointer"
                                                style={{
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px'
                                                }}
                                                onClick={() => {
                                                    setIsFilterView(true);
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    width={12}
                                                    src="/images/filter-icon.svg"
                                                    className="mr-2"
                                                />
                                                <div className="f-400 f-14 f-grey">Filter</div>
                                            </div>
                                        }
                                        {
                                            showHideButton &&
                                            <div className="d-flex align-items-center bg-hover-grey pointer border-top"
                                                style={{
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px'
                                                }}
                                                onClick={(e) => {
                                                    updatedHeaderList();
                                                    e.target
                                                        .closest(".transparennt-wrapper-dropdown")
                                                        .click();
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    width={12}
                                                    src="/images/attack-surface/hidden-icon.svg"
                                                    className="mr-2"
                                                />
                                                <div className="f-400 f-14 f-grey">Hide column</div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
            }
        </div>
    );
};

export default HeaderDropdown;
