import React, { useEffect, useMemo, useRef, useState } from "react";
import "./mspClientPriceCalculator.scss";
import { Row, Col, Button, Tab, Spinner, Nav, Modal, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomDeleteModal } from "../../DeleteModal/DeleteModal";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { MspSingleTagUI } from "../integrations/mspIntegrationdetailScreen/MspIntegrationCard";
import { TrimString } from "../../../Utils/StringTrimmer";
import CyberScoreGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberScoreGauge";
import CyberComplianceGauge from "../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import MspClientPriceCalculatorRadarChart from "./MspClientPriceCalculatorRadarChart";
import ContentLoader from "react-content-loader";
import { IndependentGetAPIData, getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { GreyLoader } from "../../Theme/APILoaders";
import Loader from "../../Common/loader";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MspScoreModal, { PackageToolTip, QuestionChip } from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import { services_enums_selected, services_titles } from "../mspSell/mspPriceCalculator";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
import CyberGroupModal from "../../AttackSurface/CyberGroupModal";
import ExploreMappingModal from "../../SecurityAssessments/ExploreMappingModal";
import { getAccessStateValue, HGHighlightText, viewOnlyAccess } from "../../App/App";
import SendPackageModal from "./SendPackageModal";
import PrintPackageModal from "./PrintPackageModal";
import { getMenuItemId } from "../../SideNav/SideNav";
import { NoAccessUI } from "../../App/App";

import MspFeaturesModal from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspFeaturesModal";
import SourcesModal from "./SourcesModal";
import MapCurrentSpendModal from "./MapCurrentSpendModal";
import UpdateCyberKPIModal from "./UpdateCyberKPIModal";
import { IncreaseDecreaseNumbersComponentAlwaysHighlight } from "../mspSell/mspPriceCalculator/MspAddEditServiceModal";
import MyRadarChart from "../../chartjs/my-radar.chart";
import ExportManagedSecurityPackageModal from "./exportManagedSecurityPackage/ExportManagedSecurityPackageModal";
import InfoModal from "./InfoModal";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import MspClientPriceCalculatorRadarChartMerge from "./MspClientPriceCalculatorRadarChartMerge";
import MspComponents from "../mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspComponents";
import useUserActions from "../../../hooks/actions/user.actions";
import { CLIENT_GETTING_STARTED_TYPE } from "../../../constants/user.constants";
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import { VisibilityType } from "../../../constants/filter.constants";

const MspClientPriceCalculator = ({ isMspClientView, setActivePackageName = () => { }, setActivePackageId = () => { } }) => {
  const { authTokens } = useAuth();
  const { state } = useLocation();
  const [activePackageId] = useState(state?.activePackageId ?? 0);
  const clientId = window.location.pathname?.split("/")?.[4] ?? -1;
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [openedPackagesIds, setOpenedPackagesIds] = useState([]);
  // const [taxList, setTaxList] = useState([]);
  // const [selectedTax, setSelectedTax] = useState();
  const [showCyberGroupModal, setShowCyberGroupModal] = useState(false);
  const [userSeatingsLoader, setUserSeatingsLoader] = useState(false);
  const [switchVal, setSwitchVal] = useState(false);
  const [switchIncludedTaxVal, setSwitchIncludedTaxVal] = useState(false);
  console.log('activePackageId ---> ', activePackageId)

  const [showMapCurrentSpendModal, setShowMapCurrentSpendModal] = useState(false);
  const [mapCurrentSpendPackageId, setMapCurrentSpendPackageId] = useState(0);
  const [mapCurrentSpendPackagePeriod, setMapCurrentSpendPackagePeriod] = useState(0);
  const [mapCurrentSpendPackagePrice, setMapCurrentSpendPackagePrice] = useState(0);
  const [mapCurrentSpendPackagePriceText, setMapCurrentSpendPackagePriceText] = useState("");
  const [showExportManagedSecurityPackageModal, setShowExportManagedSecurityPackageModal] = useState(false);
  const [exportManagedSecurityPackageId, setExportManagedSecurityPackageId] = useState(0);
  const { updateGettingStarted } = useUserActions();
  const [packagesLoading, setPackagesLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setPackagesLoading(true);
    getPageData();
    getPackagesPageData()
  }, []);

  const graphColors = [
    "#008FD4",
    "#5244B3",
    "#40D88E",
    "#F36060",
    "#DF6844",
    "#99D2EE",
    "#BAB4E1",
    "#B3EFD2",
    "#FABFBF",
    "#F2C3B4",
  ];

  const chartData = {
    data: [30, 5, 34, 20, 15],
    pointRadius: [0, 0, 0, 0, 0],
    datalabels: {
      display: false,
    },
    backgroundColor: `${/*"#FFAC2C"*/'#008FD4'}`,
    borderColor: `${/*"#FFAC2C"*/'#008FD4'}`,
    pointBackgroundColor: /*"#FFAC2C"*/'#008FD4',
    borderWidth: 1,
    pointLabelFontSize: 0,
    scaleShowLabels: false,
    label: "Current Posture",
  };
  const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState([]);
  const [defaultUserDeviceDetailsStartData, setDefaultUserDeviceDetailsStartData] = useState([]);
  const [priceCalculatorState, setPriceCalculatorState] = useState({
    activeTab: 0,
    activeChart: 0,
    mergedStatusArray: [false],
    clickOnLastTab: false,
    tabsData: [
      {
        circleColour: /*"#FFAC2C"*/'#008FD4',
        Name: "Client current posture ",
        data: { ...chartData, data: randomChartData() },
      },
    ],
  });
  const [isSmthOverriden, setIsSmthOverriden] = useState(false);


  const menuItemId = getMenuItemId('/' + window.location.pathname.split('/')[5])
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;
  const ownClientID = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.objId_Client : null;

  function activateChartIndex(_index) {
    setPriceCalculatorState((prev) => ({ ...prev, activeChart: _index }));
  }

  function AddScenario(_packageData, key) {
    let updated_data = { ...priceCalculatorState };
    updated_data.mergedStatusArray.push(false)
    if (!key) {
      updated_data.tabsData = [
        ...updated_data.tabsData,
        {
          ..._packageData,
          circleColour:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          Name: `${_packageData?.ScenarioHeader?.Name}`,
          data: {
            ...chartData,
            data: [
              _packageData?.ScenarioHeader?.InspectScore,
              _packageData?.ScenarioHeader?.ProtectScore,
              _packageData?.ScenarioHeader?.DetectScore,
              _packageData?.ScenarioHeader?.RecoverScore,
              _packageData?.ScenarioHeader?.RespondScore,
            ],
            backgroundColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
            borderColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
            pointBackgroundColor:
              graphColors[
              priceCalculatorState.tabsData.length % graphColors.length
              ],
          },
        },
      ];
      updated_data.clickOnLastTab = true;
      updated_data.loadingScenarioID = 0;

      updated_data.newScenarioCreating = false;
      updated_data.scenarioLoadingText = "";
      updated_data.loadingScenarioID = 0;
    } else {
      updated_data.tabsData[key] = {
        ..._packageData,
        circleColour: priceCalculatorState?.tabsData[key]?.circleColour,
        Name: `${_packageData.ScenarioHeader.Name}`,
        data: {
          ...chartData,
          data: [
            _packageData.ScenarioHeader.InspectScore,
            _packageData.ScenarioHeader.ProtectScore,
            _packageData.ScenarioHeader.DetectScore,
            _packageData.ScenarioHeader.RecoverScore,
            _packageData.ScenarioHeader.RespondScore,
          ],
          backgroundColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          borderColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
          pointBackgroundColor:
            graphColors[
            priceCalculatorState.tabsData.length % graphColors.length
            ],
        },
      };
    }
    setPriceCalculatorState(updated_data);
  }

  useEffect(() => {
    if (priceCalculatorState.clickOnLastTab) {
      let updated_data = { ...priceCalculatorState };
      let nav_header = document.getElementById("navHeader");

      let all_links = nav_header.getElementsByClassName("navlinks");

      all_links[all_links.length - 1].click();

      updated_data.activeTab = all_links.length - 1;
      updated_data.activeChart = all_links.length - 1;

      updated_data.clickOnLastTab = false;
      setPriceCalculatorState(updated_data);
    }
  }, [priceCalculatorState]);

  const [showCreateCustomPackageModal, setShowCreateCustomPackageModal] =
    useState(false);
  function removePackage(_key) {
    let updated_data = { ...priceCalculatorState };
    updated_data.mergedStatusArray.splice(_key, 1)
    let nav_header = document.getElementById("navHeader");
    let all_links = nav_header.getElementsByClassName("navlinks");
    if (_key <= priceCalculatorState.activeTab) {
      updated_data.activeTab = _key - 1;
      updated_data.activeChart = _key - 1;
      all_links[_key - 1].click();
    }
    updated_data.tabsData.splice(_key, 1);
    const updatedOpenedPackagesIds = Object.values(updated_data?.tabsData)
      .map(
        (item) => item?.ScenarioHeader && item?.ScenarioHeader?.objId_Scenario
      )
      ?.filter((id) => id !== undefined);
    setPriceCalculatorState(updated_data);
    setOpenedPackagesIds(updatedOpenedPackagesIds);
  }

  function randomChartData() {
    return [
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
      Math.floor(Math.random() * 36),
    ];
  }

  const ACTIVE_CHART = useMemo(
    () => priceCalculatorState.activeChart,
    [priceCalculatorState.activeChart]
  );
  // console.log('ACTIVE_CHART ---------------------------', ACTIVE_CHART)
  const TABS_DATASETS = useMemo(
    () => priceCalculatorState.tabsData.map((item) => item.data),
    [priceCalculatorState.tabsData.length]
  );
  // console.log('TABS_DATASETS ------------- ', TABS_DATASETS)

  function setCurrentPostureData(_data) {
    let updated_data = { ...priceCalculatorState };
    updated_data.tabsData[0].data.data = _data;
    if (isSmthOverriden) {
      updated_data.activeChart = 0;
      updated_data.activeTab = 0;
    }
    setPriceCalculatorState(updated_data);
  }

  function setCurrentPostureGraphData(
    _data = { CyberCompliance: 0, CyberScore: 0 }
  ) {
    let updated_data = { ...priceCalculatorState };
    updated_data.tabsData[0].ScenarioHeader = {
      CyberCompliance: _data.CyberCompliance,
      CyberScore: _data.CyberScore,
    };
    if (isSmthOverriden) {
      updated_data.activeChart = 0;
      updated_data.activeTab = 0;
    }
    setPriceCalculatorState(updated_data);
  }

  // API Implementation
  async function getPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(647, authTokens, {
        optionEnum1: clientId,
      })
        .then((response) => {
          setCurrentPostureData([
            response?.CurrentPosture?.Identity ?? response?.CurrentPosture?.Identify ?? 0,
            response?.CurrentPosture?.Protect ?? 0,
            response?.CurrentPosture?.Detect ?? 0,
            response?.CurrentPosture?.Respond ?? 0,
            response?.CurrentPosture?.Recover ?? 0,
          ]);
          setCurrentPostureGraphData({
            CyberCompliance: response?.CurrentPosture?.CyberCompliance ?? 0,
            CyberScore: response?.CurrentPosture?.CyberScore ?? 0,
          });
          // setPageData(response);
          setDefaultUserDeviceDetails(response?.DefaultPackageAsset_List ?? []);
          setDefaultUserDeviceDetailsStartData(response?.DefaultPackageAsset_List ?? []);
          setLoading(false);
          setIsSmthOverriden(false);
          resolve(response);
          setUserSeatingsLoader(false);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  async function getPackagesPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(1061, authTokens, {
        optionEnum1: clientId,
      })
        .then((response) => {
          // setCurrentPostureData([
          //   response?.CurrentPosture?.Identity ?? response?.CurrentPosture?.Identify ?? 0,
          //   response?.CurrentPosture?.Protect ?? 0,
          //   response?.CurrentPosture?.Detect ?? 0,
          //   response?.CurrentPosture?.Respond ?? 0,
          //   response?.CurrentPosture?.Recover ?? 0,
          // ]);
          // setCurrentPostureGraphData({
          //   CyberCompliance: response?.CurrentPosture?.CyberCompliance ?? 0,
          //   CyberScore: response?.CurrentPosture?.CyberScore ?? 0,
          // });
          setPageData(response ?? {});
          // setDefaultUserDeviceDetails(response?.DefaultPackageAsset_List ?? []);
          // setDefaultUserDeviceDetailsStartData(response?.DefaultPackageAsset_List ?? []);
          setPackagesLoading(false);
          setLoading(false);
          setIsSmthOverriden(false);
          resolve(response);
          setUserSeatingsLoader(false);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  function updateQuantityOfAssets(_userDeviceDetails) {
    setUserSeatingsLoader(true);

    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateSnapToUnitAssetCounts", {
        DefaultPackageAsset_List: JSON.stringify(_userDeviceDetails),
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        UpdateTarget: 3,
        objId_Customer: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    // return new Promise((resolve, reject) => {
    //   postData(authTokens, "UpdateUserSettingsAssetCounts", {
    //     NoOfDomains: _userDeviceDetails.NoOfDomains,
    //     NoOfWorkstation: _userDeviceDetails.NoOfWorkstation,
    //     NoOfUsers: _userDeviceDetails.NoOfUsers,
    //     NoOfServers: _userDeviceDetails.NoOfServices,
    //     NoOfMobileDevices: _userDeviceDetails.NoOfMobileDevices,
    //     accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    //     UpdateTarget: 3,
    //     NoOfRouters: _userDeviceDetails.NoOfRouters,
    //     NoOfNetworkDevices: _userDeviceDetails.NoOfNetworkDevices,
    //     objId_Customer: clientId,
    //   })
    //     .then((response) => {
    //       resolve(response);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // });
  }

  const [packageDetails, setPackageDetails] = useState([]);
  const [discount, setDiscount] = useState(0.00)
  const [contributionValue, setContributionValue] = useState(0);

  const [componentsData, setComponentsData] = useState([]);
  async function getPackageDetails(_packageId = 3955510, fromUpd = false) {
    setPriceCalculatorState({
      ...priceCalculatorState,
      loadingScenarioID: _packageId,
      newScenarioCreating: priceCalculatorState?.tabsData?.find(
        (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
      )
        ? false
        : true,
      scenarioLoadingText: "Loading...",
    });

    await getAPIData(619, authTokens, {
      optionEnum1: _packageId/*9841656*/,
      optionEnum2: clientId/*5803387*/,
    })
      .then((response) => {
        let packageData = interpolatePackageResponse(response);
        if (!fromUpd) {
          setDiscount(response?.MSPPackagePrice?.Discount);
        }
        packageData.MSPPackagePrice.PackagePerAsset_List.forEach(asset => {
          asset.UnitSell = Number(asset?.UnitSell)?.toFixed(2);
        });

        if (
          priceCalculatorState?.tabsData?.find(
            (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
          )
        ) {
          const key = priceCalculatorState?.tabsData?.findIndex(
            (obj) => obj?.ScenarioHeader?.objId_Scenario === _packageId
          );
          AddScenario(packageData, key);
        } else {
          AddScenario(packageData);
        }

        setPackageDetails(response);

        setComponentsData(
          response?.Nist_All?.flatMap((obj) =>
            Array.isArray(obj?.ScoreContributionSelected_List)
              ? obj.ScoreContributionSelected_List.filter(item => item?.ContributionName != null)
              : []
          ).reduce((acc, current) => {
            console.log('acc', acc)
            const existingItem = acc.find(item => item.ContributionName === current.ContributionName);

            if (existingItem) {
              existingItem.List_ScoreProducts = [
                ...existingItem.List_ScoreProducts,
                ...current.List_ScoreProducts.filter(
                  product => !existingItem.List_ScoreProducts.some(
                    existingProduct => existingProduct.objId_Product === product.objId_Product
                  )
                )
              ];
            } else {
              acc.push(current);
            }

            return acc;
          }, [])
        );
        // if (resetType == '') {
        // } else {
        //   // console.log('packageDetails - ', packageDetails)
        //   if (resetType == 'price') {
        //     console.log('here')
        //     console.log('resetedData - ', resetedData)
        //     let updData = response;
        //     updData = {
        //       ...updData,
        //       MSPPackagePrice: {
        //         ...updData?.MSPPackagePrice,
        //         SellOverridden: resetedData?.SellOverridden,
        //         IsSellEddited: resetedData?.IsSellEddited
        //       }
        //     }
        //     console.log('updData --- ', updData)
        //     setPackageDetails(updData);
        //   }
        // }

        console.log('packageDetails', packageDetails)

        if (response?.MSPPackagePrice?.PackageMapCurrentSpend_List && response?.MSPPackagePrice?.PackageMapCurrentSpend_List != null) {
          const totalSelectedPrice = response?.MSPPackagePrice?.PackageMapCurrentSpend_List?.reduce((sum, item) => {
            if (item?.IsSelected) {
              if (response?.MSPPackagePrice?.BillingPeriod_Text == item?.Period_Text) {
                console.log('same')
                return sum + (item?.Price * item?.Quantity);
              } else {
                if (response?.MSPPackagePrice?.BillingPeriod_Text == 'Monthly') { //Monthly
                  console.log('Monthly')
                  console.log('item ---- > ', item)
                  if (item?.Period == 1) { //Quarterly to Monthly
                    console.log('1')
                    return sum + ((item?.Price / 3) * item?.Quantity);
                  }
                  if (item?.Period == 2) { //Bi Annually to Monthly
                    console.log('2')
                    return sum + ((item?.Price / 6) * item?.Quantity);
                  }
                  if (item?.Period == 3) { //Annually to Monthly
                    console.log('3')
                    return sum + ((item?.Price / 12) * item?.Quantity);
                  }
                }

                if (response?.MSPPackagePrice?.BillingPeriod_Text == 'Quarterly') { //Quarterly
                  console.log('Quarterly')
                  if (item?.Period == 0) { //Monthly to Quarterly
                    return sum + ((item?.Price * 3) * item?.Quantity);
                  }
                  if (item?.Period == 2) { //Bi Annually to Quarterly
                    return sum + ((item?.Price / 2) * item?.Quantity);
                  }
                  if (item?.Period == 3) { //Annually to Quarterly
                    return sum + ((item?.Price / 4) * item?.Quantity);
                  }
                }

                if (response?.MSPPackagePrice?.BillingPeriod_Text == 'Bi Annually') { //Bi Annually
                  console.log('Bi Annually')
                  if (item?.Period == 0) { //Monthly to Bi Annually
                    return sum + ((item?.Price * 6) * item?.Quantity);
                  }
                  if (item?.Period == 1) { //Quarterly to Bi Annually
                    return sum + ((item?.Price * 2) * item?.Quantity);
                  }
                  if (item?.Period == 3) { //Annually to Bi Annually
                    return sum + ((item?.Price / 2) * item?.Quantity);
                  }
                }

                if (response?.MSPPackagePrice?.BillingPeriod_Text == 'Annually') { //Annually
                  console.log('Annually')
                  if (item?.Period == 0) { //Monthly to Annually
                    return sum + ((item?.Price * 12) * item?.Quantity);
                  }
                  if (item?.Period == 1) { //Quarterly to Annually
                    return sum + ((item?.Price * 4) * item?.Quantity);
                  }
                  if (item?.Period == 2) { //Bi Annually to Annually
                    return sum + ((item?.Price * 2) * item?.Quantity);
                  }
                }
              }
            }
            return sum;
          }, 0);

          setContributionValue(totalSelectedPrice);
        } else {
          setContributionValue(0.00);
        }

        if (fromUpd) {
          setContribLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPriceCalculatorState({
          ...priceCalculatorState,
          loadingScenarioID: 0,
        });
      });
  }

  const [securityCapabilitiesData, setSecurityCapabilitiesData] = useState({});
  console.log('securityCapabilitiesData', securityCapabilitiesData)
  console.log('Object.keys(securityCapabilitiesData).length == 0', Object.keys(securityCapabilitiesData).length == 0)
  const [securityCapabilitiesLoading, setSecurityCapabilitiesLoading] = useState(false);
  async function getSecurityCapabilities(objId) {
    setSecurityCapabilitiesLoading(true);
    return new Promise((resolve, reject) => {
      getAPIData(1056, authTokens, {
        optionEnum1: objId,
        optionEnum2: clientId ?? 0,
      })
        .then((response) => {
          console.log('response', response)
          resolve(response);
          setSecurityCapabilitiesData(response);
          setSecurityCapabilitiesLoading(false);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
          setSecurityCapabilitiesLoading(false);
        });
    });
  }

  const [inUseData, setInUseData] = useState({});
  async function checkPackageIfInUse(_packageId) {
    await getAPIData(998, authTokens, {
      optionEnum1: _packageId/*9841656*/,
      optionEnum2: clientId/*5803410*/,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          if (response?.TotalClientCount > 0) {
            setInUseData(response);
            setShowInfoModal(true);
          } else {
            setDeleteModalConfig((prev) => ({
              ...prev,
              show: true,
              confirmQuestion: `Delete Package`,
              deleteMessage: `Are you sure you want to delete this Package? `,
              deleteType: "mspSell",
              pageEnum: 621,
              deleteId: _packageId,
              deleteFunction: deleteData,
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setPriceCalculatorState({
          ...priceCalculatorState,
          loadingScenarioID: 0,
        });
      });
  }

  const [featuresLoading, setFeaturesLoading] = useState(false);
  const [featuresData, setFeaturesData] = useState([]);
  async function getFeaturesData(packageId) {
    setFeaturesLoading(true);
    await getAPIData(679,
      authTokens,
      {
        optionEnum1: 0,
        optionEnum2: 0,
        optionEnum3: 0,
        optionEnum4: 0,
        optionEnum5: packageId,
      }
    )
      .then((response) => {
        if (response?.mr?.Success) {
          setFeaturesData(response?.QuestionnaireSection_List)
        }
        setFeaturesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        setFeaturesLoading(false);
      });
  }

  function interpolatePackageResponse(_ApiResponse) {
    let ApiResponse = { ..._ApiResponse };

    let pillarList = [
      {
        pillarName: "Identify services",
        dataList:
          ApiResponse?.Nist_Identify?.filter(
            (item) => item.Selected === true
          ) ?? [],
        showCount: 3,
      },
      {
        pillarName: "Protection services",
        dataList:
          ApiResponse?.Nist_Protect?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Detection services",
        dataList:
          ApiResponse?.Nist_Detect?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Response services",
        dataList:
          ApiResponse?.Nist_Respond?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
      {
        pillarName: "Recover services",
        dataList:
          ApiResponse?.Nist_Recover?.filter((item) => item.Selected === true) ??
          [],
        showCount: 3,
      },
    ];
    delete ApiResponse["Nist_Identify"];
    delete ApiResponse["Nist_Protect"];
    delete ApiResponse["Nist_Detect"];
    delete ApiResponse["Nist_Respond"];
    delete ApiResponse["Nist_Recover"];

    return {
      ...ApiResponse,
      pillarList,
    };
  }

  function startLoadingNewPackage(_loadingText = "Loading...") {
    setPriceCalculatorState({
      ...priceCalculatorState,
      newScenarioCreating: true,
      scenarioLoadingText: _loadingText,
    });
  }

  function stopLoadingNewPackage() {
    setPriceCalculatorState({
      ...priceCalculatorState,
      newScenarioCreating: false,
      scenarioLoadingText: "",
    });
  }

  function closeScenario(_scenarioId) {
    let updated_data = { ...priceCalculatorState };
    let index = updated_data?.tabsData?.findIndex(
      (tab) => tab?.ScenarioHeader?.objId_Scenario === _scenarioId
    );
    if (index > -1) {
      let nav_header = document.getElementById("navHeader");
      let all_links = nav_header.getElementsByClassName("navlinks");
      all_links[index].getElementsByTagName("img")[0].click();
    }
  }

  /**
   *
   * @param
   * {
   * objId_PackagePrice,
   * Discount,
   * Cost,
   * SellOverridden,
   * Tax,
   * PackagePerAsset_List,
   * PackagePerSeat_List,
   * PackagePeriodBreakdown_List,
   * RequiredSeats
   * }
   * @returns
   */
  function updateSeatAssetDataApi(_MSPPackagePrice, _packageType) {
    //kwargs = {}
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateMSPPackagePrice", {
        objId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        MSPPackagePrice: JSON.stringify(_MSPPackagePrice),
        PackageType: _packageType,
        objId_PricingScenario: Number(
          priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
            ?.ScenarioHeader?.objId_Scenario
        ),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function resetAssetSeatDataApi(_packageObjId, _packageType) {
    return new Promise((resolve, reject) => {
      getAPIData(653, authTokens, {
        optionEnum1: _packageObjId,
        optionEnum2: _packageType,
        optionEnum3: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function duplicatePackage(_scenarioId) {
    return new Promise((resolve, reject) => {
      getAPIData(620, authTokens, {
        optionEnum1: _scenarioId,
        optionEnum2: clientId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const [showMspScoreModal, setShowMspScoreModal] = useState(false);
  const [showMspFeaturesModal, setShowMspFeaturesModal] = useState(false);
  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [serviceModalData, setServiceModalData] = useState({});

  async function updateRadarChart() {
    setLoading(true);
    await getAPIData(611, authTokens, {
      // optionEnum1: selectedServiceId,
    }).then((response) => {
      setShowRadar(response?.ShowNistGraph);
      setServiceModalData(response);
      setLoading(false);
    });
  }

  const [showSharePackageModal, setShowSharePackageModal] = useState(false);
  const [showPrintPackageModal, setShowPrintPackageModal] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [clickedItem, setClickedItem] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimatingEnd, setIsAnimatingEnd] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    if (isDisabled) return;

    let updated_data = { ...priceCalculatorState };
    updated_data.mergedStatusArray[updated_data.activeChart] = !updated_data.mergedStatusArray[updated_data.activeChart];
    setIsAnimating(true);
    setIsAnimatingEnd(false);
    setPriceCalculatorState(updated_data);

    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  function ToggleAccordionSinglePostureData(e) {
    let event = e;
    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }

  const history = useHistory();
  const [activePackageFound, setActivePackageFound] = useState();
  const [activeOrInactivePackageId, setActiveOrInactivePackageId] = useState(0);
  const [showUpdateCyberKPIModal, setShowUpdateCyberKPIModal] = useState(false);
  const [activeOrInactiveLoading, setActiveOrInactiveLoading] = useState(0);

  const [showInfoModal, setShowInfoModal] = useState(false)

  const [deleteModalConfig, setDeleteModalConfig] = useState({
    show: false,
    hideModal: () => {
      setDeleteModalConfig((prev) => ({
        ...prev,
        show: false,
      }));
    },
    confirmQuestion: "",
    deleteMessage: "",
    deleteType: "mspSell",
    pageEnum: 0,

    deleteId: 0,
  });

  async function getActivePackage() {
    await IndependentGetAPIData(984, authTokens, {
      optionEnum1: clientId
    })
      .then((response) => {
        if (response/*?.mr?.Success*/) {
          setActivePackageName(response?.PackageName);
          setActivePackageId(response?.objId_Scenario);
        }
      })
      .catch((err) => { });
  }

  function updateCyberKPI(objId, securityAction) {
    return new Promise((resolve, reject) => {
      setActiveOrInactiveLoading(objId);
      getAPIData(1057, authTokens, {
        optionEnum1: objId,
        optionEnum2: clientId,
        optionEnum3: securityAction,
      })
        .then((response) => {
          if (response?.Success) {
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  function setIsActiveOrInactive(objId, status, securityAction) {
    updateGettingStarted({
      configValue: true,
      configType: CLIENT_GETTING_STARTED_TYPE.ApplySecurityPackage,
      clientId
    });
    return new Promise((resolve, reject) => {
      setActiveOrInactiveLoading(objId);
      getAPIData(970, authTokens, {
        optionEnum1: objId,
        optionEnum2: clientId,
        optionEnum3: status,
        optionEnum4: securityAction
      })
        .then((response) => {
          if (response?.Success) {
            getActivePackage();
            getPageData()
            getPackagesPageData().then(() => {
              setActiveOrInactiveLoading(0);
              CallToast("Changed successfully", false, false, "v2style");
            });
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  function deleteData(objId, pageEnum) {
    return new Promise((resolve, reject) => {
      getAPIData(pageEnum ?? 662, authTokens, {
        optionEnum1: objId,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Deleted successfully", false, false, "v2style");
            getPageData()
            getPackagesPageData();
          } else {
            reject(response);
            CallToast("Something went wrong", false, false, "v2style");
          }
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  let user_data = localStorage.getItem("object_Data");
  let _data = JSON.parse(user_data);
  const [switchIsOn, setSwitchIsOn] = useState([]);

  // useEffect(() => {
  //   setSwitchValPassed(switchVal)
  // }, [switchVal])

  useEffect(() => {
    setSwitchVal(
      switchIsOn.find(
        (item) => item === priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    );
  }, [priceCalculatorState?.tabsData[priceCalculatorState?.activeTab], switchIsOn]);

  const toggleSwitch = (id) => {
    if (
      switchIsOn.find(
        (item) => item === priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario
      ) !== undefined
    ) {
      setSwitchIsOn(switchIsOn.filter((itemId) => itemId !== id));
    } else {
      setSwitchIsOn([...switchIsOn, id]);
    }
  };
  console.log('packageDetails -', packageDetails)

  const [contribLoading, setContribLoading] = useState(false);

  function updatePackageMappedPrice(packageId) {
    setContribLoading(true);
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdatePackageMappedPrice", {
        ObjId_Package: packageId,
        ObjId_Customer: clientId,
        NewPackagePrice: 0,
        PackageMapCurrentSpend_List: JSON.stringify([{}]),
      })
        .then((response) => {
          if (response?.Success) {
            getPackageDetails(packageId, true);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }

        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const [isFromAddContribTable, setIsFromAddContribTable] = useState(false);
  const [showContribReset, setShowContribReset] = useState(false);

  const SearchInput = useRef();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
        SearchInput?.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      {
        menuItemData?.disableState || ((accessState?.Override === true && accessState?.AccessState_ManagedSecurity === 0)) ? <>
          {
            userPortalRole === 'MSP' ?
              <NoAccessUI /> : <NoAccessUI type={userPortalRole} />
          }
        </> : <>
          <div className="msp_client_price_calculator">
            {loading ? (
              <div className="p-4">
                <MspClientPriceCalculatorLoader />
              </div>
            ) : (
              <>
                <Row className="m-0">
                  <Tab.Container
                    onSelect={(v) => { }}
                    id="left-tabs-example"
                    defaultActiveKey={0}
                  >
                    <div
                      className="scenarioName bg-white d-flex align-items-start justify-content-between px-5 pt-2 position-sticky"
                      style={{ top: isMspClientView ? "58px" : "101px", zIndex: 2 }}
                      id="navHeader"
                    >
                      <div className="d-flex align-items-center circleHeader flex-wrap">
                        {priceCalculatorState?.tabsData?.map((tab, key) => (
                          <Nav.Item
                            key={key}
                            className={`${key === 0 ? "" : ""} mt-2`}
                          >
                            {key === 0 && (
                              <Nav.Link
                                eventKey={key}
                                className={`navlinks all-scenario-navlink ${priceCalculatorState.activeTab === 0 ? "active" : ""
                                  }`}
                                onClick={() => {
                                  localStorage.removeItem("activePackageName");
                                  let updated_data = { ...priceCalculatorState };
                                  updated_data.activeTab = key;
                                  updated_data.activeChart = key;
                                  updated_data.mergedStatusArray = updated_data.mergedStatusArray.map((value, index) => false);

                                  setPriceCalculatorState(updated_data);
                                  if (isSmthOverriden) {
                                    setLoading(true);
                                    getPageData();
                                    getPackagesPageData()
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center circleHeader  mr-2">
                                  <span
                                    style={{
                                      color: `${priceCalculatorState.activeTab === 0
                                        ? "#008FD4"
                                        : ""
                                        }`,
                                    }}
                                    className="ml-2- mr-2"
                                  >
                                    <img
                                      src={`/images/calculator/${priceCalculatorState.activeTab === 0
                                        ? "allscenariob.svg"
                                        : "allscenariog.svg"
                                        }`}
                                      // onClick={() => removeScenario(key)}
                                      className="ml-1- mr-2"
                                      alt=""
                                    />
                                    All packages
                                  </span>
                                </div>
                              </Nav.Link>
                            )}

                            {key > 0 && (
                              <Nav.Link
                                href={"javascript:void(0)"}
                                className={`navlinks ${priceCalculatorState.activeTab === key
                                  ? "active"
                                  : ""
                                  }`}
                                eventKey={key}
                                onClick={() => {
                                  localStorage.removeItem("activePackageName");
                                  localStorage.setItem("activePackageName", tab?.Name);
                                  let updated_data = { ...priceCalculatorState };
                                  updated_data.activeTab = key;
                                  updated_data.activeChart = key;
                                  updated_data.mergedStatusArray = updated_data.mergedStatusArray.map((value, index) => false);

                                  setPriceCalculatorState(updated_data);
                                }}
                              >
                                <div className="d-flex align-items-center circleHeader  mr-2">
                                  <div
                                    className="circleDiv"
                                    style={{
                                      background: `${tab.circleColour}`,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: `${priceCalculatorState.activeTab === key
                                        ? "#008FD4"
                                        : ""
                                        }`,
                                    }}
                                    className="ml-2 mr-2"
                                  >
                                    {tab?.Name}
                                    <img
                                      src={`/images/calculator/close1.svg`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removePackage(key);
                                        localStorage.removeItem("activePackageName");
                                      }}
                                      className="ml-3 close-icon"
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </Nav.Link>
                            )}
                          </Nav.Item>
                        ))}
                        <Nav.Item className={"disabled mt-2"}>
                          <Nav.Link
                            className={`${priceCalculatorState.tabsData.length === 11
                              ? "disabled"
                              : ""
                              } mt- cursor-default`}
                            onClick={() => {
                              if (!priceCalculatorState?.newScenarioCreating) {
                                AddScenario();
                              }
                            }}
                          >
                            <div className="d-flex align-items-center circleHeader mr-4 addscn cursor-default">
                              {priceCalculatorState?.newScenarioCreating ? (
                                <span className="d-inline-flex align-items-center">
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                    size="sm"
                                    className="mx-2"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  <span className="d-inline-flex align-items-center">
                                    {priceCalculatorState?.scenarioLoadingText}
                                  </span>
                                </span>
                              ) : (
                                <>
                                </>
                              )}
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="rightSideBtn mt-2">
                        {
                          priceCalculatorState.activeTab !== 0 &&
                          <span
                            className="mr-3 f-500 f-14 greyText2 crsrpoint"
                            onClick={() => {
                              setExportManagedSecurityPackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                              setShowExportManagedSecurityPackageModal(true);
                              // setShowPrintPackageModal(true);
                            }}
                          >
                            <img alt="" width={15} height={17} className="mr-2" src="/images/msp/export-supplier-icon.svg" />
                            Export
                          </span>
                        }
                        {
                          priceCalculatorState.activeTab !== 0 ?
                            <Dropdown className="transparennt-wrapper-dropdown-menu">
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  className="pointer mx-2"
                                  src="/images/settings-v2/horizontal-dots.svg"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-0">
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={() => {
                                    history.push(`/mspv2/msp-price-calculator/${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario}`, {
                                      backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                                      allPackagesList: pageData?.ScenarioHheaderList,
                                      clientId: clientId,
                                    });
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    if (priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.IsPackageActive == false) {
                                      setActiveOrInactivePackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                      setShowUpdateCyberKPIModal(true);
                                    } else {
                                      setIsActiveOrInactive(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario, 0);
                                    }
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Make {activePackageFound?.objId_Scenario == priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario ? 'Inactive' : 'Active'}
                                </div>
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    setMapCurrentSpendPackageId(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                    setMapCurrentSpendPackagePeriod(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.BillingPeriod_Text)
                                    setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal + priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Tax).toFixed(2)} /${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                                    setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal).toFixed(2) : priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.MSPPackagePrice?.Subtotal);
                                    setShowMapCurrentSpendModal(true);
                                    setIsFromAddContribTable(true);
                                    const drag = document?.getElementById("map-current-spend-modal");
                                    drag.style.display = "block";
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Add Contribution
                                </div>
                                <div className="hg-border-top mx-3"></div>
                                <div>
                                  {_data?.UserDetail?.Role?.toLowerCase() === "msp" && (
                                    <div
                                      className="d-flex align-items-center py-2 px-3 bg-hover-dark-grey"
                                      onClick={(e) => {
                                        // toggleSwitch(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                      }}
                                    >
                                      <div
                                        className="greyText2 f-14 f-400 mr-2"
                                      >
                                        Extended details
                                      </div>
                                      <SwitchButtonMulti
                                        id={`switch_toggle_button_${priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario}`}
                                        // label={"extended details"}
                                        value={switchVal}
                                        onChange={(e) => {
                                          toggleSwitch(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="hg-border-top mx-3"></div>
                                <div
                                  className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    checkPackageIfInUse(priceCalculatorState?.tabsData[priceCalculatorState?.activeTab]?.ScenarioHeader?.objId_Scenario);
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Menu>
                            </Dropdown> : <></>
                        }
                      </div>
                    </div>

                    <Row
                      style={{}}
                      className="price_calulator_wrapper hide-scrollbar"
                    >
                      <Col
                        xs={priceCalculatorState.activeTab !== 0 ? 7 : 6}
                        className="hide-scrollbar" style={{}}>
                        <Tab.Content>
                          {priceCalculatorState?.tabsData?.map((tab, key) => {
                            return (
                              <Tab.Pane eventKey={key} key={key}>
                                {key === 0 && (
                                  <PriceCalculatorDashBoard
                                    getPageData={getPageData}
                                    switchIncludedTaxVal={switchIncludedTaxVal}
                                    setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                                    isMspClientView={isMspClientView}
                                    showCreateCustomPackageModal={
                                      showCreateCustomPackageModal
                                    }
                                    setShowCreateCustomPackageModal={
                                      setShowCreateCustomPackageModal
                                    }
                                    defaultUserDeviceDetails={
                                      defaultUserDeviceDetails
                                    }
                                    defaultUserDeviceDetailsStartData={
                                      defaultUserDeviceDetailsStartData
                                    }
                                    setDefaultUserDeviceDetails={
                                      setDefaultUserDeviceDetails
                                    }
                                    updateQuantityOfAssets={updateQuantityOfAssets}
                                    pageData={pageData}
                                    getPackageDetails={getPackageDetails}
                                    getFeaturesData={getFeaturesData}
                                    getSecurityCapabilities={getSecurityCapabilities}
                                    checkPackageIfInUse={checkPackageIfInUse}
                                    closeScenario={closeScenario}
                                    priceCalculatorState={priceCalculatorState}
                                    refreshData={getPageData}
                                    _openedPackagesIds={openedPackagesIds}
                                    _setOpenedPackagesIds={setOpenedPackagesIds}
                                    userSeatingsLoader={userSeatingsLoader}
                                    clickedItem={clickedItem}
                                    setClickedItem={setClickedItem}
                                    activePackageId={activePackageId}
                                    showMapCurrentSpendModal={showMapCurrentSpendModal}
                                    setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                                    mapCurrentSpendPackageId={mapCurrentSpendPackageId}
                                    setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                                    setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                                    mapCurrentSpendPackagePrice={mapCurrentSpendPackagePrice}
                                    setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                                    mapCurrentSpendPackagePriceText={mapCurrentSpendPackagePriceText}
                                    setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                                    setActivePackageName={setActivePackageName}
                                    setActivePackageId={setActivePackageId}
                                    activePackageFound={activePackageFound}
                                    setActivePackageFound={setActivePackageFound}
                                    activeOrInactivePackageId={activeOrInactivePackageId}
                                    setActiveOrInactivePackageId={setActiveOrInactivePackageId}
                                    showUpdateCyberKPIModal={showUpdateCyberKPIModal}
                                    setShowUpdateCyberKPIModal={setShowUpdateCyberKPIModal}
                                    setIsActiveOrInactive={setIsActiveOrInactive}
                                    activeOrInactiveLoading={activeOrInactiveLoading}
                                    setActiveOrInactiveLoading={setActiveOrInactiveLoading}
                                    deleteModalConfig={deleteModalConfig}
                                    setDeleteModalConfig={setDeleteModalConfig}
                                    deleteData={deleteData}
                                    setShowInfoModal={setShowInfoModal}
                                    showInfoModal={showInfoModal}
                                    inUseData={inUseData}
                                    SearchInput={SearchInput}
                                    ownClientID={ownClientID}
                                    updateCyberKPI={updateCyberKPI}
                                    getPackagesPageData={getPackagesPageData}
                                    packagesLoading={packagesLoading}

                                  />
                                )}
                                {key > 0 && (
                                  <PackageData
                                    packageName={tab.Name}
                                    _packageData={priceCalculatorState.tabsData[key]}
                                    switchVal={switchVal}
                                    priceCalculatorState={priceCalculatorState}
                                    isMspClientView={isMspClientView}
                                    packageDetails={packageDetails}
                                    updateSeatAssetDataApi={updateSeatAssetDataApi}
                                    resetAssetSeatDataApi={resetAssetSeatDataApi}
                                    getPackageDetails={getPackageDetails}
                                    setIsSmthOverriden={setIsSmthOverriden}
                                    setSwitchVal={setSwitchVal}
                                    setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                                    setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                                    setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                                    switchIncludedTaxVal={switchIncludedTaxVal}
                                    setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                                    setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                                    featuresData={featuresData}
                                    discount={discount}
                                    setDiscount={setDiscount}
                                    showMapCurrentSpendModal={showMapCurrentSpendModal}
                                    getPageData={getPageData}
                                    clientId={clientId}
                                    mapCurrentSpendPackageId={mapCurrentSpendPackageId}
                                    mapCurrentSpendPackagePrice={mapCurrentSpendPackagePrice}
                                    mapCurrentSpendPackagePriceText={mapCurrentSpendPackagePriceText}
                                    updatePackageMappedPrice={updatePackageMappedPrice}
                                    setContribLoading={setContribLoading}
                                    contribLoading={contribLoading}
                                    featuresLoading={featuresLoading}
                                    setIsFromAddContribTable={setIsFromAddContribTable}
                                    setShowContribReset={setShowContribReset}
                                    showContribReset={showContribReset}
                                    contributionValue={contributionValue}
                                    componentsData={componentsData}
                                    securityCapabilitiesData={securityCapabilitiesData}
                                    securityCapabilitiesLoading={securityCapabilitiesLoading}
                                    ownClientID={ownClientID}
                                  />
                                )}
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Col>

                      <Col
                        className='py-5'
                        xs={5}
                      >

                        {/* {
                          priceCalculatorState.activeTab > 0 ? */}
                        <div className="bg-grey-imp radius-8 m-auto mt-4 w-100">
                          <div className="p-3">
                            <div className="f-500 f-14 greyText2">
                              Security Posture
                            </div>
                            <div className="m-auto w-fit-content mt-4">
                              {pageData?.CurrentPosture?.Identify !== 0 ||
                                pageData?.CurrentPosture?.Protect !== 0 ||
                                pageData?.CurrentPosture?.Detect !== 0 ||
                                pageData?.CurrentPosture?.Response !== 0 ||
                                pageData?.CurrentPosture?.Recover !== 0 ||
                                priceCalculatorState?.tabsData?.length > 1 ? (
                                <>
                                  {/* <MspClientPriceCalculatorRadarChart
                                    activeChart={ACTIVE_CHART}
                                    _datasets={TABS_DATASETS}
                                    merged_Array={priceCalculatorState.mergedStatusArray}
                                    merged_filter={priceCalculatorState.mergedStatusArray.filter((item) => item === true).length}
                                    isAnimating={isAnimating}
                                    setIsAnimating={setIsAnimating}
                                  /> */}
                                  <MspClientPriceCalculatorRadarChartMerge
                                    activeChart={ACTIVE_CHART}
                                    _datasets={TABS_DATASETS}
                                    merged_Array={priceCalculatorState.mergedStatusArray}
                                    merged_filter={priceCalculatorState.mergedStatusArray.filter((item) => item === true).length}
                                    isAnimating={isAnimating}
                                    setIsAnimating={setIsAnimating}
                                    isAnimatingEnd={isAnimatingEnd}
                                    setIsAnimatingEnd={setIsAnimatingEnd}
                                  />
                                </>
                              ) : (
                                <MspClientPriceCalculatorEmptyRadarChart
                                  activeChart={ACTIVE_CHART}
                                  _datasets={TABS_DATASETS}
                                />
                              )}
                            </div>


                          </div>


                          {
                            priceCalculatorState.activeTab > 0 && <>
                              <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{
                                  marginTop: '-30px'
                                }}
                              >
                                <div
                                  className={`bg-greyish f-grey mb-2 py-2 px-3 pointer radius-4`}
                                  onClick={() => {
                                    let updated_data = { ...priceCalculatorState }
                                    updated_data.mergedStatusArray[updated_data.activeChart] = !updated_data.mergedStatusArray[updated_data.activeChart]
                                    setIsAnimating(true);
                                    console.log('isAnimating - ', isAnimating)
                                    setPriceCalculatorState(updated_data)
                                  }}
                                >
                                  {!priceCalculatorState.mergedStatusArray[priceCalculatorState.activeChart] ? "Merge" : "Unmerge"}
                                </div>
                              </div>

                              <div className="p-3">
                                <div className="nist-component-table">
                                  <div className="f-500 f-12 f-darkgrey">
                                    NIST Component
                                  </div>
                                  <div className="f-500 f-12 f-darkgrey">
                                    Current
                                  </div>
                                  <div className="f-500 f-12 f-darkgrey">
                                    Predicted
                                  </div>
                                </div>

                                {
                                  Object.values(services_enums_selected)?.map((serviceNistItem) => {
                                    console.log('securityCapabilitiesData -- ', securityCapabilitiesData)
                                    console.log('priceCalculatorState.tabsData[0]', priceCalculatorState.tabsData[0])
                                    console.log('priceCalculatorState.tabsData', priceCalculatorState.tabsData)
                                    console.log('pageData - ', pageData)
                                    return (
                                      <div className="nist-component-table">
                                        <div className="f-400 f-14 f-black">
                                          {serviceNistItem?.DDLText}
                                        </div>
                                        <div className="f-400 f-14 f-grey">
                                          {pageData?.CurrentPosture[serviceNistItem?.DDLText]}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <div className="f-500 f-14 f-black mr-2">
                                            {Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.[serviceNistItem?.DDLText]}
                                          </div>
                                          {
                                            Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] != pageData?.CurrentPosture[serviceNistItem?.DDLText] && <div className="d-flex align-items-center">
                                              <img
                                                alt=""
                                                src={`${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                                className="mr-1"
                                              />
                                              <div className={`f-500 f-14 ${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] > pageData?.CurrentPosture[serviceNistItem?.DDLText] ? 'f-green-light' : 'redText'}`}>
                                                {Math.abs(Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture[serviceNistItem?.DDLText] - pageData?.CurrentPosture[serviceNistItem?.DDLText])}
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                {/* <div className="nist-component-table">

                            </div> */}
                              </div>

                              <div className="d-flex justify-content-between p-3 border-top">
                                <div>
                                  <div className="f-500 f-14 f-grey">
                                    Projected CyberResilience
                                  </div>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="f-500 f-18 f-black mr-2">
                                      {Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance}%
                                    </div>
                                    {
                                      Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance != pageData?.CurrentPosture?.CyberCompliance && <div className="d-flex align-items-center">
                                        <img
                                          alt=""
                                          src={`${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                          className="mr-1"
                                        />
                                        <div className={`f-500 f-14 ${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance > pageData?.CurrentPosture?.CyberCompliance ? 'f-green-light' : 'redText'}`}>
                                          {Math.abs(Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberCompliance - pageData?.CurrentPosture?.CyberCompliance)}%
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>

                                <CyberComplianceGauge
                                  value={priceCalculatorState.tabsData[
                                    priceCalculatorState.activeTab
                                  ]?.ScenarioHeader?.CyberCompliance}
                                  showTitle={false}
                                />
                              </div>

                              <div className="d-flex justify-content-between p-3 border-top">
                                <div>
                                  <div className="f-500 f-14 f-grey">
                                    Projected CyberScore
                                  </div>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="f-500 f-18 f-black mr-2">
                                      {Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore}
                                    </div>
                                    {
                                      Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore != pageData?.CurrentPosture?.CyberScore && <div className="d-flex align-items-center">
                                        <img
                                          alt=""
                                          src={`${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? '/images/icons/green-up-arrow.svg' : '/images/icons/red-down-arrow.svg'}`}
                                          className="mr-1"
                                        />
                                        <div className={`f-500 f-14 ${Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore > pageData?.CurrentPosture?.CyberScore ? 'f-green-light' : 'redText'}`}>
                                          {Math.abs(Object.keys(securityCapabilitiesData).length != 0 && securityCapabilitiesData?.ProjectedScenarioPosture?.CyberScore - pageData?.CurrentPosture?.CyberScore)}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>

                                <CyberScoreGauge
                                  value={priceCalculatorState.tabsData[
                                    priceCalculatorState.activeTab
                                  ]?.ScenarioHeader?.CyberScore?.toFixed(1)}
                                  showTitle={false}
                                />
                              </div>
                            </>
                          }

                          {priceCalculatorState.activeTab === 0 && (
                            <div className="p-3 hg-border-top">
                              <PriceCalculatorPostureData
                                setShowCyberGroupModal={setShowCyberGroupModal}
                                tabsData={priceCalculatorState.tabsData[0]}
                                activateChartIndex={activateChartIndex}
                                priceCalculatorState={priceCalculatorState}
                                setSwitchIncludedTaxVal={setSwitchIncludedTaxVal}
                                switchIncludedTaxVal={switchIncludedTaxVal}
                              />
                            </div>
                          )}
                        </div>
                        <div className="p-5" />
                      </Col>
                    </Row>
                  </Tab.Container>
                </Row>
              </>
            )}
            <ExportManagedSecurityPackageModal
              show={showExportManagedSecurityPackageModal}
              hideModal={() => {
                setShowExportManagedSecurityPackageModal(false);
                // clearParams();
              }}
              packageId={exportManagedSecurityPackageId/* 10550117 */}
              featuresData={featuresData}
              packageData={packageDetails}
              securityCapabilitiesData={securityCapabilitiesData}
              pageData={pageData}
              clientId={clientId}
            />
            <MapCurrentSpendModal
              show={showMapCurrentSpendModal}
              hideModal={() => {
                setShowMapCurrentSpendModal(false);
                const drag = document?.getElementById("map-current-spend-modal");
                drag.style.display = "none";
                getPageData();
                getPackagesPageData()
                setMapCurrentSpendPackageId(0);
                setMapCurrentSpendPackagePeriod(0);
                setIsFromAddContribTable(false);
              }}
              afterMap={() => {
                if (priceCalculatorState.activeTab > 0) {
                  setContribLoading(true);
                  setShowContribReset(true);
                  getPackageDetails(mapCurrentSpendPackageId, true);
                }
              }}
              afterMapReset={() => {
                if (priceCalculatorState.activeTab > 0) {
                  setContribLoading(true);
                  setShowContribReset(false);
                  getPackageDetails(mapCurrentSpendPackageId, true);
                }
              }}
              isFromAddContribTable={isFromAddContribTable}
              clientId={clientId}
              packageId={mapCurrentSpendPackageId}
              packagePeriod={mapCurrentSpendPackagePeriod}
              packagePrice={mapCurrentSpendPackagePrice}
              packagePriceText={mapCurrentSpendPackagePriceText}
            />
            <PrintPackageModal
              show={showPrintPackageModal}
              pageData={pageData}
              clientId={clientId}
              hideModal={() => {
                setShowPrintPackageModal(false);
              }}
            />
            <SendPackageModal
              show={showSharePackageModal}
              pageData={pageData}
              clientId={clientId}
              sharingCheck={setSharing}
              hideModal={() => {
                setShowSharePackageModal(false);
              }}
            />
            <CreateCustomPackageModal
              show={showCreateCustomPackageModal}
              hideModal={() => {
                setShowCreateCustomPackageModal(false);
              }}
              packageList={pageData?.ScenarioHheaderList}
              updatePageData={() => {
                return getPageData()
                  .then(() => {
                    return getPackagesPageData();
                  });
              }}
              duplicatePackage={duplicatePackage}
            />
            <MspScoreModal
              isForPackageDetail={true}
              packageId={Number(
                priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
                  ?.ScenarioHeader?.objId_Scenario
              )}
              radarData={TABS_DATASETS[ACTIVE_CHART]?.data}
              show={showMspScoreModal}
              updateRadarChart={updateRadarChart}
              hideModal={() => {
                setShowMspScoreModal(false);
              }}
              selectedNistPiller={Object.values(services_titles)[0]}
              HideMspSecopsLink={true}
              hideServiceModal={() => { }}
              showExploreMappingModal={showExploreMappingModal}
              setShowExploreMappingModal={setShowExploreMappingModal}
              existServices={[
                packageDetails?.Nist_Identify?.length,
                packageDetails?.Nist_Protect?.length,
                packageDetails?.Nist_Detect?.length,
                packageDetails?.Nist_Recover?.length,
                packageDetails?.Nist_Respond?.length,
              ]}
            />
            <MspFeaturesModal
              packageId={Number(
                priceCalculatorState?.tabsData[priceCalculatorState?.activeChart]
                  ?.ScenarioHeader?.objId_Scenario
              )}
              show={showMspFeaturesModal}
              hideModal={() => {
                setShowMspFeaturesModal(false);
              }}
              identify={packageDetails?.Nist_Identify}
              protect={packageDetails?.Nist_Protect}
              detect={packageDetails?.Nist_Detect}
              recover={packageDetails?.Nist_Recover}
              respond={packageDetails?.Nist_Respond}
            />
            <ExploreMappingModal
              show={showExploreMappingModal}
              hideModal={() => {
                setShowExploreMappingModal(false);
              }}
            />
            <CyberGroupModal
              AccessStateLabel={Number(ownClientID) != Number(clientId) ? 'AccessState_ManagedSecurity' : ''}
              isSaas={false} //msp client
              show={showCyberGroupModal}
              hideModal={() => {
                setShowCyberGroupModal(false);
                getPageData();
                getPackagesPageData()
              }}
              clientId={clientId}
              parentID={"mainBody"}
              passedOptionEnum99={1}
            />
          </div>
        </>
      }
    </>
  );
};

export default MspClientPriceCalculator;

const PriceCalculatorDashBoard = ({
  setShowCreateCustomPackageModal,
  switchIncludedTaxVal,
  setSwitchIncludedTaxVal = () => { },
  defaultUserDeviceDetails,
  defaultUserDeviceDetailsStartData,
  setDefaultUserDeviceDetails,
  updateQuantityOfAssets,
  pageData,
  getPackageDetails,
  priceCalculatorState,
  closeScenario,
  refreshData,
  isMspClientView,
  _openedPackagesIds,
  _setOpenedPackagesIds,
  userSeatingsLoader,
  getPageData = () => { },
  clickedItem,
  setClickedItem = () => { },
  activePackageId = 0,
  showMapCurrentSpendModal,
  setShowMapCurrentSpendModal = () => { },
  mapCurrentSpendPackageId,
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  mapCurrentSpendPackagePrice,
  setMapCurrentSpendPackagePrice = () => { },
  mapCurrentSpendPackagePriceText,
  setMapCurrentSpendPackagePriceText = () => { },
  setActivePackageName = () => { },
  setActivePackageId = () => { },
  getFeaturesData = () => { },
  getSecurityCapabilities = () => { },
  activePackageFound,
  setActivePackageFound = () => { },
  activeOrInactivePackageId,
  setActiveOrInactivePackageId = () => { },
  showUpdateCyberKPIModal,
  setShowUpdateCyberKPIModal = () => { },
  updateCyberKPI = () => { },
  setIsActiveOrInactive = () => { },
  activeOrInactiveLoading,
  setActiveOrInactiveLoading = () => { },
  deleteModalConfig,
  setDeleteModalConfig = () => { },
  deleteData = () => { },
  checkPackageIfInUse = () => { },
  setShowInfoModal = () => { },
  getPackagesPageData = () => { },
  showInfoModal,
  inUseData,
  SearchInput,
  ownClientID,
  packagesLoading
}) => {
  const [showUptoStandard, setShowUptoStandard] = useState(5);
  const [showUptoCustom, setShowUptoCustom] = useState(5);

  const [searchString, setSearchString] = useState("");
  const [savingAssets, setSavingAssets] = useState(false);
  // const [tempUserDetailsData, setTempUserDetailsData] = useState({
  //   NoOfUsers: 22,
  //   NoOfWorkstation: 25,
  //   NoOfServices: 23,
  //   NoOfMobileDevices: 29,
  //   NoOfDomains: 12,
  //   NoOfRouters: 26,
  //   NoOfNetworkDevices: 26,
  // });
  const [tempUserDetailsData, setTempUserDetailsData] = useState([]);
  const [openedPackages, setOpenedPackages] = useState(_openedPackagesIds);
  const preDataState = tempUserDetailsData.every((item) => item.Qty === 0);
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  const [packageOrder, setPackageOrder] = useState(0);

  useEffect(() => {
    if (activePackageId != 0) {
      setOpenedPackages([activePackageId]);
      getPackageDetails(activePackageId);
      getFeaturesData(activePackageId);
      getSecurityCapabilities(activePackageId);
    }
  }, [])

  useEffect(() => {
    setOpenedPackages(_openedPackagesIds);
  }, [_openedPackagesIds]);

  useEffect(() => {
    setTempUserDetailsData(defaultUserDeviceDetails);
  }, [defaultUserDeviceDetails]);

  function saveAssets() {
    setSavingAssets(true);
    let userDetails = [...tempUserDetailsData];

    userDetails.forEach((item) => {
      if (item.Qty === "") {
        item.Qty = 0;
      }
    });

    updateQuantityOfAssets(userDetails)
      .then((res) => {
        document.getElementById("quantyAssetOnDashboard").click();
        setSavingAssets(false);
        refreshData();
        getPackagesPageData();
        CallToast("Updated successfully", false, false, "v2style");
      })
      .catch((err) => {
        setSavingAssets(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function replaceEmptyWithZero(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          obj[key] = 0;
        }
      }
    }
    return obj;
  }

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  const clientId = window.location.pathname.split("/")?.[4] ?? -1;
  const history = useHistory();
  const { authTokens } = useAuth();

  const [assetsFromIntegrations, setAssetsFromIntegrations] = useState([]);
  const [isActivePackageOpen, setIsActivePackageOpen] = useState(false);
  const [selectActivePackageDataSearch, setSelectActivePackageDataSearch] = useState('');

  useEffect(() => {
    setActivePackageFound(pageData?.ScenarioHheaderList?.find(item => item?.IsPackageActive));

    setIsActivePackageOpen(activePackageFound ? openedPackages?.includes(activePackageFound.objId_Scenario) : false)
  }, [pageData])

  console.log('ownClientID != clientId', ownClientID != clientId && '1')

  const clientFakeData = {
    IntegrationClient: [
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      },
      {
        ObjectId: 4653141,
        Intials: "MN",
        ClientName: "M3 Networks Limited"
      }
    ],
  }

  return (
    <div
      className="p-5"
      style={{
        minWidth: "600px",
        maxWidth: "800px",
      }}
    >
      {/* Quantity of Assets Block */}
      <div className="bg-grey radius-8 point-row p-3">
        <div
          className="d-flex align-items-center justify-content-between pointer"
          onClick={ToggleAccordion}
          id="quantyAssetOnDashboard"
        >
          <div>
            <div className="f-500f-14 f-black">Quantity of Assets</div>
            <div className="f-14 f-darkgrey">
              {preDataState
                ? `Provide us with how many users, workstations/laptops and servers
              you have in your organisation.`
                : TrimString(
                  tempUserDetailsData
                    .map(
                      (item) =>
                        `${item.Name?.replace("Per ", "")}: ${item?.Qty}`
                    )
                    .join(", "),
                  65
                )}
            </div>
          </div>
          <div>
            <img
              alt=""
              src="/images/calculator/edit.svg"
              className="ml-3 mr-2"
            />
          </div>
        </div>
        <div
          className="panel"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >

          <div className="d-flex align-items-center justify-content-between my-3">
            <div className="f-500 f-14 f-darkgrey">Assets</div>
            <div
              className="d-flex align-items-center pointer"
              onClick={() => {
                setShowSourcesModal(true);
                const drag = document?.getElementById("sources-modal");
                drag.style.display = "block";
              }}
            >
              <img
                alt=''
                width={20}
                height={22}
                className='mr-1'
                src='/images/get-access-icon.svg'
              />
              <div className="f-500 f-14 txt-blue">Get Assets</div>
            </div>
          </div>

          <div
            className="d-flex flex-wrap   msp_assets_inputs_wrapper"
            style={{
              pointerEvents: isMspClientView ? "none" : "auto",
            }}
          >
            {tempUserDetailsData?.map((item, assetIndex) => {
              return (
                <div
                  key={assetIndex}
                  className={`highlight-input-with-grey-border d-flex align-items-center p-2 radius-4 mt-2 ${false ? "input-error" : ""}`}
                >
                  <div
                    className="f-darkgrey flex-shrink-0 user-details-item"
                    onClick={() => {
                      const updatedData = tempUserDetailsData.map((dataItem) => {
                        if (dataItem.ObjectId === item.ObjectId) {
                          return {
                            ...dataItem,
                            Qty: 1,
                            IsUpdatedByAsset: false,
                          };
                        }
                        return dataItem;
                      });
                      setTempUserDetailsData(updatedData);
                    }}
                  >
                    {item?.Name?.replace("Per ", "")}:
                  </div>
                  <input
                    tabIndex={0}
                    disabled={Number(ownClientID) != Number(clientId) ? viewOnlyAccess("AccessState_ManagedSecurity") : false}
                    type="number"
                    placeholder="0"
                    className="pl-1 pr-2 w-100"
                    value={item?.Qty}
                    onChange={(e) => {
                      if (Number(ownClientID) != Number(clientId)) {
                        if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                          const updatedData = tempUserDetailsData.map((dataItem) => {
                            if (dataItem.ObjectId === item.ObjectId) {
                              return {
                                ...dataItem,
                                Qty: e.target.value?.trim(),
                                IsUpdatedByAsset: false,
                              };
                            }
                            return dataItem;
                          });
                          setTempUserDetailsData(updatedData);
                        }
                      } else {
                        const updatedData = tempUserDetailsData.map((dataItem) => {
                          if (dataItem.ObjectId === item.ObjectId) {
                            return {
                              ...dataItem,
                              Qty: e.target.value?.trim(),
                              IsUpdatedByAsset: false,
                            };
                          }
                          return dataItem;
                        });
                        setTempUserDetailsData(updatedData);
                      }
                    }}
                  />
                  {item?.IsUpdatedByAsset && assetsFromIntegrations?.length > 0 ? (
                    <OverlayTrigger
                      placement="bottom"
                      trigger={["hover", "focus"]}
                      delay={{ show: 200, hide: 250 }}
                      overlay={
                        <Tooltip id={`tooltip-assets`}>
                          {assetsFromIntegrations
                            ?.find(
                              (obj) =>
                                obj?.Name?.slice(0, -1) === item.Name?.replace("Per ", "")
                            )
                            ?.AssetDevicePreview_List?.[0]?.AssetDeviceSource_List?.map(
                              (hoverItem, index) => (
                                <div className="d-flex" key={index}>
                                  <img
                                    alt=""
                                    height={16}
                                    src={`/images/attack-surface/integrations/${hoverItem?.AssetSourceLogo}.webp`}
                                    className="mr-1"
                                  />
                                  <div className="f-400 f-12 f-white line-height-18 letter-space-2">
                                    {hoverItem?.AssetSourceName}
                                  </div>
                                </div>
                              )
                            )}
                        </Tooltip>
                      }
                    >
                      <img
                        alt=""
                        height={14}
                        width={14}
                        src="/images/info.svg"
                      />
                    </OverlayTrigger>
                  ) : null}
                </div>
              );
            })}

          </div>
          {!isMspClientView && (
            <div className="d-flex align-items-center mt-2">
              <Button
                className={` mr-2 ${Number(ownClientID) == Number(clientId) ? 'hg-cancel-btn' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'btn-disable cursor-default' : 'hg-cancel-btn'}`}
                onClick={(e) => {
                  if (Number(ownClientID) != Number(clientId)) {
                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                      saveAssets(e);
                    }
                  } else {
                    saveAssets(e);
                  }
                }}
              >
                {(Number(ownClientID) != Number(clientId)) && (
                  (viewOnlyAccess("AccessState_ManagedSecurity")) &&
                  <img
                    alt=''
                    className='lockerDarkGreyImage mx-2 mb-1'
                    src='/images/settings-v2/locker-grey.svg'
                  />)
                }
                {savingAssets ? "Saving.." : "Save"}
              </Button>
              <Button
                className="hg-cancel-btn"
                onClick={(e) => {
                  ToggleAccordion(e);
                  setTempUserDetailsData(defaultUserDeviceDetails);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* active block */}
      <div className="f-500 f-14 f-black mt-4 mb-2">Active Package</div>
      {
        activePackageFound ?
          <div className="bg-grey radius-8 border-parent mt-3">
            <div
              className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
          ${Number(ownClientID) == Number(clientId) ? 'pointer' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
        `}
              style={{
                minHeight: '106px',
                maxHeight: '106px'
              }}
            >
              {/* Radar Chart */}
              <div className="d-flex align-items-center w-100"
                onClick={(e) => {
                  if (Number(ownClientID) != Number(clientId)) {
                    if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                      if (openedPackages.length > 0) {
                        const updatedData = isActivePackageOpen
                          ? openedPackages.filter(
                            (item) => item !== activePackageFound.objId_Scenario
                          )
                          : [...openedPackages, activePackageFound.objId_Scenario];

                        setOpenedPackages(updatedData);
                        _setOpenedPackagesIds(updatedData);

                        if (isActivePackageOpen) {
                          closeScenario(activePackageFound.objId_Scenario);
                        } else {
                          getPackageDetails(activePackageFound.objId_Scenario);
                          getFeaturesData(activePackageFound.objId_Scenario);
                          getSecurityCapabilities(activePackageFound.objId_Scenario);
                        }
                      } else {
                        setOpenedPackages([activePackageFound.objId_Scenario]);
                        getPackageDetails(activePackageFound.objId_Scenario);
                        getFeaturesData(activePackageFound.objId_Scenario);
                        getSecurityCapabilities(activePackageFound.objId_Scenario);
                      }
                      setClickedItem(true);
                    }
                  } else {
                    if (openedPackages.length > 0) {
                      const updatedData = isActivePackageOpen
                        ? openedPackages.filter(
                          (item) => item !== activePackageFound.objId_Scenario
                        )
                        : [...openedPackages, activePackageFound.objId_Scenario];

                      setOpenedPackages(updatedData);
                      _setOpenedPackagesIds(updatedData);

                      if (isActivePackageOpen) {
                        closeScenario(activePackageFound.objId_Scenario);
                      } else {
                        getPackageDetails(activePackageFound.objId_Scenario);
                        getFeaturesData(activePackageFound.objId_Scenario);
                        getSecurityCapabilities(activePackageFound.objId_Scenario);
                      }
                    } else {
                      setOpenedPackages([activePackageFound.objId_Scenario]);
                      getPackageDetails(activePackageFound.objId_Scenario);
                      getFeaturesData(activePackageFound.objId_Scenario);
                      getSecurityCapabilities(activePackageFound.objId_Scenario);
                    }
                    setClickedItem(true);
                  }
                }}
              >
                <div className="mr-2">
                  <PackageListRadarUI
                    dataList={[
                      activePackageFound?.InspectScore,
                      activePackageFound?.ProtectScore,
                      activePackageFound?.DetectScore,
                      activePackageFound?.RespondScore,
                      activePackageFound?.RecoverScore,
                    ]}
                    colorPassed={priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario)?.circleColour ?? '#9499AA'}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="subclass d-flex align-items-center">
                        {" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: HGHighlightText(
                              activePackageFound?.Name,
                              searchString
                            ),
                          }}
                        /> {
                          activeOrInactiveLoading == activePackageFound?.objId_Scenario ? <div className="msp-service-update-loader ml-2 mr-1"></div> :
                            <span className="f-500 f-12 txt-blue ml-2">
                              {activePackageFound?.IsPackageActive && 'Active'}
                            </span>
                        }
                      </div>
                      <div className="f-12 f-darkgrey">
                        {TrimString(activePackageFound?.Description, 50) ?? ""}
                      </div>
                      {
                        activePackageFound?.MappedPrice > 0 && activePackageFound?.Contribution > 0 ?
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center f-darkgrey line-through-text mr-2">
                              {
                                switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text?.split(' ')[0] : activePackageFound?.MonthlyPriceWithoutTax_Text?.split(' ')[0]
                              }
                            </div>
                            <div className="d-flex align-items-center txt-blue">
                              {
                                `${activePackageFound?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${switchIncludedTaxVal ?
                                  (activePackageFound?.MonthlyPrice - activePackageFound?.Contribution)?.toFixed(2) :
                                  (parseFloat(activePackageFound?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - activePackageFound?.Contribution)?.toFixed(2)} /${activePackageFound?.BillingPeriod_Text}`
                              }
                            </div>
                            {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                          </div>
                          :
                          <div className="d-flex align-items-center f-darkgrey">
                            {
                              switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text : activePackageFound?.MonthlyPriceWithoutTax_Text
                            }
                            {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>


              <div className="d-flex justify-content-end align-content-end" style={{
                height: '100%',
                width: '80px',
                marginTop: '10px'
              }}>
                <div>
                  <div className="d-flex justify-content-end align-content-end" style={{
                    marginBottom: '30px'
                  }}>
                    <Dropdown className="transparennt-wrapper-dropdown-menu">
                      <Dropdown.Toggle>
                        <img
                          alt=""
                          className="pointer mx-2"
                          src="/images/settings-v2/horizontal-dots.svg"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-0">
                        <div
                          className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                          onClick={() => {
                            history.push(`/mspv2/msp-price-calculator/${activePackageFound.objId_Scenario}`, {
                              backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                              allPackagesList: pageData?.ScenarioHheaderList,
                              clientId: clientId,
                            });
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                          onClick={(e) => {
                            if (activePackageFound?.IsPackageActive == false) {
                              setActiveOrInactivePackageId(activePackageFound.objId_Scenario);
                              setShowUpdateCyberKPIModal(true);
                            } else {
                              setIsActiveOrInactive(activePackageFound.objId_Scenario, 0);
                            }
                            e.target
                              .closest(".transparennt-wrapper-dropdown-menu")
                              .click();
                          }}
                        >
                          Set as {activePackageFound.IsPackageActive ? 'Inactive' : 'Active'}
                        </div>
                        <div
                          className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                          onClick={(e) => {
                            setMapCurrentSpendPackageId(activePackageFound?.objId_Scenario);
                            setMapCurrentSpendPackagePeriod(activePackageFound?.PackageBillingPeriod_Text);
                            setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? activePackageFound?.MonthlyPrice_Text : activePackageFound?.MonthlyPriceWithoutTax_Text);
                            setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? activePackageFound?.MonthlyPrice : parseFloat(activePackageFound?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')));
                            setShowMapCurrentSpendModal(true);
                            const drag = document?.getElementById("map-current-spend-modal");
                            drag.style.display = "block";
                            e.target
                              .closest(".transparennt-wrapper-dropdown-menu")
                              .click();
                          }}
                        >
                          Add Contribution
                        </div>
                        <div className="hg-border-top mx-3"></div>
                        <div
                          className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                          onClick={async (e) => {
                            checkPackageIfInUse(activePackageFound?.objId_Scenario);
                            e.target
                              .closest(".transparennt-wrapper-dropdown-menu")
                              .click();
                          }}
                        >
                          Delete
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    {
                      priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario) ? <div className="d-flex justify-content-end align-content-end align-items-center">
                        <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                        <div
                          className="rounded-full ml-2 f-400 f-12"
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === activePackageFound?.objId_Scenario)?.circleColour,
                            color: "#9499AA"
                          }}
                        />
                      </div> :
                        <div style={{
                          height: '20px'
                        }}></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div className="bg-lightblue w-100 px-3 py-3 radius-8 mt-3">
            <div className="d-flex align-items-center">
              <img
                alt=""
                height={15}
                className="mr-2"
                src="/images/navIcons/calculator.svg"
              />
              <div className="f-400 f-14 f-black line-height-22 letter-space-2">Apply an active security package to your client</div>
            </div>
            <Dropdown className="select-active-package-dropdown">
              <Dropdown.Toggle className="d-flex align-items-center">
                <div
                  className={`f-500 f-14 line-height-22 letter-space-2 mt-1 ${activeOrInactiveLoading != 0 ? 'f-darkgrey' : 'f-blue'}`}
                  style={{
                    marginLeft: '20px'
                  }}
                >
                  {activeOrInactiveLoading != 0 ? 'Setting package as active' : 'Select package'}
                </div>
                {
                  activeOrInactiveLoading != 0 ? <div className="msp-service-update-loader ml-2 mr-1 mt-1"></div> : <></>
                }
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="p-2"
                style={{
                  width: '332px !important',
                  height: '460px !important'
                }}
              >
                <div className="no-highlight-input w-100 d-flex align-items-center p-2">
                  <div>
                    <img
                      width={14}
                      height={14}
                      src="/images/attack-surface/search-icon.svg"
                      className="m-1"
                      alt=""
                    />
                  </div>

                  <input
                    className="pl-2 flex-grow-1"
                    placeholder="Find package..."
                    type="text"
                    value={selectActivePackageDataSearch}
                    onChange={(e) => {
                      setSelectActivePackageDataSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="f-400 f-12 f-darkgrey mt-2 ml-2">Packages</div>
                {
                  pageData?.ScenarioHheaderList?.filter((item) => item.IsPackageActive === false)?.filter((item) =>
                    item.Name.toLowerCase().includes(selectActivePackageDataSearch.toLowerCase())
                  )?.length > 0 ?
                    pageData?.ScenarioHheaderList?.filter((item) => item.IsPackageActive === false)?.filter((item) =>
                      item.Name.toLowerCase().includes(selectActivePackageDataSearch.toLowerCase())
                    )?.map((packageItem) => {
                      return (
                        <div
                          className="p-1 bg-hover-grey pointer"
                          onClick={(e) => {
                            if (packageItem?.IsPackageActive == false) {
                              setActiveOrInactivePackageId(packageItem.objId_Scenario);
                              setShowUpdateCyberKPIModal(true);
                            } else {
                              setIsActiveOrInactive(packageItem.objId_Scenario, 0);
                            }
                            e.target
                              .closest(".select-active-package-dropdown")
                              .click();
                          }}
                        >
                          <div className="f-400 f-14 f-grey">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  packageItem?.Name,
                                  selectActivePackageDataSearch
                                ),
                              }}
                            />
                          </div>
                          <div className="f-400 f-12 f-darkgrey">
                            {packageItem?.PackageBillingPeriod_Text} • {switchIncludedTaxVal ? packageItem?.MonthlyPrice_Text : packageItem?.MonthlyPriceWithoutTax_Text}
                          </div>
                        </div>
                      )
                    }) : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-13 f-grey my-3">
                      {selectActivePackageDataSearch != "" ? `No results for "${selectActivePackageDataSearch}"` : "No packages yet"}
                    </div>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
      }


      {/* {userSeatingsLoader ? (
        <div className="d-flex align-items-center bg-grey radius-8 p-3 mt-3 -mb-1">
          <div className="msp-service-update-loader ml-1 mr-2 "></div>
          Please wait until we update your packages
        </div>
      ) : (
        <></>
      )} */}

      {/* Msp Packages List */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="f-500 f-14 f-black mt-4 mb-2">Custom Packages</div>
        <SearchFilterComponent
          leftSide={(
            <div className="d-flex align-items-center mt-1">
              <div className="f-400 f-14 f-grey mr-3">
                Inc. taxes
              </div>
              <SwitchButtonMulti
                id={`switch_toggle_button`}
                label={""}
                value={switchIncludedTaxVal}
                onChange={(e) => {
                  setSwitchIncludedTaxVal(!switchIncludedTaxVal);
                }}
              />
            </div>
          )}
          passedMainStyles={'clients-header'}
          searchValue={searchString}
          searchOnChange={(e) => {
            setSearchString(e.target.value);
          }}
          searchOnCross={() => {
            setSearchString("");
          }}
          passedRef={SearchInput}
          setRef={() => {
            SearchInput.current.focus()
          }}
          onSortClick={() => {
            setPackageOrder(prev => prev === 0 ? 1 : 0)
          }}
          visibilityType={VisibilityType.LIST}
          isLastButtonDisabled={getAccessStateValue("AccessState_ManagedSecurity") !== 99 || isMspClientView}
          lastButtonLabel='Add'
          lastButtonOnClick={() => {
            if (Number(ownClientID) != Number(clientId)) {
              if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                setShowCreateCustomPackageModal(true);
              }
            } else {
              setShowCreateCustomPackageModal(true);
            }
          }}
        />
      </div>
      {
        packagesLoading ? <div className="w-100 d-flex align-items-center justify-content-center my-4">
          <GreyLoader />
          <span className="text3">Loading...</span>
        </div> :
          <div className="bg-grey radius-8 border-parent">
            {pageData?.ScenarioHheaderList?.filter(
              (item) => item.IsCustomPackage === true
            ).filter(
              (item) => item.IsPackageActive === false
            ).length > 0 && (
                pageData?.ScenarioHheaderList?.filter(
                  (item) => item.IsCustomPackage === true
                ).filter(
                  (item) => item.IsPackageActive === false
                )
                  ?.filter((item) =>
                    item.Name.toLowerCase().includes(searchString.toLowerCase())
                  )
                  ?.sort((a, b) => {
                    if (packageOrder === 0) {
                      return a.Name.localeCompare(b.Name);
                    } else {
                      return b.Name.localeCompare(a.Name);
                    }
                  })
                  ?.slice(0, showUptoCustom)
                  .map((_package, _packageIndex) => {
                    const isPackageOpen = openedPackages.includes(
                      _package.objId_Scenario
                    );

                    return (
                      <div
                        className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
                    ${Number(ownClientID) == Number(clientId) ? 'pointer' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
                  `}
                        style={{
                          minHeight: '106px',
                          maxHeight: '106px'
                        }}
                      >
                        {/* Radar Chart */}
                        <div className="d-flex align-items-center w-100"
                          onClick={(e) => {
                            if (Number(ownClientID) != Number(clientId)) {
                              if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                                if (openedPackages.length > 0) {
                                  const updatedData = isPackageOpen
                                    ? openedPackages.filter(
                                      (item) => item !== _package.objId_Scenario
                                    )
                                    : [...openedPackages, _package.objId_Scenario];

                                  setOpenedPackages(updatedData);
                                  _setOpenedPackagesIds(updatedData);

                                  if (isPackageOpen) {
                                    closeScenario(_package.objId_Scenario);
                                  } else {
                                    getPackageDetails(_package.objId_Scenario);
                                    getFeaturesData(_package.objId_Scenario);
                                    getSecurityCapabilities(_package.objId_Scenario);
                                  }
                                } else {
                                  setOpenedPackages([_package.objId_Scenario]);
                                  getPackageDetails(_package.objId_Scenario);
                                  getFeaturesData(_package.objId_Scenario);
                                  getSecurityCapabilities(_package.objId_Scenario);
                                }
                                setClickedItem(true);
                              }
                            } else {
                              if (openedPackages.length > 0) {
                                const updatedData = isPackageOpen
                                  ? openedPackages.filter(
                                    (item) => item !== _package.objId_Scenario
                                  )
                                  : [...openedPackages, _package.objId_Scenario];

                                setOpenedPackages(updatedData);
                                _setOpenedPackagesIds(updatedData);

                                if (isPackageOpen) {
                                  closeScenario(_package.objId_Scenario);
                                } else {
                                  getPackageDetails(_package.objId_Scenario);
                                  getFeaturesData(_package.objId_Scenario);
                                  getSecurityCapabilities(_package.objId_Scenario);
                                }
                              } else {
                                setOpenedPackages([_package.objId_Scenario]);
                                getPackageDetails(_package.objId_Scenario);
                                getFeaturesData(_package.objId_Scenario);
                                getSecurityCapabilities(_package.objId_Scenario);
                              }
                              setClickedItem(true);
                            }
                          }}
                        >
                          <div className="mr-2">
                            <PackageListRadarUI
                              dataList={[
                                _package?.InspectScore,
                                _package?.ProtectScore,
                                _package?.DetectScore,
                                _package?.RespondScore,
                                _package?.RecoverScore,
                              ]}
                              colorPassed={priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour ?? '#9499AA'}
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="subclass d-flex align-items-center">
                                  {" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: HGHighlightText(
                                        _package?.Name,
                                        searchString
                                      ),
                                    }}
                                  /> {
                                    activeOrInactiveLoading == _package?.objId_Scenario ? <div className="msp-service-update-loader ml-2 mr-1"></div> :
                                      <span className="f-500 f-12 txt-blue ml-2">
                                        {_package?.IsPackageActive && 'Active'}
                                      </span>
                                  }
                                </div>
                                <div className="f-12 f-darkgrey">
                                  {TrimString(_package?.Description, 50) ?? ""}
                                </div>
                                {
                                  _package?.MappedPrice > 0 && _package?.Contribution > 0 ?
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center f-darkgrey line-through-text mr-2">
                                        {
                                          switchIncludedTaxVal ? _package?.MonthlyPrice_Text?.split(' ')[0] : _package?.MonthlyPriceWithoutTax_Text?.split(' ')[0]
                                        }
                                      </div>
                                      <div className="d-flex align-items-center txt-blue">
                                        {
                                          `${_package?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${switchIncludedTaxVal ?
                                            (_package?.MonthlyPrice - _package?.Contribution)?.toFixed(2) :
                                            (parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - _package?.Contribution)?.toFixed(2)} /${_package?.BillingPeriod_Text}`
                                        }
                                      </div>
                                      {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                    </div>
                                    :
                                    <div className="d-flex align-items-center f-darkgrey">
                                      {
                                        switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text
                                      }
                                      {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                    </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="d-flex justify-content-end align-content-end" style={{
                          height: '100%',
                          width: '80px',
                          marginTop: '10px'
                        }}>
                          <div>
                            <div className="d-flex justify-content-end align-content-end" style={{
                              marginBottom: '30px'
                            }}>
                              <Dropdown className="transparennt-wrapper-dropdown-menu">
                                <Dropdown.Toggle>
                                  <img
                                    alt=""
                                    className="pointer mx-2"
                                    src="/images/settings-v2/horizontal-dots.svg"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-0">
                                  <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={() => {
                                      history.push(`/mspv2/msp-price-calculator/${_package.objId_Scenario}`, {
                                        backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                                        allPackagesList: pageData?.ScenarioHheaderList,
                                        clientId: clientId,
                                      });
                                    }}
                                  >
                                    Edit
                                  </div>
                                  <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                      if (_package?.IsPackageActive == false) {
                                        setActiveOrInactivePackageId(_package.objId_Scenario);
                                        setShowUpdateCyberKPIModal(true);
                                      } else {
                                        setIsActiveOrInactive(_package.objId_Scenario, 0);
                                      }
                                      e.target
                                        .closest(".transparennt-wrapper-dropdown-menu")
                                        .click();
                                    }}
                                  >
                                    Set as {_package.IsPackageActive ? 'Inactive' : 'Active'}
                                  </div>
                                  <div
                                    className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                      setMapCurrentSpendPackageId(_package?.objId_Scenario);
                                      setMapCurrentSpendPackagePeriod(_package?.PackageBillingPeriod_Text)
                                      setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text);
                                      setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? _package?.MonthlyPrice : parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')));
                                      setShowMapCurrentSpendModal(true);
                                      const drag = document?.getElementById("map-current-spend-modal");
                                      drag.style.display = "block";
                                      e.target
                                        .closest(".transparennt-wrapper-dropdown-menu")
                                        .click();
                                    }}
                                  >
                                    Add Contribution
                                  </div>
                                  <div className="hg-border-top mx-3"></div>
                                  <div
                                    className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                    onClick={(e) => {
                                      checkPackageIfInUse(_package?.objId_Scenario);
                                      e.target
                                        .closest(".transparennt-wrapper-dropdown-menu")
                                        .click();
                                    }}
                                  >
                                    Delete
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div
                              className=""
                              style={{
                                // height: '100%',
                                // marginTop: '20px'
                              }}>
                              {
                                priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario) ? <div className="d-flex justify-content-end align-content-end align-items-center">
                                  <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                                  <div
                                    className="rounded-full ml-2 f-400 f-12"
                                    style={{
                                      width: "12px",
                                      height: "12px",
                                      backgroundColor: priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour,
                                      color: "#9499AA"
                                    }}
                                  />
                                </div> :
                                  <div style={{
                                    height: '20px'
                                  }}></div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
          </div>
      }

      {pageData?.ScenarioHheaderList?.filter(
        (item) => item.IsCustomPackage === true
      )?.length > 0 &&
        pageData?.ScenarioHheaderList?.filter(
          (item) => item.IsCustomPackage === true
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length === 0 &&
        searchString !== "" && (
          <div className="f-grey f-500 w-100 text-center mt-5">
            No results found!
          </div>
        )}

      {pageData?.ScenarioHheaderList?.filter(
        (item) => item.IsCustomPackage === true
      )?.length > 0 &&
        pageData?.ScenarioHheaderList?.filter(
          (item) => item.IsCustomPackage === true
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length > 5 && (
          <>
            <Button
              className="hg-cancel-btn mt-3"
              onClick={() => {
                if (showUptoCustom === 5) {
                  setShowUptoCustom(10000000);
                } else {
                  setShowUptoCustom(5);
                }
              }}
            >
              {showUptoCustom === 5 ? "Show more" : "Show less"}
            </Button>
          </>
        )}

      <div className="d-flex align-items-center justify-content-between">
        <div className="f-500 f-14 f-black mt-4 mb-2">MSPs Standard Packages</div>
      </div>

      {
        packagesLoading ? <div className="w-100 d-flex align-items-center justify-content-center my-4">
          <GreyLoader />
          <span className="text3">Loading...</span>
        </div> :
          <div className="bg-grey radius-8 border-parent">
            {pageData?.ScenarioHheaderList?.filter(
              (item) => item.IsCustomPackage === false
            )?.filter(
              (item) => item.IsPackageActive === false
            ).length > 0 ? (
              pageData?.ScenarioHheaderList?.filter(
                (item) => item.IsCustomPackage === false
              )?.filter(
                (item) => item.IsPackageActive === false
              )
                ?.filter((item) =>
                  item.Name.toLowerCase().includes(searchString.toLowerCase())
                )
                ?.sort((a, b) => {
                  if (packageOrder === 0) {
                    return a.Name.localeCompare(b.Name);
                  } else {
                    return b.Name.localeCompare(a.Name);
                  }
                })
                ?.slice(0, showUptoStandard)
                .map((_package, _packageIndex) => {
                  const isPackageOpen = openedPackages.includes(
                    _package.objId_Scenario
                  );

                  return (
                    <div
                      className={`border-item d-flex align-items-center justify-content-between row-padding bg-hover-dark-grey hover-on-show-parent
                    ${Number(ownClientID) == Number(clientId) ? 'pointer' : viewOnlyAccess("AccessState_ManagedSecurity") ? 'cursor-default' : 'pointer'}
                  `}
                      style={{
                        minHeight: '106px',
                        maxHeight: '106px'
                      }}
                    >
                      {/* Radar Chart */}
                      <div className="d-flex align-items-center w-100"
                        onClick={(e) => {
                          console.log('here')
                          if (Number(ownClientID) != Number(clientId)) {
                            console.log('1')
                            if (!viewOnlyAccess("AccessState_ManagedSecurity")) {
                              if (openedPackages.length > 0) {
                                const updatedData = isPackageOpen
                                  ? openedPackages.filter(
                                    (item) => item !== _package.objId_Scenario
                                  )
                                  : [...openedPackages, _package.objId_Scenario];

                                setOpenedPackages(updatedData);
                                _setOpenedPackagesIds(updatedData);

                                if (isPackageOpen) {
                                  closeScenario(_package.objId_Scenario);
                                } else {
                                  getPackageDetails(_package.objId_Scenario);
                                  getFeaturesData(_package.objId_Scenario);
                                  getSecurityCapabilities(_package.objId_Scenario);
                                }
                              } else {
                                setOpenedPackages([_package.objId_Scenario]);
                                getPackageDetails(_package.objId_Scenario);
                                getFeaturesData(_package.objId_Scenario);
                                getSecurityCapabilities(_package.objId_Scenario);
                              }
                            }
                          } else {
                            console.log('')
                            if (openedPackages.length > 0) {
                              const updatedData = isPackageOpen
                                ? openedPackages.filter(
                                  (item) => item !== _package.objId_Scenario
                                )
                                : [...openedPackages, _package.objId_Scenario];

                              setOpenedPackages(updatedData);
                              _setOpenedPackagesIds(updatedData);

                              if (isPackageOpen) {
                                closeScenario(_package.objId_Scenario);
                              } else {
                                getPackageDetails(_package.objId_Scenario);
                                getFeaturesData(_package.objId_Scenario);
                                getSecurityCapabilities(_package.objId_Scenario);
                              }
                            } else {
                              setOpenedPackages([_package.objId_Scenario]);
                              getPackageDetails(_package.objId_Scenario);
                              getFeaturesData(_package.objId_Scenario);
                              getSecurityCapabilities(_package.objId_Scenario);
                            }
                          }
                        }}
                      >
                        <div className="mr-2">
                          <PackageListRadarUI
                            dataList={[
                              _package?.InspectScore,
                              _package?.ProtectScore,
                              _package?.DetectScore,
                              _package?.RespondScore,
                              _package?.RecoverScore,
                            ]}
                            colorPassed={priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour ?? '#9499AA'}
                          />
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="subclass d-flex align-items-center">
                                {" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: HGHighlightText(
                                      _package?.Name,
                                      searchString
                                    ),
                                  }}
                                /> {
                                  activeOrInactiveLoading == _package?.objId_Scenario ? <div className="msp-service-update-loader ml-2 mr-1"></div> :
                                    <span className="f-500 f-12 txt-blue ml-2">
                                      {_package?.IsPackageActive && 'Active'}
                                    </span>
                                }
                              </div>
                              <div className=" f-grey">
                                {_package?.Description ?? ""}
                              </div>
                              {
                                _package?.MappedPrice > 0 && _package?.Contribution > 0 ?
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center f-darkgrey line-through-text mr-2">
                                      {
                                        switchIncludedTaxVal ? _package?.MonthlyPrice_Text?.split(' ')[0] : _package?.MonthlyPriceWithoutTax_Text?.split(' ')[0]
                                      }
                                    </div>
                                    <div className="d-flex align-items-center txt-blue">
                                      {
                                        `${_package?.MonthlyPrice_Text?.match(/[^\d.,/\s]+/)[0]}${switchIncludedTaxVal ?
                                          (_package?.MonthlyPrice - _package?.Contribution)?.toFixed(2) :
                                          (parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')) - _package?.Contribution)?.toFixed(2)} /${_package?.BillingPeriod_Text}`
                                      }
                                    </div>
                                    {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                  </div>
                                  :
                                  <div className="d-flex align-items-center f-darkgrey">
                                    {switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text}
                                    {savingAssets && <div className="msp-service-update-loader ml-2 mr-1"></div>}
                                  </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end align-content-end" style={{
                        height: '100%',
                        width: '80px',
                        marginTop: '10px'
                      }}>
                        <div>
                          <div className="d-flex justify-content-end align-content-end" style={{
                            marginBottom: '30px'
                          }}>
                            <Dropdown className="transparennt-wrapper-dropdown-menu">
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  className="pointer mx-2"
                                  src="/images/settings-v2/horizontal-dots.svg"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-0">
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={() => {
                                    history.push(`/mspv2/msp-price-calculator/${_package.objId_Scenario}`, {
                                      backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                                      allPackagesList: pageData?.ScenarioHheaderList,
                                      clientId: clientId,
                                    });
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    if (_package?.IsPackageActive == false) {
                                      setActiveOrInactivePackageId(_package.objId_Scenario);
                                      setShowUpdateCyberKPIModal(true);
                                    } else {
                                      setIsActiveOrInactive(_package.objId_Scenario, 0);
                                    }
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Set as {_package.IsPackageActive ? 'Inactive' : 'Active'}
                                </div>
                                <div
                                  className="greyText2 f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    setMapCurrentSpendPackageId(_package?.objId_Scenario);
                                    setMapCurrentSpendPackagePeriod(_package?.PackageBillingPeriod_Text);
                                    setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? _package?.MonthlyPrice_Text : _package?.MonthlyPriceWithoutTax_Text);
                                    setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? _package?.MonthlyPrice : parseFloat(_package?.MonthlyPriceWithoutTax_Text.replace(/[^\d.-]/g, '')));
                                    setShowMapCurrentSpendModal(true);
                                    const drag = document?.getElementById("map-current-spend-modal");
                                    drag.style.display = "block";
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Add Contribution
                                </div>
                                <div className="hg-border-top mx-3"></div>
                                <div
                                  className="redText f-14 f-400 py-2 px-3 bg-hover-dark-grey pointer"
                                  onClick={(e) => {
                                    checkPackageIfInUse(_package?.objId_Scenario);
                                    e.target
                                      .closest(".transparennt-wrapper-dropdown-menu")
                                      .click();
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div>
                            {
                              priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario) ? <div className="d-flex align-items-center">
                                <div className="f-400 f-12" style={{ color: "#9499AA" }}>On chart</div>
                                <div
                                  className="rounded-full ml-2 f-400 f-12"
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: priceCalculatorState?.tabsData?.find(obj => obj?.ScenarioHeader?.objId_Scenario === _package?.objId_Scenario)?.circleColour,
                                    color: "#9499AA"
                                  }}
                                />
                              </div> :
                                <div style={{
                                  height: '20px'
                                }}></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="border-item d-flex align-items-center justify-content-center p-4">
                No MSPs standard packages yet
              </div>
            )}
          </div>
      }

      {pageData?.ScenarioHheaderList?.filter(
        (item) => item.IsCustomPackage === false
      )?.length > 0 &&
        pageData?.ScenarioHheaderList?.filter(
          (item) => item.IsCustomPackage === false
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length === 0 &&
        searchString !== "" && (
          <div className="f-grey f-500 w-100 text-center mt-5">
            No results found!
          </div>
        )}

      {pageData?.ScenarioHheaderList?.filter(
        (item) => item.IsCustomPackage === false
      )?.length > 0 &&
        pageData?.ScenarioHheaderList?.filter(
          (item) => item.IsCustomPackage === false
        )?.filter((item) =>
          item.Name.toLowerCase().includes(searchString.toLowerCase())
        )?.length > 5 && (
          <>
            <Button
              className="hg-cancel-btn mt-3"
              onClick={() => {
                if (showUptoStandard === 5) {
                  setShowUptoStandard(10000000);
                } else {
                  setShowUptoStandard(5);
                }
              }}
            >
              {showUptoStandard === 5 ? "Show more" : "Show less"}
            </Button>
          </>
        )}
      <CustomDeleteModal
        show={deleteModalConfig.show}
        hideModal={deleteModalConfig.hideModal}
        deleteMspFunction={deleteModalConfig.deleteFunction}
        deleteMspSellConfig={deleteModalConfig}
        deleteMessage={deleteModalConfig.deleteMessage}
        deleteType={deleteModalConfig.deleteType}
      />
      <SourcesModal
        show={showSourcesModal}
        tempUserDetailsData={tempUserDetailsData}
        startData={defaultUserDeviceDetailsStartData}
        setTempUserDetailsData={setTempUserDetailsData}
        saveAssets={saveAssets}
        _setAssetsFromIntegrations={setAssetsFromIntegrations}
        getPageData={getPageData}
        hideModal={() => {
          setShowSourcesModal(false);
          const drag = document?.getElementById("sources-modal");
          drag.style.display = "none";
        }}
        clientId={clientId}
      />
      <UpdateCyberKPIModal
        show={showUpdateCyberKPIModal}
        clientId={clientId}
        packageId={activeOrInactivePackageId}
        updateCyberKPI={updateCyberKPI}
        setIsActiveOrInactive={setIsActiveOrInactive}
        hideModal={() => {
          setShowUpdateCyberKPIModal(false);
          setActiveOrInactivePackageId(0);
        }}
      />
      <InfoModal
        show={showInfoModal}
        label={"Package in use and can't be deleted"}
        children={<div>
          <div className="f-500 f-14 f-black mb-2">
            List of customers it is applied to:
          </div>
          {
            inUseData?.IntegrationClient?.map((clientData) => {
              return (
                <div className="d-flex align-items-center pb-1">
                  <ImageWithInitials
                    initials={clientData?.Intials}
                    width={20}
                    background={"purple"}
                  />
                  <div className="f-400 f-14 f-grey ml-2">
                    {clientData?.ClientName}
                  </div>
                </div>
              )
            })
          }
        </div>}
        hideModal={() => {
          setShowInfoModal(false);
        }}
      />
    </div>
  );
};

const PriceCalculatorPostureData = ({
  tabsData,
  activateChartIndex,
  hideBanner,
  setShowCyberGroupModal,
  priceCalculatorState,
  setSwitchIncludedTaxVal = () => { },
  switchIncludedTaxVal
}) => {
  const [showBanner, setShowBanner] = useState(true);
  useEffect(() => {
    if (tabsData.length <= 1) {
      activateChartIndex(0);
    }
  }, [tabsData]);

  function ToggleAccordionPostureData(e, rowIndex) {
    let event = e;

    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
      activateChartIndex(0);
    } else {
      activateChartIndex(rowIndex);
      let wrapper = event.target.closest(".posture-data-expandables");
      let rows = wrapper.getElementsByClassName("point-row");
      for (let index = 0; index < rows.length; index++) {
        rows[index].querySelector(".panel").style.maxHeight = null;
        rows[index].querySelector("#chevron").classList.remove("rotate180");
      }
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }

  return (
    <div className="posture-data-expandables">
      <div
        className={`w-100 point-row mb-3`}
      >
        <div
          className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hover-grey radius-4"
          onClick={(e) => {
            ToggleAccordionPostureData(e, 0);
            e.preventDefault();
          }}
        >
          <div className="d-flex align-items-center f-500 f-15">
            <div
              className="rounded-full mx-2"
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: tabsData.circleColour,
              }}
            />
            {tabsData?.Name}
          </div>
          <div className="w-fit-content d-flex align-items-center">
            <img
              alt=""
              id="chevron"
              className="chevron"
              src="/images/attack-surface/chevron-up.svg"
            />
          </div>
        </div>


        <div className="panel" style={{ maxHeight: '183px' }}>
          <div className="f-darkgrey f-12 f-500 pl-4 py-2">
            Current scores
          </div>
          <div className="d-flex">
            <CyberComplianceGauge
              value={tabsData?.ScenarioHeader?.CyberCompliance}
            />

            <CyberScoreGauge value={tabsData?.ScenarioHeader?.CyberScore} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PackageListRadarUI = ({ dataList, colorPassed, passedWidth, passedHeight }) => {
  const updatedData = useMemo(() => {
    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: [
        {
          data: dataList ?? [0, 0, 0, 0, 0],
          pointRadius: [0, 0, 0, 0, 0],
          datalabels: {
            display: false,
          },
          backgroundColor: colorPassed ? hexToRGBA(colorPassed, 0.24) : "rgba(0, 143, 212, 0.24)",
          // backgroundColor: `${"#5244B3"}30`,
          borderColor: colorPassed ?? `${"#008FD4"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: colorPassed ?? "rgba(0, 143, 212, 1)",
          borderWidth: 1,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current Posture",
        },
      ],
    };
  }, [dataList]);
  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: /*40*/50,
          min: 0,
          ticks: {
            display: false,
            stepSize: 10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    // return opts;
  }, []);
  return (
    <div>
      <MyRadarChart
        id="actial-radar"
        // redraw={true}
        height={passedWidth ?? 80}
        width={passedHeight ?? 80}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
    </div>
  );
};

const PerSeatBreakdown = ({
  data,
  updateSeatAssetDataApi,
  resetAssetSeatDataApi,
  isMspClientView,
  packageDetails,
  getPackageDetails,
  setIsSmthOverriden = () => { },
  setSwitchValPassed = () => { },
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
  switchIncludedTaxVal,
  setMapCurrentSpendPackagePrice = () => { },
  setShowMapCurrentSpendModal = () => { },
  discount,
  setDiscount = () => { },
  switchVal,
  updatePackageMappedPrice = () => { },
  setContribLoading = () => { },
  contribLoading,
  setIsFromAddContribTable = () => { },
  showContribReset,
  setShowContribReset = () => { },
  contributionValue,
  ownClientID
}) => {
  const [editState, setEditState] = useState(false);

  const [resetting, setResetting] = useState(false);
  const [priceResetting, setPriceResetting] = useState(false);
  const [seatPriceResetting, setSeatPriceResetting] = useState(false);

  const [saving, setSaving] = useState(false);

  // Dynamic Implementation
  const [seatBreakDownData, setSeatBreakDownData] = useState({});
  const isTableEdited =
    seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited;
  // const [switchVal, setSwitchVal] = useState(false);
  // const [overridenTax, setOverridenTax] = useState([{
  //   packageId: 0,
  //   newTax: {
  //     // "ObjectId": 0,
  //     // "TaxCode": "",
  //     // "TaxName": "",
  //     // "TaxRate": 0,
  //     // "IsDefault": false,
  //     // "IntegrationId": 0
  // }}]);

  // useEffect(() => {
  //   setSwitchValPassed(switchVal)
  // }, [switchVal])

  useEffect(() => {
    setSeatBreakDownData(data);
  }, [data, data.MSPPackagePrice]);

  function ToggleAccordionSinglePostureData(e) {
    let event = e;

    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }
  let totalTax =
    seatBreakDownData?.MSPPackagePrice?.Subtotal *
    seatBreakDownData?.MSPPackagePrice?.Tax *
    0.01;
  // let totalCost = (
  //   seatBreakDownData?.MSPPackagePrice?.Subtotal -
  //   seatBreakDownData?.MSPPackagePrice?.Discount +
  //   totalTax
  // ).toFixed(2);
  let totalCost = Number(seatBreakDownData?.MSPPackagePrice?.TotalCost)?.toFixed(2);

  // overridenTax.findIndex((item, index) =>  {
  //   if (item.packageId === data?.ScenarioHeader?.objId_Scenario) {
  //     return index;
  //   }
  // })
  function handleSave(isPricing = false) {
    setSaving(true);

    updateSeatAssetDataApi(seatBreakDownData?.MSPPackagePrice, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
            setSaving(false);
            setEditState(false);
          })
          .catch((err) => {
            setSaving(false);

            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setSaving(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function handleReset(/*isPricing = false*/) {
    // if (isPricing) {
    setPriceResetting(true);
    // } else {
    //   setSeatPriceResetting(true);
    // }
    resetAssetSeatDataApi(seatBreakDownData?.ScenarioHeader?.objId_Scenario, 0)
      .then((res) => {
        getPackageDetails(seatBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
            // if (isPricing) {
            setPriceResetting(false);
            // } else {
            // setSeatPriceResetting(false);
            // }
          })
          .catch((err) => {
            // if (isPricing) {
            setPriceResetting(false);
            // } else {
            // setSeatPriceResetting(false);
            // }
            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        // if (isPricing) {
        setPriceResetting(false);
        // } else {
        // setSeatPriceResetting(false);
        // }
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  console.log('seatBreakDownData', seatBreakDownData)

  return (
    <div>
      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <div className="f-12 f-darkgrey w-100">
            Price Breakdown
          </div>
          <div className="d-flex align-items-center">
            {isMspClientView ? (
              <div></div>
            ) : (
              <div>
                <div className="d-flex align-items-center">
                  {(seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited || seatBreakDownData?.MSPPackagePrice?.IsSellEddited) && (
                    <div
                      className="f-blue f-500 mr-2 pointer d-flex"
                      onClick={() => {
                        handleReset(true);
                        // setIsSmthOverriden(true);
                      }}
                    >
                      <img
                        alt=""
                        className={`mr-1 ${/*resetting*/priceResetting && "rotate-full"}`}
                        src="/images/msp/blue-load-icon.svg"
                      />
                      {/*resetting*/priceResetting ? "Resetting.." : "Reset"}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="">
          {/* Req seat table */}
          <div className="border-parent  radius-8">
            <div className="per_asset_period-breakdown_table border-item f-grey">
              <div className="f-12 f-500 f-darkgrey  pl-2">Asset Type</div>
              <div className="f-12 f-500 f-darkgrey">Inc. per Seat</div>
              <div className="f-12 f-500 f-darkgrey">Client Qty</div>
              <div className="f-12 f-500 f-darkgrey">Seats Req</div>
            </div>
            {seatBreakDownData?.MSPPackagePrice?.PackagePerSeat_List?.map(
              (data, dataIndex) => {
                return (
                  <div className="per_asset_period-breakdown_table  border-item">
                    <div className="f-500  pl-2">{data.Name} </div>
                    <div className="f-500 f-grey">{data.PerSeat} </div>
                    <div className="f-500 f-grey">{data.ClientQty} </div>
                    <div className="f-600 f-grey">{data.SeatRequired} </div>
                  </div>
                );
              }
            )}
            <div className="per_asset_period-breakdown_table  border-item">
              <div className="d-flex align-items-center">
                <div className="f-500  px-2">
                  Req. Seats
                </div>

                <OverlayTrigger
                  placement="bottom"
                  trigger={["hover", "focus"]}
                  delay={{ show: 200, hide: 250 }}
                  overlay={
                    <Tooltip id={`tooltip-1365`}>
                      Required seats is calculated from the asset type which has the highest qty requirement vs qty included per seat
                    </Tooltip>
                  }
                >
                  <img
                    alt=""
                    src="/images/calculator/info-light.svg"
                  />
                </OverlayTrigger>
              </div>
              <div className="f-500 f-grey"></div>
              <div className="f-500 f-grey"></div>
              <div>
                <div style={{
                  width: '70%',
                  marginBottom: '4px',
                  marginLeft: '-14px'
                }}>
                  <IncreaseDecreaseNumbersComponentAlwaysHighlight
                    type={"number"}
                    // floatValue={false}
                    value={seatBreakDownData?.MSPPackagePrice?.RequiredSeatsOverriden}
                    isBlue={seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited}
                    isBold={seatBreakDownData?.MSPPackagePrice?.IsRequiredSeatsEdited}
                    onChange={(value) => {
                      if (seatBreakDownData.MSPPackagePrice.RequiredSeatsOverriden != Number(value)) {
                        let updated_data = { ...seatBreakDownData };
                        updated_data.MSPPackagePrice.RequiredSeatsOverriden = Number(value);
                        updated_data.MSPPackagePrice.IsRequiredSeatsEdited = true;

                        setSeatBreakDownData(updated_data);
                        return true;
                      }
                    }}
                    onBlur={() => {
                      console.log('save - 1')
                      handleSave();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-3">
        <div className="d-flex align-items-center justify-content-between w-100 mb-1">
          <div className="f-500 f-12 f-darkgrey">
            Seat Pricing
          </div>
          {/*
            seatBreakDownData?.MSPPackagePrice?.IsSellEddited &&
            <div
              className="f-blue f-500 mr-2 pointer d-flex"
              onClick={() => {
                handleReset();
                setIsSmthOverriden(true);
              }}
            >
              <img
                alt=""
                className={`mr-1 ${priceResetting && "rotate-full"}`}
                src="/images/msp/blue-load-icon.svg"
              />
              {resetting ? "Resetting.." : "Reset"}
            </div>
          */}
        </div>

        <div className="border-block  radius-8">
          {
            switchVal &&
            <div className="border-item f-12 f-500 f-darkgrey px-2 py-2 seat_pricing_table">
              <div className="f-400 f-14 f-black">Seat Cost</div>
              <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{seatBreakDownData?.MSPPackagePrice?.Cost_Text}</div>
            </div>
          }

          <div className="border-item f-12 f-500 f-darkgrey px-2 py-2 seat_pricing_table">
            <div className="f-400 f-14 f-black">Seat RRP</div>
            <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{seatBreakDownData?.MSPPackagePrice?.RRP_Text?.replace("/", " /")}</div>
          </div>

          <div className="border-item f-12 f-500 f-darkgrey px-2 seat_pricing_table">
            <div className="f-400 f-14 f-black">Seat Price</div>
            <div className="d-flex"
              style={{
                marginBottom: '4px',
                marginLeft: '-14px'
              }}>
              <IncreaseDecreaseNumbersComponentAlwaysHighlight
                type={"number"}
                value={seatBreakDownData?.MSPPackagePrice?.SellOverridden}
                isBlue={seatBreakDownData?.MSPPackagePrice?.IsSellEddited}
                children={<div className={`f-400 f-14 ${seatBreakDownData?.MSPPackagePrice?.IsSellEddited ? 'txt-blue' : 'f-black'}`}
                  style={{ marginBottom: '1px' }}
                >
                  {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                </div>}
                children2={<div className={`f-400 f-14 ${seatBreakDownData?.MSPPackagePrice?.IsSellEddited ? 'txt-blue' : 'f-black'}`}>
                  /{seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
                </div>}
                onChange={(value) => {
                  if (seatBreakDownData.MSPPackagePrice.SellOverridden != value) {
                    let updated_data = { ...seatBreakDownData };
                    updated_data.MSPPackagePrice.SellOverridden = value;
                    updated_data.MSPPackagePrice.IsSellEddited = true;

                    // handleSave();
                    return true;
                  }
                }}
                onBlur={() => {
                  console.log('save - 2')
                  handleSave();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="d-flex align-items-center justify-content-between pointer">
          <div className="f-500 f-12 f-darkgrey w-100">
            Contribution
          </div>
          <div className="d-flex align-items-center">
            {contribLoading && <div className="msp-service-update-loader ml-2 mr-1"></div>}
            {
              !contribLoading && showContribReset ? <div
                className="txt-blue pointer d-flex align-items-center"
                onClick={() => {
                  setResetting(true);
                  setShowContribReset(false);
                  setContribLoading(true);
                  updatePackageMappedPrice(packageDetails?.ScenarioHeader?.objId_Scenario);
                  setResetting(false);
                }}
              >
                <img
                  alt=""
                  className={`mr-1 ${resetting && "rotate-full"}`}
                  src="/images/msp/blue-load-icon.svg"
                />
                {resetting ? "Resetting.." : "Reset"}
              </div> : <></>
            }
          </div>
        </div>

        {/* Contribution table */}
        <div className="border-parent radius-8">
          <div
            className="border-item f-12 f-500 f-darkgrey p-2 contribution_table">
            <div>Recurring Item</div>
            <div>Qty.</div>
            <div>Price</div>
            <div>Total</div>
            <div>Period</div>
          </div>
          {seatBreakDownData?.MSPPackagePrice?.PackageMapCurrentSpend_List
            ?.map((data, dataIndex) => {
              return (
                <>
                  {data?.ItemName && data?.ItemName !== null && (
                    <div
                      className="border-item p-2 contribution_table">
                      <div className="f-400 f-14 f-black">{data.ItemName}</div>
                      <div className="f-400 f-14 greyText2">{data.Quantity}</div>
                      <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{data.Price}</div>
                      <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{data.Total}</div>
                      <div className="f-400 f-14 greyText2">{data.Period_Text}</div>
                      <img
                        alt=""
                        width={10}
                        src="/images/pencil-icon.svg"
                        className="mx-2 pointer"
                        onClick={(e) => {
                          setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
                          setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
                          setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${seatBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                          setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(seatBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : seatBreakDownData?.MSPPackagePrice?.Subtotal);
                          setShowMapCurrentSpendModal(true);
                          setIsFromAddContribTable(true);
                          const drag = document?.getElementById("map-current-spend-modal");
                          drag.style.display = "block";
                        }}
                      />
                    </div>
                  )}
                </>
              );
            })}

          <div className="f-500 f-12 greyText2  p-2 border-item pointer"
            onClick={() => {
              setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
              setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
              setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${seatBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
              setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(seatBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : seatBreakDownData?.MSPPackagePrice?.Subtotal);
              setShowMapCurrentSpendModal(true);
              setIsFromAddContribTable(true);
              const drag = document?.getElementById("map-current-spend-modal");
              drag.style.display = "block";
            }}
          >
            + Add
          </div>

          <div className="contribution_table p-2">
            <div className="f-400 f-14 greyText2">
              Total
            </div>
            <div></div>
            <div></div>
            <div>
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(Number(contributionValue)?.toFixed(2))}
            </div>
            <div className="f-400 f-14 greyText2">
              /{seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
            </div>
          </div>
        </div>
      </div>

      {/* Tota pricing Table */}
      <div className="">
        <div className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between">
          Summary
        </div>
        <div className="border-parent  radius-8">
          <div className="per_asset_total_pricing_table-updated border-item">
            <div className="f-500  pl-2">Subtotal</div>
            <div></div>
            <div className="f-500 f-grey">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(seatBreakDownData?.MSPPackagePrice?.Subtotal)?.toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-500 pl-2">
              Contribution
            </div>
            <div className="f-500 txt-blue underline pointer"
              onClick={() => {
                setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
                setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
                setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${seatBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax).toFixed(2) : seatBreakDownData?.MSPPackagePrice?.Subtotal);
                setShowMapCurrentSpendModal(true);
                setIsFromAddContribTable(true);
                const drag = document?.getElementById("map-current-spend-modal");
                drag.style.display = "block";
              }}
            >
              -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(Number(contributionValue)?.toFixed(2))}
            </div>

            <div className="f-500 f-grey"
              onClick={() => {

              }}
            >
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(seatBreakDownData?.MSPPackagePrice?.Subtotal + seatBreakDownData?.MSPPackagePrice?.Tax - contributionValue).toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-400 f-14 f-black pl-2">
              Discount
            </div>
            <div className="f-400 f-14"
              style={{
                marginLeft: '-14px'
              }}
            >
              <IncreaseDecreaseNumbersComponentAlwaysHighlight
                type={"number"}
                floatValue={true}
                children={<div
                  className="f-400 f-14 f-black"
                  style={{
                    width: '17px',
                    marginBottom: '1px'
                  }}
                >
                  -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                </div>}
                value={discount ?? 0}
                onChange={(value) => {
                  setDiscount(value);
                  return true;
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>

            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount).toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-500 pl-2">
              {`
              Tax @ ${seatBreakDownData?.TaxRates_Selected?.TaxRate}% (${seatBreakDownData?.TaxName})
            ` ?? "Tax @ 0% (No Taxable)"}
            </div>
            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)?.toFixed(2)}
              {/* {Number(seatBreakDownData?.MSPPackagePrice?.Tax)?.toFixed(2)} */}
            </div>
            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(
                seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
                ((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)
              ).toFixed(2)}{" "}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-600  pl-2">Total</div>
            <div></div>
            <div className="f-600 f-grey">
              {renderPriceText(
                seatBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                (
                  seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
                  ((seatBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * seatBreakDownData?.TaxRates_Selected?.TaxRate / 100)
                )?.toFixed(2),
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Period Break down Table */}
      {
        switchVal &&
        <div className="">
          <div className="my-2 f-12 f-darkgrey d-flex align-items-center justify-content-between">
            Margin
          </div>
          <div className="border-block  radius-8">
            <div
              className="border-item f-grey per_asset_period-breakdown_table">
              <div className="  pl-2">Period</div>
              <div>Cost</div>
              <div>Sell</div>
              <div>Margin</div>
              <div>Margin %</div>
            </div>
            {seatBreakDownData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
              (data, index) => {
                return (
                  <div
                    className="border-item per_asset_period-breakdown_table">
                    <div className="f-500  pl-2">{data?.Period}</div>
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Cost)?.toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount)?.toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-500 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount - data?.Cost)?.toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-500 f-grey">
                      {
                        isNaN((100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell))?.toFixed(0)) ? 0 : (100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell))?.toFixed(0)
                      }%
                    </div>
                  </div>
                );
              }
            )}
            {
              packageDetails?.Nist_All?.some(item => item?.IsOnDemand === true) ?
                <div className="px-2 py-3">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      width={13}
                      height={13}
                      src="/images/info-blue.svg"
                      className="mr-2"
                    />
                    <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                  </div>
                  <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                </div> : <></>
            }
          </div>
        </div>
      }
    </div>
  );
};

const PerAssetBreakdown = ({
  data,
  updateSeatAssetDataApi,
  resetAssetSeatDataApi,
  isMspClientView,
  packageDetails,
  getPackageDetails,
  setIsSmthOverriden = () => { },
  setSwitchValPassed = () => { },
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
  switchIncludedTaxVal,
  setMapCurrentSpendPackagePrice = () => { },
  setShowMapCurrentSpendModal = () => { },
  discount,
  setDiscount = () => { },
  switchVal,
  updatePackageMappedPrice = () => { },
  setContribLoading = () => { },
  contribLoading,
  setIsFromAddContribTable = () => { },
  setShowContribReset = () => { },
  showContribReset,
  contributionValue,
  ownClientID
}) => {
  const [editState, setEditState] = useState(false);
  const tableData = [
    {
      name: "User",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Device",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Mobile Device",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Router/Firewall",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
    {
      name: "Server",
      qty: 6,
      unitCost: 5,
      unitSell: 30,
      isEditedUnitSell: false,
      sellTotal: 50,
    },
  ];
  const [resetting, setResetting] = useState(false);
  const [saving, setSaving] = useState(false);

  // Dynamic Implementation
  const [assetBreakDownData, setAssetBreakDownData] = useState({});
  const isTableEdited =
    assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List?.some(
      (item) => item.IsEditedUnitSell === true || item.IsEditedQuantity === true
    );

  useEffect(() => {
    setAssetBreakDownData(data);
  }, [data, data.MSPPackagePrice]);

  function ToggleAccordionSinglePostureData(e) {
    let event = e;
    var parent = event.target.closest(".point-row");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      parent.querySelector("#chevron").classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + 5 + "px";
    }
  }
  let totalTax =
    assetBreakDownData?.MSPPackagePrice?.Subtotal *
    assetBreakDownData?.MSPPackagePrice?.Tax *
    0.01;
  let totalCost = Number(assetBreakDownData?.MSPPackagePrice?.TotalCost)?.toFixed(2);

  function handleSave() {
    setSaving(true);

    updateSeatAssetDataApi(assetBreakDownData?.MSPPackagePrice, 1)
      .then((res) => {
        getPackageDetails(assetBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
            setSaving(false);
            setEditState(false);
          })
          .catch((err) => {
            setSaving(false);

            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setSaving(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function handleReset() {
    setResetting(true);
    resetAssetSeatDataApi(assetBreakDownData?.ScenarioHeader?.objId_Scenario, 1)
      .then((res) => {
        getPackageDetails(assetBreakDownData?.ScenarioHeader?.objId_Scenario, true)
          .then((res) => {
            setResetting(false);
          })
          .catch((err) => {
            setResetting(false);
            CallToast("Something went wrong", false, false, "v2style");
          });
      })
      .catch((err) => {
        setResetting(false);

        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [isFirstOpen, setIsFirstOpen] = useState([true, false, true, true]);
  function ToggleAccordion(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log("e.targeta", e.targeta);
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpen;
    updData[blockId] = !updData[blockId];
    setIsFirstOpen(updData);
  }

  return (
    <div>
      <div className="f-600 f-14 f-black mt-5 my-2">
        Financial Breakdown
      </div>

      <div className="">
        <div className="d-flex align-items-center justify-content-between">
          <div className="f-12 f-darkgrey w-100">
            Price Breakdown
          </div>
          <div className="d-flex align-items-center">
            {isMspClientView ? (
              <div></div>
            ) : (
              <div>
                <div className="d-flex align-items-center">
                  {isTableEdited && (
                    <div
                      className="f-blue f-500 mr-2 pointer d-flex"
                      onClick={() => {
                        handleReset();
                        setIsSmthOverriden(true);
                      }}
                    >
                      <img
                        alt=""
                        className={`mr-1 ${resetting && "rotate-full"}`}
                        src="/images/msp/blue-load-icon.svg"
                      />
                      {resetting ? "Resetting.." : "Reset"}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Price breakdown table */}
        <div className="border-parent  radius-8">
          <div
            className={`border-item f-12 f-500 f-darkgrey p-2 ${switchVal
              ? " per_asset_price_breakdown_table "
              : " per_asset_price_breakdown_table_switch "
              }`}
          >
            <div>Asset Type</div>
            <div>Qty</div>
            {switchVal && <div>Unit Cost</div>}
            {switchVal && <div>Unit RRP</div>}
            <div>Unit Sell</div>
            <div>Line Total</div>
          </div>
          {assetBreakDownData?.MSPPackagePrice?.PackagePerAsset_List
            ?.map((data, dataIndex) => {
              return (
                <>
                  {data.UnitCost !== 0 && (
                    <div
                      className={`border-item f-grey px-2 ${switchVal
                        ? " per_asset_price_breakdown_table "
                        : " per_asset_price_breakdown_table_switch "
                        }`}
                    >
                      <div className="f-black f-500">{data.Name}</div>
                      <div>
                        <div style={{
                          width: '70%',
                          marginBottom: '4px',
                          marginLeft: '-14px'
                        }}>
                          <IncreaseDecreaseNumbersComponentAlwaysHighlight
                            type={"number"}
                            floatValue={false}
                            value={data.Qty}
                            isBlue={data?.IsEditedQuantity}
                            onChange={(value) => {
                              let updated_data = { ...assetBreakDownData };
                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                dataIndex
                              ].IsEditedQuantity = true;
                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                dataIndex
                              ].Qty =
                                value && Number(value);
                              setAssetBreakDownData(updated_data);
                              handleSave();
                              setIsSmthOverriden(true);
                              return true;
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </div>
                      </div>
                      {switchVal && (
                        <div>
                          {" "}
                          <span>
                            {packageDetails?.CurrencyCode_Selected
                              ?.CurrencySymbol ?? "£"}
                          </span>
                          {Number(data.UnitCost).toFixed(2)}
                        </div>
                      )}
                      {switchVal && (
                        <div>
                          {packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}
                          {Number(data?.UnitRRP)?.toFixed(2)}{" "}
                        </div>
                      )}
                      <div style={{
                        width: '70%',
                        marginBottom: '4px',
                        marginLeft: '-14px'
                      }}>
                        <IncreaseDecreaseNumbersComponentAlwaysHighlight
                          type={"number"}
                          floatValue={false}
                          value={data.UnitSell}
                          children={<div
                            className={`f-400 f-14 ${assetBreakDownData.MSPPackagePrice.PackagePerAsset_List[dataIndex].IsEditedUnitSell ? 'txt-blue' : 'f-black'}`}
                            style={{
                              marginBottom: '1px'
                            }}
                          >
                            {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                          </div>}
                          isBlue={data.IsEditedUnitSell}
                          onChange={(value) => {
                            if (assetBreakDownData.MSPPackagePrice.PackagePerAsset_List[dataIndex].UnitSell != Number(value)) {
                              let updated_data = { ...assetBreakDownData };
                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                dataIndex
                              ].IsEditedUnitSell = true;
                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                dataIndex
                              ].UnitSell = Number(value);
                              setAssetBreakDownData(updated_data);
                              // handleSave();
                              // setIsSmthOverriden(true);
                              return true;
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            if (!isNaN(value)) {
                              if (value.includes(".")) {
                                const decimalPartLength =
                                  value.split(".")[1].length;
                                if (decimalPartLength === 1) {
                                  let updated_data = { ...assetBreakDownData };
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].UnitSell =
                                    value + "0";
                                  setAssetBreakDownData(updated_data);
                                } else if (decimalPartLength > 2) {
                                  let updated_data = { ...assetBreakDownData };
                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                    dataIndex
                                  ].UnitSell =
                                    Number(value).toFixed(2).toString();
                                  setAssetBreakDownData(updated_data);
                                }
                              } else {
                                let updated_data = { ...assetBreakDownData };
                                updated_data.MSPPackagePrice.PackagePerAsset_List[
                                  dataIndex
                                ].UnitSell =
                                  value + ".00";
                                setAssetBreakDownData(updated_data);
                              }
                            }
                            handleSave();
                          }}
                        />
                      </div>
                      <div>
                        <span>
                          {" "}
                          {packageDetails?.CurrencyCode_Selected
                            ?.CurrencySymbol ?? "£"}
                        </span>
                        {Number(data?.SellTotal)?.toFixed(2)}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>

      <div className="my-3">
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="f-500 f-12 f-darkgrey w-100">
            Contribution
          </div>
          {contribLoading && <div className="msp-service-update-loader ml-2 mr-1"></div>}
          {
            !contribLoading && showContribReset && <div
              className="txt-blue pointer d-flex align-items-center"
              onClick={() => {
                setResetting(true);
                setShowContribReset(false);
                setContribLoading(true);
                updatePackageMappedPrice(packageDetails?.ScenarioHeader?.objId_Scenario);
                setResetting(false);
              }}
            >
              <img
                alt=""
                className={`mr-1 ${resetting && "rotate-full"}`}
                src="/images/msp/blue-load-icon.svg"
              />
              {resetting ? "Resetting.." : "Reset"}
            </div>
          }
        </div>

        {/* Contribution table */}
        <div className="border-parent  radius-8">
          <div className="border-item f-12 f-500 f-darkgrey p-2 contribution_table">
            <div>Recurring Item</div>
            <div>Qty.</div>
            <div>Price</div>
            <div>Total</div>
            <div>Period</div>
          </div>
          {assetBreakDownData?.MSPPackagePrice?.PackageMapCurrentSpend_List
            ?.map((data, dataIndex) => {
              return (
                <>
                  {data?.ItemName && data?.ItemName !== null && (
                    <div className="border-item p-2 contribution_table">
                      <div className="f-400 f-14 f-black">{data.ItemName}</div>
                      <div className="f-400 f-14 greyText2">{data.Quantity}</div>
                      <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{data.Price}</div>
                      <div className="f-400 f-14 greyText2">{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}{data.Total}</div>
                      <div className="f-400 f-14 greyText2">{data.Period_Text}</div>
                      <img
                        alt=""
                        width={10}
                        src="/images/pencil-icon.svg"
                        className="mx-2 pointer"
                        onClick={(e) => {
                          setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
                          setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
                          setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(assetBreakDownData?.MSPPackagePrice?.Subtotal + assetBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${assetBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                          setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(assetBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : assetBreakDownData?.MSPPackagePrice?.Subtotal);
                          setShowMapCurrentSpendModal(true);
                          setIsFromAddContribTable(true);
                          const drag = document?.getElementById("map-current-spend-modal");
                          drag.style.display = "block";
                        }}
                      />
                    </div>
                  )}
                </>
              );
            })}

          <div className="f-500 f-12 greyText2  p-2 border-item pointer"
            onClick={() => {
              setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
              setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
              setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(assetBreakDownData?.MSPPackagePrice?.Subtotal + assetBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${assetBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
              setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(assetBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : assetBreakDownData?.MSPPackagePrice?.Subtotal);
              setShowMapCurrentSpendModal(true);
              setIsFromAddContribTable(true);
              const drag = document?.getElementById("map-current-spend-modal");
              drag.style.display = "block";
            }}
          >
            + Add
          </div>

          <div className="contribution_table p-2">
            <div className="f-400 f-14 greyText2">
              Total
            </div>
            <div></div>
            <div></div>
            <div>
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(Number(contributionValue)?.toFixed(2))}
            </div>
            <div className="f-400 f-14 greyText2">
              /{assetBreakDownData?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}
            </div>
          </div>
        </div>
      </div>


      {/* Tota pricing Table */}
      <div className="">
        <div className="my-2 f-500 f-12 f-darkgrey d-flex align-items-center justify-content-between">
          Summary
        </div>
        <div className="border-parent radius-8">
          <div className="per_asset_total_pricing_table-updated border-item">
            <div className="f-400 f-14 f-black pl-2">Subtotal</div>
            <div></div>
            <div>
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(assetBreakDownData?.MSPPackagePrice?.Subtotal)?.toFixed(2)}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-400 f-14 f-black pl-2">
              Contribution
            </div>
            <div className="f-400 f-14 txt-blue underline pointer"
              onClick={() => {
                setMapCurrentSpendPackageId(packageDetails?.ScenarioHeader?.objId_Scenario);
                setMapCurrentSpendPackagePeriod(packageDetails?.MSPPackagePrice?.BillingPeriod_Text);
                setMapCurrentSpendPackagePriceText(switchIncludedTaxVal ? `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${Number(assetBreakDownData?.MSPPackagePrice?.Subtotal + assetBreakDownData?.MSPPackagePrice?.Tax).toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}` : `${packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}${assetBreakDownData?.MSPPackagePrice?.Subtotal?.toFixed(2)} /${packageDetails?.ScenarioHeader?.BillingPeriod_Selected?.DDLColour}`);
                setMapCurrentSpendPackagePrice(switchIncludedTaxVal ? Number(assetBreakDownData?.MSPPackagePrice?.Subtotal).toFixed(2) : assetBreakDownData?.MSPPackagePrice?.Subtotal);
                setShowMapCurrentSpendModal(true);
                setIsFromAddContribTable(true);
                const drag = document?.getElementById("map-current-spend-modal");
                drag.style.display = "block";
              }}
            >
              -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(Number(contributionValue)?.toFixed(2))}
            </div>

            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue).toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-400 f-14 f-black pl-2">
              Discount
            </div>
            <div className="f-400 f-14 "
              style={{
                marginLeft: '-14px'
              }}
            >
              <IncreaseDecreaseNumbersComponentAlwaysHighlight
                type={"number"}
                floatValue={true}
                children={<div
                  className="f-400 f-14 f-black"
                  style={{
                    width: '17px',
                    marginBottom: '1px'
                  }}
                >
                  -{packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
                </div>}
                value={discount ?? 0}
                onChange={(value) => {
                  setDiscount(value);
                  return true;
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>

            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {numberWithCommas(
                Number(assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount).toFixed(2)
              )}
            </div>
          </div>

          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-400 f-14 f-black pl-2">
              {`
              Tax @ ${assetBreakDownData?.TaxRates_Selected?.TaxRate}% (${assetBreakDownData?.TaxName})
            ` ?? "Tax @ 0% (No Taxable)"}
            </div>
            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number((assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * assetBreakDownData?.TaxRates_Selected?.TaxRate / 100)?.toFixed(2)}
              {/* {Number(assetBreakDownData?.MSPPackagePrice?.Tax)?.toFixed(2)} */}
            </div>
            <div className="f-400 f-14 greyText2">
              {packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"}
              {Number(
                assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
                ((assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * assetBreakDownData?.TaxRates_Selected?.TaxRate / 100)
              ).toFixed(2)}{" "}
            </div>
          </div>



          <div className="per_asset_total_pricing_table-updated  border-item">
            <div className="f-600 f-14 f-black pl-2">Total</div>
            <div></div>
            <div className="f-600 f-14 greyText2">
              {renderPriceText(
                assetBreakDownData?.ScenarioHeader?.BillingPeriod_Selected
                  ?.DDLSort,
                (
                  assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount +
                  ((assetBreakDownData?.MSPPackagePrice?.Subtotal - contributionValue - discount) * assetBreakDownData?.TaxRates_Selected?.TaxRate / 100)
                )?.toFixed(2),
                packageDetails?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Period Break down Table */}
      {
        switchVal &&
        <div className="">
          <div className="my-2 f-500 f-12 f-darkgrey d-flex align-items-center justify-content-between">
            Margin
          </div>
          <div className="border-block radius-8">
            <div className="border-item f-grey per_asset_period-breakdown_table">
              <div className="f-500 f-12 f-darkgrey pl-2">Period</div>
              <div className="f-500 f-12 f-darkgrey">Cost</div>
              <div className="f-500 f-12 f-darkgrey">Sell</div>
              <div className="f-500 f-12 f-darkgrey">Margin</div>
              <div className="f-500 f-12 f-darkgrey">Margin %</div>
            </div>

            {assetBreakDownData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
              (data, index) => {
                return (
                  <div
                    className="border-item per_asset_period-breakdown_table">
                    <div className="f-400 f-14 f-black pl-2">{data?.Period} </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Cost).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {renderPriceText(
                        99,
                        Number(data?.Sell - contributionValue - discount - data?.Cost).toFixed(2),
                        packageDetails?.CurrencyCode_Selected?.CurrencySymbol ??
                        "£"
                      )}
                    </div>
                    <div className="f-400 f-14 f-grey">
                      {
                        isNaN((100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell)).toFixed(0)) ? 0 : (100 * (Number(data?.Sell - contributionValue - discount - data?.Cost) / data?.Sell)).toFixed(0)
                      }%
                    </div>
                  </div>
                );
              }
            )}
            {
              packageDetails?.Nist_All?.some(item => item?.IsOnDemand === true) ?
                <div className="px-2 py-3">
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      width={13}
                      height={13}
                      src="/images/info-blue.svg"
                      className="mr-2"
                    />
                    <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                  </div>
                  <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                </div> : <></>
            }
          </div>
        </div>
      }
    </div>
  );
};

const CreateCustomPackageModal = ({
  show,
  hideModal,
  packageList,
  updatePageData,
  duplicatePackage,
}) => {
  const clientId = window.location.pathname.split("/")?.[4] ?? -1;
  const history = useHistory();
  const { authTokens } = useAuth();
  const [scenarioList, setScenarioList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setScenarioList(packageList);
  }, [packageList]);
  useEffect(() => {
    if (show) {
      setLoading(true);

      getPageData().then((response) => {
        setScenarioList(response?.ScenarioHheaderList);
      });
    }
  }, [show]);

  async function getPageData() {
    return new Promise((resolve, reject) => {
      getAPIData(667, authTokens, {
        optionEnum1: clientId,
      })
        .then((response) => {
          setLoading(false);
          resolve(response);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="create-custom-package-modal"
      className=""
      aria-labelledby="create-custom-package-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="hide-scrollbar p-3">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{}}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="f-16 f-500 my-2 mb-3 pl-3">
              Create custom package
            </div>

            <div
              className="f-grey p-3 radius-8 mb-3 pointer"
              style={{ border: "1px dashed #e2e2e5" }}
              onClick={() => {
                history.push("/mspv2/msp-price-calculator/0", {
                  backUrl: `/mspv2/clients/clientsView/${clientId}/managed-security`,
                  allPackagesList: packageList,
                  clientId: clientId,
                });
              }}
            >
              <img
                alt=""
                src="/images/actioncenter/plusgrey.svg"
                className="mr-3"
              />
              Create from scratch
            </div>
            <div className="f-12 f-500 f-darkgrey mb-">Make copy of</div>
            <div
              className="transparent-scroller"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {scenarioList?.map((_package, _packageIndex) => {
                return (
                  <div
                    className="d-flex align-items-center justify-content-between radius-4 p-2 pointer bg-hover-grey"
                    onClick={() => {
                      let updated_data = [...scenarioList];
                      updated_data[_packageIndex].loading = true;
                      setScenarioList(updated_data);
                      duplicatePackage(_package.objId_Scenario)
                        .then(() => {
                          updatePageData()
                            .then(() => {
                              hideModal();
                              CallToast(
                                "Updated successfully",
                                false,
                                false,
                                "v2style"
                              );
                            })
                            .catch(() => {
                              CallToast(
                                "Something went wrong",
                                false,
                                false,
                                "v2style"
                              );
                            });
                        })
                        .catch(() => {
                          CallToast(
                            "Something went wrong",
                            false,
                            false,
                            "v2style"
                          );
                        });
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/msp-calculator-file-icon.svg"
                      />
                      <div>
                        <div className="f-500 f-black">{_package.Name} </div>
                        <div className="f-12 f-darkgrey">
                          {_package?.Description}
                        </div>
                      </div>
                    </div>
                    <div>
                      {_package.loading ? <GreyLoader width={15} /> : <></>}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export const PackageData = ({
  _packageData,
  switchVal,
  priceCalculatorState,
  isMspClientView,
  packageDetails,
  updateSeatAssetDataApi = () => { },
  resetAssetSeatDataApi = () => { },
  getPackageDetails = () => { },
  setIsSmthOverriden = () => { },
  setSwitchVal = () => { },
  setMapCurrentSpendPackageId = () => { },
  setMapCurrentSpendPackagePeriod = () => { },
  setMapCurrentSpendPackagePriceText = () => { },
  switchIncludedTaxVal,
  setMapCurrentSpendPackagePrice = () => { },
  setShowMapCurrentSpendModal = () => { },
  featuresData,
  discount,
  setDiscount = () => { },
  showMapCurrentSpendModal,
  getPageData = () => { },
  clientId,
  mapCurrentSpendPackageId,
  mapCurrentSpendPackagePrice,
  mapCurrentSpendPackagePriceText,
  updatePackageMappedPrice,
  setContribLoading = () => { },
  contribLoading,
  featuresLoading,
  setIsFromAddContribTable,
  setShowContribReset,
  showContribReset,
  contributionValue,
  componentsData,
  securityCapabilitiesData,
  securityCapabilitiesLoading,
  ownClientID
}) => {
  const [packageData, setPackageData] = useState(_packageData);
  useEffect(() => {
    setPackageData(_packageData);
  }, [_packageData]);


  const mergedDataList = packageData?.pillarList?.reduce((acc, item) => {
    return acc?.concat(item.dataList);
  }, []);

  const showBy = [{
    id: 0,
    label: 'Show by NIST CSF Function'
  },
  {
    id: 1,
    label: 'Show by Security Pillar'
  }]

  const [selectedShowBy, setSelectedShowBy] = useState({
    id: 0,
    label: 'Show by NIST CSF Function'
  })
  const [selectedShowByData, setSelectedShowByData] = useState(securityCapabilitiesData?.SecurityCapabiltiesByNist_List?.sort((a, b) => a?.ObjectId - b?.ObjectId));

  useEffect(() => {
    setSelectedShowByData(securityCapabilitiesData?.SecurityCapabiltiesByNist_List)?.sort((a, b) => a?.ObjectId - b?.ObjectId);
  }, [securityCapabilitiesData])

  return (
    <div
      className="p-5"
      style={{
        minWidth: "600px",
        maxWidth: "800px",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-center bg-greyish p-1 radius-8 border-dark-grey mr-2"
          style={{
            height: '40px',
            width: '40px'
          }}
        >
          <img
            width={19}
            height={21}
            alt=""
            className=""
            src="/images/msp/package-icon2.svg"
          />
        </div>
        <div>
          <div className="f-14 f-600 f-black mr-4">
            {packageData?.ScenarioHeader?.Name}
          </div>
          <div className="f-400 f-14 f-darkgrey">
            Enhanced security suite offering advanced threat detection, incident response, and data privacy compliance.
          </div>
        </div>
      </div>


      <div className="d-flex w-50 mt-5">
        <div className="w-50 f-400 f-14 f-darkgrey">
          Billing type
        </div>
        <div className="w-50">
          {packageData?.ScenarioHeader?.PackageType_Text && (
            <MspSingleTagUI
              itemName={packageData?.ScenarioHeader?.PackageType_Text}
            />
          )}
        </div>
      </div>

      <div className="d-flex w-50 mt-1">
        <div className="w-50 f-400 f-14 f-darkgrey">
          Billing period
        </div>
        <div className="w-50 f-400 f-14 f-black">
          {packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLText}
        </div>
      </div>

      <div className="d-flex w-50 mt-1">
        <div className="w-50 f-400 f-14 f-darkgrey">
          Currency
        </div>
        <div className="w-50 f-400 f-14 f-black">
          {packageData?.CurrencyCode_Selected?.CurrencyName}
        </div>
      </div>

      {
        packageData?.ScenarioHeader?.PackageType_Enum == 0 &&
        <div className="d-flex w-50 mt-1">
          <div className="w-50 f-400 f-14 f-darkgrey">
            Qty Seats
          </div>
          <div className="w-50 f-400 f-14 f-black">
            {packageData?.MSPPackagePrice?.RequiredSeatsOverriden}
          </div>
        </div>
      }

      <div className="d-flex w-50 mt-1">
        <div className="w-50 f-400 f-14 f-darkgrey">
          Price
        </div>
        <div className="w-50 f-400 f-14 f-black">
          {renderPriceText(
            packageData?.ScenarioHeader?.BillingPeriod_Selected?.DDLSort,
            // (packageData?.MSPPackagePrice?.TotalCost - packageData?.MSPPackagePrice?.Contribution - packageData?.MSPPackagePrice?.Discount)?.toFixed(2),
            (packageData?.MSPPackagePrice?.Subtotal)?.toFixed(2),
            packageData?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
          )}
          <span className="f-400 f-14 f-darkgrey ml-2">
            {'(ex tax)'}
          </span>
        </div>
      </div>

      <div className="border-bottom-gray w-100 py-3"></div>

      <div className="mb-1 mt-5">
        <div className="d-flex align-items-center justify-content-between">
          <div className="f-600 f-14 f-black">
            Security Capabilities
          </div>
          <Dropdown className="security-capabilities-dropdown">
            <Dropdown.Toggle>
              <div className="d-flex align-items-center py-1 px-2">
                <div className="f-500 f-12 greyText2">
                  {selectedShowBy?.label}
                </div>
                <img
                  width={8}
                  alt=""
                  className="ml-2"
                  src="/images/chevron-down.svg"
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                {
                  showBy?.map((showByItem, index) => {
                    return (
                      <div
                        className={`f-400 f-12 px-2 py-1 ${showByItem?.id == selectedShowBy?.id ? 'txt-blue' : 'greyText2 bg-hover-grey pointer'}`}
                        onClick={(e) => {
                          if (showByItem?.id != selectedShowBy?.id) {
                            setSelectedShowBy(showByItem);
                            setSelectedShowByData(showByItem?.id == 0 ? securityCapabilitiesData?.SecurityCapabiltiesByNist_List?.sort((a, b) => a?.ObjectId - b?.ObjectId) : securityCapabilitiesData?.SecurityCapabiltiesBySecurityPillar_List)
                            e.target
                              .closest(".security-capabilities-dropdown")
                              .click();
                          }
                        }}
                      >
                        {showByItem?.label}
                      </div>
                    )
                  })
                }
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {
          securityCapabilitiesLoading ? <div className="d-flex align-items-center w-100 justify-content-center p-5">
            <div className="f-400 f-14 f-grey">Loading..</div>
            <div className="msp-service-update-loader ml-2 mr-2"></div>
          </div> : <>
            {
              selectedShowByData?.length > 0 && (
                <div className="my-2 border-gray w-100">
                  <div className="security-capabilities-table">
                    {selectedShowByData?.map((securityCapabilityItem, itemIndex) => (
                      <div
                        key={itemIndex}
                        className={`p-3 ${itemIndex % 2 === 0 ? 'border-right' : ''} ${itemIndex < (itemIndex % 2 === 0 ? selectedShowByData?.length - 2 : selectedShowByData?.length - 1) ? 'border-bottom' : ''
                          }`}
                      >
                        <div className="f-500 f-14 f-black mb-1">
                          {securityCapabilityItem?.Name}
                        </div>
                        {securityCapabilityItem?.SecurityCapabiltiesData_List?.length > 0 ? (
                          securityCapabilityItem?.SecurityCapabiltiesData_List.map((dataItem, dataIndex) => (
                            <div key={dataIndex} className="d-flex align-items-center mb-2">
                              <img
                                width={14}
                                alt=""
                                className="mr-2"
                                src={
                                  dataItem?.IsGreyColour
                                    ? '/images/icons/check-gray-circle.svg'
                                    : '/images/icons/check-blue-circle.svg'
                                }
                              />
                              <div className="f-400 f-12 f-black">
                                {dataItem?.Name}
                              </div>
                              {dataItem?.IsManagedBySOC && (
                                <img
                                  width={14}
                                  alt=""
                                  className="ml-2"
                                  src="/images/icons/monitor-icon.svg"
                                />
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="f-400 f-12 f-black">No security functionality in this area</div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center border-top p-2">
                    <img width={14} alt="" className="mr-1 ml-2" src="/images/icons/check-gray-circle.svg" />
                    <div className="f-400 f-12 f-darkgrey">Pre-existing</div>
                    <img width={14} alt="" className="mr-1 ml-3" src="/images/icons/check-blue-circle.svg" />
                    <div className="f-400 f-12 f-darkgrey">New!</div>
                    <img width={14} alt="" className="mr-1 ml-3" src="/images/icons/monitor-icon.svg" />
                    <div className="f-400 f-12 f-darkgrey">Monitored by SOC</div>
                  </div>
                </div>
              )
            }
          </>
        }


      </div>
      <div>
        {/* <PerSeatBreakdown /> */}
        {priceCalculatorState.tabsData[
          priceCalculatorState.activeTab
        ]?.ScenarioHeader?.PackageType_Enum === 0 && (
            <PerSeatBreakdown
              isMspClientView={isMspClientView}
              data={
                priceCalculatorState.tabsData[
                priceCalculatorState.activeTab
                ]
              }
              packageDetails={packageDetails}
              updateSeatAssetDataApi={updateSeatAssetDataApi}
              resetAssetSeatDataApi={resetAssetSeatDataApi}
              getPackageDetails={getPackageDetails}
              setIsSmthOverriden={setIsSmthOverriden}
              setSwitchValPassed={setSwitchVal}
              switchVal={switchVal}
              setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
              setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
              setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
              switchIncludedTaxVal={switchIncludedTaxVal}
              setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
              setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
              discount={discount}
              setDiscount={setDiscount}
              updatePackageMappedPrice={updatePackageMappedPrice}
              setContribLoading={setContribLoading}
              contribLoading={contribLoading}
              setIsFromAddContribTable={setIsFromAddContribTable}
              showContribReset={showContribReset}
              setShowContribReset={setShowContribReset}
              contributionValue={contributionValue}
              ownClientID={ownClientID}
            />
          )}

        {priceCalculatorState.tabsData[
          priceCalculatorState.activeTab
        ]?.ScenarioHeader?.PackageType_Enum === 1 && (
            <>
              <PerAssetBreakdown
                isMspClientView={isMspClientView}
                data={
                  priceCalculatorState.tabsData[
                  priceCalculatorState.activeTab
                  ]
                }
                packageDetails={packageDetails}
                updateSeatAssetDataApi={updateSeatAssetDataApi}
                resetAssetSeatDataApi={resetAssetSeatDataApi}
                getPackageDetails={getPackageDetails}
                setIsSmthOverriden={setIsSmthOverriden}
                setSwitchValPassed={setSwitchVal}
                switchVal={switchVal}
                setMapCurrentSpendPackageId={setMapCurrentSpendPackageId}
                setMapCurrentSpendPackagePeriod={setMapCurrentSpendPackagePeriod}
                setMapCurrentSpendPackagePriceText={setMapCurrentSpendPackagePriceText}
                switchIncludedTaxVal={switchIncludedTaxVal}
                setMapCurrentSpendPackagePrice={setMapCurrentSpendPackagePrice}
                setShowMapCurrentSpendModal={setShowMapCurrentSpendModal}
                discount={discount}
                setDiscount={setDiscount}
                updatePackageMappedPrice={updatePackageMappedPrice}
                setContribLoading={setContribLoading}
                contribLoading={contribLoading}
                setIsFromAddContribTable={setIsFromAddContribTable}
                showContribReset={showContribReset}
                setShowContribReset={setShowContribReset}
                contributionValue={contributionValue}
                ownClientID={ownClientID}
              />
            </>
          )}
      </div>

      <div className="border-bottom py-4"></div>

      <div>
        <div className="f-600 f-14 f-black my-2 pt-4">
          Included Features
        </div>

        <div className="border-parent  radius-8">
          <div className="features_table border-item px-2 py-1">
            <div className="f-500 f-12 f-darkgrey">Service</div>
            <div className="f-500 f-12 f-darkgrey">Features</div>
          </div>
          {
            packageDetails?.Nist_All?.map((item) => {
              return (
                <div className="features_table border-item py-1">
                  <div>
                    <div className="d-flex">
                      <img
                        alt=""
                        width={14}
                        height={14}
                        className="mx-2 mt-1"
                        src='/images/other-apps-icon.svg'
                      />
                      <div>
                        <div className="f-400 f-14 f-black line-height-22">
                          {item?.Headline}
                        </div>
                        <div className="f-12 f-darkgrey">
                          {item?.SecurityPillar_Selected?.DDLText && `${item?.SecurityPillar_Selected?.DDLText} • `}
                          {
                            item?.MonthlyPrice_Text
                          }{" "}
                          {
                            item
                              ?.SnapToUnit_Selected
                              ?.DDLColour
                          }{" "}
                          /
                          {
                            item
                              ?.BillingPeriod_Selected
                              ?.DDLColour
                          }{" "}
                          <span className="f-12 txt-blue">
                            {item?.IsOnDemand ? ' • POD' : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {item?.SecurityPack_Bullet_List?.length > 0 ? (
                      <>
                        {item?.SecurityPack_Bullet_List?.map((item) => {
                          return (
                            <div className="d-flex align-items-center my-1 f-12 f-400 f-black">
                              <img
                                alt=""
                                className="ml-1 mr-2"
                                src="/images/msp/green-circular-tick.svg"
                              />
                              {item?.Text}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="d-flex align-items-center my-2 f-grey f-12">
                        This service has no features
                      </div>
                    )}

                  </div>
                </div>
              )
            })
          }
        </div>
      </div>


      <div className="my-3">
        <div className="f-600 f-14 f-black mt-2">
          Service Components
        </div>
        <MspComponents
          componentsData={componentsData}
        />
      </div>
      <div>
        {/* <div className="d-flex align-items-center"> */}
        <div className="f-600 f-14 f-black my-2">
          Alignment to NIST CSF 2.0 Framework
        </div>
        {/* {featuresLoading && (<div className="msp-service-update-loader ml-2 mr-1"></div>)} */}
        {/* </div> */}
        {!featuresLoading ?
          <div className="border-block w-100">
            {
              featuresData?.map((featureItem, featureIndex) => {
                return (
                  <div>
                    {
                      featureItem?.Question_List?.filter(question =>
                        question?.AnswerId !== 0 && question?.AnswerBool === true
                      )?.length > 0 && <div className="w-100">
                        <div className={`f-500 f-14 f-black bg-grey px-2 py-1 ${featureIndex < featuresData?.length - 1 ? 'border-item' : ''
                          } ${featureIndex > 0 ? 'border-top-item' : ''}`}>
                          {featureItem?.Title}
                        </div>
                        <div className="">
                          {
                            featureItem?.Question_List?.map((question, questionIndex) => {
                              return (
                                <div>
                                  {question?.AnswerId !== 0 &&
                                    question?.AnswerBool === true && (
                                      <div className={`d-flex ${questionIndex < featureItem?.Question_List?.filter(question =>
                                        question?.AnswerId !== 0 && question?.AnswerBool === true
                                      )?.length - 1 ? 'border-item' : ''
                                        }`}>
                                        <img
                                          alt=""
                                          className="mx-2"
                                          src="/images/msp/green-circular-tick.svg"
                                        />
                                        <>
                                          {question?.InheritedText && (
                                            <div className="table-title radius-4 f-500">
                                              <div className="img">
                                                <img
                                                  alt=""
                                                  className=" "
                                                  src="/images/msp/arrow-enter.svg"
                                                />
                                              </div>

                                              <div className="title f-darkgrey">
                                                Inherited from `
                                                {question?.InheritedText}`{" "}
                                              </div>
                                            </div>
                                          )}
                                          <div
                                            className=""
                                            style={{ padding: "12px 16px" }}
                                          >
                                            <div className="d-flex align-items-start justify-content-between">
                                              <div>
                                                {question.Explanation}

                                                <div className="d-flex flex-wrap mt-2">
                                                  {
                                                    question?.nistType &&
                                                    <div
                                                      className='px-2 rounded-full d-flex align-items-center border1 f-grey bg-greyish f-12 f-500 mr-2'
                                                    >
                                                      {question?.nistType}
                                                    </div>
                                                  }
                                                  {question?.NISTAssetClassification_List?.map(
                                                    (chip, chipIndex) => {
                                                      return (
                                                        <div className="position-relative tooltip-parent">
                                                          <QuestionChip
                                                            iconUrl={
                                                              chip.IsFramework
                                                                ? "/images/msp/msp-framework-icon.svg"
                                                                : null
                                                            }
                                                            title={chip?.Name}
                                                            className={"mr-2"}
                                                            IsBlue={
                                                              chip?.Name?.toLowerCase() ===
                                                              "best practice"
                                                            }
                                                          />
                                                          {!chip.IsFramework && (
                                                            <PackageToolTip
                                                              content={chip?.Detail}
                                                            />
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      </div>
                                    )}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                )
              })
            }
          </div> : <div className="d-flex align-items-center w-100 justify-content-center p-5">
            <div className="f-400 f-14 f-grey">Loading..</div>
            <div className="msp-service-update-loader ml-2 mr-2"></div>
          </div>
        }

      </div>
      {/* <MapCurrentSpendModal
        show={showMapCurrentSpendModal}
        hideModal={() => {
          setMapCurrentSpendPackageId(0);
          setShowMapCurrentSpendModal(false);
          const drag = document?.getElementById("map-current-spend-modal");
          drag.style.display = "none";
          getPageData();
        }}
        clientId={clientId}
        packageId={mapCurrentSpendPackageId}
        packagePrice={mapCurrentSpendPackagePrice}
        packagePriceText={mapCurrentSpendPackagePriceText}
      /> */}
    </div>
  );
};

export const MspClientPriceCalculatorLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="1%" y="8%" rx="5" ry="5" width="45%" height="80" />
      <rect x="1%" y="20%" rx="5" ry="5" width="45%" height="40" />
      <rect x="1%" y="28%" rx="5" ry="5" width="20%" height="20" />

      <rect x="1%" y="32%" rx="5" ry="5" width="45%" height="70" />
      <rect x="1%" y="362" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="465" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="567" rx="5" ry="5" width="45%" height="100" />
      <rect x="1%" y="669" rx="5" ry="5" width="45%" height="100" />

      <rect x="55%" y="8%" rx="10" ry="10" width="40%" height="450" />

      <rect x="55%" y="60%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="67%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="74%" rx="10" ry="10" width="40%" height="50" />
      <rect x="55%" y="81%" rx="10" ry="10" width="40%" height="50" />
    </ContentLoader>
  );
};

const MspClientPriceCalculatorEmptyRadarChart = ({
  activeChart = 1,
  _datasets,
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor: `rgba(235, 237, 241, 0)`,
      borderColor: "rgba(235, 237, 241, 1)",
      pointBackgroundColor: "rgba(187, 190, 201, 1)",
      scaleShowLabels: false,
      borderWidth: 2,
    }));
    updatedDatasets.push({
      data: [40, 40, 40, 40, 40], // Replace this with your actual average values
      borderColor: "rgba(187, 190, 201, 1)",
      borderWidth: 2,
      pointBackgroundColor: "rgba(187, 190, 201, 1)",
      backgroundColor: `rgba(235, 237, 241, 0.35)`,
      pointRadius: [3, 3, 3, 3, 3],
      borderDash: [9, 9], // Dashed line for the average line
    });

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);

  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: /*20*/50,
          min: 0,
          ticks: {
            display: false,
            stepSize: /*5*/10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  console.log(updatedData, updatedOptions);

  return (
    <div
      className="zoom-independent position-relative"
      style={{
        width: "375px",
        height: "375px",
        marginTop: "60px",
        marginBottom: "40px",
        // backgroundColor: "#f0f0f0", // Light gray background
      }}
    >
      <MyRadarChart
        id="actial-radar"
        redraw={true}
        height={375}
        width={375}
        style={{ margin: "auto", border: "1px dashed #808080" }} // Gray dashed border
        data={updatedData}
        options={updatedOptions}
      />
      {/* ... existing code */}
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-24px",
          left: "187px",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-50px",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(58px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "calc(356px - 3rem)",
          bottom: "calc(53px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0{" "}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(-13px - 3rem)",
          top: "calc(150px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `rgba(187, 190, 201, 1)`,
          }}
        >
          0
        </div>
      </div>
    </div>
  );
};

export function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function renderPriceText(_billingPeriod, price, currency = "£") {
  // Monthly,    //............0
  // Quarterly,  //............1
  // Annual      //............2

  let old_price = numberWithCommas(price); // Math.abs(Number(price));
  var updated_price = 0;

  switch (_billingPeriod) {
    case 0:
      updated_price = `${currency}${old_price?.toString()} /month`;
      break;
    case 1:
      updated_price = `${currency}${old_price?.toString()} /quarter`;
      break;
    case 2:
      updated_price = `${currency}${old_price?.toString()} /year`;
      break;
    default:
      updated_price = `${currency}${numberWithCommas(price)}`;

      break;
  }
  if (price < 0) {
    updated_price = `-${updated_price}`;
  }
  return updated_price.replace(/(\d+\.\d)(?=\s|$)/g, "$10");
}

export function hexToRGBA(hex, opacity) {
  hex = hex.replace('#', '');
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}