const getImagePath = (integrationId, toolIcon) => {
  if (toolIcon && toolIcon.startsWith('https')) {
    return toolIcon;
  }
  return `/images/attack-surface/integrations/${integrationId}.webp`;
}

const checkImageExists = (imagePath) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true); 
    img.onerror = () => resolve(false);
    img.src = imagePath;
  });
};


const getImagePathIfExist = async (integrationId, toolIcon) => {
  if (toolIcon && toolIcon.startsWith('https')) {
    return toolIcon;
  }

  const exists = await checkImageExists(`/images/attack-surface/integrations/${integrationId}.webp`);
  return exists ? `/images/attack-surface/integrations/${integrationId}.webp` : '/images/attack-surface/generic2.svg';
}




const integrationUtils = {
  getImagePath,
  getImagePathIfExist
}

export default integrationUtils;
