import MagicIcon from '../../../icons/magic.icon';
import './mapped-using-ai.styles.scss';

const MappedUsingAI = () => (
  <div className="mapped-using-ai">
    <MagicIcon />
    <span className='text'>
      Mapped using AI
    </span>
  </div>
)

export default MappedUsingAI;
