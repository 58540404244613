import { SubActiveIcon, SubCancelledIcon, SubCompletedIcon, SubSuspendedIcon } from "../Components/icons/subscription-status.icons";

export const CustomSubDateSelection = [
  { Id: 1, OptionText: "Days", value: 1 / 30 },
  { Id: 2, OptionText: "Months", value: 1 },
  { Id: 3, OptionText: "Years", value: 12 },
];

export const BillingPeriodInMonths = {
  "/month": 1,
  "/quarter": 3,
  "/year": 12,
};

export const SubscriptionStatuses = {
  ACTIVE: "Active",
  SUSPENDED: "Suspended",
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
}

export const SubscriptionStatusIcons = {
  [SubscriptionStatuses.ACTIVE]: SubActiveIcon,
  [SubscriptionStatuses.SUSPENDED]: SubSuspendedIcon,
  [SubscriptionStatuses.CANCELLED]: SubCancelledIcon,
  [SubscriptionStatuses.COMPLETED]: SubCompletedIcon,
}
