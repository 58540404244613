const ThumbUpIcon = (props) => (
  props?.checked ? (
    <svg width="14" height="13" {...props} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.938092 5.07517H2.75627V12.3333H0.938092C0.777355 12.3333 0.623201 12.2695 0.509542 12.1561C0.395884 12.0427 0.332031 11.8888 0.332031 11.7284V5.68001C0.332031 5.5196 0.395884 5.36575 0.509542 5.25233C0.623201 5.1389 0.777355 5.07517 0.938092 5.07517ZM4.14597 4.29311L8.02476 0.422136C8.07631 0.37054 8.1448 0.339253 8.21762 0.33403C8.29044 0.328807 8.36271 0.349999 8.42112 0.393709L8.93809 0.780807C9.08167 0.888374 9.19012 1.03596 9.24976 1.20495C9.30941 1.37395 9.31758 1.55678 9.27324 1.73041L8.57446 4.47033H12.4532C12.7747 4.47033 13.083 4.59778 13.3103 4.82464C13.5377 5.0515 13.6654 5.35918 13.6654 5.68001V6.9526C13.6655 7.11068 13.6346 7.26726 13.5745 7.41348L11.6987 11.9589C11.6529 12.0697 11.5752 12.1644 11.4754 12.2311C11.3756 12.2977 11.2582 12.3333 11.1381 12.3333H4.57446C4.41372 12.3333 4.25956 12.2695 4.14591 12.1561C4.03225 12.0427 3.96839 11.8888 3.96839 11.7284V4.72073C3.96843 4.56033 4.0323 4.40652 4.14597 4.29311Z" fill="#40D88E"/>
    </svg>
  ) : (
    <svg width="14" height="13" {...props} viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.57446 4.67194H12.4532C12.7747 4.67194 13.083 4.79612 13.3103 5.01717C13.5377 5.23821 13.6654 5.53801 13.6654 5.85062V7.09059C13.6655 7.24463 13.6346 7.39719 13.5745 7.53967L11.6987 11.9686C11.6529 12.0766 11.5752 12.1689 11.4754 12.2338C11.3756 12.2988 11.2582 12.3334 11.1381 12.3334H0.938092C0.777355 12.3334 0.623201 12.2713 0.509542 12.1608C0.395884 12.0502 0.332031 11.9003 0.332031 11.744V5.85062C0.332031 5.69432 0.395884 5.54442 0.509542 5.43389C0.623201 5.32337 0.777355 5.26128 0.938092 5.26128H3.04839C3.14542 5.2613 3.24104 5.23867 3.3272 5.19529C3.41337 5.15191 3.48756 5.08905 3.54355 5.01199L6.84839 0.458149C6.89017 0.400578 6.95178 0.35947 7.02205 0.342279C7.09232 0.325088 7.1666 0.332952 7.23142 0.364444L8.33082 0.898977C8.64021 1.04935 8.88738 1.29815 9.03126 1.60401C9.17514 1.90988 9.20706 2.25442 9.12173 2.58037L8.57446 4.67194ZM3.96839 6.19715V11.1547H10.732L12.4532 7.09059V5.85062H8.57446C8.38985 5.8506 8.20769 5.80957 8.04187 5.73067C7.87605 5.65176 7.73095 5.53707 7.61765 5.39535C7.50435 5.25362 7.42584 5.0886 7.3881 4.91288C7.35037 4.73715 7.3544 4.55537 7.39991 4.38139L7.94718 2.29041C7.96431 2.22519 7.95796 2.15623 7.92918 2.095C7.9004 2.03378 7.85093 1.98398 7.789 1.9539L7.38839 1.75941L4.53385 5.6915C4.38233 5.90013 4.18839 6.07103 3.96839 6.19715ZM2.75627 6.43996H1.54415V11.1547H2.75627V6.43996Z" fill="#616778"/>
    </svg>
  )
);

export default ThumbUpIcon;
