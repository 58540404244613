import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VisibilityType } from "../../../../../constants/filter.constants";
import SearchFilterComponent from "../../../../SearchFilterComponent/SearchFilterComponent";
import SubcategoryListItem from "../subcategories/subcategory-list-item.component";
import { SubcategoriesNotFoundUI } from "../techspend.not-found";
import TechspendAddDropdown from "../techspend-add.dropdown";
import { TechspendAddMenuAction } from "../../../../../constants/techspend.constants";

const SubcategoriesTab = ({
  categoryPageData,
  categoryGraphRef,
  donutColorIds,
  getSubCategoryDetail,
  goTo,
  isSaas,
  setLoading,
  Pages,
  subcategorySearchRef,
  subcategoriesSearch,
  setSubcategoriesSearch,
  updateSubCategorySorting,
  sortingSubcategoryList,
  subCategoriesLoading,
  setShowCreateSubcategoryModal,
  viewOnlyAccess,
  ModalTabs,
  setAddSpendSelectedTab,
  setSubscriptionViewInvoiceData,
  getInvoiceDetails,
  parentCategortyData,
  childCategoryData,
  setDeleteID = () => {},
  setDeleteMessage = () => {},
  setDeleteType = () => {},
  setShowDeleteModal = () => {},
  setCategoryDeleteData = () => {},
  setSelectedTab = () => {},
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-2">
        <div className="f-500 f-grey pl-2">Subcategories</div>
        <div className="d-flex align-items-center">
          <SearchFilterComponent
            visibilityType={VisibilityType.LIST_VISIBILITY}
            showLastButton={true}
            searchValue={subcategoriesSearch}
            searchOnChange={e => {
              setSubcategoriesSearch(e.target.value);
            }}
            passedRef={subcategorySearchRef}
            setRef={() => {
              subcategorySearchRef.current.focus()
            }}
            searchOnCross={() => {
              setSubcategoriesSearch("");
            }}
            orderData={sortingSubcategoryList?.map((item) => ({
              FieldKey: item?.FieldKey,
              FieldName: item?.FieldName,
              SortOrder: item?.SortOrder,
              Visibility: item?.Visibility,
            }))}
            setOrderData={(updatedData) => {
              let updated_data = updatedData.map((item) => {
                const e = sortingSubcategoryList.find(
                  (e) => e.FieldKey === item.FieldKey
                );
                return {
                  CategoryColor: e?.CategoryColor,
                  CategoryID: e?.objId_Category,
                  CategoryName: e?.CategoryName,
                  CategorySortOrder: item?.SortOrder,
                  CategoryVisibility: item?.Visibility,
                  IsEditable: e?.IsEditable,
                  SubCatSettings: null,
                }
              });
              updateSubCategorySorting(updated_data);
            }}
            lastButtonChild={(
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_TechnologySpend")
                    ? ["hover", "focus"]
                    : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <TechspendAddDropdown 
                  goTo={goTo} 
                  Pages={Pages} 
                  setAddSpendSelectedTab={setAddSpendSelectedTab}
                  setSubscriptionViewInvoiceData={setSubscriptionViewInvoiceData}
                  getInvoiceDetails={getInvoiceDetails}
                  setLoading={setLoading}
                  setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                  defaultAction={TechspendAddMenuAction.AddSubcategory}
                  childCategoryData={childCategoryData}
                  parentCategortyData={parentCategortyData}
                />
              </OverlayTrigger>
            )}
          />
          </div>
        </div>
        <div className="w-100 position-relative">
          {subCategoriesLoading && (
            <div
              className="position-absolute"
              style={{
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px",
                opacity: "0.5",
                background: "white",
                zIndex: 1,
              }}
            ></div>
          )}
          {categoryPageData?.SyncStatus?.isFirstRun === true && categoryPageData?.SyncStatus?.IsSyncing === true &&
            !isSaas && (
              <div className="d-flex align-items-center f-darkgrey py-2 pl-2">
                <img
                  src="/images/msp/syncing-icon.svg"
                  alt=""
                  className="mr-2"
                />
                Data is syncing...
              </div>
            )
          }
          {categoryPageData?.SubCategory_List?.length === 0 && (
            <SubcategoriesNotFoundUI />
          )}
          {categoryPageData?.SubCategory_List?.length > 0 
          && categoryPageData?.CategoryName?.toLowerCase()?.includes(subcategoriesSearch?.toLowerCase()) 
          && (
            <SubcategoryListItem 
              Pages={Pages}
              categoryGraphRef={categoryGraphRef}
              donutColorIds={donutColorIds}
              getSubCategoryDetail={getSubCategoryDetail}
              goTo={goTo}
              setLoading={setLoading}
              subCategory={{
                objId_Category: categoryPageData?.ID,
                CategoryColourHexCode: categoryPageData?.CategoryColourHexCode,
                CategoryName: `[${categoryPageData?.CategoryName}]`,
                TotalAmount_Text: categoryPageData?.TotalUnassignedAmount_Text,
              }}
              subcategoriesSearch={subcategoriesSearch}
              isCategory
              ModalTabs={ModalTabs}
            />
          )}
          {categoryPageData?.SubCategory_List
          ?.filter((item) => {
            return item?.CategoryName?.toLowerCase().includes(subcategoriesSearch.toLowerCase());
          })
          ?.map(
            (subCategory, subCategoryIndex) => {
              return (
                <SubcategoryListItem 
                  Pages={Pages}
                  categoryGraphRef={categoryGraphRef}
                  donutColorIds={donutColorIds}
                  getSubCategoryDetail={getSubCategoryDetail}
                  goTo={goTo}
                  setLoading={setLoading}
                  subCategory={subCategory}
                  subcategoriesSearch={subcategoriesSearch}
                  key={`subCategory-${subCategoryIndex}`}
                  setDeleteID={setDeleteID}
                  setDeleteMessage={setDeleteMessage}
                  setDeleteType={setDeleteType}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowCreateSubcategoryModal={setShowCreateSubcategoryModal}
                  setCategoryDeleteData={setCategoryDeleteData}
                  setSelectedTab={setSelectedTab}
                  ModalTabs={ModalTabs}
                />
              );
            }
          )}
      </div>
    </>
  )
}

export default SubcategoriesTab;
