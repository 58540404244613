import { useState } from "react";
import useIntegrationActions from "../../../hooks/actions/integrations.actions";

const useConfigurationMappingList = ({ sellOrAse }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [ignoreItem, setIgnoreItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const { getConfigurationMappingList } = useIntegrationActions();

  const fetchData = async ({
    page, limit, search
  }) => {
    setPage(page);
    setIsLoading(true);
    const response = await getConfigurationMappingList({
      page, limit, sellOrAse, search
    });
    if (response?.detail) {
      if (page === 1) {
        setData(response?.detail?.Results?.filter(item => !item?.name?.includes("Ignore")));
      } else {
        setData([...data, ...response?.detail?.Results?.filter(item => !item?.name?.includes("Ignore")) ]);
      }
      setIgnoreItem(response?.detail?.Results?.find(item => item?.name?.includes("Ignore")));
      setHasNextPage(response?.detail?.nextPage);
    }
    setIsLoading(false);
  }

  return {
    isLoading,
    data,
    ignoreItem,
    searchValue,
    hasNextPage,
    page,
    setSearchValue,
    fetchData
  }
}

export default useConfigurationMappingList;
