import { SubscriptionStatusIcons } from "../../constants/subscription.constants";

const SubscriptionIcon = ({ subscriptionItem }) => {
  const StatusIcon = SubscriptionStatusIcons[subscriptionItem?.Status] ?? <div />;

  return (
    <div className="position-relative">
      <img
        alt=""
        className="mr-2"
        src="/images/msp/msp-invoice-icon.svg"
        width={20}
        height={20}
      />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          position: 'absolute',
          right: 6,
          bottom: -2,
          background: '#fff',
          borderRadius: '9999px',
          width: '14px',
          height: '14px',
        }}
      >
        <StatusIcon width="12px" height="12px" />
      </div>
    </div>
  )
}

export default SubscriptionIcon;
