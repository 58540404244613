import { Nav, Tab } from "react-bootstrap";
import CrossIcon from "../../icons/cross.icon";
import './suggest-modal.styles.scss';
import SearchFilterComponent from "../../SearchFilterComponent/SearchFilterComponent";
import { useEffect, useRef, useState } from "react";
import { VisibilityType } from "../../../constants/filter.constants";
import SuggestionsContent from "./suggestions-content/suggestions.content";
import RecurringSpendContent from "./suggestions-content/recurring-spend.content";
import useSuggestionsData from "./suggestions-content/suggestions.data";
import OrderSummary from "../../Common/loader";

const Tabs = {
  Suggestions: "suggestions",
  RecurringSpend: "recurringSpend",
}

const BodyTitle = {
  [Tabs.Suggestions]: "Technologies",
  [Tabs.RecurringSpend]: "",
}

const Header = ({ onHide }) => {
  return (
    <div className="header">
      <div className="navs">
        <Nav
          variant="pills"
          className="flex-row"
        >
          <Nav.Item>
            <Nav.Link eventKey={Tabs.Suggestions}>
              Suggestions
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey={Tabs.RecurringSpend}>
              Recurring Spend
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </div>
      <div className="cross-btn-wrapper">
        <div className="cross-btn cursor-pointer" onClick={onHide}>
          <CrossIcon 
            width="14px"
            height="14px"
            fill="#616778"
          />
        </div>
      </div>
    </div>
  )
}

const BodyTop = ({
  searchValue,
  setSearchValue
}) => {
  const SearchInput = useRef();
  return (
    <div className="body-top">
      <SearchFilterComponent
        searchValue={searchValue}
        searchOnChange={(e) => {
          setSearchValue(e.target.value);
        }}
        searchOnCross={() => {
          setSearchValue("");
        }}
        passedRef={SearchInput}
        setRef={() => {
          SearchInput.current.focus()
        }}
        visibilityType={VisibilityType.TABLE}
        showLastButton={false}
      />
    </div>
  )
}

const Body = ({ activeTab, clientId, searchValue }) => {
  const { 
    data: suggestData, 
    fetchData: fetchSuggestData, 
    isFetchingLoading,
    onApply
  } = useSuggestionsData();
  const title = BodyTitle[activeTab];

  const handleApply = async (suggestedItem) => {
    await onApply(suggestedItem);
    fetchSuggestData({ clientId });
  }

  useEffect(() => {
    if (!clientId) return;
    fetchSuggestData({ clientId});
  }, [clientId]);
  
  return (
    <div className="body">
      {(isFetchingLoading && suggestData?.length === 0) ? (
        <div className="my-4">
          <OrderSummary />
        </div>
      ) : (
        <>
          {title && (
            <h3 className="m-0 f-12 line-height-18 f-500 f-darkgrey body-header ">
              {title}
            </h3>
          )}
          <Tab.Content>
            <Tab.Pane eventKey={Tabs.Suggestions}>
              <SuggestionsContent 
                data={suggestData}
                onApply={handleApply}
                clientId={clientId}
                searchValue={searchValue}
              />
            </Tab.Pane>
            <Tab.Pane eventKey={Tabs.RecurringSpend}>
              <RecurringSpendContent />
            </Tab.Pane>
          </Tab.Content>
        </>
      )}
    </div>
  );
}

const SuggestModal = ({ clientId, show, onHide }) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(Tabs.Suggestions);

  if (!show) return null;

  return (
    <div className="cyber-group-modal suggest-modal">
      <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={Tabs.Suggestions}
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Header onHide={onHide} />
          <div className="body-scrollview hide-scrollbar">
            <BodyTop 
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <Body 
              activeTab={activeTab}
              clientId={clientId}
              searchValue={searchValue}
            />
          </div>
      </Tab.Container>
    </div>
  );
}

export default SuggestModal;
