import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import SlidingModal from "../../CustomModal/SlidingModal";
import SetDefaultIntegrationModal from "./SetDefaultIntegrationModal";
import "./mspIntegrations.scss";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ConnectIntegrationModal from "./ConnectIntegrationModal";
// import { CallToast } from "../../";
import Loader from "../../Common/loader";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import MspConnectModalLoader from "./mspIntegrationdetailScreen/MspConnectModalLoader";
import { screenMappingEnums } from "./mspMappingScreens/MSPMappings";
import { useHistory } from "react-router-dom";
import StaticLoaderModalForImporting from "./mspIntegrationdetailScreen/StaticLoaderModalForImporting";
import MspConnectIntegrationModal from "./mspIntegrationdetailScreen/MspConnectIntegrationModal";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";

const MspIntegrationV2Modal = ({
  show,
  hideModal,
  parentID,
  integrationId,
  refreshData,
}) => {
  const [showSetDefaultModal, setShowSetDefaultModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [disconnecting, setDisconnecting] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [importingFinished, setImportingFinished] = useState(false)
  const [psaImportProcessing, setPsaImportProcessing] = useState(false)
  const [showMspConnectModal, setShowMspConnectModal] = useState(false)
  const history = useHistory();
  // START____________PSA
  const [importRequestList, setImportRequestList] = useState([]);
  const [executeImporting, setExecuteImporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [psaImportingState, setPsaImportingState] = useState(false);

  const [showStaticLoaderModal, setShowStaticLoaderModal] = useState(false)

  console.log('importingFinished', importingFinished)
  console.log('psaImportProcessing', psaImportProcessing)
    console.log('aaaaaaaaaaaaaaa', (importingFinished === false ||
      psaImportProcessing === true))
  

  // useEffect(() => {
  //   if (executeImporting && importRequestList?.length > 0) {
  //     callImportDataApis(0);
  //   }
  // }, [importRequestList, executeImporting]);

  // STOP ____________PSA

  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  async function getModalData() {
    await getAPIData("65", authTokens, {
      OptionEnum1: integrationId,
      OptionEnum2: 2,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setModalData(response);
          setPsaImportingState(false ?? response?.PSAImportProcessState);
          setImportingFinished(response?.IntegrationStatus?.PSAImportFinished)
          setPsaImportProcessing(response?.IntegrationStatus?.PSAImportConfiguredInProccess)

          if(response?.IntegrationStatus?.PSAImportConfiguredInProccess===false){
            callImportDataApis(0);
          }

          if (response?.ImportRequestList?.length > 0) {
            let import_request_list = response?.ImportRequestList?.sort(
              (a, b) =>
                a.IntegrationRequestImportOrder -
                b.IntegrationRequestImportOrder
            )?.map((importItem) => ({
              ...importItem,
              loading: null,
              error: null,
              success: null,
            }));
            setImportRequestList(import_request_list);

            // SETUP TO STORE ALL THE IMPORT LIST FOR RENDERING THE UI ONCE THE IMPORTING FINISHED- 

            let render_import_list = response?.ImportRequestList?.filter(
              (item) => item?.ShowUINavigationTab === true
            )
              .sort(
                (a, b) =>
                  a.IntegrationRequestImportOrder -
                  b.IntegrationRequestImportOrder
              )
              .map((importItem, importIndex) => {
                return {
                  ...importItem,
                };
              });

            localStorage.setItem(
              "importListData",
              JSON.stringify(render_import_list)
            );
            localStorage.setItem(
              "importedIntegrtionId",
              JSON.stringify(integrationId)
            );
            localStorage.setItem(
              "importedIntegrtionName",
              JSON.stringify(response?.IntegrationDetail?.IntegrationName)
            );
          }

          if (response?.PSAImportProcessState === true) {
            // setExecuteImporting(true);
            // setIsImporting(true);
          } else {
            setIsImporting(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  async function disconnectIntegration() {
    setDisconnecting(true);
    await getAPIData("65", authTokens, {
      OptionEnum1: integrationId,
      OptionEnum2: 6,
    })
      .then((response) => {
        setDisconnecting(false);
        setShowDisconnect(false);
        if (response?.Success) {
          updateAccessKeyInLocalStorage(response?.Message)
        
          if (refreshData) refreshData();

          hideModal();
          CallToast(
            "Integration disconnected successfully",
            false,
            false,
            "v2style"
          );
          TrackUser("Tool disconnected", {
            type: modalData?.IntegrationDetail?.IntegrationName,
          });
        }
      })
      .catch((err) => {
        setDisconnecting(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (show) {
      setShowDisconnect(false);
      setDisconnecting(false);
      setLoading(true);
      getModalData();
    }
  }, [show]);

  // START_______PSA
  async function callImportDataApis(requestOrder, callAnotherEnum) {
    return;
    setExecuteImporting(false);
    if (requestOrder === importRequestList?.length) {
      // callImportDataApis(null, 99);
      return;
    }
    if (requestOrder !== null) {
      let updated_import_list = [...importRequestList];
      updated_import_list[requestOrder].loading = true;
      setImportRequestList(updated_import_list);
    }
    await getAPIData("70300", authTokens, {
      // OptionEnum1: callAnotherEnum
      //   ? callAnotherEnum
      //   : importRequestList[requestOrder].IntegrationRequestEnum,
      OptionEnum2: integrationId,
    }).then((response) => {
      if (requestOrder === null) return;
      if (response?.Success) {
        let updated_import_list = [...importRequestList];
        updated_import_list[requestOrder].loading = null;
        updated_import_list[requestOrder].success = true;
        setImportRequestList(updated_import_list);
      } else {
        let updated_import_list = [...importRequestList];
        updated_import_list[requestOrder].loading = null;
        updated_import_list[requestOrder].success = null;
        updated_import_list[requestOrder].error = true;
        setImportRequestList(updated_import_list);
      }
      if (requestOrder < importRequestList?.length) {
        // callImportDataApis(requestOrder + 1);
      }
    });
  }

  function completedImportList() {
    return importRequestList?.filter(
      (item) => item?.error !== null || item?.success !== null
    )?.length;
  }

  function totalImportList() {
    return importRequestList?.length;
  }

  function startedImporting() {
    let filter_list = importRequestList?.filter(
      (item) =>
        item?.loading !== null || item?.error !== null || item?.success !== null
    )?.length;
    if (filter_list === 0) {
      return false;
    } else {
      return true;
    }
  }

  function workedOnImportList() {
    return importRequestList?.filter(
      (item) =>
        item?.loading !== null || item?.error !== null || item?.success !== null
    )?.length;
  }

  function importedDataPercentage() {
    return Math.ceil((completedImportList() / totalImportList()) * 100);
  }
  // STOP_______PSA

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="0015sqxw"
      width={"28%"}
      onHide={hideModal}
    >
      <SlidingModal.Header className="">
        <div className="w-100 position-relative">
          <img
            alt=""
            className=""
            src={`/images/attack-surface/integrations/largeIcons/${integrationId}.webp`}
            style={{
              width: "inherit",
              borderBottom: "1px solid #ebecf1",
            }}
          />
          <img
            alt=""
            className="position-absolute pointer"
            src="/images/attack-surface/cross-icon.svg"
            onClick={hideModal}
            style={{
              right: "15px",
              top: "21px",
              width: "14px",
            }}
          />
        </div>
      </SlidingModal.Header>
      <SlidingModal.Body className="msp-connected-integration-modal">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 position-absolute"
            style={{
              top: "50%",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="f-16 f-600 f-black ml-3 my-3">
              {modalData?.IntegrationDetail?.IntegrationName}{" "}
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center justify-content-center f-blue f-500 border-right w-50 ml-3 pointer">
                <a
                  href={
                    modalData?.IntegrationDetail?.IntegrationURL ??
                    ""
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developer's site
                  <img
                    alt=""
                    className="ml-1"
                    src="/images/msp/integrations/blue-goto.svg"
                  />
                </a>
              </div>
              <div className="d-flex align-items-center justify-content-center w-50 f-grey f-500 pointer">
                <a
                  href={
                    modalData?.IntegrationDetail?.Integration_KB_URL ??
                    ""
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Details
                  <img
                    alt=""
                    className="ml-1"
                    src="/images/msp/integrations/grey-info.svg"
                  />
                </a>
              </div>
            </div>

            <hr style={{ borderTop: "1px solid #EBEDF1" }} />
            {/* for PSA Importing STATE */}
            {importRequestList?.length >0 && (
              <>
                {/* Mapping data */}

                <div>
                  <div className="bg-grey radius-4 px-3 pt-2 pb-1 mx-2  ">
                    {isImporting && (
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="f-darkgrey f-500">
                          Importing data...
                        </div>
                        <div className="f-darkgrey f-500">
                          {completedImportList() > 0 &&
                            `${importedDataPercentage()}%`}
                        </div>
                      </div>
                    )}
                    {!isImporting && (
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="f-darkgrey f-500">Mapped Data</div>
                        <div className="f-darkgrey f-500"></div>
                      </div>
                    )}

                    {importRequestList?.map((importItem, ImportIndex) => {
                      let allNullState =
                        importItem?.loading === null &&
                        importItem?.success === null &&
                        importItem?.error === null;

                      let someNotNull =
                        importItem?.loading !== null ||
                        importItem?.success !== null ||
                        importItem?.error !== null;
                      return (
                        <div className="d-flex align-items-center w-100 mb-2">
                          <div
                            className={` d-flex align-items-center w-50
                          
                          ${
                            importItem?.loading === null &&
                            importItem?.success === null &&
                            importItem?.error === null &&
                            (psaImportingState === true
                              ? "f-grey pl-4"
                              : "f-black pl-2")
                          }

                          ${
                            (importItem?.loading === true ||
                              importItem?.success === true) &&
                            "f-black"
                          }

                          ${importItem?.error === true && "f-danger"}
                          
                          `}
                            style={{ height: "25px" }}
                          >
                            {psaImportingState === true && (
                              <>
                                {importItem?.loading && (
                                  <div className="mr-3">
                                    <MspConnectModalLoader />
                                  </div>
                                )}
                                {importItem?.success && (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/integration-green-tick.svg"
                                    className=""
                                    style={{ marginRight: "0.65rem" }}
                                  />
                                )}
                                {importItem?.error && (
                                  <img
                                    alt=""
                                    src="/images/msp/error-icon.svg"
                                    className=""
                                    style={{ marginRight: "0.65rem" }}
                                  />
                                )}
                              </>
                            )}

                            {
                              importItem?.IntegrationRequestTitle === "Members" && Number(integrationId) === 3906771
                                ? "Agents"
                                : importItem?.IntegrationRequestTitle === "Clients" 
                                ? "Companies"
                                : importItem?.IntegrationRequestTitle
                            }
                          </div>

                          <div className="f-black w-50">
                            {(importingFinished === false ||
                            psaImportProcessing === true) ? (
                              <MspConnectModalLoader />
                            ) : (
                              <>
                                {allNullState &&
                                  psaImportingState === false && (
                                    <>
                                      {importItem?.PSAObjectsCountStatus}
                                    </>
                                  )}
                                {someNotNull && psaImportingState === true && (
                                  <>
                                    {importItem?.PSAObjectsCountStatus}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {psaImportingState === true && (
                      <progress
                        min="0"
                        value={completedImportList()}
                        max={totalImportList()}
                      />
                    )}
                  </div>
                </div>
                {/* View clients button */}
      { importingFinished&&  !psaImportProcessing &&       <div
                  className="bg-grey radius-4 d-flex  align-items-center justify-content-center pointer f-grey f-500 mx-2 mt-2 p-2"
                  onClick={() => {
                    if(importingFinished){
                      let importListData = localStorage.getItem("importListData");
                      localStorage.setItem("mappingState","view");
                      if (importListData) {
                        let headers = JSON.parse(importListData);
                        hideModal();
                        setTimeout(() => {
                          history.push(
                            `/mspv2/mappings?type=${
                              screenMappingEnums[
                                headers[0]?.IntegrationRequestEnum
                              ]
                            }`,
                            {
                              IsMicrosoftLightHouse: modalData?.IntegrationDetail?.IntegrationId == 7242957 ? true : false,
                              isPSA: modalData?.IntegrationDetail?.TechnologyTitle == 'PSA' ? true : false
                            }
                          );
                        }, 1000);
                      }
                    }else{
                      setShowStaticLoaderModal(true)
                      return
                    }
                 
                   
                  }}
                >
                  View mapping data
                  <img
                    alt=""
                    className="ml-2"
                    src="/images/msp/integrations/chevron-right.svg"
                  />
                </div>}
              </>
            )}
            {/* Default tools */}
            <div className="mx-3 mt-3 d-none">
              <div className="d-flex align-items-center f-black mb-2">
                <img
                  alt=""
                  src="/images/msp/integrations/blue-plug-connected.svg"
                  className="mr-2"
                />
                Default Patch Management Tool
              </div>
              <div className="d-flex align-items-center f-black mb-2">
                <img
                  alt=""
                  src="/images/msp/integrations/blue-plug-connected.svg"
                  className="mr-2"
                />
                Default RMM Tool
              </div>
              <Button
                className="hg-blue-btn w-100 mt-1 d-flex align-items-center justify-content-center"
                onClick={() => {
                  // setShowSetDefaultModal(true);
                }}
              >
                Set as default
                <img
                  alt=""
                  src="/images/msp/integrations/white-plug-pending.svg"
                  className="ml-2"
                />
              </Button>
            </div>

            <hr style={{ borderTop: "1px solid #EBEDF1" }} className="mx-2" />
            {/* Sync details */}
            <div className="mx-3">
              <div className="f-500 f-darkgrey mb-3">Sync</div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Status</div>
                <div className="f-black w-50"
                
                style={{
                  color:modalData?.IntegrationStatus?.TitleColour
                }}
                >
                  <img
                    alt=""
                    width={14}
                    height={14}
                    className="mr-2"
                    src={modalData?.IntegrationStatus?.TitleIconURL ?? "xxx"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/no-image.png";
                    }}
                  />
                  {modalData?.IntegrationStatus?.Title}
                </div>
              </div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Last Synced</div>
                <div className="f-black w-50">
                  {modalData?.IntegrationStatus?.LastSync}
                    {/* ? modalData?.IntegrationStatus?.LastSync?.toString()?.split(
                        " "
                      )?.[0]
                    : ""} */}
                </div>
              </div>
              <div className="d-flex align-items-center w-100 mb-2">
                <div className="w-50 f-grey">Next Sync</div>
                <div className="f-black w-50">
                  {modalData?.IntegrationStatus?.NextSync}
                    {/* ? modalData?.IntegrationStatus?.NextSync?.toString()?.split(
                        " "
                      )?.[0]
                    : ""} */}
                </div>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #EBEDF1" }} className="mx-2" />
            {/* Connection details */}
            <div className="mx-3">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="f-darkgrey f-500">Connection details</div>
                <div
                  className="f-blue f-500 pointer"
                  onClick={() => {
                    if (modalData?.IntegrationDetail?.TechnologyTitle?.toLowerCase()==='psa') {
                      setShowMspConnectModal(true)
                    } else {
                      
                      setShowConnectModal(true);
                    }
                  }}
                >
                  Edit
                </div>
              </div>

              {modalData?.ConnectionStrings?.map(
                (connectionDetail, connectionIndex) => {
                  return (
                    <div className="d-flex align-items-start w-100 mb-2">
                      <div className="w-50 f-grey">
                        {connectionDetail?.Label}
                      </div>
                      {connectionDetail?.ShowText ? (
                        <div
                          className="f-black w-50"
                          style={{
                            overflowWrap: "anywhere",
                          }}
                        >
                          {connectionDetail?.KeyValue}
                        </div>
                      ) : (
                        <div className="f-black w-50">• • • • • • • •</div>
                      )}
                    </div>
                  );
                }
              )}
            </div>

            {/* Disconnect Integration UI*/}
            <div
              className={`   ${
                showDisconnect ? "show d-flex" : ""
              } disconnectv2_banner     flex-column align-items-center radius-8 borderGrey`}
              style={{
                position: "fixed",
                bottom: "2%",
                right: "6%",
                width: "86%",
                zIndex: 1000,
                background: "white",
                border: "1px solid #ebecf1",
              }}
            >
              <div className="position-relative w-fit-content pt-3">
                <img
                  alt=""
                  width={48}
                  height={48}
                  className="radius-8 borderGrey mb-2"
                  src={`/images/attack-surface/integrations/${integrationId}.webp`}
                />
                <img
                  src="/images/attack-surface/disconnect-plug.svg"
                  alt=""
                  className="position-absolute"
                  style={{
                    bottom: "2px",
                    right: "-8px",
                  }}
                />
              </div>
              <div className="f-16 f-500 text-center mb-2">
                Disconnect integration
              </div>
              <div className="f-darkgrey text-center w-75">
                Are you sure that you want to disconnect this integration?{" "}
              </div>
              <hr className="mb-0" />
              <div className="p-3 w-100">
                <Button
                  className="hg-red-btn radius-2 w-100 mb-2"
                  disabled={disconnecting}
                  onClick={() => {
                    disconnectIntegration();
                  }}
                >
                  {disconnecting ? "Please wait..." : "Disconnect"}
                </Button>
                <Button
                  className="hg-cancel-btn radius-2 w-100"
                  onClick={() => {
                    setShowDisconnect(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>

            <ConnectIntegrationModal
              show={showConnectModal}
              hideModal={() => setShowConnectModal(false)}
              integrationId={integrationId}
              isEdit={true}
              refreshData={getModalData}
            />








 
        <MspConnectIntegrationModal
          show={showMspConnectModal}
          hideModal={() => setShowMspConnectModal(false)}
          integrationId={integrationId}
          refreshData={getModalData}
          isEdit={true}
        />








          </>
        )}
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <Button
          className="hg-cancel-btn radius-2 w-100"
          onClick={() => {
            setShowDisconnect(true);
          }}
        >
          Disconnect
        </Button>
      </SlidingModal.Footer>
      <SetDefaultIntegrationModal
        show={showSetDefaultModal}
        hideModal={() => setShowSetDefaultModal(false)}
      />
      <StaticLoaderModalForImporting
      show={showStaticLoaderModal}
      hideModal={()=>{
        setShowStaticLoaderModal(false)

      }}
      integrationId={integrationId}
      
      
      />
    </SlidingModal>
  );
};

export default MspIntegrationV2Modal;
