import React, { useRef, useState } from "react";
import './searchFilterComponent.scss';
import { Dropdown } from "react-bootstrap";
import { VisibilityType } from "../../constants/filter.constants";
import SelectArrowIcon from "../icons/select-arrow.icon";
import ShownHiddenCols from "../Common/shown-hidden-cols/shown-hidden-cols.component";
import SwitchButton2 from "../Theme/switch-button-2/switch-button-2.component";
import RowOrdering from "../Common/row-ordering/row-ordering.component";
import useComponentPosition from "../../hooks/component-position.hook";

const VisibilityTitle = {
    [VisibilityType.TABLE]: 'Column visibility',
    [VisibilityType.LIST]: 'Customise view',
    [VisibilityType.LIST_VISIBILITY]: 'Customise view',
}

export const VisibilityComponent = ({
    filterList = [],
    onFilterChange = () => {},
    visibilityType = VisibilityType.TABLE,
    orderData = [],
    setOrderData = () => { },
    isWhite
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const visibilityRef = useRef(null);
    const visibilityPos = useComponentPosition({ componentRef: visibilityRef, dependencies: [isOpen] });
    const [visibilitySettings, setVisibilitySettings] = useState(false);
    const visibilityTitle = VisibilityTitle[visibilityType] ?? '';

    return (
        <Dropdown 
            className="transparennt-wrapper-dropdown my-dropdown visibility"
            onToggle={(isOpen) => {
                setVisibilitySettings(false);
                setIsOpen(isOpen);
            }}
        >
            <Dropdown.Toggle
                className="d-flex align-items-center justify-content-center pointer"
                style={{ width: '32px', height: '32px'}}
            >
                <img
                    alt=""
                    width={15}
                    src={isWhite ? "/images/settings-v2/horizontal-dots-white.svg" : "/images/settings-v2/horizontal-dots.svg"}
                    className="pointer"
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu" ref={visibilityRef}>
                <div>
                {visibilitySettings ? (
                    visibilityType === VisibilityType.TABLE
                    || visibilityType === VisibilityType.LIST_VISIBILITY ? (
                        <ShownHiddenCols
                            columns={orderData}
                            setColumns={setOrderData}
                            draggableParentOffset={visibilityPos}
                        />
                    ) : (
                        <RowOrdering 
                            rows={orderData}
                            setRows={setOrderData}
                            draggableParentOffset={visibilityPos}
                        />
                    )
                ) : (
                    <div className="d-flex flex-column" style={{ gap: '2px' }}>
                        <div className="visibility-wrapper">
                            <div 
                                className="item-content d-flex align-items-center justify-content-between" 
                                onClick={() => setVisibilitySettings(true)}
                            >
                                <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                    <img 
                                        src="/images/attack-surface/hidden-icon.svg"
                                        width="14px"
                                        height="12px"
                                        alt="eye-icon"
                                    />
                                    <h3 className="mt-0 f-grey text">
                                        {visibilityTitle}
                                    </h3>
                                </div>
                                <div className="arrow-icon">
                                    <SelectArrowIcon width={10} fill="#616778" />
                                </div>
                            </div>
                        </div>
                        {filterList.length > 0 ? (
                            <div>
                                <div className="separator" />
                                {filterList.map((item, index) => (
                                    <div
                                        key={`visibility-item-${item.key}-${index}`} 
                                        className="item-wrapper"
                                    >
                                        <div 
                                            className="item-content d-flex align-items-center justify-content-between" 
                                            onClick={() => {
                                                onFilterChange({
                                                    ...item,
                                                    value: !item.value
                                                });
                                            }}
                                        >
                                            <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                                <h3 className="mt-0 f-grey text">
                                                    {item.label}
                                                </h3>
                                            </div>
                                            <SwitchButton2
                                                active={item.value}
                                                id={"no-msp-tools"}
                                                className="switcher"
                                                onChange={() => {}}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                )}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default VisibilityComponent;
