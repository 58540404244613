import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import useDropdown from "../../hooks/dropdown.hook";

import "./test.scss";
import { addLeadingZero } from "../MSPv2/clients/createClientScreens/createClientComplianceDropdowns/CyberEssentialAuditDropdown";
import dateUtils from "../../Utils/date.utils";
import { DATE_PICKER_YEARS } from "../../constants/date-picker.constants";
import YearMonthDropdown from "./year-month.dropdown";

const HighgroundDatePicker = (props) => {
  const {
    disableFutureDates,
    disablePreviousDates,
    minDate,
    onOpen,
    disableDatePicker = false,
  } = props;
  const [selectedDate, setSelectedDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [firstLoad, setFirstLoad] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });

  useEffect(() => {
    if (!firstLoad) return;
    setFirstLoad(true);
    drawCalender();
  }, [selectedDate]);
  useEffect(() => {
    if (props.selected) {
      setInputValue(props.selected);
    }
    if (props.selected?.split("/").length > 2) {
      setSelectedDate(changeDateFormat(props.selected));
    }
    if (props.selected === "") {
      setSelectedDate(moment().format("MM/DD/YYYY"));
    }
  }, [props.selected]);

  useEffect(() => {
    if (!props.setIsOpenState) return;
    props.setIsOpenState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (onOpen) onOpen();
  }, [isOpen]);

  function changeDateFormat(format) {
    return `${addLeadingZero(format.split("/")[1])}/${format.split("/")[0]}/${
      format.split("/")[2]
    }`;
  }

  function getValuefromLiTag() {
    let wrapper = document.getElementById(`hg-date-wrapper${props.id}`);

    wrapper.addEventListener("click", (e) => {
      const months_list = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      };

      let currentDateData = document.querySelector(`.current-date.${props.id}`);

      if (currentDateData) {
        let month = currentDateData.innerText.split(" ")[0];
        let selected_month = months_list[month];
        let year = currentDateData.innerText.split(" ")[1];

        if (e.target.id === "date-value") {
          setSelectedDate(`${selected_month}/${e.target.value}/${year}`);
          setInputValue(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`)
          );

          props.onChange(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`)
          );
          setUpdatedDate("");

          document.getElementById(props.id).click();
        }
      }
    });
  }

  function drawCalender() {
    const daysTag = document.querySelector(`.days.${props.id}`);
    var currentDate = document.querySelector(`.current-date.${props.id}`);
    var prevNextIcon = document.querySelectorAll(".icons span");

    // getting new date, current year and month
    let date = new Date(selectedDate);
    let currYear = date.getFullYear();
    let currMonth = date.getMonth();

    // storing full name of all months in array
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const renderCalendar = () => {
      let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(); // getting first day of month
      let lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(); // getting last date of month
      let lastDayofMonth = new Date(
        currYear,
        currMonth,
        lastDateofMonth
      ).getDay(); // getting last day of month
      let lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month
      let liTag = "";

      for (let i = firstDayofMonth; i > 0; i--) {
        // creating li of previous month last days
        liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
      }

      for (let i = 1; i <= lastDateofMonth; i++) {
        // creating li of all days of current month
        // adding active className to li if the current day, month, and year matched
        let isToday =
          i === date.getDate() &&
          currMonth === new Date(selectedDate).getMonth() &&
          currYear === new Date(selectedDate).getFullYear()
            ? "active"
            : "";
        var currentCheckDate = new Date();
        let isDisabled = false; 
        let minDateStr = minDate;
        if (minDate) {
          if (dateUtils.isDatePickerFormat(minDate)) {
            minDateStr = dateUtils.converToDateFormat(minDate);
          }
          const minDateData = new Date(minDateStr);
          isDisabled = new Date(currYear, currMonth, i) < new Date(minDateData.getFullYear(), minDateData.getMonth(), minDateData.getDate());
        }

        // Get the current date
        var currentCheckDay = currentCheckDate.getDate();

        // Create another Date object representing the previous date
        var previousCheckDate = new Date();
        previousCheckDate.setDate(currentCheckDay - 1);

        if (
          disableFutureDates &&
          new Date(currYear, currMonth, i) > new Date()
        ) {
          liTag += `<li class="inactive">${i}</li>`;
        } else if (
          disablePreviousDates &&
          new Date(currYear, currMonth, i) < previousCheckDate
        ) {
          liTag += `<li class="inactive">${i}</li>`;
        } else {
          liTag += `<li class="${isToday} ${isDisabled ? 'inactive' : ''}" id="date-value" value=${i}>${i}</li>`;
        }
        // liTag += `<li class="${isToday}" id="date-value" value=${i}>${i}</li>`;
      }

      for (let i = lastDayofMonth; i < 6; i++) {
        // creating li of next month first days
        liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`;
      }
      if (!currentDate) return;
      currentDate.innerText = `${months[currMonth]} ${currYear}`; // passing current mon and yr as currentDate text

      daysTag.innerHTML = liTag;
    };
    renderCalendar();

    prevNextIcon.forEach((icon) => {
      // getting prev and next icons
      icon.addEventListener("click", () => {
        // adding click event on both icons
        // if clicked icon is previous icon then decrement current month by 1 else increment it by 1
        currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

        if (currMonth < 0 || currMonth > 11) {
          // if current month is less than 0 or greater than 11
          // creating a new date of current year & month and pass it as date value
          date = new Date(currYear, currMonth);
          currYear = date.getFullYear(); // updating current year with new date year
          currMonth = date.getMonth(); // updating current month with new date month
        } else {
          date = new Date(); // pass the current date as date value
        }
        renderCalendar(); // calling renderCalendar function
      });
    });
  }
  function convertToDate(input) {
    // Regular expression to match date formats: 1/2/23 or 02/02/2023 or 10/2/23
    const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
    const match = input.match(datePattern);

    if (!match) {
      return ""; // Return empty string if input does not match the pattern
    }

    let [_, day, month, year] = match;

    // Convert day, month, year to numbers
    day = parseInt(day, 10);
    month = parseInt(month, 10);
    year = parseInt(year, 10);

    // If year is given in two digits, assume 20xx
    if (year < 100) {
      year += 2000;
    }

    // Check if month is valid
    if (month < 1 || month > 12) {
      return ""; // Return empty string if month is not valid
    }

    // Check if day is valid for the given month
    const daysInMonth = [
      31,
      isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    if (day < 1 || day > daysInMonth[month - 1]) {
      return ""; // Return empty string if day is not valid for the month
    }

    // Format day and month to two digits
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedYear = year.toString();

    // Return the date in DD/MM/YYYY format
    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
  }

  // Helper function to check if a year is a leap year
  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const [updatedDate, setUpdatedDate] = useState("");

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + '/' + value.slice(5);
    }
    setInputValue(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const isCorrectDate = moment(inputValue, 'MM/DD/YYYY', true).isValid();
      if (!isCorrectDate) return; 
      props.onChange(moment(inputValue).format('MM/DD/YYYY'), "date");
      setInputValue("")
      refBtn.current.click();
    }
  };

  const selectedYear = moment(selectedDate).year();
  const selectedMonth = moment(selectedDate).month() + 1;

  return (
    <Dropdown
      show={isOpen}
      className={`${
        disableDatePicker
          ? "highground-datpicker-disabled"
          : "highground-datpicker"
      } ${props.DropdownClassName ?? ""} radius-4 ${
        props.isError ? props?.errorStyle ?? "input-error" : ""
      } my-dropdown`}
      style={{ border: "2px solid red !important" }}
      id={props.id}
      tabIndex={-1}
      onToggle={(e) => {
        if (e) {
          setFirstLoad(true);
          setTimeout(() => {
            drawCalender();
            getValuefromLiTag();
          }, 10);
        } else {
        }
        setIsOpen(prev => !prev);
      }}
      // style={{zIndex: 0}}
    >
      <Dropdown.Toggle ref={refBtn} tabIndex={-1} disabled={disableDatePicker}>
        <div
          className="d-flex align-items-center hide-focus-outline"
          tabIndex={0}
        >
          {props.icon && <img alt="" className="mr-1" src={props.icon} />}
          <input
            disabled={disableDatePicker}
            tabIndex={-1}
            type="text"
            placeholder={`${
              props.placeholder ? props.placeholder : "Choose date"
            }`}
            className="border-none pl-2 bg-transparent-imp w-100"
            value={updatedDate ? updatedDate : props.selected}
            onChange={(e) => {
              setUpdatedDate(e.target.value);
              props.onChange("")
              if (e.target.value) {
                setIsOpen(false);
              }
            }}
            onBlur={(e) => {
              let written_date = convertToDate(e.target.value);
              if (written_date) {
                setUpdatedDate("");
                props.onChange(written_date);
              }else{
              setUpdatedDate("")
              // props.onChange("")
              }
            }}
          />
          {props.children}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu 
        ref={refMenu} 
        style={props.DropdownMenuStyle} 
        className={`date-picker ${props.menuClassName ?? ""}`}
      >
        <div className="wrapper " id={`hg-date-wrapper${props.id}`}>
          <div style={{ padding: '12px 12px 0 12px' }}>
            <input
              type="text"
              className="form-control highlight-input-with-grey-border "
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              maxLength="10"
            />
          </div>
          <header className="d-flex align-items-center justify-content-between">
            <YearMonthDropdown
              selectedYear={selectedYear}
              onYearChange={(year) => {
                const date = moment(`${year.value}/${selectedMonth}/01`).format("DD/MM/YYYY");
                setSelectedDate(date);
                props.onChange(date);
              }}
              selectedMonth={selectedMonth}
              onMonthChange={(month) => {
                const date = moment(`${selectedYear}/${month.value}/01`).format("DD/MM/YYYY");
                setSelectedDate(date);
                props.onChange(date);
              }}
            >
              <span className={`current-date ${props.id}`}></span>
            </YearMonthDropdown>
            <div className="icons">
              <span
                id="prev"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-left-cal.svg" />
              </span>
              <span
                id="next"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-right-cal.svg" />
              </span>
            </div>
          </header>
          <div className="calendar">
            <ul className="weeks">
              <li>Su</li>
              <li>Mo</li>
              <li>Tu</li>
              <li>We</li>
              <li>Th</li>
              <li>Fr</li>
              <li>Sa</li>
            </ul>
            <ul className={`days ${props.id}`}></ul>
          </div>
          <hr className="m-0 hg-border" />
          <div
            className="f-grey pl-4 p-3  pointer w-fit-content"
            onClick={() => {
              setSelectedDate(moment().format("MM/DD/YYYY"));
                setUpdatedDate("");

              props.onChange("");
            }}
          >
            Clear
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HighgroundDatePicker;
