import { viewOnlyAccess } from "../../../App/App";

export const NoSpendFoundUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
    </div>
  );
};
export const AutomaticSpendSyncingUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/syncing-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-grey f-500">
        Data is syncing
      </div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        Please come back later
      </div>
    </div>
  );
};
export const AutomaticNoSpendFoundUI = ({ type = "category" }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-14 line-height-22 f-500 mt-2 f-grey">No spend</div>
    </div>
  );
};
export const ManualNoSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
      <div
        className={`w-fit-content f-500 mt-2 ${
          viewOnlyAccess("AccessState_TechnologySpend")
            ? "f-darkgrey cursor-default"
            : "f-blue pointer"
        }`}
        onClick={() => {
          if (!viewOnlyAccess("AccessState_TechnologySpend")) {
            onClickAdd();
          }
        }}
      >
        {viewOnlyAccess("AccessState_TechnologySpend") ? (
          <img
            alt=""
            className="lockerDarkGreyImage mr-2 mb-1"
            src="/images/settings-v2/locker-grey.svg"
          />
        ) : (
          <></>
        )}
        Add Spend
      </div>
    </div>
  );
};
export const SubscriptionsNotFoundUI = ({ onAddClick = () => {} }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 f-14 line-height-22 mt-2  f-grey">No subscription</div>
      <div 
        className="w-fit-content f-500 f-14 line-height-22 f-blue cursor-pointer text-center"
        onClick={onAddClick}
      >
        Add first subscription
      </div>
    </div>
  );
};
export const SubcategoriesNotFoundUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 f-14 line-height-22 mt-2  f-grey">No subcategory</div>
    </div>
  );
};
export const NoSubscriptionSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">
        {/* No spend found */}
        No payments yet
      </div>
      <div
        // className="w-fit-content f-500 mt-2 f-blue pointer"
        className="w-fit-content mt-2 f-darkgrey pointer"
        // onClick={onClickAdd}
      >
        {/* Add Spend */}
        There will be autogenerated payments
      </div>
    </div>
  );
};
export const NoAutomaticGeneratedInvoiceUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-black">No payments yet</div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        There will be autogenerated payments
      </div>
    </div>
  );
};