export const DATE_PICKER_YEARS = Array.from({ length: 132 }, (v, k) => {
  const year = k + 1970;
  return { label: year.toString(), value: year };
});

const MONTHS = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

export const DATE_PICKER_MONTHS = MONTHS.map((month, index) => {
  return { label: month, value: index + 1 };
});