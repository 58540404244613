import React, { useEffect, useMemo, useRef, useState } from "react";
import CyberEssentialAuditDropdown, {
  getDateFormatForMoment,
} from "./createClientComplianceDropdowns/CyberEssentialAuditDropdown";
import SecurityPoliciesDropdown from "./createClientComplianceDropdowns/SecurityPoliciesDropdown";
import IncidentResponsePlanDropdown from "./createClientComplianceDropdowns/IncidentResponsePlanDropdown";
import AnnualCyberReviewDropdown from "./createClientComplianceDropdowns/AnnualCyberReviewDropdown";
import { useAuth } from "../../../context/Auth";
import {
  IndependentGetAPIData,
  IndependentPostRequest,
  IndependentSubmitQuestionaire,
  SubmitQuestionaire,
  getAPIData,
  postRequest,
} from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { handleDateForInvoice } from "../../MspAttackSurface.js/technologySpendModal/TechnologySpendModal";
import { Dropdown } from "react-bootstrap";
import CyberRiskAssessmentAuditDropdown from "./createClientComplianceDropdowns/CyberRiskAssessmentAuditDropdown";
import CyberEssentialsCertificationDropdown from "./createClientComplianceDropdowns/CyberEssentialsCertificationDropdown";
import { AttestationPopUp } from "../../../AttackSurface/KPIpopup/KPIpopup";
import {
  Attestation_Icon_Mappings,
} from "../../../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import "../../../AttackSurface/drilldownModal.scss";
import { HGHighlightText, viewOnlyAccess } from "../../../App/App";
import { TrimString } from "../../../../Utils/StringTrimmer";
import Loader from "../../../Common/loader";
import { ShowNeverExplanationList } from '../../../../constants/kpi-drilldown.constants';
import SearchInput from "../../../Common/search-input/search-input.component";
import SearchTitle from '../../../Common/search-title.component';
import useDropdown from "../../../../hooks/dropdown.hook";
import KPISettingsDropdown from "../../../../Components/AttackSurface/kpi-settings-dropdown/kpi-settings.dropdown";
import useToggleRow from "../../../../hooks/toggle-row.hook";
import QuestionListItem from "./question-list-item.component";
import { BASE_API_PAGE_ENUMS } from "../../../../constants/apis.constants";
import YesNoDropdown from "../../../Common/yes-no.dropdown";
import NoteSection from "../../../AttackSurface/KPICyberScoreSection/note-section/note-section.component";
import AddToolToSecStack from "../../../Common/add-tool-to-sec-stack/add-tool-to-sec-stack.modal";
import useApiActions from "../../../../hooks/api-actions.hook";

const SectionState = {
  Open: 0,
  NotAnswered: 1,
}

const getQuestionState = (item) => {
  const hasCheckbox = item?.QuestionTypeText === "DateAndCheckBox";
  const isCheckBoxActive = hasCheckbox && item.AnswerBool;
  const isAnswered = hasCheckbox
    ? item.AnswerDate
    : item.QuestionDrivedStatus !== 0 || item.QuestionDrivedStatus > 0;
  const noSelectedTools = !item?.SelectedTool_List || item?.SelectedTool_List?.length === 0;
  const addBorder = hasCheckbox ? isCheckBoxActive && !isAnswered : !isAnswered && noSelectedTools;

  const hasExpiredDate = item.QuestionText === "Cyber Essential Certificate";
  const addRequiredBorder = hasExpiredDate && !item.AnswerDate;
  const showNever = ShowNeverExplanationList.includes(item.Explanation);

  return {
    addBorder,
    addRequiredBorder,
    showNever,
    hasCheckbox
  }
}

const CyberComplianceForGroupModal = ({
  clientId,
  AccessStateLabel,
  passedOptionEnum99,
  showAttestationIcons,
  setShowAttestationIcons,
  isSaas,
  isViewOnly=false
}) => {
  const { authTokens } = useAuth();
  const [searchComponent, setSearchComponent] = useState('');
  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [curSectionState, setCurSectionState] = useState(null);
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    e.preventDefault();
    var parent = e.target.closest(".point");
    var panel = parent.querySelector(".panel");
    // Get all elements with class "point" and close their panels
    var allPoints = document.querySelectorAll(".point");
    allPoints.forEach(function (point) {
      let otherPanel = point.querySelector(".panel");
      let down_arrow_icon = point.querySelector(".rowDownArrow");
      if (otherPanel !== panel && otherPanel.style.maxHeight) {
        otherPanel.style.maxHeight = null;
        otherPanel.style.overflow = "hidden";
        if (down_arrow_icon) {
          down_arrow_icon.classList.remove("rotate180");
        }
      }
    });
    let down_arrow = parent.querySelector(".rowDownArrow");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.overflow = "hidden";
      if (down_arrow) {
        down_arrow.classList.remove("rotate180");
      }
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.style.overflow = "visible";
      if (down_arrow) {
        down_arrow.classList.add("rotate180");
      }
    }
  }

  function getPageData(success = false) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData(680, authTokens, {
          optionEnum1: 0,
          optionEnum3: clientId ?? 0,
          optionEnum99: passedOptionEnum99,
        }).then((response) => {
          setMainPageLoading(false);
          setLoading(false);
          if (response?.mr?.Success) {
            setPageData(response);
            setOriginalData(response?.QuestionnaireSection_List);
            let updated_data = null
            updated_data = [...response?.QuestionnaireSection_List].map(
              (section) => ({
                ...section,
                Question_List: section.Question_List.map((item) => {
                  return {
                    ...item,
                    selectedDropdownValue: item?.AnswerText
                      ? item?.QuestionDropdownListMembers?.map(
                        (listItem, index) => {
                          return {
                            id: index + 1,
                            category: listItem,
                          };
                        }
                      ).filter((filteritem) => {
                        return filteritem.category === item?.AnswerText;
                      })[0]
                      : null,
                    dropDowndata: item?.QuestionDropdownListMembers
                      ? item?.QuestionDropdownListMembers?.map(
                        (listItem, index) => {
                          return {
                            id: index + 1,
                            category: listItem,
                          };
                        }
                      )
                      : [],
                    loading: false,
                    AnswerJson:
                      item.QuestionTypeText === "CurrencyDropdown" &&
                        !item.AnswerJson
                        ? item.CurrencyCode_List.find(
                          (item) => item?.IsDefault === true
                        )
                        : item.AnswerJson,
                  };
                }),
              })
            );
            getAPIData(433, authTokens, {
              optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
              optionEnum2: 4,
              optionEnum3: 1,
            }).then((response) => {
              setQuestionList(prev => updated_data.map((item) => ({
                ...item,
                Question_List: item.Question_List.map((elmt) => {
                  const attestationItem = response?.AttestationRow_List?.find(attItem =>
                    (attItem?.TechnologyEnum === elmt?.QuestionTypeEnum) || (attItem?.TechnologyEnum === elmt?.TechnologyEnum)
                  );
                  if (attestationItem) {
                    const IntegrationList = prev
                      .find((item) => item.Question_List.some((elmt) => elmt.TechnologyEnum === attestationItem.technologyEnum))
                      ?.Question_List
                      ?.find((elmt) =>
                        (elmt.QuestionTypeEnum === attestationItem.technologyEnum) || (elmt.TechnologyEnum === attestationItem.technologyEnum)
                      )?.IntegrationList;
                    return {
                      ...attestationItem,
                      ...elmt,
                      QuestionTypeText: "Attestation",
                      loading: false,
                      ...(IntegrationList ? { IntegrationList } : { IntegrationList: [] }),
                    };
                  }
                  return {
                    ...elmt,
                    loading: false,
                  };
                })
              })));
            }).finally(() => resolve());
          }
        });
      } else {
        getAPIData(680, authTokens, {
          optionEnum1: 0,
          optionEnum3: clientId ?? 0,
        }).then((response) => {
          setMainPageLoading(false);
          setLoading(false);
          let updated_data = null
          if (response?.mr?.Success) {
            setPageData(response);
            setOriginalData(response?.QuestionnaireSection_List);
            updated_data = [...response?.QuestionnaireSection_List].map(
              (section) => ({
                ...section,
                Question_List: section.Question_List.map((item) => {
                  return {
                    ...item,
                    selectedDropdownValue: item?.AnswerText
                      ? item?.QuestionDropdownListMembers?.map(
                        (listItem, index) => {
                          return {
                            id: index + 1,
                            category: listItem,
                          };
                        }
                      ).filter((filteritem) => {
                        return filteritem.category === item?.AnswerText;
                      })[0]
                      : null,
                    dropDowndata: item?.QuestionDropdownListMembers
                      ? item?.QuestionDropdownListMembers?.map(
                        (listItem, index) => {
                          return {
                            id: index + 1,
                            category: listItem,
                          };
                        }
                      )
                      : [],
                    loading: false,
                    AnswerJson:
                      item.QuestionTypeText === "CurrencyDropdown" &&
                        !item.AnswerJson
                        ? item.CurrencyCode_List.find(
                          (item) => item?.IsDefault === true
                        )
                        : item.AnswerJson,
                  };
                }),
              })
            );
            getAPIData(433, authTokens, {
              optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
              optionEnum2: 4,
              optionEnum3: 1,
            }).then((response) => {
              setQuestionList(prev => updated_data.map((item) => ({
                ...item,
                Question_List: item.Question_List.map((elmt) => {
                  const attestationItem = response?.AttestationRow_List?.find(attItem =>
                    (attItem?.TechnologyEnum === elmt?.QuestionTypeEnum) || (attItem?.TechnologyEnum === elmt?.TechnologyEnum)
                  );
                  if (attestationItem) {
                    const IntegrationList = prev
                      .find((item) => item.Question_List.some((elmt) => elmt.TechnologyEnum === attestationItem.technologyEnum))
                      ?.Question_List
                      ?.find((elmt) =>
                        (elmt.QuestionTypeEnum === attestationItem.technologyEnum) || (elmt.TechnologyEnum === attestationItem.technologyEnum)
                      )?.IntegrationList;
                    return {
                      ...elmt,
                      ...attestationItem,
                      addingNewTool: false,
                      QuestionTypeText: "Attestation",
                      loading: false,
                      ...(IntegrationList ? { IntegrationList } : { IntegrationList: [] }),
                    };
                  }
                  return {
                    ...elmt,
                    loading: false,
                  };
                })
              })))
            }).finally(() => resolve());
          }
        });
      }
    })
  }
  const SubmitQuestions = async (data_to_send) => {
    setLoading(true);
    if (passedOptionEnum99) {
      await IndependentSubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 30,
        ObjId_Supplier: clientId,
        objId_SendFormQuestionnaire: 0,
        QuestionList: JSON.stringify(data_to_send),
        objId_Service: 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        optionEnum99: passedOptionEnum99,
      }).then((response) => {
        getPageData();
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    } else {
      await SubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 30,
        ObjId_Supplier: clientId,
        objId_SendFormQuestionnaire: 0,
        QuestionList: JSON.stringify(data_to_send),
        objId_Service: 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        getPageData();
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  };

  useEffect(() => {
    setCurSectionState(null);
  }, [searchComponent]);

  return (
    <>
      {mainPageLoading ? (
        <CreateClientComplianceLoader />
      ) : (
        <>
          <div className="search-container">
            <SearchInput
              placeholder="Search"
              value={searchComponent}
              onChange={(value) => setSearchComponent(value)}
            />
            {
              (!isViewOnly || !viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")) &&
              <KPISettingsDropdown
                showAttestationIcons={showAttestationIcons}
                setShowAttestationIcons={setShowAttestationIcons}
                switchId="showAttestationIcons2"
                clientId={clientId}
              />
            }
          </div>
          <div className="position-relative">
            {loading && (
              <div
                className="position-absolute"
                style={{
                  top: "0px",
                  left: "0px",
                  bottom: "0px",
                  right: "0px",
                  opacity: 0.5,
                  background: "#fff",
                  zIndex: 3,
                }}
              ></div>
            )}

            {/* <div className="f-16 f-600 mt-3 mb-2">Compliance</div> */}
            <div id="ccompilance-questions" className="bg-grey radius-8 border-parent">
              <div className="border-item f-12 f-500 f-grey cyber-score-table-row header-section">
                <div>Component</div>
                <div>Details</div>
                <div>Impact on Score</div>
              </div>
              {questionList.map((question, questionIndex) => (
                <QuestionRow
                  key={`qr-${questionIndex}`}
                  question={question}
                  questionIndex={questionIndex}
                  searchComponent={searchComponent}
                  curSectionState={curSectionState}
                  questionList={questionList}
                  setQuestionList={setQuestionList}
                  originalData={originalData}
                  setOriginalData={setOriginalData}
                  showAttestationIcons={showAttestationIcons}
                  rowParentId="ccompilance-questions"
                  setCurSectionState={setCurSectionState}
                  SubmitQuestions={SubmitQuestions}
                  AccessStateLabel={AccessStateLabel}
                  clientId={clientId}
                  isSaas={isSaas}
                  getPageData={getPageData}
                  setLoading={setLoading}
                  isViewOnly={isViewOnly}
                />
              ))}

              <div className="p-2 text-align-end">
                <span className="f-grey">Your CyberCompliance Score is</span>
                <span className="f-black f-500 mx-2">
                  {pageData?.TotalPercentageAnwered ?? 0}
                </span>
                <span
                  className=""
                  style={{
                    color: "#F36060",
                  }}
                >
                  High
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CyberComplianceForGroupModal;

const QuestionRow = ({
  question,
  questionIndex,
  searchComponent,
  curSectionState,
  questionList,
  setQuestionList,
  originalData,
  setOriginalData,
  showAttestationIcons,
  AccessStateLabel,
  rowParentId,
  setCurSectionState,
  SubmitQuestions,
  clientId,
  isSaas,
  passedOptionEnum99,
  getPageData,
  setLoading,
  isViewOnly=false
}) => {
  const { authTokens } = useAuth();
  const pointRef = useRef(null);
  const [isRowLoading, setIsRowLoading] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const questionTitle = SearchTitle({ title: question.Title, searchValue: searchComponent });
  const { toggleRow, refreshPanelStates, refreshState, setOpen, closeAllPanels, isOpen } =
    useToggleRow({ rowRef: pointRef, parentId: rowParentId, panelVisibleOut: true });
  const matchChildTitle = useMemo(() => (
    question?.Question_List?.some((item) => (
      SearchTitle({ title: item.QuestionText, searchValue: searchComponent })
      || SearchTitle({ title: item.TechnologyTitle, searchValue: searchComponent })
    ))
  ), [question?.Question_List, searchComponent]);
  const matchChildTools = useMemo(() => (
    question?.Question_List?.some((item) => (
      item.SelectedTool_List?.some((tool) => (
        SearchTitle({ title: tool.ToolName, searchValue: searchComponent })
      ))
    ))
  ), [question?.Question_List, searchComponent]);
  const { getAllTechTypeByIntegration } = useApiActions();

  useEffect(() => {
    refreshState();
    if (!searchComponent) {
      closeAllPanels();
      return;
    }
    if (!matchChildTitle) return;
    setOpen(true);
  }, [searchComponent]);
  if (!questionTitle && !matchChildTitle && !matchChildTools) return null;

  const yesNoId = `yes-no-dropdown-${question?.ObjectId}`;

  const answerYes = () => {
    setLoading(true);
    getAPIData(BASE_API_PAGE_ENUMS.ToolToYes, authTokens, {
      optionEnum2: clientId,
      optionEnum98: clientId,
      optionEnum3: question.ObjectId,
      optionEnum99: 26,
      optionEnum1: question.IntegrationTechnology
    }).then((response) => {
      if (response?.mr?.Success) {
        getPageData(true).then(() => {
          closeAllPanels();
          setOpen(true);
          setCurSectionState(null);
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  }

  const answerNo = () => {
    setLoading(true);
    getAPIData(BASE_API_PAGE_ENUMS.QuestionNote, authTokens, {
      optionEnum2: clientId,
      optionEnum98: clientId,
      optionEnum3: question.ObjectId,
      optionEnum99: 26,
      optionEnum1: question.IntegrationTechnology
    }).then((response) => {
      if (response?.mr?.Success) {
        getPageData(true).then(() => {
          closeAllPanels();
          setOpen(true);
          setCurSectionState(null);
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  }

  const updateNote = (noteValue) => {
    setIsRowLoading(true);
    return new Promise((resolve, reject) => {
      getAPIData(BASE_API_PAGE_ENUMS.QuestionNote, authTokens, {
        optionStrEnum1: noteValue,
        optionEnum2: clientId,
        optionEnum98: clientId,
        optionEnum3: question.ObjectId,
        optionEnum99: 26,
        optionEnum1: question.IntegrationTechnology
      }).finally(() => {
        setIsRowLoading(false);
        resolve();
      });
    })
  }

  const onQuestionClick = (questionIndex) => {
    if (!curSectionState && question.SectionAnsweredNo_Bool) {
      toggleRow();
      return;
    }
    if (
      !isViewOnly || !viewOnlyAccess(
        AccessStateLabel ?? "AccessState_Dashboard"
      )
    ) {
      if (!curSectionState && isOpen) {
        toggleRow();
        return;
      }
      if (!curSectionState && !isOpen) {
        toggleRow();
        setCurSectionState({
          sectionState: SectionState.Open,
          sectionIndex: questionIndex,
        });
      } else {
        const question = questionList[curSectionState?.sectionIndex];
        const requireAnswer = question?.Question_List?.some((item) => {
          const { addBorder, addRequiredBorder } = getQuestionState(item);
          return addBorder || addRequiredBorder;
        });
        if (curSectionState?.sectionState === SectionState.Open && requireAnswer) {
          setCurSectionState(prev => ({
            ...prev,
            sectionState: SectionState.NotAnswered,
          }));
          refreshPanelStates(question?.Question_List.length * 16);
        } else if (curSectionState?.sectionIndex !== questionIndex) {
          setCurSectionState({
            sectionState: SectionState.Open,
            sectionIndex: questionIndex,
          });
          toggleRow();
        } else {
          setCurSectionState(null);
          toggleRow();
        }
      }
    }
  }

  function getTools(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            let data_to_send = [...questionList];
            const questionIndex = questionList.findIndex((item) => item.Question_List.some((elmt) =>
              (elmt.TechnologyEnum === kwargs.technologyEnum) || (elmt.QuestionTypeEnum === kwargs.technologyEnum)
            ));
            const itemIndex = questionList[questionIndex].Question_List.findIndex((elmt) =>
              (elmt.TechnologyEnum === kwargs.technologyEnum) || (elmt.QuestionTypeEnum === kwargs.technologyEnum)
            );
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].IntegrationList = [
                ...response?.TechnologyTool_List,
              ];
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].SecurityStackList = [
                // TODO
              ];
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].loading = false;
            setQuestionList(data_to_send);
            resolve();
          })
          .catch((err) => { });
      } else {
        getAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
        })
          .then((response) => {
            let data_to_send = [...questionList];
            const questionIndex = questionList.findIndex((item) => item.Question_List.some((elmt) =>
              (elmt.TechnologyEnum === kwargs.technologyEnum) || (elmt.QuestionTypeEnum === kwargs.technologyEnum)
            ));
            const itemIndex = questionList[questionIndex].Question_List.findIndex((elmt) =>
              (elmt.TechnologyEnum === kwargs.technologyEnum) || (elmt.QuestionTypeEnum === kwargs.technologyEnum)
            );
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].IntegrationList = [
                ...response?.TechnologyTool_List,
              ];
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].SecurityStackList = [
                // TODO
              ];
            data_to_send[questionIndex][
              "Question_List"
            ][itemIndex].loading = false;
            setQuestionList(data_to_send);
            resolve();
          })
          .catch((err) => { });
      }
    });
  }

  function addNewIntegration(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentPostRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          optionEnum99: 3,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve(response);
            } else {
            }
          })
          .catch((err) => {
            // getCyberScoreData({ showErrorToast: true });
          });
      } else {
        postRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          MonitoredBySOC: kwargs.isManagedBySOC
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve(response);
            } else {
            }
          })
          .catch((err) => {
            // getCyberScoreData({ showErrorToast: true });
          });
      }
    });
  }

  async function disconnectIntegration(_integrationId) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => { });
      } else {
        getAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => { });
      }
    });
  }


  const answeredCount = (items) => {
    return items.filter((item) => {
      const hasCheckbox = item?.QuestionTypeText === "DateAndCheckBox";
      const isCheckBoxActive = hasCheckbox && item.AnswerBool;
      const isAnswered = hasCheckbox
        ? item.AnswerDate
        : item.QuestionDrivedStatus !== 0 || item.QuestionDrivedStatus > 0;
  
      return isAnswered;
    }).length;
  };

  return (
    <div
      className=" border-item point"
      onClick={(e) => {
        onQuestionClick(questionIndex);
      }}
      ref={pointRef}
      style={{
        pointerEvents: isViewOnly ? 'none' : viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard")
      }}
    >
      <div
        className={` align-items-center bg-hover-row w-100 row-section py-3 cyber-score-table-row ${isViewOnly ? 'cursor-default' : viewOnlyAccess(
          AccessStateLabel ?? "AccessState_Dashboard"
        )
            ? "cursor-default"
            : "pointer"
          }`}
      >
        <div className="d-flex  align-items-center w-100">
          {showAttestationIcons && (
            <div className="mr-2">
              <AttestationPopUp>
                <img
                  alt=""
                  src={
                    Attestation_Icon_Mappings[
                    question?.SectionAttestation
                    ]
                  }
                  className=""
                />
              </AttestationPopUp>
            </div>
          )}
          <div>
            <p className="f-500 m-0">{questionTitle ? questionTitle : question.Title}</p>
            {/* <div className="f-12 f-darkgrey">
              {" "}
              {question?.QuestionDescription}{" "}
            </div> */}
          </div>
        </div>
        {/* Second Column */}
        <div className="f-black">{question?.Description}</div>

        {/* Thhird column */}
        <div className="text-align-end">
          <div onClick={e => e.stopPropagation()}>
            {question.EnableSectionYesNo && (
              isViewOnly ? <div className="f-darkgrey">
              {question.SectionAnsweredNo_Bool !== null ? (
                question.SectionAnsweredNo_Bool ? "No" : "Yes"
              ) : 'No Selected'}
            </div> : viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ?
                <div className="f-darkgrey">
                  {question.SectionAnsweredNo_Bool !== null ? (
                    question.SectionAnsweredNo_Bool ? "No" : "Yes"
                  ) : 'No Selected'}
                </div>
                :
                <YesNoDropdown
                  id={yesNoId}
                  selected={question.SectionAnsweredNo_Bool !== null ? (
                    question.SectionAnsweredNo_Bool ? "No" : "Yes"
                  ) : null}
                  setSelected={(val) => {
                    if (val === "Yes") {
                      answerYes();
                    } else if (val === "No") {
                      answerNo();
                    }
                  }}
                />
            )}
          </div>
        </div>
      </div>
      <div className="panel" onClick={(e) => e.stopPropagation()}>
        {!question.SectionAnsweredNo_Bool ? (
          <>
            {question?.SubTitle && (
            <div className="f-12 f-500 f-grey my-2 ml-4">
              {question?.SubTitle}{" "}
            </div>
          )}
          {question?.Question_List?.map((tool, itemIndex) => (
            <QuestionListItem 
              key={`ql-${itemIndex}`}
              item={tool}
              questionIndex={questionIndex}
              itemIndex={itemIndex}
              searchComponent={searchComponent}
              questionList={questionList}
              setQuestionList={setQuestionList}
              originalData={originalData}
              setOriginalData={setOriginalData}
              SubmitQuestions={SubmitQuestions}
              curSectionState={curSectionState}
              AccessStateLabel={AccessStateLabel}
              SectionState={SectionState}
              isShow={answeredCount(question?.Question_List)}
              getQuestionState={getQuestionState}
              question={question}
              showAttestationIcons={showAttestationIcons}
              getTools={getTools}
              isViewOnly={isViewOnly}
              onToolAdd={(option) => {
                return new Promise((resolve, reject) => {
                  let updated_data = [...questionList];
                  const questionIndex = questionList.findIndex((item) => item.Question_List.some((elmt) => 
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  ));
                  const itemIndex = questionList[questionIndex].Question_List.findIndex((elmt) => 
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  );
                  const optionIndex = updated_data[questionIndex].Question_List[itemIndex].IntegrationList.findIndex((elmt) => elmt.obj_IntegrationId === option?.obj_IntegrationId);
                  if (optionIndex !== -1) {
                    updated_data[questionIndex].Question_List[itemIndex].IntegrationList[optionIndex].loading = true;
                  } else {
                    const securityStackIndex = updated_data[questionIndex].Question_List[itemIndex].SecurityStackList.findIndex((elmt) => elmt.obj_IntegrationId === option?.obj_IntegrationId);
                    updated_data[questionIndex].Question_List[itemIndex].SecurityStackList[securityStackIndex].loading = true;
                  }
                  getAllTechTypeByIntegration({
                    integrationId: option?.obj_IntegrationId,
                  }).then((response) => {
                    if (response?.IntegrationTechnologyType_List?.length > 0 && !isSaas) {
                      setSelectedIntegration({
                        ...option,
                        TechnologyType_List: response?.IntegrationTechnologyType_List,
                      });
                    }
                  })
                  addNewIntegration({
                    technologyEnum:
                      updated_data[questionIndex].TechnologyEnum,
                    IntegrationId: option?.obj_IntegrationId,
                    toolName: option?.IntegrationName,
                    selected: true,
                    rowIndex: questionIndex,
                    isManagedBySOC: option?.IsManagedBySOC,
                  }).then(() => {
                    getTools({
                      technologyEnum: tool?.TechnologyEnum,
                      rowIndex: questionIndex,
                    }).then(() => {
                      getPageData(true);
                      refreshPanelStates();
                    });
                  })
                  .finally(() => resolve());
                  setQuestionList(updated_data);
                });
              }}
              onNewToolAdd={(optionName) => {
                return new Promise((resolve, reject) => {
                  if (optionName === "") {
                    resolve();
                    return;
                  };
                  let updated_data = [...questionList];
                  const questionIndex = questionList.findIndex((item) => item.Question_List.some((elmt) =>
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  ));
                  const itemIndex = questionList[questionIndex].Question_List.findIndex((elmt) =>
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  );
                  updated_data[questionIndex].Question_List[itemIndex].addingNewTool = true;
                  addNewIntegration({
                    technologyEnum:
                      tool.TechnologyEnum,
                    IntegrationId: 0,
                    toolName: optionName,
                    selected: true,
                    rowIndex: questionIndex,
                  }).then((response) => {
                    if (response?.mr?.EntityId) {
                      const integrationId = response?.mr?.EntityId;
                      getAllTechTypeByIntegration({
                        integrationId,
                      }).then((response) => {
                        if (response?.IntegrationTechnologyType_List?.length > 0 && !isSaas) {
                          setSelectedIntegration({
                            obj_IntegrationId: integrationId,
                            ToolName: optionName,
                            TechnologyType_List: response?.IntegrationTechnologyType_List,
                          });
                        }
                      })
                    }
                    getTools({
                      technologyEnum: tool?.TechnologyEnum,
                      rowIndex: questionIndex,
                    }).then(() => {
                      getPageData(true);
                      refreshPanelStates();
                    });
                  }).finally(() => resolve());
                  setQuestionList(updated_data);
                })
              }}
              onRemoveTool={(option) => {
                return new Promise((resolve, reject) => {
                  let updated_data = [...questionList];
                  const questionIndex = questionList.findIndex((item) => item.Question_List.some((elmt) => 
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  ));
                  const itemIndex = questionList[questionIndex].Question_List.findIndex((elmt) => 
                    (elmt.TechnologyEnum === tool.TechnologyEnum) || (elmt.QuestionTypeEnum === tool.TechnologyEnum)
                  );
                  const optionIndex = updated_data[questionIndex].Question_List[itemIndex].IntegrationList.findIndex((elmt) => elmt.obj_IntegrationId === option.obj_IntegrationId);
                  if (optionIndex !== -1) {
                    updated_data[questionIndex].Question_List[itemIndex].IntegrationList[optionIndex].loading = true;
                  } else {
                    const securityStackIndex = updated_data[questionIndex].Question_List[itemIndex].SecurityStackList.findIndex((elmt) => elmt.obj_IntegrationId === option.obj_IntegrationId);
                    updated_data[questionIndex].Question_List[itemIndex].SecurityStackList[securityStackIndex].loading = true;
                  }
                  disconnectIntegration(
                    option.obj_IntegrationId
                  ).finally(() => {
                    getTools({
                      technologyEnum: tool?.TechnologyEnum,
                    }).finally(() => {
                      getPageData(true);
                      refreshPanelStates();
                      resolve();
                    });
                  });
                  setQuestionList(updated_data);
                });
              }}
            />
          ))}
          </>
        ) : (
          <NoteSection
            onResize={(target) => {
              refreshState(20)
            }}
            clientId={clientId}
            tool={question}
            refetchTools={() => {
              getPageData();
            }}
            submitNote={({ noteValue }) => updateNote(noteValue)}
            isLoading={isRowLoading}
            value={question?.SectionAnswerdNo_Note}
            userDetails={question?.UserDetails}
            LastUpdatedBy={question?.SectionAnswer_Date}
            LastUpdated={question?.SectionAnswer_Date}
          />
        )}
      </div>
      <AddToolToSecStack
        show={!!selectedIntegration}
        toolName={selectedIntegration?.ToolName}
        toolIcon={selectedIntegration?.ToolIcon}
        integrationId={selectedIntegration?.obj_IntegrationId}
        technologyTypeList={selectedIntegration?.TechnologyType_List}
        onHide={() => {
          setSelectedIntegration(null);
        }}
        onAddStack={(e) => {
          e.stopPropagation();
          setSelectedIntegration(null);
        }}
      />
    </div>
  );
}

export const CreateClientComplianceLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      {/* <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" /> */}
    </ContentLoader>
  );
};

export const CustomStringHGDropdown = ({
  showSearch,
  selected,
  onClick,
  data = [],
  placeholder = "Select",
  placeholderClass,
  selectedItemClass,
  greyBackground,
  disabled,
  setIsOpenState
}) => {
  const [searchString, setSearchString] = useState("");
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });

  useEffect(() => {
    if (!setIsOpenState) return;
    setIsOpenState(isOpen);
  }, [isOpen]);

  return (
    <Dropdown
      show={isOpen}
      className="transparennt-wrapper-dropdown "
      onToggle={(e) => {
        if (e) {
          setSearchString("");
        }
        setIsOpen(prev => !prev);
      }}
    >
      <Dropdown.Toggle ref={refBtn} className={`p-0 ${greyBackground ? "bg-grey-imp" : ""}`}>
        <div className="d-flex align-items-center  f-500 f-grey  px-2 p-2">
          {selected ? (
            <span className={`${selectedItemClass ?? ""} f-grey f-500`}>
              {selected}{" "}
            </span>
          ) : (
            <span className={`${placeholderClass ?? " f-grey f-500"}  `}>
              {placeholder}{" "}
            </span>
          )}

          {!disabled && (
            <img src="/images/big-chevron-down.svg" alt="" className="ml-2" />
          )}
        </div>
      </Dropdown.Toggle>
      {!disabled && (
        <Dropdown.Menu
          ref={refMenu}
          className="p-2"
          style={{
            width: "auto",
            minWidth: "120px",
            maxWidth: "500px",
            overflowX: "hidden",
            height: "auto",
          }}
        >
          {" "}
          {showSearch && (
            <div className="d-flex align-items-center mx-2 mt-1 mb-2">
              <img
                src="/images/attack-surface/search-icon.svg"
                className="mr-1"
                alt=""
              />
              <input
                type="text"
                className="flex-1 bg-transparent border-none pl-1"
                placeholder="Search"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
                autoFocus
              />
              <img
                src="/images/settings-v2/header-search-cross.svg"
                alt=""
                className="mx-1 pointer"
                style={{
                  visibility: searchString ? "visible" : "hidden",
                }}
                onClick={() => {
                  setSearchString("");
                }}
              />
            </div>
          )}
          {data?.filter((item) =>
            item?.toLowerCase()?.includes(searchString?.toLowerCase())
          )?.length === 0 && (
              <div className="my-2 f-darkgrey w-100 pl-4">No data found</div>
            )}
          <div
            className="transparent-scroller"
            style={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {data
              ?.filter((item) =>
                item?.toLowerCase()?.includes(searchString?.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center mb-2 f-500 f-grey pointer"
                    onClick={(e) => {
                      onClick(item, index);
                      setIsOpen(false);
                      e.target
                        .closest(".transparennt-wrapper-dropdown")
                        .click();
                    }}
                  >
                    <img
                      src="/images/msp/dropdown-grey-tick.svg"
                      alt=""
                      className="mr-2"
                      style={{
                        visibility:
                          selected && selected === item ? "visible" : "hidden",
                      }}
                    />
                    {item}
                  </div>
                );
              })}
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const test1 = {
  QuestionId: 3917390,
  QuestionAllowEdit: true,
  QuestionOrder: 0,
  QuestionScore: 0,
  QuestionDescription: "Are you Cyber Essentials certified?",
  QuestionDropdownListMembers: ["No", "Basic", "Plus"],
  QuestionSectionNo: 2,
  QuestionStatus: 5,
  QuestionStatusText: "LoadedForEdit",
  QuestionText: "Cyber Essentials Certification",
  QuestionTypeEnum: 4,
  QuestionTypeText: "Date",
  QuestionCheckBoxLabel: "na",
  QuestionUserList: null,
  AnswerId: 0,
  AnswerBool: null,
  AnswerDate: null,
  AnswerText: null,
  AnswserSelectedDropdownItems: null,
  AnswerSelectedQuestionDDLObjId: 0,
  AnswerSelectedUserNames: null,
  DateTimeAnswer: null,
  Label: null,
  QuestionParentId: 0,
  ChildQuestions: null,
  ResponseComment_List: null,
  NISTAssetClassification_List: null,
  NoteAnswerText: null,
  Explanation: null,
  Statement: null,
  InheritedText: null,
  AnswerArrayText: null,
  ActivityTrackingContributorVM: null,
  UploadedFile: null,
  IsDisabled: false,
  selectedDropdownValue: null,
  dropDowndata: [
    {
      id: 1,
      category: "No",
    },
    {
      id: 2,
      category: "Basic",
    },
    {
      id: 3,
      category: "Plus",
    },
  ],
  loading: false,
};

export const CustomHGDropdown = ({
  fieldId,
  fieldValue,
  selected,
  onClick,
  data = [],
  placeholder = "Select",
  placeholderClass,
  selectedItemClass,
  greyBackground,
  dropDownMenuClass,
  selectedColorBlack,
  dropDownToggleClass,
  placeDropDownAtTopRight,
  dataLoading,
  question,
  disabled,
  minWidth,
  selectedTextTrimLength,
  maxContentWidth,
  showSearch,
  prefix,
  contentwidth,
  putMenuAtRight,
  redBorder,
  pluralCheck
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const [searchString, setSearchString] = useState("");
  const dropdownToggleRef = refBtn;
  let selectedValue = selected?.[fieldValue];
  if (pluralCheck && selectedValue && selectedValue[selectedValue.length - 1] === "s") {
    selectedValue = selectedValue.slice(0, -1);
  }
  return (
    <Dropdown
      show={isOpen} 
      onToggle={() => {
        setIsOpen(prev => !prev);
      }}
     className="transparennt-wrapper-dropdown my-dropdown"
    >
      <Dropdown.Toggle
              ref={dropdownToggleRef}
        className={`p-0 ${greyBackground ? "bg-grey-imp" : ""
          }   ${dropDownToggleClass}  ${redBorder ? "input-error" : ""} `}
      >
        <div className="d-flex align-items-center  f-500 f-grey  px-2 p-2 w-100">
          {selected ? (
            <span
              className={`${selectedItemClass ?? ""} ${selectedColorBlack ? "f-black" : "f-grey"
                } f-500`}
            >
              {prefix && <span className="f-grey-imp">{prefix}: </span>}{" "}
              {TrimString(selectedValue, selectedTextTrimLength ?? 40)}{" "}
            </span>
          ) : (
            <span className={`${placeholderClass ?? " f-darkgrey "}  `}>
              {prefix && <span className="f-grey-imp">{prefix}: </span>}{" "}
              {placeholder}{" "}
            </span>
          )}

          {!disabled && (
            <img
              src="/images/big-chevron-down.svg"
              alt=""
              className={placeDropDownAtTopRight ? "ml-auto" : "ml-2"}
            />
          )}
        </div>
      </Dropdown.Toggle>
      {!disabled && (
        <Dropdown.Menu
          ref={refMenu}
          className={`${dropDownMenuClass}  p-1 ${putMenuAtRight && "menu-inset-right-corner"
            }`}
          style={{
            // width: "200px",
            minWidth: minWidth ?? undefined,
          }}
        >
          {dataLoading ? (
            <div
              className="d-flex align-items-center justify-content-center w-100 "
              style={{
                height: "250px",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {question && (
                <div className="f-12 f-400 f-darkgrey mb-2">{question}</div>
              )}
              {showSearch && (
                <div className="d-flex align-items-center px-2 py-2 radius-4 mb-1"
                  style={{
                    border: "1px solid #c8c8c8"
                  }}

                >
                  <img
                    src="/images/attack-surface/search-icon.svg"
                    className="mr-1"
                    alt=""
                  />
                  <input
                    type="text"
                    className="flex-1 bg-transparent border-none pl-1"
                    placeholder="Search"
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    autoFocus
                  />
                  <img
                    src="/images/settings-v2/header-search-cross.svg"
                    alt=""
                    className="mx-1 pointer"
                    style={{
                      visibility: searchString ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      setSearchString("");
                    }}
                  />
                </div>
              )}

              {data?.filter((item) =>
                item?.[fieldValue]
                  .toLowerCase()
                  ?.includes(searchString?.toLowerCase())
              )?.length === 0 && (
                  <div className="my-2 f-darkgrey w-100 pl-4">No data found</div>
                )}
              <div
                className="transparent-scroller"
                style={{
                  maxHeight: "250px",
                  overflowY: "auto",
                  width: contentwidth ?? "max-content",
                  maxWidth: maxContentWidth ?? "200px",
                }}
              >
                {data
                  ?.filter((item) =>
                    item?.[fieldValue]
                      .toLowerCase()
                      ?.includes(searchString?.toLowerCase())
                  )
                  ?.map((item, index) => {
                    let selectedItem = item?.[fieldValue];
                    if (pluralCheck && selectedItem && selectedItem[selectedItem.length - 1] === "s") {
                      selectedItem = selectedItem.slice(0, -1);
                    }
                    return (
                      <div
                        className="d-flex align-items-center f-500 f-grey pointer radius-4 bg-hover-little-grey"
                        style={{
                          padding: "0.4rem"
                        }}
                        onClick={(e) => {
                          onClick(item[fieldId], item[fieldValue], item);
                          if (dropdownToggleRef.current) {
                            dropdownToggleRef.current.click();
                          }
                          // e.target
                          //   .closest(".transparennt-wrapper-dropdown")
                          //   .click();
                        }}
                      >
                        <img
                          src="/images/msp/dropdown-grey-tick.svg"
                          alt=""
                          className="mr-2"
                          style={{
                            visibility:
                              selected && selected[fieldId] === item[fieldId]
                                ? "visible"
                                : "hidden",
                          }}
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: HGHighlightText(
                              selectedItem,
                              searchString
                            ),
                          }}
                        />

                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
