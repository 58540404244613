import { Modal, Spinner } from "react-bootstrap";
import './add-tool-to-sec-stack.scss'
import ImageLoader from "../ImageLoader";
import { Checkbox } from "semantic-ui-react";
import integrationUtils from "../../../Utils/integration.utils";
import { useState } from "react";
import useApiActions from "../../../hooks/api-actions.hook";

const AddToolToSecStack = ({ 
  show, onHide, toolName, integrationId, toolIcon, technologyTypeList = [],
  onAddStack = () => { },
}) => {
  const { createMultipleIntegrationTechTool } = useApiActions();
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  if (!show) return null;
  return (
    <>
      <Modal 
      id={`add-tool-to-sec-stack-modal-${integrationId}`}
      show={show} 
      onHide={() => {
        if (isLoading) return;
        onHide();
      }}
      className="add-tool-to-sec-stack-modal"
      containerClassName="container"
      dialogClassName="msp-add-member-modal  modal-dialog-centered modal-dialog-scrollable"
    >
      <Modal.Body className="hide-scrollbar" onClick={e => e.stopPropagation()}>
        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
          <ImageLoader 
            src={integrationUtils.getImagePath(integrationId, toolIcon)}
            alt=""
            width={24}
            height={24}
            noImageComponent={(
              <img
                width={24}
                height={24}
                alt=""
                src= "/images/attack-surface/generic2.svg"
              />
            )}
          />
          <span className="title f-black">
            Add {toolName} to your Security Stack?
          </span>
        </div>
        <p className="description f-black m-0">
          This tool isn’t in your Security Stack. Would you like to add it to your Security Stack?
        </p>
        <div className="technologies-section">
          <p className="description f-grey m-0">
            Which of the following technologies do you use this tool for?
          </p>
          {technologyTypeList.map((item, index) => (
            <div 
              key={`${item?.IntegrationTechnologyTypeName}-${item?.IntegrationTechnologyTypeEnum}-${index}`} 
              className="d-flex align-items-center" style={{ gap: '8px' }}
            >
              <Checkbox onChange={e => {
                if (e.target.checked) {
                  setSelectedTechnologies([...selectedTechnologies, item?.IntegrationTechnologyTypeEnum]);
                } else {
                  setSelectedTechnologies(selectedTechnologies.filter(tech => tech !== item?.IntegrationTechnologyTypeEnum));
                }
              }} id={`cb-${index}`} />
              <label className="m-0" htmlFor={`cb-${index}`}>
                {item?.IntegrationTechnologyTypeName}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer onClick={e => e.stopPropagation()}>
        <button 
          className="btn f-grey no-btn" 
          onClick={onHide}
          disabled={isLoading}
        >
          No, Dismiss
        </button>
        <button 
          className="btn add-btn d-flex align-items-center" 
          style={{ gap: '8px' }} 
          onClick={(e) => {
            setIsLoading(true);
            createMultipleIntegrationTechTool({
              ObjectId: integrationId,
              Selected: true,
              ToolName: toolName,
              IntegrationTechList: selectedTechnologies
            }).then(() => {
              onAddStack(e);
            }).finally(() => {
              setIsLoading(false);
            });
          }}
          disabled={isLoading || selectedTechnologies.length === 0}
        >
          <span>Add to Stack</span>
          {isLoading && (
            <Spinner
              size="sm"
              animation="border"
              variant="grey"
            />
          )}
        </button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default AddToolToSecStack;
