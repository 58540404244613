import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Dashboard.scss";
import { connect } from "react-redux";
import { Col, Row, Container, Dropdown } from "react-bootstrap";
import CC from "../CyberCompliance/CyberCompliance";
import CS from "../CyberSavings/CyberSavings";
// import CScore from "../CyberScore/CyberScore";
import CScore from "../CyberScore/CyberScore";
import ScoreDetails from "../ScoreDetails/ScoreDetails";
import CC_CONTAINER from "../CC_Container/CC_Container";

import "../../main.scss";
import { useDb } from "../context/db";
import { useAuth } from "../context/Auth";
import SideBar from "../SideBar/SideBar";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import { FiChevronRight } from "react-icons/fi";
// import InvestmentDetails from "../InvestmentDetails/InvestmentDetails";
import SavingsDetails from "../CyberSavingsDetails/CyberSavingsDetails";
import CyberROIDetails from "../CyberROIDetails/CyberROIDetails";
import CyberScoreDetails from "../CyberScoreDetails/CyberScoreDetails";
import RiskyUsers from "../RiskyUsers/RiskyUsers";
import ActiveRisks from "../ActiveRisks/ActiveRisks";
import ActiveThreats from "../ActiveThreats/ActiveThreats";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";

import { getAPIData } from "../../Utils/commonApi";

import CollapsabileTable from "../CollapsabileTable/CollapsabileTable";
import DashboardModal from "../Dashboard/DashboardModal";
import CountUp from "react-countup";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import IntegrationsModal from "../Integrations/IntegrationsModal";
import * as animationData from "../Spinner/loader.json";
import ContentLoader from "react-content-loader";

import { createStructuredSelector } from "reselect";
import { setuser } from "../../redux/userState/actions";
import { getUser } from "../../redux/userState/selectors";
import { useDispatch, useSelector } from "react-redux";
import { ResendEmail } from "../../Utils/VerifyEmail";
// dstart
import TechnicalDrillDownCyberScoreModal from "../AttackSurface/TechnicalDrillDownCyberScoreModal";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";
import { NoAccessUI } from "../App/App";
import CyberGroupModal from "../AttackSurface/CyberGroupModal";
import { getMenuItemId } from "../SideNav/SideNav";
import useMspClientDashboardData, { DashboardEnumDataType } from "./msp-client-dashboard-v2.data";
import useMspCyberSecurityPostureData from "./msp-cyber-security-posture.data";
import ClientDetails from "./msp-client-dashboard-v2/client-details/client-details.component";
import CyberSecurityPosture from "./msp-client-dashboard-v2/cyber-security-posture/cyber-security-posture.component";
import TechnologySpend from "./msp-client-dashboard-v2/technology-spend/technology-spend.component";
import TechnologiesPractices from "./msp-client-dashboard-v2/technologies-practices/technologies-practices.component";
import Header from '../Header';
import { ROUTE_ACTIONS, ROUTE_PATHS } from "../../constants/app.constants";
import useQueryParams from "../../hooks/query-params.hook";
import useApiActions from "../../hooks/api-actions.hook";
import { SAAS_USER_ACCESS_TABS, SAAS_USER_ROUTE_TABS } from "../../constants/user.constants";

// Replaced 'dbObj' with 'dashBoardPageData'

const Dashboard = (props) => {
  const location = useLocation();
  const { state: redirectInformation } = location;
  const { state } = useLocation();
  const userAccessKey = state?.userAccessKey;
  const [switchDashboardDropdownIcon, setSwitchDashboardDropdownIcon] =
    useState(false);
  const [blockDashboard, setBlockDashboard] = useState(false);
  const history = useHistory();
  const [upgradeOptions, setUpgradeOptions] = useState({});
  const clientId = useMemo(() => (
    JSON.parse(localStorage.getItem("object_Data"))?.UserDetail?.OrgId
  ), []);
  const { 
    data: clientDetailsData, 
    loading: isClientDetailsLoading,
    fetchData: fetchClientDetails
  } = useMspClientDashboardData(undefined, DashboardEnumDataType.CLIENT_DETAILS);
  const { 
    data: sidebarData, 
    loading: isSidebarLoading,
    fetchData: fetchSidebar
  } = useMspClientDashboardData(undefined, DashboardEnumDataType.SIDEBAR);
  const { 
    data: technologiesPracticesData, 
    loading: isTechnologiesPracticesLoading,
    fetchData: fetchTechnologiesPractices
  } = useMspClientDashboardData(undefined, DashboardEnumDataType.TECHOLOGIES_PRACTICES);
  const [isCyberSecurityPostureLoading, setIsCyberSecurityPostureLoading] = useState(true);
  const isDashboardLoading = isClientDetailsLoading || isSidebarLoading || isTechnologiesPracticesLoading;
  const [cyberSecurityPostureData, setCyberSecurityPostureData] = useState(null);

  function redirectUser(data) {
    if (data?.MenuVisbility?.MenuItem_List) {
      const aceesableTab = data?.MenuVisbility?.MenuItem_List
      ?.sort((a, b) => a?.SortOrder - b?.SortOrder)
      ?.find(
        (item) => {
          const accessStateKey = SAAS_USER_ACCESS_TABS[item?.CollapsedText];
          return data?.accessData?.[accessStateKey] > 0;
        }
      )
      if (aceesableTab) {
        const tab = SAAS_USER_ROUTE_TABS[aceesableTab?.CollapsedText];
        history.replace(tab);
      }
    }
  }

  const fetchDashboardData = () => {
    fetchClientDetails();
    fetchSidebar();
    fetchTechnologiesPractices();
  }

  const { getParam, clearParams } = useQueryParams();
  const action = getParam("action");

  const cyberScoreValue = useSelector(
    (state) => state.user?.userDetail?.cyberScoreValue
  );
  const [pageType] = useState(props.match.params.pageType || "");
  const [htmlOutput, setHtmlOutput] = useState();
  const rowElement = useRef(null);
  const { dbObj, setdbObject } = useDb();
  const { authTokens, setAuthTokens } = useAuth();
  const dispatch = useDispatch();
  const [dashBoardPageData, setDashBoardPageData] = useState();
  const [largeModal, setlargeModal] = useState(false);
  const [investmentModal, setinvestmentModal] = useState(false);
  const [scoreModal] = useState(false);
  const [savingsModal, setSavingsModal] = useState(false);
  const [roiModal, setRoiModal] = useState(false);
  const [riskyUsersModal, setRiskyUsersModal] = useState(false);
  const [activeThreatsModal, setActiveThreatsModal] = useState(false);
  const [activeRisksModal, setActiveRisksModal] = useState(false);
  // const { messageEncrypt } = useAuth();
  const [scoreThreat, setScoreThreat] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [count, setCount] = useState(0);
  const [anim, setAnim] = useState(true);
  const [animTwo] = useState(false);
  const [complianceHover, setComplianceHover] = useState(false);

  const [reportCounter, setReportCounter] = useState(0);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [allowDrillDownSecurityIncident, setAllowDrillDownSecurityIncident] =
    useState(true);
  const [showUpgradeToPremiumModal, setShowUpgradeToPremiumModal] =
    useState(false);

  // const [loading] = useState(localStorage.getItem("dasboardLoader"));
  const [
    showTechnicalDrillDowncyberScoreModal,
    setShowTechnicalDrillDowncyberScoreModal,
  ] = useState(false);
  const [showTechnicalDrillDownCyberComplianceModal, setShowTechnicalDrillDownCyberComplianceModal] = useState(false)
  const CURRENT_URL = useLocation().pathname;
  const url = new URLSearchParams(props.location.search);
  const modalUrl = url.get("modal");
  const userDetails = useSelector((state) => state.user?.userDetail);

  const URLpath = window.location.pathname.split("/");

  // const accessState = window.localStorage.getItem("object_Data")
  // ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  const menuItemId = getMenuItemId(`/${window.location.pathname.split('/')[2]}`)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List?.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  useEffect(() => {
    if (props.location.state) {
    }
    if (modalUrl == "CyberInvestmentDetails") {
      setinvestmentModal(false);
      setInterval(() => {
        setinvestmentModal(true);
      }, 100);
    }
  }, [props.location.state, modalUrl]);

  useEffect(() => {
    fetchDashboardData();
  }, [])

  useEffect(() => {
    if (!action || loading || isDashboardLoading) return;
    if (action === ROUTE_ACTIONS.cyberScore) {
      setTimeout(() => {
        setShowTechnicalDrillDowncyberScoreModal(true);
        setShowTechnicalDrillDownCyberComplianceModal(false);
      }, 100);
    }
  }, [action, loading, isDashboardLoading]);

  useEffect(() => {
    if (reportCounter > 1) {
      setReportModalOpen(false);
      setInterval(() => {
        setReportModalOpen(true);
      }, 100);
    }
  }, [reportCounter]);

  async function getPageData() {
    await getAPIData(`${
      602
    // 676
    }`, authTokens, {
      optionEnum1: clientId,
      optionEnum2: 0,
      isSaas: true,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setCyberSecurityPostureData(response);
        } 
      })
      .finally(() => {
        setIsCyberSecurityPostureLoading(false);
      });
  }

  useEffect(() => {
    if (!clientId) return;
    setIsCyberSecurityPostureLoading(true);
    getPageData();
  }, [clientId]);

  async function makeAPICall(num, load = true) {
    if (load) {
      setLoading(true);

    }


    localStorage.setItem(
      "dashboardUrlPrefix",
      URLpath[1].toLowerCase()
    )

    await getAPIData(num, authTokens, {
      accessKey: userAccessKey ? userAccessKey : JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((data) => {
      if (data?.authToken !== undefined) {
        setAuthTokens(data.authToken);
        setdbObject();
      } else {
        if (
          data?.mr?.Success === false ||
          !data ||
          data === null ||
          data === undefined
        ) {
          CallToast("Something went wrong", false, false, "v2style");
          return;
        }
        setdbObject(data);
        setDashBoardPageData(data);
        localStorage.setItem("object_Data", JSON.stringify(data));

        dispatch(
          setuser({
            ...data?.UserDetail,
            CommonComponents: data?.CommonComponents,
            ScoreAttestation_Icon:
              data?.CommonComponents?.ScoreAttestation_Icon,

            cyberScoreValue: data?.CommonComponents?.CyberScoreNumber,
            cyberThreatValue: data?.CommonComponents?.CyberThreatNumber,
            AccessObjList: data?.mr?.AccessObjList,
          })
        );
        setAllowDrillDownSecurityIncident(
          data?.mr?.AccessObjList?.filter(
            (item) =>
              item?.Question === "Security Incident Reporting Visibility"
          )?.[0]
        );

        if (
          data?.accessData?.Override === true &&
          data?.accessData?.AccessState_Dashboard === 0
        ) {
          setBlockDashboard(true);
        } else {
          setBlockDashboard(false);
        }
        redirectUser(data);
      }
      // setCount(count + 1);
    }).finally(() => {
      setLoading(false);
    });
  }

  function refreshDashboard() {
    setTimeout(async () => {
      let data = await getAPIData(2, authTokens);

      setdbObject(data);

      dispatch(setuser(data?.UserDetail));
    }, 200);
  }

  function toggleInvestmentModalDisplay() {
    setinvestmentModal(false);
    setInterval(() => {
      setinvestmentModal(true);
    }, 100);
  }

  function toggleSavingsModalDisplay() {
    setSavingsModal(false);
    setInterval(() => {
      setSavingsModal(true);
    }, 100);
  }

  function toggleROIModalDisplay() {
    setRoiModal(false);
    setInterval(() => {
      setRoiModal(true);
    }, 100);
  }

  function toggleRiskyUsersModal() {
    setRiskyUsersModal(false);
    setInterval(() => {
      setRiskyUsersModal(true);
    }, 100);
  }

  function setReportState(event) {
    event.preventDefault();
    setReportModalOpen(true);
  }

  function toggleActiveThreatsModal() {
    setActiveThreatsModal(false);
    setInterval(() => {
      setActiveThreatsModal(true);
    }, 100);
  }

  function toggleActiveRisksModal() {
    setActiveRisksModal(false);
    setInterval(() => {
      setActiveRisksModal(true);
    }, 100);
  }



  useEffect(() => {
    if (userAccessKey || (JSON.parse(localStorage.getItem("user_MessageObj")) && JSON.parse(localStorage.getItem("user_MessageObj")) !== null)) {
      console.log("pageTYpe", pageType);
      if (pageType === "ceo") {
        makeAPICall(0);
      } else if (pageType === "cto") {
        makeAPICall(1);
      } else if (pageType === "cfo") {
        makeAPICall(2);
      } else if (pageType.toLowerCase() === "other") {
        makeAPICall(0);
        console.log("api - CFO");
      } else if (CURRENT_URL.includes("create_support_ticket")) {
        makeAPICall(120);
      }
    }
  }, [userAccessKey]);


  function refreshData() {
    if (pageType === "ceo") {
      makeAPICall(0);
    } else if (pageType === "cto") {
      makeAPICall(1);
    } else if (pageType === "cfo") {
      makeAPICall(2);
    } else if (pageType.toLowerCase() === "other") {
      makeAPICall(0);
    }
  }

  const handleComplete = () => {
    try {
      document.getElementsByClassName("sideNav")[0].classList.remove("d-none");
      document.getElementById("mainBody").classList.add("col-sm-10");
      setAnim(false);
      // localStorage.setItem("dasboardLoader", null);
    } catch (err) {
      console.log("error in handleComplete function", err);
    }
  };

  useEffect(() => {
    if (!loading) {
      let demo = 0;
      const interval = setInterval(function () {
        demo = demo + 1;
        if (rowElement.current) {
          rowElement.current.style.width = `${demo}%`;
        } else {
          clearInterval(interval);
        }
        if (demo >= 95) {
          clearInterval(interval);
        }
      }, 40);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && dashBoardPageData && anim) {
      setTimeout(() => {
        handleComplete();
      }, 2000);
    }
    if (loading) {
      try {
        document
          .getElementsByClassName("sideNav")[0]
          .classList.remove("d-none");
        document.getElementById("mainBody").classList.add("col-sm-10");
      } catch (err) {
        console.log("error in useEffect", err);
      }
    }
  }, [loading, dashBoardPageData, anim]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("route");
    const tab = query.get("tab");
    if (tab == "subscriptions") {
      localStorage.setItem("redirectTab", true);
      setTimeout(() => {
        history.push("/system_settings");
      }, 2500);
    } else if (page == "system_settings") {
      setTimeout(() => {
        history.push("/system_settings");
      }, 2500);
    } else if (page == "pro_services") {
      setTimeout(() => {
        history.push("/pro_services");
      }, 2500);
    } else if (page == "Cyber_Essential_Status") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Cyber_Essential_Status");
      }, 2500);
    } else if (page == "IR_Plan_Status") {
      setTimeout(() => {
        history.push("/Compliance_Manager/IR_Plan_Status");
      }, 2500);
    } else if (page == "Infosec_Policies") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Infosec_Policies");
      }, 2500);
    } else if (page == "Risk_Assessment") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Risk_Assessment");
      }, 2500);
    } else if (page == "Annual_Cyber_Review") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Annual_Cyber_Review");
      }, 2500);
    } else if (page == "Test_Recovery") {
      setTimeout(() => {
        history.push("/Compliance_Manager/Test_Recovery");
      }, 2500);
    } else if (page == "integrations") {
      setTimeout(() => {
        history.push("/integrations");
      }, 2500);
    }






  }, [
    dbObj,
    loading,
  ]);

  let investmentNumber =
    dashBoardPageData?.CFOComponents?.ValueBoxes[0]?.BoxValue || "";
  let savingNumber =
    dashBoardPageData?.CFOComponents?.ValueBoxes[1]?.BoxValue || "";
  let investmentDecimal = " ";
  let savingsDecimal = " ";
  let investmentDecimalNo = 3;
  let savingsDecimalNo = 3;
  let investmentDecimalSuffix = "";
  let savingsDecimalSuffix = "";

  if (investmentNumber.includes("M")) {
    investmentNumber = investmentNumber
      .replace(",", ".")
      .replace(" ", "")
      .replace("M", "");
    investmentDecimal = ".";
    investmentDecimalNo = 2;
    investmentDecimalSuffix = "M";
  } else {
    investmentNumber = investmentNumber
      .replace(",", ".")
      .replace(" ", "");
  }

  if (savingNumber.includes("M")) {
    savingNumber =
      dashBoardPageData?.CFOComponents?.ValueBoxes[1]?.BoxValue?.replace(
        ",",
        "."
      )
        .replace(" ", "")
        .replace("M", "");
    savingsDecimal = ".";
    savingsDecimalNo = 2;
    savingsDecimalSuffix = "M";
  } else {
    savingNumber = savingNumber.replace(",", ".").replace(" ", "");
  }

  const { hideConfirmVerifiedBanner } = useApiActions();
  const [hideBanner, setHideBanner] = useState(false);

  useEffect(() => {
    if (userDetails?.ShowConfirmVerified) {
      setTimeout(() => {
        hideConfirmVerifiedBanner({ userId: userDetails?.userId });
        setHideBanner(true);
      }, 5000);
    }
  }, [userDetails?.ShowConfirmVerified]);

  const isBannerDisplayed = userDetails?.AccountVerified === false || (userDetails?.ShowConfirmVerified === true && !hideBanner);

  return (
    <>
      <DashboardHeader />
      {
        menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_Dashboard === 0) ? <>
          {
            userPortalRole === 'MSP' ?
              <NoAccessUI /> : <NoAccessUI type={userPortalRole} />
          }
        </> : <>
          {blockDashboard ? (
            <NoAccessUI />
          ) : (
            <Row className="fullHeight">
        
          {
            userDetails?.AccountVerified === false ? (
              <div
                  className="d-flex justify-content-between verify-banner"
                  style={{
                    background: `${userDetails?.VerifyLinkColour}`,
                    position: "fixed",
                    top: "69px",
                    width: "100%",
                    left: "0",
                    zIndex: 3, // 9000,
                  }}
                >
                  <div></div>
                  <div className="d-flex align-items-center">
                    <img
                      src="/images/settings-v2/msg-gray.svg"
                      className="mr-2"
                      alt=""
                    />
                    <div className="d-flex align-items-center">
                      Verify your account within{" "}
                      {userDetails?.DaysLeftToVerify} days
                      <span
                        className="link ml-2"
                        onClick={() => ResendEmail(userDetails?.UserName)}
                      >
                        Resend Link
                      </span>
                    </div>
                  </div>
                  <div></div>
                </div>
            ) : userDetails?.ShowConfirmVerified === true && !hideBanner ? (
              <div
                  className="d-flex justify-content-between verify-banner"
                  style={{
                    background: `${userDetails?.VerifyLinkColour}`,
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: 6, // 9000,
                  }}
                >
                  <div></div>
                  <div className="d-flex align-items-center">
                    Your account has been verified, thanks!
                  </div>
                  <div></div>
                </div>
            ) : null
          }
              {largeModal ? (
                <ModalDynamic
                  setShow={true}
                  modalSize="lg"
                  modalHeader={largeModal ? largeModal : ""}
                  modalData={
                    <CollapsabileTable pageType={largeModal ? largeModal : ""}  />
                  }
                  
                />
              ) : (
                ""
              )}
              {investmentModal == true ? (
                <div>
                  <ModalDynamic
                    setShow={investmentModal}
                    modalSize="lg"
                    modalHeader={"Cyber Investment Details"}
                    investmentDetailsForm={true}
                    showButton={true}
                    buttonText={"+ Add Investment"}
                    onClose={refreshDashboard}
                    
                  />
                </div>
              ) : (
                ""
              )}
              {scoreModal ? (
                <ModalDynamic
                  setShow={true}
                  modalHeader={"Cyber Score Details"}
                  modalData={<CyberScoreDetails  />}
                  
                />
              ) : (
                ""
              )}
              {savingsModal ? (
                <ModalDynamic
                  setShow={true}
                  modalSize="lg"
                  modalHeader={"Cyber Savings Details"}
                  modalData={<SavingsDetails  />}
                  
                />
              ) : (
                ""
              )}
              {roiModal ? (
                <ModalDynamic
                  setShow={true}
                  modalHeader={"Cyber ROI Details"}
                  modalData={<CyberROIDetails  />}
                  
                />
              ) : (
                ""
              )}
              {riskyUsersModal ? (
                <ModalDynamic
                  setShow={true}
                  modalSize="lg"
                  modalHeader={"Risky Users"}
                  modalData={<RiskyUsers  />}
                  
                />
              ) : (
                ""
              )}
              {activeThreatsModal ? (
                <ModalDynamic
                  setShow={true}
                  modalSize="lg"
                  modalHeader={"Active Threats"}
                  modalData={<ActiveThreats  />}
                  
                />
              ) : (
                ""
              )}
              {activeRisksModal ? (
                <ModalDynamic
                  setShow={true}
                  modalSize="lg"
                  modalHeader={"Active Risks"}
                  modalData={<ActiveRisks  />}
                  
                />
              ) : (
                ""
              )}
              {/* <Col xs={12}>
        {accountVerified == false && verifyBanner && verifyEmailBanner && (
          <div
            className="d-flex justify-content-between verify-banner"
            style={{ background: `${userDetails?.VerifyLinkColour}` }}
          >
            <div></div>
            <div className="d-flex align-items-center">
              <img
                src="/images/settings-v2/msg-gray.svg"
                className="mr-2"
                alt=""
              />
              <div className="d-flex align-items-center">
                Verify your account within {userDetails?.DaysLeftToVerify}{" "}
                days
                <span
                  className="link ml-2"
                  onClick={() => ResendEmail(userEmail)}
                >
                  Resend Link
                </span>
              </div>
            </div>
            <div></div>
          </div>
        )}
      </Col> */}
              <Col xs={12} sm={9} className="px-0 test">
                <div
                  className="dashboard fullHeight"
                  style={{ 
                    overflow: "hidden",
                    paddingLeft: '38px',
                    paddingTop: isBannerDisplayed ? '130px' : '92px'
                  }}
                >
                  <div className="d-flex flex-column" style={{ gap: '16px' }}>
                    <ClientDetails 
                      clientDetails={clientDetailsData?.ClientDetails}
                      links={{
                        User_Issues: ROUTE_PATHS.saas.userIssues,
                        Device_Issues: ROUTE_PATHS.saas.deviceIssues,
                        License: ROUTE_PATHS.saas.license,
                        Security_Package: ROUTE_PATHS.saas.securityPackage,
                      }}  
                      isLoading={isClientDetailsLoading} 
                    />
                    <div className="cyber-security-posture">
                      <CyberSecurityPosture 
                        isMSPCyberSPLoading={isCyberSecurityPostureLoading}
                        cyberSecurityPostureData={cyberSecurityPostureData}
                        setShowTechnicalDrillDowncyberScoreModal={setShowTechnicalDrillDowncyberScoreModal} 
                        setShowTechnicalDrillDownCyberComplianceModal={setShowTechnicalDrillDownCyberComplianceModal}
                      />
                    </div>
                    <TechnologySpend 
                      customerId={""}
                      allCategoriesLink={ROUTE_PATHS.saas.technologySpend}  
                    />
                    <TechnologiesPractices 
                      technologiesandPractices={technologiesPracticesData?.TechnologiesandPractices}
                      isLoading={isTechnologiesPracticesLoading} 
                    />
                  </div>
                  <ModalDynamic showModal={false} header={"Cyber ROI Details"}  />
                  <ModalDynamic
                    showModal={false}
                    header={"Cyber ROI Details"}
                    modalSize="lg"
                    
                  />
                  {reportModalOpen ? (
                    <ModalDynamic
                      setShow={true}
                      modalData={<IncidentResponseForm  />}
                      modalHeader={"Report an Incident"}
                      
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              {dbObj !== 0 ? (
                <Col 
                  xs={12} sm={3} className="sideBar fullHeight p-0"
                  style={{ 
                    zIndex: 4,
                    position: 'fixed',
                    right: 0,
                    maxWidth: '20.3%', 
                  }}
                >
                  <SideBar
                    refreshData={refreshData}
                    threatNum={
                      dbObj !== 0
                        ? dashBoardPageData?.CommonComponents?.CyberThreatNumber
                        : 0
                    }
                    sidebarData={sidebarData?.RightSidebarSections}
                    isLoading={isSidebarLoading}
                  />
                </Col>
              ) : (
                ""
              )}
              {/* <CreateTaskModal /> */}
              {/* <TechnicalDrillDownCyberScoreModal
            
            show={showTechnicalDrillDowncyberScoreModal}
            hideModal={() => setShowTechnicalDrillDowncyberScoreModal(false)}
          /> */}
              <CyberGroupModal
                isSaas={true}
                show={showTechnicalDrillDowncyberScoreModal || showTechnicalDrillDownCyberComplianceModal}
                hideModal={() => {
                  setShowTechnicalDrillDowncyberScoreModal(false);
                  setShowTechnicalDrillDownCyberComplianceModal(false)
                  clearParams();
                  if (pageType === "ceo") {
                    makeAPICall(0, false);
                  } else if (pageType === "cto") {
                    makeAPICall(1, false);
                  } else if (pageType === "cfo") {
                    makeAPICall(2, false);
                  } else if (pageType.toLowerCase() === "other") {
                    makeAPICall(0, false);
                  }
                  getPageData();
                  fetchDashboardData();
                }}
                clientId={0}
                parentID={"mainBody"}
                defaultTab={showTechnicalDrillDownCyberComplianceModal? "cyberCompliance"   :"cyberScore"}
                
              />
              <UpgradeToPremiumModal
                show={showUpgradeToPremiumModal}
                hideModal={() => setShowUpgradeToPremiumModal(false)}
                upgradeOptions={upgradeOptions}
              />
            </Row>
          )}
        </>
      }
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: getUser(),
});

const compose = connect(mapStateToProps, { setuser });

export default compose(Dashboard);

const DashboardHeader = () => (
  <header
    className="d-flex justify-content-between navbar header-bottom-shadow"
    style={{
      paddingTop: "1em",
      background: "#FFFFFF",
      position: "fixed",
      width: `${"83.25%"}`, // "",
      zIndex: 6,
      borderBottom: '1px solid #EBEDF1',
      padding: '12px 24px'
    }}
  >
    <h1 className="main_header d-flex m-0 f-18">Dashboard</h1>
    <Header/>
  </header>
);
