import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import { HGHighlightText, viewOnlyAccess } from "../../../../App/App";
import HorizontalDotsIcon from "../../../../icons/horizontal-dots.icon";
import useDropdown from "../../../../../hooks/dropdown.hook";

const MenuActions = {
  Edit: "Edit",
  Delete: "Delete",
}

const MenuItems = [
  {
    label: "Edit",
    action: MenuActions.Edit
  },
  {
    label: "Delete",
    action: MenuActions.Delete,
    isPSASpendDetected: true
  }
]

const SubcategoryListItem = ({
  subCategory,
  subcategoriesSearch,
  categoryGraphRef,
  getSubCategoryDetail,
  goTo,
  setLoading,
  donutColorIds,
  Pages,
  ModalTabs,
  isCategory = false,
  setDeleteID = () => {},
  setDeleteType = () => {},
  setDeleteMessage = () => {},
  setShowDeleteModal = () => {},
  setShowCreateSubcategoryModal = () => {},
  setCategoryDeleteData = () => {},
  setSelectedTab = () => {}
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const isViewOnly = viewOnlyAccess("AccessState_TechnologySpend");
  const menuDisabled = isViewOnly || isCategory || subCategory?.IsDefault;

  const handleAction = (action) => {
    switch (action) {
      case MenuActions.Edit:
        setShowCreateSubcategoryModal(subCategory);
        break;
      case MenuActions.Delete:
        setDeleteID(subCategory?.objId_Category);
        setDeleteType("categoryOrSubcategory");
        setDeleteMessage(
          `Deleting a subcategory will not delete any invoices or subscriptions related to this subcategory. They will be assigned to parent category instead\n\nAre you sure you want to delete this subcategory?`
        );
        setCategoryDeleteData({
          id: subCategory?.objId_Category,
          invoiceCount: subCategory?.InvoiceCount,
          deleteAllSelected: false,
          categoryName: subCategory?.CategoryName,
        })
        setShowDeleteModal(true);
        setIsOpen(false);
        break;
      default:
        break;
    }
  }

  return (
    <div
      className={`bg-hover-grey parent-visible radius-4 d-flex align-items-center justify-content-between p-2 ${!isCategory ? 'pointer' : ''}`}
      onMouseEnter={() => {
        const colorId = donutColorIds.findIndex((colorId) => colorId === subCategory.objId_Category);
        if (colorId === -1) {
          categoryGraphRef.current.expandSpecificSegement(null);
          return;
        }
        categoryGraphRef.current.expandSpecificSegement(
          colorId
        );
      }}
      onMouseLeave={() => {
        categoryGraphRef.current.expandSpecificSegement(
          null
        );
      }}
      onClick={() => {
        if (isCategory) return;
        setLoading(true);
        goTo(
          <>
            <div
              style={{
                width: "8px",
                height: "8px",
                background:
                  subCategory?.CategoryColourHexCode,
              }}
              alt=""
              className="mr-2 rounded-full d-inline-block"
            />
            {TrimString(
              subCategory?.CategoryName,
              30
            )}
          </>,
          Pages.SUBCATEGORY,
          {}
        );
        getSubCategoryDetail(
          subCategory?.objId_Category
        );
        setSelectedTab(ModalTabs.AllSpend);
      }}
    >
      <div
        className="d-flex align-items-center"
        title={subCategory?.CategoryName}
      >
        <div
          className="rounded-full mr-2"
          style={{
            width: "7px",
            height: "7px",
            background:
              subCategory?.CategoryColourHexCode,
          }}
        />
        <span 
          dangerouslySetInnerHTML={{
            __html: HGHighlightText(subCategory?.CategoryName, subcategoriesSearch)
          }}
        />
      </div>
      <div className="d-flex align-items-center" style={{ gap: '8px' }}>
        <div title={subCategory?.TotalAmount_Text}>
          {TrimString(
            subCategory?.TotalAmount_Text,
            20
          )}
        </div>
        <div 
          onClick={e => {
            e.stopPropagation();
          }}
          style={{
            ...(menuDisabled ? { opacity: 0 } : {})
          }}
        >
          <Dropdown 
            show={isOpen} 
            className="transparennt-wrapper-dropdown my-dropdown"
            onToggle={(isOpen) => {
              if (menuDisabled) return;
              setIsOpen(isOpen);
            }}
          >
            <Dropdown.Toggle 
              ref={refBtn} 
              className="child-visible"
              style={{
                pointerEvents: isCategory ? 'none' : 'auto'
              }}
            >
              <HorizontalDotsIcon fill="#616778" width="13" height="4" />
            </Dropdown.Toggle>
            <Dropdown.Menu ref={refMenu} className="menu subcategory-item-menu" style={{ padding: '4px 0' }}>
              <div>
                {MenuItems.map((item) => {
                  if (item.isPSASpendDetected && subCategory?.IsPSASpendDetected) return null;
                  return (
                    <div 
                      key={`subitem-${item.label}`} 
                      className="sub-item f-14 line-height-22 f-grey bg-hover-grey"
                      onClick={() => handleAction(item.action)}
                    >
                      {item.label}
                    </div>
                  )
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default SubcategoryListItem;
