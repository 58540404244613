import { useEffect, useRef, useState } from "react";
import "./my-tooltip.styles.scss";

const MyTooltip = ({ 
  children, 
  renderTooltip = () => {},
  onClose = () => {},
  delay = 300, 
  isFixed = false,
  disabled = false,
  hideOnMouseLeave = true,
  disableOnClickOutside = true,
  show = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const anchorRef = useRef(null);
  const hoverTimeoutRef = useRef(null);

  useEffect(() => {
    if (disabled) return;
    if (isHovered && anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.left });
      hoverTimeoutRef.current = setTimeout(() => {
        setShowTooltip(true);
      }, delay);
    } else {
      clearTimeout(hoverTimeoutRef.current);
      if (hideOnMouseLeave) {
        setShowTooltip(false);
        onClose();
      }
    }
  }, [isHovered, disabled, delay]);

  useEffect(() => {
    if (!showTooltip) {
      setIsHovered(false);
    }
  }, [showTooltip]);

  useEffect(() => {
    setShowTooltip(show);
  }, [show]);

  return (
    <div 
      className='my-tooltip' 
      onMouseLeave={() => setIsHovered(false)}
    >
      {disableOnClickOutside && showTooltip && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 3
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsHovered(false);
            setShowTooltip(false);
            onClose();
          }}
        />
      )}
      <div 
        className='d-flex align-items-center anchor'
        onMouseEnter={() => setIsHovered(true)}
        ref={anchorRef}
      >
        {children}
      </div>
      {!disabled && (
        <div 
          className={`tooltip-body hide-scrollbar ${showTooltip ? 'show' : ''}`}
          style={{ 
            ...(isFixed ? {
              position: 'fixed', top: `${menuPosition.top}px`, left: `${menuPosition.left}px`
            } : {
              position: 'absolute', top: 0, left: 0
            }),
          }}
        >
        {renderTooltip({ showTooltip, delay, setShowTooltip, onClose })}
        </div>
      )}
    </div>
  )
}

export default MyTooltip;
