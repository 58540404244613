export const USER_TYPES = {
  MSP: 555555
};

export const USER_ROLES = {
  SASS: "HG_SAAS",
  MSP: "MSP",
  MSP_CLIENT: "HG_MSP_CLIENT",
  MSP_MEMBER: "HG_MSP_MEMBER"
}

export const USER_PASSWORD_STRENGTH = [
  {
    maxValue: 0,
    color: "greyText",
    barColor: "bar-not-active",
  },
  {
    maxValue: 1,
    color: "redText",
    barColor: "bar-filled-red",
  },
  {
    maxValue: 2,
    color: "redText",
    barColor: "bar-filled-red",
  },
  {
    maxValue: 3,
    color: "orangeText",
    barColor: "bar-filled-orange",
  },
  {
    maxValue: 4,
    color: "orangeText",
    barColor: "bar-filled-orange",
  },
  {
    maxValue: 5,
    color: "greenText",
    barColor: "bar-filled",
  },
];

/**
 * User Access States
 * These are the access states that are used to determine which tabs the user has access to
 * 
 * key - CollapsedText of the tab
 * value - Access State
 */
export const SAAS_USER_ACCESS_TABS = {
  ["Dashboard"]: "AccessState_Dashboard",
  ["Technology Spend"]: "AccessState_TechnologySpend",
  ["Attack Surface"]: "AccessState_AttackSurface",
  ["Security Assessment"]: "", // TODO: Add the correct access state
  ["Trend Data"]: "AccessState_CyberTrendData",
  ["Drilldown"]: "AccessState_TechnicalDrilldown",
  ["Compliance"]: "AccessState_ComplianceManager",
  ["Pro Services"]: "AccessState_ProServices",
  ["Integrations"]: "AccessState_Integrations",
  ["Supply Chain "]: "AccessState_SCM",
  ["Managed Security"]: "AccessState_ManagedSecurity"
}

export const SAAS_USER_ROUTE_TABS = {
  ["Dashboard"]: "/ceo/dashboard",
  ["Technology Spend"]: "/technology-spend",
  ["Attack Surface"]: "/attack_surface",
  ["Security Assessment"]: "/security-assessments",
  ["Trend Data"]: "/trend_data",
  ["Drilldown"]: "/technical_drilldown",
  ["Compliance"]: "/Compliance_Manager",
  ["Pro Services"]: "/pro_services",
  ["Integrations"]: "/integrations",
  ["Supply Chain "]: "/supply",
  ["Managed Security"]: "/managed_security"
}

export const CLIENT_GETTING_STARTED_TYPE = {
  ManageTechnologySpend: 3,
  ApplySecurityPackage: 4,
  ManageGovernance: 5,
  ReviewSecurityPosture: 6,
}
