import { useState, useEffect } from 'react';
import integrationUtils from '../../../../Utils/integration.utils';

const IntegrationIcon = ({ integrationIconId, toolIcon, styles, passedClassName }) => {
  const [iconSrc, setIconSrc] = useState('/images/attack-surface/generic2.svg');

  useEffect(() => {
    const checkImage = async () => {
      if (toolIcon === 'generic') {
        setIconSrc('/images/attack-surface/generic2.svg');
      } else {
        const exists = await integrationUtils.getImagePathIfExist(integrationIconId, toolIcon)
        setIconSrc(exists);
      }
    };

    checkImage();
  }, [integrationIconId, toolIcon]);

  const checkAnyImageExists = (imagePath1, imagePath2) => {
    return new Promise((resolve) => {
      const img1 = new Image();
      const img2 = new Image();

      img1.onload = () => resolve(true); // Первое изображение успешно загрузилось
      img1.onerror = () => {
        // Если первое изображение не загрузилось, проверяем второе
        img2.onload = () => resolve(true); // Второе изображение успешно загрузилось
        img2.onerror = () => resolve(false); // Оба изображения не загрузились
        img2.src = imagePath2;
      };

      img1.src = imagePath1;
    });
  };

  return <img width={16}
    height={16}
    className={`rounded-full ${passedClassName ? passedClassName : ''}`}
    src={iconSrc}
    style={styles}
    alt="Integration Icon"
  />;
};

export default IntegrationIcon;