import { useState } from "react";
import useTechSpendActions from "../../../../../hooks/actions/subscription.actions";

const useGetSubPaymentDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const { getSubscriptionLastInvoiceDate } = useTechSpendActions();

  const fetchData = async ({ clientId, invoiceId }) => {
    setIsLoading(true);
    const response = await getSubscriptionLastInvoiceDate({
      clientId, invoiceId
    });
    setIsLoading(false);
    setData(response);
  }

  return {
    isLoading,
    data,
    fetchData,
    setData
  }
}

export default useGetSubPaymentDetails;
