import { Dropdown } from "react-bootstrap"
import useDropdown from "../../../hooks/dropdown.hook";
import './category-selector.styles.scss';
import CrossIcon from "../../icons/cross.icon";
import { useRef, useState } from "react";
import { HGHighlightText } from "../../App/App";
import SelectArrowIcon from "../../icons/select-arrow.icon";

const CategorySelectorDropdown = ({ 
  data, placeholder, selected, onSelect, fieldId, fieldValue, fieldColor, menuTitle = "Choose category",
  toggleClassName
}) => {
  const searchRef = useRef(null);
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const [search, setSearch] = useState('');

  const onItemSelect = (category) => {
    onSelect(category);
    setIsOpen(false);
    setSearch('');
  }

  return (
    <Dropdown className="w-100 transparennt-wrapper-dropdown my-dropdown category-selector" show={isOpen} onToggle={() => {
      setIsOpen(!isOpen);
      if (!isOpen) {
        searchRef.current.focus();
      }
    }}>
      <Dropdown.Toggle ref={refBtn} id="dropdown-basic" className="w-100 toggle">
        <div 
          className={`highlight-input d-flex align-items-center ${toggleClassName ? toggleClassName : ''}`} 
          style={{ padding: '4px', gap: '4px' }}
        >
          <span className="f-darkgrey">{placeholder}</span>
          {selected && (
            <CategoryItem 
              category={selected} 
              onSelect={onItemSelect}
              fieldId={fieldId}
              fieldValue={fieldValue}
              fieldColor={fieldColor}
              search={search} 
              displayCross
            />
          )}
          <input 
            ref={searchRef}
            className="w-100 f-grey" 
            value={search}
            style={{ padding: '4px 0' }} 
            onChange={(e) => {
              setSearch(e.target.value);
              setTimeout(() => {
                setIsOpen(true);
              }, 100);
            }}
          />
          <div style={{ paddingRight: '2px' }}>
            <SelectArrowIcon 
              className="arrow"
              width="12px"
              height="8px"
              style={{ minWidth: '12px' }} 
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu 
        ref={refMenu} 
        className="menu w-100 hide-scrollbar" 
        style={{ padding: '8px 0', maxHeight: '300px', overflowY: 'auto' }}
      >
        <div style={{ padding: '0 16px' }}>
          <span className="f-12 line-height-18 f-darkgrey f-500">
            {menuTitle}
          </span>
        </div>
        <div style={{ padding: '4px' }}>
          {data
          ?.filter(category => category[fieldValue].toLowerCase().includes(search.toLowerCase()))
          ?.map((category, index) => (
            <div 
              className="menu-item cursor-pointer" 
              key={`category-item-${index}`}
              onClick={() => onItemSelect(category)}
            >
              <CategoryItem 
                category={category} 
                fieldId={fieldId}
                fieldValue={fieldValue} 
                fieldColor={fieldColor}
                search={search} 
              />
            </div>
          ))}
          {data?.length === 0 && (
            <div className="f-12 line-height-18 f-grey f-500" style={{ padding: '0px 12px' }}>
              No data found
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CategorySelectorDropdown;

const CategoryItem = ({ category, fieldValue, fieldColor, displayCross, onSelect, search }) => {
  const color = category?.[fieldColor] ?? '#000';
  return (
    <div className="d-flex align-items-center category-item" style={{ gap: '4px' }}>
      <div 
        className="d-flex align-items-center justify-content-center" 
        style={{ width: '16px', height: '16px' }}
      >
        <div 
          style={{ backgroundColor: color, width: '8px', height: '8px', borderRadius: '9999px' }}
        />
      </div>
      <span 
        className="f-12 line-height-18 f-grey f-500" 
        style={{ padding: '1px 0' }}
        dangerouslySetInnerHTML={{
          __html: HGHighlightText(category[fieldValue], search)
        }}
      />
      {displayCross && (
        <CrossIcon 
          width="10px" 
          height="10px" 
          fill="#616778"
          onClick={(e) => {
            e.stopPropagation();
            onSelect(null);
          }} 
        />
      )}
      <div style={{ paddingRight: '0px' }} />
    </div>
  )
};
