import { Dropdown } from "react-bootstrap";
import { DATE_PICKER_MONTHS, DATE_PICKER_YEARS } from "../../constants/date-picker.constants";
import { useState } from "react";
import SelectArrowIcon from "../icons/select-arrow.icon";

const State = {
  Year: 0,
  Month: 1
}

const YearMonthDropdown = ({ 
  selectedYear, 
  selectedMonth, 
  onYearChange = () => {}, 
  onMonthChange = () => {},
  children 
}) => {
  const [currentState, setCurrentState] = useState(State.Year);
  const selector = currentState === State.Year ? DATE_PICKER_YEARS : DATE_PICKER_MONTHS;
  const handler = currentState === State.Year ? onYearChange : onMonthChange;
  const selectedValue = currentState === State.Year ? selectedYear : selectedMonth;

  const componentId = Math.random();

  const toggleId = `year-month-dropdown-${componentId}'}`;

  return (
    <Dropdown 
      className="transparennt-wrapper-dropdown my-dropdown"
      onToggle={(isOpen) => {
        if (!isOpen) {
          setCurrentState(State.Year);
        } else {
          setTimeout(() => {
            const selectedComponent = document.getElementById(`year-month-${selectedValue}-${componentId}`);
            const menu = selectedComponent?.closest('.menu');
            if (menu) {
              menu.scrollTop = selectedComponent.offsetTop - menu.offsetTop - (menu.clientHeight / 2) + (selectedComponent.clientHeight / 2);
            }
          }, 0);
        }
      }}
    >
      <Dropdown.Toggle id={toggleId} className="p-0 inner d-flex align-items-center" style={{ gap: '4px' }}>
        {children}
        <SelectArrowIcon width="11px" height="8px" className="arrow" fill="#616778" />
      </Dropdown.Toggle>
      <Dropdown.Menu 
        className="menu inner-menu align-left hide-scrollbar"
        style={{ height: '216px', left: 0  }}
      >
        <div style={{ padding: '12px' }}>
          {selector.map((item, index) => (
            <div 
              key={`year-month-${item?.label}`}
              id={`year-month-${item?.value}-${componentId}`} 
              className={`date-item ${selectedValue === item.value ? 'active' : ''}`}
              onClick={() => {
                handler(item);
                if (currentState === State.Year) {
                  setCurrentState(State.Month);
                } else {
                  const toggle = document.getElementById(toggleId);
                  toggle.click();
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default YearMonthDropdown;
