import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import './add-member.styles.scss'
import AddMemberIcon from "../../icons/add-member.icon";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useMemo, useRef, useState } from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import PlusIcon from "../../icons/plus.icon";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import CrossIcon from "../../icons/cross.icon";
import ChipsInformativeIcon from "../../icons/chips-informative.icon";
import OrderSummary from "../loader";
import EmailIcon from "../../icons/email.icon";
import MspMemberAccessRoleModal from "../../MSPSettingsv2/mspMembersAccess/MspMemberAccessRoleModal";
import { HGHighlightText } from "../../App/App";
import MspAccessLevelModal from "../../MSPSettingsv2/mspClientAccessLevel/MspAccessLevelModal";
import ImageLoader from "../ImageLoader";
import integrationUtils from "../../../Utils/integration.utils";
import useScrollLoader from "../../../hooks/scroll-loader.hook";
import Loader from "../../Common/loader";

const AddMemberModal = ({ 
  show, onHide, roles, hideCreateOption, inviteMember, loading,
  handleScroll, members, pageLoading, getAccessRolesData, isForMSPMember,
  memberKey, currentPage, totalPages, modalLoading,
  onSearch = () => {},
}) => {
  const [searchTimer, setSearchTimer] = useState(null);
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const [selectedMembersList, setSelectedMembersList] = useState([]);
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [inputValue, setInputValue] = useState("");
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const divRef = useRef();
  const scrollLoaderRef = useScrollLoader({
    onLoad: handleScroll,
    pager: {
      loading: pageLoading || modalLoading,
      currentPage,
      totalPages
    },
  })

  const filteredMembers = useMemo(() => (
    members.filter((item) => {
      return !selectedMembersList.find((it) => it?.[memberKey] === item?.[memberKey]);
    })
  ), [members, selectedMembersList]);

  const allSelectedRoles = useMemo(() => (
    selectedMembersList.every((item) => item.selectedRole)
  ), [selectedMembersList]);

  const handleSearch = (e) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    setSearchTimer(setTimeout(() => {
      onSearch(e.target.value);
    }, 300));
  }
  
  return (
    <>
      <Modal 
      show={show && !displayPrompt} 
      onHide={() => {
        if (showAccessLevelModal) return;
        if (selectedMembersList.length > 0) {
        setDisplayPrompt(true);
        } else {
          onHide();
        }
      }}
      className="add-member-modal"
      containerClassName="container"
      dialogClassName="msp-add-member-modal  modal-dialog-centered modal-dialog-scrollable"
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center" style={{ gap: '4px' }}>
          <AddMemberIcon />
          <h3 className="m-0">Add Member</h3>
          {modalLoading && (
            <Spinner
              animation="border"
              variant="grey"
              size="sm"
              className="mx-1"
              style={{
                width: "10px",
                height: "10px",
              }}
            />
          )}
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        <div
          style={{
            ...(!selectedMembersList.length && {
              display: 'none'
            })
          }}
        >
          <div 
            className="d-flex align-items-center justify-content-between"
            style={{ padding: '4px 8px' }}
          >
            <div className="f-grey f-14" style={{ fontWeight: 500 }}>Invite to team</div>
            <div className="f-grey f-14" style={{ fontWeight: 500, marginRight: '48px' }}>Access level</div>
          </div>
          {selectedMembersList.map((item, index) => (
            <div 
              className="d-flex align-items-center justify-content-between pointer radius-8 bg-hover-grey hover-on-show-parent"
              style={{ padding: '4px 8px' }}
            >
              <div className="d-flex align-items-center  ">
                {item.isCustom ? (
                  <div className="d-flex align-items-center justify-content-center" style={{ width:'32px', height:'32px' }}>
                    <EmailIcon width="17px" height="16px" fill="#616778" />
                  </div>
                ) : item.showInitials ? (
                  <div style={{ position: 'relative' }}>
                    <ImageWithInitials
                      initials={item.initials}
                      width={30}
                      background={"purple"}
                    />
                    <IntegrationIcon integrationId={item?.ObjdId_Integration} passedClassName={'mr-2'}/>
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <img
                      alt=""
                      src={`/images/${item.ImageURL}`}
                      className=""
                      style={{
                        width: "30px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <IntegrationIcon integrationId={item?.ObjdId_Integration} passedClassName={'mr-2'}/>
                  </div>
                )}
                <div className="ml-2">
                  <div 
                    className="f-500 f-14 f-black"
                    style={{ lineHeight: '22px' }}
                  >
                    {item.fullName}
                  </div>
                  <div 
                    className="f-12 f-grey"
                    style={{ lineHeight: '18px' }}
                  >
                    {item.userName}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center" style={{ gap: '24px' }}>
              <Dropdown
                className="hg-white-dropdown user-detail-dropdown my-dropdown"
              >
                <Dropdown.Toggle 
                  className={`custom-dropdown-toggle f-gray-imp`}
                  style={{ padding: '1px 4px' }}
                >
                  {item.selectedRole ? item.selectedRole?.RoleName : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="menu">
                  {!hideCreateOption && (
                    <div
                      className="my-1 pointer f-darkgrey"
                      style={{
                        display: "grid",
                        "grid-template-columns": "10% 90%",
                         padding: '4px 8px'
                      }}
                      onClick={(e) => {
                        setShowAccessLevelModal(true);
                        e.target.closest(".hg-white-dropdown").click();
                      }}
                    >
                      <img
                        alt=""
                        src="/images/settings-v2/lightgrey-plus-icon.svg"
                        className="mr-2"
                      />
                      <div>Create Access</div>
                    </div>
                  )}
                  <RoleDropdown 
                    roles={roles} 
                    selectedRole={item.selectedRole} 
                    setSelectedRole={(role) => {
                      const newList = [...selectedMembersList];
                      newList[index].selectedRole = role;
                      setSelectedMembersList(newList);
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
                <div
                  className="hover-on-show-child f-darkgrey pr-1"
                  onClick={() => {
                    let newList = [...selectedMembersList];
                    newList.splice(index, 1);
                    setSelectedMembersList(newList);
                  }}
                >
                  <CrossIcon width="10px" height="10px" fill="#616778" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex align-items-center" style={{ gap: '4px' }}>
          <div className="d-flex search-wrapper w-100">
            <input
              className="w-100" 
              type="text" 
              placeholder="Enter name or email"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                handleSearch(e);
              }}
            />
            <Dropdown
              className="hg-white-dropdown user-detail-dropdown my-dropdown"
              onToggle={(e)=>{
                setIsDropdownOpen(e)
              }}
            >
              <Dropdown.Toggle 
                className={`custom-dropdown-toggle f-gray-imp ${IsDropdownOpen ?  "opened"  :""}`}
                style={{ padding: '1px 4px' }}
              >
                {selectedRole ? selectedRole?.RoleName : "Select"}
              </Dropdown.Toggle>
              <Dropdown.Menu className="menu">
                {!hideCreateOption && (
                  <div
                    className="p-2 pl-3 my-1 pointer f-darkgrey"
                    style={{
                      display: "grid",
                      "grid-template-columns": "10% 90%",
                       padding: '4px 8px'
                    }}
                    onClick={(e) => {
                      setShowAccessLevelModal(true);
                      e.target.closest(".hg-white-dropdown").click();
                    }}
                  >
                    <img
                      alt=""
                      src="/images/settings-v2/lightgrey-plus-icon.svg"
                      className="mr-2"
                    />
                    <div>Create Access</div>
                  </div>
                )}
                <RoleDropdown 
                  roles={roles} 
                  selectedRole={selectedRole} 
                  setSelectedRole={setSelectedRole}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button
            className={loading ? "hg-cancel-btn" :
              (selectedMembersList.length > 0) ? "hg-blue-btn" : "hg-cancel-btn"
            }
            disabled={selectedMembersList.length === 0 || loading || !allSelectedRoles || showAccessLevelModal}
            style={{ height: "fit-content", alignSelf: "end", whiteSpace: "nowrap" }}
            onClick={() => {
              inviteMember(selectedMembersList).then(() => {
                setSelectedMembersList([]);
                setSelectedRole(null);
              });
            }}
          >
            {loading ? "Inviting..." : `Invite ${selectedMembersList.length ? `(${selectedMembersList.length})` : ''}`}{" "}
          </Button>
        </div>
        {pageLoading ? (
          <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <OrderSummary />
          </div>
        ) : !modalLoading && members.length === 0 && !inputValue ? (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center" style={{ height: '216px', gap: '16px' }}>
            <ChipsInformativeIcon />
            <div>
              <div className="f-14 f-grey" style={{ lineHeight: '22px', fontWeight: 600, textAlign: 'center' }}>
                Invite your team
              </div>
              <div className="f-14 f-darkgrey" style={{ lineHeight: '22px', textAlign: 'center'  }}>
                Enter email and invite your team
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div 
              className="d-flex align-items-center justify-content-between"
              style={{ padding: '4px 8px' }}
            >
              <div className="f-grey f-14" style={{ fontWeight: 500, padding: '6px 0' }}>
                {!inputValue ? "Imported members" : "Result"}
              </div>
                {!inputValue && (
                  <div className="w-fit-content dropdown-grey-theme score-question-dropdown xx ml-3 w-70">
                  <CompanySettingsDropdown
                    fieldId={"id"}
                    fieldValue={"cat"}
                    selected={{ id: 1, cat: "All" }}
                    placeholder="Select"
                    //  showid={true}
                    onClick={(id, val) => {}}
                    data={[{ id: 1, cat: "All" }]}
                  />
                </div>
              )}
            </div>
            <div
              ref={divRef}
              onScroll={handleScroll}
              className="hide-scrollbar"
            >
              {filteredMembers.map((item, index) => {
                return (
                  <div 
                    className="d-flex align-items-center justify-content-between pointer radius-8 bg-hover-grey hover-on-show-parent"
                    style={{ padding: '4px 8px' }}
                  >
                    <div className="d-flex align-items-center  ">
                      <div style={{ position: 'relative' }}>
                        {item.showInitials ? (
                          <ImageWithInitials
                            initials={item.initials}
                            width={30}
                            background={"purple"}
                          />
                        ) : (
                          <img
                            alt=""
                            src={`/images/${item.ImageURL}`}
                            className=""
                            style={{
                              width: "30px",
                              borderRadius: "50%",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        <IntegrationIcon integrationId={item?.ObjdId_Integration} passedClassName={'mr-2'}/>
                      </div>
                      <div className="ml-2">
                        <div 
                          className="f-500 f-14 f-black"
                          style={{ lineHeight: '22px' }}
                        >
                          <span 
                            dangerouslySetInnerHTML={{
                              __html: HGHighlightText(
                                item.fullName,
                                inputValue
                              ),
                            }}
                          />
                        </div>
                        <div 
                          className="f-12 f-grey"
                          style={{ lineHeight: '18px' }}
                        >
                          <span 
                            dangerouslySetInnerHTML={{
                              __html: HGHighlightText(
                                item.userName,
                                inputValue
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {!item.isInvited ? (
                      <div
                        className="hover-on-show-child f-darkgrey"
                        onClick={() => {
                          let newList = [...selectedMembersList];
                          const existingObj = newList.find(
                            (it) => it?.[memberKey] === item?.[memberKey]
                          );
        
                          if (!existingObj) {
                            newList.push({
                              ...item,
                              selectedRole
                            });
                          }
                          setSelectedMembersList(newList);
                        }}
                      >
                        <PlusIcon />
                        <span className="ml-1">
                          Add
                        </span>
                      </div>
                    ) : (
                      <div className="invited-tag">
                        Invited
                      </div>
                    )}
                  </div>
                );
              })} 
              {inputValue && (
                <div 
                  className="d-flex align-items-center justify-content-between pointer radius-8 bg-hover-grey hover-on-show-parent"
                  style={{ padding: '4px 8px' }}
                >
                  <div className="d-flex align-items-center  ">
                    <div className="d-flex align-items-center justify-content-center" style={{ width:'32px', height:'32px' }}>
                      <EmailIcon width="17px" height="16px" fill="#616778" />
                    </div>
                    <div className="ml-2">
                      <div 
                        className="f-500 f-14 f-black"
                        style={{ lineHeight: '22px' }}
                      >
                        {inputValue}
                      </div>
                      <div 
                        className="f-12 f-grey"
                        style={{ lineHeight: '18px' }}
                      >
                        Keep typing to invite by email
                      </div>
                    </div>
                  </div>
                  <div
                    className="hover-on-show-child f-darkgrey"
                    onClick={() => {
                      let newList = [...selectedMembersList];
                      const existingObj = newList.find(
                        (it) => it.userName === inputValue
                      );
                      if (existingObj) {
                        return;
                      }
                      newList.push({
                        userName: inputValue,
                        objId_PSAMember: 0,
                        objId_PSAContact: 0,
                        isCustom: true,
                        selectedRole
                      });
                      setSelectedMembersList(newList);
                      setInputValue("")
                    }}
                  >
                    <PlusIcon />
                    <span className="ml-1">
                      Add
                    </span>
                  </div>
                </div>
              )} 
              <div ref={scrollLoaderRef} />
              {modalLoading && (
                <div className="my-4">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        )}
        <MspMemberAccessRoleModal
          show={showAccessLevelModal && isForMSPMember}
          hideModal={() => {
            setShowAccessLevelModal(false);
          }}
          parentID="msp-main-screen-for-integration-modal"
          refreshData={() => {
            getAccessRolesData();
          }}
        />
        <MspAccessLevelModal
          accessLevelId={0}
          show={showAccessLevelModal && !isForMSPMember}
          hideModal={() => {
            setShowAccessLevelModal(false);
          }}
          parentID="msp-main-screen-for-integration-modal"
          refreshData={() => {
            getAccessRolesData();
          }}
          dontAllowEdit={false}
          refreshDataOnDelete={() => {
            getAccessRolesData();
          }}
        />
      </Modal.Body>
    </Modal>
    {displayPrompt && (
      <Modal
        show={displayPrompt}
        onHide={() => {
          setDisplayPrompt(false);
        }}
        className="add-member-modal prompt"
      containerClassName="container"
      dialogClassName="msp-add-member-modal  modal-dialog-centered modal-dialog-scrollable"
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center" style={{ gap: '4px' }}>
            <AddMemberIcon />
            <h3 className="m-0">Add Member</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center h-100" style={{ gap: '32px' }}>
            <div className="f-grey" style={{ whiteSpace: 'pre-wrap', fontSize: '18px', fontWeight: 500, textAlign: 'center' }}>
            {"Do you want to close the modal?\nYou will lose not stored data."}
            </div>
            <div className="d-flex align-items-center" style={{ gap: '8px' }}>
              <Button className="w-100" onClick={() => {
                setDisplayPrompt(false);
              }}>
                Cancel
              </Button>
              <Button
              className="w-100" 
                variant="grey"
              onClick={() => {
                onHide();
              }}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )}
    </>
  );
}

export default AddMemberModal;

const IntegrationIcon = ({ integrationId }) => {
  if (!integrationId) return null;
  return (
    <div 
      className="absolute d-flex"
      style={{
        right: "-2px",
        bottom: "-2px",
        position: 'absolute',
      }}
    >
      <ImageLoader 
        src={integrationUtils.getImagePath(integrationId)}
        width="12px"
        height="12px"
        style={{
          borderRadius: '9999px'
        }}
        noImageComponent={(
          <img 
            alt=""
            src={"/images/attack-surface/generic2.svg"}
            className="absolute"
            style={{
              width: '12px',
              height: '12px',
              right: '0',
              bottom: '0',
              borderRadius: '9999px'
            }}
          />
        )}
      />
    </div>
  )
}

const RoleDropdown = ({ roles, selectedRole, setSelectedRole }) => {
  return (
    <div
      className="hide-scrollbar"
      style={{ maxHeight: '350px', overflowY: 'auto' }}
    >
      {roles.map((el) => {
        return (
          <div
            className="p-2 pl-3 my-1 pointer f-grey"
            style={{
              display: "grid",
              "grid-template-columns": "10% 90%",
            }}
            onClick={(e) => {
              setSelectedRole(el);
              e.target.closest(".hg-white-dropdown").click();
            }}
          >
            <div>
              {selectedRole?.RoleName === el?.RoleName ? (
                <img
                  alt=""
                  src="/images/settings-v2/check-icon.svg"
                  className="mr-2"
                />
              ) : (
                <></>
              )}
            </div>
          
            <div>
              {TrimString(el?.RoleName,30)}
              <div className="smallGreyText text-ellipsis f-darkgrey" title={el.RoleDescription}>
                {TrimString(el.RoleDescription,40)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
}
