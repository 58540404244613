import { Dropdown } from "react-bootstrap";
import { HGHighlightText } from "../../App/App";
import moment from "moment";
import useDropdown from "../../../hooks/dropdown.hook";
import HorizontalDotsIcon from "../../icons/horizontal-dots.icon";
import SubscriptionIcon from "../../Common/subscription-icon.component";

const MenuActions = {
  Edit: "Edit",
}

const MenuItems = [
  {
    label: "Edit",
    action: MenuActions.Edit
  }
]

const SubscriptionCard = ({
  subscriptionItem,
  searchValue = "",
  showSubcategory = true,
  onClick = () => {},
  onEditClick = () => {},
  relatedToCategory = () => {}
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const isRelatedToCategory = relatedToCategory(subscriptionItem?.ObjId_SubCategory);
  
  const handleAction = (action) => {
    switch (action) {
      case MenuActions.Edit:
        onEditClick(subscriptionItem);
        break;
      default:
        break;
    }
  }

  return (
    <div 
      className="sub-tile variant-2 pointer d-flex w-100 parent-visible"
      onClick={() => {
        onClick(subscriptionItem);
      }}
      style={{ padding: '4px 8px' }}
    >
      <div className="w-100">
        <div className="d-flex align-items-center mb-2">
          <SubscriptionIcon subscriptionItem={subscriptionItem} />
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="text-ellipsis f-14 line-height-22 d-flex flex-column">
              <span
                dangerouslySetInnerHTML={{
                  __html: HGHighlightText(
                    subscriptionItem?.SubscriptionTitle,
                    searchValue
                  ),
                }}
              />
              {showSubcategory && (
                subscriptionItem?.SubCategoryName ? (
                  <span className={`f-12 line-height-18 ${isRelatedToCategory ? 'f-blue' : 'f-grey'}`}>
                    {subscriptionItem?.SubCategoryName}
                  </span>
                ) : (
                  <span className={`f-12 line-height-18 f-darkgrey`}>
                    No subcategory
                  </span>
                )
              )}
            </div>
            <div className="d-flex">
              <div
                style={{
                  background: subscriptionItem?.StatusColor,
                }}
                className={`status 
                `}
              >
                {subscriptionItem.Status}{" "}
              </div>
              <div 
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Dropdown 
                  show={isOpen} 
                  className="transparennt-wrapper-dropdown my-dropdown"
                  onToggle={(isOpen) => {
                    setIsOpen(isOpen);
                  }}
                >
                  <Dropdown.Toggle 
                    ref={refBtn} 
                    className="child-visible"
                    style={{ padding: '0px 6px', marginLeft: '4px' }}
                  >
                    <HorizontalDotsIcon fill="#616778" width="13" height="4" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu ref={refMenu} className="menu subcategory-item-menu" style={{ padding: '4px 0' }}>
                    <div>
                      {MenuItems.map((item) => (
                        <div 
                          key={`subitem-${item.label}`} 
                          className="sub-item f-14 line-height-22 f-grey bg-hover-grey"
                          onClick={() => handleAction(item.action)}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mb-1" style={{ padding: '0 28px' }}>
          <div className="text-ellipsis f-grey f-12 f-500">Amount</div>
          <div className={` f-darkgrey f-12`}>
            {subscriptionItem?.Amount}{" "}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mb-1" style={{ padding: '0 28px' }}>
          <div className="text-ellipsis f-grey f-12 f-500">Renews</div>
          <div className={` f-darkgrey f-12`}>
            {moment(
              `${subscriptionItem?.EndDate?.Month}/${subscriptionItem?.EndDate?.Day}/${subscriptionItem?.EndDate?.Year}`
            ).format("ll")}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mb-1" style={{ padding: '0 28px' }}>
          <div className="text-ellipsis f-grey f-12 f-500">Payments</div>
          <div className={` f-darkgrey f-12`}>
            {" "}
            {subscriptionItem?.Invoices}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 mb-1" style={{ padding: '0 28px' }}>
          <div className="text-ellipsis f-grey f-12 f-500">Supplier</div>
          <div className={` f-darkgrey f-12`}>
            {" "}
            {subscriptionItem?.Supplier}{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCard;
