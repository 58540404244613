import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import "./mspAttackSurface.scss";
import Loader from "../../Common/loader";
import { WhiteLoader } from "../../Theme/APILoaders";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";

const CreateCategorySpendModal = ({
  show,
  hideModal,
  refreshData = () => {},
  mapProductCategory = () => {},
  categoryName,
  isSaas,
}) => {
  const clientId = window.location.pathname.split("/")[4];
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [categoryData, setCategoryData] = useState({
    name: "",
    colorData: {},
  });

  const [subCategoryList, setSubCategoryList] = useState([
    // {
    //   name: "",
    //   colorData:{},
    // },
  ]);
  const alreadyUsedColorsForSubCategories = subCategoryList?.map((item) => ({
    ColorCode: item?.colorData?.ColorCode,
    IsInUse: true,
  }));

  useEffect(() => {
    if (show) {
      setSubCategoryList([]);
      setCategoryData({
        name: categoryName ? categoryName : "",
        colorData: {},
      });
      setLoading(true);
      getModalData();
    }
    else {
      setCategoryData({
        name: "",
        colorData: {},
      });
    }
  }, [show]);

  useEffect(() => {
    if (!show) return;
    if (modalData?.CategorySpendColor_List?.length === 0) return;
    let _categoryColor = modalData?.CategorySpendColor_List?.find((item) => item?.IsInUse === false)
    if (_categoryColor) {
      setCategoryData((prev) => ({
        ...prev,
        colorData: _categoryColor,
      }));
    }
  }, [show, modalData?.CategorySpendColor_List]);

  async function getModalData() {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: 0,
      ObjId_Customer: clientId,
      LoadInvoice: false,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setModalData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => { });
  }
  async function createCategory() {
    setSaving(true);
    let sub_category_list = subCategoryList?.map(
      (subcategory, subcategoryIndex) => ({
        objId_Category: 0,
        objId_Customer: clientId,
        CategoryName: subcategory?.name,
        CategoryColour: subcategory?.colorData?.ColorName,
        TotalAmount: 0.0,
        IsDefault: false,
        ParentCategoryId: 0,
        InvoiceItemVM_List: [],
        SubCategory_List: [],
        CategorySpendColor_List: [],
        SubCategorySpendColor_List: [],
      })
    );

    await postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Customer: clientId,
      CategoryName: categoryData?.name,
      CategoryColour: categoryData?.colorData?.ColorName,
      ParentCategoryId: 0,
      SubCategory_List: JSON.stringify(sub_category_list),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
          refreshData(response.EntityId);
          mapProductCategory(response?.ObjectTemplateList[0])
        } else {
          // CallToast("Something went wrong", false, false, "v2style");
          setCategoryData((prev) => ({ ...prev, error: response?.Message }));
        }
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function errorCount() {
    let errors = 0;
    if (categoryData?.name.trim() === "") {
      errors += 1;
      setCategoryData((prev) => ({ ...prev, error: true }));
    }
    let updated_subcat_list = [...subCategoryList];
    for (let index = 0; index < updated_subcat_list.length; index++) {
      if (updated_subcat_list[index].name === "") {
        errors += 1;
        updated_subcat_list[index].error = true;
      }
    }
    setSubCategoryList(updated_subcat_list);
    return errors;
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="create-category-spend-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div></div>
          <div className="f-500 ml-3 f-16">Add category spend</div>
          <img
            alt=""
            src="/images/user-detail-close.svg"
            className="mr-3 pointer"
            onClick={hideModal}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="d-flex align-items-center"
              style={{ height: "37px" }}
            >
              {/* Input */}
              <div
                className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1
              ${categoryData?.error ? "input-error" : ""}
              
              `}
              >
                <div className="f-darkgrey">Name:</div>

                <input
                  type="text"
                  className="bg-transparent w-100 border-none pl-2"
                  placeholder="Type"
                  value={categoryData?.name}
                  onChange={(e) => {
                    let value = e.target.value.replaceAll(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    );
                    setCategoryData((prev) => ({
                      ...prev,
                      name: value,
                      error: false,
                    }));
                  }}
                />
              </div>

              <div className="bg -grey h-1 00 d-flex align-items-center">
                <ColorDropdown
                  colorListWithAvailability={modalData?.CategorySpendColor_List}
                  dropDownMenuClass={"setDropdownLeft"}
                  height={37}
                  itemCircleWidth={14}
                  selectedCircleWidth={17}
                  data={
                    modalData?.CategorySpendColor_List?.map(
                      (item, itemIndex) => ({ ...item, id: itemIndex })
                    ) ?? []
                  }
                  selected={categoryData?.colorData}
                  onChange={(_colorData) => {
                    setCategoryData((prev) => ({
                      ...prev,
                      colorData: _colorData,
                    }));
                  }}
                />
              </div>
            </div>

            {
              (categoryData?.error || categoryData?.error?.length > 5) && <span className="f-danger"> {categoryData?.error} </span>
            }

            <div className="f-12 f-500 f-darkgrey ml-2 mt-2">Subcategories</div>

            {subCategoryList?.map((category, categoryIndex) => {
              return (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "37px" }}
                  >
                    {/* Input */}
                    <div
                      className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-border  ${
                        category?.error ? "input-error" : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={subCategoryList[categoryIndex].name}
                        onChange={(e) => {
                          let updated_data = [...subCategoryList];
                          let value = e.target.value.replaceAll(
                            /[^a-zA-Z0-9\s]/g,
                            ""
                          );
                          updated_data[categoryIndex].name = value;
                          updated_data[categoryIndex].error = false;
                          setSubCategoryList(updated_data);
                        }}
                      />
                    </div>

                    <div className="bg -grey h-1 00 d-flex align-items-center">
                      <ColorDropdown
                        isSubCategrory={true}
                        dropDownMenuClass={"setDropdownLeft"}
                        colorListWithAvailability={
                          modalData?.SubCategorySpendColor_List?.length > 0
                            ? [
                                ...modalData?.SubCategorySpendColor_List,
                                ...alreadyUsedColorsForSubCategories,
                              ]
                            : [...alreadyUsedColorsForSubCategories]
                        }
                        height={34}
                        itemCircleWidth={14}
                        selectedCircleWidth={14}
                        onChange={(e) => {
                          let updated_data = [...subCategoryList];
                          updated_data[categoryIndex].colorData = e;
                          setSubCategoryList(updated_data);
                        }}
                        data={
                          modalData?.SubCategorySpendColor_List?.map(
                            (item, itemIndex) => ({ ...item, id: itemIndex })
                          ) ?? []
                        }
                        selected={subCategoryList[categoryIndex].colorData}
                      />
                    </div>

                    <img
                      alt=""
                      width={12}
                      src="/images/attack-surface/small-cross.svg"
                      className="ml-2 pointer"
                      onClick={(e) => {
                        let updated_data = [...subCategoryList];
                        updated_data.splice(categoryIndex, 1);
                        setSubCategoryList(updated_data);
                      }}
                    />
                  </div>
                </>
              );
            })}
            <div
              className="  d-flex align-items-center pointer   f-grey f-500 mt-2"
              onClick={() => {
                let alreadySelectedColorsInSubcategories = subCategoryList.map((item)=>item?.colorData?.ColorCode)
                let _colorData= {} 
                if(alreadySelectedColorsInSubcategories?.length >0){
                  _colorData = modalData?.SubCategorySpendColor_List?.find((item)=> item?.IsInUse===false && !alreadySelectedColorsInSubcategories.includes(item?.ColorCode))
                } else{
                  _colorData = modalData?.SubCategorySpendColor_List?.find((item)=> item?.IsInUse===false)
                }    
                let updated_data = [
                  ...subCategoryList,
                  {
                    name: "",
                    colorData: {..._colorData},
                  },
                ];

                setSubCategoryList(updated_data);
              }}
            >
              <img
                alt=""
                className="mx-2"
                src="/images/actioncenter/plusgrey.svg"
              />
              Add subcategory
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="p-2">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={async () => {
              let errors = await errorCount();
              if (errors === 0) {
                createCategory();
              }
            }}
          >
            {saving ? (
              <>
                <WhiteLoader width={13} />
                Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCategorySpendModal;

export const ColorDropdown = (props) => {
  const {
    isSubCategrory,
    data,
    itemCircleWidth,
    selectedCircleWidth,
    dropDownClass,
    selected,
    height,
    dropDownMenuClass,
    colorListWithAvailability,
  } = props;

  function NotAvailableColor(color, _colorListWithAvailability) {
    if (!_colorListWithAvailability) return false;
    if (_colorListWithAvailability.length === 0) return false;
    if (!color) return false;

    for (const item of _colorListWithAvailability) {
      if (
        item?.ColorCode?.toLowerCase() === color?.toLowerCase() &&
        item?.IsInUse === true
      ) {
        return true;
      }
    }
    return false;
  }
  return (
    <Dropdown
      // alignRight
      className={`color-wrapper-dropdown px-2 d-flex align-items-center radius-4 bg-grey ${dropDownClass}`}
      style={{
        height: `${height}px`,
      }}
    >
      <Dropdown.Toggle>
        <div className="d-flex align-items-center">
          <div
            className="rounded-full"
            style={{
              width: `${selectedCircleWidth}px`,
              height: `${selectedCircleWidth}px`,
              background: selected?.ColorCode,
            }}
          />
          <img alt="" className="ml-2" src="/images/big-chevron-down.svg" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={`hide-scrollbar d-flex p-2 ${dropDownMenuClass}`}
      >
        {colorListV2.map((subArray, subArrayIndex) => {
          return (
            <>
              <div className="d-flex flex-column align-items-center">
                {subArray.map((color, colorIndex) => {
                  return (
                    <>
                      <div
                        className={`d-flex drop-item p-2 align-items-center pointer ${
                          color?.ColorCode === selected?.ColorCode
                            ? "selected"
                            : ""
                        }`}
                        style={{
                          cursor: NotAvailableColor(
                            color?.ColorCode,
                            colorListWithAvailability
                          )
                            ? "not-allowed"
                            : "pointer",
                        }}
                        onClick={(e) => {
                          if (
                            NotAvailableColor(
                              color?.ColorCode,
                              colorListWithAvailability
                            )
                          )
                            return;
                          props.onChange(color);
                          e.target.closest(".color-wrapper-dropdown").click();
                        }}
                      >
                        <AttackSurfaceTooltip
                          className={""}
                          topLeft={true}
                          content={
                            <span>
                              {NotAvailableColor(
                                color?.ColorCode,
                                colorListWithAvailability
                              )
                                ? "Color is already in use."
                                : color?.ColorName}
                            </span>
                          }
                          toolTipStyle={{
                            bottom: "180%",
                            right: "-72%",
                          }}
                        >
                          <div
                            className="rounded-full "
                            style={{
                              width: `${itemCircleWidth}px`,
                              height: `${itemCircleWidth}px`,
                              background: color?.ColorCode,
                            }}
                          />
                        </AttackSurfaceTooltip>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const colorList = [
  {
    id: 0,
    color: "#0C0D11",
    ColorName: "Black",
  },
  {
    id: 1,
    color: "#616778",
    ColorName: "Comet",
  },
  {
    id: 2,
    color: "#9499AA",
    ColorName: "Grey",
  },
  {
    id: 3,
    color: "#BBBEC9",
    ColorName: "Light Grey",
  },
  {
    id: 4,
    color: "#0C0D11",
    ColorName: "Black",
  },
  {
    id: 5,
    color: "#D4D6DD",
    ColorName: "Silver",
  },
  {
    id: 6,
    color: "#A77322",
    ColorName: "Rust",
  },
  {
    id: 7,
    color: "#BB7F24",
    ColorName: "Dark Yellow",
  },
  {
    id: 8,
    color: "#FFAC2C",
    ColorName: "Yellow",
  },
  {
    id: 9,
    color: "#FFCA78",
    ColorName: "Light Yellow",
  },
  {
    id: 10,
    color: "#FFDEAB",
    ColorName: "Navajo",
  },
  {
    id: 11,
    color: "#934732",
    ColorName: "Burnt",
  },
  {
    id: 12,
    color: "#A44E36",
    ColorName: "Dark Orange",
  },
  {
    id: 13,
    color: "#DF6844",
    ColorName: "Orange",
  },
  {
    id: 14,
    color: "#EB9E87",
    ColorName: "Light Orange",
  },
  {
    id: 15,
    color: "#0C0D11",
    ColorName: "Black",
  },
];

export const colorListV2 = [
  [
    {
      ColorName: "Black",
      ColorCode: "#0C0D11",
    },
    {
      ColorName: "Graphite",
      ColorCode: "#616778",
    },
    {
      ColorName: "Steel",
      ColorCode: "#767A88",
    },
    {
      ColorName: "Slate",
      ColorCode: "#858A99",
    },
    {
      ColorName: "Grey",
      ColorCode: "#9499AA",
    },
    {
      ColorName: "Smoke",
      ColorCode: "#9FA3B3",
    },
    {
      ColorName: "Ash Grey",
      ColorCode: "#A9ADBB",
    },
    {
      ColorName: "Comet",
      ColorCode: "#B4B8C4",
    },
    {
      ColorName: "Light Grey",
      ColorCode: "#BBBEC9",
    },
    {
      ColorName: "Silver",
      ColorCode: "#D4D6DD",
    },
  ],
  [
    {
      ColorName: "Dark Yellow",
      ColorCode: "#A77322",
    },
    {
      ColorName: "Rust",
      ColorCode: "#BB7F24",
    },
    {
      ColorName: "Gold Nugget",
      ColorCode: "#CC8A23",
    },
    {
      ColorName: "Sunflower",
      ColorCode: "#E69B28",
    },
    {
      ColorName: "Yellow",
      ColorCode: "#FFAC2C",
    },
    {
      ColorName: "Sunbeam",
      ColorCode: "#FFB441",
    },
    {
      ColorName: "Peach",
      ColorCode: "#FFBD56",
    },
    {
      ColorName: "Navajo",
      ColorCode: "#FFC56B",
    },
    {
      ColorName: "Light Yellow",
      ColorCode: "#FFCA78",
    },
    {
      ColorName: "Ivory",
      ColorCode: "#FFDEAB",
    },
  ],
  [
    {
      ColorName: "Dark Orange",
      ColorCode: "#934732",
    },
    {
      ColorName: "Burnt",
      ColorCode: "#A44E36",
    },
    {
      ColorName: "Rustic",
      ColorCode: "#B25336",
    },
    {
      ColorName: "Burnt Sienna",
      ColorCode: "#C95E3D",
    },
    {
      ColorName: "Orange",
      ColorCode: "#DF6844",
    },
    {
      ColorName: "Coral",
      ColorCode: "#E27757",
    },
    {
      ColorName: "Light Coral",
      ColorCode: "#E9957C",
    },
    {
      ColorName: "Peachy Pink",
      ColorCode: "#ECA48F",
    },
    {
      ColorName: "Light Orange",
      ColorCode: "#EB9E87",
    },
    {
      ColorName: "Nectar",
      ColorCode: "#F2C3B4",
    },
  ],
  [
    {
      ColorName: "Dark Red",
      ColorCode: "#A04244",
    },
    {
      ColorName: "Carmine",
      ColorCode: "#B2494A",
    },
    {
      ColorName: "Rust Red",
      ColorCode: "#C24D4D",
    },
    {
      ColorName: "Coral Red",
      ColorCode: "#DB5656",
    },
    {
      ColorName: "Red",
      ColorCode: "#F36060",
    },
    {
      ColorName: "Coral",
      ColorCode: "#F47070",
    },
    {
      ColorName: "Coral Blush",
      ColorCode: "#F58080",
    },
    {
      ColorName: "Pale Coral",
      ColorCode: "#F79090",
    },
    {
      ColorName: "Light Red",
      ColorCode: "#F79999",
    },
    {
      ColorName: "Misty",
      ColorCode: "#FABFBF",
    },
  ],
  [
    {
      ColorName: "Dark Blue",
      ColorCode: "#04608E",
    },
    {
      ColorName: "Electric Blue",
      ColorCode: "#036B9D",
    },
    {
      ColorName: "Deep Sky",
      ColorCode: "#0072AA",
    },
    {
      ColorName: "Sky Blue",
      ColorCode: "#0081BF",
    },
    {
      ColorName: "Blue",
      ColorCode: "#008FD4",
    },
    {
      ColorName: "Cerulean",
      ColorCode: "#1A9AD8",
    },
    {
      ColorName: "Light Cerulean",
      ColorCode: "#33A5DD",
    },
    {
      ColorName: "Baby Blue",
      ColorCode: "#4DB1E1",
    },
    {
      ColorName: "Light Blue",
      ColorCode: "#5CB7E3",
    },
    {
      ColorName: "Cornflower",
      ColorCode: "#99D2EE",
    },
  ],
  [
    {
      ColorName: "Dark Purple",
      ColorCode: "#393079",
    },
    {
      ColorName: "Indigo",
      ColorCode: "#3E3586",
    },
    {
      ColorName: "Royal",
      ColorCode: "#42368F",
    },
    {
      ColorName: "Blue Purple",
      ColorCode: "#4A3DA1",
    },
    {
      ColorName: "Purple",
      ColorCode: "#5244B3",
    },
    {
      ColorName: "Lapis Lustre",
      ColorCode: "#6357BB",
    },
    {
      ColorName: "Lavender",
      ColorCode: "#7569C2",
    },
    {
      ColorName: "Sapphire",
      ColorCode: "#867CCA",
    },
    {
      ColorName: "Light Purple",
      ColorCode: "#9087CE",
    },
    {
      ColorName: "Lavender",
      ColorCode: "#BAB4E1",
    },
  ],
  [
    {
      ColorName: "Dark Violet",
      ColorCode: "#2B2F94",
    },
    {
      ColorName: "Lilac",
      ColorCode: "#2F33A5",
    },
    {
      ColorName: "Royal Azure",
      ColorCode: "#3135B2",
    },
    {
      ColorName: "Ethereal Blue",
      ColorCode: "#373BC8",
    },
    {
      ColorName: "Violet",
      ColorCode: "#3D42DE",
    },
    {
      ColorName: "Twilight Hue",
      ColorCode: "#5055E1",
    },
    {
      ColorName: "Blueberry",
      ColorCode: "#6468E5",
    },
    {
      ColorName: "Periwinkle",
      ColorCode: "#777BE8",
    },
    {
      ColorName: "Light Violet",
      ColorCode: "#8386EA",
    },
    {
      ColorName: "Bloom",
      ColorCode: "#B1B3F2",
    },
  ],
  [
    {
      ColorName: "Dark Pink",
      ColorCode: "#923A69",
    },
    {
      ColorName: "Raspberry",
      ColorCode: "#AA437A",
    },
    {
      ColorName: "Fuchsia",
      ColorCode: "#C24D8C",
    },
    {
      ColorName: "Magenta",
      ColorCode: "#DB569E",
    },
    {
      ColorName: "Pink",
      ColorCode: "#F360AF",
    },
    {
      ColorName: "Orchid",
      ColorCode: "#F470B7",
    },
    {
      ColorName: "Light Orchid",
      ColorCode: "#F580BF",
    },
    {
      ColorName: "Pastel",
      ColorCode: "#F790C7",
    },
    {
      ColorName: "Light Pink",
      ColorCode: "#F8A0CF",
    },
    {
      ColorName: "Baby Pink",
      ColorCode: "#F9B0D7",
    },
  ],
  [
    {
      ColorName: "Dark Brown",
      ColorCode: "#582B1E",
    },
    {
      ColorName: "Rich Brown",
      ColorCode: "#673223",
    },
    {
      ColorName: "Earthy Brown",
      ColorCode: "#763928",
    },
    {
      ColorName: "Copper",
      ColorCode: "#84402D",
    },
    {
      ColorName: "Brown",
      ColorCode: "#934732",
    },
    {
      ColorName: "Sandy Brown",
      ColorCode: "#9E5947",
    },
    {
      ColorName: "Cinnamon",
      ColorCode: "#A96C5B",
    },
    {
      ColorName: "Tan",
      ColorCode: "#B37E70",
    },
    {
      ColorName: "Light Brown",
      ColorCode: "#BE9184",
    },
    {
      ColorName: "Beige",
      ColorCode: "#C9A399",
    },
  ],
  [
    {
      ColorName: "Dark Green",
      ColorCode: "#2D8F61",
    },
    {
      ColorName: "Emerald Mist",
      ColorCode: "#319F6B",
    },
    {
      ColorName: "Meadow",
      ColorCode: "#33AD72",
    },
    {
      ColorName: "Lime",
      ColorCode: "#3AC280",
    },
    {
      ColorName: "Green",
      ColorCode: "#40D88E",
    },
    {
      ColorName: "Spring",
      ColorCode: "#53DC99",
    },
    {
      ColorName: "Leaf",
      ColorCode: "#66E0A5",
    },
    {
      ColorName: "Pistachio",
      ColorCode: "#79E4B0",
    },
    {
      ColorName: "Light Green",
      ColorCode: "#85E6B7",
    },
    {
      ColorName: "Mint",
      ColorCode: "#B3EFD2",
    },
  ],
];
