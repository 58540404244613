import { Dropdown } from "react-bootstrap"
import { viewOnlyAccess } from "../../../App/App"
import { TechspendAddMenuAction } from '../../../../constants/techspend.constants';
import SelectArrowIcon from "../../../icons/select-arrow.icon";

const MenuItems = [
  { label: "Add Invoice", action: TechspendAddMenuAction.AddInvoice },
  { label: "Add Amount", action: TechspendAddMenuAction.AddAmount },
  { label: "Add Subscription", action: TechspendAddMenuAction.AddSubscription },
  { label: "Add Subcategory", action: TechspendAddMenuAction.AddSubcategory },
]

const TechspendAddDropdown = ({ 
  goTo, 
  Pages, 
  setSubscriptionViewInvoiceData,
  setAddSpendSelectedTab, 
  getInvoiceDetails,
  setLoading,
  setShowCreateSubcategoryModal,
  parentCategortyData,
  childCategoryData,
  defaultAction = TechspendAddMenuAction.AddInvoice
}) => {
  const handleAction = (action) => {
    switch (action) {
      case TechspendAddMenuAction.AddInvoice:
        setLoading(true);
        setSubscriptionViewInvoiceData({ newInvoice: true });
        getInvoiceDetails(0, 0, { isSubscriptionView: false });
        setAddSpendSelectedTab("addByInvoice");
        goTo(
          "Add Invoice",
          Pages.ADD_SPEND,
          {
            currentTab: "addByInvoice",
          }
        );
        break;
      case TechspendAddMenuAction.AddAmount:
        setLoading(true);
        setSubscriptionViewInvoiceData({ newInvoice: true });
        getInvoiceDetails(0, 0, {  isSubscriptionView: false });
        setAddSpendSelectedTab("addByAmount");
        goTo(
          "Add Amount",
          Pages.ADD_SPEND,
          {
            currentTab: "addByAmount",
          }
        );
        break;
      case TechspendAddMenuAction.AddSubscription:
        setLoading(true);
        setSubscriptionViewInvoiceData({ newInvoice: true });
        getInvoiceDetails(0, 0, {
          Category_Selected: parentCategortyData,
          isSubscriptionView: true,
          ...(childCategoryData?.objId_Category && {
            SubCategory_Selected: childCategoryData,
          })
        });
        setAddSpendSelectedTab("addBySubscription");
        goTo(
          "Add Subscription",
          Pages.ADD_SPEND,
          {
            currentTab: "addBySubscription",
          }
        );
        break;
      case TechspendAddMenuAction.AddSubcategory:
        setShowCreateSubcategoryModal({});
        const techspendButton = document.getElementById("techspend-add-btn");
        if (techspendButton) techspendButton.click();
        break;
      default:
        break;
    }
  }

  return (
    <Dropdown className="transparennt-wrapper-dropdown my-dropdown">
      <Dropdown.Toggle id="techspend-add-btn">
        <div
          className={`d-flex align-items-center bg-blue radius-4 f-white f-500 ${
            viewOnlyAccess("AccessState_TechnologySpend")
              ? "greyText cursor-default"
              : " f-grey pointer"
          }`}
          onClick={() => {
            if (
              !viewOnlyAccess("AccessState_TechnologySpend")
            ) {
              // setShowCreateSubcategoryModal(true);
              // goTo("Add Spend", Pages.ADD_SPEND, {});
            }
          }}
        >
          <span 
            className="p-2"
            onClick={e => {
              e.stopPropagation();
              handleAction(defaultAction);
            }}
          >
            Add
          </span>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ borderLeft: "1px solid #0C0D1114", padding: '0px 4px', height: '35px' }}
          >
            <SelectArrowIcon 
              width="10px" 
              height="8px" 
              fill="#fff" 
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu subcategory-item-menu" style={{ padding: '4px 0' }}>
        <div style={{ padding: '4px' }}>
          {
            MenuItems.map((item, index) => {
              return (
                <div 
                  key={`subitem-${item.label}`} 
                  className="sub-item f-14 line-height-22 f-grey bg-hover-grey pointer"
                  onClick={() => handleAction(item.action)}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {item.label}
                </div>
              )
            })
          }
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TechspendAddDropdown;
