import './switch-button-2.styles.scss';

const SwitchButton2 = ({ active, onChange, disabled }) => {
  return (
    <div 
      className={`switch-button-2 pointer ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
      onClick={() => {
        if (disabled) return;
        onChange(!active);
      }}
    >
      <div className={`circle`} />
    </div>
  );
}

export default SwitchButton2;
