import { useEffect, useState } from "react";

const useComponentPosition = ({
  componentRef,
  dependencies = []
}) => {
  const [componentPosition, setComponentPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!componentRef?.current) return;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "style") {
          const style = mutation.target.style;
          if (style.transform) {
            const [x, y] = style.transform.match(/-?\d+/g);
            setComponentPosition({ x, y });
          }
        }
      });
    });
    observer.observe(componentRef?.current, {
      attributes: true,
    });
    return () => {
      observer.disconnect();
    }
  }, [componentRef, componentRef?.current, ...dependencies]);

  return componentPosition;
}

export default useComponentPosition;
