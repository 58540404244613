import { DateRangePicker } from "react-date-range"
import { Dropdown } from "react-bootstrap";
import useDatePickerData from "./date-picker.data";

const DatePickerDropdown = ({ setDateSelection, id = '' }) => {
  const { 
    setDateSelectionValue,
    selectedDateFilter,
    dateFilterValue,
    staticRanges,
    defineds
  } = useDatePickerData({ setDateSelection, id });

  return (
    <Dropdown
      className="date-filter-dropdown dashboardDateFilter"
      alignRight
      onToggle={(e) => {
        // setToggleDateFilterDropdown(e);
      }}
      // id={`${activeFilter?.filterType}_filterX`}
    >
      <Dropdown.Toggle className="p-0 w-100">
        <div className="d-flex align-items-center justify-content-between p-2 bg-grey f-grey f-500 radius-4 w-100">
          <div className="d-flex align-items-center">
            <img
              src="/images/attack-surface/date-icon.svg"
              alt=""
              className={`mx-2 ${""}`}
            />
            {dateFilterValue}
          </div>
          <div>
            <img
              src="/images/big-chevron-down.svg"
              alt=""
              className={`ml-2 ${""}`}
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0"
        style={{
          zIndex: 4,
        }}
      >
        <DateRangePicker
          direction="vertical"
          ranges={[selectedDateFilter ?? {}]}
          staticRanges={staticRanges}
          onChange={(ranges) => {
            setDateSelectionValue(ranges.selection);
            // if (
            //   ranges.selection.startDate ===
            //   ranges.selection.endDate
            // )
            //   return;
            // setLoading(true);
            // getTechnologySpendData(ranges.selection).then(
            //   () => {
            //     setLoading(false);
            //   }
            // );
            // document
            //   .querySelector(".dashboardDateFilter")
            //   .click();
          }}
        />
        <div
          className="pl-4 f-12 pointer border-bottom pt-0"
          onClick={() => {
            setDateSelectionValue({
              startDate: defineds.startOfLastTwelveMonth,
              endDate: defineds.endOfToday,
              key: "selection",
            });
            // getTechnologySpendData({
            //   startDate: defineds.startOfLastTwelveMonth,
            //   endDate: defineds.endOfToday,
            // });
          }}
          style={{
            padding: "0.7rem",
          }}
        >
          Clear
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DatePickerDropdown;
