import React, { useState, useMemo, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "./select-tool.styles.scss"
import SearchInput from "../../../Common/search-input/search-input.component";
import SelectIntegrations from "./select-integrations.component";
import ResultIntegrations from "./result-integrations.component";
import useDropdown from "../../../../hooks/dropdown.hook";

const SelectToolDropdown = ({ 
  integrationList = [],
  fullIntegrationList = [], 
  onOptionAdd = () => {}, 
  onNewOptionAdd = () => {}, 
  addingNewTool = false,
  onOpenModal = () => {},
  refreshData = () => {},
  isPractice = false,
}) => {
  const searchRef = useRef();
  const [search, setSearch] = useState("");
  const isOptionLoading = useMemo(() => integrationList.find(option => option.loading), [integrationList]);
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: isOptionLoading || addingNewTool });
  const searchIntegrations = useMemo(() => (
    integrationList.filter(option => option.ToolName.toLowerCase().includes(search.toLowerCase()))
  ), [integrationList, search]);

  const optionAdd = (option) => {
    onOptionAdd(option)
      .then(() => {
        setIsOpen(false)
        setSearch("");
      });
  }

  const newOptionAdd = (option) => {
    onNewOptionAdd(option)
      .then(() => {
        setIsOpen(false)
        setSearch("");
      });
  }

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    } else {
      searchRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div>
      <Dropdown show={isOpen} className="w-100 transparennt-wrapper-dropdown">
        <Dropdown.Toggle ref={refBtn} style={{ cursor: 'default' }}>
          <div 
            className="select-tool-btn d-flex align-items-center pointer" onClick={() => {
              onOpenModal().then(() => setIsOpen(prev => !prev));
            }}
            style={{ marginLeft: '20px'}}
          >
            <img
              alt=""
              src="/images/attack-surface/add-tool-icon.svg"
              className="mr-2"
              width={11}
              height={11}
            />
            <span className="f-grey f-14" style={{ fontWeight: 500 }}>
              Add
            </span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu ref={refMenu} className="select-tool-menu cyber-score overlap p-2" style={{ zIndex: 1 }}>
          <div style={{marginBottom: '10px' }} >
            <SearchInput 
              value={search} 
              onChange={value => setSearch(value)} 
              placeholder="Search" 
              className="bg-white pt-0"
              passedRef={searchRef}
            />
          </div>
          <div className="hide-scrollbar" style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {search ? (
              <ResultIntegrations 
                integrationList={searchIntegrations} 
                onOptionAdd={optionAdd} 
                onNewOptionAdd={newOptionAdd}
                searchValue={search}
                addingNewTool={addingNewTool} 
                refreshData={refreshData}
              />
            ) : (
              <SelectIntegrations 
                integrationList={integrationList} 
                onOptionAdd={optionAdd} 
                searchValue={search}
                fullIntegrationList={fullIntegrationList}
                refreshData={refreshData}
                isPractice={isPractice}
              />
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <div style={{ height: '12px' }} />
    </div>
  );
}

export default SelectToolDropdown;
