import React from "react";
import './searchFilterComponent.scss';
import { VisibilityType } from "../../constants/filter.constants";

export const AppliedFiltersComponent = ({
    childAppliedFilterItem,
    onResetClick = () => { },
}) => {

    return (
        <div className="d-flex align-items-center justify-content-between w-100">
            <div>
                {childAppliedFilterItem && childAppliedFilterItem}
            </div>
            <div
                className="f-500 f-12 f-grey pointer"
                onClick={() => {
                    onResetClick();
                }}
            >
                Reset
            </div>
        </div>
    );
};

export default AppliedFiltersComponent;
