import { useEffect, useMemo, useState } from 'react';
import LumpIcon from '../../icons/lump.icon';
import { Checkbox } from 'semantic-ui-react';
import useApiActions from '../../../hooks/api-actions.hook';
import { Spinner } from 'react-bootstrap';

const ReviewMoreToolsTooltip = ({ 
  showTooltip, 
  delay, 
  setShowTooltip, 
  techModalData, 
  integration,
  onAddSuccess = () => { },
  onClose = () => { },
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const { createMultipleIntegrationTechTool } = useApiActions();
  const [isReviewing, setIsReviewing] = useState(false);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const list = useMemo(() => techModalData?.IntegrationTechnologyType_List?.map(item => ({
    label: item?.IntegrationTechnologyTypeName,
    enum: item?.IntegrationTechnologyTypeEnum,
    inYourStack: item?.ExistsInTechnologyStack
  })), [techModalData]);
  const title = techModalData?.TotalMoreTechnologies === 1
    ? 'This tool offers 1 more technology.\nWould you like to review it?'
    : `This tool offers ${techModalData?.TotalMoreTechnologies} more technologies.\nWould you like to review these?`;

  const onAddClick = async (e) => {
    e.stopPropagation();
    setIsAdding(true);
    const payload = {
      ObjectId: integration?.integrationId,
      IntegrationTechList: selectedTechnologies,
      Selected: true,
      ToolName: integration?.integrationName,
      customerId: integration?.customerId,
    };
    const response = await createMultipleIntegrationTechTool(payload);
    setIsAdding(false);
    if (!response?.mr?.Success) return;
    onAddSuccess();
    setShowTooltip(false);
    onClose();
  }

  const onDismissClick = (e) => {
    e.stopPropagation();
    setShowTooltip(false);
    onClose();
  }

  const onReviewClick = (e) => {
    e.stopPropagation();
    setIsReviewing(true);
  }

  useEffect(() => {
    if (!showTooltip) {
      setTimeout(() => {
        setIsReviewing(false);
        setSelectedTechnologies([]);
      }, delay);
    }
  }, [showTooltip]);

  return (
    <div className="review-more-tools-tooltip">
      <div className="arrow" />
      <div className="inner-body">
        <div className="d-flex" style={{ gap: '4px' }}>
          <div className='lump-icon'>
            <LumpIcon 
              style={{
                minWidth: '10px',
                minHeight: '14px',
              }}
            />
          </div>
          <div className="d-flex flex-column w-100" style={{ gap: '8px' }}>
            <p className='f-white m-0'>
              {title}
            </p>
            {isReviewing && (
              <div className='d-flex flex-column' style={{ gap: '4px' }}>
                <h4 className='m-0 review-title'>
                  Choose technologies:
                </h4>
                <div>
                {list
                .sort((a, b) => Number(b?.inYourStack) - Number(a?.inYourStack))
                .map((item, index) => (
                  <RowItem 
                    key={`${item?.label}-${item?.enum}-${index}`}
                    item={item}
                    index={index}
                    selectedTechnologies={selectedTechnologies}
                    setSelectedTechnologies={setSelectedTechnologies}
                  />
                ))}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end" style={{ gap: '10px' }}>
              <button
                onClick={onDismissClick}
              >
                Dismiss
              </button>
              {isReviewing ? (
                <button 
                  className='review-btn'
                  onClick={onAddClick}
                  disabled={!selectedTechnologies.length || isAdding}
               >
                  <span style={{ padding: '1px 4px' }}>
                    Add
                  </span>
                  {isAdding && (
                    <Spinner
                      className='mr-1'
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  )}
               </button>
              ) : (
                <button 
                  className='review-btn'
                  onClick={onReviewClick}
                >
                  Review
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewMoreToolsTooltip;

const RowItem = ({ item, index, setSelectedTechnologies, selectedTechnologies }) => {
  const isChecked = selectedTechnologies?.includes(item?.enum);
  return (
    <div 
      className={`d-flex align-items-center tech-item ${item?.inYourStack ? 'in-your-stack' : ''}`} 
      style={{ gap: '8px' }}
    >
      <div className='d-flex'>
        <Checkbox 
          onChange={e => {
            if (item.inYourStack) return;
            if (e.target.checked) {
              setSelectedTechnologies([...selectedTechnologies, item?.enum]);
            } else {
              setSelectedTechnologies(selectedTechnologies.filter(tech => tech !== item?.enum));
            }
          }}
          checked={isChecked}
          id={`cb-${index}`} className="checkbox"
          {...(item.inYourStack ? {
            checked: true,
          } : {})} 
        />
      </div>
      <label className="m-0" htmlFor={`cb-${index}`}>
        {item?.label} {item?.inYourStack ? '(in your stack)' : ''}
      </label>
    </div>
  )
}
